import { MaintenanceObjectDto } from '@wartungshelden/shared-types';

const isMaintenanceObjectDto = (
  value: object
): value is MaintenanceObjectDto => {
  return (
    'id' in value &&
    'name' in value &&
    'ownerId' in value &&
    'created' in value &&
    'type' in value
  );
};

export default isMaintenanceObjectDto;
