import React from 'react';

import { PriceComponentDto } from '@wartungshelden/shared-types';

import formatEuros from '../../../../../services/format';

interface BasicPricePositionProps {
  singlePrice: number;
  hasDocu: boolean;
  showSinglePrice: boolean;
  showHeadline?: boolean;
  singleAnchorsUnitPrice?: number;
  actualAbsoulutePriceComponents?: PriceComponentDto[];
}

const PricePosition: React.FC<BasicPricePositionProps> = ({
  singlePrice,
  hasDocu,
  singleAnchorsUnitPrice,
  showSinglePrice,
  actualAbsoulutePriceComponents,
  showHeadline,
}) => {
  return (
    <>
      {showHeadline && (
        <div className="font-bold">
          Preis{' '}
          {hasDocu ? (
            <span className="underline">mit</span>
          ) : (
            <span className="underline">ohne</span>
          )}{' '}
          Doku
        </div>
      )}

      {actualAbsoulutePriceComponents && (
        <div
          data-cy={
            hasDocu
              ? 'price-components-with-docu'
              : 'price-components-without-docu'
          }
        >
          {actualAbsoulutePriceComponents
            .filter((actualVariablePriceComponent) => {
              if (!hasDocu) return true;
              return (
                actualVariablePriceComponent.type !== 'documentation_missing'
              );
            })
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((actualVariablePriceComponent) => {
              return (
                <div
                  className="flex"
                  key={JSON.stringify(actualVariablePriceComponent)}
                >
                  <div className="whitespace-nowrap truncate w-full">
                    {actualVariablePriceComponent.reason}:
                  </div>
                  <div
                    className="text-right ml-2"
                    data-cy="price-component-price"
                  >
                    {formatEuros(actualVariablePriceComponent.value)}
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <div className="flex flex-col grow justify-end">
        {singleAnchorsUnitPrice && (
          <div className="flex" data-cy="unit-price">
            <div className="whitespace-nowrap self-end">Stückpreis:</div>
            <div className="w-full text-right ml-2 self-end">
              {formatEuros(singleAnchorsUnitPrice)}
            </div>
          </div>
        )}

        {showSinglePrice && (
          <div className="flex font-bold">
            <div className="whitespace-nowrap self-end">Einzelpreis:</div>
            <div className="w-full text-right ml-2 self-end">
              {formatEuros(singlePrice)}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

PricePosition.defaultProps = {
  showHeadline: true,
  actualAbsoulutePriceComponents: undefined,
  singleAnchorsUnitPrice: undefined,
};

export default PricePosition;
