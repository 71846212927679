import { Formik } from 'formik';
import React from 'react';

import { CustomerOrMaintenanceTeamRequestDto } from '@wartungshelden/shared-types';

import TextareaRow from '../../../components/Basics/Inputs/TextareaRow';
import ConfirmationModal from '../../../components/Basics/Modals/ConfirmationModal';
import { useUpdateMaintenanceRequest } from '../../../services/api/maintenance-requests/maintenance-request-api';

interface PriceAdoptionModalProps {
  isOpen?: boolean;
  onClose: () => void;
  request: CustomerOrMaintenanceTeamRequestDto;
}
const InternalNoteModal: React.FC<
  React.PropsWithChildren<PriceAdoptionModalProps>
> = ({ request, onClose, isOpen = false }) => {
  const { mutateAsync: updateRequest } = useUpdateMaintenanceRequest();
  return (
    <Formik
      initialValues={{
        maintenanceTeamInternalNote:
          ('maintenanceTeamInternalNote' in request &&
            request.maintenanceTeamInternalNote) ||
          '',
      }}
      onSubmit={(values) => {
        updateRequest({
          id: request.id,
          maintenanceTeamInternalNote: values.maintenanceTeamInternalNote,
        }).finally(() => {
          onClose();
        });
      }}
    >
      {({ submitForm, dirty }) => {
        return (
          <ConfirmationModal
            width="one-third"
            isVisible={isOpen}
            heading="Interne Notiz"
            onAccept={
              dirty
                ? () => {
                    submitForm().catch();
                  }
                : undefined
            }
            onCancel={onClose}
          >
            <div className="mb-2">
              Diese Notiz ist nur intern für ABS Safety.
            </div>
            <TextareaRow
              name="maintenanceTeamInternalNote"
              rows={5}
              placeholder="Interne Notiz"
              label=""
            />
          </ConfirmationModal>
        );
      }}
    </Formik>
  );
};
InternalNoteModal.defaultProps = {
  isOpen: undefined,
};

export default InternalNoteModal;
