import {
  ClimbingProtection,
  FallProtection,
  GroundStairs,
  Ladder,
  OtherType,
  Overpass,
  PersonalProtectionEquipment,
  RailSystem,
  Railing,
  StairLadder,
} from './maintenance-object-system-types.type';

export interface WithDuration {
  // For explanation see https://abssafety.atlassian.net/wiki/spaces/LBEXPLORATION/pages/230424608/Berechnung+zeitlicher+Umfang+der+Wartung
  get durationInMinutes(): number | undefined;
}

export type MaintenanceType =
  | FallProtection
  | PersonalProtectionEquipment
  | Ladder
  | Railing
  | RailSystem
  | ClimbingProtection
  | StairLadder
  | Overpass
  | GroundStairs
  | OtherType;
