import React from 'react';

const PolicyAuthFooter: React.FC = () => {
  return (
    <div className="py-2 flex flex-row justify-around">
      <div className="flex-col">
        <a
          className="underline text-gray-abs text-sm"
          target="_blank"
          href="/privacy"
        >
          Impressum & Datenschutz
        </a>
      </div>
      <div className="flex-col">
        <a
          className="underline text-gray-abs text-sm"
          target="_blank"
          href="/terms-of-service"
        >
          Nutzungsbedingungen
        </a>
      </div>
    </div>
  );
};

export default PolicyAuthFooter;
