import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceDocumentation } from '../../maintenance-documentation';
import { MaintenanceObjectRepository } from '../../maintenance-object';
import {
  MaintenanceOfferRepository,
  MaintenanceOrderStatusEnum,
} from '../../maintenance-offer';

export class MustOnlySetValidityBeforeOrdered extends DomainRule<
  MaintenanceDocumentation,
  MaintenanceDocumentation
> {
  constructor(
    private maintenanceObjectRepository: MaintenanceObjectRepository,
    private maintenanceOfferRepository: MaintenanceOfferRepository,
    private newMaintenanceDocumentation: Pick<MaintenanceDocumentation, 'id'> &
      Partial<MaintenanceDocumentation>
  ) {
    super();
  }

  protected async rule(documentation: MaintenanceDocumentation) {
    const changedObjectIds = this.getChangedObjectIds(documentation);

    if (!changedObjectIds.length) return documentation;

    const maintenanceObjects =
      await this.maintenanceObjectRepository.getAllById(changedObjectIds);

    for (const currentObject of maintenanceObjects) {
      if (!currentObject.currentRequestId) {
        continue;
      }

      if (
        !this.newMaintenanceDocumentation.validFor &&
        !this.newMaintenanceDocumentation.invalidFor &&
        !this.newMaintenanceDocumentation.notRelevantFor
      ) {
        continue;
      }

      const offers = await this.maintenanceOfferRepository.getAllByRequestId(
        currentObject.currentRequestId
      );

      const currentOffer = offers[0];

      if (
        currentOffer.order?.status &&
        currentOffer.order?.status === MaintenanceOrderStatusEnum.CONFIRMED
      ) {
        throw new DomainRuleNotSatisfiedError(MustOnlySetValidityBeforeOrdered);
      }
    }

    return documentation;
  }

  private getChangedObjectIds(documentation: MaintenanceDocumentation) {
    const newValidForIds =
      this.newMaintenanceDocumentation.validFor?.filter(
        (newMaintenanceDocumentationValidForId) =>
          !documentation.validFor.includes(
            newMaintenanceDocumentationValidForId
          )
      ) || [];

    const newInvalidForIds =
      this.newMaintenanceDocumentation.invalidFor?.filter(
        (newMaintenanceDocumentationInvalidForId) =>
          !documentation.invalidFor.includes(
            newMaintenanceDocumentationInvalidForId
          )
      ) || [];

    const newNotRelevantForIds =
      this.newMaintenanceDocumentation.notRelevantFor?.filter(
        (newMaintenanceDocumentationNotRelevantForId) =>
          !documentation.notRelevantFor.includes(
            newMaintenanceDocumentationNotRelevantForId
          )
      ) || [];

    return [
      ...new Set([
        ...newValidForIds,
        ...newInvalidForIds,
        ...newNotRelevantForIds,
      ]),
    ];
  }
}
