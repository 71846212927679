import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FaArrowAltCircleRight } from 'react-icons/fa';

import { FALL_PROTECTION_SYSTEM_LOCATION } from '../../../constants/MaintenanceConstants';
import {
  isFormikFallProtectionMaintenanceObject,
  isFormikFallProtectionPushLockSystem,
  isFormikFallProtectionRopeSystem,
} from '../../../guards/isFormikMaintenanceObject';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import AuthLinkButton from '../../Auth/AuthLinkButton';
import Button from '../../Basics/Buttons/Button';
import { DropdownFieldLabelAmong } from '../../Basics/Inputs/DropdownFieldLayout';
import { RoofAccess } from '../../index';
import PushLockSystem from './PushLockSystem';
import RopeSystem from './RopeSystem';
import RopeSystemAssistance from './RopeSystemAssistance';
import SingleSystem from './SingleSystem';

const ObjectMaintenanceProperties: React.FC<
  React.PropsWithChildren<{
    index: number;
    onClose: () => void;
  }>
> = ({ index, onClose }) => {
  const { values, status } = useFormikContext<FormikMaintenanceWizardValues>();
  const errorKeys = status ? Object.keys(status) : [];
  const formikMaintenanceObject = values.maintenanceObjects[index];
  const [showRopeAssistance, setShowRopeAssistance] = useState(false);

  if (!isFormikFallProtectionMaintenanceObject(formikMaintenanceObject))
    return null;

  const fallProtectionSystem = formikMaintenanceObject.system;

  if (!fallProtectionSystem) return null;

  const returnFallprotection = () => {
    if (isFormikFallProtectionRopeSystem(fallProtectionSystem)) {
      return <RopeSystem index={index} />;
    }

    if (isFormikFallProtectionPushLockSystem(fallProtectionSystem)) {
      return <PushLockSystem index={index} />;
    }

    return <SingleSystem index={index} />;
  };

  const locactionOptions = () => {
    if (isFormikFallProtectionPushLockSystem(fallProtectionSystem)) {
      return {
        [FALL_PROTECTION_SYSTEM_LOCATION.FACADE.label]:
          FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value,
        [FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.label]:
          FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value,
      };
    }
    return {
      [FALL_PROTECTION_SYSTEM_LOCATION.ROOF.label]:
        FALL_PROTECTION_SYSTEM_LOCATION.ROOF.value,
      [FALL_PROTECTION_SYSTEM_LOCATION.FACADE.label]:
        FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value,
      [FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.label]:
        FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value,
    };
  };

  return (
    <div className="flex flex-col items-center ">
      {showRopeAssistance ? (
        <RopeSystemAssistance
          index={index}
          onClose={() => setShowRopeAssistance(false)}
        />
      ) : (
        <>
          <div className="font-bold mb-8 w-60 text-center">
            Eigenschaften des Systems
          </div>
          <div className={`flex 'h-full flex-col overflow-y-auto w-full`}>
            <div>
              <span
                className={`font-bold mb-2 ${
                  errorKeys.includes(`maintenanceObjects.${index}.location`)
                    ? 'text-red'
                    : ''
                }`}
              >
                Position
              </span>
              <DropdownFieldLabelAmong
                label="Wo befindet sich deine Absturzsicherung?"
                name={`maintenanceObjects.${index}.location`}
                options={locactionOptions()}
              />
            </div>

            <div className="flex flex-row items-center mt-10">
              <div className="font-bold flex flex-1">Bestandteile</div>

              {isFormikFallProtectionRopeSystem(fallProtectionSystem) && (
                <div className="flex flex-1 flex-row items-center justify-end text-blue-abs">
                  <FaArrowAltCircleRight className="mr-2" />
                  <AuthLinkButton
                    label="Du brauchst Hilfe bei den Bestandteilen?"
                    onClick={() => {
                      setShowRopeAssistance(true);
                    }}
                  />
                </div>
              )}
            </div>
            {returnFallprotection()}
            <RoofAccess index={index} />

            <div className="flex w-full flex-row justify-center pt-4">
              <Button
                className="primary-button"
                onClick={() => {
                  onClose();
                }}
                label="Ok"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ObjectMaintenanceProperties;
