import { Formik } from 'formik';
import React, { useContext } from 'react';

import {
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceDocumentationDto,
  MaintenanceOrderStatusDtoEnum,
  RequestStatusEnum,
} from '@wartungshelden/shared-types';

import { distinct } from '../../../../set';
import {
  DOCUMENTATION_NOT_VALIDATED_ERROR,
  HintHighlightContext,
  MAINTENANCE_OBJECT_DOCUMENTATION_NOT_VALIDATED_ERROR,
} from '../../../contexts/HintHighlightContext';
import { useUpdateMaintenanceDocumentation } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import { useMaintenanceObjects } from '../../../services/api/maintenance-objects/maintenance-objects-api';
import { useMaintenanceOffers } from '../../../services/api/maintenance-offers/maintenance-offer-api';
import { useMaintenanceRequests } from '../../../services/api/maintenance-requests/maintenance-request-api';
import DropdownField from '../../Basics/Inputs/DropdownField';
import Loader from '../../Basics/Loaders/Loader';

interface DocumentationValidityChangerProps {
  document: MaintenanceDocumentationDto;
  documentNotValidated: boolean;
  documentIsValid: boolean;
  documentNotRelevant: boolean;
  maintenanceObjectId: string;
}

const DOC_NOT_RELEVANT = 'Nicht relevant';
const DOC_VALID = 'Gültig';
const DOC_INVALID = 'Ungültig';
const DEFAULT_DROPDOWN_OPTION = 'Datei prüfen...';

const DocumentationValidityChanger: React.FC<
  React.PropsWithChildren<DocumentationValidityChangerProps>
> = ({
  document,
  documentNotValidated,
  documentIsValid,
  documentNotRelevant,
  maintenanceObjectId,
}) => {
  const { clearHighlight } = useContext(HintHighlightContext);
  const { mutateAsync: updateMaintenanceDocumentation, isLoading } =
    useUpdateMaintenanceDocumentation();

  const { data: maintenanceObjects, isLoading: maintenanceObjectsLoading } =
    useMaintenanceObjects();

  const { data: maintenanceOffers, isLoading: maintenanceOffersLoading } =
    useMaintenanceOffers();

  const { data: maintenanceRequests, isLoading: maintenanceRequestsLoading } =
    useMaintenanceRequests();

  const offerDataLoading = () =>
    maintenanceObjectsLoading ||
    maintenanceOffersLoading ||
    maintenanceRequestsLoading;

  const currentMaintenanceObject = maintenanceObjects?.find(
    (object) => object.id === maintenanceObjectId
  );

  const currentRequest = maintenanceRequests?.find(
    (request) => request.id === currentMaintenanceObject?.currentRequestId
  );

  const currentOffer = maintenanceOffers?.find(
    (offer: CustomerOrMaintenanceTeamOfferDto) =>
      offer.requestId === currentRequest?.id
  );

  const isRequestConfirmedOrClosed = () =>
    Boolean(
      currentOffer?.order?.status === MaintenanceOrderStatusDtoEnum.CONFIRMED ||
        currentOffer?.order?.status === MaintenanceOrderStatusDtoEnum.CLOSED
    );

  const setDocumentValidity = async (setDocumentToValid) => {
    if (!document || !maintenanceObjectId) {
      return;
    }

    const oldValidFor = document.validFor ?? [];
    const oldInvalidFor = document.invalidFor ?? [];
    const oldNotRelevantFor = document.notRelevantFor ?? [];

    // if set to valid clear it up from invalid array
    const validFor = setDocumentToValid
      ? distinct([...oldValidFor, maintenanceObjectId])
      : oldValidFor.filter((id) => id !== maintenanceObjectId);

    // if set to invalid clear it up from valid array
    const invalidFor = setDocumentToValid
      ? oldInvalidFor.filter((id) => id !== maintenanceObjectId)
      : distinct([...oldInvalidFor, maintenanceObjectId]);

    // in both cases clear it up from notRelevantFor array
    const notRelevantFor = oldNotRelevantFor.filter(
      (id) => id !== maintenanceObjectId
    );

    await updateMaintenanceDocumentation({
      ...document,
      validFor,
      invalidFor,
      notRelevantFor,
    });
  };

  const setDocumentNotRelevant = async () => {
    if (!document || !maintenanceObjectId) {
      return;
    }

    const oldValidFor = document.validFor ?? [];
    const oldInvalidFor = document.invalidFor ?? [];
    const oldNotRelevantFor = document.notRelevantFor ?? [];

    const notRelevantFor = distinct([
      ...oldNotRelevantFor,
      maintenanceObjectId,
    ]);
    // clear it from valid or invalid array
    const validFor = oldValidFor.filter((id) => id !== maintenanceObjectId);
    const invalidFor = oldInvalidFor.filter((id) => id !== maintenanceObjectId);

    await updateMaintenanceDocumentation({
      ...document,
      validFor,
      invalidFor,
      notRelevantFor,
    });
  };

  const getDocumentValidity = () => {
    if (documentNotValidated) {
      return DEFAULT_DROPDOWN_OPTION;
    }
    if (documentNotRelevant) {
      return DOC_NOT_RELEVANT;
    }
    if (documentIsValid) {
      return DOC_VALID;
    }
    return DOC_INVALID;
  };

  if (currentRequest?.status === RequestStatusEnum.REQUESTED) {
    return null;
  }

  return (
    <Formik
      initialValues={{ documentationValidity: getDocumentValidity() }}
      enableReinitialize
      onSubmit={async (values) => {
        if (values?.documentationValidity !== DOC_NOT_RELEVANT) {
          await setDocumentValidity(
            values?.documentationValidity === DOC_VALID
          );
        } else {
          await setDocumentNotRelevant();
        }
        clearHighlight(`${DOCUMENTATION_NOT_VALIDATED_ERROR}${document.id}`);
        clearHighlight(
          `${MAINTENANCE_OBJECT_DOCUMENTATION_NOT_VALIDATED_ERROR}${maintenanceObjectId}`
        );
      }}
    >
      {({ submitForm, setFieldValue }) => {
        return (
          <div>
            {offerDataLoading() || isLoading ? (
              <div className="flex justify-center">
                <Loader className="h-8 w-8" />
              </div>
            ) : (
              <DropdownField
                smallSize
                name="documentationValidity"
                defaultOption={DEFAULT_DROPDOWN_OPTION}
                options={[DOC_VALID, DOC_INVALID, DOC_NOT_RELEVANT]}
                disabled={isRequestConfirmedOrClosed()}
                onSelect={(value) => {
                  setFieldValue('documentationValidity', value, false);
                  submitForm().catch();
                }}
              />
            )}
          </div>
        );
      }}
    </Formik>
  );
};

DocumentationValidityChanger.defaultProps = {};

export default DocumentationValidityChanger;
