import { Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';

import { InputField } from '../../../../components';
import Button from '../../../../components/Basics/Buttons/Button';
import DateSelectFieldFormik from '../../../../components/Basics/Inputs/DateSelection/DateSelectFieldFormik';
import { RoutePlanContext } from '../../../../contexts/RoutePlanContext';
import { PlanFormikValues } from '../InstallationPlanContainer';

const InputBar: React.FC = () => {
  const {
    onLoadOpenMaintenances,

    setInstallerAreaId,
    setDeliveryDateMonth,
    clearRoutePlan,
  } = useContext(RoutePlanContext);

  const initialValues: PlanFormikValues = {
    installerId: undefined,
    deliveryDate: undefined,
  };

  return (
    <div className="flex bg-white px-8 py-4 shadow">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          installerId: Yup.string().optional(),
          deliveryDate: Yup.string().required(),
        })}
        enableReinitialize
        validateOnMount
        onSubmit={(values) => {
          if (values.deliveryDate) {
            onLoadOpenMaintenances(values.deliveryDate, values.installerId);
          }
        }}
      >
        {({ isValid, submitForm, values }) => {
          useEffect(() => {
            setInstallerAreaId(undefined);
            setDeliveryDateMonth(undefined);
            clearRoutePlan();
          }, [values.installerId, values.deliveryDate]);

          return (
            <div className="flex flex-1 flex-col space-y-2">
              <div className="flex flex-1 space-x-2">
                <div
                  className="flex flex-1 items-center pr-8 w-1/3"
                  data-cy="due-date-selector"
                >
                  <span className="font-bold mr-4">Fälligkeitsmonat</span>
                  <DateSelectFieldFormik name="deliveryDate" onlyMonths />
                </div>
                <div className="flex flex-1 items-center pr-8  w-1/3">
                  <InputField
                    label="Monteursregion"
                    name="installerId"
                    labelStyle=""
                  />
                </div>
                <div className="flex flex-1 items-center w-1/3">
                  <Button
                    disabled={!isValid}
                    label="Daten laden"
                    onClick={() => submitForm()}
                    className="primary-button"
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default InputBar;
