import React from 'react';

import { MaintenanceDocumentationDto } from '@wartungshelden/shared-types';

import { useMaintenanceDocumentationFile } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import Loader from '../Loaders/Loader';
import FileNameComponent from './File/FileNameComponent';
import FileThumbnailComponent from './File/FileThumbnailComponent';

type DocumentPreviewProps = {
  documentationOrFile: MaintenanceDocumentationDto | File;
  isSelected: boolean;
  onSelect: () => void;
  disabled?: boolean;
  testId?: string;
};

const DocumentOrFileSelection: React.FC<
  React.PropsWithChildren<DocumentPreviewProps>
> = ({
  documentationOrFile,
  isSelected,
  onSelect,
  disabled = false,
  testId,
}) => {
  const hasFile = documentationOrFile instanceof File;

  const { isLoading, data: loadedFile } = useMaintenanceDocumentationFile(
    hasFile ? undefined : documentationOrFile.id,
    !hasFile,
    undefined
  );

  const loadedFileFromDocument = hasFile ? documentationOrFile : loadedFile;

  return (
    <div
      role="button"
      data-testid={testId}
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => {
        if (!disabled) {
          onSelect();
        }
      }}
      className={`flex h-full w-full bg-gray text-gray-abs relative cursor-pointer ${
        isSelected ? 'border-4 border-green-500' : ''
      }`}
    >
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        loadedFileFromDocument && (
          <div className="flex flex-1 flex-col h-full">
            <FileThumbnailComponent file={loadedFileFromDocument} />
            <FileNameComponent file={loadedFileFromDocument} />
          </div>
        )
      )}
    </div>
  );
};

DocumentOrFileSelection.defaultProps = {
  disabled: false,
  testId: undefined,
};

export default DocumentOrFileSelection;
