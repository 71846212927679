import React from 'react';

import FileUpload from '../FileUpload';
import Loader from '../Loaders/Loader';
import DeleteFilePreview from './DeleteFilePreview';

interface SingleFileUploadProps {
  showDeleteFile: boolean;
  onDeleteFile: (file: File) => Promise<unknown>;
  onAttachFile: (file: File) => Promise<unknown>;
  isLoading: boolean;
  file?: File;
}

const SingleFileUpload: React.FC<SingleFileUploadProps> = ({
  showDeleteFile,
  onDeleteFile,
  onAttachFile,
  isLoading,
  file,
}) => {
  return (
    <div className="flex flex-row">
      {!isLoading ? (
        <div>
          {file ? (
            <div className="flex flex-col w-32">
              <DeleteFilePreview
                onDelete={onDeleteFile}
                showDelete={showDeleteFile}
                file={file}
              />
            </div>
          ) : (
            <div className="flex flex-col w-28">
              <FileUpload
                disabled={!showDeleteFile}
                onAddFile={(files) => onAttachFile(files[0])}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col w-32 h-32 justify-center items-center border-4">
          <Loader />
        </div>
      )}
    </div>
  );
};

SingleFileUpload.defaultProps = {
  file: undefined,
};

export default SingleFileUpload;
