import React from 'react';

import { ClimbingProtectionDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface ClimbingProtecionPropertiesProps {
  climbingProtection: ClimbingProtectionDto;
  hasSubprices: boolean;
}

const ClimbingProtecionProperties: React.FC<
  ClimbingProtecionPropertiesProps
> = ({ climbingProtection, hasSubprices }) => (
  <MaintenanceObjectSummaryRow
    label="Steigschutz"
    value={climbingProtection.length}
    unit="m"
    hasSubprices={hasSubprices}
  />
);

export default ClimbingProtecionProperties;
