// eslint-disable-next-line import/prefer-default-export
export const taskStatuses = {
  OPEN: 0,
  REQUESTED: 1,
  VALIDATING: 2,
  INSTALLATION_REQUEST: 3,
  OFFER_SENT: 4,
  OFFER_ACCEPTED: 5,
  INSTALLATION_IN_PROGRESS: 6,
  INSTALLATION_DONE: 7,
  POSTWORK: 8,
  PROBLEM: 9,
  DONE: 10,
  INVOICE_SENT: 11,
  INVOICE_PAID: 12,
  CLOSED: 13,
};
