import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { BUILDING_TYPES } from '../../constants/BuildingsConstants';
import RadioList from '../Basics/Inputs/RadioList';
import ConfirmationModal from '../Basics/Modals/ConfirmationModal';
import { SelectField } from '../index';

type BuildingTypeSelectProps = {
  disabled?: boolean;
  namePrefix?: string;
};

const BuildingTypeSelectField: React.FC<
  React.PropsWithChildren<BuildingTypeSelectProps>
> = ({ disabled, namePrefix }) => {
  const { setFieldValue, values }: any = useFormikContext();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [oldValue, setOldValue] = useState();

  useEffect(() => {
    setOldValue(values.buildingType);
  }, []);

  return (
    <>
      <SelectField
        label="Gebäudetyp*"
        name={`${namePrefix ? `${namePrefix}.` : ''}buildingType`}
        placeHolder="Gebäudetyp wählen"
        onClick={() => {
          if (!disabled) {
            setIsModalVisible(true);
          }
        }}
        alternativeDisplayText={values.otherBuildingType || undefined}
        disabled={disabled}
      />
      <ConfirmationModal
        heading="Gebäudetyp wählen"
        onAccept={() => {
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          if (values.buildingType !== 'Sonstiges') {
            setFieldValue(
              `${namePrefix ? `${namePrefix}.` : ''}buildingType`,
              null
            );
          }
          setFieldValue(
            `${namePrefix ? `${namePrefix}.` : ''}buildingType`,
            oldValue
          );
        }}
        isVisible={isModalVisible}
      >
        <RadioList
          selectionItems={BUILDING_TYPES}
          name={`${namePrefix ? `${namePrefix}.` : ''}buildingType`}
          otherName="otherBuildingType"
        />
      </ConfirmationModal>
    </>
  );
};

BuildingTypeSelectField.defaultProps = {
  disabled: false,
  namePrefix: undefined,
};

export default BuildingTypeSelectField;
