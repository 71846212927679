import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

export class UserMayNotSetInternalNote<
  T extends { id: string; maintenanceTeamInternalNote?: string | null }
> extends DomainRule<T, T> {
  constructor(private user: User) {
    super();
  }

  protected async rule(entity: T) {
    if (
      !this.user.isMaintenanceTeamMemberAdmin &&
      entity.maintenanceTeamInternalNote
    ) {
      throw new DomainRuleNotSatisfiedError(UserMayNotSetInternalNote);
    }
    return entity;
  }
}
