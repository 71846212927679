import { useFormikContext } from 'formik';
import React from 'react';

import {
  FALL_PROTECTION_SYSTEM_LOCATION,
  LOCK_II_FIELDS,
} from '../../../../constants/MaintenanceConstants';
import {
  isFormikFallProtectionPushLockSystem,
  isFormikFallProtectionRopeSystem,
} from '../../../../guards/isFormikMaintenanceObject';
import {
  FormikFallProtection,
  FormikFallProtectionPushLockSystem,
  FormikFallProtectionRopeSystem,
  FormikFallProtectionSingleAnchorSystem,
} from '../../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';

interface PropertyCellViewProps {
  formikFallProtection: FormikFallProtection;
}

const FallProtectionPropertyView: React.FC<
  React.PropsWithChildren<PropertyCellViewProps>
> = ({ formikFallProtection }) => {
  const { status } = useFormikContext();
  const errorKeys = status ? Object.keys(status) : [];

  let formikFallProtectionRopeSystem:
    | FormikFallProtectionRopeSystem
    | undefined;
  let formikFallProtectionPushLockSystem:
    | FormikFallProtectionPushLockSystem
    | undefined;
  let formikFallProtectionSingleAnchorSystem:
    | FormikFallProtectionSingleAnchorSystem
    | undefined;

  if (
    formikFallProtection?.system &&
    isFormikFallProtectionRopeSystem(formikFallProtection.system)
  ) {
    formikFallProtectionRopeSystem = formikFallProtection.system;
  } else if (
    formikFallProtection?.system &&
    isFormikFallProtectionPushLockSystem(formikFallProtection.system)
  ) {
    formikFallProtectionPushLockSystem = formikFallProtection.system;
  } else {
    // @ts-ignore
    formikFallProtectionSingleAnchorSystem = formikFallProtection?.system;
  }

  if (
    !formikFallProtectionRopeSystem &&
    !formikFallProtectionPushLockSystem &&
    !formikFallProtectionSingleAnchorSystem
  )
    return null;

  const locationValueToLabel = () => {
    if (
      formikFallProtection.location ===
      FALL_PROTECTION_SYSTEM_LOCATION.ROOF.value
    ) {
      return FALL_PROTECTION_SYSTEM_LOCATION.ROOF.label;
    }
    if (
      formikFallProtection.location ===
      FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value
    ) {
      return FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.label;
    }
    if (
      formikFallProtection.location ===
      FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value
    ) {
      return FALL_PROTECTION_SYSTEM_LOCATION.FACADE.label;
    }
    return null;
  };

  const getRoofAccessAsString = () => {
    const values: string[] = [];
    if (formikFallProtection.ladder) {
      values.push('Leiter');
    }
    if (formikFallProtection.climbLadder) {
      values.push('Steigleiter');
    }
    if (formikFallProtection.liftingPlatform) {
      values.push('Hubbühne');
    }
    if (formikFallProtection.roofExit) {
      values.push('Dachausstieg');
    }
    if (formikFallProtection.scaffold) {
      values.push('Gerüst');
    }
    if (formikFallProtection.other && formikFallProtection.otherText) {
      values.push(formikFallProtection.otherText);
    }
    return values.join(', ');
  };

  return (
    <div className="bg-white my-2">
      <span
        className={`${
          errorKeys.includes('maintenanceObjects.0.location') ? 'text-red' : ''
        }`}
      >
        Position:{' '}
      </span>
      {locationValueToLabel()}
      {formikFallProtectionRopeSystem && (
        <div>
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.ropeLength')
                ? 'text-red'
                : ''
            }`}
          >
            Seillänge:{' '}
          </span>
          {formikFallProtectionRopeSystem.ropeLength} m
          <br />
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.systemAnchors')
                ? 'text-red'
                : ''
            }`}
          >
            Systemstützen:{' '}
          </span>
          {formikFallProtectionRopeSystem.systemAnchors} Stück
          <br />
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.singleAnchors')
                ? 'text-red'
                : ''
            }`}
          >
            Einzelanschlagpunkte:{' '}
          </span>
          {formikFallProtectionRopeSystem.singleAnchors} Stück
        </div>
      )}
      {formikFallProtectionPushLockSystem && (
        <div>
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.lock1')
                ? 'text-red'
                : ''
            }`}
          >
            {LOCK_II_FIELDS[0].label}:{' '}
          </span>
          {formikFallProtectionPushLockSystem.lock1} Stück
          <br />
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.lock2')
                ? 'text-red'
                : ''
            }`}
          >
            {LOCK_II_FIELDS[1].label}:{' '}
          </span>
          {formikFallProtectionPushLockSystem.lock2} Stück
        </div>
      )}
      {formikFallProtectionSingleAnchorSystem && (
        <div>
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.singleAnchors')
                ? 'text-red'
                : ''
            }`}
          >
            Einzelanschlagpunkte:{' '}
          </span>
          {formikFallProtectionSingleAnchorSystem.singleAnchors} Stück
        </div>
      )}
      <div>
        {' '}
        <span className={`${errorKeys.includes('access') ? 'text-red' : ''}`}>
          Zugang:{' '}
        </span>
        {getRoofAccessAsString()}
      </div>
    </div>
  );
};

export default FallProtectionPropertyView;
