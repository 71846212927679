import * as countries from 'i18n-iso-countries';
import React, { useContext, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';

import { Button, Card, PageContainer, PageHeader } from '../../components';
import AddressReadOnly from '../../components/Address/AddressReadOnly';
import CustomerDataModal from '../../components/Settings/CustomerDataModal';
import { SessionContext } from '../../contexts/SessionContext';
import { useOwnCustomer } from '../../services/api/customer/customer-api';

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const SettingsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);
  const { data: customer } = useOwnCustomer();

  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  return (
    <div>
      {isMaintenanceTeamMemberAdmin ? (
        <Navigate replace to="" />
      ) : (
        <div>
          {customer && (
            <PageContainer>
              <Card>
                <PageHeader>Adresse</PageHeader>
                {customer.billingAddresses.length > 0 ? (
                  <div>
                    <AddressReadOnly
                      addresses={customer.billingAddresses}
                      email={customer.email}
                      phoneNumber={customer.phoneNumber}
                    />
                    <div className="w-[25%] mt-4">
                      <Button
                        onClick={() => setIsCustomerModalOpen(true)}
                        label="Adresse bearbeiten"
                        className="primary-button small-button"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="mb-4">
                    <Button
                      label="Adresse hinzufügen"
                      icon={<FaPlus />}
                      onClick={() => setIsCustomerModalOpen(true)}
                      className="primary-button small-button"
                    />
                  </div>
                )}
              </Card>
              <CustomerDataModal
                customer={customer}
                isOpen={isCustomerModalOpen}
                setIsOpen={setIsCustomerModalOpen}
              />
            </PageContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
