import React, { useContext, useState } from 'react';
import { FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { routes } from '../../constants/routes';
import { SessionContext } from '../../contexts/SessionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import ContactButton from '../../pages/Auth/ContactButton';
import Button from '../Basics/Buttons/Button';

const HeaderLogout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {
    isMaintenanceTeamMemberAdmin,
    isMaintenanceTeamMemberUser,
    userInfo,
    logout,
  } = useContext(SessionContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setIsNotificationOpen } = useContext(SettingsContext);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const hasProfileSettings =
    !isMaintenanceTeamMemberUser || !isMaintenanceTeamMemberAdmin;

  return userInfo ? (
    <div className="flex h-20 bg-gray-lighter px-8">
      <div className="flex flex-1 items-center">{children}</div>
      <div className="flex items-center mr-2">
        <ContactButton />
      </div>
      <button
        className="flex items-center"
        id="profile"
        type="button"
        onClick={() => toggleMenu()}
      >
        <FaUserCircle size={32} className="text-red" />
      </button>
      {isMenuOpen && (
        <div className="transparent absolute top-0 bottom-0 left-0 right-0 z-10">
          <button
            type="button"
            className="h-full w-full transparent cursor-default"
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            <div className="absolute top-16 right-8 bg-custom-white-off z-20">
              {userInfo && (
                <div className="flex flex-col items-start relative">
                  <div className="w-12 overflow-hidden absolute -top-4 -right-4">
                    <div className="h-6 w-5 bg-custom-white-off rotate-45 transform origin-bottom-left" />
                  </div>

                  {userInfo?.email && (
                    <div className="flex flex-col px-4 py-2 items-start w-full">
                      <div className="mb-2">angemeldet als:</div>
                      <strong className="mb-2 ml-2">{userInfo?.email}</strong>
                    </div>
                  )}

                  {hasProfileSettings && (
                    <div className="flex flex-col items-start px-4 py-2 border-t-2 border-gray-dark w-full">
                      <strong className="mb-2">Einstellungen</strong>
                      <Link
                        className="ml-2 hover:border-b-2 hover:border-red text-black-abs mb-1"
                        to={routes.profile}
                      >
                        Nutzerprofil
                      </Link>
                      <div
                        role="link"
                        tabIndex={0}
                        className="cursor-pointer ml-2 hover:border-b-2 hover:border-red mb-1 text-black -abs"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsNotificationOpen(true);
                        }}
                        onKeyDown={(e) => {
                          if (e.key !== 'Enter') return;
                          e.stopPropagation();
                          setIsNotificationOpen(true);
                        }}
                      >
                        Benachrichtigungen
                      </div>
                      <Link
                        className="ml-2 hover:border-b-2 hover:border-red text-black -abs mb-1"
                        to="/settings"
                      >
                        Adresse
                      </Link>
                    </div>
                  )}

                  <div className="flex flex-col items-start px-4 py-2 border-t-2 w-full border-gray-dark">
                    <strong className="mb-2">Rechtliches</strong>
                    <a
                      className="hover:border-b-2 hover:border-red text-black-abs mx-2 mb-1"
                      target="_blank"
                      href="/privacy"
                    >
                      Impressum & Datenschutz
                    </a>
                    <a
                      className="hover:border-b-2 hover:border-red text-black-abs mx-2 mb-1"
                      target="_blank"
                      href="/terms-of-service"
                    >
                      Nutzungsbedingungen
                    </a>
                  </div>

                  <div
                    className={`flex w-full p-4 items-start justify-center ${
                      userInfo?.email && 'border-t-2 border-gray-dark'
                    }`}
                  >
                    <div className="flex items-center justify-center">
                      <Button
                        onClick={() => logout()}
                        icon={<FaSignOutAlt />}
                        className="secondary-button small-button "
                        label="Abmelden"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </button>
        </div>
      )}
    </div>
  ) : null;
};

export default HeaderLogout;
