import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import Button from '../../Basics/Buttons/Button';
import HintLink from '../../Basics/HintLink';
import { UnitInputField } from '../../index';

const LadderSystemAssistanceValidationSchema = Yup.object().shape({
  buildingHeight: Yup.number().min(1).required(''),
});

const LadderSystemAssistance: React.FC<
  React.PropsWithChildren<{
    index: number;
    onClose: () => void;
  }>
> = ({ index, onClose }) => {
  const { setFieldValue } = useFormikContext<FormikMaintenanceWizardValues>();

  const calculateLadderLength = (buildingHeight) => {
    setFieldValue(`maintenanceObjects.${index}.length`, buildingHeight + 1);
    setFieldValue(
      `maintenanceObjects.${index}.hasUsedHeightAssistanceCalculator`,
      true
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-1 flex-col">
        <FaArrowLeft
          className="flex flex-1 text-2xl cursor-pointer"
          onClick={() => {
            onClose();
          }}
        />

        <div className="flex flex-col w-full mt-6 items-center text-center mb-12">
          <strong className="mb-6">
            Du hast die Länge der Leiter nicht vorliegen?
          </strong>
          <div>
            Mithilfe von Informationen zur Höhe des Gebäudes, an der sich die
            Leiter befindet, können wir die Länge der Steigleiter automatisiert
            ermitteln. Bitte beachte, dass die ermittelten Werte eine Schätzung
            sind.
          </div>
        </div>
      </div>

      <Formik
        validationSchema={LadderSystemAssistanceValidationSchema}
        initialValues={{
          buildingHeight: undefined,
        }}
        validateOnMount
        onSubmit={(values) => {
          if (values.buildingHeight) {
            calculateLadderLength(values.buildingHeight);
            toast.success('Geschätzte Werte wurden eingefügt.', {
              position: 'top-center',
            });
          }
          onClose();
        }}
      >
        {({ isValid, submitForm, errors, dirty, values }) => (
          <>
            <div className="flex flex-col w-full flex-start px-8">
              <strong>Information zum Gebäude</strong>
              <UnitInputField
                name="buildingHeight"
                label="Gebäudehöhe"
                unit="m"
                isLabelBold={false}
                labelWidthTailwindClass="w-80"
              />
            </div>

            <div className="flex flex-col w-full items-center mt-4">
              <HintLink hintLabel="Du hast die Gebäudehöhe nicht vorliegen?">
                <div className="mt-2">
                  <p className="mb-2">
                    Kein Problem, gemeinsam finden wir sicherlich eine Lösung.
                  </p>
                  <p>
                    Kontaktiere uns unter:
                    <br />
                    <span className="mr-1">+49 201 7475985-730</span> |
                    <a
                      href="mailto:inspektion@absturzsicherung.de"
                      className="ml-1 text-blue-abs"
                    >
                      inspektion@absturzsicherung.de
                    </a>
                  </p>
                </div>
              </HintLink>
            </div>

            <div className="flex w-full justify-center mt-4">
              <Button
                label="Ok"
                disabled={!isValid}
                onClick={() => {
                  if (!isValid) {
                    toast.error(
                      'Es konnten keine geschätzten Werte eingefügt werden.',
                      {
                        position: 'top-center',
                      }
                    );
                    onClose();
                  }
                  submitForm();
                }}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default LadderSystemAssistance;
