import React from 'react';

import { MaintenancePriceDto } from '@wartungshelden/shared-types';

import { Card } from '../../../../../components';
import TotalPricePosition from './TotalPricePosition';

interface PriceOverviewRowProps {
  priceWithoutDocu: MaintenancePriceDto;
  priceWithDocu: MaintenancePriceDto;
  showPriceWithoutDocu: boolean;
}

const TotalPriceOverviewRow: React.FC<PriceOverviewRowProps> = ({
  priceWithoutDocu,
  priceWithDocu,
  showPriceWithoutDocu,
}) => {
  return (
    <Card paddingX={0} paddingY={0}>
      <div className="flex bg-gray-dark flex-row justify-between items-center p-2 font-bold">
        GESAMT
      </div>
      <div className="flex">
        <div className="w-1/3 bg-gray-light border-r-2" />

        <div className="w-2/3 flex">
          <div className="flex flex-col w-1/2 py-2 pl-4 pr-4 border-r-2 border-dashed">
            <TotalPricePosition
              price={priceWithDocu}
              hasDocu={showPriceWithoutDocu ? true : undefined}
            />
          </div>
          <div className="flex flex-col w-1/2 p-2 pl-4">
            {showPriceWithoutDocu && (
              <TotalPricePosition price={priceWithoutDocu} hasDocu={false} />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

TotalPriceOverviewRow.defaultProps = {};

export default TotalPriceOverviewRow;
