import React, { useContext } from 'react';

import {
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { SessionContext } from '../../../contexts/SessionContext';
import { useMaintenanceDocumentationFile } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import IsDocumentValidTip from '../../Maintenance/MaintenanceWizard/IsDocumentValidTip';
import DeleteFile from '../../Maintenance/MaintenanceWizard/Upload/DeleteFile';
import Loader from '../Loaders/Loader';
import FilePreviewComponent from './File/FilePreviewComponent';

type DocumentPreviewProps = {
  maintenanceObject?: MaintenanceObjectDto;
  maintenanceDocument: MaintenanceDocumentationDto;
  showDocumentValidity?: boolean;
  isDocumentUploadEnabled?: boolean;
  deleteFile: () => void;
};

const DocumentPreview: React.FC<
  React.PropsWithChildren<DocumentPreviewProps>
> = ({
  maintenanceObject,
  maintenanceDocument,
  showDocumentValidity,
  isDocumentUploadEnabled,
  deleteFile,
}) => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);
  const { isLoading, data: loadedFileFromDocument } =
    useMaintenanceDocumentationFile(
      maintenanceDocument.id,
      true,
      isMaintenanceTeamMemberAdmin ? maintenanceObject?.ownerId : undefined
    );

  const documentIsValid = Boolean(
    maintenanceDocument &&
      maintenanceObject?.id &&
      maintenanceDocument.validFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.invalidFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.notRelevantFor?.includes(maintenanceObject.id)
  );

  const documentNotValidated = Boolean(
    maintenanceDocument &&
      maintenanceObject?.id &&
      !maintenanceDocument.validFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.invalidFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.notRelevantFor?.includes(maintenanceObject.id)
  );
  return (
    <div className="flex h-full w-full bg-gray text-gray-abs relative">
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {loadedFileFromDocument &&
            isDocumentUploadEnabled &&
            documentNotValidated && (
              <DeleteFile
                file={loadedFileFromDocument}
                disabled={false}
                deleteFile={deleteFile}
              />
            )}
          <FilePreviewComponent file={loadedFileFromDocument} />
          {showDocumentValidity && !documentNotValidated && (
            <div className="absolute -top-2 -right-2">
              <IsDocumentValidTip isValid={documentIsValid} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

DocumentPreview.defaultProps = {
  isDocumentUploadEnabled: false,
  showDocumentValidity: false,
  maintenanceObject: undefined,
};

export default DocumentPreview;
