import * as countries from 'i18n-iso-countries';
import React from 'react';

import { BillingAddressDto } from '@wartungshelden/shared-types';

import TextWithLabel from '../Basics/TextWithLabel';

interface AddressReadOnlyProps {
  addresses: BillingAddressDto[];
  email?: string | null;
  phoneNumber?: string | null;
}

const AddressReadOnly: React.FC<
  React.PropsWithChildren<AddressReadOnlyProps>
> = ({ addresses, email, phoneNumber }) => {
  return (
    <>
      {addresses.map((address) => (
        <div
          key={`${address.firstName}-${address.lastName}-${address.company}-${address.address.addressLine1}`}
        >
          <TextWithLabel label="Firma:">{address?.company}</TextWithLabel>
          <TextWithLabel label="Vorname:">{address?.firstName}</TextWithLabel>
          <TextWithLabel label="Nachname:">{address?.lastName}</TextWithLabel>
          <TextWithLabel label="Straße, Hausnummer:">
            {address?.address.addressLine1}
          </TextWithLabel>
          <TextWithLabel label="Postleitzahl:">
            {address?.address.postalCode}
          </TextWithLabel>
          <TextWithLabel label="Stadt:">{address?.address.city}</TextWithLabel>
          <TextWithLabel label="Land:">
            {address.address.countryISO
              ? countries.getName(address.address.countryISO, 'de')
              : ''}
          </TextWithLabel>
        </div>
      ))}
      <div className="mt-6 mb-4 font-bold">
        An wen sollen wir uns bei eventuellen Rückfragen zu dieser Anfrage
        wenden?
      </div>
      <TextWithLabel label="Email:">{email}</TextWithLabel>
      <TextWithLabel label="Telefonnummer:">{phoneNumber}</TextWithLabel>
    </>
  );
};

AddressReadOnly.defaultProps = {
  email: undefined,
  phoneNumber: undefined,
};

export default AddressReadOnly;
