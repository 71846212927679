import { useFormikContext } from 'formik';
import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { PPEType } from '@wartungshelden/shared-types';

import {
  FORMIK_SELECT_DEFAULT_VALUE,
  PPE_MANUFACTURERS,
} from '../../../constants/MaintenanceConstants';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import DropdownFieldLayout from '../../Basics/Inputs/DropdownFieldLayout';
import UnitInputField from '../../Basics/Inputs/UnitInputField';
import { InputField } from '../../index';

const InventoryPPERow: React.FC<
  React.PropsWithChildren<{ index: number; disabled: boolean }>
> = ({ index, disabled }) => {
  const { values, setFieldValue } =
    useFormikContext<FormikMaintenanceWizardValues>();

  const { maintenanceObjectId } = useParams();

  const getManufacturersByPpeType = () => {
    if (
      [
        PPEType.DOME_COMPLETE,
        PPEType.DOME_FABRIC_THREAD,
        PPEType.DOME_FIX,
        PPEType.HELMET,
        PPEType.RESCUE_DESCENDER,
      ].includes(
        // @ts-ignore
        values.maintenanceObjects[index].systemType
      )
    ) {
      return ['ABS Safety'];
    }
    return PPE_MANUFACTURERS;
  };

  const clearRowValues = () => {
    setFieldValue(`maintenanceObjects.${index}.quantity`, 0);
    setFieldValue(`maintenanceObjects.${index}.manufacturer`, '');
  };

  const systemTypeNotPicked = (idx) =>
    // @ts-ignore
    values.maintenanceObjects[idx].systemType === FORMIK_SELECT_DEFAULT_VALUE;

  return (
    <>
      <div className="w-full">
        <DropdownFieldLayout
          name={`maintenanceObjects.${index}.systemType`}
          options={[...Object.values(PPEType)]}
          disabled={disabled}
          onSelect={(value) => {
            clearRowValues();
            setFieldValue(`maintenanceObjects.${index}.systemType`, value);
          }}
        />
      </div>
      <div>
        <DropdownFieldLayout
          name={`maintenanceObjects.${index}.manufacturer`}
          disabled={disabled || systemTypeNotPicked(index)}
          options={getManufacturersByPpeType()}
          searchable
        />
      </div>
      <div>
        <div>
          <UnitInputField
            key={`maintenanceObjects.${index}.quantity`}
            name={`maintenanceObjects.${index}.quantity`}
            unit="Stück"
            disabled={disabled || systemTypeNotPicked(index)}
          />
        </div>
      </div>
      <div>
        <InputField
          name={`maintenanceObjects.${index}.name`}
          help="Wenn Du möchtest, kannst Du hier eine Beschreibung hinzufügen. Dies kann eine Inventarnummer oder eine andere bei Euch übliche Bezeichnung sein."
          placeholder="Beschreibung eingeben..."
          disabled={disabled}
        />
      </div>
      <div className="w-full flex items-center justify-end">
        {!disabled && !maintenanceObjectId && (
          <button
            className={`ml-8 ${disabled && 'cursor-default'}`}
            type="button"
            onClick={() => {
              if (!disabled) {
                const tempMaintenanceObjects = values.maintenanceObjects;
                tempMaintenanceObjects.splice(index, 1);
                setFieldValue('maintenanceObjects', tempMaintenanceObjects);
              }
            }}
          >
            <FaTrashAlt
              size={20}
              className="text-gray-light group-hover:text-black-abs"
            />
          </button>
        )}
      </div>
    </>
  );
};

export default InventoryPPERow;
