import {
  MaintenanceTypeDto,
  ProvidedServiceEnum,
} from '@wartungshelden/shared-types';

import { providedServiceToLabel } from '../pages/UserPages/maintenance/MaintenanceObject';

export const FALL_PROTECTION_SYSTEM_LOCATION = {
  ROOF: { label: 'Dachfläche', value: 'Roof' },
  OVERHEAD: { label: 'über Kopf/ Decke (über 2,20m Höhe)', value: 'Overhead' },
  FACADE: { label: 'Fassade / Wand', value: 'Facade' },
};

export const PSAGA_SYSTEM_TYPES = {
  SLING: {
    label: 'Anschlagband / Bandschlinge',
    value: 'SLING',
    disabled: false,
  },
  BELT: { label: 'Auffanggurt', value: 'BELT', disabled: false },
  HELMET: { label: 'Comfort Helmet', value: 'HELMET', disabled: false },
  DOME_COMPLETE: {
    label: 'DomeWeb (komplett)',
    value: 'DOME_COMPLETE',
    disabled: false,
  },
  DOME_FIX: {
    label: 'DomeFix',
    value: 'DOME_FIX',
    disabled: false,
  },
  DOME_FABRIC_THREAD: {
    label: 'DomeWeb (Gewebefaden)',
    value: 'DOME_FABRIC_THREAD',
    disabled: false,
  },
  DAMPER: { label: 'Falldämpfer', value: 'DAMPER', disabled: false },
  GLIDER: { label: 'Gleiter', value: 'GLIDER', disabled: false },
  RESCUE_DESCENDER: {
    label: 'Rettungshub- und Abseilgerät',
    value: 'RESCUE_DESCENDER',
    disabled: false,
  },
  PPE_SET: { label: 'PSAgA-Set', value: 'PPE_SET', disabled: false },
  TEMP_ROPE: {
    label: 'Verbindungsmittel/mitl. Auffanggerät/temp. Seilsystem',
    value: 'TEMP_ROPE',
    disabled: false,
  },
  CLIMBING_PROTECTION_GLIDER: {
    label: 'Steigschutzläufer',
    value: 'CLIMBING_PROTECTION_GLIDER',
    disabled: false,
  },
};

export const SINGLE_PILLAR_FIELD = {
  name: 'scope.fallProtection[0].singlePillars',
  label: 'Einzelanschlagpunkte',
  unit: 'Stück',
  field: 'singlePillars',
};

export const FALL_PROTECTION_FIELDS = [
  {
    name: 'scope.fallProtection[0].ropeLength',
    label: 'Seillänge',
    unit: 'm',
    field: 'ropeLength',
  },
  {
    name: 'scope.fallProtection[0].systemPillars',
    label: 'Systemstützen',
    unit: 'Stück',
    field: 'systemPillars',
  },
  SINGLE_PILLAR_FIELD,
];

export const LOCK_II_FIELDS = [
  {
    name: 'scope.fallProtection[0].lock1',
    label: 'Lock-I',
    unit: 'Stück',
    field: 'lock1',
  },
  {
    name: 'scope.fallProtection[0].lock2',
    label: 'Lock-II',
    unit: 'Stück',
    field: 'lock2',
  },
];

export const HAS_DOCS_ENUM = {
  YES: 'YES',
  NO: 'NO',
  UNKNOWN: 'UNKNOWN',
};

export type FpManufacturerType = [
  'ABS Safety',
  'AccessGroup',
  'AMH Unianker',
  'Antec',
  'AS-FIX-2000',
  'BreGuSave',
  'Breuer Sicherheitskonzepte',
  'BUG ASS',
  'DIADEM',
  'Essmann',
  'GRÜN',
  'GüFa',
  'HADEF',
  'Halfmann Dachanker',
  'Innotech',
  'JOBA',
  'Kalzip',
  'KFG Klause Fischer GmbH',
  'Latchways',
  'LuxTop',
  'Meissner Schiene',
  'Optigrün',
  'POHL',
  'Polybit',
  'RUSEC',
  'Rusitec',
  'SALA',
  'SBO',
  'Schierling',
  'Sideka',
  'Skylotec',
  'Söll',
  'Spanset',
  'Tractel',
  'Zinco Fallnet',
  'unbekannter Hersteller',
  'anderer Hersteller'
];

export const MANUFACTURERS: FpManufacturerType = [
  'ABS Safety',
  'AccessGroup',
  'AMH Unianker',
  'Antec',
  'AS-FIX-2000',
  'BreGuSave',
  'Breuer Sicherheitskonzepte',
  'BUG ASS',
  'DIADEM',
  'Essmann',
  'GRÜN',
  'GüFa',
  'HADEF',
  'Halfmann Dachanker',
  'Innotech',
  'JOBA',
  'Kalzip',
  'KFG Klause Fischer GmbH',
  'Latchways',
  'LuxTop',
  'Meissner Schiene',
  'Optigrün',
  'POHL',
  'Polybit',
  'RUSEC',
  'Rusitec',
  'SALA',
  'SBO',
  'Schierling',
  'Sideka',
  'Skylotec',
  'Söll',
  'Spanset',
  'Tractel',
  'Zinco Fallnet',
  'unbekannter Hersteller',
  'anderer Hersteller',
];

export type PpeManufacturerType =
  | [
      'ABS Safety',
      'Artex',
      'Bornack',
      'Innotech',
      'Ikar',
      'MAS',
      'Miller',
      'Petzl',
      'Skylotec',
      'Tractel',
      'anderer Hersteller',
      'unbekannter Hersteller'
    ]
  | ['ABS Safety'];

export const PPE_MANUFACTURERS: PpeManufacturerType = [
  'ABS Safety',
  'Artex',
  'Bornack',
  'Innotech',
  'Ikar',
  'MAS',
  'Miller',
  'Petzl',
  'Skylotec',
  'Tractel',
  'anderer Hersteller',
  'unbekannter Hersteller',
];

export enum FallProtectionSystems {
  ROPE = 'Seilsystem',
  PUSH = 'Lock I / Lock II',
  SINGLE = 'Einzelanschlagpunkte',
}

export const FORMIK_SELECT_DEFAULT_VALUE = 'default';

export interface ReadableServiceType {
  readonly type: string;
  readonly label: string;
}

export const readableServiceType = (
  serviceType: MaintenanceTypeDto
): ReadableServiceType => {
  if (serviceType.type === 'other_type') {
    return {
      type: serviceType.type,
      label:
        providedServiceToLabel(serviceType.name) === 'other_type'
          ? serviceType.name
          : providedServiceToLabel(serviceType.name),
    };
  }
  return {
    type: serviceType.type,
    label: providedServiceToLabel(serviceType.type),
  };
};

export const isServiceAlreadySupported = (serviceType: string) =>
  serviceType === ProvidedServiceEnum.FALL_PROTECTION ||
  serviceType === ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT ||
  serviceType === ProvidedServiceEnum.LADDER;
