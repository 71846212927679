import * as Yup from 'yup';

import {
  FallProtectionSystemDto,
  ProvidedServiceEnum,
} from '@wartungshelden/shared-types';

import { labelToProvidedService } from '../pages/UserPages/maintenance/MaintenanceObject';
import {
  FALL_PROTECTION_SYSTEM_LOCATION,
  FORMIK_SELECT_DEFAULT_VALUE,
  FallProtectionSystems,
} from './MaintenanceConstants';

export const wizardStep1ValidationSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  buildingId: Yup.string().required('Required'),
  dueDate: Yup.string().notRequired(),
  frequency: Yup.number().min(1).notRequired(),
});

export const wizardStep2ValidationSchema = Yup.object().shape({
  type: Yup.string().required(),
  maintenanceObjects: Yup.array().when('type', {
    is: (type: string) => {
      return (
        labelToProvidedService(type) === ProvidedServiceEnum.FALL_PROTECTION
      );
    },
    then: Yup.array(
      Yup.object().shape({
        systemName: Yup.string().test(
          'is-valid-system-name',
          'system name is not valid',
          (value) => {
            return !(!value || value === FORMIK_SELECT_DEFAULT_VALUE);
          }
        ),
        manufacturer: Yup.string(),
        name: Yup.string(),
        location: Yup.string().when('systemName', {
          is: (system) => system === FallProtectionSystems.PUSH,
          then: Yup.string().oneOf([
            FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value,
            FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value,
            FORMIK_SELECT_DEFAULT_VALUE,
          ]),
          otherwise: Yup.string().oneOf([
            FALL_PROTECTION_SYSTEM_LOCATION.ROOF.value,
            FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value,
            FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value,
            FORMIK_SELECT_DEFAULT_VALUE,
          ]),
        }),
        system: Yup.object().when('systemName', {
          is: (systemName: string) => systemName === FallProtectionSystems.ROPE,
          then: Yup.object().shape({
            ropeLength: Yup.number(),
            systemAnchors: Yup.number(),
            singleAnchors: Yup.number(),
          }),
          otherwise: Yup.object().when('systemName', {
            is: (systemName: string) =>
              systemName === FallProtectionSystems.PUSH,
            then: Yup.object().shape({
              lock1: Yup.number(),
              lock2: Yup.number(),
            }),
            otherwise: Yup.object().shape({
              singleAnchors: Yup.number(),
            }),
          }),
        }),
      })
    ),
    otherwise: Yup.array().when('type', {
      is: (type: string) => {
        return (
          labelToProvidedService(type) ===
          ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT
        );
      },
      then: Yup.array(
        Yup.object().shape({
          systemType: Yup.string().test(
            'is-valid-system-type',
            'system type is not valid',
            (value) => {
              return !(!value || value === FORMIK_SELECT_DEFAULT_VALUE);
            }
          ),
          name: Yup.string(),
        })
      ),
    }),
  }),
});

export const userNotificationValidationSchema = Yup.object().shape({
  enableReminder: Yup.boolean().required(),
  reminderEmail: Yup.string().email().when('enableReminder', {
    is: true,
    then: Yup.string().email().required(),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  reminderDaysInAdvance: Yup.number().when('enableReminder', {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable().notRequired(),
  }),
  enableStatusUpdates: Yup.boolean().required(),
});

export const requestableMaintenanceObjectValidationSchema = Yup.object().shape({
  id: Yup.string().uuid().required(),
  ownerId: Yup.string().required(),
  // dueDate: Yup.date().required(),
  manufacturer: Yup.string().when('type.type', {
    is: (type) =>
      labelToProvidedService(type) === ProvidedServiceEnum.FALL_PROTECTION ||
      labelToProvidedService(type) ===
        ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT,
    then: (schema) => schema.notOneOf([FORMIK_SELECT_DEFAULT_VALUE]).required(),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  type: Yup.object()
    .shape({
      type: Yup.string(),
      system: Yup.object().when('type', {
        is: (type: string) =>
          labelToProvidedService(type) === 'fall_protection',
        then: Yup.object()
          .shape({
            system: Yup.string().oneOf(['rope', 'push_lock', 'single_anchor']),
            singleAnchors: Yup.number().when('system', {
              is: (system: string) => system === 'single_anchor',
              then: Yup.number().min(1).required(),
              otherwise: Yup.number().nullable().notRequired(),
            }),
            ropeLength: Yup.number().when('system', {
              is: (system: string) => system === 'rope',
              then: Yup.number().min(1).required(),
              otherwise: Yup.number().nullable().notRequired(),
            }),
            systemAnchors: Yup.number().when('system', {
              is: (system: string) => system === 'rope',
              then: Yup.number().min(1).required(),
              otherwise: Yup.number().nullable().notRequired(),
            }),
            anchors: Yup.number().when('system', {
              is: (system: string) => system === 'push_lock',
              then: Yup.number().when('sleeves', {
                is: (sleeve: number) => sleeve > 0,
                then: Yup.number().nullable().notRequired(),
                otherwise: Yup.number().min(1).required(),
              }),
            }),
            sleeves: Yup.number().when('system', {
              is: (system: string) => system === 'push_lock',
              then: Yup.number().when('anchors', {
                is: (anchor: number) => anchor > 0,
                then: Yup.number().nullable().notRequired(),
                otherwise: Yup.number().min(1).required(),
              }),
            }),
          })
          .required(),
        otherwise: Yup.object().nullable().notRequired(),
      }),
      location: Yup.string().when('type', {
        is: (type: string) =>
          labelToProvidedService(type) === 'fall_protection',
        then: Yup.string().when('system', {
          is: (system: FallProtectionSystemDto) => {
            if (!system) return false;
            return system.system === 'push_lock';
          },
          then: Yup.string().oneOf(['Overhead', 'Facade']),
          otherwise: Yup.string().oneOf(['Roof', 'Overhead', 'Facade']),
        }),
        otherwise: Yup.string().nullable().notRequired(),
      }),
      access: Yup.array().when('type', {
        is: (type: string) =>
          labelToProvidedService(type) === 'fall_protection',
        then: Yup.array()
          .min(1)
          .required()
          .test((accesses) => {
            return (
              accesses?.find((access) => access.trim().length === 0) ===
              undefined
            );
          }),
        otherwise: Yup.array().nullable().notRequired(),
      }),
      systemType: Yup.string().when('type', {
        is: (type: string) =>
          labelToProvidedService(type) === 'personal_protection_equipment',
        then: Yup.string()
          .required()
          .oneOf([
            'SLING',
            'BELT',
            'HELMET',
            'DOME_COMPLETE',
            'DOME_FABRIC_THREAD',
            'DOME_FIX',
            'DAMPER',
            'GLIDER',
            'RESCUE_DESCENDER',
            'PPE_SET',
            'TEMP_ROPE',
          ]),
        otherwise: Yup.string().nullable().notRequired(),
      }),
      quantity: Yup.number().when('type', {
        is: (type: string) =>
          labelToProvidedService(type) === 'personal_protection_equipment',
        then: Yup.number().min(1).required('Required'),
        otherwise: Yup.number().nullable().notRequired(),
      }),
      parts: Yup.array().when('type', {
        is: (type: string) => labelToProvidedService(type) === 'ladder',
        then: Yup.array().of(Yup.number()).min(1),
        otherwise: Yup.array().of(Yup.number()).nullable().notRequired(),
      }),
    })
    .required(),
});

export const maintenanceObjectsInRequestValidationSchema = Yup.object().shape({
  type: Yup.string().required(),
  // dueDate: Yup.string().required(),
  maintenanceObjects: Yup.array()
    .when('type', {
      is: (type: string) => {
        return (
          labelToProvidedService(type) === ProvidedServiceEnum.FALL_PROTECTION
        );
      },
      then: Yup.array(
        Yup.object()
          .shape({
            systemName: Yup.string()
              .notOneOf([FORMIK_SELECT_DEFAULT_VALUE])
              .required(),
            manufacturer: Yup.string()
              .notOneOf([FORMIK_SELECT_DEFAULT_VALUE])
              .required(),
            ladder: Yup.boolean(),
            climbLadder: Yup.boolean(),
            liftingPlatform: Yup.boolean(),
            scaffold: Yup.boolean(),
            roofExit: Yup.boolean(),
            other: Yup.boolean(),
            otherText: Yup.string().when('other', {
              is: (other) => other === true,
              then: Yup.string().trim().required(),
            }),
            location: Yup.string().when('systemName', {
              is: (system) => system === FallProtectionSystems.PUSH,
              then: Yup.string()
                .oneOf([
                  FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value,
                  FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value,
                ])
                .required(),
              otherwise: Yup.string()
                .oneOf([
                  FALL_PROTECTION_SYSTEM_LOCATION.ROOF.value,
                  FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value,
                  FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value,
                ])
                .required(),
            }),
            system: Yup.object().when('systemName', {
              is: (systemName: string) =>
                systemName === FallProtectionSystems.ROPE,
              then: Yup.object().shape({
                ropeLength: Yup.number().min(1).required(),
                systemAnchors: Yup.number().min(1).required(),
                singleAnchors: Yup.number(),
              }),
              otherwise: Yup.object().when('systemName', {
                is: (systemName: string) =>
                  systemName === FallProtectionSystems.PUSH,
                then: Yup.object().when('system.lock1', {
                  is: (lock1: number) => lock1 > 0,
                  then: Yup.object().shape({
                    lock1: Yup.number().min(1).required(),
                    lock2: Yup.number(),
                  }),
                  otherwise: Yup.object().shape({
                    lock1: Yup.number(),
                    lock2: Yup.number().min(1).required(),
                  }),
                }),
                otherwise: Yup.object().when('systemName', {
                  is: (systemName: string) =>
                    systemName === FallProtectionSystems.SINGLE,
                  then: Yup.object().shape({
                    singleAnchors: Yup.number().min(1).required(),
                  }),
                  otherwise: Yup.object().required(),
                }),
              }),
            }),
          })
          .test('oneOf', (object, testContext) => {
            if (
              object.ladder ||
              object.climbLadder ||
              object.liftingPlatform ||
              object.scaffold ||
              object.roofExit ||
              object.other
            ) {
              return true;
            }
            return testContext.createError({
              path: 'access',
              message: 'Must have a access method',
            });
          })
      ),
      otherwise: Yup.array().when('type', {
        is: (type: string) => {
          return (
            labelToProvidedService(type) ===
            ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT
          );
        },
        then: Yup.array(
          Yup.object().shape({
            manufacturer: Yup.string()
              .notOneOf([FORMIK_SELECT_DEFAULT_VALUE])
              .required(),
            systemType: Yup.string()
              .required()
              .notOneOf([FORMIK_SELECT_DEFAULT_VALUE]),
            quantity: Yup.number().min(1).required('Required'),
          })
        ),
        otherwise: Yup.array().when('type', {
          is: (type: string) => {
            return labelToProvidedService(type) === ProvidedServiceEnum.LADDER;
          },
          then: Yup.array(
            Yup.object().shape({
              length: Yup.number().min(1).required('Required'),
            })
          ),
        }),
      }),
    })
    .min(1),
});
