import { Combobox } from '@headlessui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { FORMIK_SELECT_DEFAULT_VALUE } from '../../../constants/MaintenanceConstants';

export type DropdownFieldOption = {
  label: string;
  value: string;
  disabled: boolean;
};

type DropdownFieldProps = {
  options: string[] | DropdownFieldOption[] | Record<string, string>;
  name?: string;
  onSelect?: ((value: any) => void) | null;
  disabled?: boolean;
  smallSize?: boolean;
  defaultOption?: string;
};

const DropdownField: React.FC<React.PropsWithChildren<DropdownFieldProps>> = ({
  options,
  name,
  onSelect,
  disabled,
  smallSize,
  defaultOption,
}) => {
  const { setFieldValue, status, getFieldProps } = useFormikContext();
  const errorKeys = status ? Object.keys(status) : [];

  const getRecordsFromArray = (
    availableOptions: string[] | DropdownFieldOption[]
  ) => {
    return availableOptions.map((option) => {
      const label = typeof option === 'string' ? option : option.label;
      const value = typeof option === 'string' ? option : option.value;
      const isDisabled = typeof option === 'string' ? false : option.disabled;

      return (
        <Combobox.Option
          disabled={isDisabled}
          key={value}
          className={({ active }) =>
            `relative cursor-default select-none py-2 px-4 ${
              active ? 'bg-blue-abs text-white' : 'text-gray-900'
            }
            ${isDisabled && 'text-gray-300'}`
          }
          value={value}
        >
          {label}
        </Combobox.Option>
      );
    });
  };

  const getRecordsFromMap = (availableOptions: Record<string, string>) => {
    return Object.entries(availableOptions).map(([key, value]) => {
      return (
        <Combobox.Option
          key={key}
          className={({ active }) =>
            `relative cursor-default select-none py-2 px-4 ${
              active ? 'bg-blue-abs text-white' : 'text-gray-900'
            }`
          }
          value={value}
        >
          {key}
        </Combobox.Option>
      );
    });
  };

  const getRecords = () => {
    if (Array.isArray(options)) {
      return getRecordsFromArray(options);
    }
    return getRecordsFromMap(options);
  };

  const getSelectedOptionLabel = () => {
    const fieldValue = getFieldProps(name ?? '').value;

    if (Array.isArray(options)) {
      if (typeof options[0] === 'string') {
        return fieldValue;
      }

      return (
        (options as DropdownFieldOption[])?.find(
          (option) => option.value === fieldValue
        )?.label ?? fieldValue
      );
    }

    const selectedOption = Object.entries(options).find(
      ([key, value]) => value === fieldValue
    );

    return selectedOption ? selectedOption[0] : fieldValue;
  };

  return (
    <>
      <Combobox
        data-cy="components-basics-combo-box"
        name={name}
        data-name={name}
        disabled={disabled}
        value={getFieldProps(name ?? '').value}
        onChange={(value) => {
          if (onSelect) {
            onSelect(value);
          } else if (name) {
            setFieldValue(name, value);
          }
        }}
      >
        <div
          className={`relative w-full ${smallSize ? 'text-sm' : 'text-base'}`}
        >
          <div className="relative w-full border-2 box-border rounded-lg border-gray-light cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-600">
            <Combobox.Button
              data-cy="components-basics-combo-box-button"
              className={`flex w-full items-center border-none py-2 px-4 leading-5 focus:ring-0 ${
                smallSize ? 'h-8' : 'h-12'
              } ${
                disabled ? 'text-gray-dark bg-gray-lighter' : 'text-gray-900'
              }`}
            >
              {getFieldProps(name ?? '').value === 'default'
                ? 'Auswählen...'
                : getSelectedOptionLabel()}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <FaChevronDown
                  className={`${disabled ? 'text-gray-300' : 'text-gray-dark'}`}
                />
              </div>
            </Combobox.Button>
          </div>
          <Combobox.Options className="absolute mt-1 max-h-60 w-full z-10 overflow-auto rounded-md bg-white py-1 border-2 box-border rounded-lg border-gray-light focus:outline-none">
            <Combobox.Option
              disabled
              value={FORMIK_SELECT_DEFAULT_VALUE}
              key="placeholder"
              className={({ active }) =>
                `relative cursor-default select-none py-2 px-4 ${
                  active ? 'bg-blue-abs text-white' : 'text-gray-900'
                }`
              }
            >
              {defaultOption || 'Auswählen...'}
            </Combobox.Option>
            {getRecords()}
          </Combobox.Options>
        </div>
      </Combobox>
      <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center text-gray-700" />
    </>
  );
};
export default DropdownField;

DropdownField.defaultProps = {
  onSelect: null,
  disabled: false,
  name: undefined,
  smallSize: false,
  defaultOption: undefined,
};
