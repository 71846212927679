import {
  eachDayOfInterval,
  endOfDay,
  endOfYear,
  startOfDay,
  startOfYear,
} from 'date-fns';
import React from 'react';

import { InstallerDto } from '@wartungshelden/shared-types';

import DateSelectField from '../../../../../components/Basics/Inputs/DateSelection/DateSelectField';
import Loader from '../../../../../components/Basics/Loaders/Loader';
import { useInstallerAppointments } from '../../../../../services/api/ressource-planning/installers-api';
import './datePicker.css';

interface InstallerCalendarProps {
  selected: Date;
  installer?: InstallerDto;
  disableWeekend?: boolean;
}

const InstallerCalendar: React.FC<InstallerCalendarProps> = ({
  selected,
  disableWeekend,
  installer,
}) => {
  if (!installer) {
    return null;
  }

  const {
    data: installerMonthAppointments,
    isLoading: isLoadingInstallerMonthAppointments,
  } = useInstallerAppointments(
    installer.id,
    startOfDay(startOfYear(selected)),
    endOfDay(endOfYear(selected))
  );

  return isLoadingInstallerMonthAppointments ? (
    <div className="flex items-center justify-center">
      <Loader />
    </div>
  ) : (
    <DateSelectField
      onChange={() => {
        // Not implemented
      }}
      calendarClassName="installerCalendar"
      selected={selected}
      showInline
      disabled
      readonly
      disableWeekend={disableWeekend}
      highlightDates={installerMonthAppointments?.map((appointment) => {
        return {
          'bg-red-blocked hover:!bg-red-blocked rounded': eachDayOfInterval({
            start: appointment.startDate,
            end: appointment.endDate,
          }),
        };
      })}
    />
  );
};

InstallerCalendar.defaultProps = {
  disableWeekend: true,
  installer: undefined,
};

export default InstallerCalendar;
