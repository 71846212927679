import React from 'react';

import { MaintenanceObjectDto } from '@wartungshelden/shared-types';

import LoadingSpinner from '../../../../components/Basics/Loaders/LoadingSpinner';
import MaintenanceWizardFooter from '../../../../components/Maintenance/MaintenanceWizard/MaintenanceWizardFooter';
import MaintenanceWizardHeader from '../../../../components/Maintenance/MaintenanceWizard/MaintenanceWizardHeader';
import MaintenanceWizardMenu from '../../../../components/Maintenance/MaintenanceWizard/MaintenanceWizardMenu';
import { useMaintenanceOffersOfRequest } from '../../../../services/api/maintenance-offers/maintenance-offer-api';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import useSetFormikStateForError from './useSetFormikStateForError';

interface MaintenanceWizardUpdateFormProps {
  activeStep: number;
  setActiveStep: (state: number | ((state: number) => number)) => void;
  isMaintenanceReadOnly: boolean;
  isLoading: boolean;
  maintenanceObject?: MaintenanceObjectDto;
}

const MaintenanceWizardUpdateForm: React.FC<
  React.PropsWithChildren<MaintenanceWizardUpdateFormProps>
> = ({
  activeStep,
  setActiveStep,
  isMaintenanceReadOnly,
  maintenanceObject,
  isLoading,
}) => {
  useSetFormikStateForError();

  const { data: offers } = useMaintenanceOffersOfRequest(
    maintenanceObject?.currentRequestId
  );
  const currentOffer = offers && offers[0];

  const isReOpened = currentOffer?.maintenanceObjects?.some(
    (offerMaintenanceObject) =>
      Boolean(
        offerMaintenanceObject?.originalId &&
          maintenanceObject &&
          currentOffer?.objectsWithOpenDocumentUpload?.includes(
            maintenanceObject?.id
          )
      )
  );

  return (
    <>
      <div className="h-screen flex flex-1 flex-col overflow-hidden items-stretch">
        <MaintenanceWizardHeader />
        <div className="flex flex-1 bg-white overflow-hidden ">
          <MaintenanceWizardMenu
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          <div className="flex flex-1 flex-col">
            {activeStep === 1 && (
              <StepOne
                isMaintenanceReadOnly={isMaintenanceReadOnly}
                isCreate={false}
                isReOpened={isReOpened}
                requestId={currentOffer?.requestId}
                maintenanceId={maintenanceObject?.id}
              />
            )}
            {activeStep === 2 && (
              <StepTwo
                isMaintenanceReadOnly={isMaintenanceReadOnly}
                isUpdateForm={maintenanceObject !== undefined}
                maintenance={maintenanceObject}
                isReOpened={isReOpened}
                requestId={currentOffer?.requestId}
                maintenanceId={maintenanceObject?.id}
              />
            )}
          </div>
        </div>
        <MaintenanceWizardFooter
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </div>
      <LoadingSpinner isLoading={isLoading}>
        Upload läuft. Bitte warten.
      </LoadingSpinner>
    </>
  );
};
MaintenanceWizardUpdateForm.defaultProps = {
  maintenanceObject: undefined,
};
export default MaintenanceWizardUpdateForm;
