import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceOffer } from '../../maintenance-offer';

export class MustHaveDocumentAttached<
  T extends MaintenanceOffer
> extends DomainRule<T, T & { documentName: string }> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (!entity.documentName) {
      throw new DomainRuleNotSatisfiedError(MustHaveDocumentAttached);
    }
    return entity as T & { documentName: string };
  }
}
