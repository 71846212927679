import { Entity } from '@warthungshelden/domain/common';

export type MaintenanceRequestUpdate = Pick<MaintenanceRequest, 'id'> &
  Partial<MaintenanceRequest>;

export class MaintenanceRequest extends Entity {
  public readonly id: string;
  public readonly created: Date;
  public readonly updated: Date;
  public readonly status: MaintenanceRequestStatus;
  public readonly objectIds: string[];
  public readonly ownerId: string;

  public readonly dateRanges?: DateRange[] | null;
  public readonly asap: boolean;

  public readonly additionalInfo?: string | null;
  public readonly maintenanceTeamInternalNote?: string | null;

  constructor(config: {
    id: string;
    created: Date;
    updated: Date;
    ownerId: string;
    status: MaintenanceRequestStatus;
    offerIds?: string[] | null;
    objectIds: string[];
    dateRanges?: DateRange[] | null;
    asap: boolean;
    additionalInfo?: string | null;
    maintenanceTeamInternalNote?: string | null;
  }) {
    super();
    this.id = config.id;
    this.created = config.created;
    this.updated = config.updated;
    this.status = config.status;
    this.objectIds = config.objectIds;
    this.dateRanges = config.dateRanges;
    this.asap = config.asap;
    this.additionalInfo = config.additionalInfo;
    this.ownerId = config.ownerId;
    this.maintenanceTeamInternalNote = config.maintenanceTeamInternalNote;
  }
}

export class DateRange extends Entity {
  public readonly from: Date;
  public readonly to: Date;

  constructor(config: { from: Date; to: Date }) {
    super();
    this.from = config.from;
    this.to = config.to;
  }
}

export enum RequestStatusEnum {
  REQUESTED = 'requested',
  VALIDATING = 'in_validation',
  CANCELED = 'canceled',
  CLOSED = 'closed',
}

export type MaintenanceRequestStatus = `${RequestStatusEnum}`;
