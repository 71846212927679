import * as Yup from 'yup';

const priceCalculationValidationSchema = Yup.object().shape({
  building: Yup.object().when('object', {
    is: (object) => {
      return object?.type?.system?.system === 'push_lock';
    },
    then: Yup.object().shape({
      buildingType: Yup.string().required(),
    }),
    otherwise: Yup.object().nullable().notRequired(),
  }),
  amount: Yup.number().when('object.type.type', {
    is: (type) => ['ladder', 'stair_ladder', 'overpass'].includes(type),
    then: Yup.number().min(1).required(),
    otherwise: Yup.number().nullable().notRequired(),
  }),
  ladderLength: Yup.number().when('object.type.type', {
    is: (type) => type === 'ladder',
    then: Yup.number().min(1).max(30).required('Required'),
    otherwise: Yup.number().notRequired(),
  }),
  shouldAddLaddersToClimbingProtection: Yup.boolean().when('object.type.type', {
    is: (type) => type === 'climbing_protection',
    then: Yup.boolean().required(),
    otherwise: Yup.boolean().notRequired(),
  }),
  steps: Yup.number().when('object.type.type', {
    is: (type) => ['stair_ladder', 'overpass'].includes(type),
    then: Yup.number().min(1).required(),
    otherwise: Yup.number().nullable().notRequired(),
  }),
  object: Yup.object().shape({
    manufacturer: Yup.string().when('type.type', {
      is: 'fall_protection',
      then: Yup.string().when('type.system.system', {
        is: (system: string) => system === 'rope' || 'single_anchor',
        then: Yup.string().required(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
      otherwise: Yup.string().when('type.type', {
        is: (type: string) =>
          ['rail_system', 'climbing_protection'].includes(type),
        then: Yup.string().required(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
    type: Yup.object()
      .shape({
        type: Yup.string(),
        system: Yup.object().when('type', {
          is: 'fall_protection',
          then: Yup.object()
            .shape({
              system: Yup.string().oneOf([
                'rope',
                'push_lock',
                'single_anchor',
              ]),
              singleAnchors: Yup.number().when('system', {
                is: 'single_anchor',
                then: Yup.number().min(1).required(),
                otherwise: Yup.number().nullable().notRequired(),
              }),
              ropeLength: Yup.number().when('system', {
                is: 'rope',
                then: Yup.number().min(1).required(),
                otherwise: Yup.number().nullable().notRequired(),
              }),
              systemAnchors: Yup.number().when('system', {
                is: 'rope',
                then: Yup.number().min(1).required(),
                otherwise: Yup.number().nullable().notRequired(),
              }),
              anchors: Yup.number().when('system', {
                is: 'push_lock',
                then: Yup.number().when('sleeves', {
                  is: (sleeve: number) => sleeve > 0,
                  then: Yup.number().nullable().notRequired(),
                  otherwise: Yup.number().min(1).required(),
                }),
              }),
              sleeves: Yup.number().when('system', {
                is: 'push_lock',
                then: Yup.number().when('anchors', {
                  is: (anchor: number) => anchor > 0,
                  then: Yup.number().nullable().notRequired(),
                  otherwise: Yup.number().min(1).required(),
                }),
              }),
            })
            .required(),
          otherwise: Yup.object().nullable().notRequired(),
        }),
        location: Yup.string().when('type', {
          is: 'fall_protection',
          then: Yup.string().when('system.system', {
            is: 'rope',
            then: Yup.string().required(),
            otherwise: Yup.string().nullable().notRequired(),
          }),
          otherwise: Yup.string().nullable().notRequired(),
        }),
        systemType: Yup.string().when('type', {
          is: 'personal_protection_equipment',
          then: Yup.string()
            .required()
            .oneOf([
              'SLING',
              'BELT',
              'HELMET',
              'DOME_COMPLETE',
              'DOME_FABRIC_THREAD',
              'DOME_FIX',
              'DAMPER',
              'GLIDER',
              'RESCUE_DESCENDER',
              'PPE_SET',
              'TEMP_ROPE',
              'CLIMBING_PROTECTION_GLIDER',
            ]),
          otherwise: Yup.string().nullable().notRequired(),
        }),
        quantity: Yup.number().when('type', {
          is: 'personal_protection_equipment',
          then: Yup.number().min(1).required('Required'),
          otherwise: Yup.number().nullable().notRequired(),
        }),
        length: Yup.number().when('type', {
          is: (type) => ['rail_system', 'railing'].includes(type),
          then: Yup.number().min(1).required('Required'),
          otherwise: Yup.number().when('type', {
            is: 'climbing_protection',
            then: Yup.number().min(1).max(30).required('Required'),
            otherwise: Yup.number().nullable().notRequired(),
          }),
        }),
        numberOfPlatforms: Yup.number().when('type', {
          is: (type) => type === 'ladder',
          then: Yup.number().nullable().notRequired(),
        }),
        amount: Yup.number().when('type', {
          is: 'ground_stairs',
          then: Yup.number().min(1).required(),
          otherwise: Yup.number().nullable().notRequired(),
        }),
        brackets: Yup.number().when('type', {
          is: 'rail_system',
          then: Yup.number().min(1).required(),
          otherwise: Yup.number().nullable().notRequired(),
        }),
      })
      .required(),
  }),
});

export default priceCalculationValidationSchema;
