import React from 'react';

import TextWithLabel from '../../../components/Basics/TextWithLabel';
import { useGetCustomers } from '../../../services/api/customer/customer-api';

interface Address {
  company: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  city: string;
  countryISO: string;
  addressLine1: string;
  email?: string;
}

interface OfferAddressProps {
  offerOrOrderAddress: Address;
  customerId: string;
  absCustomerNumber?: string | null;
  showContactData?: boolean;
}

const OfferOrOrderAddress: React.FC<
  React.PropsWithChildren<OfferAddressProps>
> = ({
  offerOrOrderAddress,
  customerId,
  showContactData = true,
  absCustomerNumber,
}) => {
  const { data: customers } = useGetCustomers();
  const currentCustomer = customers?.find(
    (customer) => customer.id === customerId
  );

  return (
    <div className="flex flex-1 flex-col w-full">
      {absCustomerNumber && (
        <TextWithLabel label="ABS-Kundennummer:">
          {absCustomerNumber}
        </TextWithLabel>
      )}
      <TextWithLabel label="Firma:">
        {offerOrOrderAddress.company}
      </TextWithLabel>
      <TextWithLabel label="Vorname:">
        {offerOrOrderAddress.firstName}
      </TextWithLabel>
      <TextWithLabel label="Nachname:">
        {offerOrOrderAddress.lastName}
      </TextWithLabel>
      <TextWithLabel label="Straße, Hausnummer:">
        {offerOrOrderAddress.addressLine1 || ''}
      </TextWithLabel>
      <TextWithLabel label="Postleitzahl:">
        {offerOrOrderAddress.postalCode}
      </TextWithLabel>
      <TextWithLabel label="Stadt:">{offerOrOrderAddress.city}</TextWithLabel>
      <TextWithLabel label="Land:">
        {offerOrOrderAddress.countryISO}
      </TextWithLabel>

      {showContactData && (
        <>
          <TextWithLabel label="Telefonnummer:">
            {currentCustomer?.phoneNumber}
          </TextWithLabel>
          <TextWithLabel label="E-Mail:">
            {offerOrOrderAddress.email}
          </TextWithLabel>
        </>
      )}
    </div>
  );
};

OfferOrOrderAddress.defaultProps = {
  absCustomerNumber: undefined,
  showContactData: undefined,
};

export default OfferOrOrderAddress;
