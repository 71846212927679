/* eslint-disable no-restricted-globals */

/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

const useScript = (
  url: string,
  isEnabled: boolean,
  appendBody = true,
  content?: string
) => {
  const [state, setState] = useState(url ? 'loading' : 'idle');

  useEffect(() => {
    if (!url || !isEnabled) {
      setState('idle');
      return;
    }
    let script = document.querySelector(
      `script[src="${url}"]`
    ) as HTMLScriptElement;

    const handleScript = (e) => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script && isEnabled) {
      script = document.createElement('script');
      script.type = 'application/javascript';
      if (content) {
        script.textContent = content;
      } else {
        script.src = url;
        script.async = true;
      }

      if (appendBody) {
        document.body.appendChild(script);
      } else {
        document.head.appendChild(script);
      }
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    return () => {
      if (script) {
        script.removeEventListener('load', handleScript);
        script.removeEventListener('error', handleScript);
      }
    };
  }, [url]);

  return state;
};

export default useScript;
