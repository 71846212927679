import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PolicyConsentDto, PolicyDto } from '@wartungshelden/shared-types';

import { useAxios } from '../api';

export const usePublicPolicies = (
  filter: 'latest' | 'all' = 'all',
  enabled = true
) => {
  const axios = useAxios();

  const fetchFilters = async (): Promise<PolicyDto[] | undefined> => {
    if (!axios) return undefined;
    const { data } = await axios.get<PolicyDto[]>(
      `/policies/public?filter=${filter}`
    );
    return data;
  };

  return useQuery(['policies', filter], fetchFilters, {
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const usePolicies = (
  filter:
    | 'consented'
    | 'unconsented'
    | 'consent_required'
    | 'latest'
    | 'all' = 'all',
  enabled = true
) => {
  const axios = useAxios();

  const fetchFilters = async (): Promise<PolicyDto[] | undefined> => {
    if (!axios) return undefined;
    const { data } = await axios.get<PolicyDto[]>(`/policies?filter=${filter}`);
    return data;
  };

  return useQuery(['policies', filter], fetchFilters, {
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useConsentToPolicy = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const createMaintenanceRequest = async (consent: PolicyConsentDto) => {
    if (!axios) return null;
    return axios.post('/policies/consents', consent);
  };

  return useMutation(createMaintenanceRequest, {
    onSuccess: async () => {
      await rqClient.invalidateQueries(['policies']);
    },
  });
};
