import React from 'react';

import { Card } from '../../../../components';
import TestProcedureCheckboxSection from './TestProcedureCheckboxSection';

const OrderDataTestProcedureCheckboxes: React.FC = () => (
  <Card paddingX={4} paddingY={2}>
    <h1 className="font-bold mt-4">Allgemeine Anweisungen zum Auftrag</h1>
    <div className="my-4">
      <TestProcedureCheckboxSection
        headline="EAP/Lock I & II/ Seilsystem"
        checkboxes={[
          {
            name: `testProcedures.fallProtection.hasCheckSketch`,
            label: 'Bitte Skizze/Plan prüfen und ggf. korrigieren!',
            shortLabel: 'Skizze/Plan prüfen ',
          },
          {
            name: `testProcedures.fallProtection.hasMarkTensileTests`,
            label: 'Bitte Zugversuche markieren!',
            shortLabel: 'Zugversuche markieren',
          },
          {
            name: `testProcedures.fallProtection.hasMarkRoofOpenings`,
            label: 'Bitte Dachöffnungen markieren!',
            shortLabel: 'Dachöffnungen markieren',
          },
          {
            name: `testProcedures.fallProtection.hasDrawASketch`,
            label: 'Bitte Skizze erstellen!',
            shortLabel: 'Skizze erstellen',
          },
          {
            name: `testProcedures.fallProtection.hasMaintainRoomNumbersLockBook`,
            label: 'Bitte Raumnummern in Lock Book pflegen!',
            shortLabel: 'Raumnummern in Lock Book pflegen',
          },
        ]}
      />
    </div>
    <div className="my-4">
      <TestProcedureCheckboxSection
        headline="PSA"
        checkboxes={[
          {
            name: `testProcedures.psaAndHsg.hasCheckPSA`,
            label: 'Bitte PSA prüfen',
            shortLabel: 'Bitte PSA prüfen',
          },
          {
            name: `testProcedures.psaAndHsg.hasHandOverPPE`,
            label: 'Bitte PSA übergeben (siehe Abnahmeprotokoll)',
            shortLabel: 'PSA übergeben',
          },
          {
            name: `testProcedures.psaAndHsg.hasCheckPlugInBolts`,
            label: 'Bitte Steckbolzen prüfen',
            shortLabel: 'Bitte Steckbolzen prüfen',
          },
        ]}
      />
    </div>
    <div className="my-4">
      <TestProcedureCheckboxSection
        headline="HSG"
        checkboxes={[
          {
            name: `testProcedures.hsg.hasCheckHSGOnSite`,
            label: 'Bitte HSG vor Ort prüfen (1. Prüfungsjahr)',
            shortLabel: 'HSG vor Ort prüfen',
          },
          {
            name: `testProcedures.hsg.hasSentHSGForVerification`,
            label: 'HSG müssen zur Überprüfung eingeschickt werden',
            shortLabel: 'HSG müssen eingeschickt werden',
          },
          {
            name: `testProcedures.hsg.hasThirdPartyHSGCannotBeTested`,
            label:
              'Hinweis: Fremdgeräte können nicht durch ABS geprüft werden!',
            shortLabel: 'Fremdgeräte können nicht durch ABS geprüft werden',
          },
        ]}
      />
    </div>
  </Card>
);

export default OrderDataTestProcedureCheckboxes;
