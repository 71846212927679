import { FormikErrors, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { maintenanceObjectsInRequestValidationSchema } from '../../../../constants/MaintenanceYupSchema';
import { FormikMaintenanceWizardValues } from './types/FormikMaintenanceWizardValues';

const useSetFormikStateForError = () => {
  const { setStatus, values } = useFormikContext();
  const location = useLocation();
  const isField = (field: string | undefined): field is string => !!field;

  const state: { showError: boolean | null } | null = useMemo(() => {
    return location.state as {
      showError: boolean | null;
    };
  }, [location.state]);

  const shouldValidateForRequest = useMemo(() => {
    if (!state) return false;
    return state.showError;
  }, [state]);

  const error: ValidationError | null = useMemo(() => {
    if (shouldValidateForRequest) {
      try {
        maintenanceObjectsInRequestValidationSchema.validateSync(values, {
          abortEarly: false,
        });
      } catch (e) {
        if (e instanceof ValidationError) {
          return e;
        }
      }
    }
    return null;
  }, [shouldValidateForRequest, values]);

  const errorFields = useMemo(() => {
    return error?.inner.map((innerError) => innerError.path).filter(isField);
  }, [error?.inner]);

  useEffect(() => {
    if (shouldValidateForRequest) {
      toast.error('Bitte ergänze alle roten Felder', {
        position: 'top-center',
        toastId: 'invalidRequest',
      });
    }
  }, [shouldValidateForRequest]);

  useEffect(() => {
    const formikErrors: FormikErrors<FormikMaintenanceWizardValues> = {};
    errorFields?.forEach((field) => {
      formikErrors[field.replace('[', '.').replace(']', '')] = 'Required'; // TODO: Error message hier rein schreiben
    });
    setStatus(formikErrors);
  }, [errorFields, setStatus]);

  return {
    shouldValidateForRequest,
  };
};
export default useSetFormikStateForError;
