import { AddressDto } from './address';

export interface ContactDto {
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly position?: string | null;
  readonly email?: string | null;
  readonly phone?: string | null;
  readonly mobilePhone?: string | null;
  readonly address?: AddressDto | null;
}

export interface BuildingDto {
  readonly id: string;
  readonly name: string;
  readonly ownerId: string;
  readonly buildingType: string;
  readonly isPublic: boolean;
  readonly contact?: ContactDto;
  readonly address?: AddressDto;
}

export type CreateBuildingDto = Omit<BuildingDto, 'id' | 'ownerId'>;
export type UpdateBuildingDto = Omit<BuildingDto, 'ownerId'>;
