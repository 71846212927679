import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  MaintenanceRequestStatus,
  RequestStatusEnum,
} from '../../maintenance-request';

export class MaintenanceTeamMemberMustNotCancelRequest<
  T extends { status?: MaintenanceRequestStatus }
> extends DomainRule<T, T> {
  protected async rule(entity: T) {
    if (entity.status === RequestStatusEnum.CANCELED) {
      throw new DomainRuleNotSatisfiedError(
        MaintenanceTeamMemberMustNotCancelRequest
      );
    }

    return entity;
  }
}
