import {
  eachDayOfInterval,
  eachWeekendOfMonth,
  endOfMonth,
  format,
  getISOWeek,
  isBefore,
  isPast,
  lastDayOfISOWeek,
  lastDayOfMonth,
  startOfISOWeek,
  startOfMonth,
  subDays,
} from 'date-fns';
import de from 'date-fns/locale/de';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { DateRange, DayClickEventHandler, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { DueDateStatus } from '../../constants/dueDateStatus';
import { getDueDateStatus } from '../../services/maintenanceService';
import {
  dateToMonthString,
  dueDateOrToday,
} from '../../services/timeDateService';
import RadioButtonField from './Inputs/RadioButtonField';
import RadioButtonGroup from './Inputs/RadioButtonGroup';
import TextareaRow from './Inputs/TextareaRow';

const daypickerStyles = {
  weeknumber: { color: 'blue' },
  day: { borderRadius: 0 },
  day_outside: { color: 'grey', borderRadius: 0 },
  caption_label: { zIndex: 0 },
};

const TimeDetermination: React.FC<
  React.PropsWithChildren<{
    dueDate?: Date;
    numberOfRows: number;
  }>
> = ({ dueDate, numberOfRows }) => {
  const { values, setFieldValue }: any = useFormikContext();

  const [hoveredDays, setHoveredDays] = useState<DateRange>({
    from: new Date(1970),
    to: new Date(1970),
  });

  const dueDateAsDate = dueDateOrToday(dueDate);
  const closestMonthToDueDate = () =>
    isPast(lastDayOfMonth(dueDateAsDate))
      ? lastDayOfMonth(new Date())
      : lastDayOfMonth(dueDateAsDate);
  const status = getDueDateStatus(dueDateAsDate);

  const getStringFromDueDateStatus = (relevantDueDate?: Date) => {
    if (!relevantDueDate) return 'nicht angegeben';
    const statusForString = getDueDateStatus(relevantDueDate);
    if (statusForString === DueDateStatus.OVERDUE) return 'bereits abgelaufen';
    if (statusForString === DueDateStatus.UPCOMING) return 'im aktuellen Monat';
    return `im ${dateToMonthString(relevantDueDate)}`;
  };

  const urgencyOfDueDate = (myDueDate: Date) =>
    isPast(lastDayOfMonth(myDueDate)) || !dueDate
      ? 'So schnell wie möglich'
      : 'Möglichst innerhalb des Fälligkeitsmonats';

  useEffect(() => {
    if (values?.preferablyInDueMonth) {
      setFieldValue('dateRanges', []);
    }
  }, [setFieldValue, values?.preferablyInDueMonth]);

  const getAllWorkDaysOfDueDate = () => {
    const eachDayInMonth = eachDayOfInterval({
      start: startOfMonth(closestMonthToDueDate()),
      end: endOfMonth(closestMonthToDueDate()),
    });
    const weekends = eachWeekendOfMonth(closestMonthToDueDate());
    return eachDayInMonth.filter((day) => !weekends.some((d) => +d === +day));
  };

  const getDateRange = (date: Date) => {
    const ourselection: DateRange = {
      from: startOfISOWeek(date),
      to: subDays(lastDayOfISOWeek(date), 2),
    };
    return ourselection;
  };

  const handleDayClick: DayClickEventHandler = (date) => {
    if (isBefore(date, startOfISOWeek(new Date()))) {
      return;
    }

    const ourselection = getDateRange(date);

    const hasAlreadyBeenSelected = values?.dateRanges.find(
      (range: DateRange) => range.to?.getDate() === ourselection.to?.getDate()
    );

    if (hasAlreadyBeenSelected) {
      setFieldValue(
        'dateRanges',
        values?.dateRanges.filter(
          (range: DateRange) =>
            range.to?.getDate() !== ourselection.to?.getDate()
        )
      );
    } else {
      const dateRanges = [...values.dateRanges, ourselection].sort(
        (a, b) => a.from.getTime() - b.from.getTime()
      );
      setFieldValue('dateRanges', dateRanges);
    }
  };

  return (
    <>
      <p className="flex">
        {numberOfRows === 1
          ? 'Der nächste Fälligkeitstermin ist'
          : 'Der früheste Fälligkeitstermin ist'}
        <span className="font-extrabold ml-1">
          {getStringFromDueDateStatus(dueDate)}.
        </span>
      </p>
      <div className="grid grid-cols-5 max-w-5xl">
        <div className="col-span-2">
          <div className="mr-2">
            <RadioButtonGroup title="">
              <div className="mb-2 mt-12 text-2xl">
                Wann soll die Wartung stattfinden?
              </div>
              <RadioButtonField
                label={urgencyOfDueDate(dueDateAsDate)}
                name="preferablyInDueMonth"
                value
              />

              <RadioButtonField
                label="In bestimmten Kalenderwochen"
                name="preferablyInDueMonth"
                value={false}
              />
              {!values.preferablyInDueMonth && (
                <div className="ml-8">
                  {Boolean(values?.dateRanges?.length) &&
                    values?.dateRanges?.map((range: DateRange) => (
                      <div key={range.to?.getDate()}>
                        <span className="font-extrabold">{`KW ${getISOWeek(
                          range.from!
                        )}`}</span>
                        {`: ${format(range.from!, 'dd.MM.yyyy')} - ${format(
                          range.to!,
                          'dd.MM.yyyy'
                        )}`}
                      </div>
                    ))}
                </div>
              )}
            </RadioButtonGroup>
          </div>
        </div>
        <div className="col-span-2 flex justify-end">
          <div>
            {!values?.preferablyInDueMonth && (
              <DayPicker
                locale={de}
                mode="custom"
                selected={values?.dateRanges}
                onDayClick={handleDayClick}
                onDayMouseEnter={(date) => {
                  setHoveredDays(getDateRange(date));
                }}
                onDayMouseLeave={() => {
                  setHoveredDays({ from: new Date(1970), to: new Date(1970) });
                }}
                showWeekNumber
                showOutsideDays
                fromDate={startOfMonth(new Date())}
                styles={daypickerStyles}
                defaultMonth={closestMonthToDueDate()}
                modifiers={{
                  hoveredDays,
                  firstDayHovered: [hoveredDays.from] as Date[],
                  lastDayHovered: [hoveredDays.to] as Date[],
                  firstActiveDays: values.dateRanges?.map(
                    (dr: DateRange) => dr.from
                  ),
                  lastActiveDays: values.dateRanges?.map(
                    (dr: DateRange) => dr.to
                  ),
                }}
                modifiersStyles={{
                  hoveredDays: { backgroundColor: 'lightgrey' },
                  firstDayHovered: { borderRadius: '100% 0 0 100%' },
                  lastDayHovered: { borderRadius: '0 100% 100% 0' },
                  firstActiveDays: { borderRadius: '100% 0 0 100%' },
                  lastActiveDays: { borderRadius: '0 100% 100% 0' },
                }}
              />
            )}
          </div>
        </div>
        <div className="col-span-4 mt-4">
          <TextareaRow
            name="additionalAppointmentInfo"
            placeholder="Hier ist Platz für weitere Vorgaben..."
            rows={5}
            label="Was sollten wir bei der Terminauswahl noch berücksichtigen?"
          />
        </div>
      </div>
    </>
  );
};

TimeDetermination.defaultProps = {
  dueDate: undefined,
};

export default TimeDetermination;
