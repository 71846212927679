import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../components';

interface ReceivedLocationState {
  email?: string;
  phone?: string;
}

const RegistrationSuccessPage = ({
  isAdminLogin,
}: {
  isAdminLogin?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToLogin = () => {
    const { email, phone } = location.state
      ? (location.state as ReceivedLocationState)
      : { email: undefined, phone: undefined };
    navigate(isAdminLogin ? '/admin/login' : '/login', {
      state: { email, phone },
    });
  };

  return (
    <>
      <div className="flex justify-center px-8 pt-4 pb-4">
        <img src="/assets/logo.svg" alt="ABS Wartung" className="w-3/5" />
      </div>
      <div className="px-7 py-7">
        <div className="flex justify-center items-center">
          <FaCheckCircle size={50} className="text-blue-abs" />
        </div>
        <h1 className="text-lg font-bold my-4">
          Deine Registrierung war erfolgreich!
        </h1>
        <p className="">
          Melde Dich jetzt an und nutze alle Vorteile von ABS Wartung völlig
          kostenfrei.
        </p>
        <Button
          onClick={navigateToLogin}
          className="primary-button full-button mt-7"
        >
          Anmelden
        </Button>
      </div>
    </>
  );
};

RegistrationSuccessPage.defaultProps = {
  isAdminLogin: false,
};

export default RegistrationSuccessPage;
