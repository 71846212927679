import React from 'react';

const MaintenanceRequestWizardHeader = () => (
  <div className="bg-red text-white h-24">
    <div className="flex h-full items-center pl-80 pr-2">
      <div className="p-4 flex flex-1 justify-between">
        <div className="text-4xl ">Anfrage erstellen</div>
      </div>
    </div>
  </div>
);

export default MaintenanceRequestWizardHeader;
