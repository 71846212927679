import React from 'react';
import { Cell } from 'react-table';

import {
  requestStatusToLabel,
  statusToUse,
} from '@wartungshelden/shared-types';

interface StatusCellProps {
  cell: Cell;
  isMaintenanceTeamMemberAdmin: boolean;
}

const StatusCell: React.FC<React.PropsWithChildren<StatusCellProps>> = ({
  cell,
  isMaintenanceTeamMemberAdmin,
}) => {
  return (
    <>
      {requestStatusToLabel(
        isMaintenanceTeamMemberAdmin,
        statusToUse(
          cell.value.requestStatus,
          cell.value.offerStatus,
          cell.value.orderStatus
        )
      )}
    </>
  );
};

export default StatusCell;
