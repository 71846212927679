import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Button } from './components';
import Modal from './components/Basics/Modals/Modal';
import FooterNotification from './components/Policies/FooterNotification';
import useEnvironmentSupported from './contexts/EnvironmentSupportedHook';

const AllPagesWrapper = () => {
  const { windowSize, isBrowserSupported } = useEnvironmentSupported();
  const isWindowTooSmall = windowSize.width && windowSize.width < 1024;

  const userDecidedToHideWarning = window.localStorage.getItem('hideWarning');

  const [showModal, setShowModal] = useState<boolean>(true);
  return (
    <>
      <Modal
        isOpen={
          (isWindowTooSmall || !isBrowserSupported) &&
          showModal &&
          userDecidedToHideWarning !== 'true'
        }
        headline="Darstellungsproblem"
        center
      >
        {!isBrowserSupported && (
          <div>
            Dein Browser bzw. diese Version Deines Browsers wird nicht
            unterstützt!
          </div>
        )}
        {isWindowTooSmall && (
          <div>
            Diese Bildschirmbreite wird noch nicht unterstützt! <br />
            Wenn Du ein Smartphone oder Tablet verwendest, wechsle bitte zu
            einem Desktop-PC.
          </div>
        )}
        <div className="mt-4 flex justify-center">
          <Button
            onClick={() => {
              setShowModal(false);
              window.localStorage.setItem('hideWarning', 'true');
            }}
          >
            Trotzdem fortfahren
          </Button>
        </div>
      </Modal>
      <Outlet />
    </>
  );
};

export default AllPagesWrapper;
