import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceOrder } from '../../maintenance-offer';

export class MustHaveOrderConfirmationDocumentAttached<
  T extends MaintenanceOrder
> extends DomainRule<T, T & { confirmationDocumentName: string }> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (!entity?.confirmationDocumentName) {
      throw new DomainRuleNotSatisfiedError(
        MustHaveOrderConfirmationDocumentAttached
      );
    }
    return entity as T & { confirmationDocumentName: string };
  }
}
