import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';

import FilePreviewComponent from './File/FilePreviewComponent';

interface DeleteFilePreviewProps {
  onDelete: (file: File) => void;
  showDelete?: boolean;
  file: File;
}

const DeleteFilePreview: React.FC<
  React.PropsWithChildren<DeleteFilePreviewProps>
> = ({ onDelete, showDelete, file }) => {
  return (
    <div className="flex w-32 h-32 bg-gray text-gray-abs mb-2 relative">
      <FilePreviewComponent file={file} />
      {showDelete && (
        <div className="absolute cursor-pointer top-0 right-0 bg-gray-lighter p-2">
          <FaTrashAlt
            size={16}
            onClick={() => {
              onDelete(file);
            }}
          />
        </div>
      )}
    </div>
  );
};

DeleteFilePreview.defaultProps = {
  showDelete: true,
};

export default DeleteFilePreview;
