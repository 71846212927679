/* eslint-disable */
import { useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { ProvidedServiceEnum } from '@wartungshelden/shared-types';

import {
  FORMIK_SELECT_DEFAULT_VALUE,
  FallProtectionSystems,
  MANUFACTURERS,
} from '../../../constants/MaintenanceConstants';
import { DOCUMENTATION_NOT_VALIDATED_ERROR } from '../../../contexts/HintHighlightContext';
import {
  isFormikFallProtectionMaintenanceObject,
  isFormikFallProtectionPushLockSystem,
  isFormikFallProtectionRopeSystem,
} from '../../../guards/isFormikMaintenanceObject';
import { labelToProvidedService } from '../../../pages/UserPages/maintenance/MaintenanceObject';
import {
  FormikFallProtection,
  FormikFallProtectionPushLockSystem,
  FormikFallProtectionRopeSystem,
  FormikFallProtectionSingleAnchorSystem,
  FormikFallProtectionSystem,
} from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import {
  FileOrDocumentation,
  byFileName,
  toFileName,
} from '../../../services/maintenanceService';
import Button from '../../Basics/Buttons/Button';
import DropdownFieldLayout from '../../Basics/Inputs/DropdownFieldLayout';
import Modal from '../../Basics/Modals/Modal';
import HintHighlight from '../../HintHighlight/HintHighlight';
import { InputField } from '../../index';
import MaintenanceDocumentationValidityPreview from './MaintenanceDocumentationValidityPreview';
import ObjectMaintenanceProperties from './ObjectMaintenanceProperties';
import PropertyCellView from './PropertyCellView';
import MaintenanceDocumentationPreview from './Upload/MaintenanceDocumentationPreview';
import UploadModal from './Upload/UploadModal';

const InventoryFallProtectionRow: React.FC<
  React.PropsWithChildren<{
    index: number;
    disabled: boolean;
    validityShown?: boolean;
    formikFallProtection: FormikFallProtection;
    maintenanceOwnerId?: string;
    maintenanceObjectId?: string;
    setAddedFiles?: Dispatch<SetStateAction<File[]>>;
    addedFiles?: File[];
  }>
> = ({
  index,
  disabled,
  validityShown,
  formikFallProtection,
  maintenanceOwnerId,
  maintenanceObjectId,
  addedFiles,
  setAddedFiles,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] =
    useState(false);

  const [chosenFilesForUpload, setChosenFilesForUpload] = useState<
    FileOrDocumentation[]
  >(formikFallProtection.selectedFiles ?? []);

  useEffect(() => {
    setChosenFilesForUpload(formikFallProtection.selectedFiles ?? []);
  }, [formikFallProtection]);

  const { values, setFieldValue, status } =
    useFormikContext<FormikMaintenanceWizardValues>();

  let formikFallProtectionRopeSystem:
    | FormikFallProtectionRopeSystem
    | undefined;
  let formikFallProtectionPushLockSystem:
    | FormikFallProtectionPushLockSystem
    | undefined;
  let formikFallProtectionSingleAnchorSystem:
    | FormikFallProtectionSingleAnchorSystem
    | undefined;

  if (
    formikFallProtection.system &&
    isFormikFallProtectionRopeSystem(formikFallProtection.system)
  ) {
    formikFallProtectionRopeSystem = formikFallProtection.system;
  } else if (
    formikFallProtection.system &&
    isFormikFallProtectionPushLockSystem(formikFallProtection.system)
  ) {
    formikFallProtectionPushLockSystem = formikFallProtection.system;
  } else {
    formikFallProtectionSingleAnchorSystem = formikFallProtection.system;
  }

  const checkIsPropertiesEnabled = (): boolean => {
    const maintenanceObject = values.maintenanceObjects[index];
    return !!(
      isFormikFallProtectionMaintenanceObject(maintenanceObject) &&
      maintenanceObject.system
    );
  };

  const clearRowValues = () => {
    setFieldValue(`maintenanceObjects.${index}.system`, undefined);
    setFieldValue(
      `maintenanceObjects.${index}.location`,
      FORMIK_SELECT_DEFAULT_VALUE
    );
  };

  const areFallProtectionSystemPropertiesChanged = (
    fallProtectionSystem: FormikFallProtectionSystem
  ): boolean => {
    if (isFormikFallProtectionRopeSystem(fallProtectionSystem)) {
      return (
        fallProtectionSystem.ropeLength > 0 ||
        fallProtectionSystem.systemAnchors > 0
      );
    }
    if (isFormikFallProtectionPushLockSystem(fallProtectionSystem)) {
      return fallProtectionSystem.lock1 > 0 || fallProtectionSystem.lock2 > 0;
    }
    return fallProtectionSystem.singleAnchors > 0;
  };

  const areAccessPropertiesChanged = (): boolean => {
    const accesses: boolean[] = [
      formikFallProtection.scaffold,
      formikFallProtection.roofExit,
      formikFallProtection.liftingPlatform,
      formikFallProtection.climbLadder,
      formikFallProtection.ladder,
      formikFallProtection.other,
    ];

    return accesses.some((access) => access);
  };

  const arePropertiesSet = (): boolean => {
    return (
      areAccessPropertiesChanged() ||
      (formikFallProtection.system !== undefined &&
        areFallProtectionSystemPropertiesChanged(
          formikFallProtection.system
        )) ||
      (formikFallProtection.location !== undefined &&
        formikFallProtection.location !== FORMIK_SELECT_DEFAULT_VALUE)
    );
  };

  const systemTypeNotPicked = (idx) =>
    // @ts-ignore
    values.maintenanceObjects[idx].systemName === undefined ||
    // @ts-ignore
    values.maintenanceObjects[idx].systemName === FORMIK_SELECT_DEFAULT_VALUE;

  return (
    <>
      <div className="w-full">
        <DropdownFieldLayout
          name={`maintenanceObjects.${index}.systemName`}
          disabled={disabled}
          onSelect={(value) => {
            clearRowValues();
            if (value === FallProtectionSystems.ROPE) {
              setFieldValue(
                `maintenanceObjects.${index}.systemName`,
                FallProtectionSystems.ROPE
              );
              const formikRopeSystem: FormikFallProtectionRopeSystem = {
                ropeLength: 0,
                systemAnchors: 0,
                singleAnchors: 0,
              };
              setFieldValue(
                `maintenanceObjects.${index}.system`,
                formikRopeSystem
              );
              return;
            }

            if (value === FallProtectionSystems.PUSH) {
              setFieldValue(
                `maintenanceObjects.${index}.systemName`,
                FallProtectionSystems.PUSH
              );
              const formikPushLockSystem: FormikFallProtectionPushLockSystem = {
                lock1: 0,
                lock2: 0,
              };
              setFieldValue(
                `maintenanceObjects.${index}.system`,
                formikPushLockSystem
              );
              return;
            }

            setFieldValue(
              `maintenanceObjects.${index}.systemName`,
              FallProtectionSystems.SINGLE
            );
            const singleAnchorsSystem: FormikFallProtectionSingleAnchorSystem =
              { singleAnchors: 0 };
            setFieldValue(
              `maintenanceObjects.${index}.system`,
              singleAnchorsSystem
            );
          }}
          options={Object.values(FallProtectionSystems)}
        />
      </div>
      <div>
        <DropdownFieldLayout
          name={`maintenanceObjects.${index}.manufacturer`}
          disabled={disabled || systemTypeNotPicked(index)}
          options={
            formikFallProtection?.systemName !== FallProtectionSystems.PUSH
              ? MANUFACTURERS
              : [MANUFACTURERS[0]]
          }
          searchable
        />
      </div>
      <div>
        {!arePropertiesSet() ? (
          <div
            className={`${
              status &&
              Object.keys(status).length > 0 &&
              'border-2 border-red rounded-lg'
            }`}
          >
            <Button
              className="primary-button full-button"
              disabled={!checkIsPropertiesEnabled()}
              icon={<MdModeEdit />}
              label="Eigenschaften festlegen"
              onClick={() => setIsModalVisible(true)}
            />
          </div>
        ) : (
          <PropertyCellView
            disabled={disabled}
            formikFallProtection={formikFallProtection}
            formikFallProtectionPushLockSystem={
              formikFallProtectionPushLockSystem
            }
            formikFallProtectionSingleAnchorSystem={
              formikFallProtectionSingleAnchorSystem
            }
            formikFallProtectionRopeSystem={formikFallProtectionRopeSystem}
            setIsModalVisible={setIsModalVisible}
          />
        )}
      </div>
      <div>
        <InputField
          name={`maintenanceObjects.${index}.name`}
          help="Wenn Du möchtest, kannst Du hier eine Beschreibung hinzufügen. Dies kann eine Inventarnummer oder eine andere bei Euch übliche Bezeichnung sein."
          placeholder="Beschreibung eingeben..."
          disabled={disabled}
        />
      </div>
      <div className="grid grid-cols-2 gap-2 w-full">
        {chosenFilesForUpload.sort(byFileName).map((file) => {
          const fileName = toFileName(file);
          return validityShown ? (
            <HintHighlight
              highlightIds={[
                `${DOCUMENTATION_NOT_VALIDATED_ERROR}${
                  'id' in file && file.id
                }`,
              ]}
            >
              <MaintenanceDocumentationValidityPreview
                key={fileName}
                disabled={disabled}
                maintenanceObjectId={maintenanceObjectId}
                maintenanceOwnerId={maintenanceOwnerId}
                documentationOrFile={file}
                deleteFile={async () => {
                  setChosenFilesForUpload((prevState) => {
                    return prevState.filter(
                      (chosenFile) =>
                        toFileName(chosenFile) !== toFileName(file)
                    );
                  });
                  setFieldValue(
                    `maintenanceObjects.${index}.selectedFiles`,
                    formikFallProtection.selectedFiles?.filter(
                      (selectedFile) =>
                        toFileName(selectedFile) !== toFileName(file)
                    )
                  );
                }}
              />
            </HintHighlight>
          ) : (
            <MaintenanceDocumentationPreview
              key={fileName}
              disabled={disabled}
              maintenanceOwnerId={maintenanceOwnerId}
              documentationOrFile={file}
              deleteFile={async () => {
                setChosenFilesForUpload((prevState) => {
                  return prevState.filter(
                    (chosenFile) => toFileName(chosenFile) !== toFileName(file)
                  );
                });
                setFieldValue(
                  `maintenanceObjects.${index}.selectedFiles`,
                  formikFallProtection.selectedFiles?.filter(
                    (selectedFile) =>
                      toFileName(selectedFile) !== toFileName(file)
                  )
                );
              }}
            />
          );
        })}

        {!disabled && (
          <>
            <div
              role="link"
              tabIndex={0}
              onKeyDown={() => setIsFileUploadModalVisible(true)}
              onClick={() => setIsFileUploadModalVisible(true)}
              className="bg-gray-lighter text-gray-abs border-4 aspect-square text-6xl flex items-center justify-center cursor-pointer"
            >
              <FaPlus />
            </div>
            {chosenFilesForUpload.length === 0 && (
              <div className="text-xs">
                TIPP: Eine{' '}
                <Link
                  to="/gute-doku"
                  target="_blank"
                  className="text-blue-abs underline underline-offset-1"
                >
                  aussagekräftige Montagedokumentation
                </Link>{' '}
                spart Zeit & Kosten.
              </div>
            )}
          </>
        )}
      </div>
      <div className="w-full flex items-center justify-end">
        {!disabled && !maintenanceObjectId && (
          <button
            className={`ml-8 ${disabled && 'cursor-default'}`}
            type="button"
            onClick={() => {
              if (!disabled) {
                const tempMaintenanceObjects = values.maintenanceObjects;
                tempMaintenanceObjects.splice(index, 1);
                setFieldValue('maintenanceObjects', tempMaintenanceObjects);
              }
            }}
          >
            <FaTrashAlt size={20} className="text-black-abs" />
          </button>
        )}
      </div>

      <Modal isOpen={isModalVisible} center>
        {values.type &&
          labelToProvidedService(values.type) ===
            ProvidedServiceEnum.FALL_PROTECTION && (
            <ObjectMaintenanceProperties
              index={index}
              onClose={() => setIsModalVisible(false)}
            />
          )}
      </Modal>

      {!disabled && (
        <UploadModal
          isVisible={isFileUploadModalVisible}
          setIsVisible={setIsFileUploadModalVisible}
          index={index}
          setChosenFiles={setChosenFilesForUpload}
          addedFiles={addedFiles}
          setAddedFiles={setAddedFiles}
        />
      )}
    </>
  );
};

InventoryFallProtectionRow.defaultProps = {
  validityShown: false,
  maintenanceObjectId: undefined,
  maintenanceOwnerId: undefined,
};

export default InventoryFallProtectionRow;
