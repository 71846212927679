import { Formik, useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import TagManager from 'react-gtm-module';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
import { v4 as uuidv4 } from 'uuid';

import { PriceInformationObjectDto } from '@wartungshelden/shared-types';

import Button from '../../../../../components/Basics/Buttons/Button';
import { PSAGA_SYSTEM_TYPES } from '../../../../../constants/MaintenanceConstants';
import { isRopeSystemDto } from '../../../../../guards/isFallProtectionSystem';
import {
  isClimbingProtectionDto,
  isFallProtectionDto,
  isGroundStairsDto,
  isLadderDto,
  isOverpassDto,
  isPPEDto,
  isRailSystemDto,
  isRailingDto,
  isStairLadderDto,
} from '../../../../../guards/isMaintenanceType';
import { PriceCalculationMaintenanceObjectModalFormikState } from '../../types/PriceCalculationMaintenanceObjectModalFormikState';
import {
  PriceInformationCombination,
  isClimbingProtectionCombination,
  isRailSystemCombination,
  isRopeSystemCombination,
  toPriceInformationObjects,
} from '../../utils/combinations';
import {
  PriceCalculationState,
  deleteCalculationObjects,
  getLabelForMaintenanceTypeOrFallProtectionSystem,
} from '../../utils/utils';
import priceCalculationValidationSchema from '../PriceValidationYupSchema';
import ClimbingProtectionSelector from '../selector/ClimbingProtectionSelector';
import FallProtectionSelector from '../selector/FallProtectionSelector';
import GroundStairSelector from '../selector/GroundStairSelector';
import LadderSelector from '../selector/LadderSelector';
import OverpassSelector from '../selector/OverpassSelector';
import PSASelector from '../selector/PSASelector';
import RailSystemSelector from '../selector/RailSystemSelector';
import RailingSelector from '../selector/RailingSelector';
import StairLadderSelector from '../selector/StairLadderSelector';

interface Props {
  selectedPriceInformationObject: PriceInformationCombination;
  setSelectedPriceInformationObject: (
    preselectedObject: PriceInformationCombination
  ) => void;
  isModalVisible: boolean;
  isUpdating: boolean;
  onSave: (
    objectInformation: PriceInformationObjectDto[],
    combinations: string[][],
    currentValues: PriceCalculationState
  ) => Promise<void>;
  onClose: () => void;
}

const PriceCalculationMaintenanceObjectModal: React.FC<
  PropsWithChildren<Props>
> = ({
  selectedPriceInformationObject,
  setSelectedPriceInformationObject,
  isModalVisible,
  onSave,
  onClose,
  isUpdating,
}) => {
  const { values } = useFormikContext<PriceCalculationState>();

  const createAdditionalGliders = (
    modalValues: PriceCalculationMaintenanceObjectModalFormikState,
    quantity: number,
    systemType: string
  ): PriceCalculationMaintenanceObjectModalFormikState => {
    const gliderId = uuidv4();
    return {
      hasValidDocumentation: modalValues.hasValidDocumentation,
      building: modalValues.building,
      object: {
        id: gliderId,
        manufacturer: modalValues.object.manufacturer,
        type: {
          type: 'personal_protection_equipment',
          quantity,
          systemType,
        },
      },
    };
  };

  const createAdditionalLadders = (
    modalValues: PriceCalculationMaintenanceObjectModalFormikState,
    parts: number[]
  ): PriceCalculationMaintenanceObjectModalFormikState => {
    const ladderId = uuidv4();
    return {
      hasValidDocumentation: modalValues.hasValidDocumentation,
      building: modalValues.building,
      object: {
        id: ladderId,
        type: {
          type: 'ladder',
          parts,
          numberOfPlatforms: modalValues.climbingProtectionPlatforms ?? 0,
        },
        manufacturer: null,
      },
    };
  };

  const createObjectsToSave = (
    modalValues: PriceCalculationMaintenanceObjectModalFormikState
  ) => {
    const groups: string[][] = [];
    const objectsToSave: PriceCalculationMaintenanceObjectModalFormikState[] =
      [];

    const { type } = modalValues.object;

    if (
      isClimbingProtectionDto(type) &&
      (modalValues.climbingProtectionGliders ||
        modalValues.shouldAddLaddersToClimbingProtection)
    ) {
      const combination = [modalValues.object.id];

      if (modalValues.climbingProtectionGliders) {
        const climbingProtectionGliders = createAdditionalGliders(
          modalValues,
          modalValues.climbingProtectionGliders,
          PSAGA_SYSTEM_TYPES.CLIMBING_PROTECTION_GLIDER.value
        );
        objectsToSave.push(climbingProtectionGliders);
        combination.push(climbingProtectionGliders.object.id);
      }

      if (modalValues.shouldAddLaddersToClimbingProtection) {
        const climbingProtectionLadders = createAdditionalLadders(modalValues, [
          type.length,
        ]);
        objectsToSave.push(climbingProtectionLadders);
        combination.push(climbingProtectionLadders.object.id);
      }

      groups.push(combination);
    }

    if (
      isFallProtectionDto(type) &&
      isRopeSystemDto(type.system) &&
      modalValues.ropeSystemProtectionGliders
    ) {
      const ropeSystemGliders = createAdditionalGliders(
        modalValues,
        modalValues.ropeSystemProtectionGliders,
        PSAGA_SYSTEM_TYPES.GLIDER.value
      );
      objectsToSave.push(ropeSystemGliders);
      groups.push([modalValues.object.id, ropeSystemGliders.object.id]);
    }
    if (isRailSystemDto(type) && modalValues.railSystemProtectionGliders) {
      const gliderId = uuidv4();
      objectsToSave.push({
        hasValidDocumentation: modalValues.hasValidDocumentation,
        building: modalValues.building,
        object: {
          id: gliderId,
          manufacturer: modalValues.object.manufacturer,
          type: {
            type: 'personal_protection_equipment',
            quantity: modalValues.railSystemProtectionGliders,
            systemType: PSAGA_SYSTEM_TYPES.GLIDER.value,
          },
        },
      });

      groups.push([modalValues.object.id, gliderId]);
    }

    const objectToSave: PriceInformationObjectDto = {
      object: modalValues.object,
      hasValidDocumentation: modalValues.hasValidDocumentation,
      building: modalValues.building,
    };

    if (
      isStairLadderDto(objectToSave.object.type) ||
      isOverpassDto(objectToSave.object.type) ||
      isLadderDto(objectToSave.object.type)
    ) {
      objectsToSave.push({
        ...objectToSave,
        object: {
          ...objectToSave.object,
          type: {
            ...objectToSave.object.type,
            parts: new Array(modalValues.amount).fill(
              isLadderDto(objectToSave.object.type)
                ? modalValues.ladderLength
                : modalValues.steps
            ),
          },
        },
      });
    } else {
      objectsToSave.push(objectToSave);
    }

    return {
      objectsToSave: objectsToSave.map((toSave) => {
        const { amount, shouldAddLaddersToClimbingProtection, ...object } =
          toSave;
        return object;
      }),
      groups,
    };
  };

  const handleOnSubmit = async (
    modalValues: PriceCalculationMaintenanceObjectModalFormikState
  ) => {
    const create = createObjectsToSave(modalValues);

    if (isUpdating) {
      // first remove objects
      const objectsToDelete = toPriceInformationObjects(
        selectedPriceInformationObject
      );
      const newObjectsState = await deleteCalculationObjects(
        objectsToDelete.flat(),
        values
      );
      // then add new objects from edit modal
      await onSave(create.objectsToSave, create.groups, newObjectsState);
    } else {
      await onSave(create.objectsToSave, create.groups, values);
    }
  };

  const getInitialValues = (
    priceInformationObject: PriceInformationCombination
  ) => {
    if (isRopeSystemCombination(priceInformationObject)) {
      return {
        ...priceInformationObject.primarySystem,
        amount: 0,
        ladderLength: 0,
        steps: 0,
        gliderId: priceInformationObject.glider?.object?.id,
        climbingProtectionPlatforms: 0,
        climbingProtectionGliders: 0,
        ropeSystemProtectionGliders:
          priceInformationObject.glider?.object?.type?.quantity ?? 0,
        railSystemProtectionGliders: 0,
      } as PriceCalculationMaintenanceObjectModalFormikState;
    }

    if (isRailSystemCombination(priceInformationObject)) {
      return {
        ...priceInformationObject.primarySystem,
        amount: 0,
        ladderLength: 0,
        steps: 0,
        gliderId: priceInformationObject.glider?.object?.id,
        climbingProtectionPlatforms: 0,
        climbingProtectionGliders: 0,
        ropeSystemProtectionGliders: 0,
        railSystemProtectionGliders:
          priceInformationObject.glider?.object?.type?.quantity ?? 0,
      } as PriceCalculationMaintenanceObjectModalFormikState;
    }

    if (isClimbingProtectionCombination(priceInformationObject)) {
      return {
        ...priceInformationObject.primarySystem,
        amount: 0,
        ladderLength: 0,
        steps: 0,
        gliderId: priceInformationObject.glider?.object?.id,
        ladderId: priceInformationObject.ladder?.object?.id,

        climbingProtectionPlatforms:
          priceInformationObject.ladder?.object?.type?.numberOfPlatforms ?? 0,
        climbingProtectionGliders:
          priceInformationObject.glider?.object?.type?.quantity ?? 0,
        ropeSystemProtectionGliders: 0,
        railSystemProtectionGliders: 0,
      } as PriceCalculationMaintenanceObjectModalFormikState;
    }

    return {
      ...priceInformationObject.primarySystem,
      amount: 0,
      ladderLength: 0,
      steps: 0,
      climbingProtectionPlatforms: 0,
      climbingProtectionGliders: 0,
      ropeSystemProtectionGliders: 0,
      railSystemProtectionGliders: 0,
    } as PriceCalculationMaintenanceObjectModalFormikState;
  };

  return (
    <ResponsiveModal
      open={isModalVisible}
      onClose={() => {}}
      showCloseIcon={false}
      center
      styles={{
        modal: {
          padding: 0,
          width: '90%',
          height: '90%',
          maxWidth: '100vw',
          margin: 0,
        },
      }}
    >
      <Formik
        initialValues={getInitialValues(selectedPriceInformationObject)}
        validationSchema={priceCalculationValidationSchema}
        onSubmit={handleOnSubmit}
        validateOnMount
        enableReinitialize
      >
        {({ values: modalValues, submitForm, isValid }) => {
          const { type } = modalValues.object;
          return (
            <div
              className="flex flex-col flex-1 h-full bg-white"
              data-cy="priceCalculationMaintenanceObjectModalContent"
            >
              <div className="flex flex-col px-8 py-4">
                <div className="text-3xl font-bold mb-2">
                  {`${getLabelForMaintenanceTypeOrFallProtectionSystem(
                    isFallProtectionDto(type) && type.system
                      ? type.system?.system
                      : type.type
                  )} ${isUpdating ? 'bearbeiten' : 'hinzufügen'}`}
                </div>
              </div>

              <div className="flex flex-1 flex-col py-4">
                <div className="flex flex-1 flex-col space-y-4">
                  {isFallProtectionDto(modalValues.object.type) && (
                    <FallProtectionSelector />
                  )}
                  {isPPEDto(modalValues.object.type) && <PSASelector />}
                  {isLadderDto(modalValues.object.type) && (
                    <LadderSelector
                      isUpdating={isUpdating}
                      setSelectedPriceInformationObject={
                        setSelectedPriceInformationObject
                      }
                    />
                  )}
                  {isClimbingProtectionDto(modalValues.object.type) && (
                    <ClimbingProtectionSelector isUpdating={isUpdating} />
                  )}
                  {isRailSystemDto(modalValues.object.type) && (
                    <RailSystemSelector />
                  )}
                  {isRailingDto(modalValues.object.type) && <RailingSelector />}
                  {isStairLadderDto(modalValues.object.type) && (
                    <StairLadderSelector isUpdating={isUpdating} />
                  )}
                  {isOverpassDto(modalValues.object.type) && (
                    <OverpassSelector isUpdating={isUpdating} />
                  )}
                  {isGroundStairsDto(modalValues.object.type) && (
                    <GroundStairSelector />
                  )}
                </div>

                <div className="flex flex-row space-x-2 px-8">
                  <Button
                    className="outline-button small-button"
                    onClick={() => onClose()}
                    label="Abbrechen"
                  />
                  <Button
                    className="primary-button small-button"
                    onClick={async () => {
                      await submitForm();

                      TagManager.dataLayer({
                        dataLayer: {
                          event: isUpdating ? 'update_object' : 'add_object',
                          // eslint-disable-next-line no-nested-ternary
                          trackingId: isFallProtectionDto(
                            modalValues.object.type
                          )
                            ? modalValues.object.type.system?.system
                            : isPPEDto(modalValues.object.type)
                            ? modalValues.object.type.systemType
                            : modalValues.object.type.type,
                        },
                      });
                    }}
                    disabled={!isValid}
                    label={isUpdating ? 'Aktualisieren' : 'Hinzufügen'}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </ResponsiveModal>
  );
};

export default PriceCalculationMaintenanceObjectModal;
