import { Entity } from '@warthungshelden/domain/common';

export class CalendarAppointment extends Entity {
  id: number | string;
  name?: string;
  description?: string;
  place?: string;
  startDate: Date;
  endDate: Date;
  wholeDay?: boolean;
  private: boolean;
  transparent: boolean;

  constructor(config: {
    readonly id: number | string;
    readonly name?: string;
    readonly description?: string;
    readonly place?: string;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly wholeDay?: boolean;
    readonly private: boolean;
    readonly transparent?: boolean;
  }) {
    super();
    this.id = config.id;
    this.name = config.name;
    this.description = config.description;
    this.place = config.place;
    this.startDate = config.startDate;
    this.endDate = config.endDate;
    this.wholeDay = config.wholeDay;
    this.private = config.private;
    this.transparent = config.transparent ?? false;
  }
}
