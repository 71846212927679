/* eslint-disable react/require-default-props */
import { useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';

import {
  isFormikFallProtectionMaintenanceObject,
  isFormikLadderMaintenanceObject,
  isFormikPPEMaintenanceObject,
} from '../../../guards/isFormikMaintenanceObject';
import { FormikFallProtection } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikLadder } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikLadder';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { FormikPPE } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikPPE';
import InventoryFallProtectionRow from './InventoryFallProtectionRow';
import InventoryLadderRow from './InventoryLadderRow';
import InventoryPPERow from './InventoryPPERow';

interface InventoryRowProps {
  index: number;
  disabled?: boolean;
  validityShown?: boolean;
  maintenanceOwnerId?: string;
  maintenanceObjectId?: string;
  setAddedFiles?: Dispatch<SetStateAction<File[]>>;
  addedFiles?: File[];
}

const InventoryRow: React.FC<React.PropsWithChildren<InventoryRowProps>> = ({
  index,
  disabled = false,
  validityShown = false,
  maintenanceOwnerId,
  maintenanceObjectId,
  setAddedFiles,
  addedFiles,
}) => {
  const { values } = useFormikContext<FormikMaintenanceWizardValues>();

  const maintenanceObject = values.maintenanceObjects[index];

  if (!maintenanceObject) return null;

  let formikFallProtection: FormikFallProtection | undefined;
  if (isFormikFallProtectionMaintenanceObject(maintenanceObject)) {
    formikFallProtection = maintenanceObject;
  }

  let formikPPE: FormikPPE | undefined;
  if (isFormikPPEMaintenanceObject(maintenanceObject)) {
    formikPPE = maintenanceObject;
  }

  let formikLadder: FormikLadder | undefined;
  if (isFormikLadderMaintenanceObject(maintenanceObject)) {
    formikLadder = maintenanceObject;
  }

  return (
    <>
      {formikFallProtection && (
        <div
          className={`grid grid-cols-6 bg-gray-light gap-x-2 p-2 group ${
            !disabled && 'hover:bg-gray-lighter'
          }`}
        >
          <InventoryFallProtectionRow
            index={index}
            disabled={disabled}
            formikFallProtection={formikFallProtection}
            maintenanceOwnerId={maintenanceOwnerId}
            maintenanceObjectId={maintenanceObjectId}
            validityShown={validityShown}
            setAddedFiles={setAddedFiles}
            addedFiles={addedFiles}
          />
        </div>
      )}
      {formikPPE && (
        <div
          className={`grid grid-cols-5 bg-gray-light gap-x-2 p-2 group ${
            !disabled && 'hover:bg-gray-lighter'
          }`}
        >
          <InventoryPPERow index={index} disabled={disabled} />
        </div>
      )}
      {formikLadder && (
        <div
          className={`grid grid-cols-4 bg-gray-light gap-x-2 p-2 group ${
            !disabled && 'hover:bg-gray-lighter'
          }`}
        >
          <InventoryLadderRow index={index} disabled={disabled} />
        </div>
      )}
    </>
  );
};

InventoryRow.defaultProps = {
  disabled: false,
  validityShown: false,
  maintenanceObjectId: undefined,
  maintenanceOwnerId: undefined,
};

export default InventoryRow;
