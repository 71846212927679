import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { format, isDate } from 'date-fns';
import React, { createContext, useContext, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { toast } from 'react-toastify';

import { Config } from '../../Config';
import { SessionContext } from '../../contexts/SessionContext';
import { handleDates } from '../timeDateService';

export const AxiosContext = createContext<AxiosInstance | undefined>(undefined);

const defaultQueryError = () => {
  const customId = 'default-error';

  toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es erneut.', {
    position: 'top-center',
    toastId: customId,
  });
};

const queryClient = new QueryClient();

export const AxiosProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { accessToken, refreshTokenOrLogout, isSignedIn, isLoading } =
    useContext(SessionContext);

  queryClient.setDefaultOptions({
    queries: {
      onError: defaultQueryError,
      enabled: Boolean(!isLoading && isSignedIn && accessToken),
    },
  });

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: Config.awsAPI,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    instance.interceptors.request.use(
      (config: AxiosRequestConfig & { retry?: boolean }) => {
        config.headers = config.headers ?? {}; // eslint-disable-line no-param-reassign

        if (!config.retry) {
          config.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
        }

        if (isDate(config.params?.fromDate)) {
          // eslint-disable-next-line no-param-reassign
          config.params.fromDate = format(
            config.params.fromDate,
            "yyyy-MM-dd'T'HH:mm:ss'Z'"
          );
        }
        if (isDate(config.params?.toDate)) {
          // eslint-disable-next-line no-param-reassign
          config.params.toDate = format(
            config.params.toDate,
            "yyyy-MM-dd'T'HH:mm:ss'Z'"
          );
        }

        return config;
      }
    );

    instance.interceptors.response.use(
      (originalResponse) => {
        const contentDispositionHeader =
          originalResponse.headers['content-disposition'];

        if (contentDispositionHeader) {
          const startFileNameIndex = contentDispositionHeader.indexOf('"') + 1;
          const endFileNameIndex = contentDispositionHeader.lastIndexOf('"');
          // eslint-disable-next-line
          originalResponse['filename'] = contentDispositionHeader.substring(
            startFileNameIndex,
            endFileNameIndex
          );
        }

        handleDates(originalResponse.data);
        return originalResponse;
      },
      async (error: AxiosError) => {
        const config = error.config as AxiosRequestConfig & { retry?: boolean };
        if (!config.retry && isSignedIn && error.response?.status === 401) {
          const token = await refreshTokenOrLogout();
          config.headers!.Authorization = `Bearer ${token}`;
          config.retry = true;
          return instance(config);
        }
        throw error;
      }
    );

    return instance;
  }, [accessToken, isSignedIn, refreshTokenOrLogout]);

  return (
    <AxiosContext.Provider value={axiosInstance}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AxiosContext.Provider>
  );
};

export const useAxios = () => {
  return useContext(AxiosContext);
};
