import {
  PPEType as DomainPPEType,
  PPETypes as DomainPPETypes,
  ProvidedServicesTypesEnum as DomainProvidedServiceTypes,
} from '@warthungshelden/domain/maintenance-exchange';

import { OfferedMaintenanceObjectDto } from './maintenance-offer';

export type MaintenanceTypeDto =
  | FallProtectionDto
  | PersonalProtectionEquipmentDto
  | LadderDto
  | RailingDto
  | RailSystemDto
  | ClimbingProtectionDto
  | StairLadderDto
  | OverpassDto
  | GroundStairsDto
  | OtherTypeDto;

export type CreateMaintenanceTypeDto =
  | CreateFallProtectionDto
  | CreatePersonalProtectionEquipmentDto
  | CreateLadderDto
  | OtherTypeDto;

export type SupportedCreateMaintenanceTypeDto =
  | CreateFallProtectionDto
  | CreatePersonalProtectionEquipmentDto
  | CreateLadderDto;

export type SupportedMaintenanceTypeDto =
  | FallProtectionDto
  | PersonalProtectionEquipmentDto
  | LadderDto;

export interface RopeFallProtectionSystemDto {
  readonly system: 'rope';
  readonly singleAnchors: number;
  readonly ropeLength: number;
  readonly systemAnchors: number;
}

export type CreateRopeFallProtectionSystemDto = Omit<
  RopeFallProtectionSystemDto,
  'id'
>;

export interface PushLockFallProtectionSystemDto {
  readonly system: 'push_lock';
  readonly anchors: number;
  readonly sleeves: number;
}

export type CreatePushLockFallProtectionSystemDto = Omit<
  PushLockFallProtectionSystemDto,
  'id'
>;

export interface SingleAnchorFallProtectionSystemDto {
  readonly system: 'single_anchor';
  readonly singleAnchors: number;
}

export type CreateSingleAnchorFallProtectionSystemDto = Omit<
  SingleAnchorFallProtectionSystemDto,
  'id'
>;

export type FallProtectionSystemDto =
  | RopeFallProtectionSystemDto
  | PushLockFallProtectionSystemDto
  | SingleAnchorFallProtectionSystemDto;

export type CreateFallProtectionSystemDto =
  | CreateRopeFallProtectionSystemDto
  | CreatePushLockFallProtectionSystemDto
  | CreateSingleAnchorFallProtectionSystemDto;

export interface FallProtectionDto<
  T extends FallProtectionSystemDto = FallProtectionSystemDto
> {
  readonly hasUsedRopeAssistanceCalculator?: boolean | null;
  readonly location?: 'Roof' | 'Overhead' | 'Facade' | null;
  readonly type: 'fall_protection';
  readonly system?: T;
  readonly access: string[];
}

export type CreateFallProtectionDto = Omit<
  FallProtectionDto,
  'id' | 'system'
> & {
  system?: CreateFallProtectionSystemDto;
};

export interface PersonalProtectionEquipmentDto {
  readonly type: 'personal_protection_equipment';
  readonly quantity: number;
  readonly category?: string | null;
  readonly systemType?: string | null;
}

export type CreatePersonalProtectionEquipmentDto = Omit<
  PersonalProtectionEquipmentDto,
  'id'
>;

export interface LadderDto {
  readonly type: 'ladder';
  readonly parts: number[];
  readonly numberOfPlatforms: number;
  readonly hasUsedHeightAssistanceCalculator?: boolean | null;
}

export type CreateLadderDto = Omit<
  LadderDto,
  'id' | 'smallParts' | 'largeParts'
>;

export interface StairLadderDto {
  readonly type: 'stair_ladder';
  readonly parts: number[];
}

export interface OverpassDto {
  readonly type: 'overpass';
  readonly parts: number[];
}

export interface GroundStairsDto {
  readonly amount: number;
  readonly type: 'ground_stairs';
}

export interface RailingDto {
  readonly type: 'railing';
  readonly length: number;
}

export interface RailSystemDto {
  readonly type: 'rail_system';
  readonly length: number;
  readonly brackets: number;
}

export interface ClimbingProtectionDto {
  readonly type: 'climbing_protection';
  readonly length: number;
  readonly ladderId?: string;
}

export interface OtherTypeDto {
  readonly type: 'other_type';
  readonly name: string;
}

export interface MaintenanceObjectDto {
  readonly id: string;
  readonly name: string;
  readonly ownerId: string;
  readonly created: Date;
  readonly description?: string | null;
  readonly manufacturer?: string | null;
  readonly frequency?: number | null;
  readonly buildingId?: string | null;
  readonly type: MaintenanceTypeDto;
  readonly dueDate?: Date | null;
  readonly currentRequestId?: string | null;
  readonly orderIds?: string[] | null;
}

export type CreateMaintenanceObjectDto = Omit<
  MaintenanceObjectDto,
  'id' | 'ownerId' | 'created' | 'type'
> & {
  type: CreateMaintenanceTypeDto;
};
export type UpdateMaintenanceObjectDto = Omit<
  MaintenanceObjectDto,
  'ownerId' | 'type'
> & {
  type: CreateMaintenanceTypeDto | MaintenanceTypeDto;
};

export const PPEType = DomainPPEType;
export type PPETypes = DomainPPETypes;
export const ProvidedServiceEnum = DomainProvidedServiceTypes;

export type CurrentMaintenanceObjectDto =
  | MaintenanceObjectDto
  | OfferedMaintenanceObjectDto;

export function currentMaintenanceObjectId(
  object: CurrentMaintenanceObjectDto
) {
  return 'id' in object ? object.id : object.originalId;
}

export function roofAccessToDisplayName(access: string): string {
  const accessMap = {
    Scaffold: 'Gerüst',
    LiftingPlatform: 'Hubbühne',
    Ladder: 'Leiter',
    RoofExit: 'Dachausstieg',
    ClimbLadder: 'Steigleiter',
  };

  return accessMap[access] ?? access;
}

export type MaintenanceTypes =
  | 'fall_protection'
  | 'personal_protection_equipment'
  | 'ladder'
  | 'railing'
  | 'rail_system'
  | 'overpass'
  | 'stair_ladder'
  | 'ground_stairs'
  | 'climbing_protection'
  | 'other_type';

export type FallProtectionTypes = 'rope' | 'push_lock' | 'single_anchor';
