import { Entity } from '@warthungshelden/domain/common';

import { Address } from '../address';

export class BillingAddress extends Entity {
  public readonly company: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly address: Address;

  constructor(config: {
    company: string;
    firstName: string;
    lastName: string;
    address: Address;
  }) {
    super();
    this.company = config.company;
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.address = config.address;
  }
}

export class Notification extends Entity {
  public readonly email?: string | null;
  public readonly isReminderEnabled: boolean;
  public readonly isReminderWhenDueEnabled: boolean;
  public readonly isTestUserContactingEnabled: boolean;
  public readonly isStatusUpdatesEnabled: boolean;
  public readonly daysInAdvance?: number | null;

  constructor(config: {
    email?: string | null;
    isReminderEnabled: boolean;
    isReminderWhenDueEnabled: boolean;
    isTestUserContactingEnabled: boolean;
    isStatusUpdatesEnabled: boolean;
    daysInAdvance?: number | null;
  }) {
    super();
    this.isReminderEnabled = config.isReminderEnabled;
    this.isReminderWhenDueEnabled = config.isReminderWhenDueEnabled;
    this.isStatusUpdatesEnabled = config.isStatusUpdatesEnabled;
    this.isTestUserContactingEnabled = config.isTestUserContactingEnabled;
    this.email = config.email;
    this.daysInAdvance = config.daysInAdvance;
  }
}

export class Customer extends Entity {
  public readonly id: string;
  public readonly billingAddresses: BillingAddress[];
  public readonly email?: string | null;
  public readonly phoneNumber?: string | null;
  public readonly notification: Notification;
  public readonly absCustomerNumber?: string | null;
  public readonly hasAcceptedCustomerNumber: boolean;

  constructor(config: {
    id: string;
    billingAddresses: BillingAddress[];
    email?: string | null;
    phoneNumber?: string | null;
    notification: Notification;
    absCustomerNumber?: string | null;
    hasAcceptedCustomerNumber: boolean;
  }) {
    super();
    this.id = config.id;
    this.billingAddresses = config.billingAddresses;
    this.email = config.email;
    this.phoneNumber = config.phoneNumber;
    this.notification = config.notification;
    this.absCustomerNumber = config.absCustomerNumber;
    this.hasAcceptedCustomerNumber = config.hasAcceptedCustomerNumber;
  }

  public shouldNotifyAboutStatusUpdates() {
    return Boolean(
      this.notification.email && this.notification.isStatusUpdatesEnabled
    );
  }

  public shouldSendReminders() {
    return Boolean(
      this.notification.email && this.notification.isReminderEnabled
    );
  }

  public shouldSendOverdueReminders() {
    return Boolean(
      this.notification.email && this.notification.isReminderWhenDueEnabled
    );
  }
}
