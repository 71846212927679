import React, { useEffect, useState } from 'react';
import { FileError, useDropzone } from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';

import ConfirmationModal from './Modals/ConfirmationModal';

type FileUploadProps = {
  accept?: string;
  disabled?: boolean;
  onAddFile: (acceptedFiles: File[]) => void;
};

const FileUpload: React.FC<React.PropsWithChildren<FileUploadProps>> = ({
  accept,
  disabled,
  onAddFile,
}) => {
  const [errors, setErrors] = useState<FileError[]>([]);
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    maxSize: 41943040,
    maxFiles: 1,
    onDropRejected: (fileRejections) => setErrors(fileRejections[0].errors),
    disabled,
  });

  useEffect(() => {
    if (!acceptedFiles.length) return;
    onAddFile(acceptedFiles);
  }, [acceptedFiles]);

  let borderColor = 'border-gray-dark';
  if (isDragAccept) {
    borderColor = 'border-green-green';
  }
  if (isDragReject) {
    borderColor = 'border-red-angry';
  }

  return (
    <>
      <div
        data-cy="components-basics-file-upload"
        className={`
            w-full
            bg-gray-lighter
            text-gray-abs
            border-4
            border-dashed
            ${borderColor}
            aspect-square
            text-6xl
            ${!disabled && 'cursor-pointer'}
            `}
      >
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps({
            className:
              'dropzone h-full w-full justify-center items-center flex',
          })}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
          <FaPlus />
        </div>
      </div>

      <ConfirmationModal
        isVisible={errors.length > 0}
        onAccept={() => setErrors([])}
        heading="Upload fehlgeschlagen"
      >
        {errors.map((error) => (
          <div>{error.message}</div>
        ))}
      </ConfirmationModal>
    </>
  );
};

export default FileUpload;

FileUpload.defaultProps = {
  accept: 'image/jpeg, image/png, application/pdf',
  disabled: false,
};
