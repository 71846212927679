import { Entity } from '@warthungshelden/domain/common';

import { Address } from '../address';

export class Contact extends Entity {
  public readonly firstName?: string | null;
  public readonly lastName?: string | null;
  public readonly position?: string | null;
  public readonly email?: string | null;
  public readonly phone?: string | null;
  public readonly mobilePhone?: string | null;
  public readonly address?: Address | null;

  constructor(config: {
    firstName?: string | null;
    lastName?: string | null;
    position?: string | null;
    email?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    address?: Address | null;
  }) {
    super();
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.position = config.position;
    this.email = config.email;
    this.phone = config.phone;
    this.mobilePhone = config.mobilePhone;
    this.address = config.address;
  }
}

export class Building extends Entity {
  public readonly id: string;
  public readonly ownerId: string;
  public readonly name: string;
  public readonly buildingType: string;
  public readonly isPublic: boolean;
  public readonly contact?: Contact | null;
  public readonly address?: Address | null;

  constructor(config: {
    id: string;
    ownerId: string;
    name: string;
    buildingType: string;
    isPublic: boolean;
    contact?: Contact | null;
    address?: Address | null;
  }) {
    super();
    this.id = config.id;
    this.ownerId = config.ownerId;
    this.name = config.name;
    this.buildingType = config.buildingType;
    this.isPublic = config.isPublic;
    this.contact = config.contact;
    this.address = config.address;
  }
}
