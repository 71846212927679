export function to<T, U extends keyof T>(property: U) {
  return (entity: T): T[U] => entity[property];
}

export function toSum() {
  return (partialSum: number, current: number) => partialSum + current;
}

export function notNull<T>() {
  return (value: T): value is Exclude<T, null> => value !== null;
}

export function toChunks<T>(inputArray: T[], chunks: number) {
  return inputArray.reduce((resultArray: T[][], item: T, index) => {
    const chunkIndex = Math.floor(index / chunks);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function tap<T>(func: (value: T) => void) {
  return (value: T) => {
    func(value);
    return value;
  };
}
