import { wherePropertyEquals } from '@warthungshelden/shared-functions';
import React, { useState } from 'react';

import { InstallerDto } from '@wartungshelden/shared-types';

import {
  SuccessMessageBox,
  WarningMessageBox,
} from '../../../../../components/Basics/MessageBox';
import { RouteDtoWithEfficencyRules } from '../../types/RouteDtoWithEfficencyRules';
import InstallerCalendar from './InstallerCalendar';
import JobDetails from './jobDetails/JobDetails';
import RouteActions from './routeActions/RouteActions';
import RouteMap from './routeMap/RouteMap';

const RouteOverview: React.FC<{
  installers: InstallerDto[];
  route: RouteDtoWithEfficencyRules;
}> = ({ route, installers }) => {
  const [alternativeRoute, setAlternativeRoute] = useState<
    RouteDtoWithEfficencyRules | undefined
  >();

  const [showDetails, setShowDetails] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const activeRoute = alternativeRoute || route;

  const mainInstallerCandidate = activeRoute.installerCandidates[0];
  const mainInstaller = installers.find(
    wherePropertyEquals('id', mainInstallerCandidate.installerId)
  );

  const isCombinedRoute = Boolean(mainInstallerCandidate.appointments.length);

  const isDateChanged =
    alternativeRoute && route.date !== alternativeRoute.date;

  const isInstallerChanged =
    alternativeRoute &&
    route.installerCandidates[0].installerId !==
      alternativeRoute.installerCandidates[0].installerId;

  const getChangeLog = () => {
    const changeLog: string[] = [];
    if (isInstallerChanged && isDateChanged) {
      changeLog.push('Datum und Monteur geändert!');
      return changeLog;
    }
    if (isDateChanged) {
      changeLog.push('Datum geändert!');
    }
    if (isInstallerChanged) {
      changeLog.push('Monteur geändert!');
    }
    return changeLog;
  };

  return (
    <div className="flex flex-col space-vertical px-4 pt-6 pb-4 border-black border-2 rounded-xl bg-white relative">
      {isCombinedRoute && (
        <div className="absolute -top-4 left-6 bg-white border-black border-2 rounded-md w-24 text-center">
          Kombiroute
        </div>
      )}
      {isAccepted && (
        <SuccessMessageBox headline="Tour akzeptiert">
          Tour wurde akzeptiert und eingeplant.
        </SuccessMessageBox>
      )}
      {activeRoute.efficiencyRules.length > 0 && !isAccepted && (
        <div className="flex-1 mb-2">
          <WarningMessageBox
            headline={`${
              activeRoute.efficiencyRules?.length > 1 ? 'Hinweise' : 'Hinweis'
            }`}
          >
            <ul className="list-disc">
              {activeRoute.efficiencyRules.map((rule) => (
                <li data-cy="warning-message">{rule}</li>
              ))}
            </ul>
          </WarningMessageBox>
        </div>
      )}

      {mainInstaller && (
        <div className="flex w-full space-x-2 mt-4">
          <div className="w-1/3">
            <JobDetails
              route={activeRoute}
              mainInstaller={mainInstaller}
              jobChangeLog={getChangeLog()}
            />
          </div>
          <div className="w-2/3 grid grid-cols-2 gap-2">
            <div className="flex flex-1 justify-center items-top">
              <InstallerCalendar
                selected={alternativeRoute ? alternativeRoute.date : route.date}
                installer={mainInstaller}
              />
            </div>

            <RouteMap
              route={activeRoute}
              mainInstallerCandidate={mainInstallerCandidate}
              mainInstaller={mainInstaller}
              showDetails={showDetails}
            />
          </div>
          <RouteActions
            route={activeRoute}
            installers={installers}
            mainInstallerCandidate={mainInstallerCandidate}
            mainInstaller={mainInstaller}
            originalRoute={route}
            setAlternativeRoute={setAlternativeRoute}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            isAccepted={isAccepted}
            setIsAccepted={setIsAccepted}
          />
        </div>
      )}
    </div>
  );
};

export default RouteOverview;
