import { useEffect } from 'react';

const useSessionStorage = <T>(
  key: string,
  itemToSave: T,
  setState: (item: T) => void
) => {
  useEffect(() => {
    const item = sessionStorage.getItem(key);
    if (item) {
      setState(JSON.parse(item));
    }
  }, []);

  useEffect(() => {
    if (itemToSave) {
      sessionStorage.setItem(key, JSON.stringify(itemToSave));
    } else {
      sessionStorage.removeItem(key);
    }
  }, [itemToSave]);
};

export default useSessionStorage;
