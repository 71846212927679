import { format, getISOWeek, isPast, lastDayOfMonth } from 'date-fns';
import React from 'react';

import {
  CurrentMaintenanceObjectDto,
  CustomerOrMaintenanceTeamRequestDto,
} from '@wartungshelden/shared-types';

import { minDueDate } from '../../../services/maintenanceService';
import { dateToMonthString } from '../../../services/timeDateService';

const urgencyOfDueDate = (maintenances: CurrentMaintenanceObjectDto[]) => {
  const dueDate = minDueDate(maintenances);
  if (!dueDate) return 'So schnell wie möglich';
  return isPast(lastDayOfMonth(dueDate))
    ? 'So schnell wie möglich'
    : `Möglichst im ${dateToMonthString(dueDate)}`;
};

interface RequestDateRangesProps {
  request: CustomerOrMaintenanceTeamRequestDto;
  maintenances: CurrentMaintenanceObjectDto[];
}

const RequestDateRanges: React.FC<
  React.PropsWithChildren<RequestDateRangesProps>
> = ({ request, maintenances }) => (
  <div className="flex">
    <div className="mr-4">Gewünschter Zeitraum:</div>
    <div>
      {request.dateRanges && request.dateRanges.length > 0 ? (
        request.dateRanges?.map((range) => (
          <div key={`${range}`}>
            <span className="font-extrabold">{`KW ${getISOWeek(
              new Date(range.from)
            )}`}</span>
            {`: ${format(new Date(range.from), 'dd.MM.yyyy')} - ${format(
              new Date(range.to),
              'dd.MM.yyyy'
            )}`}
          </div>
        ))
      ) : (
        <div>{urgencyOfDueDate(maintenances)}</div>
      )}
    </div>
  </div>
);

export default RequestDateRanges;
