import { Formik } from 'formik';
import React, { useState } from 'react';

import {
  BuildingDto,
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceOrderDocumentDataDto,
} from '@wartungshelden/shared-types';

import { InputField, Toggle } from '../../../../components';
import LoadingSpinner from '../../../../components/Basics/Loaders/LoadingSpinner';
import ConfirmationModal from '../../../../components/Basics/Modals/ConfirmationModal';
import { useOrderDocumentFile } from '../../../../services/api/maintenance-offers/maintenance-offer-document-api';
import BuildingForm from './BuildingForm';

interface PriceAdoptionModalProps {
  isOpen?: boolean;
  onClose: () => void;
  offer: CustomerOrMaintenanceTeamOfferDto;
  buildings: Array<BuildingDto>;
}

const ExportOrderDocumentModal: React.FC<
  React.PropsWithChildren<PriceAdoptionModalProps>
> = ({ offer, onClose, isOpen = false, buildings }) => {
  const { mutateAsync: getOrderDocumentFile, isLoading } = useOrderDocumentFile(
    offer.id
  );
  const [useSameDataForAllBuildings, setUseSameDataForAllBuildings] =
    useState<boolean>(true);

  const copyDataToEveryBuilding = (values) => {
    return {
      ...values,
      buildings: values.buildings.map((building) => {
        return { ...values.buildings[0], buildingId: building.buildingId };
      }),
    };
  };

  const sortBuildingsByName = (buildingsToSort) =>
    buildingsToSort.sort((a, b) => a.name.localeCompare(b.name));

  const sortBuildingsInValues = (values) => {
    return {
      ...values,
      buildings: sortBuildingsByName(values.buildings),
    };
  };

  const sortedBuildings = sortBuildingsByName(buildings);

  const initialData: MaintenanceOrderDocumentDataDto = {
    installer: undefined,
    orderNumber: undefined,
    contractNumber: undefined,
    project: undefined,
    buildings: sortedBuildings.map((building) => {
      return {
        buildingId: building.id,
        name: building.name,
        workNotes: undefined,
        addressNotes: undefined,
        serials: undefined,
        hasLockBockDocumentation: false,
        isInstalledByABS: false,
        pastAuditMeasures: {
          hasTensileTest: false,
          hasRoofOpening: false,
        },
      };
    }),
  };

  return (
    <Formik
      initialValues={initialData}
      onSubmit={async (values) => {
        let orderDocumentData = useSameDataForAllBuildings
          ? copyDataToEveryBuilding(values)
          : values;
        orderDocumentData = sortBuildingsInValues(orderDocumentData);
        const file = await getOrderDocumentFile({
          orderDocumentData,
        });

        if (file) {
          const url = URL.createObjectURL(file);
          window.open(url, '_blank');
        }

        onClose();
      }}
    >
      {({ submitForm, resetForm }) => {
        return (
          <ConfirmationModal
            width="one-third"
            isVisible={isOpen}
            heading="Wartungsauftrag exportieren"
            onAccept={() => submitForm().then(() => resetForm())}
            onCancel={onClose}
          >
            <div className="mb-4">
              Fehlende Daten aus SelectLine und LockBook Doku ergänzen.
            </div>
            <InputField name="installer" label="Installateur" />
            <InputField name="orderNumber" label="Auftragsnummer" />
            <InputField name="contractNumber" label="Vertragsnummer" />
            <InputField name="project" label="Bauvorhaben" />

            <div className="mt-12 mb-4">
              <Toggle
                label="Angaben für alle Gebäude übernehmen"
                checked={useSameDataForAllBuildings}
                name="showAllObjects"
                onChange={() => {
                  setUseSameDataForAllBuildings((prevState) => !prevState);
                }}
              />
            </div>
            {useSameDataForAllBuildings ? (
              <BuildingForm index={0} />
            ) : (
              <>
                {sortedBuildings.map((building, index) => (
                  <BuildingForm building={building} index={index} />
                ))}
              </>
            )}
            <LoadingSpinner isLoading={isLoading}>
              PDF wird erstellt...
            </LoadingSpinner>
          </ConfirmationModal>
        );
      }}
    </Formik>
  );
};

ExportOrderDocumentModal.defaultProps = {
  isOpen: undefined,
};

export default ExportOrderDocumentModal;
