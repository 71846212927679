import React from 'react';

import { OverpassDto } from '@wartungshelden/shared-types';

import { toStepsLabel } from '../../utils/utils';
import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface OverpassPropertiesProps {
  overpass: OverpassDto;
  hasSubprices: boolean;
}

const OverpassProperties: React.FC<OverpassPropertiesProps> = ({
  overpass,
  hasSubprices,
}) => (
  <>
    <MaintenanceObjectSummaryRow
      label="Kategorie"
      value={toStepsLabel(overpass)}
      hasSubprices={false}
    />

    <MaintenanceObjectSummaryRow
      label="Überstieg"
      value={overpass.parts.length}
      unit="Stück"
      hasSubprices={hasSubprices}
    />
  </>
);

export default OverpassProperties;
