import { useFormikContext } from 'formik';
import React from 'react';

import { FormikFallProtection } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import CheckBoxField from './CheckBox/CheckBoxField';
import InputField from './InputField';

type InputCheckFieldListProps = {
  label: string;
  labels: string[];
  names: string[];
  disabled?: boolean;
  index: number;
  fallProtectionMaintenanceObject: FormikFallProtection;
};

const InputCheckFieldList: React.FC<
  React.PropsWithChildren<InputCheckFieldListProps>
> = ({
  label,
  labels,
  names,
  disabled,
  index,
  fallProtectionMaintenanceObject,
}) => {
  const { status } = useFormikContext<FormikMaintenanceWizardValues>();
  const errorKeys = status ? Object.keys(status) : [];

  return (
    <>
      <div className="mb-2 mt-4">{label}</div>
      <div className="grid grid-cols-3 px-2">
        {labels.map((checkFieldLabel, idx) => (
          <CheckBoxField
            label={checkFieldLabel}
            name={names[idx]}
            key={checkFieldLabel}
            disabled={disabled}
          />
        ))}
        <div className="flex">
          <div className="mr-4">
            <CheckBoxField
              name={`maintenanceObjects.${index}.other`}
              disabled={disabled}
              label={
                <InputField
                  name={`maintenanceObjects.${index}.otherText`}
                  disabled={!fallProtectionMaintenanceObject.other}
                  placeholder="Sonstiges"
                  isInvalid={errorKeys.includes(
                    `maintenanceObjects.${index}.otherText`
                  )}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

InputCheckFieldList.defaultProps = {
  disabled: false,
};

export default InputCheckFieldList;
