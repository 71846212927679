import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import userflow from 'userflow.js';

import environment from '../../environments/environment';
import { Config } from '../Config';
import AcceptPoliciesModal from '../components/Auth/AcceptPoliciesModal';
import { SessionContext } from '../contexts/SessionContext';
import useScript from '../contexts/UseScriptHook';
import useVerifyUserflow from '../services/api/userflow/userflow-api';

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
}

const ProtectedRoute: React.FC<
  React.PropsWithChildren<ProtectedRouteProps>
> = ({ isAllowed, redirectPath = '/login', children }) => {
  const { pathname } = useLocation();

  const { userInfo, isConsent } = useContext(SessionContext);

  // Hotjar Tracking Code for https://app.abs-wartung.de
  useScript(
    'https://static.hotjar.com/c/hotjar-3060714.js?sv=6',
    environment.production && !environment.e2e,
    false,
    '(function(h,o,t,j,a,r){\n' +
      '        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n' +
      '        h._hjSettings={hjid:3060714,hjsv:6};\n' +
      "        a=o.getElementsByTagName('head')[0];\n" +
      "        r=o.createElement('script');r.async=1;\n" +
      '        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n' +
      '        a.appendChild(r);\n' +
      "    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
  );

  const { data: signature } = useVerifyUserflow(environment);

  useEffect(() => {
    if (!Config.userflow.token || !signature || !isConsent) return;
    userflow.init(Config.userflow.token);
    const verifyUser = async () => {
      if (!userInfo) return;

      if (userInfo) {
        try {
          await userflow.identify(userInfo.username, undefined, {
            signature,
          });
        } catch (e) {
          // do nothing
        }
      }
    };

    verifyUser();
  }, [userInfo, isConsent]);
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: pathname }} />;
  }

  return <AcceptPoliciesModal>{children || <Outlet />}</AcceptPoliciesModal>;
};

ProtectedRoute.defaultProps = {
  redirectPath: undefined,
};

export default ProtectedRoute;
