import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';

import {
  CustomerOrMaintenanceTeamOfferDto,
  CustomerOrMaintenanceTeamRequestDto,
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
  RequestStatusEnum,
  currentMaintenanceObjectId,
  statusToUse,
} from '@wartungshelden/shared-types';

import {
  DOCUMENTATION_NOT_VALIDATED_ERROR,
  MAINTENANCE_OBJECT_IS_REOPENED,
} from '../../../contexts/HintHighlightContext';
import {
  isFormikFallProtectionMaintenanceObject,
  isFormikLadderMaintenanceObject,
  isFormikPPEMaintenanceObject,
} from '../../../guards/isFormikMaintenanceObject';
import { FormikFallProtection } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikLadder } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikLadder';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { FormikPPE } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikPPE';
import {
  useCreateMaintenanceDocumentation,
  useUpdateMaintenanceDocumentation,
} from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import { setDocumentationsFor } from '../../../services/documentationService';
import {
  FileOrDocumentation,
  byFileName,
  toFileName,
} from '../../../services/maintenanceService';
import DocumentPreview from '../../Basics/Documents/DocumentPreview';
import DocumentPreviewAdmin from '../../Basics/Documents/DocumentPreviewAdmin';
import HorizontalLine from '../../Basics/HorizontalLine';
import CheckBox from '../../Basics/Inputs/CheckBox/CheckBox';
import LoadingSpinner from '../../Basics/Loaders/LoadingSpinner';
import TextWithLabel from '../../Basics/TextWithLabel';
import HintHighlight from '../../HintHighlight/HintHighlight';
import FallProtectionPropertyView from './SystemTypes/FallProtectionPropertyView';
import UploadAdditionalDocumentModal from './UploadAdditionalDocumentModal';

type MaintenanceReadOnlyProps = {
  isMaintenanceTeamMemberAdmin?: boolean;
  request?: CustomerOrMaintenanceTeamRequestDto;
  offer?: CustomerOrMaintenanceTeamOfferDto;
  maintenance?: MaintenanceObjectDto;
  isDocumentUploadEnabled?: boolean;
  onToggleDocumentationUpload?: () => unknown;
};

const MaintenanceObjectTypeReadOnly: React.FC<
  React.PropsWithChildren<MaintenanceReadOnlyProps>
> = ({
  isMaintenanceTeamMemberAdmin,
  request,
  offer,
  maintenance,
  isDocumentUploadEnabled,
  onToggleDocumentationUpload,
}) => {
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] =
    useState(false);

  const { values } = useFormikContext<FormikMaintenanceWizardValues>();

  const {
    mutateAsync: createMaintenanceDocumentation,
    isLoading: createMaintenanceDocumentationLoading,
  } = useCreateMaintenanceDocumentation();

  const {
    mutateAsync: updateMaintenanceDocumentation,
    isLoading: updateMaintenanceDocumentationLoading,
  } = useUpdateMaintenanceDocumentation();

  const status =
    request &&
    statusToUse(request?.status, offer?.decision.status, offer?.order?.status);

  const canSetValidityForDocuments =
    isMaintenanceTeamMemberAdmin && status !== RequestStatusEnum.REQUESTED;

  const maintenanceObjectFormikValues = values.maintenanceObjects[0];
  if (!maintenanceObjectFormikValues) return null;

  const formikFallProtection: FormikFallProtection | undefined = useMemo(() => {
    if (
      isFormikFallProtectionMaintenanceObject(maintenanceObjectFormikValues)
    ) {
      return maintenanceObjectFormikValues;
    }
    return undefined;
  }, [values.maintenanceObjects]);

  let formikPPE: FormikPPE | undefined;
  if (isFormikPPEMaintenanceObject(maintenanceObjectFormikValues)) {
    formikPPE = maintenanceObjectFormikValues;
  }

  let formikLadder: FormikLadder | undefined;
  if (isFormikLadderMaintenanceObject(maintenanceObjectFormikValues)) {
    formikLadder = maintenanceObjectFormikValues;
  }

  const selectedDocumentations = useMemo(() => {
    return formikFallProtection?.selectedFiles;
  }, [formikFallProtection?.selectedFiles]);

  const documentationsFor = setDocumentationsFor(
    createMaintenanceDocumentation,
    updateMaintenanceDocumentation
  );

  const updateDocuments = async (
    existingDocs: MaintenanceDocumentationDto[],
    selectedDocs: FileOrDocumentation[]
  ) => {
    await documentationsFor(existingDocs, [
      {
        createdObject: maintenance,
        selectedFiles: selectedDocs,
      },
    ]);
  };

  const onSaveDocumentations = async (
    existingDocs: MaintenanceDocumentationDto[],
    selectedDocs: FileOrDocumentation[]
  ) => {
    await updateDocuments(existingDocs, selectedDocs);
    setIsFileUploadModalVisible(false);
  };

  return (
    <>
      {formikFallProtection && maintenance && (
        <div className="gap-x-2 p-2 group">
          <TextWithLabel label="Interne Bezeichnung:">
            {formikFallProtection.name}
          </TextWithLabel>
          <TextWithLabel label="Systemtyp:">
            {formikFallProtection.systemName}
          </TextWithLabel>
          <TextWithLabel label="Hersteller:">
            {formikFallProtection.manufacturer}
          </TextWithLabel>
          <TextWithLabel label="Eigenschaften:">
            <FallProtectionPropertyView
              formikFallProtection={formikFallProtection}
            />
          </TextWithLabel>
          <div className="flex flex-row ">
            <div className="flex font-bold w-52 mr-2">Dateien:</div>
            <HintHighlight
              key={currentMaintenanceObjectId(maintenance)}
              highlightBorderColor="border-orange"
              highlightIds={[
                `${MAINTENANCE_OBJECT_IS_REOPENED}${currentMaintenanceObjectId(
                  maintenance
                )}`,
              ]}
            >
              <div className="w-full flex flex-wrap gap-2">
                {formikFallProtection.selectedFiles
                  .sort(byFileName)
                  .map((file) => {
                    const fileName = 'name' in file ? file.name : file.fileName;
                    return (
                      <div key={fileName}>
                        {canSetValidityForDocuments ? (
                          <div>
                            <HintHighlight
                              highlightIds={[
                                `${DOCUMENTATION_NOT_VALIDATED_ERROR}${
                                  'id' in file && file.id
                                }`,
                              ]}
                            >
                              <div className="h-40 w-32">
                                <DocumentPreviewAdmin
                                  maintenanceObject={maintenance}
                                  maintenanceDocument={
                                    file as MaintenanceDocumentationDto
                                  }
                                />
                              </div>
                            </HintHighlight>
                          </div>
                        ) : (
                          <div key={fileName} className="h-32 w-32">
                            <DocumentPreview
                              deleteFile={async () => {
                                if (!formikFallProtection?.selectedFiles) {
                                  return;
                                }
                                await updateDocuments(
                                  formikFallProtection.selectedFiles as MaintenanceDocumentationDto[],
                                  formikFallProtection.selectedFiles.filter(
                                    (selectedFile) =>
                                      toFileName(selectedFile) !== fileName
                                  )
                                );
                              }}
                              isDocumentUploadEnabled={isDocumentUploadEnabled}
                              maintenanceObject={maintenance}
                              maintenanceDocument={
                                file as MaintenanceDocumentationDto
                              }
                              showDocumentValidity={
                                !isMaintenanceTeamMemberAdmin
                              }
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                {!isMaintenanceTeamMemberAdmin &&
                  isDocumentUploadEnabled &&
                  maintenance &&
                  selectedDocumentations && (
                    <>
                      <UploadAdditionalDocumentModal
                        visible={isFileUploadModalVisible}
                        maintenanceObject={maintenance}
                        onSave={onSaveDocumentations}
                      />
                      <div className="flex h-32 w-32">
                        <div
                          role="link"
                          tabIndex={0}
                          onKeyDown={() => setIsFileUploadModalVisible(true)}
                          onClick={() => setIsFileUploadModalVisible(true)}
                          className="bg-gray-lighter text-gray-abs border-4 aspect-square text-6xl flex items-center justify-center cursor-pointer"
                        >
                          <FaPlus />
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </HintHighlight>
          </div>
          {isMaintenanceTeamMemberAdmin && (
            <>
              <div className="mt-4">
                <HorizontalLine />
              </div>
              <div className="mt-4">
                <CheckBox
                  label="Erneutes Hochladen von Dokumenten erlauben"
                  onChange={onToggleDocumentationUpload}
                  checked={isDocumentUploadEnabled}
                />
              </div>
            </>
          )}
        </div>
      )}
      {formikPPE && (
        <div className="gap-x-2 p-2 group">
          <TextWithLabel label="Interne Bezeichnung:">
            {formikPPE.name}
          </TextWithLabel>
          <TextWithLabel label="Typ:">{formikPPE.systemType}</TextWithLabel>
          <TextWithLabel label="Hersteller:">
            {formikPPE.manufacturer}
          </TextWithLabel>
          <TextWithLabel label="Anzahl:">{formikPPE.quantity}</TextWithLabel>
        </div>
      )}
      {formikLadder && (
        <div className="gap-x-2 p-2 group">
          <TextWithLabel label="Interne Bezeichnung:">
            {formikLadder.name}
          </TextWithLabel>
          <TextWithLabel label="Länge:">{formikLadder.length}</TextWithLabel>
        </div>
      )}
      <LoadingSpinner
        isLoading={
          createMaintenanceDocumentationLoading ||
          updateMaintenanceDocumentationLoading
        }
      />
    </>
  );
};

MaintenanceObjectTypeReadOnly.defaultProps = {
  isMaintenanceTeamMemberAdmin: false,
  request: undefined,
  offer: undefined,
  maintenance: undefined,
  onToggleDocumentationUpload: undefined,
  isDocumentUploadEnabled: undefined,
};

export default MaintenanceObjectTypeReadOnly;
