import { DomainEvent } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import { MaintenanceObject } from './index';

export class MaintenanceObjectCreated extends DomainEvent {
  public static override tag = 'maintenance.object.created';

  constructor(
    public readonly user: User,
    public readonly maintenanceObject: MaintenanceObject
  ) {
    super();
  }
}

export class MaintenanceObjectUpdated extends DomainEvent {
  public static override tag = 'maintenance.object.updated';

  constructor(
    public readonly user: User,
    public readonly oldMaintenanceObject: MaintenanceObject,
    public readonly newMaintenanceObject: MaintenanceObject
  ) {
    super();
  }
}

export class MaintenanceObjectDeleted extends DomainEvent {
  public static override tag = 'maintenance.object.deleted';

  constructor(
    public readonly user: User,
    public readonly maintenanceObject: MaintenanceObject
  ) {
    super();
  }
}
