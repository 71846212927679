import React from 'react';

interface Props {
  label: string;
  labelStyle?: string;
  indicateMissingData?: boolean;
  isDataMissing?: () => boolean;
}

const InfoText: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  children,
  labelStyle,
  indicateMissingData,
  isDataMissing,
}) => {
  const missingDataStyle = (data) => {
    if (isDataMissing !== undefined) {
      return isDataMissing() && '!text-red';
    }
    return data ? '' : '!text-red';
  };

  return (
    <div className={`flex items-start max-w-2xl ${label && 'mb-2'}`}>
      <div
        className={`flex w-72 mr-4 ${
          indicateMissingData && missingDataStyle(children)
        } ${labelStyle}`}
      >
        {label}
      </div>
      <div className="relative z-0 w-full">{children}</div>
    </div>
  );
};

InfoText.defaultProps = {
  labelStyle: undefined,
  indicateMissingData: false,
  isDataMissing: undefined,
};

export default InfoText;
