import React from 'react';

import Button from '../Buttons/Button';
import Modal from './Modal';

interface ModalProps {
  width?: 'one-third';
  isVisible: boolean;
  heading?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  isFullHeight?: boolean;
  useMinHeight?: boolean;
}

const ConfirmationModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  width,
  isVisible,
  heading,
  onAccept,
  onCancel,
  children,
  onDelete,
  isFullHeight,
  useMinHeight = true,
}) => {
  if (isFullHeight) {
    return (
      <Modal isOpen={isVisible} center width={width}>
        <div className="flex flex-col items-center">
          {heading && (
            <div className="font-bold mb-8 w-60 text-center">{heading}</div>
          )}
          <div className={`flex ${'h-full'} flex-col overflow-y-auto w-full`}>
            {children}
          </div>
          <div className="flex w-full flex-row justify-center pt-4 border-t-2 border-gray">
            {onCancel && (
              <div className="mr-2">
                <Button
                  className="outline-button small-button"
                  onClick={onCancel}
                  label="Abbrechen"
                />
              </div>
            )}
            {!onDelete && (
              <Button
                disabled={onAccept === undefined}
                className="primary-button small-button"
                onClick={() => {
                  if (!onAccept) return;
                  onAccept();
                }}
                label="Ok"
              />
            )}
            {onDelete && (
              <Button
                className="alert-button small-button"
                onClick={onDelete}
                label="Löschen"
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <Modal isOpen={isVisible} center width={width}>
      <div className="flex flex-col items-center ">
        {heading && (
          <div className="font-bold mb-8 w-60 text-center">{heading}</div>
        )}
        <div
          className={`flex ${
            useMinHeight ? 'h-96' : undefined
          } flex-col overflow-y-auto w-full`}
        >
          {children}
        </div>
        <div className="flex w-full flex-row justify-center pt-4 border-t-2 border-gray">
          {onCancel && (
            <div className="mr-2">
              <Button
                className="outline-button small-button"
                onClick={onCancel}
                label="Abbrechen"
              />
            </div>
          )}
          {!onDelete && (
            <Button
              disabled={!onAccept}
              className="primary-button small-button"
              onClick={() => {
                if (!onAccept) return;
                onAccept();
              }}
              label="Ok"
            />
          )}
          {onDelete && (
            <Button
              className="alert-button small-button"
              onClick={onDelete}
              label="Löschen"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  width: undefined,
  heading: undefined,
  onCancel: undefined,
  onDelete: undefined,
  onAccept: undefined,
  isFullHeight: false,
  useMinHeight: false,
};

export default ConfirmationModal;
