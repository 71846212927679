import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const BuildingFormSchema = Yup.object().shape({
  buildingName: Yup.string()
    .min(3, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  buildingType: Yup.string().required('Required'),
  city: Yup.string().required(),
  country: Yup.string().min(2).max(2).required(),
  postalCode: Yup.string()
    .required()
    .test({
      test: (postalCode) => !!postalCode && /\d{5}/.test(postalCode),
    }),
  email: Yup.string().email().notRequired(),
  addressLineOne: Yup.string().required(),
});
