const euroFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

const formatEuros = (amount?: number | null) => {
  if (typeof amount !== 'number') {
    return '/ €';
  }

  return euroFormat.format(amount);
};

export default formatEuros;
