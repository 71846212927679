import React from 'react';

import { StairLadderDto } from '@wartungshelden/shared-types';

import { toStepsLabel } from '../../utils/utils';
import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface StairLadderPropertiesProps {
  stairLadder: StairLadderDto;
  hasSubprices: boolean;
}

const StairLadderProperties: React.FC<StairLadderPropertiesProps> = ({
  stairLadder,
  hasSubprices,
}) => (
  <>
    <MaintenanceObjectSummaryRow
      label="Kategorie"
      value={toStepsLabel(stairLadder)}
      hasSubprices={false}
    />

    <MaintenanceObjectSummaryRow
      label="Treppenleiter"
      value={stairLadder.parts.length}
      unit="Stück"
      hasSubprices={hasSubprices}
    />
  </>
);

export default StairLadderProperties;
