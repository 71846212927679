import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  MaintenanceOffer,
  MaintenanceOfferStatusEnum,
} from '../../maintenance-offer';

export class MustBeAccepted<T extends MaintenanceOffer> extends DomainRule<
  T,
  T
> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (entity.decision.status !== MaintenanceOfferStatusEnum.ACCEPTED) {
      throw new DomainRuleNotSatisfiedError(MustBeAccepted);
    }
    return entity as T;
  }
}
