export function distinct<T>(array: T[]) {
  return [...new Set([...array])];
}

export function areEqual<T>(array1: T[], array2: T[]) {
  return (
    array1.length === array2.length && array1.every((v, i) => v === array2[i])
  );
}

export function intersects<T>(array1: T[], array2: T[]) {
  return array1.some((value) => array2.includes(value));
}
