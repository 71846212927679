import { Entity } from '@warthungshelden/domain/common';

export class GeoCoordinate extends Entity {
  public readonly latitude: number;
  public readonly longitude: number;
  public readonly isValid: boolean;

  constructor(config: {
    latitude: number;
    longitude: number;
    isValid: boolean;
  }) {
    super();
    this.longitude = config.longitude;
    this.latitude = config.latitude;
    this.isValid = config.isValid;
  }
}

export class GeoDescription extends Entity {
  public readonly address: string;

  constructor(config: { address: string }) {
    super();
    this.address = config.address;
  }
}
