import React from 'react';

import { InstallerDto, RouteDto } from '@wartungshelden/shared-types';

import GoogleMapsRouteButton from '../../GoogleMapsRouteButton';
import JobDetails from './JobDetails';

interface JobDetailsEditRouteProps {
  title: string;
  route: RouteDto;
  mainInstaller: InstallerDto;
  backgroundColor?: 'gray';
  delay?: {
    totalDuration: number;
    kilometers: number;
  };
}

const backGroundColorMap = {
  gray: 'bg-[#e6e6e6]',
};

const JobDetailsEditRoute: React.FC<JobDetailsEditRouteProps> = ({
  title,
  route,
  mainInstaller,
  backgroundColor,
  delay,
}) => {
  return (
    <div
      className={`${
        backgroundColor ? backGroundColorMap[backgroundColor] : ''
      } p-4 flex flex-col items-center space-y-2`}
    >
      <h3 className="font-bold self-start">{title}</h3>
      <JobDetails
        route={route}
        mainInstaller={mainInstaller}
        textSize="text-sm"
        minWidthInRem="16"
        showInstallerAsHeadline={false}
        delay={delay}
      />

      <div className=" small-button">
        <GoogleMapsRouteButton route={route} installer={mainInstaller} />
      </div>
    </div>
  );
};

JobDetailsEditRoute.defaultProps = {
  backgroundColor: undefined,
  delay: undefined,
};

export default JobDetailsEditRoute;
