import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';

type BackButtonProps = {
  onClick: () => void;
};

const BackButton: React.FC<React.PropsWithChildren<BackButtonProps>> = ({
  onClick,
}) => (
  <button
    type="button"
    className="flex items-center underline cursor-pointer"
    onClick={onClick}
  >
    <MdKeyboardArrowLeft size={32} />
    zurück
  </button>
);

export default BackButton;
