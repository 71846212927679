import React, { createContext, useCallback, useMemo, useState } from 'react';

import { MaintenancePriceDto } from '@wartungshelden/shared-types';

import useSessionStorage from '../../../hooks/useSessionStorage';
import usePriceCalculation from '../../../services/api/price-calculation/price-calculation-api';
import { PriceInformationCombination } from './utils/combinations';
import {
  OBJECT_INFORMATION_SESSION_KEY,
  PRICE_WITHOUT_DOCU_SESSION_KEY,
  PRICE_WITH_DOCU_SESSION_KEY,
  PriceCalculationState,
} from './utils/utils';

interface PriceCalculationContextProps {
  priceWithoutDocu?: MaintenancePriceDto;
  setPriceWithoutDocu: (price?: MaintenancePriceDto) => void;
  priceWithDocu?: MaintenancePriceDto;
  setPriceWithDocu: (price?: MaintenancePriceDto) => void;
  isPriceCalculationMaintenanceObjectModalVisible: boolean;
  setIsPriceCalculationMaintenanceObjectModalVisible: (
    visible: boolean
  ) => void;
  isUpdating: boolean;
  setIsUpdating: (updating: boolean) => void;
  selectedPriceInformationObject?: PriceInformationCombination;
  setSelectedPriceInformationObject: (
    selectedObject?: PriceInformationCombination
  ) => void;
  submitFormikForm: (state: PriceCalculationState) => Promise<void>;
  getPrice: (
    priceInformationDto: PriceCalculationState,
    withDocu: boolean
  ) => Promise<MaintenancePriceDto | undefined>;
  initialValues: PriceCalculationState;
}

const PriceCalculationContext = createContext<PriceCalculationContextProps>({
  priceWithDocu: undefined,
  setPriceWithoutDocu: () => {},
  priceWithoutDocu: undefined,
  setPriceWithDocu: () => {},
  isPriceCalculationMaintenanceObjectModalVisible: false,
  setIsPriceCalculationMaintenanceObjectModalVisible: () => {},
  isUpdating: false,
  setIsUpdating: () => {},
  selectedPriceInformationObject: undefined,
  setSelectedPriceInformationObject: () => {},
  submitFormikForm: (state: PriceCalculationState) => Promise.resolve(),
  getPrice: () => Promise.resolve(undefined),
  initialValues: {
    objectInformation: [],
    combinations: [],
    strategy: 'no-fix-price',
  },
});

export const PriceCalculationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [priceWithoutDocu, setPriceWithoutDocu] = useState<
    MaintenancePriceDto | undefined
  >();

  const [priceWithDocu, setPriceWithDocu] = useState<
    MaintenancePriceDto | undefined
  >();

  const [
    isPriceCalculationMaintenanceObjectModalVisible,
    setIsPriceCalculationMaintenanceObjectModalVisible,
  ] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [selectedPriceInformationObject, setSelectedPriceInformationObject] =
    useState<PriceInformationCombination | undefined>();

  useSessionStorage(
    PRICE_WITH_DOCU_SESSION_KEY,
    priceWithDocu,
    setPriceWithDocu
  );
  useSessionStorage(
    PRICE_WITHOUT_DOCU_SESSION_KEY,
    priceWithoutDocu,
    setPriceWithoutDocu
  );

  const sessionObjectInformation = useMemo<string | null>(() => {
    return sessionStorage.getItem(OBJECT_INFORMATION_SESSION_KEY);
  }, [priceWithDocu, priceWithoutDocu]);

  const initialValues: PriceCalculationState = useMemo(() => {
    const state =
      sessionObjectInformation && JSON.parse(sessionObjectInformation);
    return {
      objectInformation: state?.objectInformation ?? [],
      combinations: state?.combinations ?? [],
      strategy: 'no-fix-price',
    };
  }, []);

  const { mutateAsync: fetchPrice } = usePriceCalculation();

  const getPrice = useCallback(
    (values: PriceCalculationState, withDocu: boolean) => {
      return fetchPrice({
        objectInformation: values.objectInformation.map(
          (objectInformation) => ({
            ...objectInformation,
            hasValidDocumentation: withDocu,
          })
        ),
        strategy: values.strategy,
      });
    },
    []
  );

  const submitFormikForm = useCallback(async (state: PriceCalculationState) => {
    setPriceWithoutDocu(undefined);
    setPriceWithDocu(undefined);

    const fetchedPriceWithoutDocu = await getPrice(state, false);
    const fetchedPriceWithDocu = await getPrice(state, true);

    setPriceWithoutDocu(fetchedPriceWithoutDocu);
    setPriceWithDocu(fetchedPriceWithDocu);
  }, []);

  const contextValue: PriceCalculationContextProps = useMemo(
    () => ({
      priceWithoutDocu,
      setPriceWithoutDocu,
      priceWithDocu,
      setPriceWithDocu,
      isUpdating,
      setIsUpdating,
      isPriceCalculationMaintenanceObjectModalVisible,
      setIsPriceCalculationMaintenanceObjectModalVisible,
      selectedPriceInformationObject,
      setSelectedPriceInformationObject,
      submitFormikForm,
      getPrice,
      initialValues,
    }),
    [
      priceWithDocu,
      priceWithoutDocu,
      sessionObjectInformation,
      isUpdating,
      isPriceCalculationMaintenanceObjectModalVisible,
      selectedPriceInformationObject,
    ]
  );

  return (
    <PriceCalculationContext.Provider value={contextValue}>
      {children}
    </PriceCalculationContext.Provider>
  );
};

export default PriceCalculationContext;
