import React from 'react';

import { StepMenuButton, WizardMenu } from '../..';

interface MaintenanceRequestWizardMenuProps {
  handleNavigation: (step: number) => void;
  activeStep: number;
  steps: { [step: number]: { disabled: boolean; visible: boolean } };
}

const MaintenanceRequestWizardMenu: React.FC<
  React.PropsWithChildren<MaintenanceRequestWizardMenuProps>
> = ({ handleNavigation, activeStep, steps }) => {
  return (
    <WizardMenu>
      <StepMenuButton
        step={1}
        title="Auswahl"
        handleMenuNavigation={handleNavigation}
        isStepVisible={steps[1]?.visible}
        isStepActive={activeStep === 1}
        isStepEnabled={!steps[1]?.disabled}
      />
      <StepMenuButton
        step={2}
        title="Terminwunsch"
        handleMenuNavigation={handleNavigation}
        isStepVisible={steps[2]?.visible}
        isStepActive={activeStep === 2}
        isStepEnabled={!steps[2]?.disabled}
      />
      <StepMenuButton
        step={3}
        title="Adresseingabe"
        handleMenuNavigation={handleNavigation}
        isStepVisible={steps[3]?.visible}
        isStepActive={activeStep === 3}
        isStepEnabled={!steps[3]?.disabled}
      />
      <StepMenuButton
        step={4}
        title="Bestätigung"
        handleMenuNavigation={handleNavigation}
        isStepVisible={steps[4]?.visible}
        isStepActive={activeStep === 4}
        isStepEnabled={!steps[4]?.disabled}
      />
    </WizardMenu>
  );
};

export default MaintenanceRequestWizardMenu;
