import React from 'react';

import { SelectLineMaintenanceOrderDto } from '@wartungshelden/shared-types';

interface Props {
  selectLineData?: SelectLineMaintenanceOrderDto;
}

const TaskInfo: React.FC<Props> = ({ selectLineData }) => {
  if (!selectLineData) {
    return null;
  }

  const getTaskType = () =>
    selectLineData.taskType === 'Wartungsvertrag'
      ? 'Wartung'
      : selectLineData.taskType;

  return (
    <div className="font-bold py-2 border-t-2 border-gray-dark">
      Aufgabe: {getTaskType()}
    </div>
  );
};

TaskInfo.defaultProps = {
  selectLineData: undefined,
};

export default TaskInfo;
