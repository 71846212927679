/* eslint-disable react/jsx-props-no-spreading,react/no-unstable-nested-components */
import { isDefined } from '@warthungshelden/shared-functions';
import React, { useCallback, useContext, useMemo } from 'react';
import { Column, Row, useSortBy, useTable } from 'react-table';

import {
  BuildingDto,
  CurrentMaintenanceObjectDto,
  CustomerMaintenancePriceDto,
  MaintenanceObjectPriceDto,
  MaintenanceOfferStatusDto,
  MaintenancePriceDto,
  currentMaintenanceObjectId,
} from '@wartungshelden/shared-types';

import { readableServiceType } from '../../../constants/MaintenanceConstants';
import { SessionContext } from '../../../contexts/SessionContext';
import isMaintenanceObjectDto from '../../../guards/isMaintenanceObjectDto';
import isMaintenanceObjectPriceDtoArray from '../../../guards/isMaintenanceObjectPriceDtoArray';
// eslint-disable-next-line import/no-cycle
import { userShouldSeeOffer } from '../../../pages/Request/RequestDetailsPage';
import { useMaintenanceDocumentations } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import formatEuros from '../../../services/format';
import DueDateCell from './DueDateCell';
import FilesStatusCell from './FilesStatusCell';
// eslint-disable-next-line import/no-cycle
import PriceComposition from './PriceComposition';
import PriceSupplementRow from './PriceSupplementRow';

type TableProps = {
  buildings?: BuildingDto[] | null;
  maintenances: CurrentMaintenanceObjectDto[];
  price: MaintenancePriceDto | CustomerMaintenancePriceDto;
  excelPrice?: MaintenancePriceDto | CustomerMaintenancePriceDto;
  offerStatus?: MaintenanceOfferStatusDto;
};

const priceIndicationMessages = {
  'PI-1':
    'Preise und Anfragen können für diesen Wartungstyp noch nicht erstellt werden.',
  'PI-2':
    'Der Preis konnte mit den angegebeben Parametern nicht kalkuliert werden.',
  'PI-3': 'Ungültige Anzahl an Einzelanschlagpunkten.',
  'PI-4': 'Ungültige Leiterhöhe.',
  'PI-5':
    'Eine Anfrage für Steigleitern mit einer Höhe von über 30m wird individuell geprüft.',
  'PI-6': 'Ungültige Geländerlänge.',
};

const byMaintenance =
  (maintenance: CurrentMaintenanceObjectDto) => (priceIndication) =>
    priceIndication.objectId === currentMaintenanceObjectId(maintenance);

const isMaintenancePriceDto = (
  maintenancePrice: MaintenancePriceDto | CustomerMaintenancePriceDto
): maintenancePrice is MaintenancePriceDto => {
  return (
    'fixedTotal' in maintenancePrice && 'variableTotal' in maintenancePrice
  );
};

const pricingStrategyDisplayName = (
  price: MaintenancePriceDto | CustomerMaintenancePriceDto
) => {
  return isMaintenancePriceDto(price)
    ? {
        wartungshelden: 'Preis (ABS Wartung)',
        'fixed-fix-price': 'Preis (Feste Grundkosten)',
        'no-fix-price': 'Preis (Verrechnete Grundkosten)',
      }[price.strategy] ?? 'Preis'
    : 'Preis';
};

const getPriceDisplayValues = (
  price?: MaintenancePriceDto | CustomerMaintenancePriceDto
) => {
  if (!price) {
    return null;
  }

  const displayName = pricingStrategyDisplayName(price);

  const fixedPrice = (isMaintenancePriceDto(price) && price.fixedTotal) || 0;
  const hasPriceAdaption = price?.priceAdaptation?.value !== undefined;

  const hasDocumentationDiscount =
    price.netDocumentationSavings !== null && price.netDocumentationSavings > 0;

  const hasFullDocumentationDiscount =
    price.netDocumentationSavings === price.maximumDocumentationSavings;

  const discount =
    (hasDocumentationDiscount && price.netDocumentationSavings) || 0;

  const missingDiscount =
    price.maximumDocumentationSavings !== null &&
    price.netDocumentationSavings !== null
      ? price.maximumDocumentationSavings - price.netDocumentationSavings
      : 0;

  const priceAdaption = price.priceAdaptation ?? null;

  return {
    displayName,
    fixedPrice,
    hasPriceAdaption,
    priceAdaption,
    hasFullDocumentationDiscount,
    hasDocumentationDiscount,
    missingDiscount,
    discount,
  };
};

const getMaintenanceBasePrice =
  (referencePrice?: MaintenancePriceDto | CustomerMaintenancePriceDto) =>
  (maintenance: CurrentMaintenanceObjectDto) => {
    const currentPrice = referencePrice?.objectPrices?.find(
      byMaintenance(maintenance)
    );

    if (!currentPrice) {
      return 0;
    }

    const existingPrice = currentPrice as MaintenanceObjectPriceDto;
    const netPrice = existingPrice.variableNetPrice ?? 0;
    const maxSavings = existingPrice.maximumNetDocumentationSavings ?? 0;
    const savings = existingPrice.netDocumentationSavings ?? 0;

    if (maxSavings === savings) {
      return netPrice;
    }

    return netPrice - maxSavings + savings;
  };

const MaintenanceRequestPriceOverviewTable: React.FC<
  React.PropsWithChildren<TableProps>
> = ({ buildings, maintenances, price, excelPrice, offerStatus }) => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const { data: existingMaintenanceDocumentations } =
    useMaintenanceDocumentations();

  const hasOffer =
    userShouldSeeOffer(offerStatus) && typeof price !== 'undefined';

  const priceADisplay = getPriceDisplayValues(price);
  const priceBDisplay = getPriceDisplayValues(excelPrice);

  const columns = React.useMemo((): Column<CurrentMaintenanceObjectDto>[] => {
    return [
      {
        Header: 'Dateien',
        id: 'files',
        accessor: (maintenance) => currentMaintenanceObjectId(maintenance),
        // @ts-ignore
        Cell: (cell) => <FilesStatusCell cell={cell} />,
      },
      {
        Header: 'Gebäude',
        accessor: (maintenance) =>
          buildings?.find(({ id }) => id === maintenance.buildingId)?.name,
      },
      {
        Header: 'Interne Bezeichnung',
        accessor: 'name',
      },
      {
        Header: 'Wartungstyp',
        accessor: (maintenance) => readableServiceType(maintenance.type).label,
      },
      {
        Header: 'Nächste Fälligkeit',
        id: 'dueDate',
        accessor: 'dueDate',
        // @ts-ignore
        Cell: (cell) => <DueDateCell cell={cell} />,
      },

      {
        Header: priceADisplay?.displayName,
        id: 'priceObjectPrice',
        accessor: getMaintenanceBasePrice(price),
        // @ts-ignore
        Cell: ({ value }: { value: number }) => (
          <div className="text-right">{formatEuros(value)}</div>
        ),
      },
      {
        Header: priceBDisplay?.displayName,
        id: 'priceBObjectPrice',
        accessor: getMaintenanceBasePrice(excelPrice),
        // @ts-ignore
        Cell: ({ value }: { value: number }) => (
          <div className="text-right">{formatEuros(value)}</div>
        ),
      },
    ];
  }, [buildings, price, excelPrice]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<CurrentMaintenanceObjectDto>(
      {
        columns,
        data: maintenances,
        initialState: {
          // eslint-disable-next-line no-nested-ternary
          hiddenColumns: !isMaintenanceTeamMemberAdmin
            ? ['priceObjectPrice', 'priceBObjectPrice']
            : !priceBDisplay
            ? ['priceBObjectPrice']
            : [],
          sortBy: useMemo(
            () => [
              {
                id: 'Gebäude',
                desc: false,
              },
              {
                id: 'name',
                desc: false,
              },
            ],
            []
          ),
        },
      },
      useSortBy
    );

  const priceableMaintenanceObjects = rows.filter(
    ({ original: maintenanceObject }) => {
      const objectsToUse = price.objectPrices;

      return objectsToUse
        ?.map(({ objectId }) => objectId)
        .includes(currentMaintenanceObjectId(maintenanceObject));
    }
  );

  const nonPriceableMaintenanceObjects = rows.filter(
    ({ original: maintenanceObject }) => {
      const objectsToUse = price.unsupportedObjects;

      return objectsToUse
        ?.map(({ objectId }) => objectId)
        .includes(currentMaintenanceObjectId(maintenanceObject));
    }
  );

  const getUniqueReasons = () => {
    const objectsToUse = price.unsupportedObjects;

    if (!objectsToUse) {
      return [];
    }

    const listOfReasons = [
      ...new Set(objectsToUse.map(({ reason }) => reason)),
    ];
    return listOfReasons.map((reason) => {
      return {
        reason,
        objects: objectsToUse
          .filter((unsupported) => unsupported.reason === reason)
          .map((unsupported) => unsupported.objectId),
      };
    });
  };

  const hasDocumentation = () => {
    return (
      maintenances &&
      existingMaintenanceDocumentations &&
      maintenances.some((maintenance) =>
        existingMaintenanceDocumentations.some((maintenanceDocumentation) =>
          maintenanceDocumentation.maintenanceObjectIds.includes(
            currentMaintenanceObjectId(maintenance)
          )
        )
      )
    );
  };

  const getObjectDocumentationSupplement = useCallback(
    (
      objectPriceFrom: MaintenancePriceDto | CustomerMaintenancePriceDto,
      row: Row<CurrentMaintenanceObjectDto>
    ) => {
      const objectPrices =
        isDefined(objectPriceFrom.objectPrices) &&
        isMaintenanceObjectPriceDtoArray(objectPriceFrom.objectPrices)
          ? objectPriceFrom.objectPrices
          : [];

      const objectPrice = objectPrices.find((objPrice) =>
        isMaintenanceObjectDto(row.original)
          ? objPrice.objectId === row.original.id
          : objPrice.objectId === row.original.originalId
      );

      const maximumNetDocumentationSavings =
        objectPrice?.maximumNetDocumentationSavings ?? 0;
      const netDocumentationSavings = objectPrice?.netDocumentationSavings ?? 0;

      return maximumNetDocumentationSavings - netDocumentationSavings;
    },
    []
  );

  const generateRows = (
    r: Row<CurrentMaintenanceObjectDto>[],
    cantBeUsed: boolean
  ) =>
    r.map((row) => {
      const isFallprotection = row.original.type.type === 'fall_protection';
      prepareRow(row);
      return (
        <>
          <tr
            className={`"text-center" ${cantBeUsed && ' bg-amber-100'}`}
            {...row.getRowProps()}
          >
            {row.cells.map((cell) => (
              <td
                className={`border-r-2 border-white pr-2 ${
                  cell.column.id !== 'dueDate' && 'text-left pl-4'
                }`}
                {...cell.getCellProps()}
              >
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
          {isMaintenanceTeamMemberAdmin && isFallprotection && excelPrice && (
            <PriceSupplementRow
              doumentationSupplement={getObjectDocumentationSupplement(
                price,
                row
              )}
              doumentationSupplementExcel={getObjectDocumentationSupplement(
                excelPrice,
                row
              )}
            />
          )}
        </>
      );
    });

  return (
    <div className="max-h-70v overflow-y-auto w-full overflow-x-hidden">
      <table
        {...getTableProps()}
        className="w-full overflow-y-auto border-collapse bg-white"
      >
        <thead className="sticky top-0 w-full bg-gray-light text-black-abs">
          {headerGroups.map((headerGroup) => (
            <tr className="h-9" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  className={`text-left pl-4 ${
                    index === 0 && 'border-b-2'
                  } border-r-2 border-white`}
                  key={column.id}
                  {...column.getHeaderProps}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="overflow-y-auto" {...getTableBodyProps()}>
          {priceableMaintenanceObjects.length > 0 && (
            <>
              {generateRows(priceableMaintenanceObjects, false)}
              {isMaintenanceTeamMemberAdmin && (
                <tr>
                  <td colSpan={4} />
                  <td colSpan={1} className="pl-4">
                    Grundkosten
                  </td>
                  {priceADisplay && (
                    <td colSpan={1} className="text-right pl-4 pr-2">
                      {formatEuros(priceADisplay?.fixedPrice)}
                    </td>
                  )}
                  {priceBDisplay && (
                    <td colSpan={1} className="text-right pl-4 pr-2">
                      {formatEuros(priceBDisplay?.fixedPrice)}
                    </td>
                  )}
                </tr>
              )}

              <PriceComposition
                price={price}
                excelPrice={excelPrice}
                offerStatus={offerStatus}
              />
            </>
          )}

          {!isMaintenanceTeamMemberAdmin && !hasOffer && (
            <tr>
              <td className="pb-4 text-left pl-4" colSpan={5}>
                <div className=" font-bold">Hinweis:</div>
                <p>
                  {priceableMaintenanceObjects.length > 0
                    ? 'Die Preisschätzung basiert auf den getroffenen Benutzerangaben (Qualität der erfassten Parameter und der gesendeten Dateien). Sie ist daher vorbehaltlich zu verstehen. An- und Abfahrt sowie die Erstellung der Wartungsdokumentation sind Inklusivleistungen.'
                    : 'Die Auswahl enthält nur Wartungsaufgaben, die noch nicht unterstützt werden.'}
                </p>
              </td>
            </tr>
          )}

          {!isMaintenanceTeamMemberAdmin &&
            hasOffer &&
            (priceADisplay?.hasDocumentationDiscount ? (
              <tr>
                <td
                  className="pb-4 text-left pl-4"
                  colSpan={priceBDisplay ? 6 : 5}
                >
                  <div>
                    <div>
                      <strong>Wir haben gute Neuigkeiten für Dich!</strong>
                      <div>
                        Nach Durchsicht und Prüfung Deiner uns zur Verfügung
                        gestellten Unterlagen, konnten wir erfolgreich
                        feststellen, dass unsere Mindestanforderungen an eine
                        aussagekräftige Montage- und Fotodokumentation erfüllt
                        sind.
                      </div>
                      <div>{`Wir konnten den Preis (netto) somit um ${formatEuros(
                        priceADisplay?.discount
                      )} reduzieren.`}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              hasDocumentation() && (
                <tr>
                  <td className="pb-4" colSpan={priceBDisplay ? 6 : 5}>
                    <p>
                      <strong>
                        Durch aussagekräftige Montage- und Fotodokumentationen
                        Kosten und Mühen sparen:
                      </strong>{' '}
                      Sofern die hochgeladenen Dateien aussagekräftige Montage-
                      und Fotodokumentationen der angefragten Absturzsicherung
                      enthalten, kann es zu einer Preisreduzierung kommen.
                    </p>
                  </td>
                </tr>
              )
            ))}

          {nonPriceableMaintenanceObjects &&
            nonPriceableMaintenanceObjects?.length > 0 && (
              <>
                <tr className="bg-amber-100 border-gray-dark">
                  <td className="pt-4 p-2" colSpan={priceBDisplay ? 6 : 5}>
                    <span className="font-bold">Achtung!</span> Folgende
                    Wartungsobjekte sind nicht in der Kalkulation enthalten:
                  </td>
                </tr>
                {getUniqueReasons().map((reason) => {
                  return (
                    <>
                      <tr className="border-t-2 bg-amber-100 border-gray-dark">
                        <td
                          className="pt-4 p-2 font-bold"
                          colSpan={priceBDisplay ? 6 : 5}
                        >
                          {reason.reason &&
                            priceIndicationMessages[reason.reason]}
                        </td>
                      </tr>
                      {generateRows(
                        nonPriceableMaintenanceObjects.filter((object) =>
                          reason.objects.includes(
                            currentMaintenanceObjectId(object.original)
                          )
                        ),
                        true
                      )}
                    </>
                  );
                })}
              </>
            )}
        </tbody>
      </table>
    </div>
  );
};

MaintenanceRequestPriceOverviewTable.defaultProps = {
  offerStatus: undefined,
  buildings: undefined,
  excelPrice: undefined,
};

export default MaintenanceRequestPriceOverviewTable;
