import React from 'react';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const BuildingsGraph: React.FC<{ graphData: any }> = ({ graphData }) => {
  const percentageToFix = (
    100 -
    ((graphData &&
      graphData.find((data) => data.id === 'HEALTHY')?.percentage) ||
      0)
  ).toFixed(0);

  return (
    <div className="h-96">
      <ResponsiveContainer width={512}>
        <PieChart>
          <Pie
            dataKey="value"
            data={graphData}
            innerRadius={100}
            outerRadius={140}
            fill="#82ca9d"
            isAnimationActive={false}
            label={(entry) => `${entry.value} Gebäude`}
          >
            <Label
              style={{ fontWeight: 'bold' }}
              position="center"
              value={`${percentageToFix}% Handlungsbedarf`}
            />
            {graphData.map((entry, index) => (
              <Cell key={`cell-${entry?.id}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BuildingsGraph;
