import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';
import { DomainRule } from '@warthungshelden/domain/common';

export class MustOnlyCloseDocumentUpload<
  T extends { objectsWithOpenDocumentUpload: string[] }
> extends DomainRule<T, T> {
  constructor(private update: Partial<T>) {
    super();
  }

  protected async rule(entity: T) {
    if (!this.update?.objectsWithOpenDocumentUpload) {
      return entity;
    }

    const addsObjects = !this.update.objectsWithOpenDocumentUpload.every(
      (objectId) => entity.objectsWithOpenDocumentUpload.includes(objectId)
    );

    if (addsObjects) {
      throw new DomainRuleNotSatisfiedError(MustOnlyCloseDocumentUpload);
    }

    return entity;
  }
}
