import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  MaintenanceRequestStatus,
  RequestStatusEnum,
} from '../../maintenance-request';

export class OwnerMustOnlyCancelRequest<
  T extends { status?: MaintenanceRequestStatus }
> extends DomainRule<T, T> {
  protected async rule(entity: T) {
    const onlySetsStatus = Object.entries(entity)
      .filter(byUndefined())
      .every(([field]) => ['id', 'status', 'ownerId'].includes(field));

    if (!onlySetsStatus || entity.status !== RequestStatusEnum.CANCELED) {
      throw new DomainRuleNotSatisfiedError(OwnerMustOnlyCancelRequest);
    }

    return entity;
  }
}

function byUndefined() {
  return ([, value]) => typeof value !== 'undefined';
}
