import React from 'react';

import { CustomerOrMaintenanceTeamOfferDto } from '@wartungshelden/shared-types';

import TextWithLabel from '../../../components/Basics/TextWithLabel';

interface CustomerReferenceProps {
  offer?: CustomerOrMaintenanceTeamOfferDto | null;
}

const CustomerReference: React.FC<
  React.PropsWithChildren<CustomerReferenceProps>
> = ({ offer }) => {
  return offer?.decision.status !== 'draft' ? (
    <TextWithLabel label="Interne Bestellnummer:" labelStyle="font-normal">
      {offer?.customerReference ?? 'nicht angegeben'}
    </TextWithLabel>
  ) : null;
};

CustomerReference.defaultProps = {
  offer: undefined,
};

export default CustomerReference;
