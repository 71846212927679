import React from 'react';

interface PageHeaderProps {
  hasHighlight?: boolean;
}

const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({
  children,
  hasHighlight,
}) => (
  <div
    className={`flex items-center justify-between text-5xl font-medium mb-4 ${
      hasHighlight && 'border-l-8 border-red pt-2 pb-2'
    }`}
    data-cy="page-header"
  >
    {children}
  </div>
);

PageHeader.defaultProps = {
  hasHighlight: false,
};

export const PageHeaderOverview: React.FC<React.PropsWithChildren> = ({
  children,
}) => <PageHeader hasHighlight>{children}</PageHeader>;
export default PageHeader;
