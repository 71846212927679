import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  MaintenanceOffer,
  MaintenanceOfferStatusEnum,
} from '../../maintenance-offer';

export class MustNotChangeDecisionRule<
  T extends Pick<MaintenanceOffer, 'decision'>
> extends DomainRule<T, T> {
  constructor(private update: Partial<T>) {
    super();
  }

  protected async rule(entity: T) {
    const hasDecision =
      entity.decision?.status === MaintenanceOfferStatusEnum.DECLINED ||
      entity.decision?.status === MaintenanceOfferStatusEnum.ACCEPTED;

    const decisionChanged =
      this.update.decision?.status &&
      this.update.decision?.status !== entity.decision.status;

    if (decisionChanged && hasDecision) {
      throw new DomainRuleNotSatisfiedError(MustNotChangeDecisionRule);
    }

    return entity as T & { documentName: string };
  }
}
