import React from 'react';

import { InputFieldProps } from '../Basics/Inputs/InputField';
import PhoneInputField from '../Basics/Inputs/PhoneInputField';

const AuthPhoneInputField: React.FC<
  React.PropsWithChildren<InputFieldProps<unknown>>
> = ({ label, ...props }) => (
  <>
    <label
      htmlFor={props.name}
      className="font-semibold text-sm text-gray-600 pb-1 block"
    >
      {label}
    </label>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <PhoneInputField {...props} />
  </>
);

export default AuthPhoneInputField;
