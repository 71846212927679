import React from 'react';

import DropdownField, { DropdownFieldOption } from './DropdownField';
import FormikCombobox from './FormikCombobox';

type DropDownLayout = 'sideBySide' | 'amongEachOther';

interface DropdownFieldLayoutProps {
  label?: string | null;
  options: string[] | DropdownFieldOption[] | Record<string, string>;
  name?: string;
  onSelect?: ((value: any) => void) | null;
  disabled?: boolean;
  layout?: DropDownLayout;
  labelWidthTailwindClass?: string;
  searchable?: boolean;
}

const DropdownFieldLayout: React.FC<
  React.PropsWithChildren<DropdownFieldLayoutProps>
> = ({
  label,
  options,
  name,
  onSelect,
  disabled,
  layout,
  labelWidthTailwindClass,
  searchable,
}) => {
  const isDropDownFieldOption = (
    opt: DropdownFieldOption[] | string[]
  ): opt is DropdownFieldOption[] => {
    const option = opt[0];
    if (typeof option === 'string') {
      return false;
    }
    return opt && 'disabled' in option;
  };

  const getDropdownField = () => {
    if (
      searchable &&
      name &&
      Array.isArray(options) &&
      isDropDownFieldOption(options)
    ) {
      return (
        <FormikCombobox name={name} options={options} disabled={disabled} />
      );
    }
    return (
      <DropdownField
        options={options}
        name={name}
        disabled={disabled}
        onSelect={onSelect}
      />
    );
  };

  if (layout === 'amongEachOther') {
    return (
      <div
        data-cy="components-basics-dropdown-field-layout"
        className="flex relative w-full items-center"
      >
        {label && (
          <label
            htmlFor={name}
            className={`flex ${labelWidthTailwindClass} mr-4`}
          >
            {label}
          </label>
        )}
        <div className="relative flex flex-1">{getDropdownField()}</div>
      </div>
    );
  }

  return (
    <div className="inline-block relative w-full">
      {label && (
        <label htmlFor={name} className="font-bold">
          {label}
        </label>
      )}
      {getDropdownField()}
    </div>
  );
};

DropdownFieldLayout.defaultProps = {
  label: undefined,
  onSelect: null,
  disabled: false,
  name: undefined,
  layout: 'amongEachOther',
  labelWidthTailwindClass: 'w-64',
  searchable: false,
};

export default DropdownFieldLayout;

export const DropdownFieldLabelAmong: React.FC<
  React.PropsWithChildren<DropdownFieldLayoutProps>
> = (props) => (
  <DropdownFieldLayout
    {...props} //  eslint-disable-line react/jsx-props-no-spreading
    layout="amongEachOther"
    labelWidthTailwindClass="w-80"
  />
);

DropdownFieldLabelAmong.defaultProps = {
  label: undefined,
  onSelect: null,
  disabled: false,
  name: undefined,
  layout: 'amongEachOther',
  labelWidthTailwindClass: 'w-64',
  searchable: false,
};
