import { Formik } from 'formik';
import React, { useContext } from 'react';
import { FaCheckCircle, FaSave, FaTimesCircle } from 'react-icons/fa';

import { CustomerDto } from '@wartungshelden/shared-types';

import { userNotificationValidationSchema } from '../../constants/MaintenanceYupSchema';
import { SessionContext } from '../../contexts/SessionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import {
  useOwnCustomer,
  useUpdateCustomer,
} from '../../services/api/customer/customer-api';
import Button from '../Basics/Buttons/Button';
import Modal from '../Basics/Modals/Modal';
import { InputField, Toggle, UnitInputField } from '../index';
import { NotificationModalFormikValues } from './types/customerModalFormikValues';

interface CustomerDataModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const NotificationsModal: React.FC<
  React.PropsWithChildren<CustomerDataModalProps>
> = ({ onConfirm, onCancel }) => {
  const { userInfo } = useContext(SessionContext);
  const { data: customer } = useOwnCustomer();
  const { mutateAsync: updateCustomer } = useUpdateCustomer();
  const {
    showNotificationForm,
    setShowNotificationForm,
    isNotificationOpen,
    setIsNotificationOpen,
  } = useContext(SettingsContext);

  const formikValuesToCreateCustomerDto = (
    values: NotificationModalFormikValues,
    customerToDto: CustomerDto
  ): CustomerDto => {
    return {
      ...customerToDto,
      notification: {
        email: values.reminderEmail,
        isReminderEnabled: values.enableReminder,
        isReminderWhenDueEnabled: values.enableReminderWhenDue,
        isTestUserContactingEnabled: values.enableTestUserContacting,
        daysInAdvance: values.reminderDaysInAdvance,
        isStatusUpdatesEnabled: values.enableStatusUpdates,
      },
    };
  };

  const initialValues: NotificationModalFormikValues = {
    reminderEmail: customer?.notification.email
      ? customer?.notification.email
      : userInfo?.email,
    enableReminder: customer?.notification?.isReminderEnabled || false,
    enableReminderWhenDue:
      customer?.notification.isReminderWhenDueEnabled || false,
    reminderDaysInAdvance: customer?.notification?.daysInAdvance || 60,
    enableStatusUpdates:
      customer?.notification?.isStatusUpdatesEnabled || false,
    enableTestUserContacting:
      customer?.notification.isTestUserContactingEnabled || false,
  };

  return (
    <Modal
      isOpen={isNotificationOpen}
      headline="Benachrichtigungen"
      subHeadline="Wir benachrichtigen Dich bei wichtigen Ereignissen per E-Mail."
    >
      {showNotificationForm ? (
        <Formik
          initialValues={initialValues}
          validationSchema={userNotificationValidationSchema}
          onSubmit={async (values: NotificationModalFormikValues) => {
            if (!customer) return;
            await updateCustomer(
              formikValuesToCreateCustomerDto(values, customer)
            );
            onConfirm();
          }}
        >
          {({ values, submitForm, setFieldValue, isValid, dirty }) => {
            return (
              <>
                <div className="flex flex-col flex-1 bg-white px-8 py-6">
                  <div>
                    <InputField label="E-Mail Adresse" name="reminderEmail" />
                    {!userInfo?.email && (
                      <p className="text-red">
                        Solange keine E-Mail Adresse angegeben wurde, können die
                        Benachrichtigungen nicht gesendet werden.
                      </p>
                    )}
                  </div>

                  <strong className="mt-6 mb-2">
                    Benachrichtigungstypen wählen:
                  </strong>

                  <div className="ml-6">
                    <Toggle
                      label="anstehende Fälligkeiten"
                      boldLabel
                      onChange={() => {
                        setFieldValue('enableReminder', !values.enableReminder);
                      }}
                      checked={values.enableReminder}
                      name="enableReminder"
                    />
                    <div className="pl-16 ml-2">
                      Wir erinnern Dich rechtzeitig an die nächste Fälligkeit
                      einer Wartung.
                      {values.enableReminder && (
                        <UnitInputField
                          label="Vorlaufzeit:"
                          useLabelAutoWidth
                          unit="Tage vor dem 1. Tag des Fälligkeitsmonats"
                          name="reminderDaysInAdvance"
                        />
                      )}
                    </div>
                    <Toggle
                      label="abgelaufene Fälligkeiten"
                      boldLabel
                      onChange={() => {
                        setFieldValue(
                          'enableReminderWhenDue',
                          !values.enableReminderWhenDue
                        );
                      }}
                      checked={values.enableReminderWhenDue}
                      name="enableReminderWhenDue"
                    />
                    <div className="pl-16 ml-2">
                      Wir informieren Dich bei Ablauf des Fälligkeitsmonats
                      einer Wartung.
                    </div>
                    <div className="mt-4">
                      <Toggle
                        label="Statusänderungen von Anfragen und Aufträgen"
                        boldLabel
                        onChange={() => {
                          setFieldValue(
                            'enableStatusUpdates',
                            !values.enableStatusUpdates
                          );
                        }}
                        checked={values.enableStatusUpdates}
                        name="enableStatus"
                      />
                    </div>
                    <div className="mt-4">
                      <Toggle
                        label="Einverständnis zur Kontaktaufnahme"
                        boldLabel
                        onChange={() => {
                          setFieldValue(
                            'enableTestUserContacting',
                            !values.enableTestUserContacting
                          );
                        }}
                        checked={values.enableTestUserContacting}
                        name="enableTestUserContacting"
                      />
                      <div className="pl-16 ml-2">
                        Wir kontaktieren Dich per E-Mail oder telefonisch zur
                        Verbesserung unserer Software.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between h-24 px-8">
                  <Button
                    label="Abbrechen"
                    icon={<FaTimesCircle />}
                    onClick={() => {
                      onCancel();
                      setIsNotificationOpen(false);
                    }}
                    className="outline-button"
                  />
                  <Button
                    disabled={!(dirty && isValid)}
                    label="Speichern"
                    onClick={async () => {
                      await submitForm();
                      setIsNotificationOpen(false);
                    }}
                    icon={<FaSave />}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      ) : (
        <div>
          <div className="flex flex-row items-center justify-between first:mr-2 ">
            <p>
              Möchtest Du, dass wir Dich rechtzeitig an die Fälligkeit Deiner
              Wartungen erinnern?
            </p>
          </div>
          <div className="flex flex-row items-center justify-between h-24 px-8">
            <Button
              label="Nein, vielleicht später"
              icon={<FaTimesCircle />}
              onClick={() => {
                setIsNotificationOpen(false);
                setShowNotificationForm(true);
              }}
              className="outline-button"
            />
            <Button
              label="Ja, bitte"
              onClick={() => {
                setShowNotificationForm(true);
              }}
              icon={<FaCheckCircle />}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default NotificationsModal;
