import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CreateBuildingDto,
  RequestStatusEnum,
} from '@wartungshelden/shared-types';

import {
  Card,
  InputField,
  PageContainer,
  PageHeader,
  PhoneInputField,
  Toolbar,
  UpdateFooter,
} from '../../../components';
import Confirmation from '../../../components/Basics/Confirmation';
import FormWrapper from '../../../components/Basics/FormWrapper';
import CountryDropDown from '../../../components/Basics/Inputs/CountryDropDown';
import Toggle from '../../../components/Basics/Inputs/Toggle';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import { InfoMessageBox } from '../../../components/Basics/MessageBox';
import Modal from '../../../components/Basics/Modals/Modal';
import BuildingTypeSelectField from '../../../components/Buildings/BuildingTypeSelectField';
import { BuildingFormSchema } from '../../../constants/BuildingsYupSchema';
import { isRequiredField } from '../../../constants/MaintenanceUtils';
import { routes } from '../../../constants/routes';
import {
  useBuildings,
  useCreateBuilding,
  useRemoveBuilding,
  useUpdateBuilding,
} from '../../../services/api/buildings/buildings-api';
import { useMaintenanceObjects } from '../../../services/api/maintenance-objects/maintenance-objects-api';
import { useMaintenanceRequests } from '../../../services/api/maintenance-requests/maintenance-request-api';
import { FormikBuildingValues } from '../../../types/formikBuildingValues';

const BuildingDetailsPage = () => {
  const navigate = useNavigate();
  const { buildingId } = useParams();

  const isUpdateForm = () => !!buildingId;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const { data: buildings } = useBuildings();
  const { mutateAsync: removeBuilding, isLoading: isDeleting } =
    useRemoveBuilding();

  const { mutateAsync: updateBuilding, isLoading: isUpdating } =
    useUpdateBuilding();

  const { mutateAsync: createBuilding, isLoading: isCreating } =
    useCreateBuilding();

  const { data: maintenances, isLoading: isLoadingMaintenances } =
    useMaintenanceObjects();

  const { data: requests, isLoading: isLoadingRequests } =
    useMaintenanceRequests();

  const building = buildings?.find((b) => b.id === buildingId);

  const isBeingUsedInRequestedObject = requests
    ?.filter(({ status }) => status !== RequestStatusEnum.CANCELED)
    .flatMap(({ objectIds }) => objectIds)
    .map((objectId) => maintenances?.find(({ id }) => objectId === id))
    .some((object) => object?.buildingId === buildingId);

  const isBeingUsedInMaintenanceObject = maintenances?.some(
    (maintenance) => maintenance.buildingId === buildingId
  );

  const isEditable = !isBeingUsedInRequestedObject;

  const handleRemoveBuilding = async () => {
    if (building) {
      await removeBuilding(building.id);
    }
    navigate(-1);
  };

  const initialValues: FormikBuildingValues = {
    buildingName: building?.name || '',
    buildingType: building?.buildingType || '',
    // otherBuildingType: building?.otherBuildingType || null,
    addressLineOne: building?.address?.addressLine1 || '',
    addressLineTwo: building?.address?.addressLine2 || '',
    postalCode: building?.address?.postalCode || '',
    city: building?.address?.city || '',
    country: building?.address?.countryISO || 'DE',
    isPublicBuilding: building?.isPublic || false,
    firstName: building?.contact?.firstName || '',
    lastName: building?.contact?.lastName || '',
    position: building?.contact?.position || '',
    email: building?.contact?.email || '',
    phone: building?.contact?.phone || '',
    mobilePhone: building?.contact?.mobilePhone || '',
  };

  const submitBuildingDetailsForm = async (values: FormikBuildingValues) => {
    const newBuilding: CreateBuildingDto = {
      name: values.buildingName,
      buildingType: values.buildingType,
      isPublic: values.isPublicBuilding,
      contact: {
        firstName: values.firstName === '' ? undefined : values.firstName,
        lastName: values.lastName === '' ? undefined : values.lastName,
        email: values.email === '' ? undefined : values.email,
        mobilePhone: values.mobilePhone === '' ? undefined : values.mobilePhone,
        phone: values.phone === '' ? undefined : values.phone,
        position: values.position === '' ? undefined : values.position,
      },
      address: {
        addressLine1:
          values.addressLineOne === '' ? undefined : values.addressLineOne,
        addressLine2:
          values.addressLineTwo === '' ? undefined : values.addressLineTwo,
        countryISO: values.country === '' ? undefined : values.country,
        city: values.city === '' ? undefined : values.city,
        postalCode: values.postalCode === '' ? undefined : values.postalCode,
      },
    };

    if (buildingId) {
      await updateBuilding({ ...newBuilding, id: buildingId });
    } else {
      await createBuilding(newBuilding);
    }
  };

  return (
    <PageContainer>
      <Formik
        validationSchema={BuildingFormSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          await submitBuildingDetailsForm(values);
        }}
      >
        {({ dirty, resetForm, errors, submitForm, setFieldValue, values }) => {
          return (
            <div className="flex flex-col h-full">
              <div className="flex-1 self-stretch">
                <Form className="py-2 h-full w-full">
                  <Toolbar title="Gebäude">
                    {isUpdateForm() && isEditable && (
                      <button
                        type="button"
                        onClick={() => setIsDeleteModalOpen(true)}
                        disabled={!isEditable}
                      >
                        <FaTrashAlt size={25} />
                      </button>
                    )}
                  </Toolbar>

                  {!isEditable && (
                    <div className="pb-8">
                      <InfoMessageBox
                        headline="Änderungen am Gebäude nicht möglich"
                        label="Da bereits Wartungen für dieses Gebäude angefragt wurden, kannst Du die Gebäudedetails nicht mehr bearbeiten."
                      />
                    </div>
                  )}

                  <Card>
                    <PageHeader>Gebäudedetails</PageHeader>
                    <FormWrapper>
                      <InputField
                        label="Gebäudename*"
                        name="buildingName"
                        disabled={!isEditable}
                      />
                      <BuildingTypeSelectField disabled={!isEditable} />
                      <InputField
                        label="Straße, Hausnummer"
                        name="addressLineOne"
                        disabled={!isEditable}
                        isRequired={isRequiredField(
                          BuildingFormSchema,
                          'addressLineOne'
                        )}
                      />
                      <InputField
                        label="Adresszusatz"
                        name="addressLineTwo"
                        disabled={!isEditable}
                      />
                      <InputField
                        label="Postleitzahl*"
                        name="postalCode"
                        disabled={!isEditable}
                      />
                      <InputField
                        label="Stadt*"
                        name="city"
                        disabled={!isEditable}
                      />
                      <CountryDropDown
                        label="Land*"
                        name="country"
                        disabled={!isEditable}
                      />
                    </FormWrapper>
                    <Toggle
                      label="Öffentliches Gebäude (Körperschaft öffentlichen Rechts), z.B.
                      Städte, Gemeinden, Kommunen, Wohnungsbaugesellschaften"
                      name="isPublicBuilding"
                      checked={values.isPublicBuilding}
                      disabled={!isEditable}
                      onChange={() => {
                        setFieldValue(
                          'isPublicBuilding',
                          !values.isPublicBuilding
                        );
                      }}
                    />
                  </Card>
                  <Card>
                    <PageHeader>Ansprechpartner vor Ort</PageHeader>
                    <FormWrapper>
                      <InputField
                        label="Vorname"
                        name="firstName"
                        disabled={!isEditable}
                      />
                      <InputField
                        label="Nachname"
                        name="lastName"
                        disabled={!isEditable}
                      />
                      <InputField
                        label="Position"
                        name="position"
                        disabled={!isEditable}
                      />
                      <InputField
                        label="E-Mail"
                        name="email"
                        disabled={!isEditable}
                      />
                      <PhoneInputField
                        label="Telefon Büro"
                        name="phone"
                        disabled={!isEditable}
                      />
                      <PhoneInputField
                        label="Telefon Mobil"
                        name="mobilePhone"
                        disabled={!isEditable}
                      />
                    </FormWrapper>
                  </Card>
                </Form>
              </div>
              <div className="min-h-[12%]">
                <UpdateFooter
                  dirty={dirty}
                  isValid={Object.keys(errors).length === 0}
                  cancel={() => {
                    resetForm();
                    navigate(`/${routes.buildings}`);
                  }}
                  confirm={() => {
                    submitForm()
                      .then(() => {
                        navigate(-1);
                      })
                      .catch();
                  }}
                  confirmLabel={isUpdateForm() ? 'Aktualisieren' : 'Erstellen'}
                  cancelLabel="Abbrechen"
                />
              </div>
            </div>
          );
        }}
      </Formik>
      <Modal center isOpen={isDeleteModalOpen}>
        {isBeingUsedInMaintenanceObject ? (
          <Confirmation
            question="Das Gebäude enthält Wartungsaufgaben. Du kannst das Gebäude daher nicht löschen."
            confirmLabel="Ok"
            confirm={() => {
              setIsDeleteModalOpen(false);
            }}
          />
        ) : (
          <Confirmation
            question="Möchtest Du dieses Gebäude löschen?"
            confirmLabel="Löschen"
            confirm={() => {
              handleRemoveBuilding().catch();
            }}
            cancel={() => setIsDeleteModalOpen(false)}
          />
        )}
      </Modal>
      <LoadingSpinner
        isLoading={
          isDeleting ||
          isUpdating ||
          isCreating ||
          isLoadingMaintenances ||
          isLoadingRequests
        }
      />
    </PageContainer>
  );
};

export default BuildingDetailsPage;
