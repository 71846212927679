import { User, toMaintenanceObjectId } from '@warthungshelden/domain/common';
import {
  notNull,
  to,
  toSum,
  whereIsType,
  wherePropertyEquals,
  wherePropertyNotEquals,
} from '@warthungshelden/shared-functions';

import {
  ClimbingProtection,
  FallProtection,
  FallProtectionLocation,
  GroundStairs,
  Ladder,
  Overpass,
  PersonalProtectionEquipment,
  PushLockFallProtectionSystem,
  RailSystem,
  Railing,
  RopeFallProtectionSystem,
  SingleAnchorFallProtectionSystem,
  StairLadder,
} from '../../maintenance-object';
import {
  MaintenanceDiscount,
  MaintenanceObjectPrice,
  MaintenancePrice,
  PriceComponent,
  PriceComponentKind,
  PriceComponentType,
  PriceUnsupportedMaintenanceObject,
} from '../maintenance-price';
import { MaintenanceUnitPriceResolver } from '../maintenance-unit-price-resolver';
import {
  MaintenanceObjectPriceInformation,
  MaintenancePriceStrategy,
  Reasons,
} from './maintenance-price-strategy';
import {
  PriceableBuilding,
  PriceableMaintenanceObject,
} from './priceable-maintenance-object';

function toPriceComponent(
  position: number,
  type: PriceComponentType,
  kind: PriceComponentKind,
  value: number,
  reason: string
): PriceComponent {
  return { type, kind, value, position, reason };
}

type TotalRopeLength = {
  abs: {
    isPublic: Record<FallProtectionLocation, number>;
    isNotPublic: Record<FallProtectionLocation, number>;
  };
  foreign: {
    isPublic: Record<FallProtectionLocation, number>;
    isNotPublic: Record<FallProtectionLocation, number>;
  };
};

export class WartungsheldenPriceStrategy implements MaintenancePriceStrategy {
  private readonly ABS_ID = 'ABS Safety';
  private readonly MSG_UNSUPPORTED_MAINTENANCE_TYPE = 'PI-1';
  private readonly MSG_ERROR_WITH_PRICE_CALCULATION = 'PI-2';
  private readonly MSG_INVALID_NUMBER_OF_SINGLE_ANCHORS = 'PI-3';
  private readonly MSG_INVALID_LADDER_HEIGHT = 'PI-4';
  private readonly MSG_HUGE_LADDERS_UNSUPPORTED = 'PI-5';
  private readonly MSG_INVALID_RAILING_HEIGHT = 'PI-6';
  private readonly MSG_INVALID_NUMBER_OF_STEPS = 'PI-7';
  private readonly MSG_INVALID_CLIMBING_PROTECTION_HEIGHT = 'PI-8';

  constructor(private unitPriceResolver: MaintenanceUnitPriceResolver) {}

  private readonly easyAccessBuildingTypes = new Set([
    'Bürogebäude',
    'Industriegebäude',
    'Schule',
  ]);

  private isAbsProduct(manufacturer: string | undefined | null) {
    return manufacturer === this.ABS_ID;
  }

  private hasEasyAccess(buildingType?: string) {
    return (
      typeof buildingType !== 'undefined' &&
      this.easyAccessBuildingTypes.has(buildingType)
    );
  }

  private basePrice(
    type:
      | typeof Ladder
      | typeof Railing
      | typeof FallProtection
      | typeof PersonalProtectionEquipment
      | typeof StairLadder
      | typeof Overpass
      | typeof GroundStairs
      | typeof ClimbingProtection
  ) {
    if (
      type === Ladder ||
      type === Railing ||
      type === FallProtection ||
      type === PersonalProtectionEquipment ||
      type === StairLadder ||
      type === Overpass ||
      type === GroundStairs ||
      type === ClimbingProtection
    ) {
      return 450;
    }

    return 0;
  }

  private fallProtectionRopeAndRailSystemUnitPrice(
    manufacturer: string | undefined | null,
    length: number
  ) {
    if (this.isAbsProduct(manufacturer)) {
      if (length < 250) {
        return 1.88;
      }
      if (length < 500) {
        return 1.55;
      }
      if (length < 1000) {
        return 1.3;
      }
      return 1.15;
    } else {
      if (length < 250) {
        return 4;
      }
      if (length < 500) {
        return 3.2;
      }
      if (length < 1000) {
        return 2.75;
      }
      return 2.2;
    }
  }

  protected railSystemLengthSumByObjects(
    objects: PriceableMaintenanceObject[]
  ) {
    const sumLengthPrices = (objects: PriceableMaintenanceObject[]) =>
      objects
        .map(to('type'))
        .filter(whereIsType(RailSystem))
        .map(to('length'))
        .reduce(toSum(), 0);

    const lengthAbs = sumLengthPrices(
      objects.filter(wherePropertyEquals('manufacturer', this.ABS_ID))
    );

    const lengthForeign = sumLengthPrices(
      objects.filter(wherePropertyNotEquals('manufacturer', this.ABS_ID))
    );

    return {
      abs: lengthAbs,
      foreign: lengthForeign,
    };
  }

  protected fallProtectionRopeLengthSumByObjects(
    information: MaintenanceObjectPriceInformation[]
  ) {
    const publicObjects = information.filter(
      (priceInfo) => priceInfo.building?.isPublic
    );
    const nonPublicObjects = information.filter(
      (priceInfo) => !priceInfo.building?.isPublic
    );
    const sumRopePrices = (
      objects: PriceableMaintenanceObject[],
      location: FallProtectionLocation
    ) =>
      objects
        .map(to('type'))
        .filter(whereIsType(FallProtection))
        .filter(wherePropertyEquals('location', location))
        .map(to('system'))
        .filter(whereIsType(RopeFallProtectionSystem))
        .map(to('ropeLength'))
        .reduce(toSum(), 0);

    const publicRopeLengthAbsRoof = sumRopePrices(
      publicObjects
        .map(to('object'))
        .filter(wherePropertyEquals('manufacturer', this.ABS_ID)),
      'Roof'
    );

    const publicRopeLengthAbsFacade = sumRopePrices(
      publicObjects
        .map(to('object'))
        .filter(wherePropertyEquals('manufacturer', this.ABS_ID)),
      'Facade'
    );

    const publicRopeLengthAbsOverhead = sumRopePrices(
      publicObjects
        .map(to('object'))
        .filter(wherePropertyEquals('manufacturer', this.ABS_ID)),
      'Overhead'
    );

    const nonPublicRopeLengthAbsRoof = sumRopePrices(
      nonPublicObjects
        .map(to('object'))
        .filter(wherePropertyEquals('manufacturer', this.ABS_ID)),
      'Roof'
    );

    const nonPublicRopeLengthAbsFacade = sumRopePrices(
      nonPublicObjects
        .map(to('object'))
        .filter(wherePropertyEquals('manufacturer', this.ABS_ID)),
      'Facade'
    );

    const nonPublicRopeLengthAbsOverhead = sumRopePrices(
      nonPublicObjects
        .map(to('object'))
        .filter(wherePropertyEquals('manufacturer', this.ABS_ID)),
      'Overhead'
    );

    const publicRopeLengthForeignRoof = sumRopePrices(
      publicObjects
        .map(to('object'))
        .filter(wherePropertyNotEquals('manufacturer', this.ABS_ID)),
      'Roof'
    );

    const publicRopeLengthForeignFacade = sumRopePrices(
      publicObjects
        .map(to('object'))
        .filter(wherePropertyNotEquals('manufacturer', this.ABS_ID)),
      'Facade'
    );

    const publicRopeLengthForeignOverhead = sumRopePrices(
      publicObjects
        .map(to('object'))
        .filter(wherePropertyNotEquals('manufacturer', this.ABS_ID)),
      'Overhead'
    );

    const nonPublicRopeLengthForeignRoof = sumRopePrices(
      nonPublicObjects
        .map(to('object'))
        .filter(wherePropertyNotEquals('manufacturer', this.ABS_ID)),
      'Roof'
    );

    const nonPublicRopeLengthForeignFacade = sumRopePrices(
      nonPublicObjects
        .map(to('object'))
        .filter(wherePropertyNotEquals('manufacturer', this.ABS_ID)),
      'Facade'
    );

    const nonPublicRopeLengthForeignOverhead = sumRopePrices(
      nonPublicObjects
        .map(to('object'))
        .filter(wherePropertyNotEquals('manufacturer', this.ABS_ID)),
      'Overhead'
    );

    return {
      abs: {
        isPublic: {
          Roof: publicRopeLengthAbsRoof,
          Facade: publicRopeLengthAbsFacade,
          Overhead: publicRopeLengthAbsOverhead,
        },
        isNotPublic: {
          Roof: nonPublicRopeLengthAbsRoof,
          Facade: nonPublicRopeLengthAbsFacade,
          Overhead: nonPublicRopeLengthAbsOverhead,
        },
      },
      foreign: {
        isPublic: {
          Roof: publicRopeLengthForeignRoof,
          Facade: publicRopeLengthForeignFacade,
          Overhead: publicRopeLengthForeignOverhead,
        },
        isNotPublic: {
          Roof: nonPublicRopeLengthForeignRoof,
          Facade: nonPublicRopeLengthForeignFacade,
          Overhead: nonPublicRopeLengthForeignOverhead,
        },
      },
    };
  }

  protected fallProtectionSleevesUnitPriceByObjects(
    objects: MaintenanceObjectPriceInformation[]
  ) {
    const sumSleevUnitPrice = (objects: MaintenanceObjectPriceInformation[]) =>
      objects
        .map(to('object'))
        .map(to('type'))
        .filter(whereIsType(FallProtection))
        .map(to('system'))
        .filter(whereIsType(PushLockFallProtectionSystem))
        .map(to('sleeves'))
        .reduce(toSum(), 0);

    const sleevUnitPriceEasyAccess = sumSleevUnitPrice(
      objects.filter((object) =>
        this.hasEasyAccess(object.building?.buildingType)
      )
    );

    const sleevUnitPriceHardAccess = sumSleevUnitPrice(
      objects.filter(
        (object) => !this.hasEasyAccess(object.building?.buildingType)
      )
    );

    return {
      easyAccess: this.fallProtectionPushLockUnitPrice(
        sleevUnitPriceEasyAccess
      ),
      hardAccess: this.fallProtectionPushLockUnitPrice(
        sleevUnitPriceHardAccess
      ),
    };
  }

  protected fallProtectionSingleAnchorAmountByObjects(
    objects: MaintenanceObjectPriceInformation[]
  ) {
    const sumSingleAnchorsUnits = (
      objects: MaintenanceObjectPriceInformation[]
    ) =>
      objects
        .map(to('object'))
        .map(to('type'))
        .filter(whereIsType(FallProtection))
        .map(to('system'))
        .filter(whereIsType(SingleAnchorFallProtectionSystem))
        .map(to('singleAnchors'))
        .reduce(toSum(), 0);

    const singleAnchorsAbsUnits = sumSingleAnchorsUnits(
      objects.filter((object) => this.isAbsProduct(object.object.manufacturer))
    );

    const singleAnchorsNotAbsUnits = sumSingleAnchorsUnits(
      objects.filter((object) => !this.isAbsProduct(object.object.manufacturer))
    );

    return {
      abs: singleAnchorsAbsUnits,
      foreign: singleAnchorsNotAbsUnits,
    };
  }

  private fallProtectionPushLockUnitPrice(quantity: number) {
    if (quantity <= 15) {
      return 23;
    }
    if (quantity <= 50) {
      return 17;
    }
    if (quantity <= 100) {
      return 14;
    }
    if (quantity <= 500) {
      return 9;
    }
    return 7;
  }

  private qualifiesForRopeSystemMassDiscount = (
    totalRopeLength: TotalRopeLength,
    isAbs: boolean,
    isPublicBuilding: boolean,
    location: FallProtectionLocation,
    lowerBoundary: number,
    higherBoundray: number
  ): boolean => {
    if (isAbs) {
      if (isPublicBuilding) {
        return (
          totalRopeLength.abs.isPublic[location] >= lowerBoundary &&
          totalRopeLength.abs.isPublic[location] < higherBoundray
        );
      }
      return (
        totalRopeLength.abs.isNotPublic[location] >= lowerBoundary &&
        totalRopeLength.abs.isNotPublic[location] < higherBoundray
      );
    } else {
      if (isPublicBuilding) {
        return (
          totalRopeLength.foreign.isPublic[location] >= lowerBoundary &&
          totalRopeLength.foreign.isPublic[location] < higherBoundray
        );
      }
      return (
        totalRopeLength.foreign.isNotPublic[location] >= lowerBoundary &&
        totalRopeLength.foreign.isNotPublic[location] < higherBoundray
      );
    }
  };

  private fallProtectionRopeSystemPrice(
    id: string,
    manufacturer: string | undefined | null,
    location: FallProtectionLocation | undefined | null,
    ropeSystem: RopeFallProtectionSystem,
    hasDocumentation: boolean | null,
    isPublicBuilding: boolean,
    totalRopeLength: TotalRopeLength
  ): MaintenanceObjectPrice | PriceUnsupportedMaintenanceObject {
    const basePrice = this.basePrice(FallProtection);

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      basePrice!,
      Reasons.BASIS
    );

    if (
      ropeSystem.ropeLength <= 0 ||
      ropeSystem.systemAnchors <= 0 ||
      ropeSystem.singleAnchors < 0
    ) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_ERROR_WITH_PRICE_CALCULATION,
      });
    }

    const isAbs = this.isAbsProduct(manufacturer);

    const systemAnchorUnitPrice = isAbs ? 9.95 : 11.0;
    const singleAnchorUnitPrice = 17.0;

    const anchorPrice =
      systemAnchorUnitPrice * ropeSystem.systemAnchors +
      singleAnchorUnitPrice * ropeSystem.singleAnchors;

    const variablePrice =
      anchorPrice + (isAbs ? 1.88 : 4) * ropeSystem.ropeLength;

    const ropeLengthDiscountOne =
      anchorPrice +
      (isAbs ? 1.55 : 3.2) * ropeSystem.ropeLength -
      variablePrice;

    const ropeLengthDiscountTwo =
      anchorPrice +
      (isAbs ? 1.3 : 2.75) * ropeSystem.ropeLength -
      variablePrice;

    const ropeLengthDiscountThree =
      anchorPrice +
      (isAbs ? 1.15 : 2.2) * ropeSystem.ropeLength -
      variablePrice;

    const locationSurcharge =
      location === 'Facade'
        ? 1.4
        : location === 'Overhead' && isAbs
        ? 1.9
        : location === 'Overhead'
        ? 1.8
        : 1;

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      variablePrice,
      Reasons.BASIS
    );

    const firstRopeLengthDiscount = toPriceComponent(
      2,
      'mass_discount',
      'absolute',
      ropeLengthDiscountOne,
      Reasons.ROPE_LENGTH_MASS_DISCOUNT
    );

    const secondRopeLengthDiscount = toPriceComponent(
      3,
      'mass_discount',
      'absolute',
      ropeLengthDiscountTwo,
      Reasons.ROPE_LENGTH_MASS_DISCOUNT_2
    );

    const thirdRopeLengthDiscount = toPriceComponent(
      4,
      'mass_discount',
      'absolute',
      ropeLengthDiscountThree,
      Reasons.ROPE_LENGTH_MASS_DISCOUNT_3
    );

    const locationComponent = toPriceComponent(
      5,
      'location_surcharge',
      'factor',
      locationSurcharge,
      Reasons.LOCATION
    );

    const documentationMissingComponent = toPriceComponent(
      6,
      'documentation_missing',
      'factor',
      1.2,
      Reasons.DOCUMENTATION
    );

    const publicBuildingSurcharge = toPriceComponent(
      7,
      'public_building',
      'factor',
      1.2,
      Reasons.PUBLIC_BUILDING
    );

    const lengthLocation = location ?? 'Roof';

    const qualifiesForFirstMassDiscount =
      this.qualifiesForRopeSystemMassDiscount(
        totalRopeLength,
        isAbs,
        isPublicBuilding,
        lengthLocation,
        250,
        500
      );

    const qualifiesForSecondMassDiscount =
      this.qualifiesForRopeSystemMassDiscount(
        totalRopeLength,
        isAbs,
        isPublicBuilding,
        lengthLocation,
        500,
        1000
      );

    const qualifiesForThirdMassDiscount =
      this.qualifiesForRopeSystemMassDiscount(
        totalRopeLength,
        isAbs,
        isPublicBuilding,
        lengthLocation,
        1000,
        Infinity
      );

    const variableActualComponents = [
      variableBasePriceComponent,
      qualifiesForFirstMassDiscount ? firstRopeLengthDiscount : null,
      qualifiesForSecondMassDiscount ? secondRopeLengthDiscount : null,
      qualifiesForThirdMassDiscount ? thirdRopeLengthDiscount : null,
      locationSurcharge !== 1 ? locationComponent : null,
      !hasDocumentation ? documentationMissingComponent : null,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const variableMaxComponents = [
      variableBasePriceComponent,
      locationComponent,
      documentationMissingComponent,
      thirdRopeLengthDiscount,
      publicBuildingSurcharge,
    ];

    const fixedActualComponents = [
      fixedBasePriceComponent,
      locationSurcharge !== 1 ? locationComponent : null,
      !hasDocumentation ? documentationMissingComponent : null,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const fixedMaximumComponents = [
      fixedBasePriceComponent,
      locationComponent,
      documentationMissingComponent,
      publicBuildingSurcharge,
    ];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private railSystemPrice(
    id: string,
    manufacturer: string | undefined | null,
    railSystem: RailSystem,
    hasDocumentation: boolean | null,
    isPublicBuilding: boolean,
    totalRailingSystemLength: {
      abs: number;
      foreign: number;
    }
  ) {
    const basePrice = this.basePrice(FallProtection) * 1.4;

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      basePrice!,
      Reasons.BASIS
    );

    if (railSystem.brackets <= 0 || railSystem.length <= 0) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_ERROR_WITH_PRICE_CALCULATION,
      });
    }

    const isAbsProduct = this.isAbsProduct(manufacturer);
    const systemAnchorUnitPrice = isAbsProduct ? 9.95 : 11.0;

    const systemAnchorPrice = systemAnchorUnitPrice * railSystem.brackets;

    const variablePrice =
      (systemAnchorPrice + (isAbsProduct ? 1.88 : 4) * railSystem.length) * 1.4;

    const railSystemLengthDiscountOne =
      systemAnchorPrice +
      (isAbsProduct ? 1.55 : 3.2) * railSystem.length -
      variablePrice;

    const railSystemLengthDiscountTwo =
      systemAnchorPrice +
      (isAbsProduct ? 1.3 : 2.75) * railSystem.length -
      variablePrice;

    const railSystemLengthDiscountThree =
      systemAnchorPrice +
      (isAbsProduct ? 1.15 : 2.2) * railSystem.length -
      variablePrice;

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      variablePrice,
      Reasons.BASIS
    );

    const firstRailSystemLengthDiscount = toPriceComponent(
      2,
      'mass_discount',
      'absolute',
      railSystemLengthDiscountOne,
      Reasons.SAIL_SYSTEM_LENGTH_MASS_DISCOUNT
    );

    const secondRailSystemLengthDiscount = toPriceComponent(
      3,
      'mass_discount',
      'absolute',
      railSystemLengthDiscountTwo,
      Reasons.SAIL_SYSTEM_LENGTH_MASS_DISCOUNT_2
    );

    const thirdRailSystemLengthDiscount = toPriceComponent(
      4,
      'mass_discount',
      'absolute',
      railSystemLengthDiscountThree,
      Reasons.SAIL_SYSTEM_LENGTH_MASS_DISCOUNT_3
    );

    const documentationMissingComponent = toPriceComponent(
      5,
      'documentation_missing',
      'factor',
      1.2,
      Reasons.DOCUMENTATION
    );

    const publicBuildingSurcharge = toPriceComponent(
      6,
      'public_building',
      'factor',
      1.2,
      Reasons.PUBLIC_BUILDING
    );

    const qualifiesForFirstMassDiscount =
      (isAbsProduct &&
        totalRailingSystemLength.abs >= 250 &&
        totalRailingSystemLength.abs < 500) ||
      (!isAbsProduct &&
        totalRailingSystemLength.foreign >= 250 &&
        totalRailingSystemLength.foreign < 500);

    const qualifiesForSecondMassDiscount =
      (isAbsProduct &&
        totalRailingSystemLength.abs >= 500 &&
        totalRailingSystemLength.abs < 1000) ||
      (!isAbsProduct &&
        totalRailingSystemLength.foreign >= 500 &&
        totalRailingSystemLength.foreign < 1000);

    const qualifiesForThirdMassDiscount =
      (isAbsProduct && totalRailingSystemLength.abs >= 1000) ||
      (!isAbsProduct && totalRailingSystemLength.foreign >= 1000);

    const variableActualComponents = [
      variableBasePriceComponent,
      qualifiesForFirstMassDiscount ? firstRailSystemLengthDiscount : null,
      qualifiesForSecondMassDiscount ? secondRailSystemLengthDiscount : null,
      qualifiesForThirdMassDiscount ? thirdRailSystemLengthDiscount : null,
      !hasDocumentation ? documentationMissingComponent : null,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const variableMaxComponents = [
      variableBasePriceComponent,
      documentationMissingComponent,
      publicBuildingSurcharge,
    ];

    const fixedActualComponents = [
      fixedBasePriceComponent,
      !hasDocumentation ? documentationMissingComponent : null,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const fixedMaximumComponents = [
      fixedBasePriceComponent,
      documentationMissingComponent,
      publicBuildingSurcharge,
    ];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private climbingProtectionUnitPrice(isAbs: boolean, hasLadder: boolean) {
    if (isAbs) {
      return hasLadder ? 9.8 : 15.9;
    } else {
      return hasLadder ? 17.9 : 22.26;
    }
  }

  private climbingEquipmentPrice(
    id: string,
    climbingEquipment: StairLadder | Overpass | GroundStairs
  ) {
    if (
      (climbingEquipment instanceof GroundStairs &&
        climbingEquipment.amount < 0) ||
      (!(climbingEquipment instanceof GroundStairs) &&
        (climbingEquipment.parts.some((steps) => steps < 1 || steps > 8) ||
          !climbingEquipment.parts.length))
    ) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_INVALID_NUMBER_OF_STEPS,
      });
    }

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      climbingEquipment instanceof StairLadder
        ? this.basePrice(StairLadder)
        : this.basePrice(Overpass),
      Reasons.BASIS
    );

    const smallPartVariablePrices =
      (!(climbingEquipment instanceof GroundStairs) &&
        climbingEquipment.smallParts.reduce((prev) => prev + 35, 0)) ||
      0;

    const largePartVariablePrices =
      (!(climbingEquipment instanceof GroundStairs) &&
        climbingEquipment.largeParts.reduce((prev) => prev + 45, 0)) ||
      0;

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      climbingEquipment instanceof GroundStairs
        ? climbingEquipment.amount * 35
        : smallPartVariablePrices + largePartVariablePrices,
      Reasons.BASIS
    );

    const variableActualComponents = [variableBasePriceComponent];
    const variableMaxComponents = [variableBasePriceComponent];
    const fixedActualComponents = [fixedBasePriceComponent];
    const fixedMaximumComponents = [fixedBasePriceComponent];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private climbingProtectionPrice(
    id: string,
    manufacturer: string | undefined | null,
    climbingProtection: ClimbingProtection,
    isPublicBuilding: boolean,
    hasLadder: boolean
  ) {
    if (climbingProtection.length > 30) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_INVALID_CLIMBING_PROTECTION_HEIGHT,
      });
    }

    const isAbs = this.isAbsProduct(manufacturer);

    const baseMeterUnitPrice = isAbs ? 15.9 : 22.26;
    const meterUnitPrice = this.climbingProtectionUnitPrice(isAbs, hasLadder);

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      baseMeterUnitPrice * climbingProtection.length,
      Reasons.BASIS
    );

    const variableLadderDiscountComponent = toPriceComponent(
      2,
      'has_ladder_discount',
      'absolute',
      meterUnitPrice * climbingProtection.length -
        baseMeterUnitPrice * climbingProtection.length,
      Reasons.HAS_LADDER_DISCOUNT
    );

    const publicBuildingSurcharge = toPriceComponent(
      3,
      'public_building',
      'factor',
      1.2,
      Reasons.PUBLIC_BUILDING
    );

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      this.basePrice(ClimbingProtection),
      Reasons.BASIS
    );

    const variableActualComponents = [
      variableBasePriceComponent,
      hasLadder ? variableLadderDiscountComponent : null,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const variableMaxComponents = [
      variableBasePriceComponent,
      variableLadderDiscountComponent,
      publicBuildingSurcharge,
    ];

    const fixedActualComponents = [
      fixedBasePriceComponent,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const fixedMaximumComponents = [
      fixedBasePriceComponent,
      publicBuildingSurcharge,
    ];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private fallProtectionSingleAnchorUnitPrice(
    isAbsProduct: boolean,
    hasDocumentation: boolean
  ) {
    if (hasDocumentation) {
      return isAbsProduct ? 14 : 16;
    } else {
      return isAbsProduct ? 28 : 38;
    }
  }

  private fallProtectionPushLockPrice(
    id: string,
    manufacturer: string | undefined | null,
    system: PushLockFallProtectionSystem,
    hasDocumentation: boolean | null,
    hasEasyAccess: boolean,
    sleevesUnitPrices: {
      easyAccess: number;
      hardAccess: number;
    }
  ): MaintenanceObjectPrice | PriceUnsupportedMaintenanceObject {
    if (!this.isAbsProduct(manufacturer)) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_UNSUPPORTED_MAINTENANCE_TYPE,
      });
    }

    const basePrice = this.basePrice(FallProtection);

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      basePrice,
      Reasons.BASIS
    );

    const noEasyAccessComponent = toPriceComponent(
      2,
      'no_easy_access',
      'absolute',
      hasEasyAccess ? 0 : basePrice * 0.3,
      Reasons.NO_EASY_ACCESS
    );

    const noDocumentationFixedPriceComponent = toPriceComponent(
      3,
      'documentation_missing',
      'absolute',
      (hasEasyAccess ? basePrice * 1.2 : basePrice * 1.44) -
        (hasEasyAccess
          ? fixedBasePriceComponent.value
          : fixedBasePriceComponent.value * 1.3),
      Reasons.DOCUMENTATION
    );

    if (system.anchors <= 0 && system.sleeves <= 0) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_ERROR_WITH_PRICE_CALCULATION,
      });
    }

    const unitPriceSleeves = hasEasyAccess
      ? sleevesUnitPrices.easyAccess
      : sleevesUnitPrices.hardAccess;

    const unitPriceAnchor = 25.14;

    const variablePrice =
      unitPriceAnchor * system.anchors + unitPriceSleeves * system.sleeves;

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      variablePrice,
      Reasons.BASIS
    );

    const variableNoEasyAccessComponent = toPriceComponent(
      2,
      'no_easy_access',
      'absolute',
      hasEasyAccess ? 0 : variablePrice * 0.3,
      Reasons.NO_EASY_ACCESS
    );

    const variablePriceUndocumented =
      variablePrice * 1.2 + (system.sleeves * unitPriceAnchor) / 8;

    const noDocumentationVariablePriceComponent = toPriceComponent(
      3,
      'documentation_missing',
      'absolute',
      hasEasyAccess
        ? variablePriceUndocumented - variableBasePriceComponent.value
        : variablePriceUndocumented * 1.2 -
            variableBasePriceComponent.value * 1.3,
      Reasons.DOCUMENTATION
    );

    const fixedActualComponents = [
      fixedBasePriceComponent,
      hasEasyAccess ? null : noEasyAccessComponent,
      !hasDocumentation ? noDocumentationFixedPriceComponent : null,
    ].filter(notNull());

    const fixedMaximumComponents = [
      fixedBasePriceComponent,
      noEasyAccessComponent,
      noDocumentationFixedPriceComponent,
    ];

    const variableActualComponents = [
      variableBasePriceComponent,
      hasEasyAccess ? null : variableNoEasyAccessComponent,
      hasDocumentation ? null : noDocumentationVariablePriceComponent,
    ].filter(notNull());

    const variableMaxComponents = [
      variableBasePriceComponent,
      variableNoEasyAccessComponent,
      noDocumentationVariablePriceComponent,
    ];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private fallProtectionSingleAnchorPrice(
    id: string,
    manufacturer: string | undefined | null,
    system: SingleAnchorFallProtectionSystem,
    hasDocumentation: boolean | null,
    isPublicBuilding: boolean,
    amountOfSingleAnchor: {
      abs: number;
      foreign: number;
    }
  ): MaintenanceObjectPrice | PriceUnsupportedMaintenanceObject {
    if (system.singleAnchors <= 0) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_INVALID_NUMBER_OF_SINGLE_ANCHORS,
      });
    }

    const absProduct = this.isAbsProduct(manufacturer);

    const documentedUnitPrice = this.fallProtectionSingleAnchorUnitPrice(
      absProduct,
      true
    );

    const undocumentedUnitPrice = this.fallProtectionSingleAnchorUnitPrice(
      absProduct,
      false
    );

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      documentedUnitPrice * system.singleAnchors,
      Reasons.BASIS
    );

    const variableSingleAnchorSurchargePriceComponent = toPriceComponent(
      2,
      'single_anchor_surcharge',
      'absolute',
      variableBasePriceComponent.value * 0.2,
      Reasons.SINGLE_ANCHOR_SURCHARGE
    );

    const documentationMissingComponent = toPriceComponent(
      3,
      'documentation_missing',
      'absolute',
      1.2 * undocumentedUnitPrice * system.singleAnchors -
        (variableBasePriceComponent.value +
          variableSingleAnchorSurchargePriceComponent.value),
      Reasons.DOCUMENTATION
    );

    const massDiscountFirst = toPriceComponent(
      4,
      'mass_discount',
      'factor',
      0.9,
      `${
        absProduct ? Reasons.MASS_DISCOUNT_ABS : Reasons.MASS_DISCOUNT_FOREIGN
      }`
    );
    const massDiscountSecond = toPriceComponent(
      5,
      'mass_discount',
      'factor',
      0.9,
      Reasons.MASS_DISCOUNT_2
    );

    const publicBuildingSurcharge = toPriceComponent(
      6,
      'public_building',
      'factor',
      1.2,
      Reasons.PUBLIC_BUILDING
    );

    const qualifiesForFirstMassDiscount =
      !hasDocumentation &&
      ((absProduct && amountOfSingleAnchor.abs >= 30) ||
        (!absProduct && amountOfSingleAnchor.foreign >= 20));

    const qualifiesForSecondMassDiscount =
      !hasDocumentation &&
      ((absProduct && amountOfSingleAnchor.abs >= 100) ||
        (!absProduct && amountOfSingleAnchor.foreign >= 100));

    const variableActualComponents = [
      variableBasePriceComponent,
      variableSingleAnchorSurchargePriceComponent,
      !hasDocumentation ? documentationMissingComponent : null,
      qualifiesForFirstMassDiscount ? massDiscountFirst : null,
      qualifiesForSecondMassDiscount ? massDiscountSecond : null,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const variableMaxComponents = [
      variableBasePriceComponent,
      variableSingleAnchorSurchargePriceComponent,
      documentationMissingComponent,
      massDiscountFirst,
      massDiscountSecond,
      publicBuildingSurcharge,
    ];

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      this.basePrice(FallProtection),
      Reasons.BASIS
    );

    const fixedSingleAnchorSurchargePriceComponent = toPriceComponent(
      2,
      'single_anchor_surcharge',
      'absolute',
      this.basePrice(FallProtection) * 0.2,
      Reasons.SINGLE_ANCHOR_SURCHARGE
    );

    const fixedActualComponents = [
      fixedBasePriceComponent,
      fixedSingleAnchorSurchargePriceComponent,
      qualifiesForFirstMassDiscount ? massDiscountFirst : null,
      qualifiesForSecondMassDiscount ? massDiscountSecond : null,
      isPublicBuilding ? publicBuildingSurcharge : null,
    ].filter(notNull());

    const fixedMaximumComponents = [
      fixedBasePriceComponent,
      fixedSingleAnchorSurchargePriceComponent,
      massDiscountFirst,
      massDiscountSecond,
      publicBuildingSurcharge,
    ];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private async calculatePriceIndicationForFallProtection(
    object: PriceableMaintenanceObject<FallProtection>,
    hasDocumentation: boolean,
    building: PriceableBuilding | undefined,
    totalRopeLength: TotalRopeLength,
    sleevesUnitPrices: {
      easyAccess: number;
      hardAccess: number;
    },
    amountOfSingleAnchor: {
      abs: number;
      foreign: number;
    }
  ) {
    const isPublicBuilding = building?.isPublic ?? true;
    const buildingType = building?.buildingType ?? '';

    if (object.type.system instanceof SingleAnchorFallProtectionSystem) {
      return this.fallProtectionSingleAnchorPrice(
        toMaintenanceObjectId(object),
        object.manufacturer,
        object.type.system,
        hasDocumentation,
        isPublicBuilding,
        amountOfSingleAnchor
      );
    } else if (object.type.system instanceof PushLockFallProtectionSystem) {
      return this.fallProtectionPushLockPrice(
        toMaintenanceObjectId(object),
        object.manufacturer,
        object.type.system,
        hasDocumentation,
        this.hasEasyAccess(buildingType),
        sleevesUnitPrices
      );
    } else if (object.type.system instanceof RopeFallProtectionSystem) {
      const ropeSystem = object.type.system as RopeFallProtectionSystem;
      return this.fallProtectionRopeSystemPrice(
        toMaintenanceObjectId(object),
        object.manufacturer,
        object.type.location,
        ropeSystem,
        hasDocumentation,
        isPublicBuilding,
        totalRopeLength
      );
    } else {
      return new PriceUnsupportedMaintenanceObject({
        objectId: toMaintenanceObjectId(object),
        reason: this.MSG_UNSUPPORTED_MAINTENANCE_TYPE,
      });
    }
  }

  private roundUp(value: number) {
    return Math.round(value * 100 + Number.EPSILON) / 100;
  }

  private async personalProtectionEquipmentPrice(
    id: string,
    manufacturer: string | undefined | null,
    isOnSite: boolean,
    ppe: PriceableMaintenanceObject<PersonalProtectionEquipment>
  ): Promise<MaintenanceObjectPrice | PriceUnsupportedMaintenanceObject> {
    const basePrice = this.basePrice(PersonalProtectionEquipment);

    const unitPrice = await this.personalProtectionEquipmentUnitPrice(
      manufacturer,
      ppe.type.systemType
    );

    if (!basePrice || !unitPrice) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_UNSUPPORTED_MAINTENANCE_TYPE,
      });
    }

    // TODO: Fall_arrestor muss eingeschickt werden

    const fixedPrice = this.roundUp(isOnSite ? basePrice : 15);

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      fixedPrice,
      Reasons.BASIS
    );

    const variablePrice = this.roundUp(unitPrice * ppe.type.quantity);

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      variablePrice,
      Reasons.BASIS
    );

    const variableActualComponents = [variableBasePriceComponent];

    const variableMaxComponents = [variableBasePriceComponent];

    const fixedActualComponents = [fixedBasePriceComponent];

    const fixedMaximumComponents = [fixedBasePriceComponent];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private async personalProtectionEquipmentUnitPrice(
    manufacturer: string | undefined | null,
    type: string | undefined | null
  ) {
    if (!type) {
      return null;
    }
    const isAbsProduct = this.isAbsProduct(manufacturer);
    const unitPrices = await this.unitPriceResolver.ppeUnitPrices();
    return isAbsProduct ? unitPrices[type]?.abs : unitPrices[type]?.foreign;
  }

  protected ladderUnitPriceByObjects(objects: PriceableMaintenanceObject[]) {
    const ladders = objects.map(to('type')).filter(whereIsType(Ladder));

    const smallParts = ladders.flatMap(to('smallParts')).length;
    const largeParts = ladders.flatMap(to('largeParts')).length;

    return {
      small: smallParts < 5 ? 45 : smallParts < 10 ? 40 : 35,
      large: largeParts < 5 ? 75 : largeParts < 10 ? 67.5 : 60,
    };
  }

  protected railingUnitPriceByObjects(objects: PriceableMaintenanceObject[]) {
    const totalLength = objects.reduce((prev, current) => {
      if (current.type instanceof Railing) return prev + current.type.length;
      return prev;
    }, 0);

    return totalLength <= 150 ? 5 : 4.5;
  }

  private ladderPrice(
    id: string,
    ladder: PriceableMaintenanceObject<Ladder>,
    unitPrices:
      | {
          small: number;
          large: number;
        }
      | undefined
      | null = undefined
  ): MaintenanceObjectPrice | PriceUnsupportedMaintenanceObject {
    if (!ladder.type.parts.length) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_INVALID_LADDER_HEIGHT,
      });
    }
    if (ladder.type.parts.some((length) => length > 30)) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_HUGE_LADDERS_UNSUPPORTED,
      });
    }

    const fixedPrice = this.basePrice(Ladder);

    if (!unitPrices) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_ERROR_WITH_PRICE_CALCULATION,
      });
    }

    const smallPartVariablePrices = ladder.type.smallParts
      .map(() => unitPrices.small)
      .reduce(toSum(), 0);

    const largePartVariablePrices = ladder.type.largeParts
      .map(() => unitPrices.large)
      .reduce(toSum(), 0);

    const variablePrice =
      smallPartVariablePrices +
      largePartVariablePrices +
      ladder.type.numberOfPlatforms * 40;

    const variableBasePrice =
      ladder.type.smallParts.length * 45 +
      ladder.type.largeParts.length * 75 +
      ladder.type.numberOfPlatforms * 40;

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      fixedPrice,
      Reasons.BASIS
    );

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      variableBasePrice,
      Reasons.BASIS
    );

    const variableMassDiscountComponent = toPriceComponent(
      1,
      'mass_discount',
      'absolute',
      variablePrice - variableBasePrice,
      ladder.type.parts.length >= 10
        ? Reasons.MASS_DISCOUNT_LADDERS_2
        : Reasons.MASS_DISCOUNT_LADDERS
    );

    const variableActualComponents = [
      variableBasePriceComponent,
      variableMassDiscountComponent.value < 0
        ? variableMassDiscountComponent
        : null,
    ].filter(notNull());
    const variableMaxComponents = [
      variableBasePriceComponent,
      variableMassDiscountComponent,
    ];
    const fixedActualComponents = [fixedBasePriceComponent];
    const fixedMaximumComponents = [fixedBasePriceComponent];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  private railingPrice(
    id: string,
    railing: PriceableMaintenanceObject<Railing>,
    railingUnitPrice: number
  ): MaintenanceObjectPrice | PriceUnsupportedMaintenanceObject {
    if (!(railing.type.length > 0)) {
      return new PriceUnsupportedMaintenanceObject({
        objectId: id,
        reason: this.MSG_INVALID_RAILING_HEIGHT,
      });
    }

    const fixedPrice = this.basePrice(Railing);

    const variableBasePrice = railing.type.length * 5;
    const variablePrice = railing.type.length * railingUnitPrice;

    const fixedBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      fixedPrice,
      Reasons.BASIS
    );

    const variableBasePriceComponent = toPriceComponent(
      1,
      'base_price',
      'absolute',
      variableBasePrice,
      Reasons.BASIS
    );

    const variableMassDiscountComponent = toPriceComponent(
      2,
      'mass_discount',
      'absolute',
      variablePrice - variableBasePrice,
      Reasons.MASS_DISCOUNT_RAILINGS
    );

    const variableActualComponents = [
      variableBasePriceComponent,
      variableMassDiscountComponent.value < 0
        ? variableMassDiscountComponent
        : null,
    ].filter(notNull());
    const variableMaxComponents = [
      variableBasePriceComponent,
      variableMassDiscountComponent,
    ];
    const fixedActualComponents = [fixedBasePriceComponent];
    const fixedMaximumComponents = [fixedBasePriceComponent];

    return new MaintenanceObjectPrice({
      objectId: id,
      unsortedFixedPriceComponents: {
        actual: fixedActualComponents,
        max: fixedMaximumComponents,
      },
      unsortedVariablePriceComponents: {
        actual: variableActualComponents,
        max: variableMaxComponents,
      },
    });
  }

  protected async calculatePriceForObject(
    user: User,
    info: MaintenanceObjectPriceInformation,
    totalRopeLength: TotalRopeLength,
    ladderUnitPrices: {
      small: number;
      large: number;
    },
    railingUnitPrices: number,
    sleevesUnitPrices: {
      easyAccess: number;
      hardAccess: number;
    },
    amountOfSingleAnchor: {
      abs: number;
      foreign: number;
    },
    totalRailingSystemLength: {
      abs: number;
      foreign: number;
    }
  ): Promise<MaintenanceObjectPrice | PriceUnsupportedMaintenanceObject> {
    const object = info.object;
    const building = info.building;
    const hasDocumentation = info.hasValidDocumentation ?? false;

    const objectId = 'id' in object ? object.id : object.originalId;

    if (object.type instanceof FallProtection) {
      return this.calculatePriceIndicationForFallProtection(
        object as PriceableMaintenanceObject<FallProtection>,
        hasDocumentation,
        building,
        totalRopeLength,
        sleevesUnitPrices,
        amountOfSingleAnchor
      );
    } else if (object.type instanceof PersonalProtectionEquipment) {
      return await this.personalProtectionEquipmentPrice(
        objectId,
        object.manufacturer,
        true, // TODO: parameterization of on-site or shipment inspection
        object as PriceableMaintenanceObject<PersonalProtectionEquipment>
      );
    } else if (object.type instanceof Ladder) {
      return this.ladderPrice(
        objectId,
        object as PriceableMaintenanceObject<Ladder>,
        ladderUnitPrices
      );
    } else if (object.type instanceof Railing) {
      return this.railingPrice(
        objectId,
        object as PriceableMaintenanceObject<Railing>,
        railingUnitPrices
      );
    } else if (object.type instanceof RailSystem) {
      return this.railSystemPrice(
        toMaintenanceObjectId(object),
        object.manufacturer,
        object.type,
        hasDocumentation,
        building?.isPublic ?? true,
        totalRailingSystemLength
      );
    } else if (object.type instanceof ClimbingProtection) {
      return this.climbingProtectionPrice(
        toMaintenanceObjectId(object),
        object.manufacturer,
        object.type,
        building?.isPublic ?? true,
        !!object.type.ladderId
      );
    } else if (
      object.type instanceof StairLadder ||
      object.type instanceof Overpass ||
      object.type instanceof GroundStairs
    ) {
      return this.climbingEquipmentPrice(
        toMaintenanceObjectId(object),
        object.type
      );
    } else {
      return new PriceUnsupportedMaintenanceObject({
        objectId,
        reason: this.MSG_UNSUPPORTED_MAINTENANCE_TYPE,
      });
    }
  }

  public async calculatePriceForObjects(
    user: User,
    information: MaintenanceObjectPriceInformation[]
  ): Promise<MaintenancePrice> {
    const maintenanceObjectPrices: MaintenanceObjectPrice[] = [];
    const unsupportedObjects: PriceUnsupportedMaintenanceObject[] = [];

    const objects = information.map(to('object'));

    const totalRopeLength =
      this.fallProtectionRopeLengthSumByObjects(information);

    const totalRailingSystemLength = this.railSystemLengthSumByObjects(objects);

    // const railingSystemUnitPrices =
    //   this.railingSystemUnitPriceByObjects(objects);

    const sleevesUnitPrices =
      this.fallProtectionSleevesUnitPriceByObjects(information);

    const amountOfSingleAnchor =
      this.fallProtectionSingleAnchorAmountByObjects(information);

    const ladderUnitPrices = this.ladderUnitPriceByObjects(objects);
    const railingUnitPrices = this.railingUnitPriceByObjects(objects);

    for (const objectInfo of information) {
      const price = await this.calculatePriceForObject(
        user,
        objectInfo,
        totalRopeLength,
        ladderUnitPrices,
        railingUnitPrices,
        sleevesUnitPrices,
        amountOfSingleAnchor,
        totalRailingSystemLength
      );

      if (price instanceof MaintenanceObjectPrice) {
        maintenanceObjectPrices.push(price);
      } else {
        unsupportedObjects.push(price);
      }
    }

    return new MaintenancePrice({
      strategy: 'wartungshelden',
      objectPrices: maintenanceObjectPrices,
      unsupportedObjects: unsupportedObjects,
      discounts: [],
    });
  }
}
