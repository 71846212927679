import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  MaintenanceOrder,
  MaintenanceOrderStatusEnum,
} from '../../maintenance-offer';

export class MustBeUnconfirmed<T extends MaintenanceOrder> extends DomainRule<
  T,
  T
> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (entity.status !== MaintenanceOrderStatusEnum.UNCONFIRMED) {
      throw new DomainRuleNotSatisfiedError(MustBeUnconfirmed);
    }
    return entity;
  }
}
