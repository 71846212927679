import React from 'react';
import { Outlet } from 'react-router-dom';

import UserNavigation from '../../../components/Basics/TabNavigation/UserNavigation';
import HeaderLogout from '../../../components/Header/HeaderLogout';

const InstallerOrderContainer: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="flex flex-1 flex-col h-screen">
      <HeaderLogout>
        <UserNavigation />
      </HeaderLogout>
      <Outlet />
    </div>
  );
};

export default InstallerOrderContainer;
