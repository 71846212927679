export class MaintenanceOrderAddress {
  public readonly addressLine1: string;
  public readonly postalCode: string;
  public readonly city: string;
  public readonly countryISO: string;
  public readonly company: string;
  public readonly firstName?: string | null;
  public readonly lastName?: string | null;
  public readonly addressLine2?: string | null;

  constructor(config: {
    addressLine1: string;
    postalCode: string;
    city: string;
    countryISO: string;
    company: string;
    firstName?: string | null;
    lastName?: string | null;
    addressLine2?: string | null;
  }) {
    this.addressLine1 = config.addressLine1;
    this.addressLine2 = config.addressLine2;
    this.postalCode = config.postalCode;
    this.city = config.city;
    this.countryISO = config.countryISO;
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.company = config.company;
  }
}
