import { NotFoundError } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import {
  MustBeMaintenanceTeamRule,
  MustExistRule,
  MustNotExistRule,
} from '../rules';
import { MustAcceptCustomerNumber } from '../rules';
import { Customer, Notification } from './customer';
import { CustomerRepository } from './customer.repository';

export class CustomerService {
  constructor(private readonly customerRepository: CustomerRepository) {}

  public async getById(user: User, id: string): Promise<Customer> {
    return await this.customerRepository.getById(id);
  }

  public async getByIdOrCreate(user: User, id: string): Promise<Customer> {
    try {
      return await this.getById(user, id);
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        throw error;
      }

      return await this.add(
        user,
        new Customer({
          id,
          billingAddresses: [],
          notification: new Notification({
            isStatusUpdatesEnabled: true,
            daysInAdvance: 60,
            isReminderEnabled: true,
            isReminderWhenDueEnabled: true,
            isTestUserContactingEnabled: true,
          }),
          hasAcceptedCustomerNumber: false,
        })
      );
    }
  }

  public async getForUser(user: User): Promise<Customer> {
    return await this.getByIdOrCreate(user, user.id);
  }

  public async add(user: User, customer: Customer): Promise<Customer> {
    const id = customer.id ?? user?.id;

    await new MustNotExistRule(this.customerRepository).apply(id);
    await new MustAcceptCustomerNumber().apply(customer);

    return await this.customerRepository.create(
      new Customer({ ...customer, id })
    );
  }

  public async update(
    user: User,
    customer: Pick<Customer, 'id'> & Partial<Customer>
  ): Promise<Customer> {
    await new MustExistRule(this.customerRepository).apply(customer.id);
    await new MustAcceptCustomerNumber().apply(customer);
    return await this.customerRepository.update(customer);
  }

  public async getAll(user: User): Promise<Customer[]> {
    await new MustBeMaintenanceTeamRule<void>(user).apply();
    return await this.customerRepository.getAll();
  }
}
