import Tooltip from 'rc-tooltip';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Cell } from 'react-table';

import { MaintenanceRequestDto } from '@wartungshelden/shared-types';

interface IdCellProps {
  cell: Cell<MaintenanceRequestDto>;
  isMaintenanceTeamMemberAdmin: boolean;
}

const IdCell: React.FC<IdCellProps> = ({
  cell,
  isMaintenanceTeamMemberAdmin,
}) => {
  const request = cell?.value;
  const offer = request?.offer;

  const hasReOpenedObjects = offer?.maintenanceObjects?.some(
    (maintenanceObject) =>
      Boolean(
        maintenanceObject?.originalId &&
          offer?.objectsWithOpenDocumentUpload?.includes(
            maintenanceObject?.originalId
          )
      )
  );

  return (
    <div className="flex space-x-4">
      <div>{`Anfrage ${request.id.slice(31)}`}</div>
      {hasReOpenedObjects && !isMaintenanceTeamMemberAdmin && (
        <Tooltip
          placement="top"
          trigger="hover"
          overlay="Die vorhandenen Dokumente genügen uns nicht. Bitte schau Dir die Wartungsaufgabe im Detail an und lade eine aussagekräftige Montagedokumentation hoch."
          overlayInnerStyle={{
            padding: 12,
            maxWidth: '18rem',
          }}
        >
          <FaExclamationTriangle className="text-orange text-base self-center" />
        </Tooltip>
      )}
    </div>
  );
};

export default IdCell;
