import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  CustomerMaintenanceRequestDto,
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceOfferStatusDtoEnum,
  MaintenanceOrderStatusDtoEnum,
  MaintenanceRequestDto,
  RequestStatusEnum,
  requestStatusToLabel,
  statusToUse,
} from '@wartungshelden/shared-types';

import { Button, PageHeader } from '../../../components';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import { routes } from '../../../constants/routes';
import { useUpdateMaintenanceRequest } from '../../../services/api/maintenance-requests/maintenance-request-api';
// eslint-disable-next-line import/no-cycle
import { userShouldSeeOffer } from '../RequestDetailsPage';
import AdminStatusDropdown from './AdminStatusDropdown';

interface PageHeaderProps {
  request: MaintenanceRequestDto | CustomerMaintenanceRequestDto;
  offer?: CustomerOrMaintenanceTeamOfferDto | null;
  isMaintenanceTeamMemberAdmin?: boolean;
}

const getTitle = (hasOffer, offer) => {
  if (hasOffer) {
    return offer?.order?.status === MaintenanceOrderStatusDtoEnum.CONFIRMED
      ? 'Auftragsdetails'
      : 'Angebotsdetails';
  }
  return 'Anfragedetails';
};

const RequestDetailsPageHeader: React.FC<
  React.PropsWithChildren<PageHeaderProps>
> = ({ request, offer, isMaintenanceTeamMemberAdmin = false }) => {
  const { mutateAsync: updateMaintenanceRequest, isLoading: isCanceling } =
    useUpdateMaintenanceRequest();

  const navigate = useNavigate();

  const hasOffer = userShouldSeeOffer(offer?.decision?.status);

  const shouldUserSeeCancelButton =
    !isMaintenanceTeamMemberAdmin &&
    (!offer || offer?.decision?.status === MaintenanceOfferStatusDtoEnum.DRAFT);

  const title = getTitle(hasOffer, offer);

  return (
    <PageHeader>
      <div className="flex w-full justify-between items-center w-full">
        <div>{title}</div>
        {isMaintenanceTeamMemberAdmin ? (
          <div className="text-base font-normal flex items-center">
            <div className="mr-2">Status:</div>
            <AdminStatusDropdown request={request} offer={offer} />
          </div>
        ) : (
          <div className="text-base font-normal">
            Status:{' '}
            {requestStatusToLabel(
              false,
              statusToUse(
                request.status,
                offer?.decision.status,
                offer?.order?.status
              )
            )}
          </div>
        )}
        {shouldUserSeeCancelButton && (
          <div className="justify-self-end">
            <Button
              label="Anfrage stornieren"
              onClick={async () => {
                await updateMaintenanceRequest({
                  id: request.id,
                  status: RequestStatusEnum.CANCELED,
                });
                navigate(`/${routes.requests}`);
              }}
              icon={<FaTimesCircle />}
              className="outline-button"
            />
          </div>
        )}
      </div>
      <LoadingSpinner isLoading={isCanceling} />
    </PageHeader>
  );
};

RequestDetailsPageHeader.defaultProps = {
  offer: undefined,
  isMaintenanceTeamMemberAdmin: undefined,
};

export default RequestDetailsPageHeader;
