import React, { useState } from 'react';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';

const ContactButton = ({
  questionButtonStyle,
}: {
  questionButtonStyle?: string;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="flex flex-col items-end relative">
      <FaQuestionCircle
        data-tip
        data-for="help-tooltip"
        className={`${questionButtonStyle || ''} cursor-pointer`}
        size={32}
        onClick={() => {
          setShowTooltip(!showTooltip);
        }}
      />

      {showTooltip && (
        <div className="absolute z-10 top-7 right-4 bg-white break-all w-80 drop-shadow-lg p-3 mt-2 rounded-md">
          <div className="flex justify-end">
            <FaTimes onClick={() => setShowTooltip(false)} />
          </div>
          <div className="space-y-2">
            <div className="text-xl font-bold">Benötigst du Hilfe?</div>
            <div>Dann melde Dich gerne bei uns:</div>
            <div>
              <div>E-Mail:</div>
              <div>inspektion@absturzsicherung.de</div>
            </div>
            <div>
              <div>Telefon:</div>
              <div>+49 201 7475985-730</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ContactButton.defaultProps = {
  questionButtonStyle: 'text-gray-abs',
};

export default ContactButton;
