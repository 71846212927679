import { Field, useFormikContext } from 'formik';
import React from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { FormikBuildingValues } from '../../../types/formikBuildingValues';

type PhoneInputFieldProps = {
  label?: string | null;
  name: string;
  disabled?: boolean;
  min?: number | null;
};

const PhoneInputField: React.FC<
  React.PropsWithChildren<PhoneInputFieldProps>
> = ({ name, label, disabled, min }) => {
  const { values } = useFormikContext<FormikBuildingValues>();

  return (
    <div className={`flex h-12 items-center ${label && 'mb-2'}`}>
      {label && (
        <label htmlFor={name} className="flex font-bold w-64 mr-4">
          {label}
        </label>
      )}
      <Field
        id={name}
        name={name}
        type="number"
        className={`w-full ${
          disabled ? 'bg-gray-lighter text-gray-dark' : 'bg-gray'
        }`}
        disabled={disabled}
        min={min}
      >
        {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
          <PhoneInput
            enableLongNumbers
            disabled={disabled}
            country="de"
            containerClass={`flex flex-row w-full h-full border-2 items-center border-gray rounded-lg ${
              disabled ? 'bg-gray-lighter text-gray-dark' : 'bg-white'
            }`}
            inputClass={`flex flex-1 px-4 py-2 w-full h-full outline-0 ${
              disabled ? 'bg-gray-lighter text-gray-dark' : 'bg-white'
            }`}
            inputStyle={{
              border: 0,
              background: disabled ? '#f5f5f5' : undefined,
            }}
            buttonStyle={{
              border: 0,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              background: disabled ? '#f5f5f5' : undefined,
            }}
            dropdownStyle={{
              background: disabled ? '#CDD4DA' : undefined,
            }}
            countryCodeEditable={false}
            value={value ?? values[`${name}DialCode`]}
            onChange={(phone, country: CountryData, e, formattedValue) => {
              setFieldValue(`${name}DialCode`, country.dialCode);
              setFieldTouched(name, true);
              setFieldValue(
                name,
                phone !== country.dialCode
                  ? formattedValue.replace(/ /g, '')
                  : undefined
              );
            }}
          />
        )}
      </Field>
    </div>
  );
};
export default PhoneInputField;

PhoneInputField.defaultProps = {
  label: null,
  disabled: false,
  min: null,
};
