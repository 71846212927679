import React from 'react';

import { RailingDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface RailingPropertiesProps {
  railing: RailingDto;
  hasSubprices: boolean;
}

const RailingProperties: React.FC<RailingPropertiesProps> = ({
  railing,
  hasSubprices,
}) => (
  <MaintenanceObjectSummaryRow
    label="Länge"
    value={railing.length}
    unit="m"
    hasSubprices={hasSubprices}
  />
);

export default RailingProperties;
