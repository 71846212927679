import React, { Dispatch, SetStateAction, useState } from 'react';
import { FaCommentDollar, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  BuildingDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import {
  Button,
  Card,
  MaintenanceRequestCreateTable,
  PageHeader,
  Toggle,
} from '../../../../components';
import Loader from '../../../../components/Basics/Loaders/Loader';
import { InfoMessageBox } from '../../../../components/Basics/MessageBox';
import { maintenanceRoutes, routes } from '../../../../constants/routes';
import { isPriceableMaintenanceType } from '../../../../services/maintenanceService';

interface StepOneProps {
  maintenances?: MaintenanceObjectDto[];
  buildings?: BuildingDto[];
  isLoading: boolean;
  selectedMaintenances: MaintenanceObjectDto[];
  setSelectedMaintenances: Dispatch<SetStateAction<MaintenanceObjectDto[]>>;
}

const SelectMaintenancesStep: React.FC<
  React.PropsWithChildren<StepOneProps>
> = ({
  maintenances,
  buildings,
  isLoading,
  selectedMaintenances,
  setSelectedMaintenances,
}) => {
  const navigate = useNavigate();
  const [showUnsupportedItems, setShowUnsupportedItems] =
    useState<boolean>(true);

  const maintenancesWithoutRequest = maintenances?.filter(
    (maintenance) => !maintenance.currentRequestId
  );

  const supportedMaintenances = maintenancesWithoutRequest?.filter(
    (maintenance) => isPriceableMaintenanceType(maintenance)
  );

  const maintenancesToShow = () =>
    showUnsupportedItems ? supportedMaintenances : maintenancesWithoutRequest;

  const shouldShowInfo = Boolean(
    supportedMaintenances?.length && supportedMaintenances?.length <= 1
  );

  return (
    <Card>
      <div className="flex flex-1 h-full flex-col overflow-y-auto">
        <PageHeader>Wähle Wartungen aus</PageHeader>
        <p className="mb-6">
          Wähle die gewünschten Wartungen aus, zu denen Du einzeln oder
          kombiniert Anfragen senden möchtest. <br />
          <br />
          <span className="font-bold">Hinweis: </span>
          Alle Wartungen, die bereits angefragt wurden, sind hier ausgeblendet.
        </p>
        <Toggle
          label="Nur unterstützte Wartungen anzeigen"
          checked={showUnsupportedItems}
          name="showAllObjects"
          onChange={() => {
            setShowUnsupportedItems((prev) => !prev);
          }}
        />
        {buildings && !isLoading ? (
          <MaintenanceRequestCreateTable
            maintenances={maintenancesToShow() || []}
            buildings={buildings}
            isLoading={isLoading}
            selectedMaintenances={selectedMaintenances}
            setSelectedMaintenances={setSelectedMaintenances}
          />
        ) : (
          <div className="flex flex-1 justify-center">
            <Loader />
          </div>
        )}

        {shouldShowInfo && (
          <div className="mt-4">
            <InfoMessageBox
              headline="Wusstest Du, dass Du Kosten und Mühen sparen kannst?"
              label="Wenn Du mehrere Wartungsaufgaben zusammen anfragst, kannst Du Dir Zeit sparen und es können Rabatte entstehen.
            Aktuell liegt nur eine offene Wartung zur Anfrage vor."
              customIcon={<FaCommentDollar size={35} />}
            >
              <Button
                onClick={() => {
                  navigate(`/${routes.maintenance}/${maintenanceRoutes.add}`);
                }}
                icon={<FaPlus />}
                label="Wartung"
                className="primary-button small-button"
              />
            </InfoMessageBox>
          </div>
        )}
      </div>
    </Card>
  );
};

SelectMaintenancesStep.defaultProps = {
  maintenances: undefined,
  buildings: undefined,
};

export default SelectMaintenancesStep;
