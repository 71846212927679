export interface User {
  readonly id: string;
  readonly username: string;
  readonly isMaintenanceTeamMemberAdmin: boolean;
  readonly isMaintenanceTeamMemberUser: boolean;
  readonly isSocialLogin: boolean;
  readonly groups: UserRole[];
}

export type UserRole =
  | IsMaintenanceTeamMemberAdmin
  | IsMaintenanceTeamMemberUser
  | Feat_CanSaveRoutes;

export type IsMaintenanceTeamMemberAdmin = 'ABS-Wartung';
export type IsMaintenanceTeamMemberUser = 'ABS-Wartung-Internal';

export type Feat_CanSaveRoutes = 'Feature_allowed-to-save-routes';
