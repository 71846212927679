import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../components';
import { routes } from '../../../../constants/routes';
import {
  MaintenanceBuildingFilterKey,
  saveFilter,
} from '../../../../services/filter';
import { getGraphBgColorFromDueDate } from '../services';

const BuildingsDetailsGraph = ({ graphData }: { graphData: any }) => {
  const navigation = useNavigate();
  return (
    <table className="table-auto">
      <tbody>
        {graphData?.map((data) => {
          return (
            <tr
              className="h-14 px-2 border-b-2"
              key={`${data.building.name}, ${data.building.address.city}`}
            >
              <td className="text-xl px-2">
                {`${data.building.name}, ${data.building.address.city}`}
              </td>
              <td className="px-1 max-w-1/3 flex flex-wrap my-2">
                {data?.maintenances?.map((maintenance) => (
                  <div
                    key={maintenance.id}
                    className={`m-1 h-10 w-6 ${getGraphBgColorFromDueDate(
                      maintenance.dueDate,
                      maintenance.currentRequestId
                    )}`}
                  />
                ))}
              </td>
              <td className="px-2">
                <Button
                  onClick={() => {
                    saveFilter(MaintenanceBuildingFilterKey, [data.building]);
                    navigation(`/${routes.maintenance}`);
                  }}
                  label="Ansehen"
                  icon={<FaSearch />}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default BuildingsDetailsGraph;
