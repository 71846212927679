import { Entity } from '@warthungshelden/domain/common';

import { Address } from '../address';

export type OrderType =
  | 'unknown'
  | 'maintenance'
  | 'maintenance_contract'
  | 'assembly'
  | 'visit';

export type OrderStatus =
  | 'undefined'
  | 'worth_it'
  | 'follow_up'
  | 'consultation'
  | 'done'
  | 'closed'
  | 'created'
  | 'pre_check'
  | 'clarification'
  | 'scheduling'
  | 'scheduling_written'
  | 'scheduling_phone'
  | 'scheduling_waiting_for_confirmation'
  | 'material_and_order'
  | 'waiting_for_documents'
  | 'post_processing'
  | 'invoice';

export type OrderContactPreference = 'phone' | 'written';

export class Order extends Entity {
  public readonly id: string;
  public readonly type: OrderType;
  public readonly status: OrderStatus;
  public readonly duration?: number;
  public readonly deliveryDate?: Date;
  public readonly installerId?: string;
  public readonly notes?: string;
  public readonly contactPreference?: OrderContactPreference;
  public readonly constructionSiteNumber?: string;
  public readonly constructionSite?: string;
  public readonly constructionSiteAddress?: Address;

  constructor(config: {
    readonly id: string;
    readonly type: OrderType;
    readonly status: OrderStatus;
    readonly duration?: number;
    readonly deliveryDate?: Date;
    readonly installerId?: string;
    readonly notes?: string;
    readonly contactPreference?: OrderContactPreference;
    readonly constructionSiteNumber?: string;
    readonly constructionSite?: string;
    readonly constructionSiteAddress?: Address;
  }) {
    super();
    this.id = config.id;
    this.deliveryDate = config.deliveryDate;
    this.type = config.type;
    this.status = config.status;
    this.duration = config.duration;
    this.installerId = config.installerId;
    this.notes = config.notes;
    this.contactPreference = config.contactPreference;
    this.constructionSiteNumber = config.constructionSiteNumber;
    this.constructionSite = config.constructionSite;
    this.constructionSiteAddress = config.constructionSiteAddress;
  }
}
