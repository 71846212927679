export function not<
  F extends (...args: any) => boolean,
  P extends Parameters<F>
>(test: F) {
  return (...params: P) => !test(params);
}

export function isDefined<T>(input?: T | null | undefined): input is T {
  return typeof input !== 'undefined' && input !== null;
}

interface Type<T> extends Function {
  new (...args: any[]): T;
}

export function whereEquals<T>(value: T) {
  return (currentValue: unknown) => currentValue === value;
}

export function whereNotEquals<T>(value: T) {
  return not(whereEquals(value));
}

export function whereIsType<T>(constructor: Type<T>) {
  return (entity: unknown): entity is T => entity instanceof constructor;
}

export function whereNotIsType<T>(constructor: Type<T>) {
  return not(whereIsType(constructor));
}

export function wherePropertyEquals<T, Key extends keyof T>(
  prop: Key,
  value: T[Key]
) {
  return (entity: T) => entity[prop] === value;
}

export function wherePropertyNotEquals<T, Key extends keyof T>(
  prop: Key,
  value: T[Key]
) {
  return not(wherePropertyEquals(prop, value));
}

export function wherePropertyIsType<T, U extends keyof T>(
  property: U,
  constructor: Type<T[U]>
) {
  return (entity: T): boolean => entity[property] instanceof constructor;
}

export function wherePropertyIsNotType<T, U extends keyof T>(
  property: U,
  constructor: Type<T[U]>
) {
  return not(wherePropertyIsType(property, constructor));
}

export function wherePropertiesAreDefined<T, U extends keyof T>(
  ...properties: U[]
) {
  return (entity: T): entity is T & Required<Pick<T, U>> => {
    return Object.entries(entity)
      .filter(([key]) => properties.includes(key as U))
      .every(isDefined);
  };
}
