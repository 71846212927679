import React from 'react';
import { DateRange } from 'react-day-picker';

import {
  BuildingDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { Card, PageHeader, TimeDetermination } from '../../../../components';
import MaintenanceRequestSelectionOverviewTable from '../../../../components/Maintenance/MaintenanceTable/MaintenanceRequestSelectionOverviewTable';
import { minDueDate } from '../../../../services/maintenanceService';

export interface StepThreeFormikValues {
  dateRanges: DateRange[];
  preferablyInDueMonth: boolean;
  additionalAppointmentInfo: string;
}

interface StepThreeProps {
  selectedMaintenances: MaintenanceObjectDto[];
  buildings?: BuildingDto[];
}

const SelectAppointmentStep: React.FC<
  React.PropsWithChildren<StepThreeProps>
> = ({ selectedMaintenances, buildings }) => {
  return (
    <Card>
      <div className="flex flex-1 h-full flex-col overflow-y-auto">
        <PageHeader>Terminwunsch für Deine Auswahl</PageHeader>
        <div>
          Wähle den Wunschzeitraum für Deine ausgewählten Wartungsaufgaben aus.
        </div>
        <div className="text-2xl mt-12">Auswahl der Wartungsaufgaben</div>

        <div className="my-4">
          <MaintenanceRequestSelectionOverviewTable
            maintenances={selectedMaintenances}
            buildings={buildings}
          />
        </div>

        <TimeDetermination
          dueDate={minDueDate(selectedMaintenances)}
          numberOfRows={selectedMaintenances.length}
        />
      </div>
    </Card>
  );
};

SelectAppointmentStep.defaultProps = {
  buildings: undefined,
};

export default SelectAppointmentStep;
