import React, { useContext } from 'react';

import { CustomerOrMaintenanceTeamOfferDto } from '@wartungshelden/shared-types';

import SingleFileUpload from '../../../components/Basics/Documents/SingleFileUpload';
import {
  HintHighlightContext,
  OFFER_NOT_UPLOADED_ERROR,
} from '../../../contexts/HintHighlightContext';
import {
  useAttachOfferDocumentFile,
  useOfferDocumentFile,
  useRemoveOfferDocumentFile,
} from '../../../services/api/maintenance-offers/maintenance-offer-document-api';

interface OfferFileUploadProps {
  offer?: CustomerOrMaintenanceTeamOfferDto | null;
  deleteDisabled?: boolean;
}

const OfferFileUpload: React.FC<
  React.PropsWithChildren<OfferFileUploadProps>
> = ({ offer, deleteDisabled }) => {
  const { clearHighlight } = useContext(HintHighlightContext);

  const { mutateAsync: deleteOfferFile, isLoading: isDeleting } =
    useRemoveOfferDocumentFile();

  const { mutateAsync: attachOfferFile, isLoading: isUploading } =
    useAttachOfferDocumentFile();

  const { isLoading: isFileLoading, data: loadedFile } = useOfferDocumentFile(
    offer?.id,
    !!offer?.documentName && !isDeleting
  );

  const isLoading = isFileLoading || isDeleting || isUploading;

  return (
    <SingleFileUpload
      showDeleteFile={!deleteDisabled && offer?.decision.status === 'draft'}
      onDeleteFile={() => deleteOfferFile({ offerId: offer?.id })}
      onAttachFile={(acceptedFile) => {
        clearHighlight(`${OFFER_NOT_UPLOADED_ERROR}${offer?.requestId}`);
        return attachOfferFile({
          offerId: offer?.id,
          file: acceptedFile,
        });
      }}
      isLoading={isLoading}
      file={loadedFile ?? undefined}
    />
  );
};

OfferFileUpload.defaultProps = {
  offer: undefined,
  deleteDisabled: undefined,
};

export default OfferFileUpload;
