import Tooltip from 'rc-tooltip';
import React from 'react';
import { BsFileEarmark } from 'react-icons/bs';
import { CellProps } from 'react-table';

import {
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { useMaintenanceDocumentations } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import { getDocumentStatus } from '../../../services/documentationService';
import { getSelectedFiles } from '../../../services/maintenanceService';
import { MaintenanceRequestTableData } from '../MaintenanceRequestTable/MaintenanceRequestsTable';
import { MaintenanceRequestOverviewTableData } from './MaintenanceRequestOverviewTable';
import { TableData } from './UserMaintenanceTable';

interface FilesStatusCellProps<
  T extends
    | TableData
    | MaintenanceObjectDto
    | MaintenanceRequestTableData
    | MaintenanceRequestOverviewTableData
> {
  cell: CellProps<T, string | null | undefined>;
}

const DocumentStatusHintMessages = {
  valid: 'Gültige Dokumentation',
  invalid: 'Ungültige Dokumentation',
  notRelevant: 'Datei ist irrelevant',
  notValidated: 'Datei ungeprüft',
};

const DocumentStatusColor = {
  valid: 'bg-green',
  invalid: 'bg-red',
  notRelevant: 'bg-gray-dark',
  notValidated: 'bg-orange',
};

const DocumentStatusIcon: React.FC<
  React.PropsWithChildren<{
    document: MaintenanceDocumentationDto;
    amountOfDocuments?: number;
    maintenanceObjectId: string;
  }>
> = ({ document, maintenanceObjectId, amountOfDocuments }) => {
  const documentStatus = getDocumentStatus(document, maintenanceObjectId);

  const getHintText = () =>
    documentStatus ? DocumentStatusHintMessages[documentStatus] : '';

  if (amountOfDocuments && amountOfDocuments > 0) {
    return (
      <Tooltip
        placement="top"
        trigger="hover"
        overlay={getHintText()}
        overlayInnerStyle={{
          padding: 12,
          maxWidth: '18rem',
        }}
      >
        <div className="relative">
          <BsFileEarmark className="text-2xl" />
          {amountOfDocuments && (
            <div
              className={`absolute z-10 -bottom-1.5 -right-1 font-bold rounded-full w-4 h-4 flex items-center justify-center text-sm ${
                documentStatus && DocumentStatusColor[documentStatus]
              }`}
            >
              {amountOfDocuments}
            </div>
          )}
        </div>
      </Tooltip>
    );
  }
  return null;
};

DocumentStatusIcon.defaultProps = {
  amountOfDocuments: undefined,
};

const FilesStatusCell = <
  T extends
    | TableData
    | MaintenanceObjectDto
    | MaintenanceRequestTableData
    | MaintenanceRequestOverviewTableData
>({
  cell,
}: React.PropsWithChildren<FilesStatusCellProps<T>>) => {
  const { data: maintenanceDocumentations } = useMaintenanceDocumentations();

  if (!cell.value || !maintenanceDocumentations?.length) return null;
  const maintenanceObjectId = cell.value;
  const documents = getSelectedFiles(
    maintenanceObjectId,
    maintenanceDocumentations
  );
  const documentsByStatuses = [
    {
      status: 'notRelevant',
      maintenanceObjectId,
      documents: documents.filter(
        (document) =>
          getDocumentStatus(document, maintenanceObjectId) === 'notRelevant'
      ),
    },
    {
      status: 'invalid',
      maintenanceObjectId,
      documents: documents.filter(
        (document) =>
          getDocumentStatus(document, maintenanceObjectId) === 'invalid'
      ),
    },
    {
      status: 'valid',
      maintenanceObjectId,
      documents: documents.filter(
        (document) =>
          getDocumentStatus(document, maintenanceObjectId) === 'valid'
      ),
    },
    {
      status: 'notValidated',
      maintenanceObjectId,
      documents: documents.filter(
        (document) =>
          getDocumentStatus(document, maintenanceObjectId) === 'notValidated'
      ),
    },
  ];

  return (
    <div className="flex flex-row space-x-4">
      {documentsByStatuses.map((documentByStatus) => (
        <DocumentStatusIcon
          document={documentByStatus.documents[0]}
          maintenanceObjectId={maintenanceObjectId}
          amountOfDocuments={documentByStatus.documents.length}
        />
      ))}
    </div>
  );
};

export default FilesStatusCell;
