import React, { useContext, useState } from 'react';
import { FaMicrosoft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthProviderButton from '../../../components/Auth/AuthProviderButton';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import PolicyAuthFooter from '../../../components/Policies/PolicyAuthFooter';
import { SessionContext } from '../../../contexts/SessionContext';
import { useIsSignedIn } from '../../../services/authService';
import ContactButton from '../ContactButton';

const AdminLoginForm = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { isSignedIn } = useIsSignedIn();

  const { loginWithMicrosoft } = useContext(SessionContext);

  const redirectedFrom = (state as any)?.from;

  const [isSigningIn, setIsSigningIn] = useState(false);

  const navigateToApp = () => {
    const to =
      redirectedFrom && redirectedFrom !== pathname ? redirectedFrom : '/';

    navigate(to);
  };

  const onMicrosoftSignIn = async () => {
    setIsSigningIn(true);
    await loginWithMicrosoft();
    setIsSigningIn(false);
  };

  if (isSignedIn) {
    navigateToApp();
  }

  return (
    <>
      <div className="absolute top-0 left-0 right-0 p-2 bg-black-abs">
        <ContactButton questionButtonStyle="text-white" />
      </div>

      <div className="bg-white shadow w-full">
        <LoadingSpinner isLoading={isSigningIn} />

        <div className="flex justify-center px-8 pt-4 pb-4">
          <img src="/assets/logo.svg" alt="ABS Wartung" className="w-3/5" />
        </div>
        <div className="pb-12 px-5">
          <div className="py-2 font-semibold text-center text-xl mt-6">
            Als Administrator anmelden
          </div>

          <div className="py-1">
            <AuthProviderButton
              provider="microsoft"
              label="Anmelden mit Microsoft"
              icon={<FaMicrosoft />}
              onClick={onMicrosoftSignIn}
            />
          </div>
        </div>

        <PolicyAuthFooter />
      </div>
    </>
  );
};

export default AdminLoginForm;
