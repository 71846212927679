import { MaintenanceObjectPriceDto } from '@wartungshelden/shared-types';

const isMaintenanceObjectPriceDtoArray = (
  value: object[]
): value is MaintenanceObjectPriceDto[] => {
  return (
    'variableNetPrice' in value[0] &&
    'fixedNetPrice' in value[0] &&
    'netDocumentationSavings' in value[0] &&
    'maximumNetDocumentationSavings' in value[0]
  );
};

export default isMaintenanceObjectPriceDtoArray;
