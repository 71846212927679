import {
  DomainRule,
  DomainRuleNotSatisfiedError,
} from '@warthungshelden/domain/common';

import { OrderStatus } from '../../../maintenance-order';

export class MustHaveStatusRule<
  T extends {
    status: OrderStatus;
  }
> extends DomainRule<T, T> {
  private readonly statuses: OrderStatus[];

  constructor(...statuses: OrderStatus[]) {
    super();
    this.statuses = statuses;
  }

  protected async rule(entity: T): Promise<T> {
    if (!this.statuses.includes(entity.status)) {
      throw new DomainRuleNotSatisfiedError(MustHaveStatusRule);
    }
    return entity;
  }
}
