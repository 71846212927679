export interface MaintenanceDocumentationDto {
  readonly id: string;
  readonly fileName: string;
  readonly maintenanceObjectIds: string[];
  readonly validFor?: string[];
  readonly invalidFor?: string[];
  readonly notRelevantFor?: string[];
}

export interface UpdateMaintenanceDocumentationDto
  extends Omit<MaintenanceDocumentationDto, 'ownerId' | 'fileName'> {
  readonly maintenanceObjectIds: string[];
  readonly validFor?: string[];
  readonly invalidFor?: string[];
  readonly notRelevantFor?: string[];
}

export interface CreateMaintenanceDocumentationDto {
  readonly file: File;
  readonly maintenanceObjectIds: string[];
}
