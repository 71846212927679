import React from 'react';

interface PrimaryButtonProps {
  label: string;
  icon?: React.ReactNode;
  disabled: boolean;
  kind?: 'register' | 'login' | 'confirm';
  onClick: () => void;
  className?: string;
}

const AuthPrimaryButton: React.FC<
  React.PropsWithChildren<PrimaryButtonProps>
> = ({ label, icon, className, onClick, disabled, kind, children }) => {
  if (kind) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        data-authbutton={kind}
        data-testid="register-button"
        onClick={onClick}
        disabled={disabled}
        className={`${className} ${
          disabled && 'bg-opacity-50 text-opacity-50'
        } flex items-center justify-center`}
      >
        {(icon || label) && (
          <div className="flex items-center justify-center">
            {icon}
            {label && <div className={`${icon && 'ml-2'}`}>{label}</div>}
          </div>
        )}
        {children}
      </button>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      data-testid="register-button"
      onClick={onClick}
      disabled={disabled}
      className={`${className} ${
        disabled && 'bg-opacity-50 text-opacity-50'
      } flex items-center justify-center`}
    >
      {(icon || label) && (
        <div className="flex items-center justify-center">
          {icon}
          {label && <div className={`${icon && 'ml-2'}`}>{label}</div>}
        </div>
      )}
      {children}
    </button>
  );
};

AuthPrimaryButton.defaultProps = {
  kind: undefined,
  className: 'primary-button',
  icon: undefined,
};

export default AuthPrimaryButton;
