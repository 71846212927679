import { User } from '@warthungshelden/domain/common';

import { MaintenanceOffer } from './maintenance-offer';

export abstract class MaintenanceOfferExportService {
  public abstract exportToFile(
    maintentenanceOffer: MaintenanceOffer,
    user: User,
    additionalData?: {
      installer?: string;
      orderNumber?: string;
      contractNumber?: string;
      serials?: string;
      project?: string;
      addressNotes?: string;
      workNotes?: string;
    }
  ): Promise<NodeJS.ReadableStream>;
}
