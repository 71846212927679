import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  wizardStep1ValidationSchema,
  wizardStep2ValidationSchema,
} from '../../../../constants/MaintenanceYupSchema';
import {
  useCreateMaintenanceDocumentation,
  useUpdateMaintenanceDocumentation,
} from '../../../../services/api/maintenance-documentation/maintenance-documentation-api';
import { setDocumentationsFor } from '../../../../services/documentationService';
import MaintenanceWizardCreatePage from './MaintenanceWizardCreatePage';
import MaintenanceWizardUpdatePage from './MaintenanceWizardUpdatePage';

const MaintenanceWizardPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { maintenanceObjectId } = useParams();
  const { state } = useLocation();

  const initialStep: number = (state as any)?.initialStep ?? 1;

  const [activeStep, setActiveStep] = useState(initialStep);

  const {
    mutateAsync: createMaintenanceDocumentation,
    isLoading: createMaintenanceDocumentationLoading,
  } = useCreateMaintenanceDocumentation();

  const {
    mutateAsync: updateMaintenanceDocumentation,
    isLoading: updateMaintenanceDocumentationLoading,
  } = useUpdateMaintenanceDocumentation();

  const isLoading =
    createMaintenanceDocumentationLoading ||
    updateMaintenanceDocumentationLoading;

  const validationSchema = [
    wizardStep1ValidationSchema,
    wizardStep2ValidationSchema,
  ][activeStep - 1];

  const documentationsFor = setDocumentationsFor(
    createMaintenanceDocumentation,
    updateMaintenanceDocumentation
  );

  return maintenanceObjectId ? (
    <MaintenanceWizardUpdatePage
      maintenanceObjectId={maintenanceObjectId}
      validationSchema={validationSchema}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      isLoading={isLoading}
      setDocumentationsFor={documentationsFor}
    />
  ) : (
    <MaintenanceWizardCreatePage
      setActiveStep={setActiveStep}
      activeStep={activeStep}
      validationSchema={validationSchema}
      isLoading={isLoading}
      setDocumentationsFor={documentationsFor}
    />
  );
};

export default MaintenanceWizardPage;
