import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  FallProtection,
  MaintenanceType,
  SingleAnchorFallProtectionSystem,
} from '../../../maintenance-object';

export class SingleAnchorsSystemValuesMustBeGreaterZeroRule<
  T extends { type: MaintenanceType }
> extends DomainRule<T, T> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (
      entity.type instanceof FallProtection &&
      entity.type.system instanceof SingleAnchorFallProtectionSystem &&
      entity.type.system.singleAnchors <= 0
    ) {
      throw new DomainRuleNotSatisfiedError(
        SingleAnchorsSystemValuesMustBeGreaterZeroRule
      );
    }
    return entity;
  }
}
