import { Combobox } from '@headlessui/react';
import { FieldInputProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { DropdownFieldOption } from './DropdownField';

type ComboBoxInputOptions =
  | string[]
  | DropdownFieldOption[]
  | Record<string, string>;

type ComboBoxOptions = Record<string, string>;

interface FormikComboboxProps {
  name: string;
  options: ComboBoxInputOptions;
  disabled?: boolean;
}

const isKeyValueOptions = (
  toCheck: ComboBoxInputOptions
): toCheck is DropdownFieldOption[] =>
  Array.isArray(toCheck) && typeof toCheck[0] === 'object';

const isStringOptions = (toCheck: ComboBoxInputOptions): toCheck is string[] =>
  Array.isArray(toCheck) && typeof toCheck[0] === 'string';

const normalizeOptions = (
  optionsToNormalize: ComboBoxInputOptions
): ComboBoxOptions => {
  if (isKeyValueOptions(optionsToNormalize)) {
    return Object.fromEntries(
      optionsToNormalize.map(({ label, value }) => [label, value])
    );
  }

  if (isStringOptions(optionsToNormalize)) {
    return Object.fromEntries(
      optionsToNormalize.map((value) => [value, value])
    );
  }

  return optionsToNormalize;
};

const getLabels = (optionsToCast: ComboBoxInputOptions) => {
  if (Array.isArray(optionsToCast)) {
    return optionsToCast.map((option) =>
      typeof option === 'string' ? option : option.label
    );
  }
  return Object.keys(optionsToCast);
};

const byQuery = (query: string) => (option) =>
  option.toLowerCase().trim().includes(query.toLowerCase().trim());

const reverseOptions = (options: ComboBoxOptions): ComboBoxOptions =>
  Object.fromEntries(Object.entries(options).map((a) => a.reverse()));

const FormikCombobox: React.FC<
  React.PropsWithChildren<FormikComboboxProps>
> = ({ name, options, disabled = false }: FormikComboboxProps) => {
  const [query, setQuery] = useState('');
  const { setFieldValue, getFieldProps, status } = useFormikContext<never>();
  const errorKeys = Object.keys(status ?? {});

  const normalizedOptions = normalizeOptions(options);
  const labelToValue = reverseOptions(normalizedOptions);

  const searchResults =
    query === ''
      ? getLabels(options)
      : getLabels(options).filter(byQuery(query));

  const filteredOptions = Object.fromEntries(
    Object.entries(normalizedOptions).filter(([key]) =>
      searchResults.includes(key)
    )
  );

  return (
    <div
      className={`w-full ${
        name && errorKeys.includes(name) ? 'border-red border-2 rounded-lg' : ''
      }`}
    >
      <Combobox
        disabled={disabled}
        value={getFieldProps(name)}
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        data-cy="components-basics-combo-box"
        data-name={name}
      >
        {({ open }) => {
          useEffect(() => {
            if (!open) {
              setQuery('');
            }
          }, [open]);

          return (
            <div className="relative">
              <div className="relative w-full border-2 box-border rounded-lg border-gray-light cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-600">
                <Combobox.Input
                  className={`w-full h-12 border-none py-2 pl-3 pr-10 test-base leading-5 focus:ring-0 ${
                    disabled
                      ? 'text-gray-dark bg-gray-lighter'
                      : 'text-gray-900'
                  }`}
                  displayValue={(option?: FieldInputProps<string>) => {
                    return option?.value ? labelToValue[option.value] : '';
                  }}
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                />
                <Combobox.Button
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                  data-cy="components-basics-combo-box-button"
                >
                  <FaChevronDown
                    className={`${
                      disabled ? 'text-gray-300' : 'text-gray-dark'
                    }`}
                  />
                </Combobox.Button>
              </div>
              <Combobox.Options className="absolute mt-1 max-h-60 w-full z-10 overflow-auto text-base rounded-md bg-white py-1 focus:outline-none text-base border-l-2 border-r-2 border-b-2 border-black">
                {searchResults.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nichts gefunden.
                  </div>
                ) : (
                  Object.entries(filteredOptions).map(([key, value]) => {
                    return (
                      <Combobox.Option
                        key={key}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 px-4 ${
                            active ? 'bg-blue-abs text-white' : 'text-gray-900'
                          }`
                        }
                        value={value}
                      >
                        {key}
                      </Combobox.Option>
                    );
                  })
                )}
              </Combobox.Options>
            </div>
          );
        }}
      </Combobox>
    </div>
  );
};

FormikCombobox.defaultProps = {
  disabled: false,
};

export default FormikCombobox;
