import {
  DistanceMatrix,
  GeoAdapter,
  GeoCoordinate,
  GeoDescription,
} from './geo';

export class RessourcePlanningService {
  constructor(private geoAdapter: GeoAdapter) {}

  public async calculateDistanceMatrixFor(
    sources: (GeoCoordinate | GeoDescription)[],
    destinations: (GeoCoordinate | GeoDescription)[]
  ): Promise<DistanceMatrix> {
    return this.geoAdapter.calculateDistanceMatrix(sources, destinations);
  }

  public async getCoordinatesFor(
    address: GeoDescription
  ): Promise<GeoCoordinate[]> {
    return this.geoAdapter.getCoordinates(address);
  }
}
