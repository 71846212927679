import { DomainEventDispatcher } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';
import { to } from '@warthungshelden/shared-functions';
import { addMonths } from 'date-fns';

import { BuildingRepository } from '../building';
import {
  MaintenanceDocumentationFileService,
  MaintenanceDocumentationRepository,
  MaintenanceDocumentationService,
} from '../maintenance-documentation';
import {
  MaintenanceOfferDecisionSet,
  MaintenanceOfferStatusEnum,
} from '../maintenance-offer';
import { RequestStatusEnum } from '../maintenance-request';
import {
  MaintenanceRequestCreated,
  MaintenanceRequestStatusChanged,
} from '../maintenance-request';
import {
  CanNotBeInRequestRule,
  CanNotChangeOwnerRule,
  MustBeMaintenanceTeamRule,
  MustBeOwnerRule,
  MustExistRule,
} from '../rules';
import {
  MaintenanceObjectCreated,
  MaintenanceObjectDeleted,
  MaintenanceObjectUpdated,
} from './events';
import { MaintenanceTeamMaintenanceDurationPolicy } from './maintenance-duration-policy';
import { MaintenanceObject } from './maintenance-object';
import { MaintenanceObjectRepository } from './maintenance-object.repository';

export class MaintenanceObjectService {
  constructor(
    protected readonly eventDispatcher: DomainEventDispatcher,
    protected readonly maintenanceRepository: MaintenanceObjectRepository,
    protected readonly buildingRepository: BuildingRepository,
    protected readonly maintenanceDocumentationRepository: MaintenanceDocumentationRepository,
    protected readonly maintenanceDocumentationService: MaintenanceDocumentationService,
    protected readonly maintenanceDocumentationFileService: MaintenanceDocumentationFileService
  ) {
    this.eventDispatcher.register(MaintenanceRequestCreated, async (event) => {
      const request = event.maintenanceRequest;
      await this.attachToRequest(request.objectIds, request.id);
    });

    this.eventDispatcher.register(
      MaintenanceRequestStatusChanged,
      this.onMaintenanceRequestStatusChanged.bind(this)
    );

    this.eventDispatcher.register(
      MaintenanceOfferDecisionSet,
      this.onMaintenanceOfferDecisionSet.bind(this)
    );
  }

  public async add(
    user: User,
    newMaintenanceObject: Omit<MaintenanceObject, 'ownerId'>
  ): Promise<MaintenanceObject> {
    const maintenanceObject = newMaintenanceObject.copyWith({
      ownerId: user.id,
    });

    if (maintenanceObject.buildingId) {
      await new MustExistRule(this.buildingRepository)
        .and(new MustBeOwnerRule(maintenanceObject.ownerId))
        .apply(maintenanceObject.buildingId);
    }

    const createdMaintenanceObject = await this.maintenanceRepository.create(
      maintenanceObject
    );

    await this.eventDispatcher.dispatch(
      new MaintenanceObjectCreated(user, createdMaintenanceObject)
    );

    return createdMaintenanceObject;
  }

  public async update(
    user: User,
    newMaintenanceObject: Pick<MaintenanceObject, 'id' | 'copyWith'> &
      Partial<MaintenanceObject>
  ): Promise<MaintenanceObject> {
    const oldMaintenanceObject = await this.getById(
      newMaintenanceObject.id,
      user
    );

    await new CanNotChangeOwnerRule(oldMaintenanceObject.ownerId).apply(
      newMaintenanceObject
    );

    const maintenanceObject = newMaintenanceObject.copyWith({
      ownerId: user.id,
    });

    await new MustExistRule(this.maintenanceRepository)
      .and(new MustBeOwnerRule(user.id))
      .apply(maintenanceObject.id);

    if (maintenanceObject.buildingId) {
      await new MustExistRule(this.buildingRepository)
        .and(new MustBeOwnerRule(user.id))
        .apply(maintenanceObject.buildingId);
    }

    const updatedMaintenanceObject = await this.maintenanceRepository.update(
      maintenanceObject
    );

    await this.eventDispatcher.dispatch(
      new MaintenanceObjectUpdated(
        user,
        oldMaintenanceObject,
        updatedMaintenanceObject
      )
    );

    return updatedMaintenanceObject;
  }

  public async attachToRequest(
    objectIds: string[],
    requestId: string
  ): Promise<MaintenanceObject[]> {
    return await Promise.all(
      objectIds.map((id) =>
        this.maintenanceRepository.update({
          id,
          currentRequestId: requestId,
        })
      )
    );
  }

  public async releaseFromRequest(
    objectIds: string[]
  ): Promise<MaintenanceObject[]> {
    return await Promise.all(
      objectIds.map((id) =>
        this.maintenanceRepository.update({
          id,
          currentRequestId: null,
        })
      )
    );
  }

  public async releaseFromClosedRequest(
    objectIds: string[],
    executionDate?: Date | null
  ): Promise<MaintenanceObject[]> {
    const objects = await this.maintenanceRepository.getAllById(objectIds);

    return await Promise.all(
      objects.map(({ id, frequency, dueDate }) =>
        this.maintenanceRepository.update({
          id,
          currentRequestId: null,
          dueDate: executionDate
            ? addMonths(executionDate, frequency ?? 12)
            : dueDate,
        })
      )
    );
  }

  public async getById(id: string, user: User): Promise<MaintenanceObject> {
    await new MustExistRule(this.maintenanceRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    return this.maintenanceRepository.getById(id);
  }

  public async getAllById(
    user: User,
    ids: string[]
  ): Promise<MaintenanceObject[]> {
    await Promise.all(
      ids.map((id) =>
        new MustExistRule(this.maintenanceRepository)
          .and(
            new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user))
          )
          .apply(id)
      )
    );

    return this.maintenanceRepository.getAllById(ids);
  }

  public async getAll(user: User): Promise<MaintenanceObject[]> {
    await new MustBeMaintenanceTeamRule(user).apply(undefined);
    return this.maintenanceRepository.getAll();
  }

  public async getAllAccessibleByUser(
    user: User
  ): Promise<MaintenanceObject[]> {
    return user.isMaintenanceTeamMemberAdmin
      ? this.maintenanceRepository.getAll()
      : this.maintenanceRepository.getAllByUser(user.id);
  }

  public async delete(user: User, objectId: string) {
    const maintenanceObject = await new MustExistRule(
      this.maintenanceRepository
    )
      .and(new MustBeOwnerRule(user.id))
      .and(new CanNotBeInRequestRule())
      .apply(objectId);

    const maintenanceDocumentations =
      await this.maintenanceDocumentationRepository.getAllByUser(user.id);

    for (const documentation of maintenanceDocumentations) {
      const filteredObjects = documentation.maintenanceObjectIds.filter(
        (documentationObjectId) => documentationObjectId !== objectId
      );
      if (filteredObjects.length === 0) {
        await this.maintenanceDocumentationRepository.delete(documentation.id);
        await this.maintenanceDocumentationFileService.deleteFileFor(
          user,
          documentation.fileName
        );
      } else {
        await this.maintenanceDocumentationRepository.update({
          ...documentation,
          maintenanceObjectIds: filteredObjects,
        });
      }
    }

    await this.maintenanceRepository.delete(objectId);

    await this.eventDispatcher.dispatch(
      new MaintenanceObjectDeleted(user, maintenanceObject)
    );
  }

  public async getDurationPolicy(user: User) {
    const docs = await this.maintenanceDocumentationService.getAllAccessibleBy(
      user
    );

    return new MaintenanceTeamMaintenanceDurationPolicy(
      this.buildingRepository,
      docs
    );
  }

  private async onMaintenanceRequestStatusChanged(
    event: MaintenanceRequestStatusChanged
  ) {
    if (event.maintenanceRequest.status === RequestStatusEnum.CLOSED) {
      const executionDate =
        event.currentOffer?.order?.executionDate ??
        event.currentOffer?.suggestedDate;

      await this.releaseFromClosedRequest(
        event.maintenanceRequest.objectIds,
        executionDate
      );
    }

    if (event.maintenanceRequest.status === RequestStatusEnum.CANCELED) {
      await this.releaseFromRequest(event.maintenanceRequest.objectIds);
    }
  }

  private async onMaintenanceOfferDecisionSet(
    event: MaintenanceOfferDecisionSet
  ) {
    const isDeclined =
      event.maintenanceOffer.decision.status ===
      MaintenanceOfferStatusEnum.DECLINED;

    if (isDeclined) {
      await this.releaseFromRequest(
        event.maintenanceOffer.maintenanceObjects.map(to('originalId'))
      );
    }
  }
}
