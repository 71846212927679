import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';
import { DomainRule } from '@warthungshelden/domain/common';

import {
  MaintenanceOffer,
  MaintenanceOfferStatusEnum,
} from '../../maintenance-offer';

export class MustNotBeSent<T extends MaintenanceOffer> extends DomainRule<
  T,
  T
> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    console.log(entity.decision);

    if (entity.decision.status !== MaintenanceOfferStatusEnum.DRAFT) {
      throw new DomainRuleNotSatisfiedError(MustNotBeSent);
    }
    return entity as T;
  }
}
