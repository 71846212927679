import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { BuildingDto } from '@wartungshelden/shared-types';

import { FormikMaintenanceWizardValues } from '../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { useBuildings } from '../../services/api/buildings/buildings-api';
import ButtonList from '../Basics/Inputs/ButtonList';
import ConfirmationModal from '../Basics/Modals/ConfirmationModal';
import { SelectField } from '../index';

type BuildingsSelectFieldProps = {
  disabled: boolean;
  isRequired?: boolean;
};

const BuildingsSelectField: React.FC<
  React.PropsWithChildren<BuildingsSelectFieldProps>
> = ({ disabled, isRequired }) => {
  const { data: buildings } = useBuildings();

  const { setFieldValue, values } =
    useFormikContext<FormikMaintenanceWizardValues>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<BuildingDto>();

  const selectedBuilding = buildings?.find(
    (building) => building.id === values.buildingId
  );

  return (
    <>
      <SelectField
        label={`Gebäude${isRequired ? '*' : ''}`}
        name="buildingId"
        placeHolder="Gebäude / Gebäudeteil wählen"
        alternativeDisplayText={selectedBuilding ? selectedBuilding?.name : ''}
        onClick={() => !disabled && setIsModalVisible(true)}
        disabled={disabled}
      />
      <ConfirmationModal
        heading="Gebäude wählen"
        onAccept={() => {
          if (!selectedItem) return;
          setFieldValue('buildingId', selectedItem.id);
          setIsModalVisible(false);
        }}
        onCancel={() => setIsModalVisible(false)}
        isVisible={isModalVisible}
      >
        <ButtonList
          selectionItemRepresentationKey="name"
          setSelectedItem={setSelectedItem}
          items={buildings}
          selectedItem={selectedItem}
        />
      </ConfirmationModal>
    </>
  );
};

BuildingsSelectField.defaultProps = {
  isRequired: false,
};

export default BuildingsSelectField;
