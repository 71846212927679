import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { FallProtection, MaintenanceObject } from '../../maintenance-object';

export class AccessMustBeSetRule extends DomainRule<
  MaintenanceObject,
  MaintenanceObject
> {
  constructor() {
    super();
  }

  protected async rule(maintenanceObject: MaintenanceObject) {
    if (!(maintenanceObject.type instanceof FallProtection))
      return maintenanceObject;

    if (maintenanceObject.type.access.length === 0) {
      throw new DomainRuleNotSatisfiedError(AccessMustBeSetRule);
    }
    return maintenanceObject;
  }
}
