import {
  FallProtectionSystemDto,
  PushLockFallProtectionSystemDto,
  RopeFallProtectionSystemDto,
  SingleAnchorFallProtectionSystemDto,
} from '@wartungshelden/shared-types';

export const isSingleAnchorSystemDto = (
  value?: FallProtectionSystemDto
): value is SingleAnchorFallProtectionSystemDto => {
  return value !== undefined && value.system === 'single_anchor';
};

export const isPushLockSystemDto = (
  value?: FallProtectionSystemDto
): value is PushLockFallProtectionSystemDto => {
  return value !== undefined && value.system === 'push_lock';
};

export const isRopeSystemDto = (
  value?: FallProtectionSystemDto
): value is RopeFallProtectionSystemDto => {
  return value !== undefined && value.system === 'rope';
};
