import React, { useContext } from 'react';

import { adminRoutePrefix, routes } from '../../../constants/routes';
import { SessionContext } from '../../../contexts/SessionContext';
import Tab from './Tab';

const UserNavigation: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isMaintenanceTeamMemberAdmin, isMaintenanceTeamMemberUser } =
    useContext(SessionContext);

  if (isMaintenanceTeamMemberUser) {
    return <Tab to={routes.price}>Wartungspreisrechner</Tab>;
  }

  if (isMaintenanceTeamMemberAdmin) {
    return (
      <>
        <Tab to={`${adminRoutePrefix}/${routes.requests}`}>
          Administrationsmenü
        </Tab>
        <Tab to={`${adminRoutePrefix}/${routes.plan}`}>Tourenplan</Tab>
        <Tab to={`${adminRoutePrefix}/${routes.price}`}>
          Wartungspreisrechner
        </Tab>
        <Tab to={`${adminRoutePrefix}/${routes.installerOrder}`}>
          Wartungsauftrag
        </Tab>
      </>
    );
  }

  return (
    <>
      <Tab to={routes.dashboard}>Dashboard</Tab>
      <Tab to={routes.buildings}>Gebäude</Tab>
      <Tab to={routes.maintenance}>Wartungsaufgaben</Tab>
      <Tab to={routes.requests}>Aufträge</Tab>
    </>
  );
};

export default UserNavigation;
