import { Disclosure } from '@headlessui/react';
import Tooltip from 'rc-tooltip';
import React from 'react';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';

import CheckBoxField from '../../../../components/Basics/Inputs/CheckBox/CheckBoxField';

interface TestProcedureCheckboxSectionProps {
  headline: string;
  checkboxes: {
    name: string;
    label: string;
    shortLabel?: string;
  }[];
}

const TestProcedureCheckboxSection: React.FC<
  TestProcedureCheckboxSectionProps
> = ({ headline, checkboxes }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-2 flex flex-row items-center text-left cursor-pointer bg-gray-light p-4 rounded w-full">
            <div className="w-full">{headline}</div>
            <div>
              {open ? (
                <FaAngleDown className="ml-4" />
              ) : (
                <FaAngleRight className="ml-4" />
              )}
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <div className="flex flex-wrap justify-start items-start">
              {checkboxes.map((checkbox) => {
                if (checkbox.shortLabel) {
                  return (
                    <Tooltip
                      overlay={checkbox.label}
                      placement="topLeft"
                      trigger="hover"
                    >
                      <div className="basis-1/3 my-4">
                        <CheckBoxField
                          name={checkbox.name}
                          disabled={false}
                          label={`${checkbox.shortLabel} ...`}
                          checkboxAlignment="items-start"
                        />
                      </div>
                    </Tooltip>
                  );
                }

                return (
                  <div className="basis-1/3 my-4">
                    <CheckBoxField
                      name={checkbox.name}
                      disabled={false}
                      label={checkbox.shortLabel ?? checkbox.label}
                      checkboxAlignment="items-start"
                    />
                  </div>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default TestProcedureCheckboxSection;
