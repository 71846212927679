import { User } from '@warthungshelden/domain/common';

import { MaintenancePrice } from '../maintenance-price';
import { FixedFixedPriceStrategy } from './fixed-base-price-strategy';
import { NoFixedPriceStrategy } from './no-base-price-strategy';
import {
  PriceableBuilding,
  PriceableMaintenanceObject,
} from './priceable-maintenance-object';
import { WartungsheldenPriceStrategy } from './wartungshelden-maintenance-price-strategy';

export enum Reasons {
  BASIS = 'Basispreis',
  LOCATION = 'Aufschlag aufgrund der Position der Montage',
  DOCUMENTATION = 'Aufschlag aufgrund fehlender Dokumentation',
  PUBLIC_BUILDING = 'Aufschlag aufgrund öffentlichen Gebäudes',
  HAS_LADDER_DISCOUNT = 'Rabatt aufgrund zugehöriger Steigleiter',
  MASS_DISCOUNT_RAILINGS = 'Mengenrabatt (über 150 m)',
  MASS_DISCOUNT_LADDERS = 'Mengenrabatt (ab 5 Leitern)',
  MASS_DISCOUNT_LADDERS_2 = 'Mengenrabatt (ab 10 Leitern)',
  MASS_DISCOUNT_FOREIGN = 'Mengenrabatt (ab 20 EAP)',
  MASS_DISCOUNT_ABS = 'Mengenrabatt (ab 30 EAP)',
  MASS_DISCOUNT_2 = 'Mengenrabatt (ab 100 EAP)',
  REDISTRIBUTION = 'Verteilung Grundkosten',
  NO_EASY_ACCESS = 'Aufschlag aufgrund schwieriger Zugänglichkeit',
  SINGLE_ANCHOR_SURCHARGE = 'Aufschlag für Einzelanschlagpunkte',
  ROPE_LENGTH_MASS_DISCOUNT = 'Mengenrabatt (250 m - 499 m)',
  ROPE_LENGTH_MASS_DISCOUNT_2 = 'Mengenrabatt (500 m - 999 m)',
  ROPE_LENGTH_MASS_DISCOUNT_3 = 'Mengenrabatt (ab 1000 m)',
  SAIL_SYSTEM_LENGTH_MASS_DISCOUNT = 'Mengenrabatt (250 m - 499 m)',
  SAIL_SYSTEM_LENGTH_MASS_DISCOUNT_2 = 'Mengenrabatt (500 m - 999 m)',
  SAIL_SYSTEM_LENGTH_MASS_DISCOUNT_3 = 'Mengenrabatt (ab 1000 m)',
}

export interface MaintenanceObjectPriceInformation {
  object: PriceableMaintenanceObject;
  building?: PriceableBuilding;
  hasValidDocumentation?: boolean;
}

export interface MaintenancePriceStrategy {
  calculatePriceForObjects(
    user: User,
    information: MaintenanceObjectPriceInformation[]
  ): Promise<MaintenancePrice>;
}

export interface MaintenancePriceStrategies {
  default: MaintenancePriceStrategy;
  wartungshelden: WartungsheldenPriceStrategy;
  'fixed-fix-price': FixedFixedPriceStrategy;
  'no-fix-price': NoFixedPriceStrategy;
}

export type MaintenancePriceStrategyName = keyof MaintenancePriceStrategies;

export const MaintenancePriceStrategyNames: MaintenancePriceStrategyName[] = [
  'default',
  'wartungshelden',
  'fixed-fix-price',
  'no-fix-price',
];
