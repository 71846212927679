import {
  MaintenancePriceStrategyName,
  PriceComponentKind,
  PriceComponentType,
} from '@warthungshelden/domain/maintenance-exchange';

import { MaintenanceTypeDto } from './maintenance-object';

export interface GetMaintenancePriceDto {
  readonly strategy?: MaintenancePriceStrategyName;
  readonly objectIds: string[];
}

export interface MaintenancePriceAdaptationDto {
  readonly value: number;
  readonly reason: string;
}

export interface MaintenanceDiscountDto {
  readonly name: string;
  readonly value: number;
  readonly type: 'percentage' | 'absolute';
}

export interface MaintenancePriceDto {
  readonly strategy: MaintenancePriceStrategyName;
  readonly netTotal: number | null;
  readonly fixedTotal: number | null;
  readonly variableTotal: number | null;
  readonly netDocumentationSavings: number | null;
  readonly maximumDocumentationSavings: number | null;
  readonly discounts: MaintenanceDiscountDto[] | null;
  readonly priceAdaptation?: MaintenancePriceAdaptationDto | null;
  readonly objectPrices?: MaintenanceObjectPriceDto[] | null;
  readonly unsupportedObjects?: PriceUnsupportedMaintenanceObjectDto[] | null;
}

export interface CustomerMaintenancePriceDto {
  readonly netTotal: number | null;
  readonly netDocumentationSavings: number | null;
  readonly maximumDocumentationSavings: number | null;
  readonly priceAdaptation?: MaintenancePriceAdaptationDto | null;
  readonly discounts: MaintenanceDiscountDto[] | null;
  readonly objectPrices?: CustomerMaintenanceObjectPriceDto[] | null;
  readonly unsupportedObjects?: PriceUnsupportedMaintenanceObjectDto[] | null;
}

export interface MaintenanceObjectPriceDto {
  readonly objectId: string;
  readonly variableNetPrice: number | null;
  readonly fixedNetPrice: number | null;
  readonly netDocumentationSavings: number | null;
  readonly maximumNetDocumentationSavings: number | null;
  readonly fixedPriceComponents: PriceComponentsDto;
  readonly variablePriceComponents: PriceComponentsDto;
  readonly absoluteFixedPriceComponents: PriceComponentsDto;
  readonly absoluteVariablePriceComponents: PriceComponentsDto;
}

export interface PriceComponentsDto {
  readonly actual: PriceComponentDto[];
  readonly max: PriceComponentDto[];
}

export interface CustomerMaintenanceObjectPriceDto {
  readonly objectId: string;
}

export interface PriceUnsupportedMaintenanceObjectDto {
  readonly objectId: string;
  readonly reason: string | null;
}

export interface PriceComponentDto {
  readonly position: number;
  readonly kind: PriceComponentKind;
  readonly type: PriceComponentType | string;
  readonly value: number;
  readonly reason: string | null;
}

export interface PriceableObjectDto<
  T extends MaintenanceTypeDto = MaintenanceTypeDto
> {
  readonly manufacturer: string | null;
  readonly id: string;
  readonly type: T;
}

export interface PriceableBuildingDto {
  isPublic: boolean;
  buildingType: string;
}

export interface PriceInformationObjectDto<
  T extends PriceableObjectDto = PriceableObjectDto
> {
  readonly object: T;
  readonly building?: PriceableBuildingDto;
  readonly hasValidDocumentation?: boolean;
}

export interface PriceInformationDto {
  objectInformation: PriceInformationObjectDto[];
  strategy?: MaintenancePriceStrategyName;
}
