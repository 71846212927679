import { useFormikContext } from 'formik';
import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';

import { LOCK_II_FIELDS } from '../../../constants/MaintenanceConstants';
import {
  FormikFallProtection,
  FormikFallProtectionPushLockSystem,
  FormikFallProtectionRopeSystem,
  FormikFallProtectionSingleAnchorSystem,
} from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { locationValueToLabel } from '../../../services/maintenanceService';

interface PropertyCellViewProps {
  setIsModalVisible: (
    value: boolean | ((prevValue: boolean) => boolean)
  ) => void;
  formikFallProtection: FormikFallProtection;
  formikFallProtectionRopeSystem?: FormikFallProtectionRopeSystem;
  formikFallProtectionPushLockSystem?: FormikFallProtectionPushLockSystem;
  formikFallProtectionSingleAnchorSystem?: FormikFallProtectionSingleAnchorSystem;
  disabled?: boolean;
}

const PropertyCellView: React.FC<
  React.PropsWithChildren<PropertyCellViewProps>
> = ({
  setIsModalVisible,
  formikFallProtection,
  formikFallProtectionRopeSystem,
  formikFallProtectionPushLockSystem,
  formikFallProtectionSingleAnchorSystem,
  disabled = false,
}) => {
  const { status } = useFormikContext();
  const errorKeys = status ? Object.keys(status) : [];
  if (
    !formikFallProtectionRopeSystem &&
    !formikFallProtectionPushLockSystem &&
    !formikFallProtectionSingleAnchorSystem
  )
    return null;

  const getRoofAccessAsString = () => {
    const values: string[] = [];
    if (formikFallProtection.ladder) {
      values.push('Leiter');
    }
    if (formikFallProtection.climbLadder) {
      values.push('Steigleiter');
    }
    if (formikFallProtection.liftingPlatform) {
      values.push('Hubbühne');
    }
    if (formikFallProtection.roofExit) {
      values.push('Dachausstieg');
    }
    if (formikFallProtection.scaffold) {
      values.push('Gerüst');
    }
    if (formikFallProtection.other && formikFallProtection.otherText) {
      values.push(formikFallProtection.otherText);
    }
    return values.join(', ');
  };

  return (
    <div
      className={`${
        disabled ? 'bg-gray-lighter text-gray-dark' : 'bg-white'
      } border-2 border-gray p-1 relative`}
    >
      <span
        className={`${
          errorKeys.includes('maintenanceObjects.0.location') ? 'text-red' : ''
        }`}
      >
        Position:{' '}
      </span>
      {locationValueToLabel(formikFallProtection)}
      {formikFallProtectionRopeSystem && (
        <div>
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.ropeLength')
                ? 'text-red'
                : ''
            }`}
          >
            Seillänge:{' '}
          </span>
          {formikFallProtectionRopeSystem.ropeLength} m
          <br />
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.systemAnchors')
                ? 'text-red'
                : ''
            }`}
          >
            Systemstützen:{' '}
          </span>
          {formikFallProtectionRopeSystem.systemAnchors} Stück
          <br />
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.singleAnchors')
                ? 'text-red'
                : ''
            }`}
          >
            Einzelanschlagunkte:{' '}
          </span>
          {formikFallProtectionRopeSystem.singleAnchors} Stück
        </div>
      )}
      {formikFallProtectionPushLockSystem && (
        <div>
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.lock1')
                ? 'text-red'
                : ''
            }`}
          >
            {LOCK_II_FIELDS[0].label}:{' '}
          </span>
          {formikFallProtectionPushLockSystem.lock1} Stück
          <br />
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.lock2')
                ? 'text-red'
                : ''
            }`}
          >
            {LOCK_II_FIELDS[1].label}:{' '}
          </span>
          {formikFallProtectionPushLockSystem.lock2} Stück
        </div>
      )}
      {formikFallProtectionSingleAnchorSystem && (
        <div>
          <span
            className={`${
              errorKeys.includes('maintenanceObjects.0.system.singleAnchors')
                ? 'text-red'
                : ''
            }`}
          >
            Einzelanschlagpunkte:{' '}
          </span>
          {formikFallProtectionSingleAnchorSystem.singleAnchors} Stück
        </div>
      )}
      <div>
        {' '}
        <span className={`${errorKeys.includes('access') ? 'text-red' : ''}`}>
          Zugang:{' '}
        </span>
        {getRoofAccessAsString()}
      </div>
      {!disabled && (
        <div
          role="button"
          tabIndex={0}
          className="absolute top-1 right-1 text-xl cursor-pointer"
          onClick={() => setIsModalVisible(true)}
          onKeyDown={() => setIsModalVisible(true)}
        >
          <FaPencilAlt />
        </div>
      )}
    </div>
  );
};

PropertyCellView.defaultProps = {
  formikFallProtectionRopeSystem: undefined,
  formikFallProtectionPushLockSystem: undefined,
  formikFallProtectionSingleAnchorSystem: undefined,
  disabled: undefined,
};

export default PropertyCellView;
