import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  CreateMaintenanceRequestDto,
  CustomerMaintenancePriceDto,
  CustomerMaintenanceRequestDto,
  GetMaintenancePriceDto,
  MaintenancePriceDto,
  MaintenanceRequestDto,
  UpdateMaintenanceRequestDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

export const useMaintenanceRequests = () => {
  const axios = useAxios();

  const fetchMaintenanceRequests = async (): Promise<
    (MaintenanceRequestDto | CustomerMaintenanceRequestDto)[] | undefined
  > => {
    if (!axios) return undefined;
    const { data } = await axios.get<
      (MaintenanceRequestDto | CustomerMaintenanceRequestDto)[]
    >('/maintenance-exchange/maintenance/requests');
    const tempData = data.map((request) => ({
      ...request,
      created: new Date(request.created),
    }));
    return tempData;
  };

  return useQuery('maintenanceRequests', fetchMaintenanceRequests);
};

export const useUpdateMaintenanceRequest = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const updateMaintenanceRequest = async (
    maintenanceRequest: UpdateMaintenanceRequestDto
  ) => {
    if (!axios) return null;
    return axios.patch(
      `/maintenance-exchange/maintenance/requests`,
      maintenanceRequest
    );
  };

  return useMutation(updateMaintenanceRequest, {
    onSuccess: async () => {
      toast.success('Speichern erfolgreich', { position: 'top-center' });
      await rqClient.invalidateQueries(['maintenanceRequests']);
    },
    onError: () => {
      toast.error('Speichern erfolglos', {
        position: 'top-center',
      });
    },
  });
};

export const useCreateMaintenanceRequest = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const createMaintenanceRequest = async (
    maintenanceRequest: CreateMaintenanceRequestDto
  ) => {
    if (!axios) return null;
    return axios.post(
      '/maintenance-exchange/maintenance/requests',
      maintenanceRequest
    );
  };

  return useMutation(createMaintenanceRequest, {
    onSuccess: async () => {
      await rqClient.invalidateQueries(['maintenanceRequests']);
    },
  });
};

export const useRemoveMaintenanceRequest = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const deleteMaintenanceRequest = async (id: string) => {
    if (!axios) return null;
    return axios.delete(`/maintenance-exchange/maintenance/requests/${id}`);
  };

  return useMutation(deleteMaintenanceRequest, {
    onSuccess: async () => {
      await rqClient.invalidateQueries('maintenanceRequests');
    },
  });
};

export const useMaintenanceRequestPriceIndication = (
  dto: GetMaintenancePriceDto
) => {
  const axios = useAxios();

  const fetchPriceIndication = async () => {
    if (!axios) return null;
    const { data } = await axios.post<
      MaintenancePriceDto | CustomerMaintenancePriceDto
    >('/maintenance-exchange/maintenance/requests/price', dto);
    return data;
  };

  return useQuery(
    ['maintenanceRequestPriceIndication', dto],
    fetchPriceIndication
  );
};
