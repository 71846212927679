export abstract class DomainEvent {
  public static readonly tag: string;

  public get tag() {
    return (<typeof DomainEvent>this.constructor).tag;
  }
}

export interface DomainEventDispatcher {
  dispatch<T extends DomainEvent>(event: T): Promise<void>;

  register<T extends DomainEvent>(
    event: new (...args: never[]) => T,
    handler: (event: T) => void
  ): void;
}
