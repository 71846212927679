export interface SelectLineMaintenanceOrderAppointmentDto {
  start?: Date;
  end?: Date;
}

export interface SelectLineMaintenanceOrderContactDto {
  name?: string;
  email?: string;
  phone?: string;
  mobile?: string;
}

export interface SelectLineMaintenanceOrderBuildingAddressDto {
  street?: string;
  city?: string;
  zip?: string;
  country?: string;
}

export interface SelectLineMaintenanceOrderBuildingDto {
  name?: string;
  address?: SelectLineMaintenanceOrderBuildingAddressDto;
}

export interface SelectLineMaintenanceOrderDto {
  orderId: string;
  installerName?: string;
  documentationAvailable?: boolean;
  taskType?: string;
  customerName?: string;
  customerNameAdditive?: string;
  customerNameAdditive2?: string;
  orderNumber?: string;
  contractNumber?: string;
  projectName?: string;
  serialNumbers?: string[];
  descriptions?: string[];
  building?: SelectLineMaintenanceOrderBuildingDto;
  contactProject?: SelectLineMaintenanceOrderContactDto;
  appointment?: SelectLineMaintenanceOrderAppointmentDto;
  dueDate?: Date;
}

export interface AdditionalMaintenanceOrderDataBuildingContactDto {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  mobile?: string | null;
}

export interface AdditionalMaintenanceOrderEAPAndRopeSystemTestProcedureDto {
  hasVisualAndFunctionCheck?: boolean;
  hasJoggingTest?: boolean;
  hasTorqueControl?: boolean;
  hasSelectivelyPiecesByMeansOfRoofOpeningByRoofer?: boolean;
  hasTiltDowelControl?: boolean;
  hasLockOnTopControlByMeansOfChainHoist?: boolean;
  hasCheckWelds?: boolean;
  hasExposePiecesPunctual?: boolean;
  hasCheckSuperimposedLoad?: boolean;
  hasPunctualPiecesByMeansOfTensile?: boolean;
}

export interface AdditionalMaintenanceOrderRopeSystemTestProcedureDto {
  hasMountSystemSigns?: boolean;
  hasStickSystemLabels?: boolean;
}

export interface AdditionalMaintenanceOrderInfoAssemblyDocuTestProcedureDto {
  // TODO: Clarify Are the two mutually dependent?
  hasAssemblyDocu?: boolean;
  hasNoAssemblyDocu?: boolean;

  hasAssemblyByABS?: boolean;
  // TODO: Clarify Are the two mutually dependent?
  hasDocuForRopeAssemblyForPillars?: boolean;
  hasNoDocuForRopeAssemblyForPillars?: boolean;
}

export interface AdditionalMaintenanceOrderTestProceduresDto {
  eapAndRopeSystem?: AdditionalMaintenanceOrderEAPAndRopeSystemTestProcedureDto;
  ropeSystem?: AdditionalMaintenanceOrderRopeSystemTestProcedureDto;
  infoAssemblyDocu?: AdditionalMaintenanceOrderInfoAssemblyDocuTestProcedureDto;
}

export interface AdditionalMaintenanceOrderSystemDataDto {
  systemComponents?: string | null;
  testProcedures?: AdditionalMaintenanceOrderTestProceduresDto | null;
  additionalNotes?: string | null;
}

export interface AdditionalMaintenanceOrderDataFallProtectionTestProceduresDto {
  hasCheckSketch?: boolean;
  hasMarkTensileTests?: boolean;
  hasMarkRoofOpenings?: boolean;
  hasDrawASketch?: boolean;
  hasMaintainRoomNumbersLockBook?: boolean;
}

export interface AdditionalMaintenanceOrderDataPsaAndHsgTestProceduresDto {
  hasCheckPSA?: boolean;
  hasCheckPlugInBolts?: boolean;
  hasHandOverPPE?: boolean;
}

export interface AdditionalMaintenanceOrderDataHsgTestProceduresDto {
  hasCheckHSGOnSite?: boolean;
  hasSentHSGForVerification?: boolean;
  hasThirdPartyHSGCannotBeTested?: boolean;
}

export interface AdditionalMaintenanceOrderDataTestProceduresDto {
  fallProtection?: AdditionalMaintenanceOrderDataFallProtectionTestProceduresDto;
  psaAndHsg?: AdditionalMaintenanceOrderDataPsaAndHsgTestProceduresDto;
  hsg?: AdditionalMaintenanceOrderDataHsgTestProceduresDto;
}

export interface AdditionalMaintenanceOrderDataDto {
  orderNumber: string;
  access?: string | null;
  reception?: string | null;
  buildingContact?: AdditionalMaintenanceOrderDataBuildingContactDto | null;
  testProcedures?: AdditionalMaintenanceOrderDataTestProceduresDto;
  systems?: AdditionalMaintenanceOrderSystemDataDto[] | null;
}
