import React from 'react';

import { isSingleAnchorSystemDto } from '../../../../../guards/isFallProtectionSystem';
import { isFallProtectionDto } from '../../../../../guards/isMaintenanceType';
import {
  PricedCombination,
  toPriceCombination,
  toPricesWithDocu,
  toPricesWithoutDocu,
} from '../../utils/priced-combination';
import MaintenanceObjectSummary from '../MaintenanceObjectSummary';
import PricePosition from './PricePosition';

export const roundSingleAnchorUnitPrice = (priceToRound: number) => {
  return Math.round(priceToRound * 100) / 100;
};

interface SubPriceOverviewRowProps {
  singleSubPricedCombination: PricedCombination;
  singleAnchorUnitPriceWithDocu?: number;
  singleAnchorUnitPriceWithoutDocu?: number;
  showPriceWithoutDocu: boolean;
}

const SubPriceOverviewRow: React.FC<SubPriceOverviewRowProps> = ({
  singleSubPricedCombination,
  singleAnchorUnitPriceWithoutDocu,
  singleAnchorUnitPriceWithDocu,
  showPriceWithoutDocu,
}) =>
  // pricePosition1: JSX.Element = pricePosition
  {
    const getSinglePriceForSingleAnchorSystem = (
      unitPrice: number,
      anchors: number
    ) => {
      return unitPrice * anchors;
    };

    const totalSumWithDocu = toPricesWithDocu(singleSubPricedCombination)
      ?.map((objectPrice) => objectPrice?.variableNetPrice)
      .reduce<number>((total, currNetPrice) => total + (currNetPrice ?? 0), 0);

    const totalSumWithoutDocu = toPricesWithoutDocu(singleSubPricedCombination)
      ?.map((objectPrice) => objectPrice?.variableNetPrice)
      .reduce<number>((total, currNetPrice) => total + (currNetPrice ?? 0), 0);

    return (
      <div className="flex">
        <div className="w-1/3 bg-gray-light border-r-2 pt-2 px-2">
          <MaintenanceObjectSummary
            combination={toPriceCombination(singleSubPricedCombination)}
            hasSubprices={false}
            isSubRowSummary
          />
        </div>
        <div className="w-2/3 flex">
          <div className="flex flex-col py-2 pl-4 pr-4 border-r-2 border-dashed w-1/2">
            {totalSumWithDocu && (
              <PricePosition
                singlePrice={
                  isFallProtectionDto(
                    singleSubPricedCombination.primarySystem.combination.object
                      .type
                  ) &&
                  isSingleAnchorSystemDto(
                    singleSubPricedCombination.primarySystem.combination.object
                      .type.system
                  ) &&
                  singleAnchorUnitPriceWithDocu
                    ? getSinglePriceForSingleAnchorSystem(
                        singleAnchorUnitPriceWithDocu,
                        singleSubPricedCombination.primarySystem.combination
                          .object.type.system.singleAnchors
                      )
                    : totalSumWithDocu
                }
                singleAnchorsUnitPrice={singleAnchorUnitPriceWithDocu}
                hasDocu
                showSinglePrice
                showHeadline={false}
              />
            )}
          </div>
          <div className="flex flex-col p-2 pl-4 w-1/2">
            {showPriceWithoutDocu && totalSumWithoutDocu && (
              <PricePosition
                singlePrice={
                  isFallProtectionDto(
                    singleSubPricedCombination.primarySystem.combination.object
                      .type
                  ) &&
                  isSingleAnchorSystemDto(
                    singleSubPricedCombination.primarySystem.combination.object
                      .type.system
                  ) &&
                  singleAnchorUnitPriceWithoutDocu
                    ? getSinglePriceForSingleAnchorSystem(
                        singleAnchorUnitPriceWithoutDocu,
                        singleSubPricedCombination.primarySystem.combination
                          .object.type.system.singleAnchors
                      )
                    : totalSumWithoutDocu
                }
                singleAnchorsUnitPrice={singleAnchorUnitPriceWithoutDocu}
                hasDocu={false}
                showSinglePrice
                showHeadline={false}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

SubPriceOverviewRow.defaultProps = {
  singleAnchorUnitPriceWithoutDocu: undefined,
  singleAnchorUnitPriceWithDocu: undefined,
};

export default SubPriceOverviewRow;
