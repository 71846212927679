import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface IsDocumentValidTipProps {
  isValid?: boolean;
}

const IsDocumentValidTip: React.FC<
  React.PropsWithChildren<IsDocumentValidTipProps>
> = ({ isValid }) => {
  if (isValid === undefined) {
    return null;
  }
  if (isValid) {
    return (
      <div className="flex items-center">
        <Tooltip
          placement="top"
          trigger="hover"
          overlay={
            <div>
              <div>Dokumentation ist aussagekräftig.</div>
              <div>Du sparst Dir Kosten und Mühe.</div>
            </div>
          }
          overlayInnerStyle={{
            padding: 12,
            maxWidth: '18rem',
          }}
        >
          <FaCheckCircle
            data-tip
            data-for="valide"
            className="text-green-dark ml-2 text-2xl"
          />
        </Tooltip>
      </div>
    );
  }
  return (
    <div className="flex items-center">
      <Tooltip
        placement="top"
        trigger="hover"
        mouseLeaveDelay={1}
        overlay={
          <div>
            Achtung! Dokumentation ist nicht aussagekräftig ({' '}
            <Link
              to="/gute-doku"
              target="_blank"
              className="underline underline-offset-2 text-blue-400"
            >
              hier
            </Link>{' '}
            findest Du unsere Kriterien)
          </div>
        }
        overlayInnerStyle={{
          padding: 12,
          maxWidth: '18rem',
        }}
      >
        <FaExclamationCircle
          data-tip
          data-for="invalid"
          className="text-red ml-2 text-2xl"
        />
      </Tooltip>
    </div>
  );
};

IsDocumentValidTip.defaultProps = {
  isValid: undefined,
};

export default IsDocumentValidTip;
