import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceObject } from '../../maintenance-object';

export class ManufacturerMustExistRule<
  T extends MaintenanceObject
> extends DomainRule<T, T> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (entity.manufacturer === undefined) {
      throw new DomainRuleNotSatisfiedError(ManufacturerMustExistRule);
    }
    return entity;
  }
}
