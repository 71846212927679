import React from 'react';

import TextWithLabel from '../../../../components/Basics/TextWithLabel';

const MaintenanceObjectSummaryRow: React.FC<{
  label: string;
  value: number | string | undefined | null;
  unit?: string | undefined;
  hasSubprices: boolean;
}> = ({ label, value, unit = undefined, hasSubprices }) => {
  if (value) {
    return (
      <TextWithLabel
        label={`${label}${hasSubprices ? ' (gesamt)' : ''}:`}
        labelStyle="font-normal w-96"
        dataCy={`summary-${label}`}
      >
        {value} {unit}
      </TextWithLabel>
    );
  }
  return null;
};

MaintenanceObjectSummaryRow.defaultProps = {
  unit: undefined,
};

export default MaintenanceObjectSummaryRow;
