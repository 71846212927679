import { EventEmitter2 } from 'eventemitter2';

import { Logger } from '../logger';
import { DomainEvent, DomainEventDispatcher } from './domain-event-dispatcher';

export class NodeEventsDomainEventDispatcher implements DomainEventDispatcher {
  private readonly domainEventEmitter;

  constructor(private logger?: Logger) {
    this.domainEventEmitter = new EventEmitter2();
  }

  async dispatch<T extends DomainEvent>(event: T) {
    this.logger?.log(`Event triggered: ${event.tag} ${JSON.stringify(event)}`);
    await this.domainEventEmitter.emitAsync(event.tag, event);
  }

  register<T extends DomainEvent>(
    event: new (...args: never[]) => T,
    handler: (event: T) => void
  ) {
    const tag = (event as unknown as typeof DomainEvent).tag;
    this.domainEventEmitter.on(tag, async (event) => {
      await handler(event);
    });
  }
}
