export const MaintenanceBuildingFilterKey = 'maintenance-buildings';
export const MaintenanceServiceTypeFilterKey = 'maintenance-service-type';

export const RequestStatusFilterKey = 'request-statuses';
export const RequestServiceTypeFilterKey = 'request-service-types';
export const RequestAddressesTypeFilterKey = 'request-addresses';

export const saveFilter = <T>(name: string, values: T[]) => {
  if (values.length === 0) {
    localStorage.removeItem(`filter-${name}`);
  }
  localStorage.setItem(`filter-${name}`, JSON.stringify(values));
};

export const getFilter = <T>(name: string): T[] | null => {
  const json = localStorage.getItem(`filter-${name}`);
  if (!json) {
    return null;
  }
  const filter = JSON.parse(json);
  return (filter?.length > 0 ? filter : null) as never as T[];
};

export const resetFilters = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('filter-')) {
      localStorage.removeItem(key);
    }
  });
};
