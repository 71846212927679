import { BaseRepository } from '@warthungshelden/domain/common';

import { Order } from './order';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrderRepository extends BaseRepository<Order> {
  getAllById(objectIds: string[]): Promise<Order[]>;
  getAllOpenOn(date: Date): Promise<Order[]>;
  getAllOpenBetween(from: Date, to: Date): Promise<Order[]>;
  getAllOpenOnFor(installerId: string, date: Date): Promise<Order[]>;
  getAllOpenBetweenFor(
    installerId: string,
    from: Date,
    to: Date
  ): Promise<Order[]>;
}
