import React, { useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { FaRegCheckCircle, FaRegEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';

import {
  InstallerCandidateDto,
  InstallerDto,
} from '@wartungshelden/shared-types';

import { Button } from '../../../../../../components';
import LoadingSpinner from '../../../../../../components/Basics/Loaders/LoadingSpinner';
import { useInstallerRoute } from '../../../../../../services/api/ressource-planning/installers-api';
import { getInefficiencyAnnotationsFor } from '../../../efficiency-rules';
import { RouteDtoWithEfficencyRules } from '../../../types/RouteDtoWithEfficencyRules';
import GoogleMapsRouteButton from '../../GoogleMapsRouteButton';
import RouteActionsAddModal from './RouteActionsAddModal';
import RouteActionsEditModal from './RouteActionsEditModal';

interface RouteActionsProps {
  route: RouteDtoWithEfficencyRules;
  originalRoute: RouteDtoWithEfficencyRules;
  installers: InstallerDto[];
  mainInstallerCandidate: InstallerCandidateDto;
  mainInstaller: InstallerDto;
  setAlternativeRoute: (
    alternativeRoute: RouteDtoWithEfficencyRules | undefined
  ) => void;
  showDetails: boolean;
  setShowDetails: (showDetails: boolean) => void;
  isAccepted: boolean;
  setIsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

const RouteActions: React.FC<RouteActionsProps> = ({
  route,
  installers,
  mainInstaller,
  originalRoute,
  setAlternativeRoute,
  showDetails,
  setShowDetails,
  isAccepted,
  setIsAccepted,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const { mutateAsync: acceptRoute, isLoading: isLoadingAcceptRoute } =
    useInstallerRoute();

  return (
    <div className="flex">
      <div className="flex flex-col justify-between">
        <div className="space-y-2">
          <Button
            disabled={isAccepted}
            icon={<BsInfoCircle className={`${isAccepted && 'opacity-50'}`} />}
            className="small-button full-button outline-button !justify-start"
            onClick={() => setShowDetails(!showDetails)}
            label={showDetails ? 'Details ausblenden' : 'Details einblenden'}
          />
          <GoogleMapsRouteButton route={route} installer={mainInstaller} />
          <Button
            disabled={isAccepted}
            icon={<FaRegEdit className={`${isAccepted && 'opacity-50'}`} />}
            className="small-button full-button outline-button !justify-start"
            onClick={async () => setShowEditModal(true)}
            label="Tour bearbeiten"
          />
        </div>
        <Button
          disabled={isAccepted}
          icon={
            <FaRegCheckCircle className={`${isAccepted && 'opacity-50'}`} />
          }
          className="small-button full-button primary-button self-end !justify-start"
          onClick={async () => setShowAddModal(true)}
          label={`${isAccepted ? 'Tour akzeptiert' : 'Tour akzeptieren'}`}
        />
      </div>

      <RouteActionsEditModal
        route={route}
        originalRoute={originalRoute}
        mainInstaller={mainInstaller}
        installers={installers}
        showModal={showEditModal}
        onCancel={() => {
          setShowEditModal(false);
        }}
        onSave={(changedRoute) => {
          setAlternativeRoute({
            ...changedRoute,
            efficiencyRules: getInefficiencyAnnotationsFor(changedRoute),
          });

          setShowEditModal(false);
        }}
      />

      <RouteActionsAddModal
        route={route}
        showModal={showAddModal}
        onCancel={() => {
          setShowAddModal(false);
        }}
        onSave={async (jobs) => {
          const { efficiencyRules, ...routeDto } = route;
          try {
            await acceptRoute({
              route: { ...routeDto, jobs },
              id: mainInstaller.id,
            });
            setIsAccepted(true);
          } catch (e) {
            toast.error('Fehler beim Akzeptieren der Tour', {
              position: 'top-center',
            });
          }

          setShowAddModal(false);
        }}
      />
      <LoadingSpinner isLoading={isLoadingAcceptRoute}>
        Akzeptiere die Route...
      </LoadingSpinner>
    </div>
  );
};

export default RouteActions;
