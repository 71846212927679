import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import {
  MaintenanceOfferRepository,
  MaintenanceOfferStatusEnum,
} from '../../maintenance-offer';
import {
  MaintenanceRequestStatus,
  RequestStatusEnum,
} from '../../maintenance-request';

export class MustBeInStatus<
  T extends { id: string; status: MaintenanceRequestStatus }
> extends DomainRule<T, T> {
  private readonly status: RequestStatusEnum[];

  constructor(
    private user: User,
    private maintenanceOfferRepository: MaintenanceOfferRepository,
    ...status: RequestStatusEnum[]
  ) {
    super();
    this.status = status;
  }

  protected async rule(entity: T) {
    const offers = await this.maintenanceOfferRepository.getAllByRequestId(
      entity.id
    );

    const currentOffer = offers[0];

    if (
      !this.status.some(
        (requestStatus) =>
          requestStatus === entity.status &&
          (!currentOffer ||
            currentOffer?.decision.status === MaintenanceOfferStatusEnum.DRAFT)
      )
    ) {
      throw new DomainRuleNotSatisfiedError(MustBeInStatus);
    }
    return entity;
  }
}
