import { useFormikContext } from 'formik';
import React, { PropsWithChildren, useContext } from 'react';

import PriceCalculationContext from '../../PriceCalculationContext';
import {
  PriceInformationCombination,
  toCombinations,
  toPriceInformationObject,
  toPriceInformationObjects,
} from '../../utils/combinations';
import { toGroupedCombinations } from '../../utils/grouping';
import {
  toPriceCombination,
  toPricedCombination,
} from '../../utils/priced-combination';
import {
  PriceCalculationState,
  deleteCalculationObjects,
} from '../../utils/utils';
import PriceOverviewRow from './PriceOverviewRow';
import TotalPriceOverviewRow from './TotalPriceOverviewRow';

interface Props {
  onSaveNewItems: (newObjectsState: PriceCalculationState) => Promise<void>;
}

const PriceOverviewRows: React.FC<PropsWithChildren<Props>> = ({
  onSaveNewItems,
}) => {
  const {
    priceWithDocu,
    priceWithoutDocu,
    setIsUpdating,
    setSelectedPriceInformationObject,
    setIsPriceCalculationMaintenanceObjectModalVisible,
  } = useContext(PriceCalculationContext);
  const { values } = useFormikContext<PriceCalculationState>();

  if (
    !priceWithoutDocu ||
    !priceWithDocu ||
    !priceWithoutDocu.netTotal ||
    !priceWithDocu.netTotal ||
    !priceWithoutDocu.objectPrices ||
    !priceWithDocu.objectPrices ||
    values.objectInformation.length !== priceWithDocu.objectPrices.length ||
    values.objectInformation.length !== priceWithoutDocu.objectPrices.length
  ) {
    return null;
  }

  const combinations = toCombinations(
    values.combinations,
    values.objectInformation
  );

  const groupedCombinations = combinations.reduce(toGroupedCombinations, []);

  const objectPricesWithoutDocu = priceWithoutDocu.objectPrices;
  const objectPricesWithDocu = priceWithDocu.objectPrices;

  const groupedPricedCombinations = groupedCombinations.map((group) =>
    group.map((combination) =>
      toPricedCombination(
        combination,
        objectPricesWithDocu,
        objectPricesWithoutDocu
      )
    )
  );

  const onOpenEditModal = (groupToEdit: PriceInformationCombination[]) => {
    if (groupToEdit.length > 1) return;
    setIsUpdating(true);
    setSelectedPriceInformationObject(groupToEdit[0]);
    setIsPriceCalculationMaintenanceObjectModalVisible(true);
  };

  const showPriceWithoutDocu =
    priceWithDocu.netTotal !== priceWithoutDocu.netTotal;

  return (
    <div className="pb-12">
      {groupedPricedCombinations?.map((priceCombinationGroup, index) => {
        return (
          <div
            key={priceCombinationGroup[0].primarySystem.combination.object.id}
            className="mt-8"
            data-cy={`price-overview-row-${index}`}
          >
            <PriceOverviewRow
              pricedCombinations={priceCombinationGroup}
              onOpenEditModal={(objectsToEdit) => {
                onOpenEditModal(objectsToEdit.map(toPriceCombination));
              }}
              onDelete={async (combinationsToDelete) => {
                const objectsToDelete = combinationsToDelete.map(
                  (combiToDelete) =>
                    toPriceInformationObjects(toPriceCombination(combiToDelete))
                );
                const newObjectsState = await deleteCalculationObjects(
                  objectsToDelete.flat(),
                  values
                );

                await onSaveNewItems(newObjectsState);
              }}
              showPriceWithoutDocu={showPriceWithoutDocu}
            />
          </div>
        );
      })}

      <div className="mt-8" data-cy="total-price-overview-row">
        <TotalPriceOverviewRow
          priceWithoutDocu={priceWithoutDocu}
          priceWithDocu={priceWithDocu}
          showPriceWithoutDocu={showPriceWithoutDocu}
        />
      </div>
    </div>
  );
};

export default PriceOverviewRows;
