import { Entity } from '@warthungshelden/domain/common';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GeoCoordinate } from '@warthungshelden/domain/maintenance-administration';

export class Address extends Entity {
  public readonly addressLine1?: string | null;
  public readonly addressLine2?: string | null;
  public readonly postalCode?: string | null;
  public readonly city?: string | null;
  public readonly countryISO?: string | null;
  public readonly coordinates?: GeoCoordinate | null;

  constructor(config: {
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    countryISO?: string | null;
    coordinates?: GeoCoordinate | null;
  }) {
    super();
    this.addressLine1 = config.addressLine1;
    this.addressLine2 = config.addressLine2;
    this.postalCode = config.postalCode;
    this.city = config.city;
    this.countryISO = config.countryISO;
    this.coordinates = config.coordinates;
  }
}
