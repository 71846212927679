import { DomainEventDispatcher } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import { MaintenanceOffer } from '../maintenance-offer';
import {
  MaintenanceOfferDecisionSet,
  MaintenanceOfferDocumentUploadClosed,
} from '../maintenance-offer';
import { MaintenanceRequest } from '../maintenance-request';
import { MaintenanceRequestCreated } from '../maintenance-request';

export abstract class MaintenanceTeamNotificationService {
  constructor(private eventDispatcher: DomainEventDispatcher) {
    this.eventDispatcher.register(
      MaintenanceRequestCreated,
      async ({ user, maintenanceRequest }) => {
        await this.notifyAboutNewMaintenanceRequest(user, maintenanceRequest);
      }
    );

    this.eventDispatcher.register(
      MaintenanceOfferDecisionSet,
      async ({ user, maintenanceOffer }) => {
        await this.notifyAboutOfferDecision(user, maintenanceOffer);
      }
    );

    this.eventDispatcher.register(
      MaintenanceOfferDocumentUploadClosed,
      async ({ user, maintenanceOffer, openedDocumentUploads }) => {
        await this.notifyAboutDocumentUploadOpened(
          user,
          maintenanceOffer,
          openedDocumentUploads
        );
      }
    );
  }

  public abstract notifyAboutNewMaintenanceRequest(
    user: User,
    request: MaintenanceRequest
  ): Promise<void>;

  public abstract notifyAboutOfferDecision(
    user: User,
    offer: MaintenanceOffer
  ): Promise<void>;

  public abstract notifyAboutOfferDecision(
    user: User,
    offer: MaintenanceOffer
  ): Promise<void>;

  public abstract notifyAboutDocumentUploadOpened(
    user: User,
    offer: MaintenanceOffer,
    objects: string[]
  ): Promise<void>;
}
