import React from 'react';

import UnitInputField from '../../../../../components/Basics/Inputs/UnitInputField';

const RailingSelector = () => {
  return (
    <div className="space-y-4 px-8">
      <UnitInputField
        label="Länge*"
        name="object.type.length"
        unit="Meter"
        shouldSelectOnFocus
        allowDecimals
      />
    </div>
  );
};

export default RailingSelector;
