import { Formik } from 'formik';
import React from 'react';

import { CustomerOrMaintenanceTeamOfferDto } from '@wartungshelden/shared-types';

import DateTimeSelectField from '../../../components/Basics/Inputs/DateSelection/DateTimeSelectField';

interface ExecutionDatePickerProps {
  onChange: (date: Date | null) => unknown;
  offer: CustomerOrMaintenanceTeamOfferDto;
}

const ExecutionDatePicker: React.FC<
  React.PropsWithChildren<ExecutionDatePickerProps>
> = ({ onChange, offer }) => {
  return (
    <div className="flex items-top">
      <p className="mr-4">Ausführungstermin</p>
      <Formik
        initialValues={{
          suggestedDate: offer.suggestedDate && new Date(offer.suggestedDate),
        }}
        enableReinitialize
        onSubmit={({ suggestedDate }) => {
          if (suggestedDate !== undefined) {
            onChange(suggestedDate);
          }
        }}
      >
        <div>
          <DateTimeSelectField name="suggestedDate" submitOnChange />
        </div>
      </Formik>
    </div>
  );
};

export default ExecutionDatePicker;
