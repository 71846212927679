import React from 'react';

import { CustomerOrMaintenanceTeamOfferDto } from '@wartungshelden/shared-types';

import TextWithLabel from '../../../components/Basics/TextWithLabel';
import { formatToHHMM } from '../../../services/timeDateService';

interface DurationeProps {
  offer?: CustomerOrMaintenanceTeamOfferDto | null;
}

const Duration: React.FC<React.PropsWithChildren<DurationeProps>> = ({
  offer,
}) => {
  const minutes =
    offer?.order?.durationInMinutes ?? offer?.durationInMinutes ?? null;

  const hasRoofToBeOpened = minutes !== null && minutes.roofOpening > 0;

  return (
    <>
      <TextWithLabel
        label={`Geschätzter Aufwand ${
          hasRoofToBeOpened ? 'ohne Dachöffnung' : ''
        }:`}
        labelStyle="font-normal"
      >
        {minutes !== null
          ? formatToHHMM(minutes.duration / 60)
          : 'nicht ermittelbar'}{' '}
        Stunden
      </TextWithLabel>
      {hasRoofToBeOpened && (
        <TextWithLabel
          label="Geschätzter Aufwand mit Dachöffnung:"
          labelStyle="font-normal"
        >
          {formatToHHMM((minutes.duration + minutes.roofOpening) / 60)} Stunden
        </TextWithLabel>
      )}
    </>
  );
};

Duration.defaultProps = {
  offer: undefined,
};

export default Duration;
