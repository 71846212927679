import React from 'react';

import {
  MaintenanceAdministrationBuildingDto,
  SelectLineMaintenanceOrderDto,
} from '@wartungshelden/shared-types';

import { InputField } from '../../../../components';
import InfoText from './InfoText';
import InformationContainer from './InformationContainer';

interface Props {
  selectLineData?: SelectLineMaintenanceOrderDto;
  building?: MaintenanceAdministrationBuildingDto;
}

const AppointmentInfo: React.FC<Props> = ({ selectLineData, building }) => {
  const hasDocuAddress = Boolean(building?.address?.address1);

  const street = hasDocuAddress
    ? building?.address?.address1
    : selectLineData?.building?.address?.street;

  const city = hasDocuAddress
    ? building?.address?.city
    : selectLineData?.building?.address?.city;

  const zip = hasDocuAddress
    ? building?.address?.postcode
    : selectLineData?.building?.address?.zip;

  return (
    <InformationContainer>
      <InfoText label="Bauvorhaben:" indicateMissingData labelStyle="font-bold">
        {selectLineData?.building?.name}
      </InfoText>

      <InfoText label="Seriennummer:" indicateMissingData>
        {selectLineData?.serialNumbers?.join('; ')}
      </InfoText>

      <div className="mt-4">
        <InfoText
          label={
            // eslint-disable-next-line no-nested-ternary
            hasDocuAddress
              ? `Adresse (Lock Book Docu):`
              : street
              ? 'Adresse (SelectLine):'
              : 'Adresse'
          }
        >
          <div>
            <div> {street}</div>
            <div>
              {zip} {city}
            </div>
          </div>
        </InfoText>

        <div className="flex flex-row space-x-2">
          <div className="max-w-7xl">
            <InputField
              name="access"
              label="Zugang:"
              labelStyle="!w-72 !font-normal"
            />
            <InputField
              name="reception"
              label="Anmeldung:"
              labelStyle="!w-72 !font-normal"
            />

            <div className="mt-4">
              <InputField
                name="buildingContact.name"
                label="Ansprechpartner vor Ort:"
                labelStyle="!w-72"
              />

              <InputField
                name="buildingContact.email"
                label="E-Mail:"
                labelStyle="!w-72 !font-normal"
              />

              <InputField
                name="buildingContact.phone"
                label="Telefon:"
                labelStyle="!w-72 !font-normal"
              />

              <InputField
                name="buildingContact.mobile"
                label="Mobil:"
                labelStyle="!w-72 !font-normal"
              />
            </div>
          </div>
        </div>
      </div>
    </InformationContainer>
  );
};

AppointmentInfo.defaultProps = {
  selectLineData: undefined,
  building: undefined,
};

export default AppointmentInfo;
