import React, { useContext, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom';

import {
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceDiscountDto,
  MaintenanceOfferStatusDto,
  MaintenanceOfferStatusDtoEnum,
  MaintenanceOrderStatusDtoEnum,
  MaintenancePriceAdaptationDto,
  RequestStatusEnum,
} from '@wartungshelden/shared-types';

import { Button } from '../../components';
import LoadingSpinner from '../../components/Basics/Loaders/LoadingSpinner';
import HintHighlight from '../../components/HintHighlight/HintHighlight';
// eslint-disable-next-line import/no-cycle
import MaintenanceRequestPriceOverviewTable from '../../components/Maintenance/MaintenanceTable/MaintenanceRequestPriceOverviewTable';
import {
  OFFER_NOT_UPLOADED_ERROR,
  ORDER_CONFIRMATION_NOT_UPLOADED_ERROR,
} from '../../contexts/HintHighlightContext';
import { SessionContext } from '../../contexts/SessionContext';
import {
  useMaintenanceOfferPrice,
  useUpdateMaintenanceOffer,
} from '../../services/api/maintenance-offers/maintenance-offer-api';
import { RequestDetailsOutlet } from './RequestMainPage';
import CustomerReference from './components/CustomerReference';
import DiscountModal from './components/DiscountModal';
import Duration from './components/Duration';
import ExecutionDate from './components/ExecutionDate';
import ExecutionDatePicker from './components/ExecutionDatePicker';
import ExportOrderDocumentModal from './components/ExportOrderDocumentModal/ExportOrderDocumentModal';
import InternalNoteModal from './components/InternalNoteModal';
import OfferDecision from './components/OfferDecision';
import OfferFileUpload from './components/OfferFileUpload';
import OfferOrOrderAddress from './components/OfferOrOrderAddress';
import OrderConfirmationFileUpload from './components/OrderConfirmationFileUpload';
import PriceAdaptionModal from './components/PriceAdaptionModal';
import RequestDateRanges from './components/RequestDateRanges';
// eslint-disable-next-line import/no-cycle
import RequestDetailsPageHeader from './components/RequestDetailsPageHeader';
import SentAndDecisionDate from './components/SentAndDecisionDate';

export const userShouldSeeOffer = (status?: MaintenanceOfferStatusDto) => {
  return (
    status &&
    new Array<MaintenanceOfferStatusDto>(
      MaintenanceOfferStatusDtoEnum.SENT,
      MaintenanceOfferStatusDtoEnum.ACCEPTED,
      MaintenanceOfferStatusDtoEnum.DECLINED
    ).includes(status)
  );
};

const RequestDetailsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    currentBuildings,
    currentRequest,
    currentRequestMaintenances,
    currentOffer,
  } = useOutletContext<RequestDetailsOutlet>();

  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const [isPriceAdaptionModalOpen, setIsPriceAdaptionModalOpen] =
    useState<boolean>(false);

  const [isPriceDiscountModalOpen, setIsPriceDiscountModalOpen] =
    useState<boolean>(false);

  const [isInternalNoteModalOpen, setIsInternalNoteModalOpen] =
    useState<boolean>(false);

  const [isExportOrderModalOpen, setIsExportOrderModalOpen] =
    useState<boolean>(false);

  const { mutateAsync: updateMaintenanceOfferSuggestedExecutionDate } =
    useUpdateMaintenanceOffer({
      successMessage: 'Ausführungstermin anpassen erfolgreich',
      errorMessage: 'Ausführungstermin anpassen erfolglos',
    });

  const { mutateAsync: updateMaintenanceOfferPriceAdaptation } =
    useUpdateMaintenanceOffer({
      successMessage: 'Preis anpassen erfolgreich',
      errorMessage: 'Preis anpassen fehlgeschlagen',
    });

  const { mutateAsync: updateMaintenanceOfferPriceDiscount } =
    useUpdateMaintenanceOffer({
      successMessage: 'Discount anpassen erfolgreich',
      errorMessage: 'Discount anpassen fehlgeschlagen',
    });

  const { data: currentOfferExcelPrice } = useMaintenanceOfferPrice(
    currentOffer?.id,
    'no-fix-price'
  );

  const onSavePriceAdoption = (offer: CustomerOrMaintenanceTeamOfferDto) => {
    return async (priceAdaptation: MaintenancePriceAdaptationDto | null) => {
      const price = offer.price
        ? {
            ...offer.price,
            priceAdaptation,
          }
        : { netTotal: null, priceAdaptation };

      await updateMaintenanceOfferPriceAdaptation({
        id: offer.id,
        price,
      });

      setIsPriceAdaptionModalOpen(false);
    };
  };

  const onSaveDiscount = (offer: CustomerOrMaintenanceTeamOfferDto) => {
    return async (discounts: MaintenanceDiscountDto[]) => {
      const price = offer.price
        ? {
            ...offer.price,
            discounts,
          }
        : { netTotal: null, discounts };

      await updateMaintenanceOfferPriceDiscount({
        id: offer.id,
        price,
      });

      setIsPriceDiscountModalOpen(false);
    };
  };

  const onSaveSuggestedExecutionDate = (
    offer: CustomerOrMaintenanceTeamOfferDto
  ) => {
    return async (suggestedDate: Date | null) =>
      updateMaintenanceOfferSuggestedExecutionDate({
        id: offer.id,
        suggestedDate,
      });
  };

  const canChangeOffer =
    isMaintenanceTeamMemberAdmin &&
    currentRequest?.status === RequestStatusEnum.VALIDATING &&
    currentOffer?.decision?.status === MaintenanceOfferStatusDtoEnum.DRAFT;

  const canChangeOrder =
    isMaintenanceTeamMemberAdmin &&
    currentOffer?.decision?.status === MaintenanceOfferStatusDtoEnum.ACCEPTED &&
    (!currentOffer?.order ||
      currentOffer?.order?.status ===
        MaintenanceOrderStatusDtoEnum.UNCONFIRMED);

  const maintenanceTeamSeesOffer =
    isMaintenanceTeamMemberAdmin &&
    currentRequest?.status !== RequestStatusEnum.REQUESTED;

  const userSeesOffer =
    currentOffer && userShouldSeeOffer(currentOffer.decision?.status);

  const userSeesOfferDocument = Boolean(
    !isMaintenanceTeamMemberAdmin && currentOffer?.documentName && userSeesOffer
  );

  const shouldShowOfferDocument =
    userSeesOfferDocument || maintenanceTeamSeesOffer || canChangeOffer;

  const userSeesOrderDocument =
    !isMaintenanceTeamMemberAdmin &&
    (currentOffer?.order?.status === MaintenanceOrderStatusDtoEnum.CONFIRMED ||
      currentOffer?.order?.status === MaintenanceOrderStatusDtoEnum.CLOSED);

  const maintenanceTeamSeesOrder = Boolean(
    isMaintenanceTeamMemberAdmin &&
      currentOffer?.decision?.status === MaintenanceOfferStatusDtoEnum.ACCEPTED
  );

  const shouldShowOrderConfirmationDocument =
    userSeesOrderDocument || maintenanceTeamSeesOrder || canChangeOrder;

  const shouldShowExecutionDate =
    !isMaintenanceTeamMemberAdmin && userSeesOffer;

  const shouldShowExecutionDatePicker =
    isMaintenanceTeamMemberAdmin &&
    currentRequest?.status !== RequestStatusEnum.REQUESTED &&
    currentOffer;

  const addressesNotEqual = () => {
    if (!currentOffer || !currentOffer.order) return null;

    const { address: offerAddress } = currentOffer;
    const { address: orderAddress } = currentOffer.order;
    const { addressLine2, email, ...relevantOfferAddress } = offerAddress;

    return (
      JSON.stringify(relevantOfferAddress) !== JSON.stringify(orderAddress)
    );
  };

  const shouldShowPriceIndication = () =>
    isMaintenanceTeamMemberAdmin || shouldShowOfferDocument;

  return (
    <div className="h-full w-full px-8 pt-6 overflow-hidden bg-gray-lighter overflow-y-scroll">
      {currentRequest && (
        <>
          <RequestDetailsPageHeader
            isMaintenanceTeamMemberAdmin={isMaintenanceTeamMemberAdmin}
            request={currentRequest}
            offer={currentOffer}
          />

          <SentAndDecisionDate
            isMaintenanceTeamMemberAdmin={isMaintenanceTeamMemberAdmin}
            requestOrOffer={
              currentOffer && userSeesOffer ? currentOffer : currentRequest
            }
          />

          <CustomerReference offer={currentOffer} />

          {isMaintenanceTeamMemberAdmin && currentOffer && (
            <div className="flex w-full mt-4">
              <div className="w-1/2">
                <div className="font-bold text-xl mb-2">
                  {addressesNotEqual() || !currentOffer.order
                    ? 'Angebotsadresse'
                    : 'Angebots- und Rechnungsadresse'}
                </div>
                <OfferOrOrderAddress
                  customerId={currentRequest?.ownerId}
                  offerOrOrderAddress={{
                    ...currentOffer.address,
                    firstName: currentOffer.address.firstName ?? '',
                    lastName: currentOffer.address.lastName ?? '',
                    email: currentOffer.address.email ?? '',
                  }}
                  absCustomerNumber={currentOffer.absCustomerNumber}
                />
              </div>
              <div className="w-1/2">
                {currentOffer.order && addressesNotEqual() && (
                  <>
                    <div className="font-bold text-xl mb-2">
                      Rechnungsadresse
                    </div>
                    <OfferOrOrderAddress
                      customerId={currentRequest?.ownerId}
                      offerOrOrderAddress={{
                        ...currentOffer.order.address,
                        firstName: currentOffer.order.address.firstName ?? '',
                        lastName: currentOffer.order.address.lastName ?? '',
                      }}
                      showContactData={false}
                    />
                  </>
                )}
              </div>
            </div>
          )}

          {shouldShowPriceIndication() && (
            <div className="mt-8">
              <p className="mb-3">
                <strong>{userSeesOffer ? 'Angebotspreis' : 'Anfrage'}</strong>
              </p>
              {currentRequestMaintenances && currentOffer?.price ? (
                <MaintenanceRequestPriceOverviewTable
                  buildings={currentBuildings}
                  maintenances={currentRequestMaintenances}
                  offerStatus={currentOffer?.decision?.status}
                  price={currentOffer.price}
                  excelPrice={
                    isMaintenanceTeamMemberAdmin
                      ? currentOfferExcelPrice
                      : undefined
                  }
                />
              ) : (
                <LoadingSpinner isLoading />
              )}
            </div>
          )}
          {canChangeOffer && (
            <div className="flex flex-1 flex-row justify-end mt-4 space-x-2">
              <Button onClick={() => setIsPriceDiscountModalOpen(true)}>
                Rabatt anpassen
              </Button>

              <Button onClick={() => setIsPriceAdaptionModalOpen(true)}>
                Preis anpassen
              </Button>
            </div>
          )}

          {shouldShowOfferDocument && (
            <div className="flex flex-1 flex-row mt-8">
              <div className="flex flex-1 flex-col">
                <p className="mb-3">
                  <strong data-cy="offer-head">Angebot</strong>
                </p>
                <div className="flex">
                  <div className="mr-4">Angebotsdokument</div>
                  <div className="flex flex-col">
                    <div className="w-fit">
                      <HintHighlight
                        highlightIds={[
                          `${OFFER_NOT_UPLOADED_ERROR}${currentRequest.id}`,
                        ]}
                      >
                        <OfferFileUpload
                          offer={currentOffer}
                          deleteDisabled={!canChangeOffer}
                        />
                      </HintHighlight>
                    </div>

                    {currentOffer && (
                      <div className="mt-2">
                        <OfferDecision
                          isMaintenanceTeamMemberAdmin={
                            isMaintenanceTeamMemberAdmin
                          }
                          offer={currentOffer}
                          customer={currentRequest.ownerId}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {shouldShowOrderConfirmationDocument && (
            <div className="flex flex-1 flex-row mt-8">
              <div className="flex flex-1 flex-col">
                <p className="mb-3">
                  <strong>Auftrag</strong>
                </p>
                <div className="flex">
                  <div className="mr-4">Auftragsbestätigung</div>
                  <div className="flex flex-col">
                    <HintHighlight
                      highlightIds={[
                        `${ORDER_CONFIRMATION_NOT_UPLOADED_ERROR}${currentRequest.id}`,
                      ]}
                    >
                      <OrderConfirmationFileUpload
                        offer={currentOffer}
                        deleteDisabled={!canChangeOrder}
                      />
                    </HintHighlight>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isMaintenanceTeamMemberAdmin && (
            <div className="flex flex-1 flex-col mt-8">
              <p className="mb-3">
                <strong className="flex items-center">Auftragsdaten</strong>
              </p>
              <Button onClick={() => setIsExportOrderModalOpen(true)}>
                Exportieren
              </Button>
            </div>
          )}

          <div className="flex flex-1 flex-col mt-8">
            <p className="mb-3">
              <strong>Termin</strong>
            </p>

            {currentRequestMaintenances && (
              <RequestDateRanges
                request={currentRequest}
                maintenances={currentRequestMaintenances}
              />
            )}

            <div className="flex mt-4">
              <p className="mb-3 mr-4">Hinweis für Terminauswahl:</p>
              <div>
                {currentRequest.additionalInfo?.length
                  ? currentRequest.additionalInfo
                  : '- keine -'}
              </div>
            </div>

            {shouldShowExecutionDate && <ExecutionDate offer={currentOffer} />}

            {shouldShowExecutionDatePicker && (
              <ExecutionDatePicker
                offer={currentOffer}
                onChange={onSaveSuggestedExecutionDate(currentOffer)}
              />
            )}

            {isMaintenanceTeamMemberAdmin && <Duration offer={currentOffer} />}
          </div>

          {isMaintenanceTeamMemberAdmin &&
            'maintenanceTeamInternalNote' in currentRequest && (
              <div className="flex flex-1 flex-col mt-8">
                <div className="mb-3">
                  <strong className="flex items-center">
                    Interne Notiz
                    <FaLock className="ml-2" />
                  </strong>
                  <div>
                    <div className="py-2">
                      {currentRequest.maintenanceTeamInternalNote}
                    </div>
                    <Button onClick={() => setIsInternalNoteModalOpen(true)}>
                      Notiz anpassen
                    </Button>
                  </div>
                </div>
              </div>
            )}

          <InternalNoteModal
            request={currentRequest}
            isOpen={isInternalNoteModalOpen}
            onClose={() => setIsInternalNoteModalOpen(false)}
          />

          {currentOffer && (
            <ExportOrderDocumentModal
              offer={currentOffer}
              isOpen={isExportOrderModalOpen}
              onClose={() => setIsExportOrderModalOpen(false)}
              buildings={currentBuildings ?? []}
            />
          )}

          {currentOffer && (
            <PriceAdaptionModal
              isOpen={isPriceAdaptionModalOpen}
              onClose={() => setIsPriceAdaptionModalOpen(false)}
              offer={currentOffer}
              onPriceAdaptionChange={onSavePriceAdoption(currentOffer)}
            />
          )}
          {currentOffer && (
            <DiscountModal
              isOpen={isPriceDiscountModalOpen}
              onClose={() => setIsPriceDiscountModalOpen(false)}
              offer={currentOffer}
              onDiscountChange={onSaveDiscount(currentOffer)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RequestDetailsPage;
