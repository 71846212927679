import { Field, useFormikContext } from 'formik';
import React from 'react';
import { MdModeEdit } from 'react-icons/md';

type SelectFieldProps = {
  label: string;
  placeHolder?: any;
  icon?: any;
  name: string;
  representationKey?: string;
  alternativeDisplayText?: string;
  onClick: () => void;
  disabled?: boolean;
};

const SelectField: React.FC<React.PropsWithChildren<SelectFieldProps>> = ({
  label,
  placeHolder,
  icon,
  name,
  representationKey,
  alternativeDisplayText,
  onClick,
  disabled,
}) => {
  const hasValue = (field: any) => {
    if (representationKey) {
      return (
        field.value[representationKey] && field.value[representationKey] !== ''
      );
    }
    return field.value && field.value !== '';
  };

  const { status } = useFormikContext();

  return (
    <div className="flex w-full items-center mb-2">
      <div
        className={`flex font-bold w-64 mr-4 ${
          status && Object.keys(status).includes(name) ? 'text-red' : ''
        }`}
      >
        {label}
      </div>
      <button
        data-cy="components-basics-select-field"
        onClick={onClick}
        type="button"
        className={`w-full flex h-12 ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
      >
        <Field disabled id={name} name={name} placeholder={label} type="text">
          {({ field }: any) => (
            <div className="flex mb-2 h-12 items-center w-full">
              {hasValue(field) ? (
                <div className="flex flex-row items-center my-2 w-full">
                  <div
                    className={`${
                      disabled
                        ? 'bg-gray-lighter text-gray-dark'
                        : 'bg-gray-light'
                    }  flex px-4 py-2 w-full mr-2`}
                  >
                    {representationKey
                      ? alternativeDisplayText || field.value[representationKey]
                      : alternativeDisplayText || field.value}
                  </div>
                  <div
                    className={`flex h-10 items-center justify-center ${
                      disabled ? 'bg-gray-light' : 'bg-blue-wartung'
                    }  rounded w-16`}
                  >
                    <MdModeEdit className="text-white" />
                  </div>
                </div>
              ) : (
                <div className="flex w-full h-12 items-center justify-center text-white flex-row bg-blue-wartung rounded">
                  {icon || <MdModeEdit />}
                  <span className="ml-2">{placeHolder}</span>
                </div>
              )}
            </div>
          )}
        </Field>
      </button>
    </div>
  );
};
export default SelectField;

SelectField.defaultProps = {
  icon: null,
  placeHolder: null,
  representationKey: undefined,
  alternativeDisplayText: undefined,
  disabled: false,
};
