import { useFormikContext } from 'formik';
import React from 'react';

import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { useBuildings } from '../../../services/api/buildings/buildings-api';
import { InfoMessageBox } from '../../Basics/MessageBox';
import TextWithLabel from '../../Basics/TextWithLabel';

const MaintenanceObjectReadOnly: React.FC<React.PropsWithChildren> = () => {
  const { values } = useFormikContext<FormikMaintenanceWizardValues>();

  const maintenanceObject = values;

  if (!maintenanceObject) return null;

  const { data: buildings } = useBuildings();
  const currentBuilding = buildings?.find(
    (building) => building.id === values.buildingId
  );

  return (
    <div className="gap-x-2 p-2 group">
      <TextWithLabel label="Gebäude:">{currentBuilding?.name}</TextWithLabel>
      <TextWithLabel label="Wartungstyp:">
        {maintenanceObject?.type}
      </TextWithLabel>
      <TextWithLabel label="Häufigkeit:">
        {maintenanceObject?.frequency}
      </TextWithLabel>
      <TextWithLabel label="Nächste Fälligkeit:">
        {maintenanceObject?.dueDate}
      </TextWithLabel>
    </div>
  );
};

export default MaintenanceObjectReadOnly;
