import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceDocumentation } from '../../maintenance-documentation';

export class ObjectCanOnlyBeInOneValidityState extends DomainRule<
  MaintenanceDocumentation,
  MaintenanceDocumentation
> {
  constructor(
    private newMaintenanceDocumentation: Pick<MaintenanceDocumentation, 'id'> &
      Partial<MaintenanceDocumentation>
  ) {
    super();
  }

  protected async rule(documentation: MaintenanceDocumentation) {
    const validForToCheck =
      this.newMaintenanceDocumentation.validFor ?? documentation.validFor;

    const invalidForToCheck =
      this.newMaintenanceDocumentation.invalidFor ?? documentation.invalidFor;

    const notRelevantForToCheck =
      this.newMaintenanceDocumentation.notRelevantFor ??
      documentation.notRelevantFor;

    const ids = [
      ...validForToCheck,
      ...invalidForToCheck,
      ...notRelevantForToCheck,
    ];

    const uniqueIds = new Set(ids);

    if (ids.length !== uniqueIds.size) {
      throw new DomainRuleNotSatisfiedError(ObjectCanOnlyBeInOneValidityState);
    }

    return documentation;
  }
}
