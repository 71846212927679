import React, { useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';

import Loader from '../../../Basics/Loaders/Loader';
import DeleteFile from './DeleteFile';

type FilePreviewProps = {
  disabled?: boolean;
  file?: File | null;
  deleteFile?: (file: File) => void;
  onClick?: () => void;
};

const FilePreview: React.FC<React.PropsWithChildren<FilePreviewProps>> = ({
  disabled,
  file,
  deleteFile,
  onClick,
}) => {
  const [isFullFilenameShown, setIsFullFilenameShown] = useState(false);

  return (
    <div
      className="bg-gray text-gray-abs col-span-1 flex flex-col h-full w-full aspect-square justify-center
        items-center relative text-xl"
      onMouseEnter={() => setIsFullFilenameShown(true)}
      onMouseLeave={() => setIsFullFilenameShown(false)}
    >
      {file ? (
        <>
          {deleteFile && !disabled && (
            <DeleteFile
              file={file}
              disabled={!!disabled}
              deleteFile={deleteFile}
            />
          )}

          <div
            role="button"
            tabIndex={0}
            className={`text-xl h-5/6 w-full flex text-8xl hover:bg-gray ${
              onClick ? 'cursor-pointer' : ''
            }`}
            onKeyDown={onClick}
            onClick={onClick}
          >
            {file.type === 'application/pdf' ? (
              <div>
                {onClick ? (
                  <FaFilePdf className="self-center m-auto w-1/2" />
                ) : (
                  <a
                    href={file && URL.createObjectURL(file)}
                    target="_blank"
                    className="w-full h-full flex"
                    rel="noreferrer"
                  >
                    <FaFilePdf className="self-center m-auto w-1/2" />
                  </a>
                )}
              </div>
            ) : (
              <div>
                {onClick ? (
                  <img
                    src={file && URL.createObjectURL(file)}
                    alt={`bild${file}`}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <a
                    href={file && URL.createObjectURL(file)}
                    target="_blank"
                    className="w-full h-full flex"
                    rel="noreferrer"
                  >
                    <img
                      src={file && URL.createObjectURL(file)}
                      alt={`bild${file}`}
                      className="object-cover w-full h-full"
                    />
                  </a>
                )}
              </div>
            )}
          </div>
          <div
            className={`${
              isFullFilenameShown ? 'min-h-[20%]' : 'h-[20%]'
            } overflow-hidden flex items-center justify-center w-full`}
          >
            <p
              className={`w-full text-base leading-none tracking-tight text-center ${
                isFullFilenameShown ? 'break-all' : 'whitespace-nowrap'
              }`}
            >
              {file.name.length > 16 && !isFullFilenameShown
                ? `${file.name.substring(0, 13)}...`
                : file.name}
            </p>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

FilePreview.defaultProps = {
  disabled: false,
  deleteFile: undefined,
  onClick: undefined,
  file: undefined,
};

export default FilePreview;
