import { isDefined } from '@warthungshelden/shared-functions';

import { RouteDto } from '@wartungshelden/shared-types';

export type RouteEfficiencyRule = (route: RouteDto) => string | null;

function onlyDrivesLessThan(maximumMinutes: number): RouteEfficiencyRule {
  return (route) => {
    const onlyLessThanXMinutes = route.jobDistances
      .slice(1)
      .map(({ minutes }) => minutes)
      .filter(isDefined)
      .every((minutes) => minutes < maximumMinutes);

    return onlyLessThanXMinutes
      ? null
      : `Mindestens eine Fahrzeit übersteigt ${maximumMinutes} Minuten.`;
  };
}

function checkMinimumWorkHours(
  minimumWorkHours: number,
  totalWorkTime: number
) {
  return totalWorkTime < minimumWorkHours
    ? `Die Tour enthält weniger als ${minimumWorkHours} Stunden Arbeitszeit.`
    : null;
}

function checkMaximumWorkHours(
  maximumWorkHours: number,
  totalWorkTime: number
) {
  return totalWorkTime > maximumWorkHours
    ? `Die Arbeitszeit der Tour übersteigt  ${maximumWorkHours} Stunden.`
    : null;
}

function totalWorkTimeIsLessOrGreaterThan(workHourLimit: {
  minimumWorkHours?: number;
  maximumWorkHours?: number;
}): RouteEfficiencyRule {
  const { minimumWorkHours, maximumWorkHours } = workHourLimit;
  return (route) => {
    const { jobs } = route;
    const totalWorkTime = jobs.reduce((prev, curr) => {
      return prev + curr.duration;
    }, 0);

    if (minimumWorkHours) {
      return checkMinimumWorkHours(minimumWorkHours, totalWorkTime);
    }

    if (maximumWorkHours) {
      return checkMaximumWorkHours(maximumWorkHours, totalWorkTime);
    }

    return null;
  };
}

function distanceToFirstJobIsGreaterOrEqualThan(
  maximumDistanceKilometerToFirstJob: number
): RouteEfficiencyRule {
  return (route) => {
    const distanceToFirstJob = route.jobDistances[0].kilometers;

    if (!distanceToFirstJob) return null;

    return distanceToFirstJob >= maximumDistanceKilometerToFirstJob
      ? `Die Entfernung zum ersten Tourenpunkt übersteigt ${maximumDistanceKilometerToFirstJob} km.`
      : null;
  };
}

export const RouteEfficiencyRules: RouteEfficiencyRule[] = [
  onlyDrivesLessThan(60),
  totalWorkTimeIsLessOrGreaterThan({ minimumWorkHours: 4 }),
  totalWorkTimeIsLessOrGreaterThan({ maximumWorkHours: 8 }),
  distanceToFirstJobIsGreaterOrEqualThan(200),
];

export function getInefficiencyAnnotationsFor(route: RouteDto) {
  return RouteEfficiencyRules.map((rule) => rule(route)).filter(isDefined);
}
