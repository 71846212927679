import React from 'react';

import { BuildingDto } from '@wartungshelden/shared-types';

import { InputField } from '../../../../components';
import CheckBoxField from '../../../../components/Basics/Inputs/CheckBox/CheckBoxField';
import CheckBoxFieldWithDescription from '../../../../components/Basics/Inputs/CheckBox/CheckBoxFieldWithDescription';
import TextareaRow from '../../../../components/Basics/Inputs/TextareaRow';

interface BuildingFormProps {
  building?: BuildingDto;
  index: number;
}
const BuildingForm: React.FC<React.PropsWithChildren<BuildingFormProps>> = ({
  building,
  index,
}) => {
  const prefix = `buildings.${index}`;

  return (
    <>
      <div className="border-t-[1px] border-black-abs text-center mb-2 font-bold">
        {building && `Angaben zu ${building.name}`}
      </div>
      <TextareaRow
        name={`${prefix}.addressNotes`}
        rows={1}
        placeholder="Bemerkungen zur Adresse hinzufügen"
        label="Bemerkungen zur Adresse"
        displayInSingleRow
      />
      <InputField name={`${prefix}.serials`} label="Seriennummer" />
      <CheckBoxFieldWithDescription
        label="ja"
        name={`${prefix}.isInstalledByABS`}
        description="Montage durch ABS?"
      />
      <CheckBoxFieldWithDescription
        label="vorhanden"
        name={`${prefix}.hasLockBockDocumentation`}
        description="LockBook Docu?"
      />
      <CheckBoxFieldWithDescription description="frühere Prüfungsmaßnahmen">
        <div className="flex flex-col justify-between">
          <CheckBoxField
            name={`${prefix}.pastAuditMeasures.hasTensileTest`}
            label="Zugversuch"
          />
          <CheckBoxField
            name={`${prefix}.pastAuditMeasures.hasRoofOpening`}
            label="Dachöffnung"
          />
        </div>
      </CheckBoxFieldWithDescription>
      <TextareaRow
        name={`${prefix}.workNotes`}
        rows={5}
        placeholder="Bemerkungen zur Wartungsaufgabe."
        label="Bemerkungen zur Wartungsaufgabe"
        displayInSingleRow
      />
    </>
  );
};

BuildingForm.defaultProps = {
  building: undefined,
};

export default BuildingForm;
