import React from 'react';

interface PrimaryButtonProps {
  label: string;
  icon: React.ReactNode;
  isEnabled?: boolean;
  onClick: () => void;
}

const AuthOptionButton: React.FC<
  React.PropsWithChildren<PrimaryButtonProps>
> = ({ label, icon, isEnabled, onClick }) => (
  <button
    type="button"
    disabled={!isEnabled}
    className={`transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset ${
      !isEnabled && 'opacity-50'
    }`}
    onClick={() => isEnabled && onClick()}
  >
    <div className="flex px-4 items-center justify-center text-sm">
      {icon}
      <div className="ml-2 text-sm">{label}</div>
    </div>
  </button>
);

AuthOptionButton.defaultProps = {
  isEnabled: true,
};

export default AuthOptionButton;
