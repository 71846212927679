import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import {
  FaArrowAltCircleRight,
  FaCheck,
  FaQuestionCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { isServiceAlreadySupported } from '../../../constants/MaintenanceConstants';
import { maintenanceObjectsInRequestValidationSchema } from '../../../constants/MaintenanceYupSchema';
import { routes } from '../../../constants/routes';
import { SessionContext } from '../../../contexts/SessionContext';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { labelToProvidedService } from '../../../pages/UserPages/maintenance/MaintenanceObject';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import {
  useOwnCustomer,
  useUpdateCustomer,
} from '../../../services/api/customer/customer-api';
import { useMaintenanceObjects } from '../../../services/api/maintenance-objects/maintenance-objects-api';
import Button from '../../Basics/Buttons/Button';
import CheckBox from '../../Basics/Inputs/CheckBox/CheckBox';
import HintModal from '../../Basics/Modals/HintModal/HintModal';
import { WizardFooter } from '../../index';

interface MaintenanceWizardFooterProps {
  activeStep: number;
  setActiveStep: (value: number | ((prevValue: number) => number)) => void;
}

const MaintenanceWizardFooter: React.FC<
  React.PropsWithChildren<MaintenanceWizardFooterProps>
> = ({ activeStep, setActiveStep }) => {
  const navigate = useNavigate();
  const { maintenanceObjectId } = useParams();
  const { data: customer } = useOwnCustomer();
  const { mutateAsync: updateCustomer } = useUpdateCustomer();
  const { userInfo } = useContext(SessionContext);

  const readShouldShowDocuHint = () => {
    const savedValue = window.localStorage.getItem(
      'shouldBeShowingDocumentationHint'
    );
    return Boolean(savedValue === null || savedValue === 'true');
  };

  const [stopShowingDocuHint, setStopShowingDocuHint] =
    useState<boolean>(false);

  const saveShouldShowDocuHint = (show: boolean) => {
    window.localStorage.setItem(
      'shouldBeShowingDocumentationHint',
      String(show)
    );
  };

  const [isDocuMissingModalOpen, setIsDocuMissingModalOpen] =
    useState<boolean>(false);

  const doesNotHaveDocumentationButShould = (values) =>
    values.type === 'Absturzsicherung' &&
    values.maintenanceObjects?.every(
      (maintenanceObject) => maintenanceObject?.selectedFiles?.length === 0
    );

  const { setIsNotificationOpen, setShowNotificationForm } =
    useContext(SettingsContext);

  const { data: maintenanceObjects } = useMaintenanceObjects();

  const maintenanceObject = maintenanceObjects?.find(
    (object) => object.id === maintenanceObjectId
  );

  const { isValid, values, dirty, submitForm, validateForm } =
    useFormikContext<FormikMaintenanceWizardValues>();

  useEffect(() => {
    validateForm();
  }, [activeStep]);

  const isMaintenanceReadOnly =
    maintenanceObject !== undefined &&
    maintenanceObject.currentRequestId !== undefined;

  const canMakeRequest =
    values.type &&
    isServiceAlreadySupported(labelToProvidedService(values.type))
      ? maintenanceObjectsInRequestValidationSchema.isValidSync(values) &&
        !isMaintenanceReadOnly
      : !isMaintenanceReadOnly;

  const saveChanges = async () => {
    await submitForm();
    if (!maintenanceObjects || maintenanceObjects?.length > 0) return;
    if (!userInfo?.email) {
      setIsNotificationOpen(true);
      setShowNotificationForm(false);
    } else {
      if (!customer || !userInfo || customer.notification) return;
      await updateCustomer({
        ...customer,
        notification: {
          email: userInfo.email,
          daysInAdvance: 60,
          isReminderEnabled: true,
          isReminderWhenDueEnabled: true,
          isStatusUpdatesEnabled: true,
          isTestUserContactingEnabled: true,
        },
      });
    }
  };

  const onTipModalConfirmation = async (shouldSaveChanges: boolean) => {
    saveShouldShowDocuHint(!stopShowingDocuHint);
    setIsDocuMissingModalOpen(false);
    if (shouldSaveChanges) {
      await saveChanges();
    }
  };

  return (
    <WizardFooter>
      <div className="flex flex-1 justify-end space-x-2">
        <div>
          {!isMaintenanceReadOnly && (
            <Button
              label="Abbrechen"
              icon={<FaTimesCircle />}
              onClick={() => navigate(-1)}
              className="outline-button"
            />
          )}
        </div>
        {activeStep === 2 ? (
          <Button
            label={
              isMaintenanceReadOnly ? 'Schließen' : 'Speichern & Schließen'
            }
            icon={<FaCheck />}
            disabled={!isValid}
            className="primary-button"
            onClick={async () => {
              sessionStorage.removeItem('changed_maintenance_objects');
              if (isMaintenanceReadOnly) {
                navigate(`/${routes.maintenance}`);
              } else if (
                readShouldShowDocuHint() &&
                doesNotHaveDocumentationButShould(values)
              ) {
                setIsDocuMissingModalOpen(true);
              } else {
                await saveChanges();
              }
            }}
          />
        ) : (
          <Button
            label="Weiter"
            icon={<FaArrowAltCircleRight />}
            disabled={!isValid && (!dirty || maintenanceObjectId === undefined)}
            onClick={async () => {
              setActiveStep(2);
            }}
          />
        )}
      </div>
      <HintModal
        isOpen={isDocuMissingModalOpen}
        headline="Lade zu Deiner Absturzsicherung noch eine Montagedokumentation hoch"
        width="one-third"
        center
      >
        <div className="font-bold">
          Warum ist eine aussagekräftige Montagedokumentation für eine Wartung
          wichtig?
        </div>
        <div className="pl-8 mt-2">
          <ul className="list-disc">
            <li>
              Eine aussagekräftige Montagedokumentation ist für die Wartung
              einer Absturzsicherung unverzichtbar, da in vielen Fällen die
              Absturzsicherung nach der Befestigung nicht mehr einsehbar oder
              zugänglich ist.
            </li>
            <li>
              Ohne eine solche Dokumentation fehlen uns Hinweise zum Einbau und
              es kann zu einer Dachöffnung kommen. Das heißt für Dich, es müssen
              zusätzlich Dachdecker*innen kommen und das Dach muss punktuell
              geöffnet werden.
            </li>
            <li>
              Kurz gesagt: Es ist für Dich mehr Aufwand und es wird am Ende
              teurer.
            </li>
          </ul>
        </div>
        <div className="mt-8">
          <Link
            to="/gute-doku"
            target="_blank"
            className="flex items-center text-blue-abs"
          >
            <FaQuestionCircle className="mr-1" />{' '}
            <div>Was ist eine aussagekräftige Montagedokumentation?</div>
          </Link>
        </div>
        <div className="flex flex-col items-center mt-8">
          <CheckBox
            disabled={false}
            checked={stopShowingDocuHint}
            onChange={() => {
              setStopShowingDocuHint((prevState) => !prevState);
            }}
            label="Tipp nicht mehr anzeigen"
          />
          <div className="flex mt-8 flex-wrap gap-2 justify-center">
            <Button
              className="outline-button"
              onClick={async () => onTipModalConfirmation(true)}
              label="Später"
            />
            <Button
              className="primary-button"
              onClick={async () => onTipModalConfirmation(false)}
              label="Dokumentation hochladen"
            />
          </div>
        </div>
      </HintModal>
    </WizardFooter>
  );
};

export default MaintenanceWizardFooter;
