import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { Ladder, MaintenanceObject } from '../../maintenance-object';

export class MustBeLadderTypeRule<
  T extends MaintenanceObject
> extends DomainRule<T, T> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (!(entity.type instanceof Ladder)) {
      throw new DomainRuleNotSatisfiedError(MustBeLadderTypeRule);
    }
    return entity;
  }
}
