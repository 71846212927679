import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';
import { distinct } from '@warthungshelden/shared-functions';

import {
  MaintenanceDocumentation,
  MaintenanceDocumentationService,
} from '../../maintenance-documentation';
import { MaintenanceObjectRepository } from '../../maintenance-object';

export class MustNotExistOnBuilding extends DomainRule<
  MaintenanceDocumentation,
  MaintenanceDocumentation
> {
  constructor(
    private user: User,
    private maintenanceDocumentationService: MaintenanceDocumentationService,
    private maintenanceObjectRepository: MaintenanceObjectRepository
  ) {
    super();
  }

  protected async rule(newMaintenanceDocumentation: MaintenanceDocumentation) {
    const maintenanceObjects =
      await this.maintenanceObjectRepository.getAllById(
        newMaintenanceDocumentation.maintenanceObjectIds
      );

    const buildingIds = distinct(
      maintenanceObjects.map(
        (maintenanceObject) => maintenanceObject.buildingId
      )
    );

    const areInBuilding = await Promise.all(
      buildingIds.map(async (buildingId) => {
        if (!buildingId) {
          return false;
        }

        const documentations =
          await this.maintenanceDocumentationService.getAllByBuilding(
            this.user,
            buildingId
          );

        return Boolean(
          documentations.find(
            (documentation) =>
              documentation.displayName ===
              newMaintenanceDocumentation.displayName
          )
        );
      })
    );

    if (areInBuilding.some((isInBuilding) => isInBuilding)) {
      throw new DomainRuleNotSatisfiedError(MustNotExistOnBuilding);
    }

    return newMaintenanceDocumentation;
  }
}
