import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import NotificationsModal from '../../components/Settings/NotificationsModal';
import {
  SettingsContext,
  SettingsProvider,
} from '../../contexts/SettingsContext';

const UserRoleWrapper: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setIsNotificationOpen } = useContext(SettingsContext);
  return (
    <SettingsProvider>
      <Outlet />
      <NotificationsModal
        onCancel={() => setIsNotificationOpen(false)}
        onConfirm={() => setIsNotificationOpen(false)}
      />
    </SettingsProvider>
  );
};

export default UserRoleWrapper;
