import React from 'react';

interface AuthLinkButtonProps {
  label: string;
  onClick: () => void;
}

const AuthLinkButton: React.FC<
  React.PropsWithChildren<AuthLinkButtonProps>
> = ({ label, onClick }) => (
  <button
    type="button"
    className="underline text-blue-abs text-sm"
    onClick={onClick}
  >
    {label}
  </button>
);

export default AuthLinkButton;
