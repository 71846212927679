import React, { useCallback, useEffect, useState } from 'react';

import { areEqual } from '../../../../set';
import { getFilter, saveFilter } from '../../../services/filter';
import SelectAllCheckbox from '../../Filter/SelectAllCheckbox';
import CheckBox from '../Inputs/CheckBox/CheckBox';

type FilterCheckboxListProps<
  T extends { [P in Key]: string } | { id: string },
  Key extends keyof T
> = {
  items: T[];
  selectedItems?: T[] | null;
  discriminator: Key;
  labelProp: Key;
  setSelectedItems: (items: T[]) => void;
  filterKey: string;
  label?: string;
};

const FilterCheckboxList: React.FC<FilterCheckboxListProps<any, any>> = <
  T extends { [P in Key]: string } | { id: string },
  Key extends keyof T
>({
  items,
  selectedItems,
  setSelectedItems,
  filterKey,
  labelProp,
  discriminator,
  label,
}: FilterCheckboxListProps<T, Key>) => {
  const [isAllChecked, setIsAllChecked] = useState(false);

  const toId = useCallback(
    // @ts-ignore
    (item: T) => (discriminator ? item[discriminator] : item.id),
    [discriminator]
  );

  const getUniqueItems = useCallback(
    (uniqueItems: T[]) => {
      return uniqueItems.reduce((unique, o) => {
        if (!unique.some((obj) => toId(obj) === toId(o))) {
          unique.push(o);
        }
        return unique;
      }, new Array<T>());
    },
    [toId]
  );
  const [uniqueItems, setUniqueItems] = useState<T[]>(getUniqueItems(items));

  useEffect(() => {
    const newUnique = getUniqueItems(items);

    const oldIds = uniqueItems.map(toId);
    const newIds = newUnique.map(toId);

    if (!uniqueItems || !areEqual(oldIds, newIds)) {
      setUniqueItems(newUnique);
    }
  }, [getUniqueItems, items, toId, uniqueItems]);

  useEffect(() => {
    const savedFilters = getFilter<T>(filterKey);
    setSelectedItems(savedFilters || uniqueItems);
  }, [uniqueItems]);

  const toggleCheckedValue = (isSelected, item) => {
    if (isSelected) {
      return selectedItems?.filter((prevItem) => toId(prevItem) !== toId(item));
    }
    return selectedItems ? [...selectedItems, item] : [item];
  };

  return (
    <div className="flex flex-col h-full bg-white">
      <div className="flex items-center justify-between text-base font-bold bg-gray-light text-black-abs p-2 h-9">
        {label}
        <SelectAllCheckbox
          checked={isAllChecked}
          setChecked={setIsAllChecked}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          items={uniqueItems}
          filterKey={filterKey}
        />
      </div>

      <div className="flex flex-1 h-full flex-col overflow-y-auto pl-4 py-2 space-y-2 ">
        {uniqueItems?.map((item: T) => {
          const isSelected = !!(labelProp
            ? selectedItems?.find((selectedItem) => {
                return toId(selectedItem) === toId(item);
              })
            : selectedItems?.includes(item));

          const onCheckboxChange = () => {
            const updatedSelectedItems = toggleCheckedValue(isSelected, item);

            if (updatedSelectedItems) {
              saveFilter(filterKey, updatedSelectedItems);
              setSelectedItems(updatedSelectedItems);
            }
          };
          return (
            <CheckBox
              disabled={false}
              key={toId(item)}
              checked={isSelected}
              onChange={onCheckboxChange}
              label={item[labelProp] as string}
            />
          );
        })}
      </div>
    </div>
  );
};

FilterCheckboxList.defaultProps = {
  selectedItems: undefined,
  label: undefined,
};

export default FilterCheckboxList;
