import { useFormikContext } from 'formik';
import React from 'react';

import { FormikBuildingValues } from '../../../types/formikBuildingValues';
import { RadioButtonField } from '../../index';
import InputField from './InputField';

type RadioListProps = {
  selectionItems: string[];
  name: string;
  otherName?: string;
};

const RadioList: React.FC<React.PropsWithChildren<RadioListProps>> = ({
  selectionItems,
  name,
  otherName,
}) => {
  const { values } = useFormikContext<FormikBuildingValues>();
  return (
    <div data-cy="components-basics-radio-list">
      {selectionItems.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`radio-${index}`} className="flex">
          <RadioButtonField
            key={`radio-${index}`} // eslint-disable-line react/no-array-index-key
            label={item !== 'Sonstiges' ? item : undefined}
            name={name}
            value={item}
          />
          {item === 'Sonstiges' && otherName && (
            <InputField
              name={otherName}
              placeholder="Sonstiges"
              disabled={values.buildingType !== 'Sonstiges'}
            />
          )}
        </div>
      ))}
    </div>
  );
};

RadioList.defaultProps = {
  otherName: undefined,
};

export default RadioList;
