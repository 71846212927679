export function distinct<T>(array: T[]) {
  return [...new Set([...array])];
}

export function intersects<T>(array1: T[], array2: T[]) {
  return array1.some((value) => array2.includes(value));
}

export function intersection<T>(array1: T[]) {
  return (item: T) => array1.includes(item);
}

export function difference<T>(array1: T[]) {
  return (item: T) => !array1.includes(item);
}
