import React, { PropsWithChildren } from 'react';

import TextWithLabel from '../../../../components/Basics/TextWithLabel';
import { MANUFACTURERS } from '../../../../constants/MaintenanceConstants';
import { locationValueToLabel } from '../../../../services/maintenanceService';
import {
  PriceInformationCombination,
  isClimbingProtectionCombination,
  isGroundStairsCombination,
  isLadderCombination,
  isOverpassCombination,
  isPersonalProtectionEquipmenCombination,
  isPushLockCombination,
  isRailSystemCombination,
  isRailingCombination,
  isRopeSystemCombination,
  isSingleAnchorCombination,
  isStairLadderCombination,
} from '../utils/combinations';
import {
  FOREIGN_MANUFACTURER,
  buildingText,
  getObjectSummarySubText,
} from '../utils/utils';
import MaintenanceObjectSummaryRow from './MaintenanceObjectSummaryRow';
import ClimbingProtecionProperties from './systemProperties/ClimbingProtecionProperties';
import GliderAsCombinationProperties from './systemProperties/GliderAsCombinationProperties';
import GroundStairsProperties from './systemProperties/GroundStairsProperties';
import LadderProperties from './systemProperties/LadderProperties';
import OverpassProperties from './systemProperties/OverpassProperties';
import PpeProperties from './systemProperties/PpeProperties';
import PushLockProperties from './systemProperties/PushLockProperties';
import RailSystemProperties from './systemProperties/RailSystemProperties';
import RailingProperties from './systemProperties/RailingProperties';
import RopeSystemProperties from './systemProperties/RopeSystemProperties';
import SingleAnchorProperties from './systemProperties/SingleAnchorProperties';
import StairLadderProperties from './systemProperties/StairLadderProperties';

interface Props {
  combination: PriceInformationCombination;
  hasSubprices: boolean;
  isSubRowSummary?: boolean;
}

const fallProtectionSystem = (combination) => {
  if (isRopeSystemCombination(combination)) {
    return combination.primarySystem;
  }
  if (isPushLockCombination(combination)) {
    return combination.primarySystem;
  }
  if (isSingleAnchorCombination(combination)) {
    return combination.primarySystem;
  }

  return null;
};

const MaintenanceObjectSummary: React.FC<PropsWithChildren<Props>> = ({
  combination,
  hasSubprices,
  isSubRowSummary,
}) => {
  const fallProtection = fallProtectionSystem(combination);
  const objectSummarySubText = getObjectSummarySubText(combination);

  return (
    <>
      {!isSubRowSummary && <div className="font-bold p-2">Eigenschaften</div>}
      {fallProtection && (
        <div className="gap-x-2 px-2 group">
          {!isSubRowSummary && (
            <MaintenanceObjectSummaryRow
              label="Hersteller"
              value={
                fallProtection.object.manufacturer === MANUFACTURERS[0]
                  ? MANUFACTURERS[0]
                  : FOREIGN_MANUFACTURER
              }
              hasSubprices={false}
            />
          )}

          {!isSubRowSummary && (
            <MaintenanceObjectSummaryRow
              label="Gebäude"
              value={buildingText(fallProtection)}
              hasSubprices={false}
            />
          )}

          {isRopeSystemCombination(combination) &&
            (isSubRowSummary ? (
              <TextWithLabel label=" " labelStyle="font-normal w-96">
                {objectSummarySubText}
              </TextWithLabel>
            ) : (
              <>
                <MaintenanceObjectSummaryRow
                  label="Position"
                  value={locationValueToLabel(
                    combination.primarySystem.object.type
                  )}
                  hasSubprices={false}
                />
                <RopeSystemProperties
                  ropeSystem={combination.primarySystem.object.type.system!}
                  hasSubprices={hasSubprices}
                />
                {combination.glider && (
                  <GliderAsCombinationProperties
                    glider={combination.glider.object.type}
                    hasSubprices={hasSubprices}
                  />
                )}
              </>
            ))}

          {isPushLockCombination(combination) &&
            (isSubRowSummary ? (
              <TextWithLabel label={' '} labelStyle="font-normal w-96">
                {objectSummarySubText}
              </TextWithLabel>
            ) : (
              <>
                <MaintenanceObjectSummaryRow
                  label="Position"
                  value={locationValueToLabel(
                    combination.primarySystem.object.type
                  )}
                  hasSubprices={false}
                />
                <PushLockProperties
                  pushLockSystem={combination.primarySystem.object.type.system!}
                  hasSubprices={hasSubprices}
                />
              </>
            ))}

          {isSingleAnchorCombination(combination) &&
            (isSubRowSummary ? (
              <TextWithLabel label={' '} labelStyle="font-normal w-96">
                {objectSummarySubText}
              </TextWithLabel>
            ) : (
              <SingleAnchorProperties
                singleAnchorSystem={
                  combination.primarySystem.object.type.system!
                }
                hasSubprices={hasSubprices}
              />
            ))}
        </div>
      )}
      {isPersonalProtectionEquipmenCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <MaintenanceObjectSummaryRow
              label="Hersteller"
              value={
                combination.primarySystem.object.manufacturer ===
                MANUFACTURERS[0]
                  ? MANUFACTURERS[0]
                  : FOREIGN_MANUFACTURER
              }
              hasSubprices={false}
            />

            <PpeProperties
              ppe={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />
          </div>
        ))}
      {isLadderCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <LadderProperties
              ladder={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />
          </div>
        ))}
      {isRailingCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <RailingProperties
              railing={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />
          </div>
        ))}
      {isStairLadderCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <StairLadderProperties
              stairLadder={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />
          </div>
        ))}
      {isOverpassCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <OverpassProperties
              overpass={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />
          </div>
        ))}
      {isGroundStairsCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <GroundStairsProperties
              stairs={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />
          </div>
        ))}
      {isRailSystemCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <MaintenanceObjectSummaryRow
              label="Hersteller"
              value={
                combination.primarySystem.object.manufacturer ===
                MANUFACTURERS[0]
                  ? MANUFACTURERS[0]
                  : FOREIGN_MANUFACTURER
              }
              hasSubprices={false}
            />

            <MaintenanceObjectSummaryRow
              label="Gebäude"
              value={buildingText(combination.primarySystem)}
              hasSubprices={false}
            />

            <RailSystemProperties
              railSystem={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />

            {combination.glider && (
              <GliderAsCombinationProperties
                glider={combination.glider.object.type}
                hasSubprices={hasSubprices}
              />
            )}
          </div>
        ))}
      {isClimbingProtectionCombination(combination) &&
        (isSubRowSummary ? (
          <TextWithLabel label={' '} labelStyle="font-normal w-96">
            {objectSummarySubText}
          </TextWithLabel>
        ) : (
          <div className="gap-x-2 p-2 group">
            <MaintenanceObjectSummaryRow
              label="Hersteller"
              value={
                combination.primarySystem.object.manufacturer ===
                MANUFACTURERS[0]
                  ? MANUFACTURERS[0]
                  : FOREIGN_MANUFACTURER
              }
              hasSubprices={false}
            />

            <MaintenanceObjectSummaryRow
              label="Gebäude"
              value={buildingText(combination.primarySystem)}
              hasSubprices={false}
            />

            <ClimbingProtecionProperties
              climbingProtection={combination.primarySystem.object.type}
              hasSubprices={hasSubprices}
            />

            {combination.ladder && (
              <>
                <TextWithLabel
                  label={`Steigleiter${hasSubprices ? ' (gesamt):' : ':'}`}
                  labelStyle="font-normal w-96"
                  dataCy="summary-Leiter"
                >
                  {`${combination.primarySystem.object.type.length} m`}
                </TextWithLabel>
                <TextWithLabel
                  label={`Podeste${hasSubprices ? ' (gesamt):' : ':'}`}
                  labelStyle="font-normal w-96"
                >
                  {`${combination.ladder.object.type.numberOfPlatforms} Stück`}
                </TextWithLabel>
              </>
            )}

            {combination.glider && (
              <GliderAsCombinationProperties
                glider={combination.glider.object.type}
                hasSubprices={hasSubprices}
              />
            )}
          </div>
        ))}
    </>
  );
};

MaintenanceObjectSummary.defaultProps = {
  isSubRowSummary: false,
};

export default MaintenanceObjectSummary;
