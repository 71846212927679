import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import adminLoginBackground from '../../../assets/adminLoginBackground.png';
import environment from '../../../environments/environment';
import useScript from '../../contexts/UseScriptHook';

const AuthWrapper = () => {
  // Hotjar Tracking Code for https://app.abs-wartung.de
  useScript(
    'https://static.hotjar.com/c/hotjar-3060714.js?sv=6',
    environment.production && !environment.e2e,
    false,
    '(function(h,o,t,j,a,r){\n' +
      '        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n' +
      '        h._hjSettings={hjid:3060714,hjsv:6};\n' +
      "        a=o.getElementsByTagName('head')[0];\n" +
      "        r=o.createElement('script');r.async=1;\n" +
      '        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n' +
      '        a.appendChild(r);\n' +
      "    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
  );

  const location = useLocation();
  const isOnAdminLogin = () => location.pathname === '/admin/login';

  return (
    <div
      className="bg-gray-100 overflow-auto h-full m-0"
      style={{
        backgroundImage: isOnAdminLogin() ? `url(${adminLoginBackground})` : '',
        backgroundPosition: 'left 48px',
        backgroundSize: 'cover',
      }}
    >
      <div
        className={`h-full w-full flex justify-center ${
          isOnAdminLogin()
            ? 'items-center bg-gradient-to-b from-black-abs to-transparent'
            : ''
        }`}
      >
        <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-lg">
          <div className="w-full p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthWrapper;
