import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { RadioButtonField } from '../../../../../components';
import UnitInputField from '../../../../../components/Basics/Inputs/UnitInputField';
import { InfoMessageBox } from '../../../../../components/Basics/MessageBox';
import { isLadderDto } from '../../../../../guards/isMaintenanceType';
import { PriceCalculationMaintenanceObjectModalFormikState } from '../../types/PriceCalculationMaintenanceObjectModalFormikState';
import { PriceInformationCombination } from '../../utils/combinations';
import { preselectedClimbingProtection } from '../../utils/utils';

interface LadderSelectorProps {
  isUpdating: boolean;
  setSelectedPriceInformationObject: (
    preselectedObject: PriceInformationCombination
  ) => void;
}

const LadderSelector: React.FC<LadderSelectorProps> = ({
  isUpdating,
  setSelectedPriceInformationObject,
}) => {
  const { setFieldValue, values } =
    useFormikContext<PriceCalculationMaintenanceObjectModalFormikState>();
  useEffect(() => {
    const { type } = values.object;
    if (isUpdating && isLadderDto(type)) {
      setFieldValue('ladderLength', type.parts[0] <= 10 ? 5 : 15);
      setFieldValue('amount', type.parts.length);
    }
  }, []);
  return (
    <div className="space-y-4 px-8">
      <div className="pb-4">
        <InfoMessageBox headline="Hinweis">
          <div>
            Hier können nur <strong>Steigleitern ohne Steigschutz</strong>{' '}
            hinzugefügt werden.
          </div>
          <div>
            Für Steigleitern mit Steigschutz nutze den Weg über den Button
            „Steigschutz“ oder klicke{' '}
            <button
              type="button"
              className="underline text-blue-abs"
              onClick={() => {
                setSelectedPriceInformationObject(
                  preselectedClimbingProtection
                );
              }}
            >
              hier
            </button>
            .
          </div>
        </InfoMessageBox>
      </div>
      <div className="flex flex-row items-start">
        <div className="font-bold w-64 mr-4">Kategorie*</div>
        <div className="flex flex-1 flex-col space-y-2">
          <RadioButtonField name="ladderLength" value={5} label="bis 10m" />
          <RadioButtonField
            name="ladderLength"
            value={15}
            label="zwischen 11m und 30m"
          />
        </div>
      </div>

      <UnitInputField
        label="Steigleitern*"
        name="amount"
        unit="Stück"
        allowDecimals={false}
        shouldSelectOnFocus
      />

      <UnitInputField
        label="Podeste (gesamt)"
        name="object.type.numberOfPlatforms"
        unit="Stück"
        allowDecimals={false}
        shouldSelectOnFocus
      />
    </div>
  );
};

export default LadderSelector;
