import React from 'react';
import 'react-responsive-modal/styles.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider, AuthService } from '@wartungshelden/react-oauth2';

import './App.css';
import { Config } from './Config';
import InstanaComponent from './Instana';
import { HintHighlightProvider } from './contexts/HintHighlightContext';
import { RoutePlanProvider } from './contexts/RoutePlanContext';
import { SessionProvider } from './contexts/SessionContext';
import PermissionRouter from './routes/PermissionRouter';
import { AxiosProvider } from './services/api/api';

const authService = new AuthService({
  clientId: Config.clientId,
  location: window.location,
  scopes: ['openid', 'profile'],
  autoRefresh: true,
  provider: `${Config.appProvider}/oauth2`,
  redirectUri: Config.redirectUri,
  logoutEndpoint: `${Config.appProvider}/logout`,
});

function App() {
  return (
    <BrowserRouter>
      <AuthProvider authService={authService}>
        <SessionProvider>
          <AxiosProvider>
            <InstanaComponent />
            <HintHighlightProvider>
              <RoutePlanProvider>
                <div className="App">
                  <div className="Main-container">
                    <PermissionRouter />
                  </div>
                </div>
              </RoutePlanProvider>
            </HintHighlightProvider>
          </AxiosProvider>
        </SessionProvider>
        <ToastContainer />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
