import { AddressDto } from './address';

export interface BillingAddressDto {
  readonly company: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly address: AddressDto;
}

export interface NotificationDto {
  readonly email?: string | null;
  readonly isReminderEnabled: boolean;
  readonly isReminderWhenDueEnabled: boolean;
  readonly isTestUserContactingEnabled: boolean;
  readonly isStatusUpdatesEnabled: boolean;
  readonly daysInAdvance?: number | null;
}

export interface CustomerDto {
  readonly id: string;
  readonly billingAddresses: BillingAddressDto[];
  readonly email?: string | null;
  readonly phoneNumber?: string | null;
  readonly notification: NotificationDto;
  readonly absCustomerNumber?: string | null;
  readonly hasAcceptedCustomerNumber: boolean;
}

export type CreateCustomerDto = Omit<CustomerDto, 'id'>;
