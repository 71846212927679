import { isWithinInterval } from 'date-fns';

import { MaintenanceDiscount, MaintenancePrice } from './maintenance-price';

export interface DiscountCampaign {
  applyDiscount(price: MaintenancePrice): MaintenancePrice;
}

export class TimeConstrainedDiscountCampaign implements DiscountCampaign {
  constructor(
    public readonly from: Date,
    public readonly to: Date,
    public readonly discount: MaintenanceDiscount
  ) {}

  public applyDiscount(price: MaintenancePrice) {
    if (!isWithinInterval(new Date(), { start: this.from, end: this.to })) {
      return price;
    }

    return price.withDiscount(this.discount);
  }
}

const September2022Campaign = new TimeConstrainedDiscountCampaign(
  new Date(2022, 7, 0),
  new Date(2022, 8, 30),
  new MaintenanceDiscount({
    name: 'September-Aktion',
    value: -10,
    type: 'percentage',
  })
);

export const DiscountCampaigns = [September2022Campaign];
