import {
  BuildingDto,
  CurrentMaintenanceObjectDto,
} from '@wartungshelden/shared-types';

const groupRequestObjectsByBuildings = (
  buildings: BuildingDto[] | undefined | null,
  maintenanceObjects: CurrentMaintenanceObjectDto[] | undefined
) => {
  const result: Array<{
    building: BuildingDto | undefined;
    maintenances: CurrentMaintenanceObjectDto[];
  }> = [];

  if (buildings && maintenanceObjects) {
    const buildingFromRequest = [
      ...new Set(
        maintenanceObjects.map((maintenanceObject) =>
          buildings.find(
            (building) => building.id === maintenanceObject.buildingId
          )
        )
      ),
    ];

    buildingFromRequest?.forEach((building) => {
      const maintenancesFromSameBuilding = maintenanceObjects.filter(
        (maintenanceObject) => maintenanceObject.buildingId === building?.id
      );

      result.push({
        building,
        maintenances: maintenancesFromSameBuilding,
      });
    });
  }

  return result;
};

export default groupRequestObjectsByBuildings;
