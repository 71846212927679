import { ProvidedServiceEnum } from '@wartungshelden/shared-types';

export const labelToProvidedService = (value: string) => {
  switch (value) {
    case 'Absturzsicherung':
      return ProvidedServiceEnum.FALL_PROTECTION;
    case 'PSAgA':
      return ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT;
    case 'Blitzschutz':
      return ProvidedServiceEnum.LIGHTNING_CONDUCTOR;
    case 'RWA':
      return ProvidedServiceEnum.SMOKE_HEAT_EXTRACTION_SYSTEM;
    case 'Steigleiter':
      return ProvidedServiceEnum.LADDER;
    case 'Mobile Leiter, Tritt, Fahrgerüst':
      return ProvidedServiceEnum.MOBILE_LADDER;
    case 'Lüftungs-/Klimaanlage':
      return ProvidedServiceEnum.CONDITIONER;
    case 'Lichtkuppel':
      return ProvidedServiceEnum.WINDOW;
    case 'PV-Anlage':
      return ProvidedServiceEnum.PV_SYSTEM;
    default:
      return value;
  }
};

export const providedServiceToLabel = (status: string) => {
  switch (status) {
    case ProvidedServiceEnum.FALL_PROTECTION:
      return 'Absturzsicherung';
    case ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT:
      return 'PSAgA';
    case ProvidedServiceEnum.LIGHTNING_CONDUCTOR:
      return 'Blitzschutz';
    case ProvidedServiceEnum.SMOKE_HEAT_EXTRACTION_SYSTEM:
      return 'RWA';
    case ProvidedServiceEnum.LADDER:
      return 'Steigleiter';
    case ProvidedServiceEnum.MOBILE_LADDER:
      return 'Mobile Leiter, Tritt, Fahrgerüst';
    case ProvidedServiceEnum.PV_SYSTEM:
      return 'PV-Anlage';
    case ProvidedServiceEnum.CONDITIONER:
      return 'Lüftungs-/Klimaanlage';
    case ProvidedServiceEnum.WINDOW:
      return 'Lichtkuppel';
    default:
      return status;
  }
};

export const providedServiceTypes = Object.values(ProvidedServiceEnum).map(
  (status: string) => providedServiceToLabel(status)
);
