import React from 'react';

import {
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { useMaintenanceDocumentationsForBuilding } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import { FileOrDocumentation } from '../../../services/maintenanceService';
import Loader from '../../Basics/Loaders/Loader';
import DocumentationUploadModal from '../Documentation/DocumentationUploadModal';

interface UploadAdditionalDocumentModalProps {
  visible: boolean;
  maintenanceObject: MaintenanceObjectDto;
  onSave: (
    existingDocs: MaintenanceDocumentationDto[],
    selectedDocs: FileOrDocumentation[],
    uploadedDocs: File[]
  ) => void;
}

const UploadAdditionalDocumentModal: React.FC<
  UploadAdditionalDocumentModalProps
> = ({ visible, maintenanceObject, onSave }) => {
  const { data: documentations, isLoading } =
    useMaintenanceDocumentationsForBuilding(maintenanceObject.buildingId);

  if (visible && isLoading) {
    return <Loader />;
  }

  const defaultArray = <T,>(array?: T[] | null, value: T[] = []) =>
    array ?? value;

  const disabledDocuments = documentations?.filter((doc) =>
    [
      ...defaultArray(doc.validFor),
      ...defaultArray(doc.invalidFor),
      ...defaultArray(doc.notRelevantFor),
    ].includes(maintenanceObject.id)
  );

  return documentations ? (
    <DocumentationUploadModal
      visible={visible}
      existingDocumentations={documentations}
      disabledDocumentations={disabledDocuments}
      onSave={onSave}
      index={0}
    />
  ) : null;
};

export default UploadAdditionalDocumentModal;
