import { DomainEvent } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import { MaintenanceOffer } from './maintenance-offer';

export class MaintenanceOfferStatusChanged extends DomainEvent {
  public static override tag = 'maintenance.offer.status.changed';

  constructor(
    public readonly user: User,
    public readonly maintenanceOffer: MaintenanceOffer
  ) {
    super();
  }
}

export class MaintenanceOfferDocumentUploadOpened extends DomainEvent {
  public static override tag = 'maintenance.offer.document_upload.opened';

  constructor(
    public readonly user: User,
    public readonly maintenanceOffer: MaintenanceOffer,
    public readonly openedDocumentUploads: string[]
  ) {
    super();
  }
}

export class MaintenanceOfferDocumentUploadClosed extends DomainEvent {
  public static override tag = 'maintenance.offer.document_upload.closed';

  constructor(
    public readonly user: User,
    public readonly maintenanceOffer: MaintenanceOffer,
    public readonly openedDocumentUploads: string[]
  ) {
    super();
  }
}

export class MaintenanceOfferDecisionSet extends DomainEvent {
  public static override tag = 'maintenance.offer.decision.set';

  constructor(
    public readonly user: User,
    public readonly maintenanceOffer: MaintenanceOffer
  ) {
    super();
  }
}

export class MaintenanceOrderClosed extends DomainEvent {
  public static override tag = 'maintenance.order.closed';

  constructor(
    public readonly user: User,
    public readonly maintenanceOrder: MaintenanceOffer
  ) {
    super();
  }
}
