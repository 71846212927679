import { isDefined, to } from '@warthungshelden/shared-functions';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import React, { FC, createContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { InstallerDto, OpenMaintenanceDto } from '@wartungshelden/shared-types';

import { getInefficiencyAnnotationsFor } from '../pages/AdminPages/routePlan/efficiency-rules';
import { RouteDtoWithEfficencyRules } from '../pages/AdminPages/routePlan/types/RouteDtoWithEfficencyRules';
import useRoute from '../services/api/ressource-planning/route-api';

interface ContextInterface {
  installerAreaId?: string;
  setInstallerAreaId: (installerAreaId: string | undefined) => void;
  deliveryDateMonth?: Date;
  setDeliveryDateMonth: (deliveryDate: Date | undefined) => void;
  selectedOpenMaintenances: OpenMaintenanceDto[] | undefined;
  setSelectedOpenMaintenances: (
    selectedOpenMaintenances: OpenMaintenanceDto[] | undefined
  ) => void;
  selectedInstallers: InstallerDto[] | undefined;
  setSelectedInstallers: (
    selectedInstallers: InstallerDto[] | undefined
  ) => void;
  considerCalendar: boolean;
  setConsiderCalendar: (considerCalendar: boolean) => void;
  invalidZipCodes: string[];
  routes?: RouteDtoWithEfficencyRules[];
  isLoadingPlanRoute: boolean;
  onPlanRoute: () => void;
  onLoadOpenMaintenances: (
    deliveryDateMonth?: Date,
    installerAreaId?: string
  ) => void;
  preferABSInstallers: boolean;
  setPreferABSInstallers: (preferABSInstallers: boolean) => void;
  clearRoutePlan: () => void;
  getNoteForMaintenance: (orderId: string) => string | undefined;
}

const contextDefaultValues: ContextInterface = {
  installerAreaId: undefined,
  setInstallerAreaId: () => {},
  deliveryDateMonth: undefined,
  setDeliveryDateMonth: () => {},
  selectedOpenMaintenances: undefined,
  setSelectedOpenMaintenances: (
    selectedOpenMaintenances: OpenMaintenanceDto[] | undefined
  ) => {},
  selectedInstallers: undefined,
  setSelectedInstallers: (selectedInstallers: InstallerDto[] | undefined) => {},
  considerCalendar: true,
  setConsiderCalendar: (considerCalendar: boolean) => {},
  invalidZipCodes: [],
  routes: [],
  isLoadingPlanRoute: false,
  onPlanRoute: () => {},
  onLoadOpenMaintenances: async (
    deliveryDateMonth?: Date,
    installerAreaId?: string
  ) => {},
  preferABSInstallers: true,
  setPreferABSInstallers: (preferABSInstallers: boolean) => {},
  clearRoutePlan: () => {},
  getNoteForMaintenance: () => undefined,
};

export const RoutePlanContext =
  createContext<ContextInterface>(contextDefaultValues);

const getInvalidZipCodes = (currentRoutes) => {
  if (!currentRoutes) return [];

  const invalidZipCodesFromRoutes = currentRoutes
    .flatMap((route) =>
      route.jobs.map((job) => {
        if (!job.coordinates.isValid) return job.zipCode;
        return null;
      })
    )
    .filter((zip) => zip !== null);

  return [...invalidZipCodesFromRoutes];
};

export const RoutePlanProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [routes, setRoutes] = useState<
    RouteDtoWithEfficencyRules[] | undefined
  >();

  const [deliveryDateMonth, setDeliveryDateMonth] = useState<
    Date | undefined
  >();
  const [installerAreaId, setInstallerAreaId] = useState<string | undefined>();
  const [selectedOpenMaintenances, setSelectedOpenMaintenances] = useState<
    OpenMaintenanceDto[] | undefined
  >();
  const [selectedInstallers, setSelectedInstallers] = useState<
    InstallerDto[] | undefined
  >();
  const [considerCalendar, setConsiderCalendar] = useState<boolean>(true);
  const [preferABSInstallers, setPreferABSInstallers] = useState<boolean>(true);

  const [invalidZipCodes, setInvalidZipCodes] = useState<string[]>([]);
  const [isLoadingPlanRoute, setIsLoadingPlanRoute] = useState<boolean>(false);
  const { mutateAsync: getRoute } = useRoute();

  const getNoteForMaintenance = (orderId: string) => {
    return selectedOpenMaintenances?.find(
      (openMaintenance) => openMaintenance.orderId === orderId
    )?.note;
  };

  const clearRoutePlan = () => {
    if (routes) {
      setRoutes(undefined);
    }
  };

  const onLoadOpenMaintenances = async (
    selectedDeliveryDateMonth,
    selectedInstallerAreaId
  ) => {
    clearRoutePlan();
    setInstallerAreaId(selectedInstallerAreaId);
    setDeliveryDateMonth(selectedDeliveryDateMonth);
  };

  const onPlanRoute = async () => {
    clearRoutePlan();
    setInvalidZipCodes([]);
    setIsLoadingPlanRoute(true);
    if (
      !selectedInstallers ||
      !selectedOpenMaintenances ||
      !deliveryDateMonth
    ) {
      return;
    }

    try {
      const fetchedRoutes = await getRoute({
        orders: selectedOpenMaintenances.map(to('orderId')).filter(isDefined),
        installers: selectedInstallers,
        from: startOfDay(startOfMonth(deliveryDateMonth)),
        to: endOfDay(endOfMonth(deliveryDateMonth)),
        considerCalendar,
        preferABSInstallers,
      });

      if (fetchedRoutes) {
        setInvalidZipCodes(getInvalidZipCodes(fetchedRoutes));
        const routesWithEfficiencyRules: RouteDtoWithEfficencyRules[] =
          fetchedRoutes
            .map((route) => {
              return {
                ...route,
                efficiencyRules: getInefficiencyAnnotationsFor(route),
              };
            })
            // sort by lowest amount of efficiencyRules and by smallest distance to first job
            .sort(
              (a, b) =>
                a.efficiencyRules.length - b.efficiencyRules.length ||
                (a.jobDistances[0]?.kilometers || 0) -
                  (b.jobDistances[0]?.kilometers || 0)
            );
        setRoutes(routesWithEfficiencyRules);
      }
    } catch {
      toast.error('Leider konnte die Route nicht berechnet werden', {
        position: 'top-center',
      });
    }
    setIsLoadingPlanRoute(false);
  };

  const value = useMemo<ContextInterface>(
    () => ({
      installerAreaId,
      setInstallerAreaId,
      deliveryDateMonth,
      setDeliveryDateMonth,
      selectedOpenMaintenances,
      setSelectedOpenMaintenances,
      selectedInstallers,
      setSelectedInstallers,
      considerCalendar,
      preferABSInstallers,
      setPreferABSInstallers,
      setConsiderCalendar,
      invalidZipCodes,
      routes,
      isLoadingPlanRoute,
      onPlanRoute,
      onLoadOpenMaintenances,
      clearRoutePlan,
      getNoteForMaintenance,
    }),
    [
      installerAreaId,
      deliveryDateMonth,
      selectedOpenMaintenances,
      selectedInstallers,
      considerCalendar,
      invalidZipCodes,
      routes,
      isLoadingPlanRoute,
      preferABSInstallers,
    ]
  );
  return (
    <RoutePlanContext.Provider value={value}>
      {children}
    </RoutePlanContext.Provider>
  );
};
