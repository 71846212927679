import { Formik } from 'formik';
import React, { useEffect, useReducer, useState } from 'react';

import { MaintenanceObjectDto } from '@wartungshelden/shared-types';

import { AddressFormikValues } from '../../../components/Address/types/AddressFormikValues';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import MaintenanceRequestWizardHeader from '../../../components/Maintenance/MaintenanceRequestWizard/MaintenanceRequestWizardHeader';
import { useBuildings } from '../../../services/api/buildings/buildings-api';
import {
  useOwnCustomer,
  useUpdateCustomer,
} from '../../../services/api/customer/customer-api';
import { useMaintenanceObjects } from '../../../services/api/maintenance-objects/maintenance-objects-api';
import { useCreateMaintenanceRequest } from '../../../services/api/maintenance-requests/maintenance-request-api';
import { resetFilters } from '../../../services/filter';
// eslint-disable-next-line import/no-cycle
import {
  MaintenanceRequestWizardForm,
  addressYupSchema,
} from './components/maintenanceRequestWizardForm';
import { StepThreeFormikValues } from './steps/SelectAppointmentStep';

type StepAction = 'previous' | 'next' | 'reset' | number;

export type StepState = {
  steps: { [step: number]: { disabled: boolean; visible: boolean } };
  current: number;
};

function stepReducerInitialState(steps: number, initial = 1): StepState {
  return Array(steps - 1)
    .fill(0)
    .reduce(
      (state, _, index) => {
        return {
          ...state,
          steps: {
            ...state.steps,
            [index + 2]: { disabled: true, visible: true },
          },
        };
      },
      { current: initial, steps: { 1: { disabled: false, visible: true } } }
    );
}

function stepReducer(state: StepState, action: StepAction) {
  if (action === 'reset') {
    return stepReducerInitialState(Object.keys(state.steps).length);
  }

  let step: number;
  if (action === 'previous') {
    step = state.current - 1;
  } else {
    step = action === 'next' ? state.current + 1 : action;
  }

  const isLast = step === Object.keys(state.steps).length;

  const steps = isLast
    ? Object.entries(state.steps).reduce(
        (current, [key, data]) => ({
          ...current,
          [key]: { ...data, disabled: true },
        }),
        {}
      )
    : state.steps;

  const stepsEnabled = {
    ...steps,
    [step]: { ...steps[step], disabled: false },
  };

  return { steps: stepsEnabled, current: step };
}

const MaintenanceRequestWizardPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { data: maintenances, isLoading: isLoadingMaintenances } =
    useMaintenanceObjects();
  const { data: buildings, isLoading: isLoadingBuildings } = useBuildings();
  const { mutateAsync: createMaintenanceRequest, isLoading: isRequesting } =
    useCreateMaintenanceRequest();
  const { data: customer } = useOwnCustomer();
  const { mutateAsync: updateCustomer, isLoading: isSavingCustomer } =
    useUpdateCustomer(false);

  const isLoading = isLoadingMaintenances || isLoadingBuildings;

  const [selectedMaintenances, setSelectedMaintenances] = useState<
    MaintenanceObjectDto[]
  >([]);

  const [step, changeStep] = useReducer(
    stepReducer,
    stepReducerInitialState(4, 1)
  );

  useEffect(() => {
    if (selectedMaintenances.length === 0) {
      changeStep('reset');
    }
  }, [selectedMaintenances]);

  const handleNextNavigation = () => changeStep('next');
  const handleMenuNavigation = (chosenStep: number) => changeStep(chosenStep);

  const initialValues: StepThreeFormikValues &
    AddressFormikValues & { hasSaveOnCustomerChecked: boolean } = {
    dateRanges: [],
    preferablyInDueMonth: true,
    additionalAppointmentInfo: '',
    addressLine: `${
      customer?.billingAddresses[0]?.address.addressLine1 || ''
    } ${customer?.billingAddresses[0]?.address.addressLine2 || ''}`,
    city: customer?.billingAddresses[0]?.address.city || '',
    company: customer?.billingAddresses[0]?.company || '',
    country: customer?.billingAddresses[0]?.address.countryISO || 'DE',
    firstName: customer?.billingAddresses[0]?.firstName || '',
    lastName: customer?.billingAddresses[0]?.lastName || '',
    postalCode: customer?.billingAddresses[0]?.address.postalCode || '',
    email: customer?.email || '',
    phone: customer?.phoneNumber || undefined,
    hasSaveOnCustomerChecked: !customer?.billingAddresses[0],
  };

  return (
    <div className="h-screen flex flex-1 flex-col overflow-hidden items-stretch">
      <MaintenanceRequestWizardHeader />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={addressYupSchema}
        validateOnMount
        onSubmit={async (values) => {
          resetFilters();
          const {
            email,
            postalCode,
            lastName,
            addressLine,
            city,
            company,
            country,
            firstName,
            hasSaveOnCustomerChecked,
            preferablyInDueMonth,
            dateRanges,
            additionalAppointmentInfo,
          } = values;

          if (customer && hasSaveOnCustomerChecked) {
            await updateCustomer({
              ...customer,
              email,
              billingAddresses: [
                {
                  address: {
                    countryISO: country,
                    addressLine1: addressLine,
                    city,
                    postalCode,
                  },
                  company,
                  firstName: firstName || '',
                  lastName: lastName || '',
                },
              ],
            });
          }

          await createMaintenanceRequest({
            objectIds: selectedMaintenances.map(({ id }) => id),
            asap: preferablyInDueMonth,
            additionalInfo: additionalAppointmentInfo,
            dateRanges: dateRanges.map(({ from, to }) => ({
              from: from!,
              to: to!,
            })),
            address: {
              email: email?.length ? email : undefined,
              postalCode,
              firstName: firstName?.length ? firstName : undefined,
              lastName: lastName?.length ? lastName : undefined,
              addressLine1: addressLine,
              addressLine2: '',
              city,
              company,
              countryISO: country,
            },
            absCustomerNumber: customer?.absCustomerNumber,
          });
        }}
      >
        {({ values }) => (
          <>
            <MaintenanceRequestWizardForm
              maintenances={maintenances || []}
              selectedMaintenances={selectedMaintenances}
              setSelectedMaintenances={setSelectedMaintenances}
              values={values}
              isLoading={isLoading}
              buildings={buildings || []}
              handleMenuNavigation={handleMenuNavigation}
              handleNextNavigation={handleNextNavigation}
              step={step}
              changeStep={changeStep}
            />
            <LoadingSpinner isLoading={isSavingCustomer || isRequesting} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default MaintenanceRequestWizardPage;
