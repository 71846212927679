import React, { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useMaintenanceObjects,
  useRemoveMaintenanceObject,
} from '../../../services/api/maintenance-objects/maintenance-objects-api';
import { renderTaskStatus } from '../../../services/maintenanceService';
import Confirmation from '../../Basics/Confirmation';
import Modal from '../../Basics/Modals/Modal';

const MaintenanceWizardHeader = () => {
  const navigate = useNavigate();
  const { maintenanceObjectId } = useParams();

  const { mutateAsync: removeMaintenanceObject } = useRemoveMaintenanceObject();
  const { data: maintenanceObjects } = useMaintenanceObjects();
  const maintenanceObject = maintenanceObjects?.find(
    (object) => object.id === maintenanceObjectId
  );

  const handleRemoveMaintenanceObject = async () => {
    if (maintenanceObject?.id) {
      await removeMaintenanceObject(maintenanceObject.id);
      navigate(-1);
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  return (
    <div className="h-24 bg-red text-white">
      <div className="flex h-full items-end pl-80 pr-2">
        <div className="flex flex-1 justify-between items-center h-full">
          <div className="text-4xl ">Wartung</div>
          <div className="flex items-center">
            {maintenanceObjectId ? (
              <div className="flex mr-10">
                <button
                  type="button"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                  }}
                >
                  <FaTrashAlt size={25} />
                </button>
              </div>
            ) : null}
            <div>Status:</div>
            <div className="w-48 bg-white/40 text-center mx-4 p-2">
              {renderTaskStatus(maintenanceObject?.currentRequestId ? 1 : 0)}
            </div>
          </div>
        </div>
      </div>
      <Modal center isOpen={isDeleteModalOpen}>
        {!maintenanceObject?.currentRequestId ? (
          <Confirmation
            question="Möchtest Du diese Wartung löschen?"
            confirm={() => {
              handleRemoveMaintenanceObject().catch();
            }}
            confirmLabel="Löschen"
            cancel={() => setIsDeleteModalOpen(false)}
          />
        ) : (
          <Confirmation
            question="Die Wartungsaufgabe wurde bereits angefragt. Du kannst die Aufgabe daher nicht löschen."
            confirm={() => {
              setIsDeleteModalOpen(false);
              navigate(-1);
            }}
            confirmLabel="Ok"
          />
        )}
      </Modal>
    </div>
  );
};

export default MaintenanceWizardHeader;
