import {
  MaintenanceObjectPriceDto,
  PriceComponentDto,
} from '@wartungshelden/shared-types';

const summarizeArrayValues = (
  firstArray: PriceComponentDto[],
  secondArray: PriceComponentDto[]
): PriceComponentDto[] => {
  const typeMap = [...firstArray, ...secondArray].reduce(
    (components, currentComponent) => {
      if (!(currentComponent.type in components)) {
        return { ...components, [currentComponent.type]: currentComponent };
      }

      const currentValue = components[currentComponent.type];
      return {
        ...components,
        [currentComponent.type]: {
          ...currentValue,
          value: currentValue.value + currentComponent.value,
          position: Math.max(currentValue.position, currentComponent.position),
        },
      };
    },
    {} as Record<string, PriceComponentDto>
  );

  return Object.values<PriceComponentDto>(typeMap);
};

const toSummarizedObjectPrice = (
  summarizedPrice: MaintenanceObjectPriceDto | undefined,
  priceToBeSummarized: MaintenanceObjectPriceDto
) => {
  if (!summarizedPrice) {
    return priceToBeSummarized;
  }

  return {
    ...priceToBeSummarized,
    variableNetPrice:
      (priceToBeSummarized.variableNetPrice ?? 0) +
      (summarizedPrice.variableNetPrice ?? 0),
    variablePriceComponents: {
      actual: summarizeArrayValues(
        summarizedPrice.variablePriceComponents.actual,
        priceToBeSummarized.variablePriceComponents.actual
      ),
      max: summarizeArrayValues(
        summarizedPrice.variablePriceComponents.max,
        priceToBeSummarized.variablePriceComponents.max
      ),
    },
    fixedPriceComponents: {
      actual: summarizeArrayValues(
        summarizedPrice.fixedPriceComponents.actual,
        priceToBeSummarized.fixedPriceComponents.actual
      ),
      max: summarizeArrayValues(
        summarizedPrice.fixedPriceComponents.max,
        priceToBeSummarized.fixedPriceComponents.max
      ),
    },
    absoluteFixedPriceComponents: {
      actual: summarizeArrayValues(
        summarizedPrice.absoluteFixedPriceComponents.actual,
        priceToBeSummarized.absoluteFixedPriceComponents.actual
      ),
      max: summarizeArrayValues(
        summarizedPrice.absoluteFixedPriceComponents.max,
        priceToBeSummarized.absoluteFixedPriceComponents.max
      ),
    },
    absoluteVariablePriceComponents: {
      actual: summarizeArrayValues(
        summarizedPrice.absoluteVariablePriceComponents.actual,
        priceToBeSummarized.absoluteVariablePriceComponents.actual
      ),
      max: summarizeArrayValues(
        summarizedPrice.absoluteVariablePriceComponents.max,
        priceToBeSummarized.absoluteVariablePriceComponents.max
      ),
    },
  };
};

export default toSummarizedObjectPrice;
