import { DomainEventDispatcher } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';
import { v4 as uuidv4 } from 'uuid';

import { MaintenanceObjectRepository } from '../maintenance-object';
import {
  MaintenanceOffer,
  MaintenanceOfferAddress,
  MaintenanceOfferRepository,
  MaintenanceOfferStatusEnum,
  MaintenanceOrderClosed,
} from '../maintenance-offer';
import {
  MaintenancePrice,
  MaintenancePriceService,
  MaintenancePriceStrategyName,
} from '../maintenance-price';
import {
  AccessMustBeSetRule,
  CanNotBeInRequestRule,
  CanNotChangeOwnerRule,
  ManufacturerMustExistRule,
  MustBeCustomTypeRule,
  MustBeLadderTypeRule,
  MustBeMaintenanceTeamRule,
  MustBeOwnerRule,
  MustExistRule,
} from '../rules';
import { MaintenanceTeamMemberMustNotCancelRequest } from '../rules';
import { MustBeInStatus } from '../rules';
import { OwnerMustOnlyCancelRequest } from '../rules';
import { UserMayNotSetInternalNote } from '../rules';
import { PushLockSystemCantBeOnRoofRule } from '../rules/maintenance-object/fallprotection-systems/push-lock-system-cant-be-on-roof-rule';
import { PushLockSystemMandatoryValuesCantBeZeroRule } from '../rules/maintenance-object/fallprotection-systems/push-lock-system-mandatory-values-cant-be-zero-rule';
import { PushLockSystemManufacturerMustBeAbsRule } from '../rules/maintenance-object/fallprotection-systems/push-lock-system-manufacturer-must-be-abs-rule';
import { PushLockSystemValuesMustBePositiveRule } from '../rules/maintenance-object/fallprotection-systems/push-lock-system-values-must-be-positive-rule';
import { RopeSystemMandatoryValuesCantBeZeroRule } from '../rules/maintenance-object/fallprotection-systems/rope-system-mandatory-values-cant-be-zero-rule';
import { RopeSystemValuesMustBePositiveRule } from '../rules/maintenance-object/fallprotection-systems/rope-system-values-must-be-positive-rule';
import { SingleAnchorsSystemValuesMustBeGreaterZeroRule } from '../rules/maintenance-object/fallprotection-systems/single-anchors-system-values-must-be-positive-rule';
import {
  MaintenanceRequestCreated,
  MaintenanceRequestStatusChanged,
} from './events';
import {
  MaintenanceRequest,
  MaintenanceRequestUpdate,
  RequestStatusEnum,
} from './maintenance-request';
import { MaintenanceRequestRepository } from './maintenance-request.repository';

export class MaintenanceRequestService {
  constructor(
    protected readonly eventDispatcher: DomainEventDispatcher,
    protected readonly maintenanceRequestRepository: MaintenanceRequestRepository,
    protected readonly maintenanceObjectRepository: MaintenanceObjectRepository,
    protected readonly maintenanceOfferRepository: MaintenanceOfferRepository,
    protected readonly maintenancePriceService: MaintenancePriceService
  ) {
    this.eventDispatcher.register(MaintenanceOrderClosed, async (event) => {
      await this.close(event.user, event.maintenanceOrder.requestId);
    });
  }

  public async add(
    user: User,
    newMaintenanceRequest: MaintenanceRequest,
    address: MaintenanceOfferAddress,
    absCustomerNumber?: string
  ): Promise<MaintenanceRequest> {
    const maintenanceRequest = newMaintenanceRequest.copyWith({
      ownerId: user.id,
    });

    await new UserMayNotSetInternalNote(user).apply(maintenanceRequest);

    await Promise.all(
      maintenanceRequest.objectIds.map((objectId) =>
        new MustExistRule(this.maintenanceObjectRepository)
          .and(new MustBeOwnerRule(maintenanceRequest.ownerId))
          .and(new CanNotBeInRequestRule())
          .and(
            new ManufacturerMustExistRule()
              .or(new MustBeCustomTypeRule())
              .or(new MustBeLadderTypeRule())
          )
          .and(new PushLockSystemCantBeOnRoofRule())
          .and(new PushLockSystemMandatoryValuesCantBeZeroRule())
          .and(new PushLockSystemManufacturerMustBeAbsRule())
          .and(new PushLockSystemValuesMustBePositiveRule())
          .and(new RopeSystemMandatoryValuesCantBeZeroRule())
          .and(new RopeSystemValuesMustBePositiveRule())
          .and(new SingleAnchorsSystemValuesMustBeGreaterZeroRule())
          .and(new AccessMustBeSetRule())
          .apply(objectId)
      )
    );

    const request = await this.maintenanceRequestRepository.create(
      maintenanceRequest.copyWith({
        id: uuidv4(),
        status: 'requested',
      })
    );

    await this.eventDispatcher.dispatch(
      new MaintenanceRequestCreated(user, request, address, absCustomerNumber)
    );

    return request;
  }

  public async cancel(user: User, id: string) {
    return this.update(user, { id, status: RequestStatusEnum.CANCELED });
  }

  public async close(user: User, id: string) {
    return this.update(user, { id, status: RequestStatusEnum.CLOSED });
  }

  public async update(
    user: User,
    maintenanceRequest: MaintenanceRequestUpdate
  ): Promise<MaintenanceRequest> {
    const oldMaintenanceRequest = await new MustExistRule(
      this.maintenanceRequestRepository
    ).apply(maintenanceRequest.id);

    const ownerOnlyCancels = new MustBeOwnerRule<
      MaintenanceRequestUpdate & { ownerId: string }
    >(user.id).and(new OwnerMustOnlyCancelRequest());

    const maintenanceTeamDoesNotCancel = new MustBeMaintenanceTeamRule<
      MaintenanceRequestUpdate & { ownerId: string }
    >(user).and(new MaintenanceTeamMemberMustNotCancelRequest());

    await maintenanceTeamDoesNotCancel
      .or(ownerOnlyCancels)
      .and(new UserMayNotSetInternalNote(user))
      .and(new CanNotChangeOwnerRule(oldMaintenanceRequest.ownerId))
      .apply({ ...maintenanceRequest, ownerId: oldMaintenanceRequest.ownerId });

    const offers = await this.maintenanceOfferRepository.getAllByRequestId(
      maintenanceRequest.id
    );

    const isClosed = maintenanceRequest.status === RequestStatusEnum.CLOSED;
    const isCanceled = maintenanceRequest.status === RequestStatusEnum.CANCELED;

    const currentOffer = offers[0];
    const statusChanged =
      maintenanceRequest.status &&
      (currentOffer?.decision.status !== MaintenanceOfferStatusEnum.DRAFT ||
        oldMaintenanceRequest.status !== maintenanceRequest.status);

    const shouldCancel = statusChanged && isCanceled;
    const shouldClose = statusChanged && isClosed;

    if (shouldCancel) {
      await new MustBeInStatus(
        user,
        this.maintenanceOfferRepository,
        RequestStatusEnum.VALIDATING,
        RequestStatusEnum.REQUESTED
      ).apply(oldMaintenanceRequest);
    } else if (shouldClose) {
      await new MustBeMaintenanceTeamRule(user).apply(oldMaintenanceRequest);
    }

    const updatedMaintenanceRequest = await this.updatePrivileged(
      maintenanceRequest
    );

    if (statusChanged) {
      await this.eventDispatcher.dispatch(
        new MaintenanceRequestStatusChanged(
          user,
          updatedMaintenanceRequest,
          currentOffer
        )
      );
    }

    return updatedMaintenanceRequest;
  }

  public async getAll(user: User): Promise<MaintenanceRequest[]> {
    await new MustBeMaintenanceTeamRule(user).apply(undefined);
    return this.maintenanceRequestRepository.getAll();
  }

  public async getById(user: User, id: string): Promise<MaintenanceRequest> {
    await new MustExistRule(this.maintenanceRequestRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    return this.maintenanceRequestRepository.getById(id);
  }

  public async getAllAccessibleBy(user: User): Promise<MaintenanceRequest[]> {
    return user.isMaintenanceTeamMemberAdmin
      ? this.maintenanceRequestRepository.getAll()
      : this.maintenanceRequestRepository.getAllByUser(user.id);
  }

  public async calculatePriceFor(
    user: User,
    objectIds: string[],
    strategy: MaintenancePriceStrategyName = 'default'
  ): Promise<MaintenancePrice> {
    await Promise.all(
      objectIds.map((objectId) =>
        new MustExistRule(this.maintenanceObjectRepository)
          .and(
            new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user))
          )
          .apply(objectId)
      )
    );

    return this.maintenancePriceService.calculatePrice(
      user,
      objectIds,
      strategy
    );
  }

  public async getOffersFor(
    user: User,
    id: string
  ): Promise<MaintenanceOffer[]> {
    await new MustExistRule(this.maintenanceRequestRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    const offers = await this.maintenanceOfferRepository.getAllByRequestId(id);

    for (const offer of offers) {
      await new MustBeOwnerRule(user.id)
        .or(new MustBeMaintenanceTeamRule(user))
        .apply(offer);
    }

    return offers;
  }

  private async updatePrivileged(
    maintenanceRequest: Pick<MaintenanceRequest, 'id'> &
      Partial<MaintenanceRequest>
  ): Promise<MaintenanceRequest> {
    await new MustExistRule(this.maintenanceRequestRepository).apply(
      maintenanceRequest.id
    );

    return this.maintenanceRequestRepository.update(maintenanceRequest);
  }
}
