import { Entity } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import { DurationInMinutes } from '../maintenance-offer';
import { MaintenanceDurationPolicy } from './maintenance-duration-policy';
import { MaintenanceType } from './types';

export class MaintenanceObject<
  T extends MaintenanceType = MaintenanceType
> extends Entity {
  public readonly id: string;
  public readonly name: string;
  public readonly ownerId: string;
  public readonly created: Date;
  public readonly description?: string | null;
  public readonly manufacturer?: string | null;
  public readonly frequency?: number | null;
  public readonly buildingId?: string | null;
  public readonly type: T;
  public readonly dueDate?: Date | null;

  public readonly currentRequestId?: string | null;
  public readonly orderIds?: string[] | null;

  constructor(config: {
    id: string;
    name: string;
    created: Date;
    description?: string | null;
    manufacturer?: string | null;
    frequency?: number | null;
    buildingId?: string | null;
    type: T;
    dueDate?: Date | null;
    currentRequestId?: string | null;
    orderIds?: string[] | null;
    ownerId: string;
  }) {
    super();
    this.id = config.id;
    this.name = config.name;
    this.created = config.created;
    this.description = config.description;
    this.manufacturer = config.manufacturer;
    this.frequency = config.frequency;
    this.buildingId = config.buildingId;
    this.type = config.type;
    this.dueDate = config.dueDate;
    this.currentRequestId = config.currentRequestId;
    this.orderIds = config.orderIds;
    this.ownerId = config.ownerId;
  }

  public async durationInMinutes(
    user: User,
    policy: MaintenanceDurationPolicy
  ): Promise<DurationInMinutes | null> {
    return policy.calculateDurationInMinutes(user, this);
  }
}

export enum ProvidedServicesTypesEnum {
  FALL_PROTECTION = 'fall_protection',
  PERSONAL_PROTECTION_EQUIPMENT = 'personal_protection_equipment',
  LIGHTNING_CONDUCTOR = 'lightning_conductor',
  SMOKE_HEAT_EXTRACTION_SYSTEM = 'heat_extraction',
  LADDER = 'ladder',
  MOBILE_LADDER = 'mobile_ladder',
  PV_SYSTEM = 'pv_system',
  CONDITIONER = 'conditioner',
  WINDOW = 'window',
}

export enum PPEType {
  SLING = 'Anschlagband / Bandschlinge',
  BELT = 'Auffanggurt',
  HELMET = 'Comfort Helmet',
  DOME_COMPLETE = 'DomeWeb (komplett)',
  DOME_FABRIC_THREAD = 'DomeWeb (Gewebefaden)',
  DOME_FIX = 'DomeFix',
  DAMPER = 'Falldämpfer',
  GLIDER = 'Gleiter',
  CLIMBING_PROTECTION_GLIDER = 'Steigschutzläufer',
  RESCUE_DESCENDER = 'Rettungshub- und Abseilgerät',
  PPE_SET = 'PSAgA-Set',
  TEMP_ROPE = 'Verbindungsmittel/mitl. Auffanggerät/temp. Seilsystem',
}

export const getPPETypeKeyByValue = (value: string) => {
  const indexOfS = Object.values(PPEType).indexOf(value as unknown as PPEType);
  return Object.keys(PPEType)[indexOfS];
};

export type ProvidedServicesTypes = `${ProvidedServicesTypesEnum}`;
export type PPETypes = `${PPEType}`;
