import React from 'react';

import { UnitInputField } from '../../index';

const RopeSystem: React.FC<React.PropsWithChildren<{ index: number }>> = ({
  index,
}) => {
  return (
    <>
      <UnitInputField
        name={`maintenanceObjects.${index}.system.ropeLength`}
        label="Seillänge"
        unit="m"
        isLabelBold={false}
        labelWidthTailwindClass="w-80"
        allowDecimals
      />
      <UnitInputField
        name={`maintenanceObjects.${index}.system.systemAnchors`}
        label="Systemstützen"
        unit="Stück"
        isLabelBold={false}
        labelWidthTailwindClass="w-80"
      />
      <UnitInputField
        name={`maintenanceObjects.${index}.system.singleAnchors`}
        label="Einzelanschlagpunkte (ergänzend)"
        unit="Stück"
        isLabelBold={false}
        labelWidthTailwindClass="w-80"
      />
    </>
  );
};

export default RopeSystem;
