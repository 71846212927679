import { format } from 'date-fns';
import React from 'react';

import { CustomerOrMaintenanceTeamOfferDto } from '@wartungshelden/shared-types';

interface ExecutionDateProps {
  offer: CustomerOrMaintenanceTeamOfferDto;
}

const ExecutionDate: React.FC<React.PropsWithChildren<ExecutionDateProps>> = ({
  offer,
}) => (
  <div className="flex">
    <p className="mb-3 mr-2">Ausführungstermin:</p>
    {offer.suggestedDate ? (
      <div className="border-2 rounded-lg px-4 py-2 bg-white border-gray">
        {format(offer.suggestedDate, 'dd.MM.yyyy HH:mm')}
      </div>
    ) : (
      <div>(wird im Anschluss an die Beauftragung festgelegt)</div>
    )}
  </div>
);

export default ExecutionDate;
