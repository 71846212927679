import {
  OrderContactPreference,
  OrderStatus,
} from '@warthungshelden/domain/maintenance-administration';

import { AppointmentDto } from './calendar';
import { InstallerDto } from './installer';

export interface GeoCoordinateDto {
  readonly latitude: number;
  readonly longitude: number;
  readonly isValid: boolean;
}

export interface GeoDescriptionDto {
  readonly address: string;
}

export interface DistanceDto {
  readonly minutes?: number | null;
  readonly kilometers?: number | null;
}

export interface GetDistanceMatrixDto {
  readonly origin: (GeoCoordinateDto | GeoDescriptionDto)[];
  readonly destination: (GeoCoordinateDto | GeoDescriptionDto)[];
}

export interface DistanceMatrixDto {
  readonly matrix: DistanceDto[][];
}

export interface OpenMaintenanceDto {
  readonly orderId?: string;
  readonly installerId?: string;
  readonly zipcode?: string;
  readonly duration?: number;
  readonly deliveryDate?: Date;
  readonly note?: string;
}

export interface CreateRouteDto {
  readonly orders: string[];
  readonly installers: InstallerDto[];
  readonly from: Date;
  readonly to: Date;
  readonly considerCalendar?: boolean;
  readonly preferABSInstallers?: boolean;
}

export interface ChangeRouteInstallerDto {
  readonly route: RouteDto;
  readonly installer: InstallerDto;
}

export interface JobDto {
  zipCode: string;
  duration: number;
  coordinates: GeoCoordinateDto;
  city?: string;
  orderId: string;
  status: OrderStatus;
  contactPreference?: OrderContactPreference;
}

export interface InstallerCandidateDto {
  distanceToFirstJob: DistanceDto;
  installerId: string;
  distanceFromLastJob: DistanceDto;
  appointments: AppointmentDto[];
}

export interface RouteDto {
  id: string;
  date: Date;
  totalJobDistance: DistanceDto;
  jobDistances: DistanceDto[];
  duration: number;
  jobs: JobDto[];
  installerCandidates: InstallerCandidateDto[];
}
