import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';

import { BillingAddressDto } from '@wartungshelden/shared-types';

import { Button, Card, PageHeader } from '../../../../components';
import AddressForm from '../../../../components/Address/AddressForm';
import AddressReadOnly from '../../../../components/Address/AddressReadOnly';
import { AddressFormikValues } from '../../../../components/Address/types/AddressFormikValues';
import CheckBoxField from '../../../../components/Basics/Inputs/CheckBox/CheckBoxField';
import LoadingSpinner from '../../../../components/Basics/Loaders/LoadingSpinner';
import { InfoMessageBox } from '../../../../components/Basics/MessageBox';
import TextWithLabel from '../../../../components/Basics/TextWithLabel';
import { useOwnCustomer } from '../../../../services/api/customer/customer-api';
import AddressModal from '../components/AddressModal';

const SelectAddressStep: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const { isValid, values } = useFormikContext<AddressFormikValues>();

  const { data: customer, isLoading: loadingCustomer } = useOwnCustomer();

  const [initiallyValid] = useState(isValid);

  const address: BillingAddressDto = {
    address: {
      addressLine1: values.addressLine,
      addressLine2: '',
      postalCode: values.postalCode,
      city: values.city,
      countryISO: values.country,
    },
    company: values.company,
    firstName: values.firstName || '',
    lastName: values.lastName || '',
  };

  const navigateToUserProfile = () => {
    window.open('/profile', '_blank');
  };

  return (
    <Card>
      <div className="flex flex-1 h-full flex-col overflow-y-auto">
        <PageHeader>Wir benötigen Deine Adresse</PageHeader>
        {initiallyValid ? (
          <>
            <AddressReadOnly
              addresses={[address]}
              phoneNumber={values.phone}
              email={values.email}
            />
            <div className="mt-4">
              <Button
                onClick={() => setIsCustomerModalOpen(true)}
                label="Angaben bearbeiten"
                className="primary-button small-button"
              />
            </div>
            <div className="mt-10">
              {!customer?.absCustomerNumber ? (
                <InfoMessageBox
                  isCentered={false}
                  customIcon={<FaRegLightbulb size={35} />}
                  headline="ABS-Kundennummer"
                  label="Falls Du bereits ABS-Kunde bist, kannst Du im Nutzerprofil Deine ABS-Kundennummer ergänzen."
                >
                  <Button
                    label="Zum Nutzerprofil"
                    className="primary-button small-button"
                    onClick={navigateToUserProfile}
                  />
                </InfoMessageBox>
              ) : (
                <>
                  <div className="mb-2 font-bold">ABS-Kunde</div>
                  <TextWithLabel label="ABS-Kundennummer:">
                    {customer.absCustomerNumber}
                  </TextWithLabel>
                  <div className="mt-4">
                    <Button
                      label="Zum Nutzerprofil"
                      className="primary-button small-button"
                      onClick={navigateToUserProfile}
                    />
                  </div>
                </>
              )}
            </div>
            <AddressModal
              isOpen={isCustomerModalOpen}
              setIsOpen={setIsCustomerModalOpen}
            />
          </>
        ) : (
          <div className="overflow-y-scroll">
            <AddressForm />
            <div className="mt-4 mb-2">
              <CheckBoxField
                name="hasSaveOnCustomerChecked"
                label="Adresse und Kontaktdaten im Konto speichern"
              />
            </div>
          </div>
        )}
        <LoadingSpinner isLoading={loadingCustomer} />
      </div>
    </Card>
  );
};

export default SelectAddressStep;
