import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

import { InstallerDto, RouteDto } from '@wartungshelden/shared-types';

import { formatToHHMM } from '../../../../../../services/timeDateService';

interface JobDetailsProps {
  route: RouteDto;
  mainInstaller: InstallerDto;
  textSize?: 'text-xl' | 'text-lg' | 'text-sm';
  minWidthInRem?: '24' | '16';
  showInstallerAsHeadline?: boolean;
  delay?: {
    totalDuration: number;
    kilometers: number;
  };
  jobChangeLog?: string[];
}

const minWidthToTailwindMap = {
  '24': 'min-w-[24rem]',
  '16': 'min-w-[16rem]',
};

const JobDetails: React.FC<JobDetailsProps> = ({
  route,
  mainInstaller,
  textSize,
  minWidthInRem,
  showInstallerAsHeadline,
  delay,
  jobChangeLog,
}) => {
  return (
    <div className="flex flex-col">
      {jobChangeLog && (
        <div className="mb-8 text-xl">
          {jobChangeLog.map((change) => (
            <div>{change}</div>
          ))}
        </div>
      )}

      {showInstallerAsHeadline && (
        <div
          className={`flex ${textSize} font-bold mb-4`}
          data-cy="installer-name"
        >
          {showInstallerAsHeadline && mainInstaller?.name}
        </div>
      )}

      <div
        className={`flex ${
          minWidthInRem ? minWidthToTailwindMap[minWidthInRem] : ''
        } justify-between`}
      >
        <div className={`flex flex-col ${textSize}`}>
          {!showInstallerAsHeadline && (
            <div data-cy="installer-name">Monteur:</div>
          )}
          <div>Arbeitszeit:</div>
          <div>Gesamtdauer:</div>
          <div>Luftlinie:</div>
        </div>
        <div className={`flex flex-col ${textSize}`}>
          {!showInstallerAsHeadline && (
            <div>
              <strong>{mainInstaller?.name}</strong>
            </div>
          )}
          <div className="flex items-center">
            <span>
              <strong>{route.duration} h </strong>
              {Math.ceil(route.duration / 8) > 1
                ? `(${Math.ceil(route.duration / 8)} Tage)`
                : ''}
            </span>
            {route.duration > 8 && (
              <FaExclamationTriangle className="text-orange ml-2" />
            )}
          </div>
          <div>
            <strong>
              {formatToHHMM(
                route.duration + (route.totalJobDistance.minutes ?? 0) / 60
              )}{' '}
              h
            </strong>
            {delay?.totalDuration && (
              <span
                className={`font-bold ${
                  delay.totalDuration < 0 ? 'text-green' : 'text-red'
                }`}
              >
                {delay.totalDuration < 0 ? ' - ' : ' + '}
                {formatToHHMM(Math.abs(delay.totalDuration))} h
              </span>
            )}
          </div>
          <div>
            <strong>
              {route.totalJobDistance?.kilometers
                ? route.totalJobDistance.kilometers.toFixed(1)
                : '/'}{' '}
              km
            </strong>
            {delay?.kilometers && (
              <span
                className={`font-bold ${
                  delay.totalDuration < 0 ? 'text-green' : 'text-red'
                }`}
              >
                {delay.kilometers < 0 ? ' ' : ' + '}
                {delay.kilometers.toFixed(1)} km
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
JobDetails.defaultProps = {
  textSize: 'text-xl',
  minWidthInRem: '24',
  showInstallerAsHeadline: true,
  delay: undefined,
  jobChangeLog: undefined,
};
export default JobDetails;
