import { useFormikContext } from 'formik';
import Tooltip from 'rc-tooltip';
import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

import {
  FallProtectionDto,
  PriceInformationObjectDto,
} from '@wartungshelden/shared-types';

import { UnitInputField } from '../../../../../components';
import RadioButtonField from '../../../../../components/Basics/Inputs/RadioButtonField';
import Toggle from '../../../../../components/Basics/Inputs/Toggle';
import { FALL_PROTECTION_SYSTEM_LOCATION } from '../../../../../constants/MaintenanceConstants';
import { isRopeSystemDto } from '../../../../../guards/isFallProtectionSystem';
import { isFallProtectionDto } from '../../../../../guards/isMaintenanceType';
import ManufacturerSelector from './ManufacturerSelector';

const FallProtectionSelector = () => {
  const { values, setFieldValue } =
    useFormikContext<PriceInformationObjectDto>();

  const fallProtectionSystem = (values.object.type as FallProtectionDto).system;

  const shouldShowSplitWarning =
    isFallProtectionDto(values.object.type) &&
    isRopeSystemDto(values.object.type.system) &&
    values.object.type.system.singleAnchors > 9;

  const returnFallprotection = () => {
    if (fallProtectionSystem?.system === 'rope') {
      return (
        <>
          <div className="mx-8">
            <UnitInputField
              name="object.type.system.ropeLength"
              label="Seillänge"
              unit="m"
              isLabelBold={false}
              labelWidthTailwindClass="w-80"
              allowDecimals
              shouldSelectOnFocus
            />
            <UnitInputField
              name="object.type.system.systemAnchors"
              label="Systemstützen"
              unit="Stück"
              isLabelBold={false}
              shouldSelectOnFocus
              labelWidthTailwindClass="w-80"
            />
          </div>
          <div
            className={`${
              shouldShowSplitWarning ? 'bg-blue-abs/[0.30] ' : ''
            }py-4 px-6 mx-2`}
          >
            <UnitInputField
              name="object.type.system.singleAnchors"
              label="Einzelanschlagpunkte (ergänzend)"
              unit="Stück"
              isLabelBold={false}
              shouldSelectOnFocus
              labelWidthTailwindClass="w-80"
              help={
                <Tooltip
                  placement="right"
                  trigger="hover"
                  overlay={`Ab einer Anzahl von 10 oder mehr ergänzenden
                Einzelanschlagpunkten (EAP) wird automatisch eine separate
                Position erstellt. Dies hängt u.a. mit den Preisen zusammen, die
                im Hintergrund berechnet werden. Zudem wird so ein einheitliches
                Vorgehen gefördert.`}
                  overlayInnerStyle={{
                    padding: 12,
                    maxWidth: '18rem',
                  }}
                >
                  <FaQuestionCircle
                    data-tip
                    data-for="help-tooltip"
                    className="text-black-abs"
                  />
                </Tooltip>
              }
            />

            {shouldShowSplitWarning && (
              <div className="mt-8">
                <div>
                  <span className="text-blue-abs font-bold">Hinweis</span>: Die
                  Menge der EAP ist zu groß, um sie als Teil eines Seilsystems
                  auszuführen.
                </div>
                <div>
                  Daher wird nach dem Hinzufügen automatisch eine separate
                  Position für EAP erstellt.
                </div>
              </div>
            )}
          </div>

          <div className="mx-8">
            <UnitInputField
              name="ropeSystemProtectionGliders"
              label="Gleiter"
              unit="Stück"
              isLabelBold={false}
              shouldSelectOnFocus
              labelWidthTailwindClass="w-80"
            />
          </div>
        </>
      );
    }

    if (fallProtectionSystem?.system === 'push_lock') {
      return (
        <div className="px-8">
          <UnitInputField
            name="object.type.system.anchors"
            label="Lock-I"
            unit="Stück"
            isLabelBold={false}
            shouldSelectOnFocus
            labelWidthTailwindClass="w-80"
          />
          <UnitInputField
            name="object.type.system.sleeves"
            label="Lock-II"
            unit="Stück"
            isLabelBold={false}
            shouldSelectOnFocus
            labelWidthTailwindClass="w-80"
          />
        </div>
      );
    }

    return (
      <div className="px-8">
        <UnitInputField
          name="object.type.system.singleAnchors"
          label="Einzelanschlagpunkte"
          unit="Stück"
          shouldSelectOnFocus
          labelWidthTailwindClass="w-80"
          isLabelBold={false}
        />
      </div>
    );
  };

  const sailSystemPositions = [
    FALL_PROTECTION_SYSTEM_LOCATION.ROOF,
    FALL_PROTECTION_SYSTEM_LOCATION.FACADE,
    FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD,
  ];

  const hasManufacturer = () => fallProtectionSystem?.system !== 'push_lock';
  const hasPosition = () => fallProtectionSystem?.system === 'rope';
  const hasEntry = () => fallProtectionSystem?.system === 'push_lock';
  const hasBuildingEntry = () => fallProtectionSystem?.system !== 'push_lock';

  return (
    <div className="space-y-4 ">
      {hasManufacturer() && <ManufacturerSelector />}

      <div className="font-bold px-8">Bestandteile*</div>
      {returnFallprotection()}

      {hasPosition() && (
        <div className="flex flex-row items-start px-8">
          <div className="font-bold w-80 mr-4">Position*</div>
          <div className="flex flex-1 flex-col space-y-2">
            {sailSystemPositions.map((locationOption) => (
              <RadioButtonField
                key={locationOption.label}
                label={locationOption.label}
                name="object.type.location"
                value={locationOption.value}
              />
            ))}
          </div>
        </div>
      )}

      <div className="px-8">
        <div className="font-bold">Gebäude*</div>
        {hasEntry() && (
          <div className="flex flex-row items-start">
            <div className="w-80 mr-4">Zugang</div>
            <div className="flex flex-1 flex-col space-y-2">
              <RadioButtonField
                label="einfach (Industrie, Büro, Schule)"
                name="building.buildingType"
                value="Bürogebäude"
              />
              <RadioButtonField
                label="schwer (Wohngebäude, Hotel)"
                name="building.buildingType"
                value="Wohngebäude"
              />
            </div>
          </div>
        )}

        {hasBuildingEntry() && (
          <div className="pt-4">
            <Toggle
              label="Öffentliches Gebäude (Körperschaft öffentlichen Rechts), z.B.
                      Städte, Gemeinden, Kommunen, Wohnungsbaugesellschaften"
              name="building.isPublic"
              checked={!!values.building?.isPublic}
              onChange={() => {
                setFieldValue('building.isPublic', !values.building?.isPublic);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FallProtectionSelector;
