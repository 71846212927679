import { useFormikContext } from 'formik';
import React from 'react';

import DateSelectField, { HighlightDates } from './DateSelectField';

interface DateSelectFieldFormikProps {
  name: string;
  onlyMonths?: boolean;
  onMonthChange?: (month: Date) => void;
  onYearChange?: (year: Date) => void;
  highlightDates?: Array<HighlightDates | Date>;
  disableWeekend?: boolean;
}

const DateSelectFieldFormik: React.FC<DateSelectFieldFormikProps> = ({
  name,
  onlyMonths,
  onMonthChange,
  onYearChange,
  highlightDates,
  disableWeekend,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <DateSelectField
      onChange={(date) => setFieldValue(name, date)}
      selected={values[name]}
      onlyMonths={onlyMonths}
      onMonthChange={onMonthChange}
      onYearChange={onYearChange}
      highlightDates={highlightDates}
      disableWeekend={disableWeekend}
    />
  );
};

DateSelectFieldFormik.defaultProps = {
  onlyMonths: false,
  onMonthChange: undefined,
  onYearChange: undefined,
  highlightDates: undefined,
  disableWeekend: false,
};

export default DateSelectFieldFormik;
