import React from 'react';

const FieldError: React.FC<
  React.PropsWithChildren<{ message?: string; visible: boolean }>
> = ({ message, visible }) =>
  visible ? <div className="text-red">{message}</div> : null;

FieldError.defaultProps = {
  message: undefined,
};

export default FieldError;
