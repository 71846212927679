import {
  ClimbingProtectionDto,
  FallProtectionDto,
  GroundStairsDto,
  LadderDto,
  MaintenanceTypeDto,
  OtherTypeDto,
  OverpassDto,
  PersonalProtectionEquipmentDto,
  RailSystemDto,
  RailingDto,
  StairLadderDto,
} from '@wartungshelden/shared-types';

export const isFallProtectionDto = (
  value?: MaintenanceTypeDto
): value is FallProtectionDto => {
  return value !== undefined && value.type === 'fall_protection';
};

export const isRailSystemDto = (
  value?: MaintenanceTypeDto
): value is RailSystemDto => {
  return value !== undefined && value.type === 'rail_system';
};

export const isClimbingProtectionDto = (
  value?: MaintenanceTypeDto
): value is ClimbingProtectionDto => {
  return value !== undefined && value.type === 'climbing_protection';
};

export const isLadderDto = (value?: MaintenanceTypeDto): value is LadderDto => {
  return value !== undefined && value.type === 'ladder';
};

export const isPPEDto = (
  value?: MaintenanceTypeDto
): value is PersonalProtectionEquipmentDto => {
  return value !== undefined && value.type === 'personal_protection_equipment';
};

export const isRailingDto = (
  value?: MaintenanceTypeDto
): value is RailingDto => {
  return value !== undefined && value.type === 'railing';
};

export const isStairLadderDto = (
  value?: MaintenanceTypeDto
): value is StairLadderDto => {
  return value !== undefined && value.type === 'stair_ladder';
};

export const isOverpassDto = (
  value?: MaintenanceTypeDto
): value is OverpassDto => {
  return value !== undefined && value.type === 'overpass';
};

export const isGroundStairsDto = (
  value?: MaintenanceTypeDto
): value is GroundStairsDto => {
  return value !== undefined && value.type === 'ground_stairs';
};

export const isOtherTypeDto = (
  value?: MaintenanceTypeDto
): value is OtherTypeDto => {
  return value !== undefined && value.type === 'other_type';
};
