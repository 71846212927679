import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIsSignedIn } from '../../services/authService';
import AdminLoginForm from './components/AdminLoginForm';
import UserLoginForm from './components/UserLoginForm';

const LoginPage = ({ isAdminLogin }: { isAdminLogin?: boolean }) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { isSignedIn } = useIsSignedIn();

  const redirectedFrom = (state as any)?.from;

  const navigateToApp = () => {
    const to =
      redirectedFrom && redirectedFrom !== pathname ? redirectedFrom : '/';

    navigate(to);
  };

  useEffect(() => {
    if (isSignedIn) {
      navigateToApp();
    }
  }, [isSignedIn]);

  if (isAdminLogin) {
    return <AdminLoginForm />;
  }

  return <UserLoginForm />;
};

LoginPage.defaultProps = {
  isAdminLogin: false,
};

export default LoginPage;
