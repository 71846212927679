import React from 'react';

import { MaintenancePriceDto } from '@wartungshelden/shared-types';

import formatEuros from '../../../../../services/format';

interface TotalPricePositionProps {
  price: MaintenancePriceDto;
  hasDocu?: boolean;
}

const TotalPricePosition: React.FC<TotalPricePositionProps> = ({
  price,
  hasDocu,
}) => {
  const showDocuHeadline = hasDocu !== undefined;

  return (
    <>
      {showDocuHeadline && (
        <div className="font-bold">
          Preis{' '}
          {hasDocu ? (
            <span className="underline">mit</span>
          ) : (
            <span className="underline">ohne</span>
          )}{' '}
          Doku
        </div>
      )}
      <div className="flex mt-2">
        {Boolean(price.fixedTotal && price.fixedTotal > 0) && (
          <>
            <div className="whitespace-nowrap">Grundkosten</div>
            <div className="w-full text-right ml-2">
              {formatEuros(price.fixedTotal)}
            </div>
          </>
        )}
      </div>
      <div
        className="flex grow font-bold border-t-4 border-black-abs mt-10"
        data-cy={
          !showDocuHeadline || hasDocu
            ? 'total-price-position-with-docu'
            : 'total-price-position-without-docu'
        }
      >
        <div className="whitespace-nowrap truncate w-full">
          Preis gesamt (gerundet):
        </div>
        <div className="text-right ml-2" data-cy="total-price">
          {formatEuros(price.netTotal)}
        </div>
      </div>
    </>
  );
};

TotalPricePosition.defaultProps = {
  hasDocu: undefined,
};

export default TotalPricePosition;
