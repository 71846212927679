import React from 'react';
import { FaFilePdf } from 'react-icons/fa';

type DocumentPreviewProps = {
  file: File;
};

const FileThumbnailComponent: React.FC<
  React.PropsWithChildren<DocumentPreviewProps>
> = ({ file }) => {
  return (
    <div className="w-full h-full flex flex-1 items-center justify-center">
      {file.type === 'application/pdf' ? (
        <FaFilePdf className="h-2/3 w-2/3" />
      ) : (
        <img
          src={file && URL.createObjectURL(file)}
          alt={`bild${file}`}
          className="p-2 w-9/12 object-cover"
        />
      )}
    </div>
  );
};

export default FileThumbnailComponent;
