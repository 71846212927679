import { User } from '@warthungshelden/domain/common';

export interface MaintenanceOfferDocumentAdapter {
  addOfferDocument(
    user: User,
    offerId: string,
    fileName: string,
    file: Buffer,
    userId?: string
  ): Promise<void>;

  getOfferDocument(
    user: User,
    offerId: string,
    fileName: string,
    userId?: string
  ): Promise<Buffer>;

  deleteOfferDocument(
    user: User,
    offerId: string,
    fileName: string,
    userId?: string
  ): Promise<void>;

  addOrderConfirmationDocument(
    user: User,
    orderId: string,
    fileName: string,
    file: Buffer,
    userId?: string
  ): Promise<void>;

  getOrderConfirmationDocument(
    user: User,
    orderId: string,
    fileName: string,
    userId?: string
  ): Promise<Buffer>;

  deleteOrderConfirmationDocument(
    user: User,
    orderId: string,
    fileName: string,
    userId?: string
  ): Promise<void>;
}
