import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { Building } from '../../building';

export class CoordinatesMustBeValid extends DomainRule<Building, Building> {
  protected rule(building: Building): Promise<Building> | Building {
    if (
      !building.address ||
      !building.address.coordinates ||
      building.address.coordinates.isValid
    ) {
      return building;
    }

    throw new DomainRuleNotSatisfiedError(CoordinatesMustBeValid);
  }
}
