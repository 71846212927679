import { Formik } from 'formik';
import React, { useContext } from 'react';
import { FaSignInAlt, FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { PolicyDto, PolicyTypeEnum } from '@wartungshelden/shared-types';

import { SessionContext } from '../../contexts/SessionContext';
import {
  useConsentToPolicy,
  usePolicies,
} from '../../services/api/policies/policy-api';
import Button from '../Basics/Buttons/Button';
import CheckBoxField from '../Basics/Inputs/CheckBox/CheckBoxField';
import LoadingSpinner from '../Basics/Loaders/LoadingSpinner';
import Modal from '../Basics/Modals/Modal';

const AcceptPoliciesValidationSchema = Yup.object().shape({
  accept: Yup.boolean().is([true]),
});

const AcceptPoliciesModal = ({ children }: { children: React.ReactNode }) => {
  const {
    isSignedIn,
    logout,
    isLoading: isLoadingSignIn,
  } = useContext(SessionContext);
  const navigate = useNavigate();

  const { mutateAsync: consent } = useConsentToPolicy();

  const { data: availablePolicies, isLoading: isLoadingPolicies } = usePolicies(
    'latest',
    isSignedIn
  );

  const {
    data: consentedRequiredPolicies,
    isLoading: isLoadingRequiredPolicies,
  } = usePolicies('consent_required', isSignedIn);

  const termsOfService = availablePolicies?.find(
    ({ type }) => type === PolicyTypeEnum.TERMS_OF_SERVICE
  );

  const dataPrivacyPolicy = availablePolicies?.find(
    ({ type }) => type === PolicyTypeEnum.DATA_PROTECTION
  );

  const consentRequired = Boolean(
    consentedRequiredPolicies && consentedRequiredPolicies.length !== 0
  );

  const consentTo = async (policy: PolicyDto) => {
    await consent({ type: policy.type, version: policy.version });
  };

  if (!consentRequired) {
    return <div>{children}</div>;
  }

  return (
    <Modal isOpen={consentRequired} headline="Vereinbarungen">
      <Formik
        initialValues={{ accept: false }}
        validationSchema={AcceptPoliciesValidationSchema}
        onSubmit={async () => {
          if (termsOfService) {
            await consentTo(termsOfService);
          }

          if (dataPrivacyPolicy) {
            await consentTo(dataPrivacyPolicy);
          }

          navigate(0);
        }}
      >
        {({ submitForm, isValid, dirty }) => {
          return (
            <div className="flex flex-col flex-1 bg-white px-8 py-6">
              Um die Registrierung für unseren Service abzuschließen, ist Deine
              Zustimmung zu folgenden Dokumenten erforderlich:
              <div className="py-5">
                <CheckBoxField
                  name="accept"
                  label={
                    <span>
                      Ich bestätige, dass ich mit den{' '}
                      <a
                        className="underline text-blue-abs"
                        href={termsOfService?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Nutzungsbedingungen
                      </a>{' '}
                      von ABS Wartung einverstanden bin und die{' '}
                      <a
                        className="underline text-blue-abs"
                        href={dataPrivacyPolicy?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Datenschutzbestimmungen
                      </a>{' '}
                      gelesen habe.
                    </span>
                  }
                />
              </div>
              <div className="flex flex-row items-center justify-between h-24 px-8">
                <Button
                  label="Abbrechen"
                  icon={<FaTimesCircle />}
                  onClick={logout}
                  className="outline-button"
                />
                <Button
                  disabled={!dirty && !isValid}
                  label="Fortfahren"
                  onClick={submitForm}
                  icon={<FaSignInAlt />}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AcceptPoliciesModal;
