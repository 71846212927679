import { format } from 'date-fns';
import Tooltip from 'rc-tooltip';
import React, { useContext } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import { JobDto } from '@wartungshelden/shared-types';

import { RoutePlanContext } from '../../../../../../contexts/RoutePlanContext';

interface StopProps {
  job: Omit<JobDto, 'coordinates' | 'status'>;
  showDetails: boolean;
  interval?: {
    from: Date;
    to: Date;
  };
  isTransparent?: boolean;
}

const Stop: React.FC<StopProps> = ({
  job,
  showDetails,
  interval,
  isTransparent,
}) => {
  const { getNoteForMaintenance } = useContext(RoutePlanContext);
  const notes = getNoteForMaintenance(job.orderId);

  const days = new Array<number>();
  let remainingDuration = job.duration;

  for (let i = 0; i < Math.ceil(job.duration / 8); i += 1) {
    if (remainingDuration - 8 > 0) {
      days.push(8);
      remainingDuration -= 8;
    } else {
      days.push(remainingDuration);
    }
  }

  return (
    <tr className={`${isTransparent && 'opacity-50'}`}>
      <td className="w-14 text-right align-top">
        <div className="flex justify-center border-2 border-black-abs rounded-md px-2 h-fit">
          {job.duration} h
        </div>
      </td>
      <td className="w-10 text-right h-1">
        <div className="flex flex-col h-full">
          <div className="h-10 w-6 flex items-center justify-center">
            <div className="border-blue-abs border-2 rounded-full h-4 w-4 flex items-center justify-center h-6 w-h ">
              <div className="bg-blue-abs rounded-full h-2 w-2" />
            </div>
          </div>
          <div className="w-6 flex justify-center h-full">
            <div className="h-full min-h-[1rem] w-1 bg-blue-abs pt-1" />
          </div>
        </div>
      </td>
      <td className="h-1">
        <div className="flex flex-col h-full">
          <div className="font-bold">
            {job.zipCode} {job.city}
          </div>
          {showDetails && (
            <div data-cy="stop-details">
              {job.duration > 8 ? (
                <div className="space-x-1">
                  <span>{days.length} Tage, </span>
                  {days.map((day, index) => (
                    <>
                      <span className="border-2 border-black-abs rounded-md px-2 h-fit">
                        {day} h
                      </span>
                      <span>{days.length - 1 === index ? '' : '+'}</span>
                    </>
                  ))}
                </div>
              ) : (
                <div>
                  {interval && (
                    <div>
                      {`${format(interval.from, 'HH:mm')} - ${format(
                        interval.to,
                        'HH:mm'
                      )} Uhr`}
                    </div>
                  )}
                  <div>{job.orderId}</div>
                </div>
              )}
              {notes && (
                <Tooltip
                  placement="right"
                  trigger="hover"
                  overlay={notes}
                  overlayInnerStyle={{
                    padding: 12,
                    maxWidth: '18rem',
                  }}
                >
                  <div className="flex flex-row w-fit items-center">
                    Notiz
                    <FaInfoCircle
                      data-tip
                      data-for="help-tooltip"
                      className="ml-2 text-black-abs"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

Stop.defaultProps = {
  interval: undefined,
  isTransparent: undefined,
};

export default Stop;
