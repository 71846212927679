import { AggregateDomainRuleNotSatisfiedError } from './errors';

export abstract class DomainRule<Input, Output> {
  private _descendants: DomainRule<Output, unknown>[] = [];
  private _alternatives: DomainRule<Input, Output>[] = [];

  public async apply(input: Input): Promise<Output> {
    let result;

    try {
      result = await this.rule(input);
      await this.applyDescendants(result);
    } catch (error) {
      if (this._alternatives.length > 0) {
        result = await this.applyAlternatives(input, error);
      } else {
        throw error;
      }
    }

    return result;
  }

  public and(...descendants: DomainRule<Output, unknown>[]) {
    this._descendants.push(...descendants);
    return this;
  }

  public or(...alternatives: DomainRule<Input, Output>[]) {
    this._alternatives.push(...alternatives);
    return this;
  }

  protected abstract rule(input: Input): Output | Promise<Output>;

  private async applyAlternatives(input: Input, otherError: any) {
    const errors = [otherError];

    for (const rule of this._alternatives) {
      try {
        return await rule.apply(input);
      } catch (error) {
        errors.push(error);
      }
    }

    throw new AggregateDomainRuleNotSatisfiedError(errors);
  }

  private async applyDescendants(result: Output) {
    for (const rule of this._descendants) {
      await rule.apply(result);
    }
  }
}
