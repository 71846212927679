import { useEffect, useState } from 'react';

import supportedBrowsers from '../../../supportedBrowsers';

interface WindowSize {
  width?: number;
  height?: number;
}

const useEnvironmentSupported = () => {
  const isBrowserSupported = !!supportedBrowsers.test(navigator.userAgent);

  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({ height: window.innerHeight, width: window.innerWidth });

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isBrowserSupported, windowSize };
};

export default useEnvironmentSupported;
