import React from 'react';
import { Cell } from 'react-table';

import { MaintenanceRequestDto } from '@wartungshelden/shared-types';

interface CompanyCellProps {
  cell: Cell<MaintenanceRequestDto>;
}

const CompanyCell: React.FC<CompanyCellProps> = ({ cell }) => {
  const company = cell?.value?.address?.company;

  return <div>{company}</div>;
};

export default CompanyCell;
