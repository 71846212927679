import React from 'react';

import { SingleAnchorFallProtectionSystemDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface SingleAnchorPropertiesProps {
  singleAnchorSystem: SingleAnchorFallProtectionSystemDto;
  hasSubprices: boolean;
}

const SingleAnchorProperties: React.FC<SingleAnchorPropertiesProps> = ({
  singleAnchorSystem,
  hasSubprices,
}) => (
  <MaintenanceObjectSummaryRow
    label="Einzelanschlagpunkte"
    value={singleAnchorSystem.singleAnchors}
    unit="Stück"
    hasSubprices={hasSubprices}
  />
);

export default SingleAnchorProperties;
