import React from 'react';

interface StepMenuButtonProps {
  step: number;
  title: string;
  text?: string;
  handleMenuNavigation?: (step: number) => void;
  isStepEnabled: boolean;
  isStepActive: boolean;
  isStepVisible: boolean;
  isInvalid?: boolean;
}

const StepMenuButton: React.FC<
  React.PropsWithChildren<StepMenuButtonProps>
> = ({
  step,
  title,
  text,
  handleMenuNavigation,
  isStepEnabled,
  isStepActive,
  isStepVisible,
  isInvalid,
}) => {
  let activeStyle: string;
  if (isStepActive) {
    activeStyle = 'border-gray-dark border-l-red';
  } else {
    activeStyle = isStepEnabled
      ? 'bg-white border-gray-dark'
      : 'bg-white/50 border-gray-dark/50';
  }

  if (isStepEnabled) {
    return isStepVisible ? (
      <button
        disabled={!isStepEnabled}
        type="button"
        className={`flex flex-col bg-white ${
          isInvalid && 'bg-red-light'
        } h-28 my-4 mr-4 ml-6 p-2 rounded-2xl border-2 border-l-[6px] ${activeStyle} relative`}
        onClick={() => {
          if (!handleMenuNavigation) return;
          handleMenuNavigation(step);
        }}
      >
        <div
          className={`${
            isStepActive
              ? 'bg-red text-white '
              : 'bg-white border-2 border-gray-dark text-black-abs'
          } rounded-[50%] w-10 h-10 absolute top-0 bottom-0 my-auto left-[-1.25rem] flex items-center justify-center font-bold`}
        >
          {step}
        </div>
        <div className={`flex ${isInvalid ? 'text-red' : 'text-black-abs'}`}>
          <div className="flex flex-1 text-2xl font-medium text-left ml-6">
            {title}
          </div>
        </div>

        <div
          className={`ml-6 text-left text-lg ${
            isStepEnabled ? 'text-black' : 'text-black/50'
          }`}
        >
          {text}
        </div>
      </button>
    ) : null;
  }
  return isStepVisible ? (
    <button
      disabled={!isStepEnabled}
      type="button"
      className={`flex flex-col bg-white h-28 my-4 mr-4 ml-6 p-2 rounded-2xl border-2 border-l-[6px] ${activeStyle} relative`}
      onClick={() => {
        if (!handleMenuNavigation) return;
        handleMenuNavigation(step);
      }}
    >
      <div className="bg-gray-light border-2 border-gray-dark text-gray-dark rounded-[50%] w-10 h-10 absolute top-0 bottom-0 my-auto left-[-1.25rem] flex items-center justify-center font-bold">
        {step}
      </div>
      <div className={`flex ${isInvalid ? 'text-red' : 'text-gray-dark'}`}>
        <div className="flex flex-1 text-2xl font-medium text-left ml-6">
          {title}
        </div>
      </div>

      <div className="ml-6 text-left text-lg text-gray-dark">{text}</div>
    </button>
  ) : null;
};

StepMenuButton.defaultProps = {
  text: undefined,
  isInvalid: false,
  handleMenuNavigation: undefined,
};

export default StepMenuButton;
