/* eslint-disable import/no-cycle */
// this is the concept to feed getCellProps, but I did not deep dive into the concept of Getters so far...
// const myGetter = (partialProps: Partial<TableCellProps>) => ({ color: 'blue' });
import React from 'react';
import { FaExclamationCircle, FaExclamationTriangle } from 'react-icons/fa';
import { CellProps } from 'react-table';

import { MaintenanceObjectDto } from '@wartungshelden/shared-types';

import { DueDateStatus } from '../../../constants/dueDateStatus';
import { getDueDateStatus } from '../../../services/maintenanceService';
import { formatToMonth } from '../../../services/timeDateService';
import { MaintenanceRequestTableData } from '../MaintenanceRequestTable/MaintenanceRequestsTable';
import { MaintenanceRequestOverviewTableData } from './MaintenanceRequestOverviewTable';
import { TableData } from './UserMaintenanceTable';

interface DueDateCellProps<
  T extends
    | TableData
    | MaintenanceObjectDto
    | MaintenanceRequestTableData
    | MaintenanceRequestOverviewTableData
> {
  cell: CellProps<T, Date | null | undefined>;
}

const DueDateCell = <
  T extends
    | TableData
    | MaintenanceObjectDto
    | MaintenanceRequestTableData
    | MaintenanceRequestOverviewTableData
>({
  cell,
}: React.PropsWithChildren<DueDateCellProps<T>>) => {
  if (!cell.value) return null;
  const dueStatus = cell.value
    ? getDueDateStatus(cell.value)
    : DueDateStatus.FUTURE;
  return (
    <div
      className={`flex w-full h-9 items-center justify-center ${
        dueStatus === DueDateStatus.OVERDUE && 'bg-red-light text-red'
      } ${dueStatus === DueDateStatus.UPCOMING && 'bg-orange/10 text-orange'}`}
    >
      <div className="basis-6">
        {dueStatus === DueDateStatus.OVERDUE && <FaExclamationCircle />}
        {dueStatus === DueDateStatus.UPCOMING && <FaExclamationTriangle />}
      </div>
      <div>{formatToMonth(cell.value)}</div>
    </div>
  );
};

export default DueDateCell;
