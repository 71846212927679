import React from 'react';

import {
  CustomerMaintenanceOfferDto,
  CustomerMaintenanceRequestDto,
  MaintenanceOfferDto,
  MaintenanceOrderStatusDtoEnum,
  MaintenanceRequestDto,
} from '@wartungshelden/shared-types';

import TextWithLabel from '../../../components/Basics/TextWithLabel';
import { getTimestampOrUnknown } from '../../../services/timeDateService';

interface SentDateProps {
  isMaintenanceTeamMemberAdmin?: boolean;
  requestOrOffer:
    | MaintenanceRequestDto
    | MaintenanceOfferDto
    | CustomerMaintenanceRequestDto
    | CustomerMaintenanceOfferDto;
}

const SentAndDecisionDate: React.FC<SentDateProps> = ({
  isMaintenanceTeamMemberAdmin,
  requestOrOffer,
}) => {
  const isOffer = 'requestId' in requestOrOffer;
  const isOrder = Boolean(isOffer && requestOrOffer.order);
  const isRequest = !isOffer && !isOrder;

  const orderConfirmed =
    isOffer &&
    requestOrOffer.order?.status === MaintenanceOrderStatusDtoEnum.CONFIRMED;

  const userShouldSeeDecision =
    isOffer &&
    !orderConfirmed &&
    requestOrOffer.decision &&
    (requestOrOffer.decision.status === 'declined' ||
      requestOrOffer.decision.status === 'accepted');

  const userShouldSeeOrderAccepted = orderConfirmed;

  return (
    <div className="mt-8">
      {isRequest && !isMaintenanceTeamMemberAdmin && (
        <div className="mb-3">
          <TextWithLabel label="Anfrage gesendet:" labelStyle="font-normal">
            {getTimestampOrUnknown(requestOrOffer.created)}
          </TextWithLabel>
        </div>
      )}
      {isRequest && isMaintenanceTeamMemberAdmin && (
        <div className="mb-3">
          <TextWithLabel label="Anfrage empfangen:" labelStyle="font-normal">
            {getTimestampOrUnknown(requestOrOffer.created)}
          </TextWithLabel>
        </div>
      )}
      {isOffer && !isOrder && isMaintenanceTeamMemberAdmin && (
        <div className="mb-3">
          <TextWithLabel label="Angebot gesendet:" labelStyle="font-normal">
            {getTimestampOrUnknown(requestOrOffer.decision.date)}
          </TextWithLabel>
        </div>
      )}
      {isOffer && !isOrder && !isMaintenanceTeamMemberAdmin && (
        <div className="mb-3">
          <TextWithLabel label="Angebot empfangen:" labelStyle="font-normal">
            {getTimestampOrUnknown(requestOrOffer.decision.date)}
          </TextWithLabel>
        </div>
      )}

      {userShouldSeeDecision && (
        <div className="mb-3">
          <TextWithLabel
            label={`Angebot ${
              requestOrOffer.decision.status === 'accepted'
                ? 'angenommen:'
                : 'abgelehnt:'
            }`}
            labelStyle="font-normal"
          >
            {getTimestampOrUnknown(requestOrOffer.decision.date)}
          </TextWithLabel>
        </div>
      )}

      {userShouldSeeOrderAccepted && (
        <TextWithLabel
          label="Beauftragt:"
          labelStyle="font-normal"
          dataCy="commissioned"
        >
          {getTimestampOrUnknown(requestOrOffer.order?.orderDate)}
        </TextWithLabel>
      )}
    </div>
  );
};

SentAndDecisionDate.defaultProps = {
  isMaintenanceTeamMemberAdmin: false,
};

export default SentAndDecisionDate;
