import React, { useContext, useState } from 'react';

import {
  AddressDto,
  MaintenanceOfferStatusDto,
  MaintenanceOrderStatusDto,
  MaintenanceRequestStatus,
  OfferedMaintenanceObjectDto,
  ProcessStatus,
  RequestStatusEnum,
  currentMaintenanceObjectId,
  requestStatusToLabel,
  statusToUse,
} from '@wartungshelden/shared-types';

import {
  FilterCheckboxList,
  MaintenanceRequestsTable,
  PageContainer,
} from '../../components';
import { PageHeaderOverview } from '../../components/Basics/Layouts/PageHeader';
import LoadingSpinner from '../../components/Basics/Loaders/LoadingSpinner';
import {
  ReadableServiceType,
  readableServiceType,
} from '../../constants/MaintenanceConstants';
import { SessionContext } from '../../contexts/SessionContext';
import { useBuildings } from '../../services/api/buildings/buildings-api';
import { useGetCustomers } from '../../services/api/customer/customer-api';
import { useMaintenanceObjects } from '../../services/api/maintenance-objects/maintenance-objects-api';
import { useMaintenanceOffers } from '../../services/api/maintenance-offers/maintenance-offer-api';
import { useMaintenanceRequests } from '../../services/api/maintenance-requests/maintenance-request-api';
import {
  RequestAddressesTypeFilterKey,
  RequestServiceTypeFilterKey,
  RequestStatusFilterKey,
} from '../../services/filter';

type RequestStatusMap = {
  id: ProcessStatus;
  label: string;
};

const RequestOverviewPage = () => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const { data: buildings, isLoading: buildingsLoading } = useBuildings();

  const { data: maintenances, isLoading: objectsLoading } =
    useMaintenanceObjects();

  const { data: maintenanceRequests, isLoading: maintenanceRequestsLoading } =
    useMaintenanceRequests();

  const { data: maintenanceOffers, isLoading: maintenanceOffersLoading } =
    useMaintenanceOffers();

  const { data: customers } = useGetCustomers();

  const noMaintenanceRequests = maintenanceRequests?.length === 0;

  const [selectedServiceTypes, setSelectedServiceTypes] = useState<
    ReadableServiceType[] | null
  >();

  const [selectedAddresses, setSelectedAddresses] = useState<Array<
    Exclude<AddressDto, 'city'> & { city: string }
  > | null>();

  const [selectedStatuses, setSelectedStatuses] = useState<Array<{
    id:
      | MaintenanceRequestStatus
      | MaintenanceOfferStatusDto
      | MaintenanceOrderStatusDto;
    label: string;
  }> | null>();

  const isRequestStatusMap = (
    field: RequestStatusMap | undefined
  ): field is RequestStatusMap => !!field;

  const statuses: RequestStatusMap[] | undefined = maintenanceRequests
    ?.map((request): RequestStatusMap | undefined => {
      const currentOffer = maintenanceOffers?.find(
        ({ requestId }) => requestId === request.id
      );

      const status = statusToUse(
        request.status,
        currentOffer?.decision.status,
        currentOffer?.order?.status
      );

      if (request.status === RequestStatusEnum.CANCELED) return undefined;

      return {
        id: status,
        label: requestStatusToLabel(isMaintenanceTeamMemberAdmin, status),
      };
    })
    .filter(isRequestStatusMap);

  const isAddress = (
    field: (Exclude<AddressDto, 'city'> & { city: string }) | undefined
  ): field is Exclude<AddressDto, 'city'> & { city: string } => !!field;

  const isLoading =
    objectsLoading ||
    maintenanceRequestsLoading ||
    maintenanceOffersLoading ||
    buildingsLoading;

  const maintenancesUsedInOffers =
    maintenanceOffers?.reduce((offeredMaintenances, { maintenanceObjects }) => {
      return [...offeredMaintenances, ...maintenanceObjects];
    }, new Array<OfferedMaintenanceObjectDto>()) ?? [];

  const maintenancesUsedInRequests =
    maintenances?.filter(({ currentRequestId }) => currentRequestId) ?? [];

  const usedMaintenances = [
    ...maintenancesUsedInOffers,
    ...maintenancesUsedInRequests,
  ];

  const buildingsUsedInRequests = buildings?.filter(({ id }) => {
    return maintenances?.map(({ buildingId }) => buildingId).includes(id);
  });

  const addressesUsedInRequests: Array<
    Exclude<AddressDto, 'city'> & { city: string }
  > = buildingsUsedInRequests
    ? buildingsUsedInRequests
        .map((building) => building.address)
        .map((address) => {
          if (!address || !address.city) {
            return undefined;
          }
          return address as Exclude<AddressDto, 'city'> & { city: string };
        })
        .filter(isAddress)
    : [];

  const maintenanceTypeOptions = usedMaintenances?.map((maintenance) =>
    readableServiceType(maintenance.type)
  );

  const filteredMaintenances = usedMaintenances
    ?.filter((maintenance) => {
      if (!maintenance.buildingId) {
        return [];
      }
      return buildings
        ?.filter((building) => {
          if (!building.address || !building.address.city) return false;
          return selectedAddresses
            ?.map((address) => address.city)
            .includes(building.address.city);
        })
        .map((building) => building.id)
        .includes(maintenance.buildingId);
    })
    .filter((maintenance) => {
      return selectedServiceTypes
        ?.map(({ type }) => type)
        ?.includes(maintenance.type.type);
    });

  const maintenanceRequestsToShow = maintenanceRequests
    ?.filter((maintenanceRequest) => {
      const offer = maintenanceOffers?.find(
        ({ requestId }) => requestId === maintenanceRequest.id
      );

      return selectedStatuses
        ?.map(({ id }) => id)
        ?.includes(
          statusToUse(
            maintenanceRequest.status,
            offer?.decision?.status,
            offer?.order?.status
          )
        );
    })
    .filter((maintenanceRequest) =>
      maintenanceRequest.objectIds.some((maintenanceId) =>
        filteredMaintenances?.some(
          (maintenance) =>
            maintenanceId === currentMaintenanceObjectId(maintenance)
        )
      )
    );

  return (
    <PageContainer>
      <PageHeaderOverview>
        <div className="pl-4">Aufträge</div>
      </PageHeaderOverview>
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : (
        <div className="flex h-full">
          <div className="flex flex-col h-full mr-6 space-y-4 pb-24">
            <div className="h-1/3 w-96">
              {statuses && (
                <FilterCheckboxList
                  label="Status"
                  filterKey={RequestStatusFilterKey}
                  discriminator="id"
                  labelProp="label"
                  items={statuses}
                  selectedItems={selectedStatuses}
                  setSelectedItems={(items) => {
                    setSelectedStatuses(items);
                  }}
                />
              )}
            </div>
            <div className="h-1/3 w-96">
              {maintenanceTypeOptions && (
                <FilterCheckboxList
                  label="Wartungsaufgabe"
                  filterKey={RequestServiceTypeFilterKey}
                  discriminator="type"
                  labelProp="label"
                  items={maintenanceTypeOptions}
                  selectedItems={selectedServiceTypes}
                  setSelectedItems={(items) => {
                    setSelectedServiceTypes(items);
                  }}
                />
              )}
            </div>
            <div className="h-1/3 w-96">
              {buildingsUsedInRequests && (
                <FilterCheckboxList
                  label="Gebäudeort"
                  filterKey={RequestAddressesTypeFilterKey}
                  discriminator="city"
                  labelProp="city"
                  items={addressesUsedInRequests}
                  selectedItems={selectedAddresses}
                  setSelectedItems={(items) => {
                    setSelectedAddresses(items);
                  }}
                />
              )}
            </div>
          </div>
          {maintenanceRequestsToShow && maintenanceOffers && (
            <div className="flex flex-1 h-full pb-24">
              <MaintenanceRequestsTable
                key="MaintenanceRequestsTable"
                maintenanceRequests={maintenanceRequestsToShow}
                maintenances={usedMaintenances}
                offers={maintenanceOffers}
                buildings={buildings}
                customers={customers}
                isLoading={isLoading}
                noMaintenanceRequest={noMaintenanceRequests}
              />
            </div>
          )}
        </div>
      )}
    </PageContainer>
  );
};

export default RequestOverviewPage;
