import React, { PropsWithChildren } from 'react';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

import { palette } from '../../../constants/palette';

type WidthType = 'one-third' | 'two-thirds' | 'full' | 'half' | undefined;

interface ModalProps {
  width?: WidthType;
  headline?: string;
  subHeadline?: string;
  isOpen: boolean;
  center?: boolean;
  bottom?: boolean;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  width,
  headline,
  subHeadline,
  isOpen,
  center,
  bottom,
  children,
}) => {
  const widthMap = {
    'one-third': '33%',
    'two-third': '66%',
    half: '50%',
    full: '100%',
  };

  return (
    <>
      {/* Mount/Unmount the component bases on open prop. This is needed to temporarily resolve strange glitching bug in react-responsive-modal
     component. See: https://github.com/pradel/react-responsive-modal/issues/495
    */}
      {isOpen && (
        <ResponsiveModal
          open={isOpen}
          center={center}
          onClose={() => {
            // empty function
          }}
          showCloseIcon={false}
          styles={{
            modal: {
              backgroundColor: palette.offWhite,
              padding: 0,
              width: width ? widthMap[width] : undefined,
              maxWidth: '100vw',
              margin: 0,
              display: bottom ? 'flex' : undefined,
              position: bottom ? 'fixed' : undefined,
              bottom: bottom ? 0 : undefined,
            },
          }}
        >
          {headline && (
            <div className="flex flex-col px-8 py-4">
              <div className="text-3xl font-bold mb-2">{headline}</div>
              {subHeadline}
            </div>
          )}
          <div
            className="flex flex-col flex-1 bg-white px-8 py-6"
            data-cy="responsiveModalBody"
          >
            {children}
          </div>
        </ResponsiveModal>
      )}
    </>
  );
};

Modal.defaultProps = {
  width: undefined,
  center: false,
  bottom: false,
  headline: undefined,
  subHeadline: undefined,
};

export default Modal;
