import React, { useContext } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import AllPagesWrapper from '../AllPagesWrapper';
import LoadingSpinner from '../components/Basics/Loaders/LoadingSpinner';
import {
  adminRoutePrefix,
  buildingsRoutes,
  maintenanceRoutes,
  requestRoutes,
  routes,
} from '../constants/routes';
import { SessionContext } from '../contexts/SessionContext';
import InstallerOrderContainer from '../pages/AdminPages/installerOrder/InstallerOrderContainer';
import InstallerOrderPage from '../pages/AdminPages/installerOrder/InstallerOrderPage';
import PriceCalculationContainer from '../pages/AdminPages/priceCalculation/PriceCalculationContainer';
import PriceCalculationPage from '../pages/AdminPages/priceCalculation/PriceCalculationPage';
import InstallationPlanContainer from '../pages/AdminPages/routePlan/InstallationPlanContainer';
import InstallationPlanPage from '../pages/AdminPages/routePlan/InstallationPlanPage';
import AuthWrapper from '../pages/Auth/AuthWrapper';
import LoginPage from '../pages/Auth/LoginPage';
import RegisterPage from '../pages/Auth/RegisterPage';
import RegistrationSuccessPage from '../pages/Auth/RegistrationSuccessPage';
import ResendCodePage from '../pages/Auth/ResendCodePage';
import ResetPasswordPage from '../pages/Auth/ResetPasswordPage';
import VerifyConfirm from '../pages/Auth/VerifyConfirm';
import VerifyPage from '../pages/Auth/VerifyPage';
import InstallationDocumentationPage from '../pages/InstallationDocumentation/InstallationDocumentationPage';
import RequestBuildingDetailsPage from '../pages/Request/RequestBuildingDetailsPage';
import RequestDetailsPage from '../pages/Request/RequestDetailsPage';
import RequestMainPage from '../pages/Request/RequestMainPage';
import RequestMaintenanceDetailsPage from '../pages/Request/RequestMaintenanceDetailsPage';
import RequestOverviewPage from '../pages/Request/RequestOverviewPage';
import ProfilePage from '../pages/SettingsPages/ProfilePage';
import SettingsMainPage from '../pages/SettingsPages/SettingsMainPage';
import SettingsPage from '../pages/SettingsPages/SettingsPage';
import UserRoleWrapper from '../pages/UserPages/UserRoleWrapper';
import BuildingsMainPage from '../pages/UserPages/buildings/BuildingsMainPage';
import DashboardMainPage from '../pages/UserPages/dashboard/DashboardMainPage';
import DashboardPage from '../pages/UserPages/dashboard/DashboardPage';
import MaintenanceMainPage from '../pages/UserPages/maintenance/MaintenanceMainPage';
import BuildingsRouter from './BuildingsRouter';
import DefaultRoute from './DefaultRoute';
import MaintenanceRouter from './MaintenanceRouter';
import ProtectedRoute from './ProtectedRoute';

const PermissionRouter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const TermsOfServicePage20220505 = React.lazy(
    () => import('../pages/Policies/2022_05_05/TermsOfServicePage')
  );
  const TermsOfServicePage20220728 = React.lazy(
    () => import('../pages/Policies/2022_07_28/TermsOfServicePage')
  );
  const DataProtectionPolicyPage20220505 = React.lazy(
    () => import('../pages/Policies/2022_05_05/DataProtectionPolicyPage')
  );
  const DataProtectionPolicyPage20220728 = React.lazy(
    () => import('../pages/Policies/2022_07_28/DataProtectionPolicyPage')
  );

  const {
    isMaintenanceTeamMemberAdmin,
    isMaintenanceTeamMemberUser,
    isSignedIn,
  } = useContext(SessionContext);

  const isNotMaintenanceTeam = () =>
    !isMaintenanceTeamMemberUser && !isMaintenanceTeamMemberAdmin;

  return (
    <React.Suspense fallback={<LoadingSpinner isLoading />}>
      <Routes>
        <Route element={<AuthWrapper />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin/login" element={<LoginPage isAdminLogin />} />

          <Route path="/login/reset" element={<ResetPasswordPage />} />
          <Route
            path="/admin/login/reset"
            element={<ResetPasswordPage isAdminLogin />}
          />

          <Route path="/login/reset/confirm" element={<VerifyConfirm />} />
          <Route
            path="/admin/login/reset/confirm"
            element={<VerifyConfirm isAdminLogin />}
          />

          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/admin/register"
            element={<RegisterPage isAdminLogin />}
          />

          <Route path="/register/verify" element={<VerifyPage />} />

          <Route
            path="/admin/register/verify"
            element={<VerifyPage isAdminLogin />}
          />

          <Route
            path="/register/success"
            element={<RegistrationSuccessPage />}
          />
          <Route
            path="/admin/register/success"
            element={<RegistrationSuccessPage isAdminLogin />}
          />

          <Route path="/register/verify/resend" element={<ResendCodePage />} />
          <Route
            path="/admin/register/verify/resend"
            element={<ResendCodePage isAdminLogin />}
          />
        </Route>

        <Route element={<AllPagesWrapper />}>
          <Route element={<UserRoleWrapper />}>
            <Route element={<UserRoleWrapper />}>
              <Route
                element={
                  <ProtectedRoute
                    isAllowed={isSignedIn}
                    redirectPath="/admin/login"
                  />
                }
              >
                {isMaintenanceTeamMemberAdmin && (
                  <Route path={adminRoutePrefix} element={<Outlet />}>
                    <Route
                      path={requestRoutes.main}
                      element={<RequestMainPage />}
                    >
                      <Route index element={<RequestOverviewPage />} />

                      <Route
                        path={requestRoutes.details}
                        element={<RequestDetailsPage />}
                      />
                      <Route
                        path={requestRoutes.maintenance}
                        element={<RequestMaintenanceDetailsPage />}
                      />
                      <Route
                        path={requestRoutes.building}
                        element={<RequestBuildingDetailsPage />}
                      />
                    </Route>

                    <Route
                      path={routes.plan}
                      element={<InstallationPlanContainer />}
                    >
                      <Route index element={<InstallationPlanPage />} />
                    </Route>

                    <Route
                      path={routes.price}
                      element={<PriceCalculationContainer />}
                    >
                      <Route index element={<PriceCalculationPage />} />
                    </Route>

                    <Route
                      path={routes.installerOrder}
                      element={<InstallerOrderContainer />}
                    >
                      <Route index element={<InstallerOrderPage />} />
                    </Route>
                    <Route index element={<DefaultRoute />} />
                  </Route>
                )}
                {isMaintenanceTeamMemberUser && (
                  <Route path={adminRoutePrefix} element={<Outlet />}>
                    <Route
                      path={routes.price}
                      element={<PriceCalculationContainer />}
                    >
                      <Route index element={<PriceCalculationPage />} />
                    </Route>
                    <Route index element={<DefaultRoute />} />
                  </Route>
                )}
              </Route>

              <Route element={<ProtectedRoute isAllowed={isSignedIn} />}>
                {isNotMaintenanceTeam() && (
                  <>
                    <Route element={<DashboardMainPage />}>
                      <Route
                        path={routes.dashboard}
                        element={<DashboardPage />}
                      />
                    </Route>

                    <Route element={<BuildingsMainPage />}>
                      <Route
                        path={buildingsRoutes.main}
                        element={<BuildingsRouter />}
                      />
                    </Route>

                    <Route element={<MaintenanceMainPage />}>
                      <Route
                        path={maintenanceRoutes.main}
                        element={<MaintenanceRouter />}
                      />
                    </Route>

                    <Route
                      path={requestRoutes.main}
                      element={<RequestMainPage />}
                    >
                      <Route index element={<RequestOverviewPage />} />

                      <Route
                        path={requestRoutes.details}
                        element={<RequestDetailsPage />}
                      />
                      <Route
                        path={requestRoutes.maintenance}
                        element={<RequestMaintenanceDetailsPage />}
                      />
                      <Route
                        path={requestRoutes.building}
                        element={<RequestBuildingDetailsPage />}
                      />
                    </Route>

                    <Route element={<SettingsMainPage />}>
                      <Route
                        path={routes.settings}
                        element={<SettingsPage />}
                      />
                      <Route path={routes.profile} element={<ProfilePage />} />
                    </Route>
                  </>
                )}
              </Route>
              <Route path="*" element={<DefaultRoute />} />
            </Route>
          </Route>

          <Route element={<Outlet />}>
            <Route
              path="/terms-of-service/2022-07-28"
              element={<TermsOfServicePage20220728 />}
            />
            <Route
              path="/terms-of-service/2022-05-05"
              element={<TermsOfServicePage20220505 />}
            />
            <Route
              path="/terms-of-service/*"
              element={<Navigate replace to="2022-07-28" />}
            />
          </Route>

          <Route element={<Outlet />}>
            <Route
              path="/privacy/2022-07-28"
              element={<DataProtectionPolicyPage20220728 />}
            />

            <Route
              path="/privacy/2022-05-05"
              element={<DataProtectionPolicyPage20220505 />}
            />

            <Route
              path="/privacy/*"
              element={<Navigate replace to="2022-07-28" />}
            />
          </Route>

          <Route
            path="/gute-doku"
            element={<InstallationDocumentationPage />}
          />
          <Route
            path="/gute-doku/:hintId"
            element={<InstallationDocumentationPage />}
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default PermissionRouter;
