import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FaArrowAltCircleRight, FaTrashAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import AuthLinkButton from '../../Auth/AuthLinkButton';
import UnitInputField from '../../Basics/Inputs/UnitInputField';
import Modal from '../../Basics/Modals/Modal';
import { InputField } from '../../index';
import LadderSystemAssistance from './LadderSystemAssistance';

const InventoryLadderRow: React.FC<
  React.PropsWithChildren<{ index: number; disabled: boolean }>
> = ({ index, disabled }) => {
  const { values, setFieldValue } =
    useFormikContext<FormikMaintenanceWizardValues>();
  const [showLadderLengthAssistance, setShowLadderLengthAssistance] =
    useState(false);

  const { maintenanceObjectId } = useParams();

  return (
    <>
      <Modal isOpen={showLadderLengthAssistance} center>
        <LadderSystemAssistance
          index={index}
          onClose={() => {
            setShowLadderLengthAssistance(false);
          }}
        />
      </Modal>
      <div>
        <div>
          <UnitInputField
            key={`maintenanceObjects.${index}.length`}
            name={`maintenanceObjects.${index}.length`}
            unit="Meter"
            disabled={disabled}
            allowDecimals
          />
        </div>
      </div>
      <div>
        <InputField
          name={`maintenanceObjects.${index}.name`}
          help="Wenn Du möchtest, kannst Du hier eine Beschreibung hinzufügen. Dies kann eine Inventarnummer oder eine andere bei Euch übliche Bezeichnung sein."
          placeholder="Beschreibung eingeben..."
          disabled={disabled}
        />
      </div>

      {!disabled && (
        <div className="flex flex-row items-center text-blue-abs">
          <FaArrowAltCircleRight className="mr-2" />
          <AuthLinkButton
            label="Du kennst die Länge nicht?"
            onClick={() => {
              setShowLadderLengthAssistance(true);
            }}
          />
        </div>
      )}

      <div className="w-full flex items-center justify-end">
        {!disabled && !maintenanceObjectId && (
          <button
            className={`ml-8 ${disabled && 'cursor-default'}`}
            type="button"
            onClick={() => {
              if (!disabled) {
                const tempMaintenanceObjects = values.maintenanceObjects;
                tempMaintenanceObjects.splice(index, 1);
                setFieldValue('maintenanceObjects', tempMaintenanceObjects);
              }
            }}
          >
            <FaTrashAlt
              size={20}
              className="text-gray-light group-hover:text-black-abs"
            />
          </button>
        )}
      </div>
    </>
  );
};

export default InventoryLadderRow;
