import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

export class MustBeOwnerRule<T extends { ownerId: string }> extends DomainRule<
  T,
  T
> {
  constructor(private creatorId: string) {
    super();
  }

  protected async rule(ownedEntity: T) {
    if (ownedEntity.ownerId !== this.creatorId) {
      throw new DomainRuleNotSatisfiedError(MustBeOwnerRule);
    }
    return ownedEntity;
  }
}
