import React from 'react';

const ButtonList: React.FC<
  React.PropsWithChildren<{
    items?: string[] | { id: string }[] | null;
    selectedItem: any;
    setSelectedItem: (prev: any) => void;
    selectionItemRepresentationKey?: string | null;
  }>
> = ({
  items,
  selectedItem,
  setSelectedItem,
  selectionItemRepresentationKey,
}) => {
  const isSelected = (item: any) =>
    item.id ? item.id === selectedItem?.id : item === selectedItem;
  return (
    <div>
      {items?.map((item: any) => (
        <button
          data-cy="components-basics-button-list-field"
          key={item.id ? item.id : item}
          className={`flex items-center mb-2 px-4 py-2 rounded border-gray-dark border-2 w-full ${
            isSelected(item)
              ? 'bg-blue-wartung text-white border-blue-wartung'
              : 'bg-transparent'
          }`}
          type="button"
          onClick={() => {
            setSelectedItem(item);
          }}
        >
          {selectionItemRepresentationKey
            ? item[selectionItemRepresentationKey]
            : item}
        </button>
      ))}
    </div>
  );
};

ButtonList.defaultProps = {
  items: undefined,
  selectionItemRepresentationKey: null,
};

export default ButtonList;
