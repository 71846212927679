import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import UserNavigation from '../../../components/Basics/TabNavigation/UserNavigation';
import HeaderLogout from '../../../components/Header/HeaderLogout';
import { routes } from '../../../constants/routes';

const MaintenanceMainPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  const shouldRenderHeader = () =>
    location.pathname === `/${routes.maintenance}`;

  if (shouldRenderHeader()) {
    return (
      <div className="flex flex-1 flex-col h-screen">
        <HeaderLogout>
          <UserNavigation />
        </HeaderLogout>
        <Outlet />
      </div>
    );
  }
  return <Outlet />;
};
export default MaintenanceMainPage;
