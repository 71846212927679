import * as qs from 'qs';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  MaintenanceDocumentationDto,
  UpdateMaintenanceDocumentationDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

const QUERY_KEY = 'maintenance-documentation';

export const useMaintenanceDocumentations = () => {
  const axios = useAxios();

  const fetchMaintenanceDocumentations = async () => {
    if (!axios) return null;
    const { data = [] } = await axios.get<MaintenanceDocumentationDto[]>(
      '/maintenance-exchange/maintenance/documentations'
    );
    return data;
  };

  return useQuery(QUERY_KEY, fetchMaintenanceDocumentations);
};

export const useMaintenanceDocumentationsForBuilding = (
  buildingId?: string | null
) => {
  const axios = useAxios();

  const fetchMaintenanceDocumentationsForBuilding = async () => {
    if (!axios) return null;
    const { data = [] } = await axios.get<MaintenanceDocumentationDto[]>(
      '/maintenance-exchange/maintenance/documentations',
      { params: { buildingId } }
    );
    return data;
  };

  return useQuery(
    [QUERY_KEY, buildingId],
    fetchMaintenanceDocumentationsForBuilding
  );
};

export const useMaintenanceDocumentationFile = (
  documentationId: string | undefined,
  enabled = true,
  userId?: string
) => {
  const axios = useAxios();

  const fetchMaintenanceDocumentationFile = async () => {
    if (!axios || !documentationId) return null;

    const { data, filename }: { data: Blob; filename: string } =
      await axios.get(
        `/maintenance-exchange/maintenance/documentations/${documentationId}/file`,
        {
          responseType: 'blob',
          params: { userId },
        }
      );

    return new File([data], filename, {
      type: data.type,
    });
  };

  return useQuery(
    [QUERY_KEY, documentationId],
    fetchMaintenanceDocumentationFile,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled,
    }
  );
};

export const useCreateMaintenanceDocumentation = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const createMaintenanceDocumentation = async ({
    file,
    maintenanceObjectIds,
  }) => {
    if (!axios) return null;
    const formData = new FormData();
    formData.append('file', file);

    return axios.post<MaintenanceDocumentationDto>(
      '/maintenance-exchange/maintenance/documentations',
      formData,
      {
        params: {
          maintenanceObjectIds,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };

  return useMutation(createMaintenanceDocumentation, {
    onSuccess: async () => {
      await rqClient.invalidateQueries(QUERY_KEY);
    },
  });
};

export const useUpdateMaintenanceDocumentation = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const updateMaintenanceDocumentation = async ({
    id,
    maintenanceObjectIds,
    validFor,
    invalidFor,
    notRelevantFor,
  }: UpdateMaintenanceDocumentationDto) => {
    if (!axios) return null;
    return axios.patch<MaintenanceDocumentationDto | undefined>(
      '/maintenance-exchange/maintenance/documentations',
      { id, maintenanceObjectIds, validFor, invalidFor, notRelevantFor }
    );
  };

  return useMutation(updateMaintenanceDocumentation, {
    onSuccess: async () => {
      await rqClient.invalidateQueries([QUERY_KEY]);
      await rqClient.invalidateQueries('maintenanceOffers');
    },
  });
};
