import { differenceInCalendarMonths, min, startOfToday } from 'date-fns';

import {
  CreateFallProtectionDto,
  CreateFallProtectionSystemDto,
  CreateLadderDto,
  CreateMaintenanceObjectDto,
  CreatePersonalProtectionEquipmentDto,
  CreatePushLockFallProtectionSystemDto,
  CreateRopeFallProtectionSystemDto,
  CreateSingleAnchorFallProtectionSystemDto,
  CurrentMaintenanceObjectDto,
  FallProtectionDto,
  FallProtectionSystemDto,
  LadderDto,
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
  PPEType,
  PPETypes,
  PersonalProtectionEquipmentDto,
  SupportedCreateMaintenanceTypeDto,
  SupportedMaintenanceTypeDto,
  UpdateMaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import {
  FALL_PROTECTION_SYSTEM_LOCATION,
  FORMIK_SELECT_DEFAULT_VALUE,
  FallProtectionSystems,
} from '../constants/MaintenanceConstants';
import { DueDateStatus } from '../constants/dueDateStatus';
import { taskStatuses } from '../constants/taskStatus';
import {
  isFormikFallProtectionMaintenanceObject,
  isFormikFallProtectionPushLockSystem,
  isFormikFallProtectionRopeSystem,
  isFormikLadderMaintenanceObject,
  isFormikPPEMaintenanceObject,
} from '../guards/isFormikMaintenanceObject';
import {
  FormikFallProtection,
  FormikFallProtectionPushLockSystem,
  FormikFallProtectionRopeSystem,
  FormikFallProtectionSingleAnchorSystem,
  FormikFallProtectionSystem,
} from '../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikLadder } from '../pages/UserPages/maintenance/maintenanceWizard/types/FormikLadder';
import { FormikMaintenanceWizardValues } from '../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { FormikPPE } from '../pages/UserPages/maintenance/maintenanceWizard/types/FormikPPE';
import { monthToDate } from './timeDateService';

export const locationValueToLabel = (objectWithLocation: {
  location?: 'Roof' | 'Overhead' | 'Facade' | 'default' | null;
}) => {
  if (
    objectWithLocation.location === FALL_PROTECTION_SYSTEM_LOCATION.ROOF.value
  ) {
    return FALL_PROTECTION_SYSTEM_LOCATION.ROOF.label;
  }
  if (
    objectWithLocation.location ===
    FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.value
  ) {
    return FALL_PROTECTION_SYSTEM_LOCATION.OVERHEAD.label;
  }
  if (
    objectWithLocation.location === FALL_PROTECTION_SYSTEM_LOCATION.FACADE.value
  ) {
    return FALL_PROTECTION_SYSTEM_LOCATION.FACADE.label;
  }
  return null;
};

const wizardValuesToCreateRopeFallProtectionSystemDto = (
  value: FormikFallProtectionRopeSystem
): CreateRopeFallProtectionSystemDto => {
  return {
    system: 'rope',
    ropeLength: value.ropeLength || 0,
    singleAnchors: value.singleAnchors || 0,
    systemAnchors: value.systemAnchors || 0,
  };
};

export const wizardValuesToCreatePersonalProtectionEquipmentDto = (
  value: FormikPPE
): CreatePersonalProtectionEquipmentDto => {
  return {
    type: 'personal_protection_equipment',
    category: value.category,
    quantity: value.quantity,
    systemType:
      value.systemType === FORMIK_SELECT_DEFAULT_VALUE
        ? undefined
        : Object.keys(PPEType)[
            Object.values<PPETypes>(PPEType).indexOf(value.systemType)
          ],
  };
};

const wizardValuesToCreatePushLockFallProtectionSystemDto = (
  value: FormikFallProtectionPushLockSystem
): CreatePushLockFallProtectionSystemDto => {
  return {
    system: 'push_lock',
    anchors: value.lock1 || 0,
    sleeves: value.lock2 || 0,
  };
};

export const wizardValuesToCreateLadderDto = (
  value: FormikLadder
): CreateLadderDto => {
  return {
    type: 'ladder',
    parts: [value.length],
    hasUsedHeightAssistanceCalculator: value.hasUsedHeightAssistanceCalculator,
    numberOfPlatforms: value.numberOfPlatforms,
  };
};

const wizardValuesToCreateSingleAnchorFallProtectionSystemDto = (
  value: FormikFallProtectionSingleAnchorSystem
): CreateSingleAnchorFallProtectionSystemDto => {
  return {
    system: 'single_anchor',
    singleAnchors: value.singleAnchors || 0,
  };
};

const wizardValuesToCreateFallProtectionSystemDto = (
  value: FormikFallProtection
): CreateFallProtectionSystemDto | undefined => {
  if (!value.system) return undefined;

  if (isFormikFallProtectionRopeSystem(value.system)) {
    return wizardValuesToCreateRopeFallProtectionSystemDto(value.system);
  }
  if (isFormikFallProtectionPushLockSystem(value.system)) {
    return wizardValuesToCreatePushLockFallProtectionSystemDto(value.system);
  }
  return wizardValuesToCreateSingleAnchorFallProtectionSystemDto(value.system);
};

const getSelectedAccessValues = (formikValues: FormikFallProtection) => {
  const accessValues: string[] = [];
  if (formikValues.ladder) {
    accessValues.push('Ladder');
  }
  if (formikValues.climbLadder) {
    accessValues.push('ClimbLadder');
  }
  if (formikValues.liftingPlatform) {
    accessValues.push('LiftingPlatform');
  }
  if (formikValues.roofExit) {
    accessValues.push('RoofExit');
  }
  if (formikValues.scaffold) {
    accessValues.push('Scaffold');
  }
  if (formikValues.other) {
    accessValues.push(formikValues.otherText);
  }
  return accessValues;
};

export const wizardValuesToCreateFallProtectionDto = (
  value: FormikFallProtection
): CreateFallProtectionDto => {
  return {
    type: 'fall_protection',
    location:
      value.location !== FORMIK_SELECT_DEFAULT_VALUE
        ? value.location
        : undefined,
    hasUsedRopeAssistanceCalculator:
      value.hasUsedRopeAssistanceCalculator || false,
    system: wizardValuesToCreateFallProtectionSystemDto(value),
    access: getSelectedAccessValues(value),
  };
};

export const wizardValuesToCreateMaintenanceTypeDto = (
  value: FormikFallProtection | FormikPPE | FormikLadder
): SupportedCreateMaintenanceTypeDto => {
  if (isFormikFallProtectionMaintenanceObject(value)) {
    return wizardValuesToCreateFallProtectionDto(value);
  }
  if (isFormikPPEMaintenanceObject(value)) {
    return wizardValuesToCreatePersonalProtectionEquipmentDto(value);
  }
  return wizardValuesToCreateLadderDto(value);
};

export const getDueDateStatus = (dueDate: Date) => {
  const diff = differenceInCalendarMonths(dueDate, startOfToday());

  if (diff < 0) {
    return DueDateStatus.OVERDUE;
  }

  if (diff === 0) {
    return DueDateStatus.UPCOMING;
  }

  if (diff === 1) {
    return DueDateStatus.NEXT_MONTH;
  }

  return DueDateStatus.FUTURE;
};

export const renderTaskStatus = (status: number) => {
  switch (status) {
    case taskStatuses.OPEN:
    case taskStatuses.DONE:
      return 'Offen';
    case taskStatuses.REQUESTED:
      return 'Angefragt';
    case taskStatuses.VALIDATING:
      return 'Angefragt (in Prüfung)';
    case taskStatuses.OFFER_SENT:
      return 'Angefragt (Angebot liegt vor)';
    case taskStatuses.OFFER_ACCEPTED:
      return 'Beauftragt';
    case taskStatuses.INSTALLATION_IN_PROGRESS:
      return 'In Ausführung';
    case taskStatuses.PROBLEM:
      return 'Instandhaltung/-setzung erforderlich';
    default:
      return '';
  }
};

export const minDueDate = (maintenances: CurrentMaintenanceObjectDto[]) => {
  const allDueDates = maintenances
    .map((maintenance) => maintenance.dueDate)
    .filter(
      (date): date is Date => typeof date !== 'undefined' && date !== null
    );
  if (allDueDates.length === 0) return undefined;
  return min(allDueDates.map((date) => new Date(date)));
};

const hasFallProtectionSystemTypeChanged = (
  createdFallProtectionDto: CreateFallProtectionDto,
  oldFallProtectionDto: FallProtectionDto
): boolean => {
  return (
    createdFallProtectionDto.system?.system !==
    oldFallProtectionDto.system?.system
  );
};

export const wizardValuesToCreateMaintenanceObjectDto = (
  formikMaintenanceObject: FormikPPE | FormikFallProtection | FormikLadder,
  values: FormikMaintenanceWizardValues
): Omit<CreateMaintenanceObjectDto, 'type'> & {
  type: SupportedCreateMaintenanceTypeDto;
} => {
  return {
    manufacturer:
      !isFormikLadderMaintenanceObject(formikMaintenanceObject) &&
      formikMaintenanceObject.manufacturer !== FORMIK_SELECT_DEFAULT_VALUE
        ? formikMaintenanceObject.manufacturer
        : undefined,
    buildingId: values.buildingId,
    dueDate: values.dueDate ? monthToDate(values.dueDate) : undefined,
    frequency: values.frequency === '' ? undefined : values.frequency,
    name: formikMaintenanceObject.name,
    type: wizardValuesToCreateMaintenanceTypeDto(formikMaintenanceObject),
  };
};

export const wizardValuesToUpdateMaintenanceObjectDto = (
  maintenanceObject: MaintenanceObjectDto,
  values: FormikMaintenanceWizardValues
): Omit<UpdateMaintenanceObjectDto, 'type'> & {
  type: SupportedMaintenanceTypeDto | SupportedCreateMaintenanceTypeDto;
} => {
  const objectToUpdate = values.maintenanceObjects[0];
  const createMaintenanceObjectDto = wizardValuesToCreateMaintenanceObjectDto(
    objectToUpdate,
    values
  );

  const { ownerId, ...objectWithoutOwner } = maintenanceObject;

  if (
    objectWithoutOwner.type.type !== 'other_type' &&
    (createMaintenanceObjectDto.type.type !== 'fall_protection' ||
      (objectWithoutOwner.type.type === 'fall_protection' &&
        hasFallProtectionSystemTypeChanged(
          createMaintenanceObjectDto.type,
          objectWithoutOwner.type
        )))
  ) {
    return {
      ...objectWithoutOwner,
      ...createMaintenanceObjectDto,
      type: {
        ...createMaintenanceObjectDto.type,
      },
    };
  }

  if (
    createMaintenanceObjectDto.type.type === 'fall_protection' &&
    createMaintenanceObjectDto.type.system &&
    objectWithoutOwner.type.type === 'fall_protection' &&
    objectWithoutOwner.type.system
  ) {
    return {
      ...objectWithoutOwner,
      ...createMaintenanceObjectDto,
      type: {
        ...createMaintenanceObjectDto.type,
        system: {
          ...createMaintenanceObjectDto.type.system,
        },
      },
    };
  }

  return {
    ...objectWithoutOwner,
    ...createMaintenanceObjectDto,
  };
};

export const fallProtectionSystemDtoToFormikFallProtectionSystem = (
  dto: FallProtectionSystemDto
): FormikFallProtectionSystem => {
  if (dto.system === 'rope') {
    return {
      singleAnchors: dto.singleAnchors,
      systemAnchors: dto.systemAnchors,
      ropeLength: dto.ropeLength,
    };
  }
  if (dto.system === 'push_lock') {
    return {
      lock1: dto.anchors,
      lock2: dto.sleeves,
    };
  }
  return {
    singleAnchors: dto.singleAnchors,
  };
};

const getOtherText = (maintenanceObject: FallProtectionDto) =>
  maintenanceObject.access?.find(
    (access) =>
      access !== 'Scaffold' &&
      access !== 'Ladder' &&
      access !== 'RoofExit' &&
      access !== 'LiftingPlatform' &&
      access !== 'ClimbLadder'
  ) || '';

export const fallProtectionSystemDtoToSystemName = (
  fallProtectionSystemDto: FallProtectionSystemDto
) => {
  if (fallProtectionSystemDto.system === 'rope') {
    return FallProtectionSystems.ROPE;
  }
  if (fallProtectionSystemDto.system === 'push_lock') {
    return FallProtectionSystems.PUSH;
  }
  return FallProtectionSystems.SINGLE;
};

export const getSelectedFiles = (
  maintenanceObjectId: string,
  maintenaneDocumentations: MaintenanceDocumentationDto[]
): MaintenanceDocumentationDto[] => {
  return maintenaneDocumentations.filter((maintenaneDocumentation) => {
    return maintenaneDocumentation.maintenanceObjectIds.includes(
      maintenanceObjectId
    );
  });
};

export const maintenanceObjectDtoToFormikMaintenanceType = (
  maintenanceObject: MaintenanceObjectDto,
  maintenanceDocumentations?: MaintenanceDocumentationDto[] | null
): FormikFallProtection[] | FormikPPE[] | FormikLadder[] => {
  if (maintenanceObject.type.type === 'fall_protection') {
    const maintenanceType: FallProtectionDto = {
      ...maintenanceObject.type,
    } as FallProtectionDto;
    return [
      {
        manufacturer: maintenanceObject.manufacturer,
        location: maintenanceType.location,
        scaffold: maintenanceObject.type.access?.includes('Scaffold'),
        ladder: maintenanceObject.type.access?.includes('Ladder'),
        roofExit: maintenanceObject.type.access?.includes('RoofExit'),
        liftingPlatform:
          maintenanceObject.type.access?.includes('LiftingPlatform'),
        climbLadder: maintenanceObject.type.access?.includes('ClimbLadder'),
        otherText: getOtherText(maintenanceObject.type),
        other: getOtherText(maintenanceObject.type) !== '',
        system: maintenanceObject.type.system
          ? fallProtectionSystemDtoToFormikFallProtectionSystem(
              maintenanceObject.type.system
            )
          : undefined,
        systemName: maintenanceType.system
          ? fallProtectionSystemDtoToSystemName(maintenanceType.system)
          : undefined,
        name: maintenanceObject.name,
        selectedFiles: !maintenanceDocumentations
          ? []
          : getSelectedFiles(maintenanceObject.id, maintenanceDocumentations),
      },
    ];
  }
  if (maintenanceObject.type.type === 'personal_protection_equipment') {
    const maintenanceType: PersonalProtectionEquipmentDto = {
      ...maintenanceObject.type,
    } as PersonalProtectionEquipmentDto;
    return [
      {
        manufacturer: maintenanceObject.manufacturer,
        quantity: maintenanceType.quantity,
        category: maintenanceType.category,
        name: maintenanceObject.name,
        systemType: maintenanceType.systemType
          ? PPEType[maintenanceType.systemType]
          : undefined,
      },
    ];
  }
  if (maintenanceObject.type.type === 'ladder') {
    const maintenanceType: LadderDto = {
      ...maintenanceObject.type,
    } as LadderDto;
    return [
      {
        name: maintenanceObject.name,
        length: maintenanceType.parts[0],
        numberOfPlatforms: maintenanceType.numberOfPlatforms,
      },
    ];
  }
  return [];
};

export const priceableMaintenanceTypes = [
  'fall_protection',
  'personal_protection_equipment',
  'ladder',
];

export const isPriceableMaintenanceType = (maintenance: MaintenanceObjectDto) =>
  priceableMaintenanceTypes.includes(maintenance.type.type);

export type FileOrDocumentation = MaintenanceDocumentationDto | File;

export const toFileName = (file: FileOrDocumentation) =>
  file instanceof File ? file.name : file.fileName;

export const withFile = (file: FileOrDocumentation) => {
  return (currentFile: FileOrDocumentation) =>
    toFileName(file) === toFileName(currentFile);
};

export const byFileName = (
  fileA: FileOrDocumentation,
  fileB: FileOrDocumentation
) => {
  return toFileName(fileA).localeCompare(toFileName(fileB));
};
