import React, { useContext } from 'react';

import { HintHighlightContext } from '../../contexts/HintHighlightContext';

interface HintHighlightProps {
  highlightIds: string[];
  highlightBorderColor?: 'border-blue-abs' | 'border-red' | 'border-orange';
}

const HintHighlight: React.FC<React.PropsWithChildren<HintHighlightProps>> = ({
  children,
  highlightIds,
  highlightBorderColor,
}) => {
  const { highlights } = useContext(HintHighlightContext);

  const shouldShowHighlightHint = highlightIds.some((highlightId) =>
    highlights.includes(highlightId)
  );

  return (
    <div
      className={`${
        shouldShowHighlightHint &&
        `border-2 animate-wiggle-short ${highlightBorderColor}`
      }`}
    >
      {children}
    </div>
  );
};

HintHighlight.defaultProps = {
  highlightBorderColor: 'border-red',
};

export default HintHighlight;
