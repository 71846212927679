import { Field, useFormikContext } from 'formik';
import React, { SyntheticEvent } from 'react';

import Tip from '../Tip';

type InputFieldProps = {
  label?: string | null;
  disabled?: boolean;
  placeholder?: string;
  name: string;
  unit: string;
  labelWidthTailwindClass?: string;
  useLabelAutoWidth?: boolean;
  isLabelBold?: boolean;
  fullWith?: boolean;
  allowDecimals?: boolean;
  allowNegativeValues?: boolean;
  shouldTurnNegative?: boolean;
  shouldSelectOnFocus?: boolean;
  help?: React.ReactElement | string;
};

const UnitInputField: React.FC<React.PropsWithChildren<InputFieldProps>> = ({
  name,
  label,
  disabled,
  placeholder,
  unit,
  labelWidthTailwindClass,
  useLabelAutoWidth = false,
  isLabelBold,
  fullWith,
  allowDecimals,
  allowNegativeValues,
  shouldTurnNegative,
  shouldSelectOnFocus,
  help,
}) => {
  const { status, setFieldValue } = useFormikContext();
  const errorKeys = status ? Object.keys(status) : [];
  return (
    <div className={`flex h-12 items-center w-full ${label && 'mb-2'}`}>
      {label && (
        <label
          htmlFor={name}
          className={`flex ${isLabelBold ? 'font-bold' : ''} ${
            !useLabelAutoWidth && labelWidthTailwindClass
          } mr-4
          ${errorKeys.includes(name) ? 'text-red' : ''}
          `}
        >
          {label}
        </label>
      )}
      <Field
        data-cy="components-basics-input-field"
        className={`h-12 px-4 py-2 text-right rounded-lg ${
          disabled
            ? 'border-0 bg-white bg-gray-lighter text-gray-dark'
            : 'border-2 border-gray rounded-lg'
        } ${fullWith ? 'w-full' : 'w-24'}
        ${!label && errorKeys.includes(name) ? 'border-red border-2' : ''}
        `}
        id={name}
        name={name}
        disabled={disabled}
        as="input"
        type="number"
        min={allowNegativeValues ? undefined : 0}
        placeholder={placeholder}
        onFocus={
          shouldSelectOnFocus
            ? (e: SyntheticEvent) => {
                const element = e.target as HTMLInputElement;
                element.select();
              }
            : undefined
        }
        onClick={(e: SyntheticEvent) => {
          if (shouldSelectOnFocus) {
            // if field should be selected make a small delay so it would select even if its second click for deselecting
            const element = e.target as HTMLInputElement;
            setTimeout(() => {
              element.select();
            }, 5);
          }
        }}
        onKeyPress={(e: KeyboardEvent) => {
          if (
            (!allowDecimals && !/[\d-]/.test(e.key)) ||
            (!allowNegativeValues && !/[\d,.]/.test(e.key))
          ) {
            e.preventDefault();
          }
        }}
        onBlur={(e: FocusEvent) => {
          if (!shouldTurnNegative) return;

          const element = e.target as HTMLInputElement;

          if (Number(element.value) < 0) return;

          element.value = (Number(element.value) * -1).toString();
          setFieldValue(name, element.value);
        }}
        onKeyUp={(e: KeyboardEvent) => {
          if (!shouldTurnNegative) return;

          const element = e.target as HTMLInputElement;

          if (Number(element.value) < 0) return;

          element.value = (Number(element.value) * -1).toString();
          setFieldValue(name, element.value);
        }}
      />
      {unit && (
        <div
          className={`ml-1 ${
            !disabled ? 'group-hover:text-black-abs' : 'text-gray-dark'
          } `}
        >
          {unit}
        </div>
      )}
      {help && <div className="ml-4">{help}</div>}
    </div>
  );
};

export default UnitInputField;

UnitInputField.defaultProps = {
  disabled: false,
  labelWidthTailwindClass: 'w-64',
  isLabelBold: true,
  fullWith: false,
  allowDecimals: false,
  allowNegativeValues: false,
  label: undefined,
  placeholder: undefined,
  useLabelAutoWidth: false,
  shouldTurnNegative: false,
  shouldSelectOnFocus: false,
  help: undefined,
};
