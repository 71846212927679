import { DomainEventDispatcher } from '@warthungshelden/domain/common';
import { difference, distinct } from '@warthungshelden/shared-functions';
import {
  addMonths,
  endOfDay,
  endOfMonth,
  isSameDay,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import {
  Customer,
  CustomerNotificationService,
  CustomerRepository,
} from '../customer';
import { MaintenanceOfferRepository } from '../maintenance-offer';
import {
  MaintenanceObjectCreated,
  MaintenanceObjectDeleted,
  MaintenanceObjectUpdated,
} from './events';
import { MaintenanceObject } from './maintenance-object';
import { MaintenanceObjectReminder } from './maintenance-object-reminder';
import { MaintenanceObjectReminderRepository } from './maintenance-object-reminder.repository';

export class MaintenanceObjectReminderService {
  constructor(
    private readonly eventDispatcher: DomainEventDispatcher,
    private readonly customerRepository: CustomerRepository,
    private readonly customerNotificationService: CustomerNotificationService,
    private readonly maintenanceOfferRepository: MaintenanceOfferRepository,
    private readonly reminderRepository: MaintenanceObjectReminderRepository
  ) {
    this.eventDispatcher.register(MaintenanceObjectUpdated, async (event) => {
      await this.setReminderFor(event.newMaintenanceObject);
    });

    this.eventDispatcher.register(MaintenanceObjectCreated, async (event) => {
      await this.setReminderFor(event.maintenanceObject);
    });

    this.eventDispatcher.register(MaintenanceObjectDeleted, async (event) => {
      await this.removeReminderFor(event.maintenanceObject);
    });
  }

  public async sendAllPendingOfferReminders(daysAgo = 14) {
    const twoWeeksAgo = subDays(new Date(), daysAgo);

    const fromDate = startOfDay(twoWeeksAgo);
    const toDate = endOfDay(twoWeeksAgo);

    const allOffers =
      await this.maintenanceOfferRepository.getAllWhereSentBetween(
        fromDate,
        toDate
      );

    await Promise.all(
      allOffers.map((offer) =>
        this.customerNotificationService.notifyAboutPendingOffer(offer)
      )
    );
  }

  public async sendAllUpcomingReminders(): Promise<void> {
    const customers = await this.customerRepository.getAll();

    const upcomingRemindersByUsers = await Promise.all(
      customers.flatMap(this.toUpcomingRemindersFor.bind(this))
    );

    const upcomingReminders = upcomingRemindersByUsers.flat();

    await Promise.all(
      upcomingReminders.map(this.sendUpcomingReminder.bind(this))
    );
  }

  public async sendAllOverdueReminders(): Promise<void> {
    const customers = await this.customerRepository.getAll();

    const overdueRemindersByUsers = await Promise.all(
      customers.flatMap(this.toOverdueRemindersFor.bind(this))
    );

    await Promise.all(
      overdueRemindersByUsers.flat().map(this.sendOverdueReminder.bind(this))
    );
  }

  public async setReminderFor(
    maintenanceObject: MaintenanceObject
  ): Promise<void> {
    const reminders = await this.reminderRepository.getAllByUser(
      maintenanceObject.ownerId
    );

    if (!maintenanceObject.dueDate) {
      return await this.removeObjectFrom(reminders, maintenanceObject);
    }

    const existingWithDate = reminders.filter(byDueDate(maintenanceObject));

    if (existingWithDate.length === 0) {
      await this.reminderRepository.create(
        new MaintenanceObjectReminder({
          id: uuidv4(),
          ownerId: maintenanceObject.ownerId,
          dueDate: maintenanceObject.dueDate,
          objectIds: [maintenanceObject.id],
        })
      );
    } else {
      await Promise.all(
        existingWithDate.map((existing) => {
          this.reminderRepository.update(
            addObjectId(existing, maintenanceObject.id)
          );
        })
      );
    }

    const remindersLeft = reminders
      .filter(difference(existingWithDate))
      .filter(byMaintenanceObject(maintenanceObject));

    await this.removeObjectFrom(remindersLeft, maintenanceObject);
  }

  public async removeReminderFor(
    maintenanceObject: MaintenanceObject
  ): Promise<void> {
    const reminders = await this.reminderRepository.getAllByUser(
      maintenanceObject.id
    );

    await this.removeObjectFrom(reminders, maintenanceObject);
  }

  private async toUpcomingRemindersFor(
    customer: Customer
  ): Promise<MaintenanceObjectReminder[]> {
    const reminders = await this.reminderRepository.getAllByUser(customer.id);
    const daysInAdvance = customer.notification?.daysInAdvance;

    if (
      !customer.shouldSendReminders() ||
      daysInAdvance === undefined ||
      daysInAdvance === null
    ) {
      return [];
    }

    return reminders.filter(isUpcoming(daysInAdvance));
  }

  private async sendUpcomingReminder(reminder: MaintenanceObjectReminder) {
    await this.customerNotificationService.notifyAboutUpcomingMaintenanceDueDate(
      reminder
    );

    await this.reminderRepository.update(
      reminder.copyWith({ lastUpcomingReminder: new Date() })
    );
  }

  private async sendOverdueReminder(reminder: MaintenanceObjectReminder) {
    await this.customerNotificationService.notifyAboutOverdueMaintenanceDueDate(
      reminder
    );

    await this.reminderRepository.update(
      reminder.copyWith({ lastOverdueReminder: new Date() })
    );
  }

  private async toOverdueRemindersFor(
    customer: Customer
  ): Promise<MaintenanceObjectReminder[]> {
    const reminders = await this.reminderRepository.getAllByUser(customer.id);

    if (!customer.shouldSendReminders()) {
      return [];
    }

    return reminders.filter(isOverdue());
  }

  private async removeObjectFrom(
    reminders: MaintenanceObjectReminder[],
    maintenanceObject: MaintenanceObject
  ): Promise<void> {
    const remindersWithoutObject = reminders.map((withObject) =>
      removeObjectId(withObject, maintenanceObject.id)
    );

    const remindersToRemove = remindersWithoutObject.filter(
      ({ objectIds }) => objectIds.length === 0
    );

    const remindersToUpdate = remindersWithoutObject.filter(
      difference(remindersToRemove)
    );

    await Promise.all(
      remindersToRemove.map(({ id }) => this.reminderRepository.delete(id))
    );

    await Promise.all(
      remindersToUpdate.map((reminder) =>
        this.reminderRepository.update(reminder)
      )
    );
  }
}

function addObjectId(
  reminder: MaintenanceObjectReminder,
  id: string
): MaintenanceObjectReminder {
  return reminder.copyWith({
    objectIds: distinct([...reminder.objectIds, id]),
  });
}

function removeObjectId(
  reminder: MaintenanceObjectReminder,
  id: string
): MaintenanceObjectReminder {
  return reminder.copyWith({
    objectIds: reminder.objectIds.filter(difference([id])),
  });
}

function byMaintenanceObject(maintenanceObject: MaintenanceObject) {
  return ({ objectIds }) => objectIds.includes(maintenanceObject.id);
}

function byDueDate(maintenanceObject: MaintenanceObject) {
  return ({ dueDate }) =>
    Boolean(
      maintenanceObject.dueDate && isSameDay(maintenanceObject.dueDate, dueDate)
    );
}

function isUpcoming(daysInAdvance: number) {
  return ({ lastUpcomingReminder, dueDate }: MaintenanceObjectReminder) => {
    const dayToRemind = subDays(dueDate, daysInAdvance);
    const intervalToRemindIn = { start: dayToRemind, end: dueDate };

    const alreadyNotified = Boolean(
      lastUpcomingReminder &&
        isWithinInterval(lastUpcomingReminder, intervalToRemindIn)
    );
    const today = new Date();
    const shouldStillNotify = isWithinInterval(today, intervalToRemindIn);

    return !alreadyNotified && shouldStillNotify;
  };
}

function isOverdue() {
  return ({ lastOverdueReminder, dueDate }: MaintenanceObjectReminder) => {
    const overdueDate = addMonths(dueDate, 1);
    const dayToRemind = startOfMonth(overdueDate);
    const lastDateToRemind = endOfMonth(overdueDate);
    const intervalToRemindIn = { start: dayToRemind, end: lastDateToRemind };

    const alreadyNotified = Boolean(
      lastOverdueReminder &&
        isWithinInterval(lastOverdueReminder, intervalToRemindIn)
    );
    const today = new Date();
    const shouldStillNotify = isWithinInterval(today, intervalToRemindIn);

    return !alreadyNotified && shouldStillNotify;
  };
}
