import { Formik } from 'formik';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AdditionalMaintenanceOrderDataDto } from '@wartungshelden/shared-types';

import { Button, InputField, PageContainer } from '../../../components';
import { PageHeaderOverview } from '../../../components/Basics/Layouts/PageHeader';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import {
  installationOrderQueryKey,
  useInstallationOrder,
  useInstallationOrderAdditionalWithDocu,
  useInstallationOrderBuilding,
  useInstallationOrderSelectLine,
  useUpdateInstallationOrderAdditional,
} from '../../../services/api/maintenance-offers/maintenance-offer-api';
import AppointmentInfo from './components/AppointmentInfo';
import GeneralInfo from './components/GeneralInfo';
import InstallerInfo from './components/InstallerInfo';
import MissingDataIndicator from './components/MisingDataIndicator';
import OrderDataTestProcedureCheckboxes from './components/OrderDataTestProcedureCheckboxes';
import ProjectInfo from './components/ProjectInfo';
import SystemsInfo from './components/SystemsInfo';
import TaskInfo from './components/TaskInfo';

const selectLineDataSchema = Yup.object({
  orderNumber: Yup.string().required(),
  contractNumber: Yup.string().required(),
  documentationAvailable: Yup.boolean().required(),

  customerName: Yup.string().required(),
  contactProject: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().required(),
    mobile: Yup.string().required(),
  }),
  installerName: Yup.string().required(),
  dueDate: Yup.date().required(),
  appointment: Yup.object().shape({
    start: Yup.date().required(),
  }),
  building: Yup.object().shape({
    name: Yup.string().required(),
  }),
  serialNumbers: Yup.array().min(1).required(),
});

const getAdditionalData = (
  additionalData: AdditionalMaintenanceOrderDataDto | undefined,
  orderNumber?: string
): AdditionalMaintenanceOrderDataDto => {
  return {
    orderNumber: orderNumber ?? '',
    access: '',
    reception: '',
    buildingContact: {
      name: '',
      email: '',
      phone: '',
      mobile: '',
    },
    testProcedures: undefined,
    systems: undefined,
    ...additionalData,
  };
};

const InstallerOrderPage = () => {
  const [orderId, setOrderId] = useState<string | undefined>();

  const queryClient = useQueryClient();

  const { data: selectLineData, isLoading: isLoadingSelectLine } =
    useInstallationOrderSelectLine(orderId);

  const {
    data: additionalDataWithDocu,
    isLoading: isLoadingAdditionalWithDocu,
  } = useInstallationOrderAdditionalWithDocu(orderId);

  const { data: building, isLoading: isLoadingBuilding } =
    useInstallationOrderBuilding(orderId);

  const {
    mutateAsync: updateInstallationOrderAdditional,
    isLoading: isLoadingAdditionalUpdate,
  } = useUpdateInstallationOrderAdditional();

  const {
    mutateAsync: fetchInstallationOrder,
    isLoading: isLoadingSpreadsheet,
  } = useInstallationOrder();

  const isLoading =
    isLoadingSpreadsheet ||
    isLoadingSelectLine ||
    isLoadingAdditionalWithDocu ||
    isLoadingBuilding ||
    isLoadingAdditionalUpdate;

  const hasMissingSelectLineData = () => {
    return !selectLineDataSchema.isValidSync(selectLineData);
  };

  const downloadExcelFile = async () => {
    if (!orderId) {
      return;
    }
    try {
      const loadedFile = await fetchInstallationOrder(orderId);
      if (!loadedFile) {
        throw new Error('File could not be loaded');
      }

      const fileUrl = URL.createObjectURL(loadedFile);

      const downloadLink = document.createElement('a');
      downloadLink.href = fileUrl;
      downloadLink.download = loadedFile.name;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(fileUrl);
    } catch (e) {
      toast.error(
        'Die Datei konnte nicht erstellt werden. Bitte überprüfe ob die übergebene Auftragsnummer existiert.',
        {
          position: 'top-center',
        }
      );
    }
  };

  return (
    <PageContainer>
      <PageHeaderOverview>
        <div className="pl-4">Wartungsauftrag</div>
      </PageHeaderOverview>

      <div className="overflow-y-auto flex flex-col h-full pb-40">
        <Formik
          enableReinitialize
          initialValues={{ orderId: '' }}
          onSubmit={async (values) => {
            if (!values.orderId) {
              return;
            }

            if (values.orderId === orderId) {
              await queryClient.resetQueries([installationOrderQueryKey]);
            } else {
              setOrderId(values.orderId.trim());
            }
          }}
        >
          {({ submitForm }) => (
            <div className="flex flex-row space-x-2">
              <div className="w-96">
                <InputField name="orderId" label="Auftragsnummer" />
              </div>
              <Button
                className="primary-button"
                onClick={submitForm}
                label="Auftrag suchen"
                disabled={isLoading}
              />
            </div>
          )}
        </Formik>
        {selectLineData && (
          <Formik
            enableReinitialize
            initialValues={getAdditionalData(
              additionalDataWithDocu,
              selectLineData.orderNumber
            )}
            onSubmit={async (values) => {
              if (!values) {
                return;
              }

              await updateInstallationOrderAdditional(
                values as AdditionalMaintenanceOrderDataDto
              );
            }}
          >
            {({ dirty, submitForm, values }) => (
              <div className="mt-4">
                {hasMissingSelectLineData() && (
                  <MissingDataIndicator selectLineData={selectLineData} />
                )}
                <div className="flex flex-row space-x-8">
                  <div className="flex flex-1 flex-col space-y-4 ">
                    <GeneralInfo
                      selectLineData={selectLineData}
                      building={building}
                    />
                    <ProjectInfo selectLineData={selectLineData} />
                    <TaskInfo selectLineData={selectLineData} />
                    <SystemsInfo additionalData={values} />
                  </div>
                  <div className="flex flex-1 flex-col space-y-4 ">
                    <InstallerInfo selectLineData={selectLineData} />
                    <AppointmentInfo
                      selectLineData={selectLineData}
                      building={building}
                    />
                    <OrderDataTestProcedureCheckboxes />
                  </div>

                  <div className="flex w-full flex-row justify-end space-x-2 absolute bottom-0 right-0 p-4 bg-gray-lighter">
                    <Button
                      className="primary-button"
                      onClick={async () => {
                        await submitForm();
                      }}
                      label="Speichern"
                      disabled={!dirty}
                    />

                    <Button
                      className="confirmation-button"
                      onClick={() => {
                        downloadExcelFile().finally(() => {});
                      }}
                      label="Excel-Datei herunterladen"
                      disabled={dirty}
                    />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
        <LoadingSpinner isLoading={isLoading} />
      </div>
    </PageContainer>
  );
};

export default InstallerOrderPage;
