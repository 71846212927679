import { LogLevel } from '@nestjs/common/services/logger.service';

export interface Logger {
  log(message: unknown, ...optionalParams: unknown[]): unknown;

  error(message: unknown, ...optionalParams: unknown[]): unknown;

  warn(message: unknown, ...optionalParams: unknown[]): unknown;

  debug?(message: unknown, ...optionalParams: unknown[]): unknown;

  verbose?(message: unknown, ...optionalParams: unknown[]): unknown;

  setLogLevels?(levels: LogLevel[]): unknown;
}
