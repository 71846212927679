import * as countries from 'i18n-iso-countries';
import React from 'react';

import DropdownFieldLayout from './DropdownFieldLayout';

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const countryOptions = () => {
  const cntryoptns = countries.getNames('de', { select: 'official' });

  return Object.entries(cntryoptns)
    .sort(([, name1], [, name2]) => name1.localeCompare(name2))
    .reduce(
      (current, [name, alpha2]) => ({
        ...current,
        [alpha2]: name,
      }),
      {}
    );
};

type CountryDropDownProps = {
  label?: string;
  name: string;
  disabled?: boolean;
};

const CountryDropDown: React.FC<
  React.PropsWithChildren<CountryDropDownProps>
> = ({ label, name, disabled }) => {
  return (
    <div className="flex h-12 items-center w-full mb-2">
      <DropdownFieldLayout
        label={label}
        options={countryOptions()}
        name={name}
        disabled={disabled}
        labelWidthTailwindClass="flex font-bold w-64"
      />
    </div>
  );
};

CountryDropDown.defaultProps = {
  label: undefined,
  disabled: false,
};

export default CountryDropDown;
