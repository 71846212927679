import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { maintenanceRoutes } from '../constants/routes';
import MaintenancePage from '../pages/UserPages/maintenance/MaintenancePage';
import MaintenanceWizardPage from '../pages/UserPages/maintenance/maintenanceWizard/MaintenanceWizardPage';
import MaintenanceRequestWizardPage from '../pages/UserPages/requestWizard/MaintenanceRequestWizardPage';

const MaintenanceRouter = () => (
  <Routes>
    <Route index element={<MaintenancePage />} />
    <Route path={maintenanceRoutes.add} element={<MaintenanceWizardPage />} />
    <Route
      path={maintenanceRoutes.details}
      element={<MaintenanceWizardPage />}
    />
    <Route
      path={maintenanceRoutes.request}
      element={<MaintenanceRequestWizardPage />}
    />
    <Route
      path={maintenanceRoutes.requestWithSelection}
      element={<MaintenanceRequestWizardPage />}
    />
  </Routes>
);

export default MaintenanceRouter;
