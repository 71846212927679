import React from 'react';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaQuestionCircle,
} from 'react-icons/fa';

import { shouldShowHintInLegend } from '../services';

const BuildingsGraphLegend = ({ graphData }: { graphData: any }) => (
  <div className="flex flex-col space-y-4">
    {shouldShowHintInLegend(graphData, 'OVERDUE') && (
      <div className="flex flex-row">
        <div className="mr-2 text-xl text-red">
          <FaExclamationCircle />
        </div>

        <div>
          <strong>Dringender Handlungsbedarf</strong>
          <div>Fälligkeitstermin ist bereits überschritten</div>
        </div>
      </div>
    )}

    {shouldShowHintInLegend(graphData, 'UPCOMING') && (
      <div className="flex flex-row">
        <div className="mr-2 text-xl text-yellow">
          <FaExclamationTriangle />
        </div>

        <div>
          <strong>Akuter Handlungsbedarf</strong>
          <div>Fälligkeitstermin ist der aktuelle Monat</div>
        </div>
      </div>
    )}

    {shouldShowHintInLegend(graphData, 'NEXT_MONTH') && (
      <div className="flex flex-row">
        <div className="mr-2 text-xl text-blue-abs">
          <FaInfoCircle />
        </div>

        <div>
          <strong>Empfohlener Handlungsbedarf</strong>
          <div>Fälligkeitstermin steht bald an</div>
        </div>
      </div>
    )}

    {shouldShowHintInLegend(graphData, 'HEALTHY') && (
      <div className="flex flex-row">
        <div className="mr-2 text-xl text-green-green">
          <FaCheckCircle />
        </div>

        <div>
          <strong>Kein Handlungsbedarf</strong>
          <div>Keine akuter Fälligkeitstermin</div>
        </div>
      </div>
    )}

    {shouldShowHintInLegend(graphData, 'UNKNOWN') && (
      <div className="flex flex-row">
        <div className="mr-2 text-xl text-gray-abs">
          <FaQuestionCircle />
        </div>
        <div>
          <strong>Unbekannter Handlungsbedarf</strong>
          <div>Es fehlen Informationen zum Fälligkeitstermin</div>
        </div>
      </div>
    )}
  </div>
);

export default BuildingsGraphLegend;
