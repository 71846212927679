import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  FallProtectionTypes,
  MaintenanceTypes,
} from '@wartungshelden/shared-types';

import { MenuOptionType } from '../../../pages/AdminPages/priceCalculation/types/MenuOptionType';
import { getLabelForMaintenanceTypeOrFallProtectionSystem } from '../../../pages/AdminPages/priceCalculation/utils/utils';
import { useAxios } from '../api';

export interface AdminSettings {
  id: string;
  calculatorFavoriteTypes: Array<MaintenanceTypes | FallProtectionTypes>;
}

export interface AdminMenuOptions {
  id: string;
  calculatorFavoriteTypes: MenuOptionType[];
}

export const useOwnAdminSettings = () => {
  const axios = useAxios();

  const fetchAdminSettings = async (): Promise<AdminMenuOptions | null> => {
    if (!axios) return null;
    const { data } = await axios.get<AdminSettings>(
      '/maintenance-administration/admin-settings/me'
    );
    return {
      ...data,
      calculatorFavoriteTypes: data.calculatorFavoriteTypes.map((key) => {
        return {
          key,
          value: getLabelForMaintenanceTypeOrFallProtectionSystem(key),
        };
      }),
    };
  };

  return useQuery('admin-settings', fetchAdminSettings);
};

export const useUpdateAdminSettings = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const updateAdminSettings = async (
    calculatorFavoriteTypes: MenuOptionType[]
  ) => {
    if (!axios) return null;
    return axios.post('/maintenance-administration/admin-settings/me', {
      calculatorFavoriteTypes: calculatorFavoriteTypes.map(
        (favorite) => favorite.key
      ),
    });
  };

  return useMutation(updateAdminSettings, {
    onSuccess: async () => {
      await rqClient.invalidateQueries(['admin-settings']);
    },
  });
};
