import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceOffer } from '../../maintenance-offer';

export class MustNotHaveDocumentAttached<
  T extends MaintenanceOffer
> extends DomainRule<T, T> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (entity.documentName) {
      throw new DomainRuleNotSatisfiedError(MustNotHaveDocumentAttached);
    }
    return entity as T & { documentName: string };
  }
}
