import React, { useContext } from 'react';

import { PageHeaderOverview } from '../../../components/Basics/Layouts/PageHeader';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import { RoutePlanContext } from '../../../contexts/RoutePlanContext';
import InstallationPlanPreview from './InstallationPlanPreview';
import InputBar from './components/InputBar';

const InstallationPlanPage = () => {
  const { isLoadingPlanRoute } = useContext(RoutePlanContext);

  return (
    <div className="pt-12 overflow-hidden bg-gray-lighter flex-1">
      <div className="flex flex-col h-full">
        <div className="px-8">
          <PageHeaderOverview>
            <div className="pl-4">Tourenplan</div>
          </PageHeaderOverview>
        </div>

        <div className="flex flex-1 flex-col overflow-hidden">
          <InputBar />

          <div className="flex flex-1 mt-2 overflow-y-auto px-8">
            <div className="flex-1 h-full">
              <InstallationPlanPreview />
            </div>
          </div>

          <LoadingSpinner isLoading={isLoadingPlanRoute}>
            Berechnung der Touren läuft...
          </LoadingSpinner>
        </div>
      </div>
    </div>
  );
};

export default InstallationPlanPage;
