import React from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';

import { InstallerDto, JobDto, RouteDto } from '@wartungshelden/shared-types';

import { Button } from '../../../../components';

const toZipCode = (job: JobDto) => `${job.zipCode},DE`; // TODO Check if we have to find out the country

const toAddressString = (installer: InstallerDto): string =>
  `${installer.address.street},${installer.address.zip} ${installer.address.city}`;

export const toGoogleMapsLink = (
  route: RouteDto,
  installer: InstallerDto
): string => {
  const routeCoordinateUrlPart = encodeURIComponent(
    route.jobs.map(toZipCode).join('|')
  );
  const installerAddressUrlPart = encodeURIComponent(
    toAddressString(installer)
  );

  return `https://www.google.com/maps/dir/?api=1&origin=${installerAddressUrlPart}&destination=${installerAddressUrlPart}&waypoints=${routeCoordinateUrlPart}&travelmode=driving`;
};

const GoogleMapsRouteButton: React.FC<{
  route: RouteDto;
  installer: InstallerDto;
}> = ({ route, installer }) => {
  return (
    <Button
      icon={<FaMapMarkedAlt />}
      className="small-button full-button outline-button !justify-start"
      onClick={() => window.open(toGoogleMapsLink(route, installer), '_blank')}
      label="Google Maps"
    />
  );
};

export default GoogleMapsRouteButton;
