import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { isFormikFallProtectionMaintenanceObject } from '../../../guards/isFormikMaintenanceObject';
import { FormikFallProtection } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import InputCheckFieldList from '../../Basics/Inputs/InputCheckFieldList';

type RoofAccessProps = {
  disabled?: boolean;
  index: number;
};

const RoofAccess: React.FC<React.PropsWithChildren<RoofAccessProps>> = ({
  disabled,
  index,
}) => {
  const { values, setFieldValue, status } =
    useFormikContext<FormikMaintenanceWizardValues>();
  const errorKeys = status ? Object.keys(status) : [];
  const maintenanceObject = values.maintenanceObjects[index];
  let fallProtectionMaintenanceObject: FormikFallProtection | undefined;
  if (isFormikFallProtectionMaintenanceObject(maintenanceObject)) {
    fallProtectionMaintenanceObject = maintenanceObject;
  }

  useEffect(() => {
    if (!fallProtectionMaintenanceObject?.other) {
      setFieldValue('otherText', '');
    }
  }, [fallProtectionMaintenanceObject?.other, setFieldValue]);

  return (
    <div className="mb-12">
      <div
        className={`font-bold mb-2 ${
          errorKeys.includes(`access`) ||
          errorKeys.includes(`maintenanceObjects.${index}.otherText`)
            ? 'text-red'
            : ''
        }`}
      >
        Zugang
      </div>
      <div className="mb-2">
        Der Zugang zur Absturzsicherung muss unbedingt gesichert erfolgen
        können! Nur so ist die Wartung durchführbar, und du vermeidest, dass dir
        Mehrkosten für die zusätzliche An- und Abreise entstehen.
      </div>
      <div>
        {fallProtectionMaintenanceObject && (
          <InputCheckFieldList
            label="Welchen Zugang kannst du zur Verfügung stellen bzw. organisieren?"
            labels={[
              'Gerüst',
              'Hubbühne',
              'Leiter',
              'Dachausstieg',
              'Steigleiter',
            ]}
            names={[
              `maintenanceObjects.${index}.scaffold`,
              `maintenanceObjects.${index}.liftingPlatform`,
              `maintenanceObjects.${index}.ladder`,
              `maintenanceObjects.${index}.roofExit`,
              `maintenanceObjects.${index}.climbLadder`,
            ]}
            disabled={disabled}
            index={index}
            fallProtectionMaintenanceObject={fallProtectionMaintenanceObject}
          />
        )}
      </div>
    </div>
  );
};

RoofAccess.defaultProps = {
  disabled: false,
};

export default RoofAccess;
