import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

const Tip = ({
  children,
  disabled,
}: {
  disabled: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className="items-center z-10">
      {!disabled && (
        <Tooltip
          placement="top"
          trigger="hover"
          overlay={children}
          overlayInnerStyle={{
            padding: 12,
            maxWidth: '18rem',
          }}
        >
          <FaQuestionCircle
            data-tip
            data-for="help-tooltip"
            className="text-black-abs"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default Tip;
