import { isDefined } from '@warthungshelden/shared-functions';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import * as Yup from 'yup';

import {
  MaintenanceObjectPriceDto,
  PriceInformationObjectDto,
} from '@wartungshelden/shared-types';

import { Card, RadioButtonField } from '../../../../../components';
import Button from '../../../../../components/Basics/Buttons/Button';
import Modal from '../../../../../components/Basics/Modals/Modal';
import {
  isPushLockSystemDto,
  isRopeSystemDto,
  isSingleAnchorSystemDto,
} from '../../../../../guards/isFallProtectionSystem';
import {
  isClimbingProtectionDto,
  isFallProtectionDto,
  isGroundStairsDto,
  isLadderDto,
  isOverpassDto,
  isPPEDto,
  isRailSystemDto,
  isRailingDto,
  isStairLadderDto,
} from '../../../../../guards/isMaintenanceType';
import {
  PriceInformationCombination,
  toPriceInformationObject,
} from '../../utils/combinations';
import toSummarizedObjectPrice from '../../utils/price-summary';
import {
  PricedCombination,
  toPriceCombination,
  toPricesWithDocu,
  toPricesWithoutDocu,
} from '../../utils/priced-combination';
import {
  CURRENT_DISCOUNT,
  getLabelForMaintenanceTypeOrFallProtectionSystem,
  getObjectSummarySubText,
  getSinglePrice,
} from '../../utils/utils';
import MaintenanceObjectSummary from '../MaintenanceObjectSummary';
import PricePosition from './PricePosition';
import SubPriceOverviewRow from './SubPriceOverviewRow';

interface PriceOverviewRowProps {
  pricedCombinations: PricedCombination[];
  onOpenEditModal: (objectsToEdit: PricedCombination[]) => void;
  onDelete: (objectsToDelete: PricedCombination[]) => void;
  showPriceWithoutDocu: boolean;
}

const PriceOverviewRow: React.FC<PriceOverviewRowProps> = ({
  pricedCombinations,
  onOpenEditModal,
  onDelete,
  showPriceWithoutDocu,
}) => {
  const [isGroupEditModalOpen, setIsGroupEditModalOpen] = useState(false);

  const pricesWithDocu = pricedCombinations
    .flatMap((combi) => toPricesWithDocu(combi))
    .filter(isDefined);

  const pricesWithoutDocu = pricedCombinations
    .flatMap((combi) => toPricesWithoutDocu(combi))
    .filter(isDefined);

  const summarizedPriceWithDocu = pricesWithDocu.reduce(
    toSummarizedObjectPrice,
    undefined
  );

  const summarizedPriceWithoutDocu = pricesWithoutDocu.reduce(
    toSummarizedObjectPrice,
    undefined
  );

  const isGroupOfPrices = pricedCombinations.length > 1;

  const toSummarizedObject = (
    summarizedObject: PriceInformationObjectDto,
    objectToBeSummarized: PriceInformationObjectDto
  ): PriceInformationObjectDto => {
    const { type: objectToBeSummarizedType } = objectToBeSummarized.object;
    const { type: summarizedObjectType } = summarizedObject.object;

    if (
      isFallProtectionDto(objectToBeSummarizedType) &&
      isFallProtectionDto(summarizedObjectType)
    ) {
      if (
        isSingleAnchorSystemDto(objectToBeSummarizedType.system) &&
        isSingleAnchorSystemDto(summarizedObjectType.system)
      ) {
        return {
          ...summarizedObject,
          object: {
            ...summarizedObject.object,
            type: {
              ...summarizedObjectType,
              system: {
                ...summarizedObjectType.system,
                singleAnchors:
                  summarizedObjectType.system.singleAnchors +
                  objectToBeSummarizedType.system.singleAnchors,
              },
            },
          },
        };
      }
      if (
        isPushLockSystemDto(objectToBeSummarizedType.system) &&
        isPushLockSystemDto(summarizedObjectType.system)
      ) {
        return {
          ...summarizedObject,
          object: {
            ...summarizedObject?.object,
            type: {
              ...summarizedObjectType,
              system: {
                ...summarizedObjectType.system,
                anchors:
                  summarizedObjectType.system.anchors +
                  objectToBeSummarizedType.system.anchors,
                sleeves:
                  summarizedObjectType.system.sleeves +
                  objectToBeSummarizedType.system.sleeves,
              },
            },
          },
        };
      }

      if (
        isRopeSystemDto(objectToBeSummarizedType.system) &&
        isRopeSystemDto(summarizedObjectType.system)
      ) {
        return {
          ...summarizedObject,
          object: {
            ...summarizedObject?.object,
            type: {
              ...summarizedObjectType,
              system: {
                ...summarizedObjectType.system,
                singleAnchors:
                  summarizedObjectType.system.singleAnchors +
                  objectToBeSummarizedType.system.singleAnchors,
                systemAnchors:
                  summarizedObjectType.system.systemAnchors +
                  objectToBeSummarizedType.system.systemAnchors,
                ropeLength:
                  summarizedObjectType.system.ropeLength +
                  objectToBeSummarizedType.system.ropeLength,
              },
            },
          },
        };
      }
    }

    if (isPPEDto(objectToBeSummarizedType) && isPPEDto(summarizedObjectType)) {
      return {
        ...summarizedObject,
        object: {
          ...summarizedObject?.object,
          type: {
            ...summarizedObjectType,
            quantity:
              summarizedObjectType.quantity + objectToBeSummarizedType.quantity,
          },
        },
      };
    }

    if (
      isClimbingProtectionDto(objectToBeSummarizedType) &&
      isClimbingProtectionDto(summarizedObjectType)
    ) {
      return {
        ...summarizedObject,
        object: {
          ...summarizedObject?.object,
          type: {
            ...summarizedObjectType,
            length:
              summarizedObjectType.length + objectToBeSummarizedType.length,
          },
        },
      };
    }

    if (
      isRailSystemDto(objectToBeSummarizedType) &&
      isRailSystemDto(summarizedObjectType)
    ) {
      return {
        ...summarizedObject,
        object: {
          ...summarizedObject?.object,
          type: {
            ...summarizedObjectType,
            length:
              summarizedObjectType.length + objectToBeSummarizedType.length,
            brackets:
              summarizedObjectType.brackets + objectToBeSummarizedType.brackets,
          },
        },
      };
    }

    if (
      isRailingDto(objectToBeSummarizedType) &&
      isRailingDto(summarizedObjectType)
    ) {
      return {
        ...summarizedObject,
        object: {
          ...summarizedObject?.object,
          type: {
            ...summarizedObjectType,
            length:
              summarizedObjectType.length + objectToBeSummarizedType.length,
          },
        },
      };
    }

    if (
      isLadderDto(objectToBeSummarizedType) &&
      isLadderDto(summarizedObjectType)
    ) {
      return {
        ...summarizedObject,
        object: {
          ...summarizedObject?.object,
          type: {
            ...summarizedObjectType,
            parts: [
              ...summarizedObjectType.parts,
              ...objectToBeSummarizedType.parts,
            ],
            numberOfPlatforms:
              summarizedObjectType.numberOfPlatforms +
              objectToBeSummarizedType.numberOfPlatforms,
          },
        },
      };
    }

    if (
      (isStairLadderDto(objectToBeSummarizedType) &&
        isStairLadderDto(summarizedObjectType)) ||
      (isOverpassDto(objectToBeSummarizedType) &&
        isOverpassDto(summarizedObjectType))
    ) {
      return {
        ...summarizedObject,
        object: {
          ...summarizedObject?.object,
          type: {
            ...summarizedObjectType,
            parts: [
              ...summarizedObjectType.parts,
              ...objectToBeSummarizedType.parts,
            ],
          },
        },
      };
    }

    if (
      isGroundStairsDto(objectToBeSummarizedType) &&
      isGroundStairsDto(summarizedObjectType)
    ) {
      return {
        ...summarizedObject,
        object: {
          ...summarizedObject?.object,
          type: {
            ...summarizedObjectType,
            amount:
              summarizedObjectType.amount + objectToBeSummarizedType.amount,
          },
        },
      };
    }

    return summarizedObject;
  };

  const toSummarizedCombination = (
    combinations: PriceInformationCombination[]
  ): PriceInformationCombination => {
    const summarizedCombination = combinations.reduce(
      (currentSummary, combination) => {
        return Object.entries(combination).reduce((summary, [key, value]) => {
          return {
            ...summary,
            [key]: [...(summary[key] ?? []), value],
            type: combination.type,
          };
        }, currentSummary);
      },
      // Used any as we can not have the fixed proerty set in the index type
      {} as any
    );

    return Object.entries(summarizedCombination)
      .filter(([, value]) => typeof value === 'object')
      .reduce<PriceInformationCombination>((summary, [key, value]) => {
        const objects = value as PriceInformationObjectDto[];
        const definedObjects = objects.filter(isDefined);

        const combinationSummary = definedObjects?.length
          ? definedObjects
              .slice(1)
              .reduce(toSummarizedObject, definedObjects[0])
          : definedObjects[0];

        return {
          ...summary,
          [key]: combinationSummary,
          type: summarizedCombination.type,
        };
      }, {} as PriceInformationCombination);
  };

  const maintenanceCombinationSummary = toSummarizedCombination(
    pricedCombinations.map((combi) => toPriceCombination(combi))
  );

  const maintenanceObjectSummary = toPriceInformationObject(
    maintenanceCombinationSummary // TODO Is this correct?
  );

  const calculateSingleAnchorsUnitPrice = (
    totalPrice: MaintenanceObjectPriceDto | undefined,
    hasDocu: boolean
  ) => {
    if (
      totalPrice &&
      isFallProtectionDto(maintenanceObjectSummary.object.type) &&
      isSingleAnchorSystemDto(maintenanceObjectSummary.object.type.system) &&
      maintenanceObjectSummary.object.type.system.singleAnchors &&
      (maintenanceObjectSummary.object.type.system.singleAnchors >= 40 ||
        isGroupOfPrices)
    ) {
      // don't show stuck price if it's more than 39 singleAnchors in total
      if (maintenanceObjectSummary.object.type.system.singleAnchors < 39) {
        return undefined;
      }
      if (hasDocu) {
        const missingDocuSurcharge =
          totalPrice?.absoluteVariablePriceComponents.actual.find(
            (variablePriceComponent) =>
              variablePriceComponent.type === 'documentation_missing'
          )?.value ?? 0;

        return (
          ((totalPrice?.variableNetPrice ?? 0 - missingDocuSurcharge) /
            maintenanceObjectSummary.object.type.system.singleAnchors) *
          CURRENT_DISCOUNT
        );
      }
      return (
        ((totalPrice?.variableNetPrice ?? 0) /
          maintenanceObjectSummary.object.type.system.singleAnchors) *
        CURRENT_DISCOUNT
      );
    }

    return undefined;
  };

  const singleAnchorUnitPriceWithDocu = calculateSingleAnchorsUnitPrice(
    summarizedPriceWithDocu,
    true
  );
  const singleAnchorUnitPriceWithoutDocu = calculateSingleAnchorsUnitPrice(
    summarizedPriceWithoutDocu,
    false
  );

  return (
    <Card paddingX={0} paddingY={0}>
      <div className="flex bg-gray flex-row justify-between items-center p-2 font-bold">
        {getLabelForMaintenanceTypeOrFallProtectionSystem(
          isFallProtectionDto(maintenanceObjectSummary.object.type) &&
            maintenanceObjectSummary.object.type.system
            ? maintenanceObjectSummary.object.type.system.system
            : maintenanceObjectSummary.object.type.type,
          isPPEDto(maintenanceObjectSummary.object.type) &&
            maintenanceObjectSummary.object.type.systemType
            ? maintenanceObjectSummary.object.type.systemType
            : ''
        )}
        <div className="flex flex-row space-x-2">
          <Button
            className="primary-button small-button"
            onClick={() => {
              if (isGroupOfPrices) {
                setIsGroupEditModalOpen(true);
              } else {
                onOpenEditModal(pricedCombinations);
              }
            }}
            dataCy="price-position-edit-button"
            icon={<FaEdit />}
          />
          <Button
            className="alert-button small-button"
            onClick={() => onDelete(pricedCombinations)}
            dataCy="price-position-delete-button"
            icon={<FaTrashAlt />}
          />
        </div>
      </div>
      <div
        className={`flex ${isGroupOfPrices ? 'border-b-2 border-dashed' : ''}`}
      >
        <div className="w-1/3 bg-gray-light border-r-2 ">
          <MaintenanceObjectSummary
            key={maintenanceObjectSummary.object.id}
            combination={maintenanceCombinationSummary}
            hasSubprices={isGroupOfPrices}
          />
        </div>
        <div className="w-2/3 flex">
          <div className="flex flex-col py-2 pl-4 pr-4 border-r-2 border-dashed w-1/2">
            {summarizedPriceWithDocu && (
              <PricePosition
                singlePrice={getSinglePrice(summarizedPriceWithDocu, true)}
                hasDocu
                singleAnchorsUnitPrice={
                  isGroupOfPrices ? undefined : singleAnchorUnitPriceWithDocu
                }
                showSinglePrice={!isGroupOfPrices}
                showHeadline={showPriceWithoutDocu}
                actualAbsoulutePriceComponents={
                  summarizedPriceWithDocu.absoluteVariablePriceComponents.actual
                }
              />
            )}
          </div>
          <div className="flex flex-col p-2 pl-4 w-1/2">
            {summarizedPriceWithoutDocu && showPriceWithoutDocu ? (
              <PricePosition
                singlePrice={getSinglePrice(summarizedPriceWithoutDocu, false)}
                hasDocu={false}
                singleAnchorsUnitPrice={
                  isGroupOfPrices ? undefined : singleAnchorUnitPriceWithoutDocu
                }
                showSinglePrice={!isGroupOfPrices}
                showHeadline
                actualAbsoulutePriceComponents={
                  summarizedPriceWithoutDocu.absoluteVariablePriceComponents
                    .actual
                }
              />
            ) : (
              <div className="flex-1" />
            )}
          </div>
        </div>
      </div>
      {isGroupOfPrices &&
        pricedCombinations.map((priceCombination) => {
          return (
            <SubPriceOverviewRow
              key={priceCombination.primarySystem.combination.object.id}
              singleSubPricedCombination={priceCombination}
              singleAnchorUnitPriceWithDocu={singleAnchorUnitPriceWithDocu}
              singleAnchorUnitPriceWithoutDocu={
                singleAnchorUnitPriceWithoutDocu
              }
              showPriceWithoutDocu={showPriceWithoutDocu}
            />
          );
        })}
      <Modal isOpen={isGroupEditModalOpen} center>
        <div
          className="py-6"
          data-cy="priceCalculationMaintenanceObjectGroupModalContent"
        >
          <div className="font-bold text-lg mb-2">
            Wähle zum Bearbeiten ein Produkt aus.
          </div>
          <div className="font-sx font-bold">
            {getLabelForMaintenanceTypeOrFallProtectionSystem(
              isFallProtectionDto(maintenanceObjectSummary.object.type) &&
                maintenanceObjectSummary.object.type.system
                ? maintenanceObjectSummary.object.type.system.system
                : maintenanceObjectSummary.object.type.type,
              isPPEDto(maintenanceObjectSummary.object.type) &&
                maintenanceObjectSummary.object.type.systemType
                ? maintenanceObjectSummary.object.type.systemType
                : ''
            )}
          </div>
          <Formik
            initialValues={{
              selectedProduct: undefined,
            }}
            onSubmit={(values) => {
              if (values?.selectedProduct) {
                onOpenEditModal([values.selectedProduct]);
                setIsGroupEditModalOpen(false);
              }
            }}
            validationSchema={Yup.object().shape({
              selectedProduct: Yup.object().required(),
            })}
            validateOnMount
            enableReinitialize
          >
            {({ isValid, submitForm }) => (
              <>
                <div className="space-y-2 mt-4 pb-10 pl-4 max-h-96 overflow-y-auto">
                  {pricedCombinations.map((priceCombination) => {
                    return (
                      <RadioButtonField
                        name="selectedProduct"
                        value={priceCombination}
                        label={getObjectSummarySubText(
                          toPriceCombination(priceCombination)
                        )}
                      />
                    );
                  })}
                </div>
                <div className="flex space-x-4 px-8">
                  <Button
                    className="outline-button"
                    label="Abbrechen"
                    onClick={() => {
                      setIsGroupEditModalOpen(false);
                    }}
                  />
                  <Button
                    className="primary-button"
                    label="Bearbeiten"
                    disabled={!isValid}
                    onClick={async () => {
                      await submitForm();
                    }}
                  />
                </div>
              </>
            )}
          </Formik>
        </div>
      </Modal>
    </Card>
  );
};

export default PriceOverviewRow;
