import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import BackButton from '../../components/Basics/Buttons/BackButton';
import HeaderLogout from '../../components/Header/HeaderLogout';

const SettingsMainPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();

  const onNavigateBack = () => {
    if (window.history.length === 1) {
      window.close();
    }
    navigate(-1);
  };

  return (
    <>
      <HeaderLogout>
        <BackButton onClick={onNavigateBack} />
      </HeaderLogout>
      <Outlet />
    </>
  );
};

export default SettingsMainPage;
