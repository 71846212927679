import React from 'react';

import { LadderDto } from '@wartungshelden/shared-types';

import { toLadderCategoryLabel } from '../../utils/utils';
import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface LadderPropertiesProps {
  ladder: LadderDto;
  hasSubprices: boolean;
}

const LadderProperties: React.FC<LadderPropertiesProps> = ({
  ladder,
  hasSubprices,
}) => (
  <>
    <MaintenanceObjectSummaryRow
      label="Kategorie"
      value={toLadderCategoryLabel(ladder, false)}
      hasSubprices={false}
    />

    <MaintenanceObjectSummaryRow
      label="Steigleitern"
      value={ladder.parts.length}
      unit="Stück"
      hasSubprices={hasSubprices}
    />

    <MaintenanceObjectSummaryRow
      label="Podeste"
      value={ladder.numberOfPlatforms}
      unit="Stück"
      hasSubprices={hasSubprices}
    />
  </>
);

export default LadderProperties;
