import React, { useContext } from 'react';

import { SessionContext } from '../../../contexts/SessionContext';
import DocumentationValidityChanger from './DocumentationValidityChanger';
import MaintenanceDocumentationPreview, {
  MaintenanceDocumentationPreviewProps,
} from './Upload/MaintenanceDocumentationPreview';

const MaintenanceDocumentationValidityPreview: React.FC<
  React.PropsWithChildren<
    MaintenanceDocumentationPreviewProps & {
      maintenanceObjectId?: string;
    }
  >
> = ({ maintenanceObjectId, ...props }) => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const { documentationOrFile } = props;

  const document =
    documentationOrFile instanceof File ? null : documentationOrFile;

  const documentIsValid = Boolean(
    document &&
      maintenanceObjectId &&
      document.validFor?.includes(maintenanceObjectId) &&
      !document.invalidFor?.includes(maintenanceObjectId) &&
      !document.notRelevantFor?.includes(maintenanceObjectId)
  );

  const documentNotRelevant = Boolean(
    document &&
      maintenanceObjectId &&
      document.notRelevantFor?.includes(maintenanceObjectId) &&
      !document.invalidFor?.includes(maintenanceObjectId) &&
      !document.validFor?.includes(maintenanceObjectId)
  );

  const documentNotValidated = Boolean(
    document &&
      maintenanceObjectId &&
      !document?.validFor?.includes(maintenanceObjectId) &&
      !document.invalidFor?.includes(maintenanceObjectId) &&
      !document.notRelevantFor?.includes(maintenanceObjectId)
  );

  return (
    <div className="flex flex-col relative">
      <MaintenanceDocumentationPreview
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        maintenanceOwnerId={
          isMaintenanceTeamMemberAdmin ? props.maintenanceOwnerId : undefined
        }
        documentIsValid={
          isMaintenanceTeamMemberAdmin || documentNotValidated
            ? undefined
            : documentIsValid
        }
      />
      {document && (
        <div className="flex flex-row mt-2">
          {isMaintenanceTeamMemberAdmin && maintenanceObjectId && (
            <DocumentationValidityChanger
              document={document}
              documentNotValidated={documentNotValidated}
              documentIsValid={documentIsValid}
              documentNotRelevant={documentNotRelevant}
              maintenanceObjectId={maintenanceObjectId}
            />
          )}
        </div>
      )}
    </div>
  );
};

MaintenanceDocumentationValidityPreview.defaultProps = {
  maintenanceObjectId: undefined,
};

export default MaintenanceDocumentationValidityPreview;
