import { Field } from 'formik';
import React from 'react';

interface TextareaRowProps {
  name: string;
  label: string;
  placeholder: string;
  rows: number;
  displayInSingleRow?: boolean;
  disabled?: boolean;
  labelStyle?: string;
}

const TextareaRow: React.FC<React.PropsWithChildren<TextareaRowProps>> = ({
  name,
  placeholder,
  rows,
  label,
  displayInSingleRow,
  disabled,
  labelStyle,
}) => (
  <div className={`${displayInSingleRow && 'flex'} w-full`}>
    <label
      htmlFor={name}
      className={`${displayInSingleRow && 'font-bold w-64 mr-4'} ${labelStyle}`}
    >
      {label}
    </label>
    <Field
      as="textarea"
      data-cy="components-basics-input-field"
      rows={rows}
      id={name}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      className={`
                  mt-2
                  mb-4
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  ${disabled ? 'text-gray-300' : 'text-gray-700'}
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
    />
  </div>
);

TextareaRow.defaultProps = {
  displayInSingleRow: false,
  disabled: false,
  labelStyle: undefined,
};

export default TextareaRow;
