import { Combobox } from '@headlessui/react';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { InstallerDto } from '@wartungshelden/shared-types';

interface SectionComboBoxProps<T extends { id: string }> {
  currentValue: T;
  topSection: T[];
  bottomSection: T[];
  keyToDisplay: keyof T;
  onChange: (changed: T) => void;
  disabled?: boolean;
}

const SectionComboBox = <T extends { id: string }>({
  currentValue,
  topSection,
  bottomSection,
  onChange,
  keyToDisplay,
  disabled,
}: SectionComboBoxProps<T>) => {
  const [selectedObject, setSelectedObject] = useState(currentValue);
  const [query, setQuery] = useState('');

  const filteredTopSection =
    query === ''
      ? topSection
      : topSection.filter((value) => {
          const valueProperty = value[keyToDisplay];
          if (typeof valueProperty === 'string') {
            return valueProperty
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, ''));
          }
          return value;
        });

  const filteredBottomSection =
    query === ''
      ? bottomSection
      : bottomSection.filter((value) => {
          const valueProperty = value[keyToDisplay];
          if (typeof valueProperty === 'string') {
            return valueProperty
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, ''));
          }
          return value;
        });

  return (
    <div className="flex-1" data-cy="section-combobox">
      <Combobox
        value={selectedObject}
        onChange={(installer) => {
          setSelectedObject(installer);
          onChange(installer);
        }}
      >
        <div className="relative mt-1">
          <div className="relative w-full border-2 box-border rounded-lg border-gray-light cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-600">
            <Combobox.Input
              className={`w-full h-12 border-none py-2 pl-3 pr-10 test-base leading-5 focus:ring-0 ${
                disabled ? 'text-gray-dark bg-gray-lighter' : 'text-gray-900'
              }`}
              displayValue={(installer: InstallerDto) =>
                installer ? `${installer.name}` : 'Not found'
              }
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <FaChevronDown
                className={`${disabled ? 'text-gray-300' : 'text-gray-dark'}`}
              />
            </Combobox.Button>
          </div>
          <Combobox.Options className="absolute mt-1 max-h-60 w-full z-10 overflow-auto text-base rounded-md bg-white py-1 focus:outline-none text-base border-l-2 border-r-2 border-b-2 border-black">
            {filteredTopSection.length === 0 &&
            filteredBottomSection.length === 0 &&
            query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nichts gefunden.
              </div>
            ) : (
              <>
                <h3 className="py-2 px-4 font-bold">Empfehlungen</h3>
                {filteredTopSection.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-4 ${
                        active ? 'bg-blue-abs text-white' : 'text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    {`${option[keyToDisplay]}`}
                  </Combobox.Option>
                ))}
                <hr className="py-2 border-t-2 border-black" />
                <h3 className="px-4 font-bold">Alle</h3>
                {filteredBottomSection.map((option) => {
                  return (
                    <Combobox.Option
                      key={option.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? 'bg-blue-abs text-white' : 'text-gray-900'
                        }`
                      }
                      value={option}
                    >
                      {`${option[keyToDisplay]}`}
                    </Combobox.Option>
                  );
                })}
              </>
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
};

SectionComboBox.defaultProps = {
  disabled: false,
};
export default SectionComboBox;
