import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import {
  MaintenanceAdministrationBuildingDto,
  SelectLineMaintenanceOrderDto,
} from '@wartungshelden/shared-types';

import InfoText from './InfoText';
import InformationContainer from './InformationContainer';

interface Props {
  selectLineData?: SelectLineMaintenanceOrderDto;
  building?: MaintenanceAdministrationBuildingDto;
}

const GeneralInfo: React.FC<Props> = ({ selectLineData, building }) => {
  return (
    <InformationContainer>
      <InfoText label="Auftrags-Nr.:" indicateMissingData>
        {selectLineData?.orderNumber}
      </InfoText>
      <InfoText label="Vertrags-Nr.:" indicateMissingData>
        {selectLineData?.contractNumber}
      </InfoText>

      <div className="mt-4">
        <InfoText label="Docu-App:">
          {selectLineData?.documentationAvailable ? (
            <>
              <FaCheckCircle
                className="text-xl text-green-green inline"
                display="inline"
              />
              {building && (
                <a
                  href={`https://docu.lock-book.com/buildings/${building?.id}`}
                  className="hover:border-b-2 hover:border-blue-abs text-blue-abs ml-4"
                  target="_blank"
                  rel="noreferrer"
                >
                  In Lock Book Docu öffnen
                </a>
              )}
            </>
          ) : (
            <FaTimesCircle className="text-xl text-red inline" />
          )}
        </InfoText>
      </div>
    </InformationContainer>
  );
};

GeneralInfo.defaultProps = {
  selectLineData: undefined,
  building: undefined,
};

export default GeneralInfo;
