import { isPhoneNumber } from 'class-validator';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { FaSignInAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { PolicyTypeEnum } from '@wartungshelden/shared-types';

import { Button } from '../../components';
import AuthInputField from '../../components/Auth/AuthInputField';
import AuthLinkButton from '../../components/Auth/AuthLinkButton';
import AuthPhoneInputField from '../../components/Auth/AuthPhoneInputField';
import AuthPrimaryButton from '../../components/Auth/AuthPrimaryButton';
import FieldError from '../../components/Basics/FieldError';
import HorizontalLine from '../../components/Basics/HorizontalLine';
import CheckBoxField from '../../components/Basics/Inputs/CheckBox/CheckBoxField';
import PolicyAuthFooter from '../../components/Policies/PolicyAuthFooter';
import { SessionContext } from '../../contexts/SessionContext';
import { usePublicPolicies } from '../../services/api/policies/policy-api';
import ContactButton from './ContactButton';

const RegisterPage = ({ isAdminLogin }: { isAdminLogin?: boolean }) => {
  const navigate = useNavigate();
  const { signUp } = useContext(SessionContext);
  const [isEmailSelected, setIsEmailSelected] = useState(true);

  const { data: availablePolicies } = usePublicPolicies('latest');

  const termsOfService = availablePolicies?.find(
    ({ type }) => type === PolicyTypeEnum.TERMS_OF_SERVICE
  );

  const dataPrivacyPolicy = availablePolicies?.find(
    ({ type }) => type === PolicyTypeEnum.DATA_PROTECTION
  );

  const register = (
    username: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    return signUp(username, password, firstName, lastName);
  };

  const navigateToLogin = () => {
    navigate(isAdminLogin ? '/admin/login' : '/login');
  };

  const navigateToVerifyPage = (email?: string, phone?: string) => {
    navigate(isAdminLogin ? '/admin/register/verify' : '/register/verify', {
      state: { email, phone },
    });
  };

  const SignupValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-Mail nicht gültig')
      .when({
        is: () => isEmailSelected,
        then: (schema) => schema.required('E-Mail erforderlich'),
        otherwise: (schema) => schema.optional(),
      }),
    phone: Yup.string().when({
      is: () => !isEmailSelected,
      then: (schema) =>
        schema.test({
          message: 'Rufnummer nicht gültig',
          test: (number) => isPhoneNumber(number ?? ''),
        }),
      otherwise: (schema) => schema.optional(),
    }),
    password: Yup.string()
      .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein')
      .matches(/\d/, 'Das Passwort muss mindestens eine Zahl enthalten')
      .matches(
        /[a-z]/,
        'Das Passwort muss mindestens einen Kleinbuchstaben enthalten'
      )
      .matches(
        /[A-Z]/,
        'Das Passwort muss mindestens einen Großbuchstaben enthalten'
      )
      .matches(
        /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/,
        'Das Passwort muss mindestens eines der folgenden Sonderzeichen enthalten: (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =)'
      )
      .required('Passwort erforderlich'),
    firstName: Yup.string().required('Vorname erforderlich'),
    lastName: Yup.string().required('Nachname erforderlich'),
    accept: Yup.boolean().is([true]),
  });

  return (
    <>
      <div className="absolute top-4 right-4">
        <ContactButton />
      </div>
      <div className="flex justify-center px-8 pt-4 pb-4">
        <img src="/assets/logo.svg" alt="ABS Wartung" className="w-3/5" />
      </div>
      <Formik
        validationSchema={SignupValidationSchema}
        initialValues={{
          email: '',
          phone: '',
          password: '',
          firstName: '',
          lastName: '',
          accept: false,
        }}
        onSubmit={async (values) => {
          const success = await register(
            isEmailSelected ? values.email.toLowerCase() : values.phone,
            values.password,
            values.firstName,
            values.lastName
          );

          if (success) {
            navigateToVerifyPage(
              values.email.length ? values.email : undefined,
              values.phone.length ? values.phone : undefined
            );
          }
        }}
      >
        {({
          submitForm,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <div className="bg-white shadow w-full rounded-lg mb-2">
            <div className="px-5 py-7">
              <div className="py-2">Erstelle ein neues Benutzerkonto.</div>
              <div className="pt-3">
                <AuthInputField name="firstName" label="Vorname" />
                <FieldError
                  message={errors.firstName}
                  visible={Boolean(errors.firstName && touched.firstName)}
                />
              </div>
              <div className="pt-3">
                <AuthInputField name="lastName" label="Nachname" />
                <FieldError
                  message={errors.lastName}
                  visible={Boolean(errors.lastName && touched.lastName)}
                />
              </div>
              <div className="pt-3">
                {isEmailSelected ? (
                  <AuthInputField name="email" label="E-Mail" />
                ) : (
                  <AuthPhoneInputField name="phone" label="Telefon" />
                )}
              </div>
              <AuthLinkButton
                label={`Stattdessen mit ${
                  isEmailSelected ? 'Telefonnummer' : 'E-Mail'
                } registrieren`}
                onClick={() => {
                  setFieldValue(isEmailSelected ? 'email' : 'phone', '');
                  setFieldTouched(isEmailSelected ? 'email' : 'phone', false);
                  setIsEmailSelected((prev) => !prev);
                }}
              />
              <FieldError
                message={errors.email}
                visible={Boolean(errors.email && touched.email)}
              />
              <FieldError
                message={errors.phone}
                visible={Boolean(errors.phone && touched.phone)}
              />
              <div className="pt-3">
                <AuthInputField
                  name="password"
                  label="Passwort"
                  type="password"
                />
                <p className="pt-2 text-sm text-gray-600">
                  Das Passwort sollte mindenstens 8 Zeichen lang sein, einen
                  Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein
                  Sonderzeichen enthalten.
                </p>
                <FieldError
                  message={errors.password}
                  visible={Boolean(errors.password && touched.password)}
                />
              </div>
              <div className="pt-7">
                <CheckBoxField
                  name="accept"
                  label={
                    <span>
                      Ich bestätige, dass ich mit den{' '}
                      <a
                        className="underline text-blue-abs"
                        href={termsOfService?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Nutzungsbedingungen
                      </a>{' '}
                      von ABS Wartung einverstanden bin und die{' '}
                      <a
                        className="underline text-blue-abs"
                        href={dataPrivacyPolicy?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Datenschutzbestimmungen
                      </a>{' '}
                      gelesen habe.
                    </span>
                  }
                />
              </div>
              <div className="pt-7">
                <AuthPrimaryButton
                  kind="register"
                  onClick={submitForm}
                  label="Nutzerkonto anlegen"
                  className="full-button primary-button"
                  disabled={!isValid || !dirty}
                  icon={<FaSignInAlt color="white" />}
                />
              </div>
            </div>
            <HorizontalLine />
            <div className="py-5">
              <div className="grid grid-cols-1 gap-1 px-5">
                <Button
                  onClick={navigateToLogin}
                  className="outline-button full-button"
                >
                  Mit bestehendem Nutzerkonto anmelden
                </Button>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <PolicyAuthFooter />
    </>
  );
};

RegisterPage.defaultProps = {
  isAdminLogin: false,
};

export default RegisterPage;
