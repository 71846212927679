import React from 'react';
import 'react-calendar/dist/Calendar.css';
import { FaReply } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { PageContainer } from '../../../components';
import { PageHeaderOverview } from '../../../components/Basics/Layouts/PageHeader';
import { InfoMessageBox } from '../../../components/Basics/MessageBox';
import { routes } from '../../../constants/routes';
import { useBuildings } from '../../../services/api/buildings/buildings-api';
import { useMaintenanceObjects } from '../../../services/api/maintenance-objects/maintenance-objects-api';
import BuildingsDetailsGraph from './components/BuildingsDetailsGraph';
import BuildingsGraph from './components/BuildingsGraph';
import BuildingsGraphLegend from './components/BuildingsGraphLegend';
import {
  generateBuildingsDetailGraphData,
  generateBuildingsOverallGraphData,
} from './services';

const DashboardPage = () => {
  const { data: buildings } = useBuildings();
  const { data: maintenances } = useMaintenanceObjects();

  const graphData = generateBuildingsOverallGraphData(buildings, maintenances);
  const detailsGraphData = generateBuildingsDetailGraphData(
    buildings,
    maintenances
  );

  return (
    <PageContainer>
      <PageHeaderOverview>
        <div className="pl-4">Deine Gebäude im Überblick</div>
      </PageHeaderOverview>

      {graphData.length > 0 ? (
        <div className="h-full pb-20 bg-white pl-4">
          <div className="flex items-center justify-start mt-12">
            <div className="w-512 flex flex-col">
              <div className="text-2xl mb-8">
                Handlungsbedarf bei Deinen Gebäuden
              </div>
              <BuildingsGraph graphData={graphData} />
            </div>

            <BuildingsGraphLegend graphData={graphData} />
          </div>

          <div className="flex items-center justify-start mt-16">
            <div className="w-full flex flex-col items-start">
              <div className="w-512 text-2xl mb-8">
                Handlungsbedarf je Gebäude
              </div>
              <BuildingsDetailsGraph graphData={detailsGraphData} />
            </div>
          </div>
        </div>
      ) : (
        <InfoMessageBox
          label="Bevor Du hier eine Übersicht sehen kannst, lege zuerst ein Gebäude an."
          isCentered
        >
          <Link
            to={`/${routes.buildings}`}
            className="flex flex-row items-center"
          >
            <FaReply className="transform -scale-x-1" />
            <span className="ml-2 underline font-bold">zu den Gebäuden</span>
          </Link>
        </InfoMessageBox>
      )}
    </PageContainer>
  );
};

export default DashboardPage;
