import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { ProvidedServiceEnum } from '@wartungshelden/shared-types';

import { FORMIK_SELECT_DEFAULT_VALUE } from '../../constants/MaintenanceConstants';
import { getDefaultFrequencies } from '../../constants/MaintenanceUtils';
import {
  labelToProvidedService,
  providedServiceTypes,
} from '../../pages/UserPages/maintenance/MaintenanceObject';
import { FormikFallProtection } from '../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikLadder } from '../../pages/UserPages/maintenance/maintenanceWizard/types/FormikLadder';
import { FormikMaintenanceWizardValues } from '../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { FormikPPE } from '../../pages/UserPages/maintenance/maintenanceWizard/types/FormikPPE';
import ButtonList from '../Basics/Inputs/ButtonList';
import ConfirmationModal from '../Basics/Modals/ConfirmationModal';
import { InputField, SelectField } from '../index';

type ServiceTypeSelectProps = {
  disabled: boolean;
  isRequired?: boolean;
};

const ServiceTypeSelectField: React.FC<
  React.PropsWithChildren<ServiceTypeSelectProps>
> = ({ disabled, isRequired }) => {
  const { setFieldValue, values } =
    useFormikContext<FormikMaintenanceWizardValues>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState(values.type);
  const [isOtherServiceTypeTriggered, setIsOtherServiceTypeTriggered] =
    useState<boolean>(
      values.type !== undefined &&
        values.type !== null &&
        ![
          ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT,
          ProvidedServiceEnum.FALL_PROTECTION,
          ProvidedServiceEnum.LADDER,
          '',
        ].includes(labelToProvidedService(values.type))
    );

  return (
    <>
      <SelectField
        label={`Wartungstyp${isRequired ? '*' : ''}`}
        name="type"
        placeHolder="Wartungstyp wählen"
        onClick={() => !disabled && setIsModalVisible(true)}
        disabled={disabled}
      />
      <ConfirmationModal
        heading={
          isOtherServiceTypeTriggered
            ? 'Möchtest du einen eigenen Wartungstypen hinzufügen?'
            : 'Wartungtyp wählen'
        }
        onAccept={() => {
          if (!selectedItem || isOtherServiceTypeTriggered) {
            setIsModalVisible(false);
            return;
          }

          setFieldValue('type', selectedItem);
          setFieldValue(
            'frequency',
            getDefaultFrequencies(labelToProvidedService(selectedItem))
          );

          if (
            labelToProvidedService(selectedItem) ===
            ProvidedServiceEnum.FALL_PROTECTION
          ) {
            const formikFallProtection: FormikFallProtection = {
              name: '',
              climbLadder: false,
              ladder: false,
              other: false,
              otherText: '',
              liftingPlatform: false,
              roofExit: false,
              scaffold: false,
              selectedFiles: [],
              systemName: FORMIK_SELECT_DEFAULT_VALUE,
            };

            setFieldValue('maintenanceObjects', [formikFallProtection]);
          } else if (
            labelToProvidedService(selectedItem) ===
            ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT
          ) {
            const formikPPE: FormikPPE = {
              name: '',
              manufacturer: undefined,
              systemType: 'default',
              category: '',
              quantity: 0,
            };
            setFieldValue('maintenanceObjects', [formikPPE]);
          } else if (
            labelToProvidedService(selectedItem) === ProvidedServiceEnum.LADDER
          ) {
            const formikLadder: FormikLadder = {
              name: '',
              length: 0,
              numberOfPlatforms: 0,
            };
            setFieldValue('maintenanceObjects', [formikLadder]);
          }

          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setIsOtherServiceTypeTriggered(false);
        }}
        isVisible={isModalVisible}
      >
        <div className="flex flex-col content-between h-full">
          {isOtherServiceTypeTriggered ? (
            <>
              <div className="grow px-4">
                <InputField name="type" placeholder="Wartungstyp eintragen" />
              </div>
              <p className="px-4 mb-2">
                <span className="font-bold">Beachte:</span> Eigene Wartungstypen
                können aktuell noch nicht beauftragt werden.
              </p>
              <p className="px-4 mb-2">
                Du hast allerdings die Möglichkeit eigenen Wartungstypen
                anzulegen und zu verwalten. Zudem können wir dich an die nächste
                Fälligkeit der nächsten Wartung erinnern.
              </p>
            </>
          ) : (
            <div className="grow px-4">
              <ButtonList
                setSelectedItem={setSelectedItem}
                items={providedServiceTypes.sort()}
                selectedItem={selectedItem}
              />
            </div>
          )}
          <button
            type="button"
            onClick={() => setIsOtherServiceTypeTriggered((prev) => !prev)}
          >
            <div className="p-4 bg-blue-200 flex items-center">
              {isOtherServiceTypeTriggered ? (
                <>
                  <FaArrowLeft className="mr-2" />
                  <div>Zurück zu den Standard Wartungstypen</div>
                </>
              ) : (
                <>
                  <div>Wartungstyp ist nicht aufgelistet?</div>
                  <FaArrowRight className="ml-2" />
                </>
              )}
            </div>
          </button>
        </div>
      </ConfirmationModal>
    </>
  );
};
ServiceTypeSelectField.defaultProps = {
  isRequired: false,
};
export default ServiceTypeSelectField;
