import React, { useContext } from 'react';

import { CustomerOrMaintenanceTeamOfferDto } from '@wartungshelden/shared-types';

import SingleFileUpload from '../../../components/Basics/Documents/SingleFileUpload';
import {
  HintHighlightContext,
  ORDER_CONFIRMATION_NOT_UPLOADED_ERROR,
} from '../../../contexts/HintHighlightContext';
import {
  useAttachOrderConfirmationFile,
  useOrderConfirmationFile,
  useRemoveOrderConfirmationFile,
} from '../../../services/api/maintenance-offers/maintenance-order-confirmation-document-api';

interface OrderConfirmationFileUploadProps {
  offer?: CustomerOrMaintenanceTeamOfferDto | null;
  deleteDisabled?: boolean;
}

const OrderConfirmationFileUpload: React.FC<
  React.PropsWithChildren<OrderConfirmationFileUploadProps>
> = ({ offer, deleteDisabled }) => {
  const { clearHighlight } = useContext(HintHighlightContext);

  const { mutateAsync: deleteOrderConfirmationFile, isLoading: isDeleting } =
    useRemoveOrderConfirmationFile();

  const { mutateAsync: attachOrderConfirmationFile, isLoading: isUploading } =
    useAttachOrderConfirmationFile();

  const { isLoading: isFileLoading, data: loadedFile } =
    useOrderConfirmationFile(
      offer?.id,
      !!offer?.order?.confirmationDocumentName && !isDeleting
    );

  const isLoading = isFileLoading || isDeleting || isUploading;

  return (
    <SingleFileUpload
      showDeleteFile={!deleteDisabled && offer?.order?.status === 'UNCONFIRMED'}
      onDeleteFile={() => deleteOrderConfirmationFile({ offerId: offer?.id })}
      onAttachFile={(acceptedFile) => {
        clearHighlight(
          `${ORDER_CONFIRMATION_NOT_UPLOADED_ERROR}${offer?.requestId}`
        );
        return attachOrderConfirmationFile({
          offerId: offer?.id,
          file: acceptedFile,
        });
      }}
      isLoading={isLoading}
      file={loadedFile ?? undefined}
    />
  );
};

OrderConfirmationFileUpload.defaultProps = {
  offer: undefined,
  deleteDisabled: undefined,
};

export default OrderConfirmationFileUpload;
