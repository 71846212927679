import { useFormikContext } from 'formik';
import React from 'react';

import { isServiceAlreadySupported } from '../../../constants/MaintenanceConstants';
import {
  getDefaultFrequencies,
  isDefaultFrequency,
} from '../../../constants/MaintenanceUtils';
import { labelToProvidedService } from '../../../pages/UserPages/maintenance/MaintenanceObject';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { Toggle, UnitInputField } from '../../index';

interface FrequencyToggleProps {
  isMaintenanceReadOnly: boolean;
  isRequired?: boolean;
}

const FrequencyToggle: React.FC<
  React.PropsWithChildren<FrequencyToggleProps>
> = ({ isMaintenanceReadOnly, isRequired }) => {
  const { values, setFieldValue } =
    useFormikContext<FormikMaintenanceWizardValues>();

  const providedServiceType = labelToProvidedService(values.type || '');

  return (
    <div className="flex items-center w-full">
      <UnitInputField
        name="frequency"
        label={`Häufigkeit${isRequired ? '*' : ''}`}
        unit="Monate"
        disabled={
          isMaintenanceReadOnly ||
          (values.type !== undefined &&
            isServiceAlreadySupported(providedServiceType) &&
            isDefaultFrequency(values.frequency, providedServiceType))
        }
      />
      {values.type && isServiceAlreadySupported(providedServiceType) && (
        <Toggle
          label="gemäß Standard"
          checked={isDefaultFrequency(values.frequency, providedServiceType)}
          name="frequency_toggle"
          disabled={isMaintenanceReadOnly}
          onChange={() => {
            if (isDefaultFrequency(values.frequency, providedServiceType)) {
              setFieldValue('frequency', '');
            } else {
              if (!values.type) return;
              setFieldValue(
                'frequency',
                getDefaultFrequencies(providedServiceType)
              );
            }
          }}
        />
      )}
    </div>
  );
};
FrequencyToggle.defaultProps = {
  isRequired: false,
};
export default FrequencyToggle;
