import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  FallProtection,
  MaintenanceType,
  PushLockFallProtectionSystem,
} from '../../../maintenance-object';

export class PushLockSystemMandatoryValuesCantBeZeroRule<
  T extends { type: MaintenanceType }
> extends DomainRule<T, T> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (
      entity.type instanceof FallProtection &&
      entity.type.system instanceof PushLockFallProtectionSystem &&
      entity.type.system.sleeves === 0 &&
      entity.type.system.anchors === 0
    ) {
      throw new DomainRuleNotSatisfiedError(
        PushLockSystemMandatoryValuesCantBeZeroRule
      );
    }
    return entity;
  }
}
