import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BuildingDto,
  CurrentMaintenanceObjectDto,
  CustomerOrMaintenanceTeamRequestDto,
  currentMaintenanceObjectId,
} from '@wartungshelden/shared-types';

import groupRequestObjectsByBuildings from '../../constants/MaintenanceRequestUtils';
import { adminRoutePrefix } from '../../constants/routes';
import {
  MAINTENANCE_OBJECT_DOCUMENTATION_NOT_VALIDATED_ERROR,
  MAINTENANCE_OBJECT_IS_REOPENED,
  OFFER_NOT_UPLOADED_ERROR,
  ORDER_CONFIRMATION_NOT_UPLOADED_ERROR,
} from '../../contexts/HintHighlightContext';
import { SessionContext } from '../../contexts/SessionContext';
import { providedServiceToLabel } from '../../pages/UserPages/maintenance/MaintenanceObject';
import BackButton from '../Basics/Buttons/BackButton';
import HintHighlight from '../HintHighlight/HintHighlight';

type MaintenanceRequestMenuProps = {
  currentBuildings?: BuildingDto[];
  currentRequest?: CustomerOrMaintenanceTeamRequestDto;
  currentRequestMaintenances?: CurrentMaintenanceObjectDto[];
};

type MaintenanceSortedByBuilding = {
  building: BuildingDto | undefined;
  maintenances: CurrentMaintenanceObjectDto[];
}[];

export const sortByBuildingNameAndInventoryName = (
  maintenancesSortedByBuilding: MaintenanceSortedByBuilding
): MaintenanceSortedByBuilding => {
  return [...maintenancesSortedByBuilding]
    .flatMap((maintenance) => {
      return {
        ...maintenance,
        maintenances: maintenance.maintenances.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }),
      };
    })
    .sort((a, b) => {
      if (!a.building?.name) return -1;
      if (!b.building?.name) return 1;

      if (a.building.name < b.building.name) return -1;
      if (a.building.name > b.building.name) return 1;
      return 0;
    });
};

const MaintenanceRequestMenu: React.FC<
  React.PropsWithChildren<MaintenanceRequestMenuProps>
> = ({ currentBuildings, currentRequest, currentRequestMaintenances }) => {
  const navigate = useNavigate();
  const { buildingId, maintenanceId } = useParams();

  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const maintenancesSortedByBuilding = sortByBuildingNameAndInventoryName(
    groupRequestObjectsByBuildings(currentBuildings, currentRequestMaintenances)
  );

  const adminPrefix = isMaintenanceTeamMemberAdmin
    ? `/${adminRoutePrefix}`
    : '';

  return (
    <div className="w-96 h-full pt-6 pl-2 pr-4 border-gray-dark border-r-2 bg-white">
      <div className="mb-6">
        <BackButton
          onClick={() => {
            navigate('/');
          }}
        />
      </div>

      <HintHighlight
        highlightIds={[
          `${OFFER_NOT_UPLOADED_ERROR}${currentRequest?.id}`,
          `${ORDER_CONFIRMATION_NOT_UPLOADED_ERROR}${currentRequest?.id}`,
        ]}
      >
        <button
          type="button"
          onClick={() => {
            navigate(`${adminPrefix}/requests/${currentRequest?.id}`);
          }}
          className={`w-full text-left px-2 py-1 font-bold hover:bg-gray-light hover:text-black-abs border-l-4 ${
            maintenanceId === undefined && buildingId === undefined
              ? 'border-red text-black-abs bg-gray-lighter'
              : 'text-black-abs border-white'
          }`}
        >
          {`Anfrage: ${
            currentRequest &&
            currentRequest.id.slice(currentRequest.id.length - 5)
          }`}
        </button>
      </HintHighlight>

      <div>
        {maintenancesSortedByBuilding?.map((maintenanceByBuilding) => (
          <div
            className="py-2 pl-4"
            key={`${
              maintenanceByBuilding?.building?.id
            }-${currentMaintenanceObjectId(
              maintenanceByBuilding.maintenances[0]
            )}`}
          >
            <button
              type="button"
              onClick={() => {
                navigate(
                  `${adminPrefix}/requests/${currentRequest?.id}/buildings/${maintenanceByBuilding?.building?.id}`
                );
              }}
              className={`w-full text-left px-2 py-1 font-bold hover:bg-gray-light hover:text-black-abs border-l-4  ${
                buildingId === maintenanceByBuilding?.building?.id
                  ? 'border-red text-black-abs bg-gray-lighter'
                  : 'text-black-abs border-white'
              }`}
            >
              Gebäude: {maintenanceByBuilding?.building?.name}
            </button>

            <div className="flex flex-col pl-4">
              {maintenanceByBuilding?.maintenances?.map((maintenance) => (
                <HintHighlight
                  key={currentMaintenanceObjectId(maintenance)}
                  highlightIds={[
                    `${MAINTENANCE_OBJECT_DOCUMENTATION_NOT_VALIDATED_ERROR}${currentMaintenanceObjectId(
                      maintenance
                    )}`,
                  ]}
                >
                  <HintHighlight
                    key={currentMaintenanceObjectId(maintenance)}
                    highlightBorderColor="border-orange"
                    highlightIds={[
                      `${MAINTENANCE_OBJECT_IS_REOPENED}${currentMaintenanceObjectId(
                        maintenance
                      )}`,
                    ]}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        navigate(
                          `${adminPrefix}/requests/${
                            currentRequest?.id
                          }/maintenances/${currentMaintenanceObjectId(
                            maintenance
                          )}`
                        );
                      }}
                      className={`w-full text-left px-2 py-1 font-bold hover:bg-gray-light hover:text-black-abs border-l-4 ${
                        maintenanceId ===
                        currentMaintenanceObjectId(maintenance)
                          ? 'border-red text-black-abs  bg-gray-lighter'
                          : 'text-black-abs border-white'
                      }`}
                    >
                      {maintenance?.name
                        ? `${providedServiceToLabel(maintenance.type.type)}: ${
                            maintenance?.name
                          }`
                        : providedServiceToLabel(maintenance.type.type)}
                    </button>
                  </HintHighlight>
                </HintHighlight>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MaintenanceRequestMenu;

MaintenanceRequestMenu.defaultProps = {
  currentRequest: undefined,
  currentBuildings: undefined,
  currentRequestMaintenances: undefined,
};
