import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceObject } from '../../maintenance-object';

export class CanNotBeInRequestRule extends DomainRule<
  MaintenanceObject,
  MaintenanceObject
> {
  protected rule(
    maintenanceObject: MaintenanceObject
  ): Promise<MaintenanceObject> | MaintenanceObject {
    if (!maintenanceObject.currentRequestId) {
      return maintenanceObject;
    }

    throw new DomainRuleNotSatisfiedError(CanNotBeInRequestRule);
  }
}
