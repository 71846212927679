import { User, toMaintenanceObjectId } from '@warthungshelden/domain/common';

import { BuildingRepository } from '../building';
import { MaintenanceDocumentationService } from '../maintenance-documentation';
import {
  MaintenanceObject,
  MaintenanceObjectRepository,
} from '../maintenance-object';
import {
  MaintenanceOffer,
  OfferedMaintenanceObject,
} from '../maintenance-offer';
import { MaintenancePrice } from './maintenance-price';
import {
  FixedFixedPriceStrategy,
  MaintenanceObjectPriceInformation,
  MaintenancePriceStrategies,
  MaintenancePriceStrategy,
  MaintenancePriceStrategyName,
  NoFixedPriceStrategy,
  PriceableMaintenanceObject,
  WartungsheldenPriceStrategy,
} from './maintenance-price-strategy';
import { MaintenanceUnitPriceResolver } from './maintenance-unit-price-resolver';

export class MaintenancePriceService {
  private readonly strategies: MaintenancePriceStrategies;

  constructor(
    private readonly maintenanceObjectRepository: MaintenanceObjectRepository,
    private readonly maintenanceDocumentationService: MaintenanceDocumentationService,
    private readonly buildingRepository: BuildingRepository,
    private readonly unitPriceResolver: MaintenanceUnitPriceResolver,
    defaultStrategy: MaintenancePriceStrategy = new FixedFixedPriceStrategy(
      unitPriceResolver
    )
  ) {
    this.strategies = {
      default: defaultStrategy,
      wartungshelden: new WartungsheldenPriceStrategy(unitPriceResolver),
      'fixed-fix-price': new FixedFixedPriceStrategy(unitPriceResolver),
      'no-fix-price': new NoFixedPriceStrategy(unitPriceResolver),
    };
  }

  private async gatherPriceInformationForObject(
    user: User,
    object: PriceableMaintenanceObject
  ): Promise<MaintenanceObjectPriceInformation> {
    const hasValidDocumentation =
      (await this.maintenanceDocumentationService.hasValidDocumentation(
        user,
        toMaintenanceObjectId(object)
      )) ?? undefined;

    const building = object.buildingId
      ? await this.buildingRepository.getById(object.buildingId)
      : undefined;

    return {
      object,
      hasValidDocumentation,
      building,
    };
  }

  private async gatherPriceInformationFor(
    user: User,
    objects: PriceableMaintenanceObject[]
  ): Promise<MaintenanceObjectPriceInformation[]> {
    return Promise.all(
      objects.map((object) =>
        this.gatherPriceInformationForObject.bind(this)(user, object)
      )
    );
  }

  public async calculatePrice(
    user: User,
    objectIds: string[],
    strategy: MaintenancePriceStrategyName = 'default'
  ): Promise<MaintenancePrice> {
    const objects = await this.maintenanceObjectRepository.getAllById(
      objectIds
    );

    const priceInformation = await this.gatherPriceInformationFor(
      user,
      objects
    );

    return this.strategies[strategy].calculatePriceForObjects(
      user,
      priceInformation
    );
  }

  public async calculatePriceForMaintenances(
    user: User,
    objects: (MaintenanceObject | OfferedMaintenanceObject)[],
    strategy: MaintenancePriceStrategyName = 'default'
  ): Promise<MaintenancePrice> {
    const priceInformation = await this.gatherPriceInformationFor(
      user,
      objects
    );

    return this.strategies[strategy].calculatePriceForObjects(
      user,
      priceInformation
    );
  }

  public async calculatePriceForMaintenancePriceInformation(
    user: User,
    information: MaintenanceObjectPriceInformation[],
    strategy: MaintenancePriceStrategyName = 'default'
  ): Promise<MaintenancePrice> {
    return this.strategies[strategy].calculatePriceForObjects(
      user,
      information
    );
  }

  public async recalculatePriceForOffer(
    user: User,
    offer: MaintenanceOffer,
    strategy: MaintenancePriceStrategyName = 'default'
  ): Promise<MaintenancePrice> {
    const newPrice = await this.calculatePriceForMaintenances(
      user,
      offer.maintenanceObjects,
      strategy
    );

    return newPrice.copyWith({
      discounts: offer.price?.discounts,
      priceAdaptation: offer.price?.priceAdaptation,
    });
  }
}
