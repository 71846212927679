import { Menu, Transition } from '@headlessui/react';
import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';

import {
  FallProtectionTypes,
  MaintenanceTypes,
  PriceInformationDto,
} from '@wartungshelden/shared-types';

import { Button, Toggle } from '../../../../components';
import Tip from '../../../../components/Basics/Tip';
import {
  useOwnAdminSettings,
  useUpdateAdminSettings,
} from '../../../../services/api/admin-settings/admin-settings-api';
import PriceCalculationContext from '../PriceCalculationContext';
import { MenuOptionType } from '../types/MenuOptionType';
import { PriceInformationCombination } from '../utils/combinations';
import { getLabelForMaintenanceTypeOrFallProtectionSystem } from '../utils/utils';
import MaintenanceTypeButton from './MaintenanceTypeButton';
import MaintenanceTypeMenuButton from './MaintenanceTypeMenuButton';

interface Props {
  onPreselectedObjectClick: (
    preselectedObject: PriceInformationCombination
  ) => void;
}

const menuOptions: MenuOptionType[] = Array.from<
  MaintenanceTypes | FallProtectionTypes
>([
  'personal_protection_equipment',
  'ladder',
  'railing',
  'rail_system',
  'climbing_protection',
  'rope',
  'push_lock',
  'single_anchor',
  'stair_ladder',
  'overpass',
  'ground_stairs',
]).map((type) => ({
  key: type,
  value: getLabelForMaintenanceTypeOrFallProtectionSystem(type),
}));

const CreateMaintenanceObjectMenu: React.FC<Props> = ({
  onPreselectedObjectClick,
}) => {
  const { submitForm, setFieldValue, values } =
    useFormikContext<PriceInformationDto>();

  const { setPriceWithoutDocu, setPriceWithDocu } = useContext(
    PriceCalculationContext
  );

  const { data: adminSettings } = useOwnAdminSettings();
  const [calculatorFavorites, setCalculatorFavorites] = useState<
    MenuOptionType[]
  >([]);
  useEffect(() => {
    if (!adminSettings?.calculatorFavoriteTypes) return;

    setCalculatorFavorites(
      adminSettings.calculatorFavoriteTypes.filter((favorite) =>
        menuOptions.some((option) => option.key === favorite.key)
      )
    );
  }, [adminSettings]);

  const { mutateAsync: updateAdminSettings } = useUpdateAdminSettings();

  const isFavorite = (item): boolean =>
    Boolean(calculatorFavorites.some(({ key }) => key === item));

  const toggleFavorite = async (item) => {
    let calculatorFavoriteTypes: MenuOptionType[];
    if (isFavorite(item.key)) {
      calculatorFavoriteTypes = calculatorFavorites.filter(
        (favorites) => favorites.key !== item.key
      );
    } else {
      calculatorFavoriteTypes = [...calculatorFavorites, item];
    }
    await updateAdminSettings(calculatorFavoriteTypes);
  };

  return (
    <div className="flex flex-row justify-between relative">
      <div className="flex flex-1 flex-wrap items-center">
        <div className="mb-4">
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button
                  className="outline-button small-button flex items-center mr-4 p-5 space-x-2"
                  data-cy="add-system-for-price-calculation-button"
                >
                  <FaPlus />
                  <div>Hinzufügen</div>
                </Menu.Button>

                <Transition
                  className="absolute z-10"
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute z-10 w-60 left-0 top-2 space-y-1 bg-white border border-gray-200 shadow-lg outline-none p-2"
                  >
                    {menuOptions
                      .sort((a, b) => (a.value < b.value ? -1 : 1))
                      .map((menuOption) => (
                        <Menu.Item key={menuOption.key}>
                          {({ close }) => (
                            <MaintenanceTypeMenuButton
                              menuOption={menuOption}
                              onPreselectedObjectClick={
                                onPreselectedObjectClick
                              }
                              close={close}
                              isFavorite={isFavorite(menuOption.key)}
                              onToggleFavorite={() =>
                                toggleFavorite(menuOption)
                              }
                            />
                          )}
                        </Menu.Item>
                      ))}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>

        {calculatorFavorites.map((calculatorFavoriteType) => {
          return (
            <div className="mb-4" key={calculatorFavoriteType.key}>
              <MaintenanceTypeButton
                menuOption={calculatorFavoriteType}
                onPreselectedObjectClick={onPreselectedObjectClick}
              />
            </div>
          );
        })}
      </div>

      <div className="flex items-start">
        <Toggle
          label="Verteilung Grundkosten"
          checked={values.strategy === 'no-fix-price'}
          name="strategy"
          onChange={async () => {
            setFieldValue(
              'strategy',
              values.strategy === 'no-fix-price' ? 'default' : 'no-fix-price'
            );
            await submitForm();
          }}
        />
        <div className="ml-2 mt-2">
          <Tip disabled={false}>
            Die Verteilung der Grundkosten erfolgt{' '}
            <span className="font-bold">gewichtet</span>. D.h. die Grundkosten
            werden anteilig entsprechend der Preise für die jeweiligen
            Positionen umgelegt. <span className="font-bold">Wichtig:</span>{' '}
            PSAgA ist von der Umverteilung der Grundkosten ausgeschlossen.
          </Tip>
        </div>

        <Button
          className="alert-button small-button ml-12 p-5"
          onClick={() => {
            setFieldValue('objectInformation', []);
            setFieldValue('combinations', []);
            setPriceWithDocu(undefined);
            setPriceWithoutDocu(undefined);
          }}
          icon={<FaTrash />}
          disabled={values.objectInformation.length === 0}
          label="Berechnung löschen"
        />
      </div>
    </div>
  );
};

export default CreateMaintenanceObjectMenu;
