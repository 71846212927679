export const adminRoutePrefix = 'admin';

export const routes = {
  requests: 'requests',
  maintenance: 'maintenance',
  buildings: 'buildings',
  settings: '/settings',
  profile: 'profile',
  plan: 'plan',
  dashboard: 'dashboard',
  price: 'price',
  installerOrder: 'installer-order',
};

export const buildingsRoutes = {
  main: `${routes.buildings}/*`,
  add: 'add',
  details: ':buildingId',
};

export const maintenanceRoutes = {
  main: `${routes.maintenance}/*`,
  add: 'add',
  details: ':maintenanceObjectId',
  request: `request`,
  requestWithSelection: `request/:maintenanceObjectId`,
};

export const requestRoutes = {
  main: `${routes.requests}/*`,
  details: `:requestId`,
  maintenance: `:requestId/maintenances/:maintenanceId`,
  building: `:requestId/buildings/:buildingId`,
};
