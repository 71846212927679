import { useFormikContext } from 'formik';
import React from 'react';
import {
  FaArrowAltCircleRight,
  FaCheck,
  FaTelegramPlane,
  FaTimesCircle,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../../constants/routes';
import Button from '../../Basics/Buttons/Button';
import { WizardFooter } from '../../index';

interface MaintenanceRequestWizardFooterProps {
  nextEnabled: boolean;
  handleNext: () => void;
  activeStep: number;
  changeStep: (step: number) => void;
}

const MaintenanceRequestWizardFooter: React.FC<
  React.PropsWithChildren<MaintenanceRequestWizardFooterProps>
> = ({ nextEnabled, activeStep, handleNext, changeStep }) => {
  const navigation = useNavigate();

  const { submitForm } = useFormikContext();

  return (
    <WizardFooter>
      <div className="flex flex-1 justify-end mr-2">
        {activeStep !== 4 && (
          <Button
            label="Abbrechen"
            icon={<FaTimesCircle />}
            onClick={() => navigation(`/${routes.maintenance}`)}
            className="outline-button"
          />
        )}
      </div>

      {(activeStep === 1 || activeStep === 2) && (
        <Button
          label="Weiter"
          icon={<FaArrowAltCircleRight />}
          disabled={!nextEnabled}
          onClick={handleNext}
        />
      )}
      {activeStep === 3 && (
        <Button
          label="Wartung anfragen"
          icon={<FaTelegramPlane />}
          disabled={!nextEnabled}
          onClick={async () => {
            await submitForm();
            changeStep(4);
          }}
          className="confirmation-button"
        />
      )}
      {activeStep === 4 && (
        <Button
          label="Fertig"
          icon={<FaCheck />}
          disabled={!nextEnabled}
          onClick={() => navigation('/maintenance')}
        />
      )}
    </WizardFooter>
  );
};

export default MaintenanceRequestWizardFooter;
