import React from 'react';

import { LOCK_II_FIELDS } from '../../../constants/MaintenanceConstants';
import { UnitInputField } from '../../index';

const PushLockSystem: React.FC<React.PropsWithChildren<{ index: number }>> = ({
  index,
}) => (
  <>
    <UnitInputField
      name={`maintenanceObjects.${index}.system.lock1`}
      label={LOCK_II_FIELDS[0].label}
      unit="Stück"
      isLabelBold={false}
      labelWidthTailwindClass="w-80"
    />
    <UnitInputField
      name={`maintenanceObjects.${index}.system.lock2`}
      label={LOCK_II_FIELDS[1].label}
      unit="Stück"
      isLabelBold={false}
      labelWidthTailwindClass="w-80"
    />
  </>
);

export default PushLockSystem;
