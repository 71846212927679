import {
  MaintenanceObjectPriceDto,
  PriceComponentDto,
  PriceInformationObjectDto,
} from '@wartungshelden/shared-types';

import { isPPEDto } from '../../../../guards/isMaintenanceType';
import {
  PriceInformationCombination,
  toPriceInformationObjects,
} from './combinations';
import { getLabelForPriceComponent } from './utils';

export type PricedCombinationPosition<
  T extends PriceInformationCombination = PriceInformationCombination,
  Key extends keyof T = any
> = {
  combination: T[Key];
  priceWithDocu?: MaintenanceObjectPriceDto;
  priceWithoutDocu?: MaintenanceObjectPriceDto;
};

export type PricedCombination<
  T extends PriceInformationCombination = PriceInformationCombination
> = {
  [type in keyof T]: PricedCombinationPosition<T, type>;
};

const byObjectId =
  (objectId: string) =>
  ({ objectId: objId }: MaintenanceObjectPriceDto) =>
    objId === objectId;

const byNotObjectId =
  (objectId: string) =>
  ({ objectId: objId }: MaintenanceObjectPriceDto) =>
    objId !== objectId;

function toObjectPriceWithSummarizedSubsystemComponent(
  objects: PriceInformationObjectDto[],
  lastPosition: number
) {
  const toSubsystemPriceComponent = (
    subsystemComponent: PriceComponentDto,
    { value }: PriceComponentDto
  ) => ({ ...subsystemComponent, value: value + subsystemComponent.value });

  return (objectPrice) => {
    const object = objects.find(
      (info) => objectPrice.objectId === info.object.id
    )!;

    return {
      ...objectPrice,
      absoluteVariablePriceComponents: {
        ...objectPrice.absoluteVariablePriceComponents,
        actual: [
          objectPrice.absoluteVariablePriceComponents.actual.reduce(
            toSubsystemPriceComponent,
            {
              value: 0,
              reason: getLabelForPriceComponent(
                object.object.type.type,
                isPPEDto(object.object.type)
                  ? object.object.type.systemType ?? undefined
                  : undefined
              ),
              type: object.object.type.type,
              kind: 'absolute',
              position: lastPosition,
            }
          ),
        ],
      },
    };
  };
}

const withRedistributedFixCosts = (
  combination: PriceInformationCombination,
  objectPrices: MaintenanceObjectPriceDto[]
): MaintenanceObjectPriceDto[] => {
  const byIsRedistribution = ({ type }) => type === 'fix_price_redistribution';
  const byIsNotRedistribution = ({ type }) =>
    type !== 'fix_price_redistribution';

  const objects = toPriceInformationObjects(combination);
  const objectIds = objects.map((object) => object.object.id);

  const pricesForCombination = objectPrices.filter(({ objectId }) =>
    objectIds.includes(objectId)
  );

  const fixedPriceRedistributions = pricesForCombination.flatMap(
    (objectPrice) => {
      return objectPrice.absoluteVariablePriceComponents.actual.filter(
        byIsRedistribution
      );
    }
  );

  const absoluteFixedPriceRedistributions = pricesForCombination.flatMap(
    (objectPrice) => {
      return objectPrice.absoluteVariablePriceComponents.actual.filter(
        byIsRedistribution
      );
    }
  );

  const objectPricesWithoutRedistributions =
    pricesForCombination.map<MaintenanceObjectPriceDto>((objectPrice) => ({
      ...objectPrice,
      variablePriceComponents: {
        ...objectPrice.variablePriceComponents,
        actual: objectPrice.variablePriceComponents.actual.filter(
          byIsNotRedistribution
        ),
      },
      absoluteVariablePriceComponents: {
        ...objectPrice.absoluteVariablePriceComponents,
        actual: objectPrice.absoluteVariablePriceComponents.actual.filter(
          byIsNotRedistribution
        ),
      },
    }));

  const primarySystemWithoutRedistributions =
    objectPricesWithoutRedistributions.find(
      byObjectId(combination.primarySystem.object.id)
    )!;

  const objectPricesWithoutPrimarySystem = objectPricesWithoutRedistributions
    .filter(byNotObjectId(combination.primarySystem.object.id))
    .map(
      toObjectPriceWithSummarizedSubsystemComponent(
        objects,
        Math.max(
          ...fixedPriceRedistributions.map(
            (redistribution) => redistribution.position
          )
        )
      )
    );

  return [
    ...objectPricesWithoutPrimarySystem,
    {
      ...primarySystemWithoutRedistributions,
      variablePriceComponents: {
        ...primarySystemWithoutRedistributions.variablePriceComponents,
        actual: [
          ...primarySystemWithoutRedistributions.variablePriceComponents.actual,
          ...fixedPriceRedistributions,
        ],
      },
      absoluteVariablePriceComponents: {
        ...primarySystemWithoutRedistributions.absoluteVariablePriceComponents,
        actual: [
          ...primarySystemWithoutRedistributions.absoluteVariablePriceComponents
            .actual,
          ...absoluteFixedPriceRedistributions,
        ],
      },
    },
  ];
};

const recalculateVariablePrice = (
  price: MaintenanceObjectPriceDto
): MaintenanceObjectPriceDto => {
  const newVariableNetPrice = price.absoluteVariablePriceComponents.actual
    .map(({ value }) => value)
    .reduce((partialSum, value) => partialSum + value, 0);

  return {
    ...price,
    variableNetPrice: newVariableNetPrice,
  };
};

export const toPricedCombination = (
  combination: PriceInformationCombination,
  pricesWithDocu: MaintenanceObjectPriceDto[],
  pricesWithoutDocu: MaintenanceObjectPriceDto[]
): PricedCombination => {
  const redistributedFixCostsWithDocu = withRedistributedFixCosts(
    combination,
    pricesWithDocu
  ).map(recalculateVariablePrice);

  const redistributedFixCostsWithoutDocu = withRedistributedFixCosts(
    combination,
    pricesWithoutDocu
  ).map(recalculateVariablePrice);

  return Object.entries(combination).reduce(
    (pricedCombinations, [key, current]) => {
      return {
        ...pricedCombinations,
        [key]: {
          combination: current,
          priceWithDocu: redistributedFixCostsWithDocu.find(
            ({ objectId }) => combination[key]?.object?.id === objectId
          ),
          priceWithoutDocu: redistributedFixCostsWithoutDocu.find(
            ({ objectId }) => combination[key]?.object?.id === objectId
          ),
        },
      };
    },
    {} as unknown as PricedCombination
  );
};

export const toPriceCombination = (
  pricedCombination: PricedCombination
): PriceInformationCombination => {
  const keys = Object.entries(pricedCombination).map<
    [string, PriceInformationCombination]
  >(([key, { combination }]) => [
    key,
    combination as PriceInformationCombination,
  ]);

  return Object.fromEntries(keys) as unknown as PriceInformationCombination;
};

export const toPricesWithDocu = (pricedCombination: PricedCombination) => {
  return Object.values<PricedCombinationPosition>(pricedCombination).map(
    ({ priceWithDocu }) => priceWithDocu
  );
};

export const toPricesWithoutDocu = (pricedCombination: PricedCombination) => {
  return Object.values<PricedCombinationPosition>(pricedCombination).map(
    ({ priceWithoutDocu }) => priceWithoutDocu
  );
};
