import { Logger } from '@nestjs/common';
import {
  roundUpToFifteenMinutesInFuture,
  to,
} from '@warthungshelden/shared-functions';
import {
  addHours,
  addMinutes,
  addMonths,
  eachDayOfInterval,
  endOfDay,
  isWeekend,
  max,
  setHours,
  startOfDay,
  subMonths,
} from 'date-fns';

import { CalendarAdapter, CalendarAppointment } from '../../calendar';
import { Installer } from '../../installer';
import { InstallerAdapter } from '../../installer';
import { Order, OrderRepository } from '../../maintenance-order';
import { MustHaveStatusRule } from '../../rules';
import { GeoAdapter, GeoCoordinate, GeoDescription } from '../geo';
import { Job, Route } from './route';

export type InstallerPlanningInformation = {
  installer: Installer;
  coordinate: GeoCoordinate;
  workdays: Date[];
};

export interface RoutePlanningStrategy {
  calculateRoute(
    jobs: Job[],
    installers: InstallerPlanningInformation[],
    appointments: InstallerAppointmentMap,
    preferABSInstallers: boolean
  ): Promise<Route[]>;

  calculateForInstaller(
    route: Route,
    installer: Installer,
    installerCoordinates: GeoCoordinate
  ): Promise<Route>;
}

export type InstallerAppointmentMap = Record<string, CalendarAppointment[]>;

export const generateRouteCalendarAppointments = async (
  route: Route,
  orderRepository: OrderRepository,
  endOfLastAppointment: Date
): Promise<CalendarAppointment[]> => {
  const [, appointments] = await route.jobs.reduce<
    Promise<[Date, CalendarAppointment[]]>
  >(async (currentResult, job, index) => {
    const [startTime, appointments] = await currentResult;

    const startTimeWithDrivingTime =
      index === 0
        ? startTime
        : roundUpToFifteenMinutesInFuture(
            addMinutes(
              startTime,
              (route.jobDistances[index].minutes ?? 0) * 1.2
            )
          );

    const endTime = roundUpToFifteenMinutesInFuture(
      addHours(startTimeWithDrivingTime, job.duration)
    );

    const order = await orderRepository.getById(job.orderId);

    const appointment = new CalendarAppointment({
      id: CalendarAdapter.calendarAppointmentId(),
      name: `[20 geplant][${job.orderId}] ${
        order.constructionSite ?? undefined
      }`,
      description: `Auftragsnummer: ${job.orderId}`,
      place: order.constructionSiteAddress?.address,
      startDate: startTimeWithDrivingTime,
      endDate: endTime,
      wholeDay: false,
      private: false,
      transparent: false,
    });

    return [endTime, [...appointments, appointment]];
  }, Promise.resolve([endOfLastAppointment, []]));

  return appointments;
};

export class RoutePlanningService {
  constructor(
    private readonly logger: Logger,
    private routePlanningStrategy: RoutePlanningStrategy,
    private orderRepository: OrderRepository,
    private installerAdapter: InstallerAdapter,
    private geoAdapter: GeoAdapter,
    private calendarAdapter: CalendarAdapter
  ) {}

  public async scheduleRoute(installerId: string, route: Route) {
    const statusRule = new MustHaveStatusRule(
      'scheduling_written',
      'scheduling_phone'
    );

    await Promise.all(route.jobs.map((job) => statusRule.apply(job)));

    const appointmentsOnDay =
      await this.calendarAdapter.getAppointmentsForInstaller(
        installerId,
        startOfDay(route.date),
        endOfDay(route.date)
      );

    const endOfLastAppointment = max([
      setHours(route.date, 8),
      ...appointmentsOnDay.map(to('endDate')),
    ]);

    const appointments = await generateRouteCalendarAppointments(
      route,
      this.orderRepository,
      endOfLastAppointment
    );

    await this.calendarAdapter.createAppointmentsForInstaller(
      installerId,
      ...appointments
    );

    for (const job of route.jobs) {
      await this.orderRepository.update({
        id: job.orderId,
        status: job.status,
        deliveryDate: route.date,
        installerId: installerId,
      });
    }
  }

  public async findRoutes(
    jobs: Job[],
    installers: InstallerPlanningInformation[],
    appointments: InstallerAppointmentMap,
    preferABSInstallers: boolean
  ): Promise<Route[]> {
    return this.routePlanningStrategy.calculateRoute(
      jobs,
      installers,
      appointments,
      preferABSInstallers
    );
  }

  public async findRoutesFromZipCodesAndInstallers(createRoute: {
    orders: string[];
    installers: Installer[];
    from: Date;
    to: Date;
    considerCalendar: boolean;
    preferABSInstallers: boolean;
  }) {
    const openOrders = await this.orderRepository.getAllById(
      createRoute.orders
    );

    const coordinateJobInformation = await Promise.all(
      openOrders.map<Promise<Job>>(this.toCoordinateJobInformation.bind(this))
    );

    const installerLocations = await Promise.all<InstallerPlanningInformation>(
      createRoute.installers.map(
        this.toInstallerPlanningInformation(
          createRoute.from,
          createRoute.to
        ).bind(this)
      )
    );

    const appointments = await createRoute.installers.reduce(
      async (map, installer) => {
        const resolvedMap = await map;

        if (createRoute.considerCalendar) {
          try {
            const appointmentsForInstaller =
              await this.calendarAdapter.getAppointmentsForInstaller(
                installer.id,
                subMonths(createRoute.from, 1),
                addMonths(createRoute.to, 1)
              );

            return {
              ...resolvedMap,
              [installer.id]: appointmentsForInstaller,
            };
          } catch (error) {
            this.logger.warn(
              `Can not load calendar for installer ${installer.id} as it does not exist`
            );
          }
        }

        return {
          ...resolvedMap,
          [installer.id]: new Array<CalendarAppointment>(),
        };
      },
      Promise.resolve({} as InstallerAppointmentMap)
    );

    return this.findRoutes(
      coordinateJobInformation,
      installerLocations,
      appointments,
      createRoute.preferABSInstallers
    );
  }

  public async calculateForInstaller(route: Route, installer: Installer) {
    const coordinates = await this.geoAdapter.getCoordinates(
      new GeoDescription({
        address: `${installer.address.city ?? ' '}
        ${installer.address.zip ?? ' '} ${
          installer.address.country ?? 'Deutschland'
        }`,
      })
    );

    return await this.routePlanningStrategy.calculateForInstaller(
      route,
      installer,
      coordinates[0]
    );
  }

  private async toCoordinateJobInformation(order: Order): Promise<Job> {
    const zipCode = order.constructionSiteAddress?.postalCode;

    if (!zipCode || typeof order.duration === 'undefined') {
      throw new Error('Order is missing zip code or duration');
    }

    const coordinates = await this.geoAdapter.getCoordinates(
      new GeoDescription({
        address: `${zipCode?.trim()} Deutschland`,
      })
    );

    return new Job({
      zipCode: zipCode,
      coordinates: await coordinates[0],
      duration: order.duration,
      orderId: order.id,
      status: order.status,
      contactPreference: order.contactPreference,
    });
  }

  private toInstallerPlanningInformation(from: Date, to: Date) {
    return async (
      installer: Installer
    ): Promise<InstallerPlanningInformation> => {
      const coordinate = await this.geoAdapter.getCoordinates(
        new GeoDescription({
          address: `${installer.address.city ?? ' '}
        ${installer.address.zip ?? ' '} ${
            installer.address.country ?? 'Deutschland'
          }`,
        })
      );

      return {
        installer,
        coordinate: coordinate[0],
        workdays: workdaysDaysBetween(from, to),
      };
    };
  }
}

function isWeekday(date: Date) {
  return !isWeekend(date);
}

function workdaysDaysBetween(from: Date, to: Date): Date[] {
  return eachDayOfInterval({
    start: from,
    end: to,
  }).filter(isWeekday);
}
