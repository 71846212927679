import React from 'react';

interface TextWithLabelProps {
  label: string;
  labelStyle?: string;
  dataCy?: string;
}

const TextWithLabel: React.FC<React.PropsWithChildren<TextWithLabelProps>> = ({
  label,
  labelStyle,
  dataCy,
  children,
}) => (
  <div
    className={`flex items-start max-w-2xl ${label && 'mb-2'}`}
    data-cy={dataCy}
  >
    <div className={`flex font-bold w-72 mr-4 ${labelStyle}`}>{label}</div>
    <div className="relative z-0 w-full">{children}</div>
  </div>
);

TextWithLabel.defaultProps = {
  labelStyle: undefined,
  dataCy: undefined,
};

export default TextWithLabel;
