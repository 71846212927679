import {
  FormikFallProtection,
  FormikFallProtectionPushLockSystem,
  FormikFallProtectionRopeSystem,
  FormikFallProtectionSingleAnchorSystem,
  FormikFallProtectionSystem,
} from '../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikLadder } from '../pages/UserPages/maintenance/maintenanceWizard/types/FormikLadder';
import { FormikPPE } from '../pages/UserPages/maintenance/maintenanceWizard/types/FormikPPE';

export function isFormikFallProtectionMaintenanceObject(
  a: FormikFallProtection | FormikPPE | FormikLadder
): a is FormikFallProtection {
  return a && 'ladder' in a && 'selectedFiles' in a;
}

export function isFormikLadderMaintenanceObject(
  a: FormikFallProtection | FormikPPE | FormikLadder
): a is FormikLadder {
  return a && 'length' in a;
}

export function isFormikPPEMaintenanceObject(
  a: FormikFallProtection | FormikPPE | FormikLadder
): a is FormikPPE {
  return a && 'manufacturer' in a && 'quantity' in a && 'category' in a;
}

export function isFormikFallProtectionRopeSystem(
  a: FormikFallProtectionSystem
): a is FormikFallProtectionRopeSystem {
  return a && 'ropeLength' in a && 'systemAnchors' in a && 'singleAnchors' in a;
}

export function isFormikFallProtectionPushLockSystem(
  a: FormikFallProtectionSystem
): a is FormikFallProtectionPushLockSystem {
  return a && 'lock1' in a && 'lock2' in a;
}

export function isFormikFallProtectionSingleAnchorSystem(
  a: FormikFallProtectionSystem
): a is FormikFallProtectionSingleAnchorSystem {
  return (
    a && !('ropeLength' in a) && !('systemAnchors' in a) && 'singleAnchors' in a
  );
}
