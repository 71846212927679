import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  AppointmentDto,
  InstallerDto,
  OpenMaintenanceDto,
  RouteDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

const useInstallers = () => {
  const axios = useAxios();

  const fetchInstallers = async (): Promise<InstallerDto[] | undefined> => {
    if (!axios) return undefined;

    const { data } = await axios.get<InstallerDto[]>(
      '/maintenance-administration/installers?onlyValidCity=true'
    );

    return data?.sort((a, b) => a.name.localeCompare(b.name));
  };

  return useQuery('installers', fetchInstallers, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};

export const useOpenMaintenancesBetween = (
  installerId?: string,
  fromDate?: Date,
  toDate?: Date
) => {
  const axios = useAxios();

  const fetchOpenMaintenances = async ({
    id,
    from,
    to,
  }: {
    id: string | undefined;
    from;
    to;
  }): Promise<OpenMaintenanceDto[] | undefined> => {
    if (!axios || !from) return undefined;

    const { data } = await axios.get<OpenMaintenanceDto[]>(
      id
        ? `/maintenance-administration/installers/${id}/maintenances/open`
        : '/maintenance-administration/orders/maintenance/open',
      {
        params: {
          fromDate: from,
          toDate: to,
        },
      }
    );

    return data;
  };

  return useQuery(
    ['open-maintenances', installerId, fromDate, toDate],
    () =>
      fetchOpenMaintenances({ id: installerId, from: fromDate, to: toDate }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: [installerId, fromDate, toDate].every(
        (value) => typeof value !== undefined
      ),
    }
  );
};

export const useInstallerAppointments = (
  installerId: string | undefined,
  fromDate?: Date,
  toDate?: Date
) => {
  const axios = useAxios();

  const fetchAppointments = async (
    id: string | undefined,
    from,
    to
  ): Promise<AppointmentDto[] | undefined> => {
    if (!axios || !id || !from) return undefined;
    const { data } = await axios.get<AppointmentDto[]>(
      `/maintenance-administration/installers/${id}/appointments`,
      {
        params: {
          fromDate: from,
          toDate: to,
        },
      }
    );

    return data;
  };

  return useQuery(
    ['appointments', installerId, fromDate, toDate],
    () => fetchAppointments(installerId, fromDate, toDate),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: [installerId, fromDate, toDate].every(
        (value) => typeof value !== undefined
      ),
    }
  );
};

export const useInstallerRoute = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const acceptRoute = async (config: { route: RouteDto; id: string }) => {
    if (!axios || !config.id) return null;
    return axios.post(
      `/maintenance-administration/installers/${config.id}/route`,
      config.route
    );
  };

  return useMutation(acceptRoute, {
    onSuccess: async () => {
      toast.success('Speichern erfolgreich', { position: 'top-center' });
      await rqClient.invalidateQueries(['']);
    },
  });
};

export default useInstallers;
