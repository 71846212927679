import React from 'react';

import { RopeFallProtectionSystemDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface RopeSystemPropertiesProps {
  ropeSystem: RopeFallProtectionSystemDto;
  hasSubprices: boolean;
}

const RopeSystemProperties: React.FC<RopeSystemPropertiesProps> = ({
  ropeSystem,
  hasSubprices,
}) => (
  <>
    <MaintenanceObjectSummaryRow
      label="Seillänge"
      value={ropeSystem.ropeLength}
      unit="m"
      hasSubprices={hasSubprices}
    />

    <MaintenanceObjectSummaryRow
      label="Systemstützen"
      value={ropeSystem.systemAnchors}
      unit="Stück"
      hasSubprices={hasSubprices}
    />
    <MaintenanceObjectSummaryRow
      label="Weitere EAP"
      value={ropeSystem.singleAnchors}
      unit="Stück"
      hasSubprices={hasSubprices}
    />
  </>
);

export default RopeSystemProperties;
