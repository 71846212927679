import React from 'react';

import {
  MaintenanceAdministrationBuildingDto,
  SelectLineMaintenanceOrderDto,
} from '@wartungshelden/shared-types';

import InfoText from './InfoText';
import InformationContainer from './InformationContainer';

interface Props {
  selectLineData?: SelectLineMaintenanceOrderDto;
}

const ProjectInfo: React.FC<Props> = ({ selectLineData }) => {
  return (
    <InformationContainer>
      <InfoText
        label="Auftraggeber:"
        labelStyle="font-bold"
        indicateMissingData
        isDataMissing={() => !selectLineData?.customerName}
      >
        <div>
          <div>{selectLineData?.customerName}</div>
          <div>{selectLineData?.customerNameAdditive}</div>
          <div>{selectLineData?.customerNameAdditive2}</div>
        </div>
      </InfoText>

      <div className="mt-4">
        <InfoText label="Ansprechpartner Projekt:" indicateMissingData>
          {selectLineData?.contactProject?.name}
        </InfoText>
        <InfoText label="E-Mail:" indicateMissingData>
          {selectLineData?.contactProject?.email}
        </InfoText>
        <InfoText label="Telefon:" indicateMissingData>
          {selectLineData?.contactProject?.phone}
        </InfoText>
        <InfoText label="Mobil:" indicateMissingData>
          {selectLineData?.contactProject?.mobile}
        </InfoText>
      </div>
    </InformationContainer>
  );
};

ProjectInfo.defaultProps = {
  selectLineData: undefined,
};

export default ProjectInfo;
