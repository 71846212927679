import React from 'react';
import { FaHome } from 'react-icons/fa';

interface HomeProps {
  isFirst: boolean;
  label: string;
  isTransparent?: boolean;
}

const Home: React.FC<HomeProps> = ({ isFirst, label, isTransparent }) => (
  <tr className={`${isTransparent && 'opacity-50'}`}>
    <td className="w-12 text-right" />
    <td className="w-10">
      <FaHome className="text-2xl text-blue-abs" />

      {isFirst && (
        <div className="w-6 flex justify-center">
          <div className="h-[2rem] w-1 bg-blue-abs" />
        </div>
      )}
    </td>
    <td className="h-1">
      <div className="font-bold flex h-full justify-start">{label}</div>
    </td>
  </tr>
);

Home.defaultProps = {
  isTransparent: undefined,
};
export default Home;
