/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { User } from '@warthungshelden/domain/common';
import {
  GeoAdapter,
  GeoDescription,
} from '@warthungshelden/domain/maintenance-administration';

import { MaintenanceObjectRepository } from '../maintenance-object';
import {
  BuildingCanNotBeInRequestRule,
  CanNotBeInObjectRule,
  CanNotChangeOwnerRule,
  CoordinatesMustBeValid,
  MustBeOwnerRule,
  MustExistRule,
} from '../rules';
import { Building } from './building';
import { BuildingRepository } from './building-repository';

export class BuildingService {
  constructor(
    protected readonly buildingRepository: BuildingRepository,
    protected readonly maintenanceObjectRepository: MaintenanceObjectRepository,
    protected readonly geoAdapter: GeoAdapter
  ) {}

  public async add(
    user: User,
    building: Omit<Building, 'ownerId'>
  ): Promise<Building> {
    const oldAddress = building.address;
    const coordinates = await this.geoAdapter.getCoordinates(
      new GeoDescription({
        address: `${oldAddress?.addressLine1}, ${oldAddress?.postalCode} ${oldAddress?.city}`,
      })
    );

    const newBuilding = new Building({
      ...building,
      ownerId: user.id,
      address: oldAddress?.copyWith({
        coordinates: coordinates[0],
      }),
    });

    await new CoordinatesMustBeValid().apply(newBuilding);

    return await this.buildingRepository.create(newBuilding);
  }

  public async getAllAccessibleBy(user: User): Promise<Building[]> {
    return user.isMaintenanceTeamMemberAdmin
      ? this.buildingRepository.getAll()
      : this.buildingRepository.getAllByUser(user.id);
  }

  public async update(
    user: User,
    newBuilding: Pick<Building, 'id' | 'copyWith'> & Partial<Building>
  ): Promise<Building> {
    const oldBuilding = await this.buildingRepository.getById(newBuilding.id);

    await new CanNotChangeOwnerRule(oldBuilding.ownerId).apply(newBuilding);

    const building = newBuilding.copyWith({ ownerId: user.id });

    const requestedMaintenanceObjects =
      await this.maintenanceObjectRepository.getAllByUser(user.id);

    await new MustExistRule(this.buildingRepository)
      .and(new MustBeOwnerRule(user.id))
      .and(new BuildingCanNotBeInRequestRule(requestedMaintenanceObjects))
      .apply(building.id);

    if (newBuilding.address) {
      const oldAddress = building.address;
      const coordinates = await this.geoAdapter.getCoordinates(
        new GeoDescription({
          address: `${oldAddress?.addressLine1}, ${oldAddress?.postalCode} ${oldAddress?.city}`,
        })
      );

      const updatedBuilding = building.copyWith({
        address: building.address?.copyWith({ coordinates: coordinates[0] }),
      });

      await new CoordinatesMustBeValid().apply(updatedBuilding);

      return this.buildingRepository.update(updatedBuilding);
    } else {
      return this.buildingRepository.update(building);
    }
  }

  public async delete(user: User, buildingId: string) {
    await new MustExistRule(this.buildingRepository)
      .and(new MustBeOwnerRule(user.id))
      .and(new CanNotBeInObjectRule(this.maintenanceObjectRepository, user.id))
      .apply(buildingId);

    await this.buildingRepository.delete(buildingId);
  }
}
