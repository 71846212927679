import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

export class CanNotChangeOwnerRule<
  T extends { ownerId?: string }
> extends DomainRule<T, T> {
  constructor(private oldOwnerId: string) {
    super();
  }

  protected async rule(entity: T) {
    if (entity.ownerId && entity.ownerId !== this.oldOwnerId) {
      throw new DomainRuleNotSatisfiedError(CanNotChangeOwnerRule);
    }
    return entity;
  }
}
