import { useFormikContext } from 'formik';
import React from 'react';

import { PriceInformationObjectDto } from '@wartungshelden/shared-types';

import { UnitInputField } from '../../../../../components';
import Toggle from '../../../../../components/Basics/Inputs/Toggle';
import { isRailSystemDto } from '../../../../../guards/isMaintenanceType';
import ManufacturerSelector from './ManufacturerSelector';

const RailSystemSelector = () => {
  const { values, setFieldValue } =
    useFormikContext<PriceInformationObjectDto>();

  const recommendedBrackets =
    isRailSystemDto(values.object.type) && values.object.type.length
      ? Math.ceil(values.object.type.length / 1.5) + 1
      : undefined;

  return (
    <>
      <ManufacturerSelector />
      <div className="font-bold px-8">Bestandteile*</div>
      <div className="px-8">
        <UnitInputField
          name="object.type.length"
          label="Länge"
          unit="m"
          isLabelBold={false}
          shouldSelectOnFocus
          labelWidthTailwindClass="w-80"
        />
        <UnitInputField
          name="object.type.brackets"
          label="Halter"
          unit="Stück"
          isLabelBold={false}
          shouldSelectOnFocus
          labelWidthTailwindClass="w-80"
        />
        {recommendedBrackets && (
          <div className="mt-2 mb-8">
            <span className="text-blue-abs font-bold">Hinweis</span>
            {`: Die automatische Berechnung empfiehlt eine Anzahl von ${recommendedBrackets} Haltern`}
          </div>
        )}

        <UnitInputField
          name="railSystemProtectionGliders"
          label="Gleiter"
          unit="Stück"
          isLabelBold={false}
          shouldSelectOnFocus
          labelWidthTailwindClass="w-80"
        />
        <div className="font-bold">Gebäude*</div>
        <div className="pt-4">
          <Toggle
            label="Öffentliches Gebäude (Körperschaft öffentlichen Rechts), z.B.
                      Städte, Gemeinden, Kommunen, Wohnungsbaugesellschaften"
            name="building.isPublic"
            checked={!!values.building?.isPublic}
            onChange={() => {
              setFieldValue('building.isPublic', !values.building?.isPublic);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RailSystemSelector;
