import { isDefined } from '@warthungshelden/shared-functions';
import React, { useContext } from 'react';

import { RoutePlanContext } from '../../../../contexts/RoutePlanContext';

const RoutesInfo: React.FC = () => {
  const { routes } = useContext(RoutePlanContext);

  const totalDrivingKilometers = routes
    ?.map(({ totalJobDistance }) => totalJobDistance.kilometers)
    .filter(isDefined)
    .reduce((total, currentLength) => total + currentLength, 0);

  return routes ? (
    <div className="flex my-4 text-xl font-bold space-x-24">
      <div>Gesamtstrecke: {totalDrivingKilometers?.toFixed(2)}km</div>
      <div>Anzahl Touren: {routes?.length}</div>
    </div>
  ) : null;
};

export default RoutesInfo;
