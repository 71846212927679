import { GeoCoordinateDto } from './ressource-planning';

export interface InstallerAddressDto {
  street?: string;
  city?: string;
  zip?: string;
  country?: string;
  coordinates?: GeoCoordinateDto;
}

export interface InstallerContactDto {
  email?: string;
  mobile?: string;
}

export interface InstallerDto {
  id: string;
  name: string;
  address: InstallerAddressDto;
  contact: InstallerContactDto;
  isAbsInstaller: boolean | null;
}
