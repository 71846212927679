import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import BackButton from '../../../components/Basics/Buttons/BackButton';
import UserNavigation from '../../../components/Basics/TabNavigation/UserNavigation';
import HeaderLogout from '../../../components/Header/HeaderLogout';
import { routes } from '../../../constants/routes';

const DashboardMainPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const shouldRenderHeader = () => location.pathname === `/${routes.dashboard}`;

  if (shouldRenderHeader()) {
    return (
      <>
        <HeaderLogout>
          <UserNavigation />
        </HeaderLogout>
        <Outlet />
      </>
    );
  }
  return (
    <>
      <HeaderLogout>
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
      </HeaderLogout>
      <Outlet />
    </>
  );
};

export default DashboardMainPage;
