import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceOffer, MaintenanceOrder } from '../../maintenance-offer';

export class MustHaveOrder<T extends MaintenanceOffer> extends DomainRule<
  T,
  T & { order: MaintenanceOrder }
> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (!entity.order) {
      throw new DomainRuleNotSatisfiedError(MustHaveOrder);
    }
    return entity as T & { order: MaintenanceOrder };
  }
}
