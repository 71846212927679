import { Entity } from '@warthungshelden/domain/common';

export class Documentation extends Entity {
  public readonly id: number;
  public readonly isCompleted: boolean;
  public readonly isActive: boolean;
  public readonly type: 'maintenance' | 'assembly';
  public readonly updatedAt: Date;
  public readonly createdAt: Date;
  public readonly products: Product[];

  constructor(config: {
    readonly id: number;
    readonly isCompleted: boolean;
    readonly isActive: boolean;
    readonly type: 'maintenance' | 'assembly';
    readonly updatedAt: Date;
    readonly createdAt: Date;
    readonly products: Product[];
  }) {
    super();
    this.id = config.id;
    this.isCompleted = config.isCompleted;
    this.isActive = config.isActive;
    this.type = config.type;
    this.updatedAt = config.updatedAt;
    this.createdAt = config.createdAt;
    this.products = config.products;
  }
}

export class Product extends Entity {
  public readonly productName: string;
  public readonly documentationItems: DocumentationItem[];

  constructor(config: {
    readonly productName: string;
    readonly documentationItems: DocumentationItem[];
  }) {
    super();
    this.productName = config.productName;
    this.documentationItems = config.documentationItems;
  }
}

export class BuildingAddress extends Entity {
  address1: string | null;
  address2: string | null;
  zip: string | null;
  city: string | null;
  country: string | null;

  constructor(config: {
    address1: string | null;
    address2: string | null;
    zip: string | null;
    city: string | null;
    country: string | null;
  }) {
    super();
    this.address1 = config.address1;
    this.address2 = config.address2;
    this.zip = config.zip;
    this.city = config.city;
    this.country = config.country;
  }
}

export class BuildingContact extends Entity {
  role: string | null;
  salutation: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone1: string | null;
  phone2: string | null;
  fax: string | null;

  constructor(config: {
    role: string | null;
    salutation: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone1: string | null;
    phone2: string | null;
    fax: string | null;
  }) {
    super();
    this.role = config.role;
    this.salutation = config.salutation;
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.email = config.email;
    this.phone1 = config.phone1;
    this.phone2 = config.phone2;
    this.fax = config.fax;
  }
}

export class Building extends Entity {
  id: number;
  name: string | null;
  companyId: number;
  contact: BuildingContact;
  address: BuildingAddress;
  imageUrl: string | null;
  comment: string | null;
  projectNumber: string | null;
  isActive: boolean;
  amountAssemblies: number | null;
  amountMaintenances: number | null;

  constructor(config: {
    id: number;
    name: string | null;
    contact: BuildingContact;
    address: BuildingAddress;
    companyId: number;
    imageUrl: string | null;
    comment: string | null;
    projectNumber: string | null;
    isActive: boolean;
    amountAssemblies: number | null;
    amountMaintenances: number | null;
  }) {
    super();
    this.id = config.id;
    this.name = config.name;
    this.contact = config.contact;
    this.address = config.address;
    this.companyId = config.companyId;
    this.imageUrl = config.imageUrl;
    this.comment = config.comment;
    this.projectNumber = config.projectNumber;
    this.isActive = config.isActive;
    this.amountAssemblies = config.amountAssemblies;
    this.amountMaintenances = config.amountMaintenances;
  }
}

export class BuildingArea extends Entity {
  public readonly buildingId: number;
  public readonly id: number;
  public readonly name: string;
  public readonly isDeleted: boolean;

  public readonly documentations: Documentation[];

  constructor(config: {
    readonly buildingId: number;
    readonly id: number;
    readonly name: string;
    readonly isDeleted: boolean;
    readonly documentations: Documentation[];
  }) {
    super();
    this.buildingId = config.buildingId;
    this.id = config.id;
    this.name = config.name;
    this.isDeleted = config.isDeleted;
    this.documentations = config.documentations;
  }
}

export class InstalledArticle extends Entity {
  public readonly comment: string;
  public readonly identificationNumber: string;
  public readonly identificationCounter: string;
  public readonly isDeleted: boolean;
  public readonly serialNumber: string;

  constructor(config: {
    readonly comment: string;
    readonly identificationNumber: string;
    readonly identificationCounter: string;
    readonly isDeleted: boolean;
    readonly serialNumber: string;
  }) {
    super();
    this.identificationNumber = config.identificationNumber;
    this.comment = config.comment;
    this.identificationCounter = config.identificationNumber;
    this.isDeleted = config.isDeleted;
    this.serialNumber = config.serialNumber;
  }
}

export class DocumentationItem {
  public readonly installedArticleId: number;
  public readonly id: number;
  public readonly documentationId: number;
  public readonly name: string;
  public readonly installedArticle: InstalledArticle | null;

  constructor(config: {
    readonly installedArticleId: number;
    readonly id: number;
    readonly documentationId: number;
    readonly name: string;
    readonly installedArticle: InstalledArticle | null;
  }) {
    this.installedArticleId = config.installedArticleId;
    this.id = config.id;
    this.documentationId = config.documentationId;
    this.name = config.name;
    this.installedArticle = config.installedArticle;
  }
}

export class DocumentationItemQuestion extends Entity {
  public readonly id: number;
  public readonly question: string;
  public readonly questionType: 'yesno' | 'text';
  public readonly answer: string | number | null;
  public readonly defaultAnswer: string | number | null;
  public readonly documentationItemId: number;
  public readonly position: number;

  constructor(config: {
    readonly id: number;
    readonly question: string;
    readonly questionType: 'yesno' | 'text';
    readonly answer: string | number | null;
    readonly defaultAnswer: string | number | null;
    readonly documentationItemId: number;
    readonly position: number;
  }) {
    super();
    this.id = config.id;
    this.question = config.question;
    this.answer = config.answer;
    this.defaultAnswer = config.defaultAnswer;
    this.questionType = config.questionType;
    this.documentationItemId = config.documentationItemId;
    this.position = config.position;
  }
}

export class DocumentationItemAnswer extends Entity {
  public readonly id: number;
  public readonly answer: string;
  public readonly questionId: number;

  constructor(config: {
    readonly id: number;
    readonly answer: string;
    readonly questionId: number;
  }) {
    super();
    this.id = config.id;
    this.questionId = config.questionId;
    this.answer = config.answer;
  }
}
