import React, { PropsWithChildren } from 'react';
import { FaLightbulb } from 'react-icons/fa';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

import { palette } from '../../../../constants/palette';

type WidthType = 'one-third' | 'two-thirds' | 'full' | undefined;

interface HintModalProps {
  width?: WidthType;
  headline?: string;
  subHeadline?: string;
  isOpen: boolean;
  center?: boolean;
  bottom?: boolean;
}

const HintModal: React.FC<PropsWithChildren<HintModalProps>> = ({
  width,
  headline,
  subHeadline,
  isOpen,
  center,
  bottom,
  children,
}) => {
  const widthMap = {
    'one-third': '33%',
    'two-third': '66%',
    full: '100%',
  };

  return (
    <div>
      {isOpen && (
        <ResponsiveModal
          open={isOpen}
          center={center}
          onClose={() => {
            // empty function
          }}
          showCloseIcon={false}
          styles={{
            modal: {
              backgroundColor: palette.offWhite,
              padding: 0,
              width: width ? widthMap[width] : undefined,
              maxWidth: '100vw',
              margin: 0,
              display: bottom ? 'flex' : undefined,
              position: bottom ? 'fixed' : undefined,
              bottom: bottom ? 0 : undefined,
              borderWidth: 4,
              borderColor: palette.wartungBlue,
            },
          }}
        >
          {headline && (
            <div className="flex flex-col px-8 pt-6 pb-4">
              <div className="flex">
                <div className="text-2xl pt-2 text-blue-wartung mr-6">
                  <FaLightbulb />
                </div>
                <div>
                  <div className="text-3xl font-bold mb-2 text-blue-wartung">
                    Tipp
                  </div>
                  <div className="text-2xl font-bold mb-2">{headline}</div>
                </div>
              </div>
              {subHeadline}
            </div>
          )}
          <div
            className="flex flex-col flex-1 bg-white py-6 px-20"
            data-cy="responsiveModalBody"
          >
            {children}
          </div>
        </ResponsiveModal>
      )}
    </div>
  );
};

HintModal.defaultProps = {
  width: undefined,
  center: false,
  bottom: false,
  headline: undefined,
  subHeadline: undefined,
};

export default HintModal;
