import { Field, FieldAttributes } from 'formik';
import React from 'react';

import CheckBox from './CheckBox';

export type InputFieldProps<T> = FieldAttributes<T> & {
  label?: string | React.ReactNode | null;
  isRequired?: boolean;
  checkboxAlignment?: 'items-center' | 'items-start';
};

const CheckBoxField: React.FC<
  React.PropsWithChildren<InputFieldProps<unknown>>
> = ({ name, disabled, label, isRequired, checkboxAlignment = false }) => (
  <Field
    id={name}
    name={name}
    type="checkbox"
    disabled={disabled}
    className="w-5 h-5 text-blue-abs rounded-md"
  >
    {({ field }) => (
      <CheckBox
        label={label}
        isRequired={isRequired}
        checkboxAlignment={checkboxAlignment}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
    )}
  </Field>
);

CheckBoxField.defaultProps = {
  label: undefined,
  isRequired: false,
  checkboxAlignment: 'items-center',
};

export default CheckBoxField;
