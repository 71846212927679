import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';
import {
  BaseRepository,
  CompositeBaseRepository,
  Id,
} from '@warthungshelden/domain/common';

export class MustExistRule<T extends { id: string }> extends DomainRule<
  string,
  T
> {
  constructor(private baseRepository: BaseRepository<T>) {
    super();
  }

  protected async rule(id: string): Promise<T> {
    try {
      return await this.baseRepository.getById(id);
    } catch (e) {
      throw new DomainRuleNotSatisfiedError(MustExistRule);
    }
  }
}

export class CompositeMustExistRule<
  T extends { ownerId: string },
  U extends keyof T
> extends DomainRule<Id<T, U>, T> {
  constructor(private baseRepository: CompositeBaseRepository<T, U>) {
    super();
  }

  protected async rule(id: Id<T, U>): Promise<T> {
    try {
      return await this.baseRepository.getById(id);
    } catch (e) {
      throw new DomainRuleNotSatisfiedError(CompositeMustExistRule);
    }
  }
}
