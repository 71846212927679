import React from 'react';

import HorizontalLine from './HorizontalLine';

const HorizontalLineWithText = ({ text }: { text: string }) => (
  <div className="relative flex py-5 items-center">
    <HorizontalLine />
    <span className="flex-shrink mx-4 text-gray-400">{text}</span>
    <HorizontalLine />
  </div>
);

export default HorizontalLineWithText;
