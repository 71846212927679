import { useQuery } from 'react-query';

import { useAxios } from '../api';

const useVerifyUserflow = (environment: {
  production: boolean;
  e2e: boolean;
}) => {
  if (environment.e2e) return { data: undefined };

  const axios = useAxios();

  const fetchVerify = async (): Promise<string | undefined> => {
    if (!axios) return undefined;
    const { data } = await axios.get<string>('/userflow/verify');
    return data;
  };

  return useQuery('verify', fetchVerify, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useVerifyUserflow;
