import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';

type DeleteFileProps = {
  disabled: boolean;
  deleteFile: (file: File) => void;
  file: File;
};

const DeleteFile = ({ disabled, deleteFile, file }: DeleteFileProps) => (
  <div className="absolute cursor-pointer top-0 right-0 bg-gray-lighter">
    <FaTrashAlt
      size={15}
      className="p-2 w-full h-full"
      onClick={() => {
        if (!disabled) deleteFile(file);
      }}
    />
  </div>
);

export default DeleteFile;
