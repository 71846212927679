import React, { PropsWithChildren } from 'react';

import CheckBoxField from './CheckBoxField';

type CheckBoxFieldWithDescriptionWithoutChildrenProps = {
  name: string;
  label: string;
  description: string;
};

type CheckBoxFieldWithDescriptionWithChildrenProps = {
  name?: undefined;
  label?: undefined;
  description: string;
};

const isCheckBoxFieldWithDescriptionWithoutChildrenProps = (
  x:
    | CheckBoxFieldWithDescriptionWithoutChildrenProps
    | CheckBoxFieldWithDescriptionWithChildrenProps
): x is CheckBoxFieldWithDescriptionWithoutChildrenProps => {
  return typeof x.name === 'string' && typeof x.label === 'string';
};

const CheckBoxFieldWithDescription: React.FC<
  PropsWithChildren<
    | CheckBoxFieldWithDescriptionWithoutChildrenProps
    | CheckBoxFieldWithDescriptionWithChildrenProps
  >
> = (props) => {
  /* eslint-disable react/destructuring-assignment */
  return (
    <div className="flex h-12 items-center w-full mb-2">
      <span className="font-bold w-64 mr-4">{props.description}</span>
      <div className="flex relative z-0 w-full">
        {isCheckBoxFieldWithDescriptionWithoutChildrenProps(props) ? (
          <CheckBoxField
            name={props.name}
            label={props.label}
            className="w-full"
          />
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

export default CheckBoxFieldWithDescription;
