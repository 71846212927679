import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { CustomerDto } from '@wartungshelden/shared-types';

import { SessionContext } from '../../../contexts/SessionContext';
import { useAxios } from '../api';

export const useOwnCustomer = () => {
  const axios = useAxios();

  const fetchCustomer = async () => {
    if (!axios) return null;
    const { data } = await axios.get<CustomerDto>(
      '/maintenance-exchange/customers/me'
    );
    return data;
  };

  return useQuery('customer', fetchCustomer, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetCustomers = () => {
  const axios = useAxios();
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const fetchCustomers = async () => {
    if (!axios) return null;
    if (!isMaintenanceTeamMemberAdmin) return [];
    const { data } = await axios.get<CustomerDto[]>(
      '/maintenance-exchange/customers'
    );
    return data;
  };

  return useQuery('customers', fetchCustomers);
};

export const useUpdateCustomer = (showSuccessMessage = true) => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const updateCustomer = async (customer: CustomerDto) => {
    if (!axios) return null;
    return axios.patch('/maintenance-exchange/customers', customer);
  };

  return useMutation(updateCustomer, {
    onSuccess: async () => {
      if (showSuccessMessage) {
        toast.success('Speichern erfolgreich', { position: 'top-center' });
      }

      await rqClient.invalidateQueries(['customer']);
    },
  });
};
