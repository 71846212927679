import { User } from '@warthungshelden/domain/common';
import { to, wherePropertyIsType } from '@warthungshelden/shared-functions';

import { PersonalProtectionEquipment } from '../../maintenance-object';
import { MaintenancePrice, PriceComponent } from '../maintenance-price';
import { MaintenanceUnitPriceResolver } from '../maintenance-unit-price-resolver';
import { FixedFixedPriceStrategy } from './fixed-base-price-strategy';
import {
  MaintenanceObjectPriceInformation,
  MaintenancePriceStrategy,
  Reasons,
} from './maintenance-price-strategy';
import { PriceableMaintenanceObject } from './priceable-maintenance-object';

function byId(id: string) {
  return (object: PriceableMaintenanceObject) => {
    const objectId = 'id' in object ? object.id : object.originalId;
    return objectId === id;
  };
}

export class NoFixedPriceStrategy implements MaintenancePriceStrategy {
  private readonly fixedFixedPriceStrategy: FixedFixedPriceStrategy;

  constructor(private unitPriceResolver: MaintenanceUnitPriceResolver) {
    this.fixedFixedPriceStrategy = new FixedFixedPriceStrategy(
      unitPriceResolver
    );
  }

  public async calculatePriceForObjects(
    user: User,
    information: MaintenanceObjectPriceInformation[]
  ): Promise<MaintenancePrice> {
    const objects = information.map(to('object'));

    const oldPrice =
      await this.fixedFixedPriceStrategy.calculatePriceForObjects(
        user,
        information
      );

    if (
      objects.every(wherePropertyIsType('type', PersonalProtectionEquipment))
    ) {
      return oldPrice;
    }

    const { sum: maintenanceObjectsWithoutPPEVariablePrice } =
      oldPrice.objectPrices.reduce(
        ({ sum, maintenancesWithoutPPE }, oldObjectPrice) => {
          const maintenanceObject = objects.find(byId(oldObjectPrice.objectId));

          const isNotPPE =
            typeof maintenanceObject !== 'undefined' &&
            !(maintenanceObject?.type instanceof PersonalProtectionEquipment);

          const newMaintenanceObjectsWithoutPPE = isNotPPE
            ? [...maintenancesWithoutPPE, maintenanceObject]
            : maintenancesWithoutPPE;
          const newSum = isNotPPE
            ? sum + oldObjectPrice.variableNetPrice.actual
            : sum;

          return {
            sum: newSum,
            maintenancesWithoutPPE: newMaintenanceObjectsWithoutPPE,
          };
        },
        {
          sum: 0,
          maintenancesWithoutPPE: new Array<PriceableMaintenanceObject>(),
        }
      );

    const objectPrices = oldPrice.objectPrices.map((oldObjectPrice) => {
      const isPPE =
        objects.find(byId(oldObjectPrice.objectId))?.type instanceof
        PersonalProtectionEquipment;

      if (isPPE) {
        return oldObjectPrice.copyWith({
          unsortedFixedPriceComponents: {
            actual: [],
            max: [],
          },
        });
      }

      const actualPercentage =
        (oldObjectPrice.variableNetPrice.actual ?? 0) /
        maintenanceObjectsWithoutPPEVariablePrice;

      const actualVariableAddition =
        (oldPrice.netFixed ?? 0) * actualPercentage;

      const maxVariableAddition = (oldPrice.netFixed ?? 0) * actualPercentage;

      const variableAdditionActual: PriceComponent = {
        position:
          oldObjectPrice.variablePriceComponents.actual[
            oldObjectPrice.variablePriceComponents.actual.length - 1
          ].position + 1,
        type: 'fix_price_redistribution',
        kind: 'absolute',
        value: actualVariableAddition,
        reason: Reasons.REDISTRIBUTION,
      };

      const variableAdditionMax: PriceComponent = {
        position:
          oldObjectPrice.variablePriceComponents.max[
            oldObjectPrice.variablePriceComponents.max.length - 1
          ].position + 1,
        type: 'fix_price_redistribution',
        kind: 'absolute',
        value: maxVariableAddition,
        reason: Reasons.REDISTRIBUTION,
      };

      const newVariablePriceComponentsActual = [
        ...oldObjectPrice.absoluteVariablePriceComponents.actual,
        variableAdditionActual,
      ];

      const newVariablePriceComponentsMax = [
        ...oldObjectPrice.absoluteVariablePriceComponents.max,
        variableAdditionMax,
      ];

      return oldObjectPrice.copyWith({
        unsortedFixedPriceComponents: {
          actual: [],
          max: [],
        },
        unsortedVariablePriceComponents: {
          actual: newVariablePriceComponentsActual,
          max: newVariablePriceComponentsMax,
        },
      });
    });

    return oldPrice.copyWith({
      strategy: 'no-fix-price',
      objectPrices: objectPrices,
    });
  }
}
