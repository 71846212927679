import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';

import { Card, PageHeader } from '../../../../../components';
import FormWrapper from '../../../../../components/Basics/FormWrapper';
import DueDateSelectField from '../../../../../components/Basics/Inputs/DateSelection/DueDateSelectField';
import BuildingsSelectField from '../../../../../components/Buildings/BuildingsSelectField';
import FrequencyToggle from '../../../../../components/Maintenance/MaintenanceWizard/FrequencyToggle';
import MaintenanceObjectCantBeEditedInfoBox from '../../../../../components/Maintenance/ReadOnly/MaintenanceObjectCantBeEditedInfoBox';
import MaintenanceObjectReadOnly from '../../../../../components/Maintenance/ReadOnly/MaintenanceObjectReadOnly';
import ServiceTypeSelectField from '../../../../../components/Maintenance/ServiceTypeSelectField';
import { isRequiredField } from '../../../../../constants/MaintenanceUtils';
import { wizardStep1ValidationSchema } from '../../../../../constants/MaintenanceYupSchema';
import { useMaintenanceDocumentationsForBuilding } from '../../../../../services/api/maintenance-documentation/maintenance-documentation-api';
import { FormikMaintenanceWizardValues } from '../types/FormikMaintenanceWizardValues';

interface StepOneProps {
  isMaintenanceReadOnly: boolean;
  isCreate: boolean;
  isReOpened?: boolean;
  requestId?: string;
  maintenanceId?: string;
}

const StepOne: React.FC<React.PropsWithChildren<StepOneProps>> = ({
  isMaintenanceReadOnly,
  isCreate,
  isReOpened,
  requestId,
  maintenanceId,
}) => {
  const { values, validateForm, setFieldValue } =
    useFormikContext<FormikMaintenanceWizardValues>();

  const { data: maintenanceDocumentationsByBuilding } =
    useMaintenanceDocumentationsForBuilding(values.buildingId);

  useEffect(() => {
    if (!isCreate || !maintenanceDocumentationsByBuilding) return;
    setFieldValue(
      'uploadedMaintenanceDocumentations',
      maintenanceDocumentationsByBuilding
    );
  }, [values.buildingId, maintenanceDocumentationsByBuilding]);

  useEffect(() => {
    validateForm(values);
  }, [values.buildingId, values.type]);

  return (
    <Card>
      <PageHeader>
        <div className="flex flex-row items-center text-4xl text-bold ">
          <FaEdit size={45} className="pr-4" />
          Erfassung des Bedarfs
        </div>
      </PageHeader>

      <MaintenanceObjectCantBeEditedInfoBox
        isMaintenanceReadOnly={isMaintenanceReadOnly}
        isReOpened={isReOpened}
        requestId={requestId}
        maintenanceId={maintenanceId}
      />

      <div className="text-basic mb-6">
        Grundlegende Angaben zur Erfassung der Wartungsaufgabe.
      </div>

      {isMaintenanceReadOnly ? (
        <MaintenanceObjectReadOnly />
      ) : (
        <FormWrapper>
          <BuildingsSelectField
            disabled={isMaintenanceReadOnly}
            isRequired={isRequiredField(
              wizardStep1ValidationSchema,
              'buildingId'
            )}
          />
          <ServiceTypeSelectField
            disabled={isMaintenanceReadOnly}
            isRequired={isRequiredField(wizardStep1ValidationSchema, 'type')}
          />
          <FrequencyToggle
            isMaintenanceReadOnly={isMaintenanceReadOnly}
            isRequired={isRequiredField(
              wizardStep1ValidationSchema,
              'frequency'
            )}
          />
          <DueDateSelectField
            disabled={isMaintenanceReadOnly}
            isRequired={isRequiredField(wizardStep1ValidationSchema, 'dueDate')}
          />
        </FormWrapper>
      )}
    </Card>
  );
};

StepOne.defaultProps = {
  isReOpened: false,
  requestId: undefined,
  maintenanceId: undefined,
};

export default StepOne;
