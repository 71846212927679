// @ts-nocheck
import React, { FC, createContext, useEffect, useMemo, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingSpinner from '../components/Basics/Loaders/LoadingSpinner';
import {
  UserInfo,
  getAccessToken,
  getUser,
  refreshSession,
  resendConfirmationCode,
  resetPasswordConfirm,
  resetPasswordRequest,
  signin,
  signinWithApple,
  signinWithGoogle,
  signinWithMicrosoft,
  signout,
  signup,
  useIsSignedIn,
  verify,
} from '../services/authService';

interface ResponseInterface {
  isSuccessful: boolean;
  error?: any;
}

interface ContextInterface {
  userInfo: UserInfo | null;
  isSignedIn: boolean;
  isLoading: boolean;
  isMaintenanceTeamMemberAdmin: boolean;
  isMaintenanceTeamMemberUser: boolean;
  login: (username: string, password: string) => Promise<ResponseInterface>;
  loginWithGoogle: () => Promise<boolean>;
  loginWithApple: () => Promise<boolean>;
  loginWithMicrosoft: () => Promise<boolean>;
  signUp: (
    username: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<boolean>;
  verifyUser: (username: string, code: string) => Promise<ResponseInterface>;
  sendNewConfirmationCode: (username: string) => Promise<boolean>;
  requestPasswordReset: (username: string) => Promise<boolean>;
  resetPassword: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<boolean>;
  logout: () => Promise<boolean>;
  refreshTokenOrLogout: () => Promise<string | null>;
  accessToken: string | null;
  isConsent: boolean;
}

const contextDefaultValues: ContextInterface = {
  userInfo: null,
  isSignedIn: false,
  accessToken: null,
  isLoading: false,
  isMaintenanceTeamMemberAdmin: false,
  isMaintenanceTeamMemberUser: false,
  login: async () => Promise.resolve({ isSuccessful: false }),
  loginWithGoogle: async () => Promise.resolve(false),
  loginWithApple: async () => Promise.resolve(false),
  loginWithMicrosoft: async () => Promise.resolve(false),
  signUp: async () => Promise.resolve(false),
  logout: async () => Promise.resolve(false),
  verifyUser: async () => Promise.resolve({ isSuccessful: false }),
  requestPasswordReset: async () => Promise.resolve(false),
  resetPassword: async () => Promise.resolve(false),
  sendNewConfirmationCode: async () => Promise.resolve(false),
  refreshTokenOrLogout: async () => Promise.resolve(null),
  isConsent: false,
};

export const SessionContext =
  createContext<ContextInterface>(contextDefaultValues);

export const SessionProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isSignedIn, isLoading: isLoadingSignIn } = useIsSignedIn();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [staleSession, setStaleSession] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);

  const isLoading = isLoadingSignIn || isLoadingUser;

  const [isConsent, setIsConsent] = useState(false);

  const buttonStyle = {
    backgroundColor: null,
    color: null,
    fontSize: null,
    lineHeight: null,
    borderRadius: null,
    height: null,
    width: null,
    padding: null,
  };

  const handleAcceptCookie = () => {
    TagManager.initialize({
      gtmId: 'GTM-KQD9SH8',
    });
  };

  const handleDeclineCookie = () => {
    document.cookie.split(';').forEach((cookie) => {
      if (!cookie.includes('CookieConsent')) {
        document.cookie = cookie
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`);
      }
    });
  };

  useEffect(() => {
    const consent = getCookieConsentValue();
    const scripts = document.querySelectorAll('noscript');
    const tagManager = Array.from(scripts).find((script) => {
      if (!script?.textContent) return false;
      return script.textContent.indexOf('id="tag-manager"') !== -1;
    });
    if (consent === 'true' && !tagManager) {
      handleAcceptCookie();
      setIsConsent(true);
    }
  }, []);

  useEffect(() => {
    if (
      !isConsent &&
      userInfo &&
      (userInfo.isMaintenanceTeamMemberAdmin ||
        userInfo?.isMaintenanceTeamMemberUser)
    ) {
      handleAcceptCookie();
      setIsConsent(true);
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchUserData = async () => {
      const isRefresh = staleSession;

      setStaleSession(false);

      if (!isRefresh) {
        setIsLoadingUser(true);
      }

      const user = await getUser();
      setUserInfo(user);
      const token = await getAccessToken();

      setAccessToken(token);

      if (!isRefresh) {
        setIsLoadingUser(false);
      }
    };

    if (!isLoadingSignIn) {
      setIsLoadingUser(false);
    }

    if (isSignedIn) {
      fetchUserData();
    }
  }, [isSignedIn, staleSession, isLoadingSignIn]);

  const logout = async () => {
    try {
      await signout();
    } catch (e) {
      toast.error('Logout fehlgeschlagen.', {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  const login = async (username, password) => {
    try {
      await signin(username, password);
    } catch (error) {
      return { isSuccessful: false, error };
    }
    return { isSuccessful: true };
  };

  const loginWithGoogle = async () => {
    try {
      await signinWithGoogle();
    } catch (e) {
      toast.error('Login fehlgeschlagen.', {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  const loginWithApple = async () => {
    try {
      await signinWithApple();
    } catch (e) {
      toast.error('Login fehlgeschlagen.', {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  const loginWithMicrosoft = async () => {
    try {
      await signinWithMicrosoft();
    } catch (e) {
      toast.error('Login fehlgeschlagen.', {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  const signUp = async (
    username: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    try {
      await signup(username, password, firstName, lastName);
    } catch (e) {
      // @ts-ignore
      switch (e.name) {
        case 'UsernameExistsException':
          toast.error('E-Mail-Adresse oder Telefonnummer existieren bereits.', {
            position: 'top-center',
            autoClose: false,
          });
          break;
        case 'InvalidPasswordException':
          toast.error('Das Passwort muss mindestens 6 Zeichen lang sein.', {
            position: 'top-center',
            autoClose: false,
          });
          break;
        case 'CodeDeliveryFailureException':
          toast.error(
            'Verifizierungscode konnte nicht gesendet werden. Hast du dich bei der Mailadresse oder Telefonnummer vertippt?',
            {
              position: 'top-center',
              autoClose: false,
            }
          );
          break;
        default:
          toast.error(
            'Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es erneut oder wende dich per E-Mail an inspektion@absturzsicherung.de',
            {
              position: 'top-center',
              autoClose: false,
            }
          );
      }
      return false;
    }
    return true;
  };

  const verifyUser = async (username: string, code: string) => {
    try {
      await verify(username, code);
    } catch (error) {
      return { isSuccessful: false, error };
    }
    return { isSuccessful: true };
  };

  const refreshTokenOrLogout = async () => {
    const success = await refreshSession();

    if (success) {
      setStaleSession(true);
      return getAccessToken();
    }

    toast.error('Deine Sitzung ist abgelaufen. Bitte melde dich erneut an.', {
      position: 'top-center',
    });

    await logout();
    return null;
  };

  const sendNewConfirmationCode = async (username: string) => {
    try {
      await resendConfirmationCode(username);
    } catch (e) {
      toast.error('Es konnte kein neuer Code gesendet werden.', {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  const requestPasswordReset = async (username: string) => {
    try {
      await resetPasswordRequest(username);
    } catch (e) {
      toast.error('Das Passwort konnte nicht zurückgesetzt werden.', {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  const resetPassword = async (
    username: string,
    code: string,
    password: string
  ) => {
    try {
      await resetPasswordConfirm(username, code, password);
    } catch (e) {
      toast.error('Das Passwort konnte nicht zurückgesetzt werden.', {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  const value = useMemo<ContextInterface>(
    () => ({
      isSignedIn,
      userInfo,
      isLoading,
      logout,
      login,
      loginWithApple,
      loginWithGoogle,
      loginWithMicrosoft,
      signUp,
      verifyUser,
      sendNewConfirmationCode,
      requestPasswordReset,
      resetPassword,
      accessToken,
      refreshTokenOrLogout,
      isMaintenanceTeamMemberAdmin:
        userInfo?.isMaintenanceTeamMemberAdmin ?? false,
      isMaintenanceTeamMemberUser:
        userInfo?.isMaintenanceTeamMemberUser ?? false,
      isConsent,
    }),
    [isSignedIn, userInfo, isLoading, accessToken, isConsent]
  );

  return (
    <SessionContext.Provider value={value}>
      {children}
      {isLoadingUser && <LoadingSpinner isLoading />}
      <CookieConsent
        style={{
          alignItems: 'center',
          background: null,
        }}
        containerClasses="py-4 bg-gray-light"
        contentClasses="text-black-abs"
        buttonClasses="primary-button"
        declineButtonClasses="secondary-button"
        declineButtonStyle={buttonStyle}
        buttonStyle={buttonStyle}
        enableDeclineButton
        onAccept={() => {
          if (!isConsent) {
            handleAcceptCookie();
            setIsConsent(true);
          }
        }}
        onDecline={handleDeclineCookie}
        buttonText="Alle Cookies akzeptieren"
        declineButtonText="Erforderliche Cookies"
        overlay
      >
        Durch den Klick auf “Alle Cookies akzeptieren” stimmst Du der
        Speicherung von Cookies in Deinem Browser, auch zum Zwecke der
        anonymisierten Analyse, zu. Weitere Informationen findest Du in unserer{' '}
        <Link to="/privacy" target="_blank" className="underline">
          Datenschutzerklärung
        </Link>
        .
      </CookieConsent>
    </SessionContext.Provider>
  );
};
