import { DomainEventDispatcher } from '@warthungshelden/domain/common';

import { MaintenanceObjectReminder } from '../maintenance-object';
import {
  MaintenanceOffer,
  MaintenanceOfferStatusEnum,
} from '../maintenance-offer';
import {
  MaintenanceOfferDocumentUploadOpened,
  MaintenanceOfferStatusChanged,
} from '../maintenance-offer';
import { MaintenanceRequest } from '../maintenance-request';
import { MaintenanceRequestStatusChanged } from '../maintenance-request';

export abstract class CustomerNotificationService {
  constructor(private eventDispatcher: DomainEventDispatcher) {
    this.eventDispatcher.register(
      MaintenanceRequestStatusChanged,
      async (event) => {
        await this.notifyAboutMaintenanceRequestStatusChanged(
          event.maintenanceRequest
        );
      }
    );

    this.eventDispatcher.register(
      MaintenanceOfferStatusChanged,
      async (event) => {
        if (
          event.maintenanceOffer.decision.status ===
          MaintenanceOfferStatusEnum.DRAFT
        ) {
          return;
        }

        await this.notifyAboutMaintenanceRequestStatusChanged(
          event.maintenanceOffer
        );
      }
    );

    this.eventDispatcher.register(
      MaintenanceOfferDocumentUploadOpened,
      async (event) => {
        await this.notifyAboutDocumentUploadOpened(
          event.maintenanceOffer,
          event.openedDocumentUploads
        );
      }
    );
  }

  public abstract notifyAboutMaintenanceRequestStatusChanged(
    offerOrRequest: MaintenanceOffer | MaintenanceRequest
  ): Promise<void>;

  public abstract notifyAboutUpcomingMaintenanceDueDate(
    reminder: MaintenanceObjectReminder
  ): Promise<void>;

  public abstract notifyAboutOverdueMaintenanceDueDate(
    reminder: MaintenanceObjectReminder
  ): Promise<void>;

  public abstract notifyAboutPendingOffer(
    offer: MaintenanceOffer
  ): Promise<void>;

  public abstract notifyAboutDocumentUploadOpened(
    offer: MaintenanceOffer,
    objects: string[]
  ): Promise<void>;
}
