import { Entity } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';
import { v4 as uuidv4 } from 'uuid';

import {
  MaintenanceDurationPolicy,
  MaintenanceObject,
  MaintenanceType,
} from '../maintenance-object';
import { MaintenancePrice } from '../maintenance-price';
import { MaintenanceRequest } from '../maintenance-request';
import { MaintenanceOfferAddress } from './maintenance-offer-address';
import { MaintenanceOrder } from './maintenance-order';

export enum MaintenanceOfferStatusEnum {
  DRAFT = 'draft',
  SENT = 'sent',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
}

export type MaintenanceOfferStatus = `${MaintenanceOfferStatusEnum}`;

export class MaintenanceOfferDecision extends Entity {
  readonly status: MaintenanceOfferStatus;
  readonly date: Date;
  readonly reasons?: string[];

  constructor(config: {
    readonly status: MaintenanceOfferStatus;
    readonly date: Date;
    readonly reasons?: string[];
  }) {
    super();
    this.status = config.status;
    this.date = config.date;
    this.reasons = config.reasons;
  }
}

export class MaintenanceOffer extends Entity {
  public readonly id: string;
  public readonly ownerId: string;
  public readonly created: Date;
  public readonly customerReference?: string | null;
  public readonly requestId: string;
  public readonly maintenanceObjects: OfferedMaintenanceObject[];
  public readonly address: MaintenanceOfferAddress;
  public readonly decision: MaintenanceOfferDecision;
  public readonly objectsWithOpenDocumentUpload: string[];
  public readonly price?: MaintenancePrice | null;
  public readonly order?: MaintenanceOrder | null;
  public readonly suggestedDate?: Date | null;
  public readonly documentName?: string | null;
  public readonly validUntil?: Date | null;
  public readonly absCustomerNumber?: string | null;

  constructor(config: {
    id: string;
    created: Date;
    ownerId: string;
    requestId: string;
    customerReference?: string | null;
    maintenanceObjects: OfferedMaintenanceObject[];
    address: MaintenanceOfferAddress;
    suggestedDate?: Date | null;
    documentName?: string | null;
    validUntil?: Date | null;
    decision: MaintenanceOfferDecision;
    objectsWithOpenDocumentUpload: string[];
    price?: MaintenancePrice | null;
    order?: MaintenanceOrder | null;
    absCustomerNumber?: string | null;
  }) {
    super();
    this.id = config.id;
    this.ownerId = config.ownerId;
    this.requestId = config.requestId;
    this.customerReference = config.customerReference;
    this.created = config.created;
    this.suggestedDate = config.suggestedDate;
    this.maintenanceObjects = config.maintenanceObjects;
    this.address = config.address;
    this.documentName = config.documentName;
    this.validUntil = config.validUntil;
    this.decision = config.decision;
    this.objectsWithOpenDocumentUpload = config.objectsWithOpenDocumentUpload;
    this.price = config.price;
    this.order = config.order;
    this.absCustomerNumber = config.absCustomerNumber;
  }

  public static fromMaintenanceRequest(
    maintenanceRequest: MaintenanceRequest,
    maintenanceObjects: MaintenanceObject[],
    address: MaintenanceOfferAddress,
    price?: MaintenancePrice,
    absCustomerNumber?: string
  ) {
    const currentDate = new Date();

    return new MaintenanceOffer({
      id: uuidv4(),
      ownerId: maintenanceRequest.ownerId,
      requestId: maintenanceRequest.id,
      created: currentDate,
      maintenanceObjects: maintenanceObjects.map(toOfferedMaintenanceObject),
      decision: new MaintenanceOfferDecision({
        status: 'draft',
        date: currentDate,
      }),
      objectsWithOpenDocumentUpload: [],
      price: price,
      address,
      absCustomerNumber,
    });
  }

  public async calculateDurationInMinutes(
    user: User,
    policy: MaintenanceDurationPolicy
  ): Promise<DurationInMinutes> {
    const minutesOrNull = await Promise.all(
      this.maintenanceObjects.map((maintenanceObject) =>
        maintenanceObject.durationInMinutes(user, policy)
      )
    );

    const minutes = minutesOrNull.map(
      (number) =>
        number ?? new DurationInMinutes({ duration: 0, roofOpening: 0 })
    );
    return minutes.reduce(
      (acc, curr) =>
        acc.copyWith({
          duration: acc.duration + curr.duration,
          roofOpening: acc.roofOpening + curr.roofOpening,
        }),
      new DurationInMinutes({ duration: 0, roofOpening: 0 })
    );
  }
}

export class DurationInMinutes extends Entity {
  public readonly duration: number;
  public readonly roofOpening: number;

  constructor(config: { duration: number; roofOpening: number }) {
    super();
    this.duration = config.duration;
    this.roofOpening = config.roofOpening;
  }
}

export class OfferedMaintenanceObject<
  T extends MaintenanceType = MaintenanceType
> extends Entity {
  public readonly originalId: string;
  public readonly name: string;
  public readonly ownerId: string;
  public readonly created: Date;
  public readonly description?: string | null;
  public readonly manufacturer?: string | null;
  public readonly frequency?: number | null;
  public readonly buildingId?: string | null;
  public readonly type: T;
  public readonly dueDate?: Date | null;

  constructor(config: {
    originalId: string;
    name: string;
    created: Date;
    description?: string | null;
    manufacturer?: string | null;
    frequency?: number | null;
    buildingId?: string | null;
    type: T;
    dueDate?: Date | null;
    ownerId: string;
  }) {
    super();
    this.originalId = config.originalId;
    this.name = config.name;
    this.created = config.created;
    this.description = config.description;
    this.manufacturer = config.manufacturer;
    this.frequency = config.frequency;
    this.buildingId = config.buildingId;
    this.type = config.type;
    this.dueDate = config.dueDate;
    this.ownerId = config.ownerId;
  }

  public async durationInMinutes(
    user: User,
    policy: MaintenanceDurationPolicy
  ): Promise<DurationInMinutes | null> {
    return policy.calculateDurationInMinutes(user, this);
  }
}

export function toOfferedMaintenanceObject(
  maintenanceObject: MaintenanceObject
) {
  return new OfferedMaintenanceObject({
    originalId: maintenanceObject.id,
    buildingId: maintenanceObject.buildingId,
    created: maintenanceObject.created,
    description: maintenanceObject.description,
    dueDate: maintenanceObject.dueDate,
    frequency: maintenanceObject.frequency,
    manufacturer: maintenanceObject.manufacturer,
    name: maintenanceObject.name,
    ownerId: maintenanceObject.ownerId,
    type: maintenanceObject.type,
  });
}

export type MaintenanceOfferUpdate = Pick<MaintenanceOffer, 'id'> &
  Partial<MaintenanceOffer>;
