import { User } from '@warthungshelden/domain/common';

import { BuildingRepository } from '../building';
import { MaintenanceDocumentation } from '../maintenance-documentation';
import {
  DurationInMinutes,
  OfferedMaintenanceObject,
} from '../maintenance-offer';
import { MaintenanceObject } from './maintenance-object';
import {
  FallProtection,
  PushLockFallProtectionSystem,
  RopeFallProtectionSystem,
  SingleAnchorFallProtectionSystem,
} from './types';

export abstract class MaintenanceDurationRule {
  public abstract calculateDurationInMinutes(
    user: User,
    object: MaintenanceObject | OfferedMaintenanceObject,
    baseValue: DurationInMinutes
  ): Promise<DurationInMinutes | null>;
}

export class MaintenanceDurationRoofOpeningRule extends MaintenanceDurationRule {
  constructor(private documentations: MaintenanceDocumentation[]) {
    super();
  }

  async calculateDurationInMinutes(
    user: User,
    object: MaintenanceObject | OfferedMaintenanceObject,
    duration: DurationInMinutes
  ): Promise<DurationInMinutes | null> {
    const type = object.type;

    if (!(type instanceof FallProtection) || type.location === 'Overhead') {
      return duration.copyWith({});
    }

    const hasValidDocumentation = this.documentations.some((documentation) => {
      const objectId = 'id' in object ? object.id : object.originalId;
      return documentation.validFor.includes(objectId);
    });

    if (
      hasValidDocumentation ||
      type.system instanceof PushLockFallProtectionSystem
    ) {
      return duration.copyWith({});
    }

    const anchors =
      type.system instanceof RopeFallProtectionSystem
        ? type.system.singleAnchors + type.system.systemAnchors
        : type.system instanceof SingleAnchorFallProtectionSystem
        ? type.system.singleAnchors
        : 0;

    const roofOpenings = Math.max(Math.floor(anchors * 0.3), 2);
    const roofOpeningDuration = roofOpenings * 60;

    return duration.copyWith({ roofOpening: roofOpeningDuration });
  }
}

export class MaintenanceDurationLiftingPlatformRule extends MaintenanceDurationRule {
  public async calculateDurationInMinutes(
    user: User,
    object: MaintenanceObject | OfferedMaintenanceObject,
    duration: DurationInMinutes
  ): Promise<DurationInMinutes | null> {
    const type = object.type;

    if (!(type instanceof FallProtection)) {
      return duration.copyWith({});
    }

    const hasLiftingPlatform = type.access.includes('LiftingPlatform');

    if (!hasLiftingPlatform) return duration.copyWith({});

    return duration.copyWith({
      duration: duration.duration * 2,
      roofOpening: duration.roofOpening * 2,
    });
  }
}

export class MaintenanceDurationHotelRule extends MaintenanceDurationRule {
  constructor(private buildingRepository: BuildingRepository) {
    super();
  }

  public async calculateDurationInMinutes(
    user: User,
    object: MaintenanceObject | OfferedMaintenanceObject,
    duration: DurationInMinutes
  ): Promise<DurationInMinutes | null> {
    const type = object.type;

    if (!object.buildingId) {
      return duration.copyWith({});
    }

    if (
      !(type instanceof FallProtection) ||
      !(type.system instanceof PushLockFallProtectionSystem)
    ) {
      return duration.copyWith({});
    }

    try {
      const building = await this.buildingRepository.getById(object.buildingId);

      const takesLonger = ['Wohngebäude', 'Hotel'].includes(
        building.buildingType
      );

      if (!takesLonger) return duration.copyWith({});

      const additionalTime = (type.system.anchors + type.system.sleeves) * 5;

      return duration.copyWith({
        duration: duration.duration + additionalTime,
      });
    } catch {
      console.error(
        `Can not access building ${object.buildingId} for evaluating duration`
      );
      return duration.copyWith({});
    }
  }
}

export abstract class MaintenanceDurationPolicy {
  public abstract calculateDurationInMinutes(
    user: User,
    object: MaintenanceObject | OfferedMaintenanceObject
  ): Promise<DurationInMinutes | null>;
}

export class MaintenanceTeamMaintenanceDurationPolicy extends MaintenanceDurationPolicy {
  private readonly rules: MaintenanceDurationRule[];

  constructor(
    private buildingRepository: BuildingRepository,
    private documentations: MaintenanceDocumentation[]
  ) {
    super();

    this.rules = [
      new MaintenanceDurationHotelRule(this.buildingRepository),
      new MaintenanceDurationRoofOpeningRule(this.documentations),
      new MaintenanceDurationLiftingPlatformRule(),
    ];
  }

  public async calculateDurationInMinutes(
    user: User,
    object: MaintenanceObject | OfferedMaintenanceObject
  ): Promise<DurationInMinutes | null> {
    const duration = object.type.durationInMinutes;

    if (typeof duration === 'undefined') {
      return null;
    }

    return this.rules.reduce(async (duration, rule) => {
      return await rule.calculateDurationInMinutes(
        user,
        object,
        (await duration) ??
          new DurationInMinutes({ duration: 0, roofOpening: 0 })
      );
    }, Promise.resolve<DurationInMinutes | null>(new DurationInMinutes({ duration: duration, roofOpening: 0 })));
  }
}
