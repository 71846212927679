import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useOwnCustomer,
  useUpdateCustomer,
} from '../services/api/customer/customer-api';
import { SessionContext } from './SessionContext';

interface ContextInterface {
  showNotificationForm: boolean;
  setShowNotificationForm: (
    state: boolean | ((prevState: boolean) => boolean)
  ) => void;
  isNotificationOpen: boolean;
  setIsNotificationOpen: (
    state: boolean | ((prevState: boolean) => boolean)
  ) => void;
}

const contextDefaultValues: ContextInterface = {
  showNotificationForm: false,
  setShowNotificationForm: () => {
    // no impl
  },
  isNotificationOpen: false,
  setIsNotificationOpen: () => {
    // no impl
  },
};

export const SettingsContext =
  createContext<ContextInterface>(contextDefaultValues);

export const SettingsProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [showNotificationForm, setShowNotificationForm] = useState(true);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { userInfo } = useContext(SessionContext);
  const { data: customer } = useOwnCustomer();
  const { mutateAsync: updateCustomer } = useUpdateCustomer(false);

  // set initial notification email to user email!
  // if notification email is set this will not trigger updates anymore
  useEffect(() => {
    if (
      !userInfo ||
      !userInfo.email ||
      !customer ||
      customer.notification.email
    )
      return;

    const customerDefaultNotification = {
      ...customer.notification,
      email: userInfo.email,
    };

    const updatingCustomer = async () => {
      await updateCustomer({
        ...customer,
        notification: customerDefaultNotification,
      });
    };
    updatingCustomer();
  }, [customer, updateCustomer, userInfo]);

  const value = useMemo<ContextInterface>(
    () => ({
      showNotificationForm,
      setShowNotificationForm,
      isNotificationOpen,
      setIsNotificationOpen,
    }),
    [showNotificationForm, isNotificationOpen]
  );
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};
