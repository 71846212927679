import React from 'react';

interface PriceCalculationLayoutProps {
  topComponent: React.ReactNode;
  bottomComponent: React.ReactNode;
}

const PriceCalculationLayout: React.FC<PriceCalculationLayoutProps> = ({
  topComponent,
  bottomComponent,
}) => {
  return (
    <div className="flex flex-col w-full flex-1">
      <div>{topComponent}</div>
      <div className="flex-1 overflow-hidden">
        <div className="overflow-y-auto h-[90%]">{bottomComponent}</div>
      </div>
    </div>
  );
};

export default PriceCalculationLayout;
