import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import { MaintenanceDocumentation } from '../../maintenance-documentation';

export class UserMustNotChangeValidity extends DomainRule<
  MaintenanceDocumentation,
  MaintenanceDocumentation
> {
  constructor(
    private user: User,
    private newMaintenanceDocumentation: Pick<MaintenanceDocumentation, 'id'> &
      Partial<MaintenanceDocumentation>
  ) {
    super();
  }

  protected async rule(documentation: MaintenanceDocumentation) {
    const changedValidFor =
      this.newMaintenanceDocumentation.validFor &&
      this.newMaintenanceDocumentation.validFor !== documentation.validFor;

    const changedInvalidFor =
      this.newMaintenanceDocumentation.invalidFor &&
      this.newMaintenanceDocumentation.invalidFor !== documentation.invalidFor;

    const changedValidty = changedValidFor || changedInvalidFor;

    if (changedValidty && !this.user.isMaintenanceTeamMemberAdmin) {
      throw new DomainRuleNotSatisfiedError(UserMustNotChangeValidity);
    }

    return documentation;
  }
}
