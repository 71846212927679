import React from 'react';
import { FaPlus } from 'react-icons/fa';

import { Button } from '../../../../components';
import { MenuOptionType } from '../types/MenuOptionType';
import { PriceInformationCombination } from '../utils/combinations';
import { getPreselectedObject } from '../utils/utils';

interface MaintenanceTypeButtonProps {
  onPreselectedObjectClick: (
    preselectedObject: PriceInformationCombination
  ) => void;
  menuOption: MenuOptionType;
}

const MaintenanceTypeButton: React.FC<MaintenanceTypeButtonProps> = ({
  onPreselectedObjectClick,
  menuOption,
}) => {
  return (
    <Button
      className="primary-button small-button mr-4 rounded-md p-5"
      onClick={() => {
        onPreselectedObjectClick(getPreselectedObject(menuOption.key));
      }}
      icon={<FaPlus />}
      label={menuOption.value}
    />
  );
};

export default MaintenanceTypeButton;
