import { isDefined } from '@warthungshelden/shared-functions';

import {
  ClimbingProtectionDto,
  FallProtectionDto,
  FallProtectionSystemDto,
  GroundStairsDto,
  LadderDto,
  MaintenanceTypeDto,
  OtherTypeDto,
  OverpassDto,
  PersonalProtectionEquipmentDto,
  PriceInformationObjectDto,
  PriceableObjectDto,
  PushLockFallProtectionSystemDto,
  RailSystemDto,
  RailingDto,
  RopeFallProtectionSystemDto,
  SingleAnchorFallProtectionSystemDto,
  StairLadderDto,
} from '@wartungshelden/shared-types';

import {
  isPushLockSystemDto,
  isRopeSystemDto,
  isSingleAnchorSystemDto,
} from '../../../../guards/isFallProtectionSystem';
import {
  isClimbingProtectionDto,
  isFallProtectionDto,
  isGroundStairsDto,
  isLadderDto,
  isOtherTypeDto,
  isOverpassDto,
  isPPEDto,
  isRailSystemDto,
  isRailingDto,
  isStairLadderDto,
} from '../../../../guards/isMaintenanceType';

type CombinationType<T extends MaintenanceTypeDto> = PriceInformationObjectDto<
  PriceableObjectDto<T>
>;

export interface SystemCombination {
  type: string;
  primarySystem: PriceInformationObjectDto;
}

export interface RopeSystemCombination extends SystemCombination {
  type: 'RopeSystemCombination';
  primarySystem: CombinationType<
    FallProtectionDto<RopeFallProtectionSystemDto>
  >;
  glider?: CombinationType<PersonalProtectionEquipmentDto>;
}

export interface PushLockCombination extends SystemCombination {
  type: 'PushLockCombination';
  primarySystem: CombinationType<
    FallProtectionDto<PushLockFallProtectionSystemDto>
  >;
}

export interface SingleAnchorCombination extends SystemCombination {
  type: 'SingleAnchorCombination';
  primarySystem: CombinationType<
    FallProtectionDto<SingleAnchorFallProtectionSystemDto>
  >;
}

export interface PersonalProtectionEquipmentCombination
  extends SystemCombination {
  type: 'PersonalProtectionEquipmentCombination';
  primarySystem: CombinationType<PersonalProtectionEquipmentDto>;
}

export interface LadderCombination extends SystemCombination {
  type: 'LadderCombination';
  primarySystem: CombinationType<LadderDto>;
}

export interface RailingCombination extends SystemCombination {
  type: 'RailingCombination';
  primarySystem: CombinationType<RailingDto>;
}

export interface RailSystemCombination extends SystemCombination {
  type: 'RailSystemCombination';
  primarySystem: CombinationType<RailSystemDto>;
  glider?: CombinationType<PersonalProtectionEquipmentDto>;
}

export interface ClimbingProtectionCombination extends SystemCombination {
  type: 'ClimbingProtectionCombination';
  primarySystem: CombinationType<ClimbingProtectionDto>;
  ladder?: CombinationType<LadderDto>;
  glider?: CombinationType<PersonalProtectionEquipmentDto>;
}

export interface StairLadderCombination extends SystemCombination {
  type: 'StairLadderCombination';
  primarySystem: CombinationType<StairLadderDto>;
}

export interface OverpassCombination extends SystemCombination {
  type: 'OverpassCombination';
  primarySystem: CombinationType<OverpassDto>;
}

export interface GroundStairsCombination extends SystemCombination {
  type: 'GroundStairsCombination';
  primarySystem: CombinationType<GroundStairsDto>;
}

export interface OtherTypeCombination extends SystemCombination {
  type: 'OtherTypeCombination';
  primarySystem: CombinationType<OtherTypeDto>;
}

export type PriceInformationCombination =
  | RopeSystemCombination
  | PushLockCombination
  | SingleAnchorCombination
  | PersonalProtectionEquipmentCombination
  | LadderCombination
  | RailingCombination
  | RailSystemCombination
  | ClimbingProtectionCombination
  | StairLadderCombination
  | OverpassCombination
  | GroundStairsCombination
  | OtherTypeCombination;

function isCombination<T extends PriceInformationCombination>(
  combination: any,
  type: string
): combination is T {
  return (
    typeof combination === 'object' &&
    combination !== null &&
    'type' in combination &&
    combination?.type === type
  );
}

export function isRopeSystemCombination(
  combination: object
): combination is RopeSystemCombination {
  return isCombination<RopeSystemCombination>(
    combination,
    'RopeSystemCombination'
  );
}

export function isPushLockCombination(
  combination: object
): combination is PushLockCombination {
  return isCombination<PushLockCombination>(combination, 'PushLockCombination');
}

export function isSingleAnchorCombination(
  combination: object
): combination is SingleAnchorCombination {
  return isCombination<SingleAnchorCombination>(
    combination,
    'SingleAnchorCombination'
  );
}

export function isPersonalProtectionEquipmenCombination(
  combination: object
): combination is PersonalProtectionEquipmentCombination {
  return isCombination<PersonalProtectionEquipmentCombination>(
    combination,
    'PersonalProtectionEquipmentCombination'
  );
}

export function isLadderCombination(
  combination: object
): combination is LadderCombination {
  return isCombination<LadderCombination>(combination, 'LadderCombination');
}

export function isRailingCombination(
  combination: object
): combination is RailingCombination {
  return isCombination<RailingCombination>(combination, 'RailingCombination');
}

export function isRailSystemCombination(
  combination: object
): combination is RailSystemCombination {
  return isCombination<RailSystemCombination>(
    combination,
    'RailSystemCombination'
  );
}

export function isClimbingProtectionCombination(
  combination: object
): combination is ClimbingProtectionCombination {
  return isCombination<ClimbingProtectionCombination>(
    combination,
    'ClimbingProtectionCombination'
  );
}

export function isStairLadderCombination(
  combination: object
): combination is StairLadderCombination {
  return isCombination<StairLadderCombination>(
    combination,
    'StairLadderCombination'
  );
}

export function isOverpassCombination(
  combination: object
): combination is OverpassCombination {
  return isCombination<OverpassCombination>(combination, 'OverpassCombination');
}

export function isGroundStairsCombination(
  combination: object
): combination is GroundStairsCombination {
  return isCombination<GroundStairsCombination>(
    combination,
    'GroundStairsCombination'
  );
}

export function isOtherTypeCombination(
  combination: object
): combination is OtherTypeCombination {
  return isCombination<OtherTypeCombination>(
    combination,
    'OtherTypeCombination'
  );
}

const toCombination = (
  priceInformationObjects: PriceInformationObjectDto[]
): PriceInformationCombination => {
  const findWithType = <T extends MaintenanceTypeDto>(
    guard: (type?: MaintenanceTypeDto) => boolean
  ) =>
    priceInformationObjects.find((object) =>
      guard(object.object.type)
    ) as CombinationType<T>;

  const findWithFallProtectionType = <T extends FallProtectionSystemDto>(
    guard: (type?: FallProtectionSystemDto | undefined) => boolean
  ) =>
    priceInformationObjects
      .filter((object) => isFallProtectionDto(object.object.type))
      .find((object) =>
        guard((object.object.type as FallProtectionDto).system)
      ) as CombinationType<FallProtectionDto<T>>;

  const ropeSystem =
    findWithFallProtectionType<RopeFallProtectionSystemDto>(isRopeSystemDto);

  const pushLockSystem =
    findWithFallProtectionType<PushLockFallProtectionSystemDto>(
      isPushLockSystemDto
    );
  const singleAnchorSystem =
    findWithFallProtectionType<SingleAnchorFallProtectionSystemDto>(
      isSingleAnchorSystemDto
    );

  const ppe = findWithType<PersonalProtectionEquipmentDto>(isPPEDto);
  const ladder = findWithType<LadderDto>(isLadderDto);
  const railing = findWithType<RailingDto>(isRailingDto);
  const railSystem = findWithType<RailSystemDto>(isRailSystemDto);
  const stairLadder = findWithType<StairLadderDto>(isStairLadderDto);
  const overpass = findWithType<OverpassDto>(isOverpassDto);
  const groundStairs = findWithType<GroundStairsDto>(isGroundStairsDto);
  const other = findWithType<OtherTypeDto>(isOtherTypeDto);

  const climbingProtection = findWithType<ClimbingProtectionDto>(
    isClimbingProtectionDto
  );

  if (climbingProtection) {
    return {
      type: 'ClimbingProtectionCombination',
      primarySystem: climbingProtection,
      ladder,
      glider: ppe,
    };
  }

  if (ropeSystem) {
    return {
      type: 'RopeSystemCombination',
      primarySystem: ropeSystem,
      glider: ppe,
    };
  }

  if (railSystem) {
    return {
      type: 'RailSystemCombination',
      primarySystem: railSystem,
      glider: ppe,
    };
  }

  if (pushLockSystem) {
    return { type: 'PushLockCombination', primarySystem: pushLockSystem };
  }

  if (singleAnchorSystem) {
    return {
      type: 'SingleAnchorCombination',
      primarySystem: singleAnchorSystem,
    };
  }

  if (ppe) {
    return {
      type: 'PersonalProtectionEquipmentCombination',
      primarySystem: ppe,
    };
  }

  if (ladder) {
    return { type: 'LadderCombination', primarySystem: ladder };
  }

  if (railing) {
    return { type: 'RailingCombination', primarySystem: railing };
  }

  if (stairLadder) {
    return { type: 'StairLadderCombination', primarySystem: stairLadder };
  }

  if (overpass) {
    return { type: 'OverpassCombination', primarySystem: overpass };
  }

  if (groundStairs) {
    return { type: 'GroundStairsCombination', primarySystem: groundStairs };
  }

  if (other) {
    return { type: 'OtherTypeCombination', primarySystem: other };
  }

  throw new Error('Combination is not possible');
};

const toLowestObjectIndex = (
  combination: PriceInformationObjectDto[],
  priceInformationObjects: PriceInformationObjectDto[]
) => {
  return Math.min(
    ...combination.map((combinedObject) =>
      priceInformationObjects.findIndex(
        ({ object }) => object.id === combinedObject.object.id
      )
    )
  );
};

const toIndexCombinationMap = (
  combinedObjects: PriceInformationObjectDto[][]
) => {
  return (
    indexOfCombination: number,
    index: number
  ): [number, PriceInformationObjectDto[]] => [
    indexOfCombination,
    combinedObjects[index],
  ];
};

export const toCombinations = (
  combinationConfiguration: string[][],
  priceInformationObjects: PriceInformationObjectDto[]
): PriceInformationCombination[] => {
  const isNotInConfiguration = (object) =>
    !combinationConfiguration.flat().includes(object.object.id);

  const toObjectPriceInfo = (id) => {
    return priceInformationObjects.find((object) => object.object.id === id);
  };

  const withCombinationConfig = combinationConfiguration.map((config) =>
    config.map(toObjectPriceInfo).filter(isDefined)
  );

  const withoutCombinationConfig = priceInformationObjects
    .filter(isNotInConfiguration)
    .map((object) => [object]);

  const combinedObjects = [
    ...withCombinationConfig,
    ...withoutCombinationConfig,
  ];

  return combinedObjects
    .map((combination) =>
      toLowestObjectIndex(combination, priceInformationObjects)
    )
    .map(toIndexCombinationMap(combinedObjects))
    .sort(([index1], [index2]) => index1 - index2)
    .map(([, combination]) => combination)
    .map(toCombination);
};

export function toPriceInformationObject(
  combination: Pick<PriceInformationCombination, 'primarySystem'>
) {
  return combination.primarySystem;
}

export function toPriceInformationObjects(
  combination: PriceInformationCombination
): PriceInformationObjectDto[] {
  return Object.values(combination).filter(
    (value) => typeof value === 'object'
  );
}
