import React from 'react';

import { UnitInputField } from '../../index';

const SingleSystem: React.FC<React.PropsWithChildren<{ index: number }>> = ({
  index,
}) => (
  <UnitInputField
    name={`maintenanceObjects.${index}.system.singleAnchors`}
    label="Einzelanschlagpunkte"
    unit="Stück"
    labelWidthTailwindClass="w-80"
    isLabelBold={false}
  />
);

export default SingleSystem;
