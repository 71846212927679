import { useFormikContext } from 'formik';
import React from 'react';

import { PriceInformationObjectDto } from '@wartungshelden/shared-types';

import DropdownFieldLayout from '../../../../../components/Basics/Inputs/DropdownFieldLayout';
import UnitInputField from '../../../../../components/Basics/Inputs/UnitInputField';
import {
  MANUFACTURERS,
  PSAGA_SYSTEM_TYPES,
} from '../../../../../constants/MaintenanceConstants';
import ManufacturerSelector from './ManufacturerSelector';

const PSASelector = () => {
  const { values, setFieldValue } =
    useFormikContext<PriceInformationObjectDto>();

  const hasManufacturer = (systemType) =>
    ![
      'DOME_COMPLETE',
      'DOME_FABRIC_THREAD',
      'DOME_FIX',
      'HELMET',
      'RESCUE_DESCENDER',
    ].includes(systemType);

  return (
    <div className="space-y-12">
      <div>
        <div className="max-w-2xl px-8">
          <DropdownFieldLayout
            label="Typ*"
            name="object.type.systemType"
            labelWidthTailwindClass="w-80 font-bold"
            options={Object.values(PSAGA_SYSTEM_TYPES)}
            searchable
            onSelect={(selection) => {
              if (!hasManufacturer(selection)) {
                setFieldValue('object.manufacturer', MANUFACTURERS[0]);
              }
              setFieldValue('object.type.systemType', selection);
            }}
          />
        </div>
      </div>

      <ManufacturerSelector
        disableForeignManufacturer={
          !hasManufacturer(
            'systemType' in values.object.type && values.object.type.systemType
          )
        }
      />

      <div className="px-8">
        <UnitInputField
          label="Anzahl*"
          name="object.type.quantity"
          labelWidthTailwindClass="w-80"
          shouldSelectOnFocus
          unit="Stück"
        />
      </div>
    </div>
  );
};

export default PSASelector;
