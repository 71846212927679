import { CellProps } from 'react-table';
import { ObjectSchema } from 'yup';
import { SchemaFieldDescription } from 'yup/es/schema';

import {
  MaintenanceObjectDto,
  MaintenanceTypeDto,
} from '@wartungshelden/shared-types';

// eslint-disable-next-line import/no-cycle
import { TableData } from '../components/Maintenance/MaintenanceTable/UserMaintenanceTable';
import { providedServiceToLabel } from '../pages/UserPages/maintenance/MaintenanceObject';
import { isPriceableMaintenanceType } from '../services/maintenanceService';
import { isServiceAlreadySupported } from './MaintenanceConstants';
import { requestableMaintenanceObjectValidationSchema } from './MaintenanceYupSchema';

export const getDefaultFrequencies = (providedService: string) => {
  if (isServiceAlreadySupported(providedService)) {
    return 12;
  }
  return undefined;
};

export const isDefaultFrequency = (
  frequency?: number | '' | null,
  type?: string | null
): boolean => {
  if (!type) return false;
  if (frequency === '') return false;
  return frequency === getDefaultFrequencies(type);
};

export const getCheckField = (maintenance: MaintenanceObjectDto) => {
  if (isPriceableMaintenanceType(maintenance)) {
    return requestableMaintenanceObjectValidationSchema.isValidSync(maintenance)
      ? 'Vollständig'
      : 'Angaben fehlen';
  }
  return 'Typ nicht unterstützt';
};

export const isRequiredField = (
  validationSchema: ObjectSchema<any>,
  name: string
) => {
  const splittedName = name.split('.');
  let field: SchemaFieldDescription | undefined =
    validationSchema.describe().fields[splittedName[0]];
  splittedName.forEach((n, index) => {
    if (index === 0) return;

    if (!Number.isNaN(parseInt(n, 10))) return;

    if (field?.type === 'array') {
      if ('innerType' in field) {
        field = field.innerType;
      }
    }

    if (field && 'fields' in field) {
      field = field.fields[n];
    }
  });
  if ('tests' in field) {
    return field.tests.some((test) => test.name === 'required');
  }
  return false;
};

export const renderTypeCell = (
  cell: CellProps<TableData, MaintenanceTypeDto>
) =>
  providedServiceToLabel(
    cell.value.type === 'other_type' ? cell.value.name : cell.value.type
  );
