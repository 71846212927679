export interface Documentation {
  readonly id: number;
  readonly isCompleted: boolean;
  readonly isActive: boolean;
  readonly type: 'maintenance' | 'assembly';
}

export interface DocumentationDto extends Documentation {
  readonly updatedAt: Date;
  readonly createdAt: Date;
  readonly products: ProductDto[];
}

export interface BuildingAreaDto {
  readonly buildingId: number;
  readonly id: number;
  readonly name: string;
  readonly isDeleted: boolean;
  readonly documentations: DocumentationDto[];
}

export interface MaintenanceAdministrationBuildingContactDto {
  readonly role: string | null;
  readonly salutation: string | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string | null;
  readonly phone1: string | null;
  readonly phone2: string | null;
  readonly fax: string | null;
}

export interface MaintenanceAdministrationBuildingAddressDto {
  readonly address1: string | null;
  readonly address2: string | null;
  readonly postcode: string | null;
  readonly city: string | null;
  readonly country: string | null;
}

export interface MaintenanceAdministrationBuildingDto {
  id: number;
  name: string | null;
  companyId: number;
  contact: MaintenanceAdministrationBuildingContactDto;
  address: MaintenanceAdministrationBuildingAddressDto;
  imageUrl: string | null;
  comment: string | null;
  projectNumber: string | null;
  isActive: boolean;
  amountAssemblies: number | null;
  amountMaintenances: number | null;
}

export interface InstalledArticleDto {
  identificationNumber: string;
  identificationCounter: string;
  comment: string;
  isDeleted: boolean;
  serialNumber: string;
}

export interface ProductDto {
  readonly productName: string;
  readonly documentationItems: DocumentationItemDto[];
}

export interface DocumentationItemQuestionDto {
  readonly id: number;
  readonly question: string;
  readonly questionType: 'yesno' | 'text';
  readonly documentationItemId: number;
  readonly position: number;
}

export interface DocumentationItemAnswerDto {
  readonly id: number;
  readonly answer: string;
  readonly questionId: number;
}

export interface DocumentationItemDto {
  readonly installedArticleId: number;
  readonly id: number;
  readonly documentationId: number;
  readonly name: string;
  readonly installedArticle: InstalledArticleDto | null;
}
