import React from 'react';
import { Modal } from 'react-responsive-modal';

import { palette } from '../../../constants/palette';
import Loader from './Loader';

interface LoadingSpinnerProps {
  isLoading: boolean;
}

const LoadingSpinner: React.FC<
  React.PropsWithChildren<LoadingSpinnerProps>
> = ({ isLoading, children }) => (
  <Modal
    open={isLoading}
    onClose={() => {}}
    center
    showCloseIcon={false}
    styles={{
      modal: { backgroundColor: palette.offWhite, padding: 0 },
    }}
  >
    <div
      className="flex flex-col flex-1 bg-white px-8 py-6 items-center"
      data-cy="loading-spinner"
    >
      <Loader />
      {children && <div className="mt-4 text-2xl">{children}</div>}
    </div>
  </Modal>
);

export default LoadingSpinner;
