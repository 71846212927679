export interface AppointmentDto {
  readonly id: number | string;
  readonly name?: string;
  readonly description?: string;
  readonly place?: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly wholeDay?: boolean;
  readonly private: boolean;
  readonly transparent: boolean;
}
