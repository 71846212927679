import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import {
  FallProtection,
  MaintenanceType,
  RopeFallProtectionSystem,
} from '../../../maintenance-object';

export class RopeSystemMandatoryValuesCantBeZeroRule<
  T extends { type: MaintenanceType }
> extends DomainRule<T, T> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (
      entity.type instanceof FallProtection &&
      entity.type.system instanceof RopeFallProtectionSystem &&
      (entity.type.system.systemAnchors === 0 ||
        entity.type.system.ropeLength === 0)
    ) {
      throw new DomainRuleNotSatisfiedError(
        RopeSystemMandatoryValuesCantBeZeroRule
      );
    }
    return entity;
  }
}
