import { useFormikContext } from 'formik';
import React from 'react';

import FieldError from '../Basics/FieldError';
import CountryDropDown from '../Basics/Inputs/CountryDropDown';
import { InputField, PhoneInputField } from '../index';

interface AddressFormProps {
  namePrefix?: string;
  showContactData?: boolean;
}

const AddressForm: React.FC<React.PropsWithChildren<AddressFormProps>> = ({
  children,
  namePrefix,
  showContactData,
}) => {
  const { errors, touched } = useFormikContext<any>();

  return (
    <>
      <InputField
        name={namePrefix ? `${namePrefix}.company` : 'company'}
        label="Firma"
      />
      <InputField
        name={namePrefix ? `${namePrefix}.firstName` : 'firstName'}
        label="Vorname"
      />
      <InputField
        name={namePrefix ? `${namePrefix}.lastName` : 'lastName'}
        label="Nachname"
      />
      <InputField
        name={namePrefix ? `${namePrefix}.addressLine` : 'addressLine'}
        label="Straße, Hausnummer"
      />
      <InputField
        name={namePrefix ? `${namePrefix}.postalCode` : 'postalCode'}
        label="Postleitzahl"
      />
      <InputField
        name={namePrefix ? `${namePrefix}.city` : 'city'}
        label="Stadt"
      />
      <CountryDropDown
        name={namePrefix ? `${namePrefix}.country` : 'country'}
        label="Land"
      />
      {showContactData && (
        <>
          <div className="mt-6 mb-4 font-bold">
            An wen sollen wir uns bei eventuellen Rückfragen zu dieser Anfrage
            wenden?
          </div>
          <InputField name="email" label="E-Mail-Adresse" />
          <PhoneInputField name="phone" label="Telefonnummer (optional)" />
          <FieldError
            message={errors.phone as string}
            visible={Boolean(touched.phone && errors.phone)}
          />
        </>
      )}
      <div className="flex justify-center">{children}</div>
    </>
  );
};

AddressForm.defaultProps = {
  showContactData: true,
  namePrefix: undefined,
};

export default AddressForm;
