import { isPhoneNumber } from 'class-validator';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import AuthInputField from '../../components/Auth/AuthInputField';
import AuthLinkButton from '../../components/Auth/AuthLinkButton';
import AuthPhoneInputField from '../../components/Auth/AuthPhoneInputField';
import AuthPrimaryButton from '../../components/Auth/AuthPrimaryButton';
import FieldError from '../../components/Basics/FieldError';
import PolicyAuthFooter from '../../components/Policies/PolicyAuthFooter';
import { SessionContext } from '../../contexts/SessionContext';

const VerifyConfirm = ({ isAdminLogin }: { isAdminLogin?: boolean }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [params] = useSearchParams();

  const { resetPassword } = useContext(SessionContext);

  const initialCode =
    ((state as any)?.verificationCode as string | undefined) ??
    params.get('verificationCode');

  const initialEmail =
    ((state as any)?.email as string | undefined) ?? params.get('email');

  const initialPhone = !initialEmail
    ? ((state as any)?.phone as string | undefined) ?? params.get('phone')
    : undefined;

  const [isEmailSelected, setIsEmailSelected] = useState(!initialPhone);

  const navigateToLogin = (email?: string, phone?: string) => {
    navigate(isAdminLogin ? '/admin/login' : '/login', {
      state: { email, phone },
    });
  };

  const ResetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-Mail nicht gültig')
      .when({
        is: () => isEmailSelected,
        then: (schema) => schema.required('E-Mail erforderlich'),
        otherwise: (schema) => schema.optional(),
      }),
    phone: Yup.string().when({
      is: () => !isEmailSelected,
      then: (schema) =>
        schema.test({
          message: 'Rufnummer nicht gültig',
          test: (number) => isPhoneNumber(number ?? ''),
        }),
      otherwise: (schema) => schema.optional(),
    }),
    verificationCode: Yup.string()
      .required('Code erforderlich')
      .length(6, 'Code ungültig'),
    password: Yup.string()
      .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein')
      .matches(/\d/, 'Das Passwort muss mindestens eine Zahl enthalten')
      .matches(
        /[a-z]/,
        'Das Passwort muss mindestens einen Kleinbuchstaben enthalten'
      )
      .matches(
        /[A-Z]/,
        'Das Passwort muss mindestens einen Großbuchstaben enthalten'
      )
      .matches(
        /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/,
        'Das Passwort muss mindestens eines der folgenden Sonderzeichen enthalten: (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =)'
      )
      .required('Passwort erforderlich'),
  });

  const onSubmit = async (values) => {
    const success = await resetPassword(
      isEmailSelected ? values.email : values.phone,
      values.verificationCode,
      values.password
    );

    if (success) {
      navigateToLogin(
        values.email.length ? values.email : undefined,
        values.phone.length ? values.phone : undefined
      );
    }
  };

  return (
    <Formik
      validationSchema={ResetPasswordValidationSchema}
      initialValues={{
        email: initialEmail ?? '',
        phone: initialPhone ?? '',
        verificationCode: initialCode ?? '',
        password: '',
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        submitForm,
        isValid,
        dirty,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <div className="flex justify-center px-8 pt-4 pb-4">
            <img src="/assets/logo.svg" alt="ABS Wartung" className="w-3/5" />
          </div>
          <div className="bg-white shadow w-full rounded-lg mb-2">
            <div className="px-5 py-7">
              <div className="py-2">
                {isEmailSelected
                  ? 'Um ein neues Passwort zu erstellen, gib Deine E-Mail-Adresse und den Code ein, den wir Dir per E-Mail gesendet haben.'
                  : 'Um ein neues Passwort zu erstellen, gib Deine Telefonnummer und den Code ein, den wir Dir per SMS gesendet haben.'}
              </div>
              <div className="pt-3">
                {isEmailSelected ? (
                  <AuthInputField name="email" label="E-Mail" />
                ) : (
                  <AuthPhoneInputField name="phone" label="Telefon" />
                )}
                <AuthLinkButton
                  label={`Stattdessen mit ${
                    isEmailSelected ? 'Telefonnummer' : 'E-Mail'
                  } registrieren`}
                  onClick={() => {
                    setFieldValue(isEmailSelected ? 'email' : 'phone', '');
                    setFieldTouched(isEmailSelected ? 'email' : 'phone', false);
                    setIsEmailSelected((prev) => !prev);
                  }}
                />
                <FieldError
                  visible={Boolean(errors.email && touched.email)}
                  message={errors.email}
                />
                <FieldError
                  visible={Boolean(errors.phone && touched.phone)}
                  message={errors.phone}
                />
              </div>
              <div className="pt-3">
                <AuthInputField name="verificationCode" label="Code" />
                <FieldError
                  visible={Boolean(
                    errors.verificationCode && touched.verificationCode
                  )}
                  message={errors.verificationCode}
                />
              </div>
              <div className="pt-3">
                <AuthInputField
                  name="password"
                  label="Passwort"
                  type="password"
                />
                <p className="pt-2 text-sm text-gray-600">
                  Das Passwort sollte mindenstens 8 Zeichen lang sein, einen
                  Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein
                  Sonderzeichen enthalten.
                </p>
                <FieldError
                  visible={Boolean(errors.password && touched.password)}
                  message={errors.password}
                />
              </div>
              <div className="pt-7">
                <AuthPrimaryButton
                  onClick={submitForm}
                  label="Neues Passwort erstellen"
                  disabled={!isValid || !dirty}
                  className="primary-button full-button"
                />
              </div>
            </div>
          </div>

          <PolicyAuthFooter />
        </>
      )}
    </Formik>
  );
};

VerifyConfirm.defaultProps = {
  isAdminLogin: false,
};

export default VerifyConfirm;
