import { isDefined } from '@warthungshelden/shared-functions';
import { v4 as uuidv4 } from 'uuid';

import {
  FallProtectionTypes,
  LadderDto,
  MaintenanceObjectPriceDto,
  MaintenanceTypeDto,
  MaintenanceTypes,
  OverpassDto,
  PriceInformationDto,
  PriceInformationObjectDto,
  StairLadderDto,
} from '@wartungshelden/shared-types';

import { PSAGA_SYSTEM_TYPES } from '../../../../constants/MaintenanceConstants';
import { isPushLockSystemDto } from '../../../../guards/isFallProtectionSystem';
import { isFallProtectionDto } from '../../../../guards/isMaintenanceType';
import {
  ClimbingProtectionCombination,
  GroundStairsCombination,
  LadderCombination,
  OverpassCombination,
  PersonalProtectionEquipmentCombination,
  PriceInformationCombination,
  PushLockCombination,
  RailSystemCombination,
  RailingCombination,
  RopeSystemCombination,
  SingleAnchorCombination,
  StairLadderCombination,
  isClimbingProtectionCombination,
  isGroundStairsCombination,
  isLadderCombination,
  isOverpassCombination,
  isPersonalProtectionEquipmenCombination,
  isPushLockCombination,
  isRailSystemCombination,
  isRailingCombination,
  isRopeSystemCombination,
  isSingleAnchorCombination,
  isStairLadderCombination,
} from './combinations';

const easyAccessBuildingTypes = new Set([
  'Bürogebäude',
  'Industriegebäude',
  'Schule',
]);

export const FOREIGN_MANUFACTURER = 'Fremdhersteller';

export const OBJECT_INFORMATION_SESSION_KEY = 'objectInformation';
export const PRICE_WITHOUT_DOCU_SESSION_KEY = 'priceWithDocu';
export const PRICE_WITH_DOCU_SESSION_KEY = 'priceWithoutDocu';

export default easyAccessBuildingTypes;

export const buildingText = (
  priceInformationObject: PriceInformationObjectDto
) => {
  if (
    isFallProtectionDto(priceInformationObject.object.type) &&
    isPushLockSystemDto(priceInformationObject.object.type.system)
  ) {
    return priceInformationObject.building?.buildingType &&
      easyAccessBuildingTypes.has(priceInformationObject.building.buildingType)
      ? 'Einfach zugänglich'
      : 'Schwer zugänglich';
  }
  return priceInformationObject.building?.isPublic
    ? 'Öffentlich'
    : 'Nicht öffentlich';
};

export const getLabelForMaintenanceTypeOrFallProtectionSystem = (
  maintenanceType: MaintenanceTypes | FallProtectionTypes,
  psaSystemType?: string
) => {
  if (maintenanceType === 'rope') return 'Seilsystem';
  if (maintenanceType === 'single_anchor') return 'Einzelanschlagpunkte';
  if (maintenanceType === 'push_lock') return 'Lock I / Lock II';
  if (maintenanceType === 'personal_protection_equipment') {
    if (!psaSystemType) return 'PSAgA';
    return `PSAgA: ${PSAGA_SYSTEM_TYPES[psaSystemType].label}`;
  }
  if (maintenanceType === 'railing') return 'Geländer';
  if (maintenanceType === 'rail_system') return 'Schienensystem';
  if (maintenanceType === 'climbing_protection') return 'Steigschutz';
  if (maintenanceType === 'overpass') return 'Überstieg';
  if (maintenanceType === 'stair_ladder') return 'Treppenleiter';
  if (maintenanceType === 'ground_stairs') return 'Bodentreppe';
  return 'Steigleiter';
};

export const getLabelForPriceComponent = (
  maintenanceType: MaintenanceTypes | MaintenanceTypeDto | FallProtectionTypes,
  psaSystemType?: string
): string => {
  if (maintenanceType === 'rope')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'single_anchor')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'push_lock')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'personal_protection_equipment') {
    if (!psaSystemType) return 'PSAgA';
    return PSAGA_SYSTEM_TYPES[psaSystemType].label;
  }
  if (maintenanceType === 'railing')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'rail_system')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'climbing_protection')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'overpass')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'stair_ladder')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'ground_stairs')
    return `${getLabelForMaintenanceTypeOrFallProtectionSystem(
      maintenanceType,
      psaSystemType
    )} (gesamt)`;
  if (maintenanceType === 'ladder') return 'Steigleiter (inkl. Podeste)';
  return psaSystemType ?? (maintenanceType as string);
};

export const preselectedClimbingProtection: PriceInformationCombination = {
  type: 'ClimbingProtectionCombination',
  primarySystem: {
    object: {
      type: {
        type: 'climbing_protection',
        length: 0,
      },
      id: uuidv4(),
      manufacturer: null,
    },
    hasValidDocumentation: undefined,
    building: {
      buildingType: '',
      isPublic: false,
    },
  },
};
export const getPreselectedObject = (
  maintenanceType: MaintenanceTypes | FallProtectionTypes
): PriceInformationCombination => {
  if (maintenanceType === 'rope') {
    return {
      type: 'RopeSystemCombination',
      primarySystem: {
        object: {
          type: {
            type: 'fall_protection',
            system: {
              system: 'rope',
              ropeLength: 0,
              systemAnchors: 0,
              singleAnchors: 0,
            },
            access: [],
          },
          manufacturer: null,
          id: uuidv4(),
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  if (maintenanceType === 'push_lock') {
    return {
      type: 'PushLockCombination',
      primarySystem: {
        object: {
          type: {
            type: 'fall_protection',
            system: {
              system: 'push_lock',
              anchors: 0,
              sleeves: 0,
            },
            access: [],
          },
          id: uuidv4(),
          manufacturer: 'ABS Safety',
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  if (maintenanceType === 'single_anchor') {
    return {
      type: 'SingleAnchorCombination',
      primarySystem: {
        object: {
          type: {
            type: 'fall_protection',
            system: {
              system: 'single_anchor',
              singleAnchors: 0,
            },
            access: [],
          },
          id: uuidv4(),
          manufacturer: null,
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  if (maintenanceType === 'personal_protection_equipment') {
    return {
      type: 'PersonalProtectionEquipmentCombination',
      primarySystem: {
        object: {
          type: {
            type: 'personal_protection_equipment',
            quantity: 0,
          },
          id: uuidv4(),
          manufacturer: null,
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }
  if (maintenanceType === 'railing') {
    return {
      type: 'RailingCombination',
      primarySystem: {
        object: {
          type: {
            type: 'railing',
            length: 0,
          },
          id: uuidv4(),
          manufacturer: null,
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  if (maintenanceType === 'rail_system') {
    return {
      type: 'RailSystemCombination',
      primarySystem: {
        object: {
          type: {
            type: 'rail_system',
            length: 0,
            brackets: 0,
          },
          id: uuidv4(),
          manufacturer: null,
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  if (maintenanceType === 'climbing_protection') {
    return preselectedClimbingProtection;
  }

  if (maintenanceType === 'stair_ladder') {
    return {
      type: 'StairLadderCombination',
      primarySystem: {
        object: {
          type: {
            type: 'stair_ladder',
            parts: [],
          },
          id: uuidv4(),
          manufacturer: null,
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  if (maintenanceType === 'overpass') {
    return {
      type: 'OverpassCombination',
      primarySystem: {
        object: {
          type: {
            type: 'overpass',
            parts: [],
          },
          id: uuidv4(),
          manufacturer: null,
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  if (maintenanceType === 'ground_stairs') {
    return {
      type: 'GroundStairsCombination',
      primarySystem: {
        object: {
          type: {
            type: 'ground_stairs',
            amount: 0,
          },
          id: uuidv4(),
          manufacturer: null,
        },
        hasValidDocumentation: undefined,
        building: {
          buildingType: '',
          isPublic: false,
        },
      },
    };
  }

  return {
    type: 'LadderCombination',
    primarySystem: {
      object: {
        type: {
          type: 'ladder',
          parts: [],
          numberOfPlatforms: 0,
        },
        id: uuidv4(),
        manufacturer: null,
      },
      hasValidDocumentation: undefined,
      building: {
        buildingType: '',
        isPublic: false,
      },
    },
  };
};

export const CURRENT_DISCOUNT = 1;

export const getSinglePrice = (
  maintenanceObjectPrice: MaintenanceObjectPriceDto,
  hasDocu: boolean
) => {
  const missingDocuSurcharge =
    maintenanceObjectPrice.absoluteVariablePriceComponents.actual.find(
      (variablePriceComponent) =>
        variablePriceComponent.type === 'documentation_missing'
    )?.value;

  return (
    ((maintenanceObjectPrice.variableNetPrice ?? 0) -
      (hasDocu && missingDocuSurcharge ? missingDocuSurcharge : 0)) *
    CURRENT_DISCOUNT
  );
};

export const toLadderCategoryLabel = (
  ladder: LadderDto,
  abbreviated: boolean
) => {
  const small = ladder.parts.filter((length) => length <= 10).length;
  const large = ladder.parts.filter((length) => length > 10).length;

  const smallLaddersLabel = abbreviated ? '10m' : 'bis 10m';
  const largeLaddersLabel = abbreviated ? '10m-30m' : '10m bis 30m';
  return [small ? smallLaddersLabel : null, large ? largeLaddersLabel : null]
    .filter(isDefined)
    .join(', ');
};

export const toPPECategoryLabel = (systemType: string) =>
  PSAGA_SYSTEM_TYPES[systemType].label;

export const toStepsLabel = (
  climbingEquipment: StairLadderDto | OverpassDto
) => {
  const small = climbingEquipment.parts.filter((steps) => steps <= 3).length;
  const large = climbingEquipment.parts.filter((steps) => steps > 3).length;

  return [small ? `bis 3 Stufen` : null, large ? `bis 8 Stufen` : null]
    .filter(isDefined)
    .join(', ');
};

export function notEmptyString(input?: string) {
  return input !== '';
}

export type PriceCalculationState = PriceInformationDto & {
  combinations: string[][];
};
export const deleteCalculationObjects = async (
  objectsToDelete: PriceInformationObjectDto[],
  currentObjects: PriceCalculationState
): Promise<PriceCalculationState> => {
  const newObjectInformation = currentObjects.objectInformation.filter(
    (objectInformation) =>
      objectsToDelete.every(
        (objectToDelete) =>
          objectToDelete.object.id !== objectInformation.object.id
      )
  );

  // if one of delete objects is in a combination delete the combination
  const newCombinations = currentObjects.combinations.filter((combination) => {
    const idsToDelete = objectsToDelete.map(
      (objectToDelete) => objectToDelete.object.id
    );
    return combination.every((id) => !idsToDelete.includes(id));
  });

  return {
    objectInformation: newObjectInformation,
    combinations: newCombinations,
  };
};

const ropeLengthSubText = (combination: RopeSystemCombination) =>
  combination.primarySystem.object.type.system?.ropeLength
    ? `${combination.primarySystem.object.type.system.ropeLength} m Seil`
    : '';
const ropeSystemAnchorsSubText = (combination: RopeSystemCombination) =>
  combination.primarySystem.object.type.system?.systemAnchors
    ? `${combination.primarySystem.object.type.system.systemAnchors} Stützen`
    : '';

const ropeSingleAnchorsSubText = (combination: RopeSystemCombination) =>
  combination.primarySystem.object.type.system?.singleAnchors
    ? `${combination.primarySystem.object.type.system.singleAnchors} EAP`
    : '';

const pushLockISystemSubText = (combination: PushLockCombination) =>
  combination.primarySystem.object.type.system?.anchors
    ? `${combination.primarySystem.object.type.system.anchors}x Lock I`
    : '';

const pushLockIISystemSubText = (combination: PushLockCombination) =>
  combination.primarySystem.object.type.system?.sleeves
    ? `${combination.primarySystem.object.type.system.sleeves}x Lock II`
    : '';

const singleAnchorSystemSubText = (combination: SingleAnchorCombination) =>
  combination.primarySystem.object.type.system
    ? `${combination.primarySystem.object.type.system.singleAnchors} Stück`
    : '';

const personalProtectionSubText = (
  combination: PersonalProtectionEquipmentCombination
) =>
  combination.primarySystem.object.type.quantity
    ? `${combination.primarySystem.object.type.quantity} Stück`
    : '';

const railingSubText = (combination: RailingCombination) =>
  combination.primarySystem.object.type.length
    ? `${combination.primarySystem.object.type.length} m`
    : '';

const stairLadderSubText = (combination: StairLadderCombination) =>
  combination.primarySystem.object.type.parts.length
    ? `${
        combination.primarySystem.object.type.parts.length
      }x Treppenleiter ${toStepsLabel(combination.primarySystem.object.type)}`
    : '';

const overpassSubText = (combination: OverpassCombination) =>
  combination.primarySystem.object.type.parts.length
    ? `${
        combination.primarySystem.object.type.parts.length
      }x Überstieg ${toStepsLabel(combination.primarySystem.object.type)}`
    : '';

const groundStairsSubText = (combination: GroundStairsCombination) =>
  combination.primarySystem.object.type.amount
    ? `${combination.primarySystem.object.type.amount} Stück`
    : '';

const railSystemRailLengthSubText = (combination: RailSystemCombination) =>
  combination.primarySystem.object.type.length
    ? `${combination.primarySystem.object.type.length} m`
    : '';

const railSystemBracketsSubText = (combination: RailSystemCombination) =>
  combination.primarySystem.object.type.brackets
    ? `${combination.primarySystem.object.type.brackets}x Halter`
    : '';

const climbingProtectionLengthSubText = (
  combination: ClimbingProtectionCombination
) =>
  combination.primarySystem.object.type.length
    ? `${combination.primarySystem.object.type.length} m${
        combination?.ladder ? ' inkl. Steigleiter' : ''
      }`
    : '';

const gliderSubText = (
  combination:
    | ClimbingProtectionCombination
    | RopeSystemCombination
    | RailSystemCombination
) =>
  combination.glider && combination.glider.object.type.systemType
    ? `${combination.glider.object.type.quantity} ${toPPECategoryLabel(
        combination.glider.object.type.systemType
      )}`
    : '';

const ladderSubText = (
  combination: LadderCombination | ClimbingProtectionCombination
) => {
  const ladder = isLadderCombination(combination)
    ? combination.primarySystem
    : combination.ladder;

  return ladder && ladder.object.type.parts.length
    ? `${ladder.object.type.parts.length}x Leiter ${toLadderCategoryLabel(
        ladder.object.type,
        true
      )}`
    : '';
};
const ladderPlatformsSubText = (
  combination: LadderCombination | ClimbingProtectionCombination
) => {
  const ladder = isLadderCombination(combination)
    ? combination.primarySystem
    : combination.ladder;

  return ladder && ladder.object.type.numberOfPlatforms
    ? `${ladder.object.type.numberOfPlatforms}x Podeste`
    : '';
};

export const getObjectSummarySubText = (
  combination: PriceInformationCombination
): string | undefined => {
  if (isRopeSystemCombination(combination)) {
    return [
      ropeLengthSubText(combination),
      ropeSystemAnchorsSubText(combination),
      ropeSingleAnchorsSubText(combination),
      gliderSubText(combination),
    ]
      .filter(notEmptyString)
      .join(', ');
  }
  if (isPushLockCombination(combination)) {
    return [
      pushLockISystemSubText(combination),
      pushLockIISystemSubText(combination),
    ]
      .filter(notEmptyString)
      .join(', ');
  }
  if (isSingleAnchorCombination(combination)) {
    return [singleAnchorSystemSubText(combination)]
      .filter(notEmptyString)
      .join(', ');
  }
  if (isPersonalProtectionEquipmenCombination(combination)) {
    return [personalProtectionSubText(combination)]
      .filter(notEmptyString)
      .join(', ');
  }
  if (isLadderCombination(combination)) {
    return [ladderSubText(combination), ladderPlatformsSubText(combination)]
      .filter(notEmptyString)
      .join(', ');
  }
  if (isRailingCombination(combination)) {
    return [railingSubText(combination)].filter(notEmptyString).join(', ');
  }
  if (isStairLadderCombination(combination)) {
    return [stairLadderSubText(combination)].filter(notEmptyString).join(', ');
  }
  if (isOverpassCombination(combination)) {
    return [overpassSubText(combination)].filter(notEmptyString).join(', ');
  }
  if (isGroundStairsCombination(combination)) {
    return [groundStairsSubText(combination)].filter(notEmptyString).join(', ');
  }
  if (isRailSystemCombination(combination)) {
    return [
      railSystemRailLengthSubText(combination),
      railSystemBracketsSubText(combination),
      gliderSubText(combination),
    ]
      .filter(notEmptyString)
      .join(', ');
  }
  if (isClimbingProtectionCombination(combination)) {
    return [
      climbingProtectionLengthSubText(combination),
      gliderSubText(combination),
      ladderPlatformsSubText(combination),
    ]
      .filter(notEmptyString)
      .join(', ');
  }
  return undefined;
};
