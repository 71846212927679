import { useFormikContext } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';

import { StepMenuButton, WizardMenu } from '../..';
import { wizardStep1ValidationSchema } from '../../../constants/MaintenanceYupSchema';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';

interface MaintenanceWizardMenuProps {
  activeStep: number;
  setActiveStep: (value: number | ((prevValue: number) => number)) => void;
}

const MaintenanceWizardMenu: React.FC<
  React.PropsWithChildren<MaintenanceWizardMenuProps>
> = ({ activeStep, setActiveStep }) => {
  const { values, dirty } = useFormikContext<FormikMaintenanceWizardValues>();
  const { status } = useFormikContext();

  const { maintenanceObjectId } = useParams();
  return (
    <WizardMenu>
      <StepMenuButton
        step={1}
        title="Bedarf erfassen"
        text="Ort, Wartungstyp, Häufigkeit, etc."
        handleMenuNavigation={() => setActiveStep(1)}
        isStepVisible
        isStepEnabled={activeStep >= 1}
        isStepActive={activeStep === 1}
      />
      <StepMenuButton
        step={2}
        title="Bestandsaufnahme"
        text="Bestandteile"
        handleMenuNavigation={() => setActiveStep(2)}
        isStepVisible
        isStepEnabled={
          wizardStep1ValidationSchema.isValidSync(values) &&
          (dirty || maintenanceObjectId !== undefined)
        }
        isStepActive={activeStep === 2}
        isInvalid={status && Object.keys(status).length > 0}
      />
    </WizardMenu>
  );
};

export default MaintenanceWizardMenu;
