import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceOrder } from '../../maintenance-offer';

export class MustNotHaveOrderConfirmationDocumentAttached<
  T extends MaintenanceOrder | null | undefined
> extends DomainRule<T, T & { confirmationDocumentName?: string }> {
  constructor() {
    super();
  }

  protected async rule(entity: T) {
    if (entity?.confirmationDocumentName) {
      throw new DomainRuleNotSatisfiedError(
        MustNotHaveOrderConfirmationDocumentAttached
      );
    }
    return entity as T & { confirmationDocumentName?: string };
  }
}
