import React from 'react';

import { GroundStairsDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface OverpassPropertiesProps {
  stairs: GroundStairsDto;
  hasSubprices: boolean;
}

const GroundStairsProperties: React.FC<OverpassPropertiesProps> = ({
  stairs,
  hasSubprices,
}) => (
  <MaintenanceObjectSummaryRow
    label="Bodentreppe"
    value={`${stairs.amount} Stück`}
    hasSubprices={hasSubprices}
  />
);

export default GroundStairsProperties;
