import React from 'react';

import Button from './Buttons/Button';

type UpdateFooterProps = {
  dirty: boolean;
  isValid: boolean;
  cancel: () => void;
  confirm: () => void;
  confirmLabel: string;
  cancelLabel: string;
};

const UpdateFooter: React.FC<React.PropsWithChildren<UpdateFooterProps>> = ({
  dirty = false,
  isValid = false,
  cancel,
  confirm,
  confirmLabel,
  cancelLabel,
  children,
}) => (
  <footer className="flex flex-1 justify-end px-4 py-2 bg-gray-lighter items-center h-full">
    {children}
    {dirty && (
      <>
        <div className="mr-4">
          <Button
            className="primary-button small-button"
            onClick={() => {
              cancel();
            }}
          >
            {cancelLabel}
          </Button>
        </div>
        <Button
          className="primary-button small-button"
          disabled={!isValid}
          onClick={() => {
            confirm();
          }}
        >
          {confirmLabel}
        </Button>
      </>
    )}
  </footer>
);

export default UpdateFooter;
