import { roundUpToFifteenMinutesInFuture } from '@warthungshelden/shared-functions';
import {
  addHours,
  addMinutes,
  differenceInHours,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import React from 'react';

import {
  DistanceDto,
  InstallerCandidateDto,
  InstallerDto,
  RouteDto,
} from '@wartungshelden/shared-types';

import Home from './Home';
import Stop from './Stop';

interface RouteMapProps {
  mainInstallerCandidate: InstallerCandidateDto;
  mainInstaller: InstallerDto;
  route: RouteDto;
  showDetails: boolean;
}

const Distance: React.FC<{ distance: DistanceDto }> = ({ distance }) => {
  if (distance) {
    return (
      <tr>
        <td className="w-12" />
        <td colSpan={2}>
          <div className="py-1 italic">
            {`${distance?.minutes?.toFixed(
              0
            )} min (${distance?.kilometers?.toFixed(1)} km)`}
            <div className="w-6 flex justify-center">
              <div className="h-[2rem] w-1 bg-blue-abs" />
            </div>
          </div>
        </td>
      </tr>
    );
  }
  return null;
};

const RouteMap: React.FC<RouteMapProps> = ({
  route,
  showDetails,
  mainInstallerCandidate,
  mainInstaller,
}) => {
  const appointments = mainInstallerCandidate?.appointments;

  let currentStartTime = appointments?.length
    ? roundUpToFifteenMinutesInFuture(
        addMinutes(
          appointments[appointments.length - 1]?.endDate,
          (mainInstallerCandidate.distanceToFirstJob.minutes ?? 0) * 1.2
        )
      )
    : setSeconds(setMinutes(setHours(new Date(), 8), 0), 0);

  let currentEndTime = roundUpToFifteenMinutesInFuture(
    addHours(currentStartTime, route.jobs[0].duration)
  );

  return (
    <div className="flex flex-col">
      <table>
        <tbody>
          <Home
            isFirst
            isTransparent={!!appointments?.length}
            label={`${mainInstaller.address.zip} ${mainInstaller.address.city}`}
          />

          {appointments?.map((appointment) => {
            return (
              <Stop
                key={appointment.id}
                isTransparent
                job={{
                  orderId: '',
                  city: '',
                  zipCode: appointment?.place || '',
                  duration: differenceInHours(
                    appointment.endDate,
                    appointment.startDate
                  ),
                }}
                interval={{
                  from: appointment.startDate,
                  to: appointment.endDate,
                }}
                showDetails={showDetails}
              />
            );
          })}
          <Distance distance={mainInstallerCandidate.distanceToFirstJob} />

          {route.jobs.map((job, index) => {
            const distance = route.jobDistances[index + 1];

            if (index > 0) {
              currentStartTime = roundUpToFifteenMinutesInFuture(
                addMinutes(
                  currentEndTime,
                  (route.jobDistances[index].minutes ?? 0) * 1.2
                )
              );
              currentEndTime = roundUpToFifteenMinutesInFuture(
                addHours(currentStartTime, job.duration)
              );
            }

            return [
              <Stop
                key={job.orderId}
                job={job}
                showDetails={showDetails}
                interval={{
                  from: currentStartTime,
                  to: currentEndTime,
                }}
              />,
              <Distance key={`${job.orderId}distance`} distance={distance} />,
            ];
          })}
          <Distance distance={mainInstallerCandidate.distanceFromLastJob} />
          <Home
            isFirst={false}
            label={`${mainInstaller.address.zip} ${mainInstaller.address.city}`}
          />
        </tbody>
      </table>
    </div>
  );
};

export default RouteMap;
