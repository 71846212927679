import React from 'react';

interface PrimaryButtonProps {
  label: string;
  icon: React.ReactNode;
  provider: string;
  isEnabled?: boolean;
  onClick: () => void;
}

const AuthProviderButton: React.FC<
  React.PropsWithChildren<PrimaryButtonProps>
> = ({ label, icon, isEnabled, onClick, provider }) => (
  <button
    data-authprovider={provider}
    type="button"
    disabled={!isEnabled}
    className={`transition duration-200 border border-gray-200 text-gray-500 w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-normal text-center inline-block ${
      !isEnabled && 'opacity-50'
    }`}
    onClick={() => isEnabled && onClick()}
  >
    <div className="flex px-4 items-center justify-center text-xl">
      {icon}
      <div className="ml-2 text-base">{label}</div>
    </div>
  </button>
);

AuthProviderButton.defaultProps = {
  isEnabled: true,
};

export default AuthProviderButton;
