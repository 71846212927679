import {
  MaintenanceOfferStatus as DomainMaintenanceOfferStatus,
  MaintenanceOfferStatusEnum as DomainMaintenanceOfferStatusEnum,
  MaintenanceOrderStatus as DomainMaintenanceOrderStatus,
  MaintenanceOrderStatusEnum as DomainMaintenanceOrderStatusEnum,
} from '@warthungshelden/domain/maintenance-exchange';

import { MaintenanceObjectDto } from './maintenance-object';
import {
  CustomerMaintenancePriceDto,
  MaintenancePriceDto,
} from './maintenance-price';

export type UpdateMaintenanceOfferDto = Pick<MaintenanceOfferDto, 'id'> &
  Partial<Omit<MaintenanceOfferDto, 'price'>> & {
    decision?: Partial<MaintenanceOfferDecisionDto>;
    price?: Pick<MaintenancePriceDto, 'netTotal' | 'priceAdaptation'>;
  };

export type DurationInMinutes = {
  duration: number;
  roofOpening: number;
};

export interface MaintenanceOfferDto {
  readonly id: string;
  readonly requestId: string;
  readonly ownerId: string;
  readonly created: Date;
  readonly suggestedDate?: Date | null;
  readonly documentName?: string | null;
  readonly customerReference?: string | null;
  readonly validUntil?: Date | null;
  readonly durationInMinutes?: DurationInMinutes | null;
  readonly maintenanceObjects: OfferedMaintenanceObjectDto[];
  readonly order?: MaintenanceOrderDto | null;
  readonly price?: MaintenancePriceDto | null;
  readonly decision: MaintenanceOfferDecisionDto;
  readonly objectsWithOpenDocumentUpload: string[];
  readonly address: MaintenanceOfferAddressDto;
  readonly absCustomerNumber?: string | null;
}

export type CustomerMaintenanceOfferDto = Omit<MaintenanceOfferDto, 'price'> & {
  readonly price?: CustomerMaintenancePriceDto;
};

export type CustomerOrMaintenanceTeamOfferDto =
  | MaintenanceOfferDto
  | CustomerMaintenanceOfferDto;

export interface MaintenanceOfferAddressDto {
  readonly addressLine1: string;
  readonly postalCode: string;
  readonly city: string;
  readonly countryISO: string;
  readonly company: string;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly email?: string | null;
  readonly addressLine2?: string | null;
}

export interface MaintenanceOrderAddressDto {
  readonly addressLine1: string;
  readonly postalCode: string;
  readonly city: string;
  readonly countryISO: string;
  readonly company: string;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly addressLine2?: string | null;
}

export interface MaintenanceOrderDto {
  readonly id: string;
  readonly status: MaintenanceOrderStatusDto;
  readonly orderDate: Date;
  readonly executionDate?: Date | null;
  readonly durationInMinutes?: DurationInMinutes | null;
  readonly confirmationDocumentName?: string | null;
  readonly address: MaintenanceOrderAddressDto;
}

export interface MaintenanceOfferDecisionDto {
  readonly status: MaintenanceOfferStatusDto;
  readonly date: Date;
}

export interface MaintenanceOfferSetDecisionDto {
  readonly status:
    | MaintenanceOfferUserDecision
    | MaintenanceOfferMaintenanceTeamDecision;
  readonly address: MaintenanceOrderAddressDto;
  readonly reasons?: string[];
}

export type OfferedMaintenanceObjectDto = Omit<
  MaintenanceObjectDto,
  'id' | 'currentRequestId' | 'orderIds'
> & {
  originalId: string;
};

export interface PastAuditMeasureOptionsDto {
  hasTensileTest: boolean;
  hasRoofOpening: boolean;
}

export interface MaintenanceOrderDocumentDataPerBuildingDto {
  buildingId: string;
  serials?: string;
  addressNotes?: string;
  workNotes?: string;
  isInstalledByABS?: boolean;
  hasLockBockDocumentation?: boolean;
  pastAuditMeasures?: PastAuditMeasureOptionsDto;
}

export interface MaintenanceOrderDocumentDataDto {
  installer?: string;
  orderNumber?: string;
  contractNumber?: string;
  project?: string;
  buildings: Array<MaintenanceOrderDocumentDataPerBuildingDto>;
}

export type MaintenanceOfferMaintenanceTeamDecision =
  DomainMaintenanceOfferStatusEnum.SENT;

export type MaintenanceOfferUserDecision =
  | DomainMaintenanceOfferStatusEnum.ACCEPTED
  | DomainMaintenanceOfferStatusEnum.DECLINED;

export type MaintenanceOfferStatusDto = DomainMaintenanceOfferStatus;
export const MaintenanceOfferStatusDtoEnum = DomainMaintenanceOfferStatusEnum;

export type MaintenanceOrderStatusDto = DomainMaintenanceOrderStatus;
export const MaintenanceOrderStatusDtoEnum = DomainMaintenanceOrderStatusEnum;
