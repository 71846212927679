import React, { FC, createContext, useMemo, useState } from 'react';

interface ContextInterface {
  highlights: string[];
  addHighlight: (error: string) => void;
  clearHighlight: (error: string) => void;
}

const contextDefaultValues: ContextInterface = {
  highlights: [],
  addHighlight: (highlight: string) => {},
  clearHighlight: (highlight: string) => {},
};

export const OFFER_NOT_UPLOADED_ERROR = 'OfferNotUploaded:';
export const ORDER_CONFIRMATION_NOT_UPLOADED_ERROR =
  'OrderConfirmationNotUploaded:';
export const DOCUMENTATION_NOT_VALIDATED_ERROR = 'documentationNotValidated:';
export const MAINTENANCE_OBJECT_DOCUMENTATION_NOT_VALIDATED_ERROR =
  'maintenanceObjectDocumentationNotValidated:';
export const MAINTENANCE_OBJECT_IS_REOPENED = 'maintenanceObjectIsReopened:';

export const HintHighlightContext =
  createContext<ContextInterface>(contextDefaultValues);

export const HintHighlightProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [highlights, setHighlights] = useState<string[]>([]);

  const addHighlight = (highlight: string) => {
    setHighlights((prevState) => {
      return [
        ...prevState.filter((prevHighlight) => prevHighlight !== highlight),
        highlight,
      ];
    });
  };

  const clearHighlight = (error: string) => {
    setHighlights((prevState) => [...prevState.filter((err) => err !== error)]);
  };

  const value = useMemo<ContextInterface>(
    () => ({
      highlights,
      addHighlight,
      clearHighlight,
    }),
    [highlights]
  );
  return (
    <HintHighlightContext.Provider value={value}>
      {children}
    </HintHighlightContext.Provider>
  );
};
