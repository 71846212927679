import { Entity } from '@warthungshelden/domain/common';

export class MaintenanceObjectReminder extends Entity {
  public readonly id: string;
  public readonly ownerId: string;
  public readonly lastUpcomingReminder?: Date;
  public readonly lastOverdueReminder?: Date;
  public readonly dueDate: Date;
  public readonly objectIds: string[];

  constructor(config: {
    readonly id: string;
    readonly ownerId: string;
    readonly lastUpcomingReminder?: Date;
    readonly lastOverdueReminder?: Date;
    readonly dueDate: Date;
    readonly objectIds: string[];
  }) {
    super();
    this.id = config.id;
    this.ownerId = config.ownerId;
    this.lastUpcomingReminder = config.lastUpcomingReminder;
    this.lastOverdueReminder = config.lastOverdueReminder;
    this.dueDate = config.dueDate;
    this.objectIds = config.objectIds;
  }
}
