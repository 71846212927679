export class DomainError extends Error {
  public override name = this.constructor.name;

  constructor(message?: string) {
    super(message);
  }
}

export class NotFoundError extends DomainError {
  constructor() {
    super(`The requested entity does not exist`);
  }
}

export class MaintenanceSystemNotFoundError extends DomainError {
  constructor() {
    super();
  }
}

export class MaintenanceTypeNotFoundError extends DomainError {
  constructor() {
    super();
  }
}

export class MustHaveRoleError extends DomainError {
  constructor(roles: string[]) {
    super(
      `This can only be done by a user having at least one of the following roles: ${roles.join(
        ', '
      )}`
    );
  }
}

export class DomainRuleNotSatisfiedError<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Function
> extends DomainError {
  constructor(type: T) {
    super(`Rule ${type.name} is not satisfied.`);
  }
}

export class AggregateDomainRuleNotSatisfiedError extends DomainError {
  public override name = this.constructor.name;

  constructor(public readonly errors: any[]) {
    super(`Rules not satisfied.`);
  }
}
