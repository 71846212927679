import { Entity } from '@warthungshelden/domain/common';

import { CalendarAppointment } from '../../calendar';
import { OrderContactPreference, OrderStatus } from '../../maintenance-order';
import { Distance, GeoCoordinate } from '../geo';

export class Job extends Entity {
  public readonly zipCode: string;
  public readonly coordinates: GeoCoordinate;
  public readonly duration: number;
  public readonly orderId: string;
  public readonly status: OrderStatus;
  public readonly contactPreference?: OrderContactPreference;

  constructor(config: {
    readonly zipCode: string;
    readonly coordinates: GeoCoordinate;
    readonly duration: number;
    readonly orderId: string;
    readonly status: OrderStatus;
    readonly contactPreference?: OrderContactPreference;
  }) {
    super();
    this.zipCode = config.zipCode;
    this.coordinates = config.coordinates;
    this.duration = config.duration;
    this.orderId = config.orderId;
    this.status = config.status;
    this.contactPreference = config.contactPreference;
  }
}

export class InstallerCandidate extends Entity {
  public readonly installerId: string;
  public readonly distanceToFirstJob: Distance;
  public readonly distanceFromLastJob: Distance;
  public readonly appointments: CalendarAppointment[];

  constructor(config: {
    readonly installerId: string;
    readonly distanceToFirstJob: Distance;
    readonly distanceFromLastJob: Distance;
    readonly appointments: CalendarAppointment[];
  }) {
    super();
    this.installerId = config.installerId;
    this.distanceToFirstJob = config.distanceToFirstJob;
    this.distanceFromLastJob = config.distanceFromLastJob;
    this.appointments = config.appointments;
  }
}

export class Route extends Entity {
  public readonly id: string;
  public readonly date: Date;
  public readonly totalJobDistance: Distance;
  public readonly jobDistances: Distance[];
  public readonly duration: number;
  public readonly jobs: Job[];
  public readonly installerCandidates: InstallerCandidate[];

  constructor(config: {
    readonly id: string;
    readonly date: Date;
    readonly totalJobDistance: Distance;
    readonly jobDistances: Distance[];
    readonly duration: number;
    readonly jobs: Job[];
    readonly installerCandidates: InstallerCandidate[];
  }) {
    super();
    this.id = config.id;
    this.date = config.date;
    this.totalJobDistance = config.totalJobDistance;
    this.jobDistances = config.jobDistances;
    this.duration = config.duration;
    this.jobs = config.jobs;
    this.installerCandidates = config.installerCandidates;
  }
}
