import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  ClimbingProtectionDto,
  PriceInformationObjectDto,
} from '@wartungshelden/shared-types';

import { UnitInputField } from '../../../../../components';
import RadioButton from '../../../../../components/Basics/Inputs/RadioButton';
import Toggle from '../../../../../components/Basics/Inputs/Toggle';
import { isClimbingProtectionDto } from '../../../../../guards/isMaintenanceType';
import ManufacturerSelector from './ManufacturerSelector';

type ClimbingProtectionFormikState = PriceInformationObjectDto & {
  shouldAddLaddersToClimbingProtection?: boolean;
  ladderId?: string;
};

interface ClimbingProtectionSelectorProps {
  isUpdating: boolean;
}

const ClimbingProtectionSelector: React.FC<ClimbingProtectionSelectorProps> = ({
  isUpdating,
}) => {
  const { values, setFieldValue, isValid, errors } =
    useFormikContext<ClimbingProtectionFormikState>();

  const [shouldShowError, setShouldShowError] = useState(false);

  useEffect(() => {
    if (
      !isValid &&
      errors?.object?.type &&
      'length' in errors.object.type &&
      errors.object.type.length !==
        'object.type.length must be greater than or equal to 1'
    ) {
      setShouldShowError(true);
    } else {
      setShouldShowError(false);
    }
  }, [errors, isValid]);

  const { type } = values.object;
  const { ladderId } = values;

  useEffect(() => {
    if (isUpdating) {
      setFieldValue(
        'shouldAddLaddersToClimbingProtection',
        isClimbingProtectionDto(type) && Boolean(ladderId)
      );
    }
  }, []);

  return (
    <>
      <ManufacturerSelector />

      <div className="font-bold px-8">Bestandteile*</div>
      <div className="px-8">
        <UnitInputField
          name="object.type.length"
          label="Länge"
          unit="m"
          isLabelBold={false}
          shouldSelectOnFocus
          labelWidthTailwindClass="w-80"
        />
        {shouldShowError && (
          <div className="text-red">
            Für Steigschutz über 30m bietet das System keinen Preis an.
          </div>
        )}

        <div className="flex flex-row items-start mb-4">
          <div className="w-80 mr-4">Steigleiter</div>
          <div className="flex flex-1 flex-col space-y-2">
            <RadioButton
              value
              label="Ja, passende Steigleiter einkalkulieren"
              name="addLadder"
              setState={() => {
                setFieldValue('object.type.ladderId', uuidv4());
                setFieldValue('amount', 1);
                setFieldValue('shouldAddLaddersToClimbingProtection', true);
              }}
              state={values?.shouldAddLaddersToClimbingProtection}
            />

            {values.shouldAddLaddersToClimbingProtection && (
              <div className="w-80">
                <UnitInputField
                  isLabelBold={false}
                  name="climbingProtectionPlatforms"
                  label="Podeste (gesamt)"
                  unit="Stück"
                  allowDecimals={false}
                  shouldSelectOnFocus
                />
              </div>
            )}

            <RadioButton
              value={false}
              label="Nein, keine Steigleiter nötig"
              name="dontAddLadder"
              setState={() => {
                setFieldValue('object.type.ladderId', undefined);
                setFieldValue('amount', 0);
                setFieldValue('shouldAddLaddersToClimbingProtection', false);
              }}
              state={
                values?.shouldAddLaddersToClimbingProtection !== undefined &&
                !values.shouldAddLaddersToClimbingProtection
              }
            />
          </div>
        </div>
        <UnitInputField
          name="climbingProtectionGliders"
          label="Steigschutzläufer"
          unit="Stück"
          isLabelBold={false}
          shouldSelectOnFocus
          labelWidthTailwindClass="w-80"
        />
      </div>

      <div className="font-bold px-8">Gebäude*</div>
      <div className="px-8">
        <Toggle
          label="Öffentliches Gebäude (Körperschaft öffentlichen Rechts), z.B.
                      Städte, Gemeinden, Kommunen, Wohnungsbaugesellschaften"
          name="building.isPublic"
          checked={!!values.building?.isPublic}
          onChange={() => {
            setFieldValue('building.isPublic', !values.building?.isPublic);
          }}
        />
      </div>
    </>
  );
};

export default ClimbingProtectionSelector;
