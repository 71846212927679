import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ObjectSchema } from 'yup';

import {
  CreateMaintenanceObjectDto,
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import LoadingSpinner from '../../../../components/Basics/Loaders/LoadingSpinner';
import MaintenanceWizardFooter from '../../../../components/Maintenance/MaintenanceWizard/MaintenanceWizardFooter';
import MaintenanceWizardHeader from '../../../../components/Maintenance/MaintenanceWizard/MaintenanceWizardHeader';
import MaintenanceWizardMenu from '../../../../components/Maintenance/MaintenanceWizard/MaintenanceWizardMenu';
import { isServiceAlreadySupported } from '../../../../constants/MaintenanceConstants';
import { routes } from '../../../../constants/routes';
import { isFormikFallProtectionMaintenanceObject } from '../../../../guards/isFormikMaintenanceObject';
import { useCreateMaintenanceObject } from '../../../../services/api/maintenance-objects/maintenance-objects-api';
import { resetFilters } from '../../../../services/filter';
import { wizardValuesToCreateMaintenanceObjectDto } from '../../../../services/maintenanceService';
import { monthToDate } from '../../../../services/timeDateService';
import { labelToProvidedService } from '../MaintenanceObject';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import { FormikFallProtection } from './types/FormikFallProtection';
import { FormikLadder } from './types/FormikLadder';
import { FormikMaintenanceWizardValues } from './types/FormikMaintenanceWizardValues';
import { FormikPPE } from './types/FormikPPE';

interface MaintenanceWizardCreatePageProps {
  activeStep: number;
  setActiveStep: (state: number | ((state: number) => number)) => void;
  validationSchema: ObjectSchema<any>;
  isLoading: boolean;
  setDocumentationsFor: (
    existingMaintenanceDocumentations: MaintenanceDocumentationDto[],
    objects: {
      createdObject?: MaintenanceObjectDto;
      selectedFiles: Array<File | MaintenanceDocumentationDto>;
    }[]
  ) => Promise<void>;
}

const MaintenanceWizardCreatePage: React.FC<
  React.PropsWithChildren<MaintenanceWizardCreatePageProps>
> = ({
  activeStep,
  setActiveStep,
  validationSchema,
  setDocumentationsFor,
  isLoading,
}) => {
  const { mutateAsync: createMaintenanceObject, isLoading: isSaving } =
    useCreateMaintenanceObject();
  const navigate = useNavigate();

  const submitFormikForm = async (values: FormikMaintenanceWizardValues) => {
    resetFilters();
    if (
      values.type &&
      isServiceAlreadySupported(labelToProvidedService(values.type))
    ) {
      const maintenanceResponses = await Promise.all(
        values.maintenanceObjects.map(
          async (
            formikMaintenanceObject:
              | FormikPPE
              | FormikFallProtection
              | FormikLadder
          ) => {
            const createMaintenanceObjectDto: CreateMaintenanceObjectDto =
              wizardValuesToCreateMaintenanceObjectDto(
                formikMaintenanceObject,
                values
              );

            const response = await createMaintenanceObject(
              createMaintenanceObjectDto
            );

            return {
              createdObject: response?.data,
              selectedFiles: isFormikFallProtectionMaintenanceObject(
                formikMaintenanceObject
              )
                ? formikMaintenanceObject.selectedFiles
                : [],
            };
          }
        )
      );
      if (
        isFormikFallProtectionMaintenanceObject(values.maintenanceObjects[0])
      ) {
        await setDocumentationsFor(
          values.uploadedMaintenanceDocumentations,
          maintenanceResponses
        );
      }

      navigate(`/${routes.maintenance}`);
    } else {
      await createMaintenanceObject({
        name: values.name, // We will not use the name for these currently
        description: values.description,
        buildingId: values.buildingId,
        dueDate: values.dueDate ? monthToDate(values.dueDate) : undefined,
        frequency: values.frequency === '' ? undefined : values.frequency,
        type: {
          name: labelToProvidedService(values.type || ''),
          type: 'other_type',
        },
      });
      navigate(`/${routes.maintenance}`);
    }
  };

  const initialValues: FormikMaintenanceWizardValues = {
    name: '',
    maintenanceObjects: [],
    uploadedMaintenanceDocumentations: [],
    frequency: '',
    type: '',
  };

  return (
    <Formik
      onSubmit={(values) => {
        submitFormikForm(values);
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {() => {
        return (
          <>
            <div className="h-screen flex flex-1 flex-col overflow-hidden items-stretch">
              <MaintenanceWizardHeader />
              <div className="flex flex-1 bg-white overflow-hidden">
                <MaintenanceWizardMenu
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
                <div className="flex flex-1 flex-col">
                  {activeStep === 1 && (
                    <StepOne isMaintenanceReadOnly={false} isCreate />
                  )}
                  {activeStep === 2 && (
                    <StepTwo
                      isMaintenanceReadOnly={false}
                      isUpdateForm={false}
                    />
                  )}
                </div>
              </div>
              <MaintenanceWizardFooter
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </div>
            <LoadingSpinner isLoading={isLoading || isSaving}>
              Upload läuft. Bitte warten.
            </LoadingSpinner>
          </>
        );
      }}
    </Formik>
  );
};

export default MaintenanceWizardCreatePage;
