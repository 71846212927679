import { Entity } from '@warthungshelden/domain/common';

import { DurationInMinutes } from './maintenance-offer';
import { MaintenanceOrderAddress } from './maintenance-order-address';

export enum MaintenanceOrderStatusEnum {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  CLOSED = 'CLOSED',
}

export type MaintenanceOrderStatus = `${MaintenanceOrderStatusEnum}`;

export class MaintenanceOrder extends Entity {
  public readonly id: string;
  public readonly status: MaintenanceOrderStatus;
  public readonly orderDate: Date;
  public readonly address: MaintenanceOrderAddress;
  public readonly durationInMinutes: DurationInMinutes;
  public readonly executionDate?: Date | null;
  public readonly confirmationDocumentName?: string | null;

  constructor(config: {
    id: string;
    status: MaintenanceOrderStatus;
    orderDate: Date;
    address: MaintenanceOrderAddress;
    durationInMinutes: DurationInMinutes;
    executionDate?: Date | null;
    confirmationDocumentName?: string | null;
  }) {
    super();
    this.id = config.id;
    this.status = config.status;
    this.orderDate = config.orderDate;
    this.address = config.address;
    this.durationInMinutes = config.durationInMinutes;
    this.executionDate = config.executionDate;
    this.confirmationDocumentName = config.confirmationDocumentName;
  }
}
