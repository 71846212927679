import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingSpinner from '../components/Basics/Loaders/LoadingSpinner';
import { adminRoutePrefix, routes } from '../constants/routes';
import { SessionContext } from '../contexts/SessionContext';

const DefaultRoute: React.FC<React.PropsWithChildren> = () => {
  const { pathname } = useLocation();

  const {
    isMaintenanceTeamMemberAdmin,
    isMaintenanceTeamMemberUser,
    isSignedIn,
    isLoading,
  } = useContext(SessionContext);

  const requestsAdminPage = pathname.startsWith('/admin');

  const getDefaultRoute = () => {
    if (!isSignedIn && requestsAdminPage) {
      return `${adminRoutePrefix}/login`;
    }

    if (isMaintenanceTeamMemberUser) {
      return `${adminRoutePrefix}/${routes.price}`;
    }

    if (isMaintenanceTeamMemberAdmin) {
      return `${adminRoutePrefix}/${routes.requests}`;
    }

    return `/${routes.dashboard}`;
  };

  if (isLoading) {
    return <LoadingSpinner isLoading />;
  }

  return <Navigate to={getDefaultRoute()} replace state={{ from: pathname }} />;
};

export default DefaultRoute;
