import React from 'react';

type InputFieldProps = {
  title: string;
};

const RadioButtonGroup: React.FC<React.PropsWithChildren<InputFieldProps>> = ({
  title,
  children,
}) => (
  <div role="group">
    <div className="font-bold mb-2 mt-4 text-2xl">{title}</div>
    {children}
  </div>
);
export default RadioButtonGroup;
