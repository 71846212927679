import React from 'react';

import Button from './Buttons/Button';

type ConfirmationProps = {
  question: string;
  confirm: () => void;
  confirmLabel: string;
  cancel?: () => void;
};

const Confirmation: React.FC<React.PropsWithChildren<ConfirmationProps>> = ({
  question,
  confirm,
  cancel,
  confirmLabel,
}) => (
  <div className="flex flex-col pt-2">
    <div className="self-center">{question}</div>
    <div className="flex justify-center pt-4 space-x-2">
      {cancel && (
        <Button
          className="primary-button small-button"
          onClick={cancel}
          label="Abbrechen"
        />
      )}
      <Button
        onClick={confirm}
        className={`${
          cancel !== undefined ? 'alert-button' : 'primary-button'
        } small-button`}
        label={confirmLabel}
      />
    </div>
  </div>
);

Confirmation.defaultProps = {
  cancel: undefined,
};

export default Confirmation;
