import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { MaintenanceDocumentation } from '../../maintenance-documentation';

export class CanNotSetValidityForUndocumentedObject extends DomainRule<
  MaintenanceDocumentation,
  MaintenanceDocumentation
> {
  constructor(
    private newMaintenanceDocumentation: Pick<MaintenanceDocumentation, 'id'> &
      Partial<MaintenanceDocumentation>
  ) {
    super();
  }

  protected async rule(documentation: MaintenanceDocumentation) {
    const objectIdsToCheck =
      this.newMaintenanceDocumentation?.maintenanceObjectIds ??
      documentation.maintenanceObjectIds;

    const validForToCheck =
      this.newMaintenanceDocumentation.validFor ?? documentation.validFor;

    const invalidForToCheck =
      this.newMaintenanceDocumentation.invalidFor ?? documentation.invalidFor;

    if (
      !validForToCheck.every((id) => objectIdsToCheck.includes(id)) ||
      !invalidForToCheck.every((id) => objectIdsToCheck.includes(id))
    ) {
      throw new DomainRuleNotSatisfiedError(
        CanNotSetValidityForUndocumentedObject
      );
    }

    return documentation;
  }
}
