import React from 'react';

import RadioButtonField from '../../../../../components/Basics/Inputs/RadioButtonField';
import { MANUFACTURERS } from '../../../../../constants/MaintenanceConstants';

interface ManufacturerSelectorProps {
  disableForeignManufacturer?: boolean;
}

const ManufacturerSelector: React.FC<ManufacturerSelectorProps> = ({
  disableForeignManufacturer,
}) => {
  return (
    <div className="flex flex-row items-start px-8">
      <div className="font-bold w-80 mr-4">Hersteller*</div>
      <div className="flex flex-1 flex-col space-y-2">
        <RadioButtonField
          label="ABS Safety"
          name="object.manufacturer"
          value={MANUFACTURERS[0]}
        />
        <RadioButtonField
          disabled={disableForeignManufacturer}
          label="Fremdhersteller"
          name="object.manufacturer"
          value={MANUFACTURERS[MANUFACTURERS.length - 1]}
        />
      </div>
    </div>
  );
};

ManufacturerSelector.defaultProps = {
  disableForeignManufacturer: false,
};

export default ManufacturerSelector;
