import {
  MaintenanceOfferStatusEnum as DomainMaintenanceOfferStatusEnum,
  MaintenanceOrderStatusEnum as DomainMaintenanceOrderStatusEnum,
  RequestStatusEnum as DomainRequestEnum,
  MaintenanceRequestStatus as DomainRequestStatus,
  MaintenanceOrderStatusEnum,
} from '@warthungshelden/domain/maintenance-exchange';

import {
  MaintenanceOfferAddressDto,
  MaintenanceOfferStatusDto,
  MaintenanceOfferStatusDtoEnum,
  MaintenanceOrderStatusDto,
} from './maintenance-offer';

export interface MaintenanceRequestDto {
  readonly id: string;
  readonly created: Date;
  readonly updated: Date;
  readonly ownerId: string;
  readonly status: MaintenanceRequestStatus;
  readonly asap: boolean;
  readonly dateRanges?: DateRangeDto[] | null;
  readonly additionalInfo?: string | null;
  readonly objectIds: string[];
  readonly maintenanceTeamInternalNote?: string | null;
}

export type CustomerMaintenanceRequestDto = Omit<
  MaintenanceRequestDto,
  'maintenanceTeamInternalNote'
>;

export type CustomerOrMaintenanceTeamRequestDto =
  | MaintenanceRequestDto
  | CustomerMaintenanceRequestDto;

export interface DateRangeDto {
  readonly from: Date;
  readonly to: Date;
}

export type CreateMaintenanceRequestDto = Omit<
  MaintenanceRequestDto,
  | 'id'
  | 'ownerId'
  | 'status'
  | 'created'
  | 'updated'
  | 'priceIndication'
  | 'offerIds'
> & {
  readonly address: MaintenanceOfferAddressDto;
  readonly absCustomerNumber?: string | null;
};

export type UpdateMaintenanceRequestDto = Pick<MaintenanceRequestDto, 'id'> &
  Partial<MaintenanceRequestDto>;

export const RequestStatusEnum = DomainRequestEnum;

export type MaintenanceRequestStatus = DomainRequestStatus;

export function requestStatusToLabelForMaintenanceTeam(
  status:
    | MaintenanceRequestStatus
    | MaintenanceOfferStatusDto
    | MaintenanceOrderStatusDto
) {
  switch (status) {
    case RequestStatusEnum.REQUESTED:
      return 'Anfrage liegt vor';
    case RequestStatusEnum.CANCELED:
      return 'Anfrage storniert';
    case RequestStatusEnum.VALIDATING:
    case DomainMaintenanceOfferStatusEnum.DRAFT:
      return 'In Prüfung';
    case DomainMaintenanceOfferStatusEnum.SENT:
      return 'Angebot versendet';
    case DomainMaintenanceOfferStatusEnum.DECLINED:
      return 'Angebot abgelehnt';
    case DomainMaintenanceOfferStatusEnum.ACCEPTED:
      return 'Angebot angenommen';
    case DomainMaintenanceOrderStatusEnum.UNCONFIRMED:
    case DomainMaintenanceOrderStatusEnum.CONFIRMED:
      return 'Beauftragt';
    case RequestStatusEnum.CLOSED:
    case DomainMaintenanceOrderStatusEnum.CLOSED:
      return 'Abgeschlossen';
    default:
      return status;
  }
}

export function requestStatusToLabelForUser(
  status:
    | MaintenanceRequestStatus
    | MaintenanceOfferStatusDto
    | MaintenanceOrderStatusDto
) {
  switch (status) {
    case RequestStatusEnum.REQUESTED:
      return 'Angefragt';
    case RequestStatusEnum.CANCELED:
      return 'Anfrage storniert';
    case RequestStatusEnum.VALIDATING:
    case DomainMaintenanceOfferStatusEnum.DRAFT:
      return 'In Prüfung';
    case DomainMaintenanceOfferStatusEnum.SENT:
      return 'Angebot liegt vor';
    case DomainMaintenanceOfferStatusEnum.DECLINED:
      return 'Angebot abgelehnt';
    case DomainMaintenanceOfferStatusEnum.ACCEPTED:
      return 'Angebot angenommen';
    case DomainMaintenanceOrderStatusEnum.UNCONFIRMED:
    case DomainMaintenanceOrderStatusEnum.CONFIRMED:
      return 'Beauftragt';
    case RequestStatusEnum.CLOSED:
    case DomainMaintenanceOrderStatusEnum.CLOSED:
      return 'Abgeschlossen';
    default:
      return status;
  }
}

export type ProcessStatus =
  | MaintenanceRequestStatus
  | MaintenanceOfferStatusDto
  | MaintenanceOrderStatusDto;

export const requestStatusToLabel = (
  isMaintenanceTeamMember: boolean,
  requestStatus: ProcessStatus,
  offerStatus?: MaintenanceOfferStatusDto
): string => {
  const status =
    !offerStatus || requestStatus === RequestStatusEnum.CANCELED
      ? requestStatus
      : offerStatus;

  if (isMaintenanceTeamMember) {
    return requestStatusToLabelForMaintenanceTeam(status);
  }
  return requestStatusToLabelForUser(status);
};

export const statusToUse = (
  requestStatus: MaintenanceRequestStatus,
  offerStatus?: MaintenanceOfferStatusDto,
  orderStatus?: MaintenanceOrderStatusDto
): ProcessStatus => {
  return !offerStatus || offerStatus === MaintenanceOfferStatusDtoEnum.DRAFT
    ? requestStatus
    : !orderStatus || orderStatus === MaintenanceOrderStatusEnum.UNCONFIRMED
    ? offerStatus
    : orderStatus;
};
