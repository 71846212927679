import { isPhoneNumber } from 'class-validator';
import React from 'react';
import * as Yup from 'yup';

import {
  BuildingDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { AddressFormikValues } from '../../../../components/Address/types/AddressFormikValues';
import MaintenanceRequestWizardFooter from '../../../../components/Maintenance/MaintenanceRequestWizard/MaintenanceRequestWizardFooter';
import MaintenanceRequestWizardMenu from '../../../../components/Maintenance/MaintenanceRequestWizard/MaintenanceRequestWizardMenu';
// eslint-disable-next-line import/no-cycle
import { StepState } from '../MaintenanceRequestWizardPage';
import ConfirmationStep from '../steps/ConfirmationStep';
import SelectAddressStep from '../steps/SelectAddressStep';
import SelectAppointmentStep, {
  StepThreeFormikValues,
} from '../steps/SelectAppointmentStep';
import SelectMaintenancesStep from '../steps/SelectMaintenancesStep';

interface MaintenanceRequestWizardFormProps {
  step: StepState;
  selectedMaintenances: MaintenanceObjectDto[];
  setSelectedMaintenances: (
    state:
      | MaintenanceObjectDto[]
      | ((prevState: MaintenanceObjectDto[]) => MaintenanceObjectDto[])
  ) => void;
  values: StepThreeFormikValues & AddressFormikValues;
  handleMenuNavigation: (chosenStep: number) => void;
  handleNextNavigation: () => void;
  buildings: BuildingDto[];
  maintenances: MaintenanceObjectDto[];
  isLoading: boolean;
  changeStep: (step: number) => void;
}

export const stepThreeRequestYupSchema = Yup.object().shape({
  preferablyInDueMonth: Yup.boolean().required(),
  dateRanges: Yup.array().when('preferablyInDueMonth', {
    is: false,
    then: Yup.array().min(1),
    otherwise: Yup.array().min(0),
  }),
});

export const addressYupSchema = Yup.object().shape({
  company: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  postalCode: Yup.number().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  addressLine: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().test({
    message: 'Rufnummer nicht gültig',
    test: (number) => typeof number === 'undefined' || isPhoneNumber(number),
  }),
});

export const MaintenanceRequestWizardForm: React.FC<
  React.PropsWithChildren<MaintenanceRequestWizardFormProps>
> = ({
  step,
  selectedMaintenances,
  values,
  handleMenuNavigation,
  handleNextNavigation,
  buildings,
  maintenances,
  setSelectedMaintenances,
  isLoading,
  changeStep,
}) => {
  const step1Invalid = step.current === 1 && selectedMaintenances.length === 0;

  const step2Invalid =
    step.current === 2 && !stepThreeRequestYupSchema.isValidSync(values);

  const step3Invalid =
    step.current === 3 && !addressYupSchema.isValidSync(values);

  const nextStepEnabled = !step1Invalid && !step2Invalid && !step3Invalid;

  return (
    <>
      <div className="flex flex-1 bg-white overflow-hidden ">
        <MaintenanceRequestWizardMenu
          handleNavigation={handleMenuNavigation}
          activeStep={step.current}
          steps={step.steps}
        />
        <div className="flex flex-1">
          {[
            <SelectMaintenancesStep
              key={1}
              isLoading={isLoading}
              buildings={buildings}
              maintenances={maintenances}
              selectedMaintenances={selectedMaintenances}
              setSelectedMaintenances={setSelectedMaintenances}
            />,
            <SelectAppointmentStep
              key={2}
              buildings={buildings}
              selectedMaintenances={selectedMaintenances}
            />,
            <SelectAddressStep key={3} />,
            <ConfirmationStep key={4} />,
          ].filter((_, index) => index + 1 === step.current)}
        </div>
      </div>
      <MaintenanceRequestWizardFooter
        activeStep={step.current}
        handleNext={handleNextNavigation}
        nextEnabled={nextStepEnabled}
        changeStep={changeStep}
      />
    </>
  );
};
