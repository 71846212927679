import { isPhoneNumber } from 'class-validator';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as Yup from 'yup';

import { CustomerDto } from '@wartungshelden/shared-types';

import {
  Button,
  Card,
  InputField,
  PageContainer,
  PageHeader,
  PhoneInputField,
} from '../../components';
import CheckBoxField from '../../components/Basics/Inputs/CheckBox/CheckBoxField';
import LoadingSpinner from '../../components/Basics/Loaders/LoadingSpinner';
import TextWithLabel from '../../components/Basics/TextWithLabel';
import { SessionContext } from '../../contexts/SessionContext';
import {
  useOwnCustomer,
  useUpdateCustomer,
} from '../../services/api/customer/customer-api';

interface CustomerFormikValues {
  absCustomerNumber: any;
  hasAcceptedCustomerNumber: any;
  phoneNumber: any;
}

const ProfilePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { userInfo } = useContext(SessionContext);
  const { data: customer, isLoading: isLoadingCustomer } = useOwnCustomer();
  const { mutateAsync: updateCustomer, isLoading: isSavingCustomer } =
    useUpdateCustomer();

  const initialValues: CustomerFormikValues = {
    absCustomerNumber: customer?.absCustomerNumber || '',
    hasAcceptedCustomerNumber: customer?.hasAcceptedCustomerNumber || false,
    phoneNumber: customer?.phoneNumber || '',
  };

  const validationSchema = Yup.object().shape({
    absCustomerNumber: Yup.string().test((value, context) => {
      const { hasAcceptedCustomerNumber } = context.parent;
      if (hasAcceptedCustomerNumber && value) return true;
      return !hasAcceptedCustomerNumber && !value;
    }),
    phoneNumber: Yup.string()
      .optional()
      .test({
        message: 'Rufnummer nicht gültig',
        test: (number) =>
          typeof number === 'undefined' || isPhoneNumber(number),
      }),
    hasAcceptedCustomerNumber: Yup.boolean()
      .when('absCustomerNumber', {
        is: (absCustomerNumber) =>
          absCustomerNumber !== undefined && absCustomerNumber !== '',
        then: Yup.boolean().oneOf([true]).required(),
      })
      .required(),
  });

  const onSaveCustomer = async (
    customerToSave: CustomerDto,
    values: CustomerFormikValues
  ) => {
    await updateCustomer({
      ...customerToSave,
      phoneNumber:
        values.phoneNumber !== '' && typeof values.phoneNumber !== 'undefined'
          ? values.phoneNumber
          : null,
      absCustomerNumber: values.absCustomerNumber,
      hasAcceptedCustomerNumber: values.hasAcceptedCustomerNumber,
    });

    if (window.history.length === 1) {
      window.close();
    }
  };

  return (
    <PageContainer>
      {userInfo && customer && (
        <Card>
          <PageHeader>Nutzerprofil</PageHeader>
          <TextWithLabel label="Name">
            {userInfo.givenName} {userInfo.familyName}
          </TextWithLabel>
          <TextWithLabel label="E-Mail">{userInfo.email}</TextWithLabel>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              await onSaveCustomer(customer, values);
            }}
          >
            {({ values, dirty, isValid, submitForm }) => {
              return (
                <>
                  <PhoneInputField name="phoneNumber" label="Telefonnummer" />
                  <hr className="border-gray-abs border mt-6 mb-6" />
                  <div className="mb-4">
                    <div className="mb-2 w-full">
                      <p className="font-bold">
                        Hast Du eine ABS-Kundennummer?
                      </p>
                    </div>
                    <InputField
                      name="absCustomerNumber"
                      placeholder="z.B. 12345"
                      label="ABS-Kundennummer"
                    />
                    <CheckBoxField
                      name="hasAcceptedCustomerNumber"
                      isRequired={values.absCustomerNumber.length > 0}
                      label="Mit Angabe der ABS-Kundennummer bestätige ich, dass die bei ABS Safety GmbH vorliegenden Daten und Dokumente zu meinem Unternehmen gehören und für ABS Wartung verwendet werden dürfen. Ich bestätige weiterhin, dass ich zu einer entsprechenden Freigabe berechtigt bin."
                    />
                  </div>
                  <hr className="border-gray-abs border mt-6 mb-6" />
                  <Button disabled={!isValid || !dirty} onClick={submitForm}>
                    Speichern
                  </Button>
                </>
              );
            }}
          </Formik>
          <LoadingSpinner isLoading={isLoadingCustomer || isSavingCustomer} />
        </Card>
      )}
    </PageContainer>
  );
};

export default ProfilePage;
