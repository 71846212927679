import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { buildingsRoutes } from '../constants/routes';
import BuildingDetailsPage from '../pages/UserPages/buildings/BuildingDetailsPage';
import BuildingsPage from '../pages/UserPages/buildings/BuildingsPage';

const BuildingsRouter = () => {
  return (
    <Routes>
      <Route index element={<BuildingsPage />} />
      <Route path={buildingsRoutes.add} element={<BuildingDetailsPage />} />
      <Route path={buildingsRoutes.details} element={<BuildingDetailsPage />} />
    </Routes>
  );
};

export default BuildingsRouter;
