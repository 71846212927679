import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  CreateMaintenanceObjectDto,
  MaintenanceObjectDto,
  UpdateMaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

export const useMaintenanceObjects = () => {
  const axios = useAxios();

  const fetchMaintenanceObjects = async () => {
    if (!axios) return null;
    const { data } = await axios.get<MaintenanceObjectDto[]>(
      '/maintenance-exchange/maintenance/objects'
    );
    return data;
  };

  return useQuery('maintenance-objects', fetchMaintenanceObjects);
};

export const useCreateMaintenanceObject = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const createMaintenanceObject = async (
    maintenanceObject: CreateMaintenanceObjectDto
  ) => {
    if (!axios) return null;
    return axios.post<MaintenanceObjectDto>(
      '/maintenance-exchange/maintenance/objects',
      maintenanceObject
    );
  };

  return useMutation(createMaintenanceObject, {
    onSuccess: async (param) => {
      if (param) {
        const changedMaintenanceObjectIds = sessionStorage.getItem(
          'changed_maintenance_objects'
        );
        const changedMaintenanceObjectIdsArray: string[] =
          changedMaintenanceObjectIds !== null
            ? JSON.parse(changedMaintenanceObjectIds)
            : [];
        changedMaintenanceObjectIdsArray.push(param.data.id); // TODO Why?
        sessionStorage.setItem(
          'changed_maintenance_objects',
          JSON.stringify(changedMaintenanceObjectIdsArray)
        );
        await rqClient.invalidateQueries('maintenance-objects');
      }
    },
  });
};

export const useUpdateMaintenanceObject = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const updateMaintenanceObject = async (
    maintenanceObject: UpdateMaintenanceObjectDto
  ) => {
    if (!axios) return null;
    return axios.patch<MaintenanceObjectDto>(
      `/maintenance-exchange/maintenance/objects`,
      maintenanceObject
    );
  };

  return useMutation(updateMaintenanceObject, {
    onSuccess: async () => {
      await rqClient.invalidateQueries('maintenance-objects');
    },
  });
};

export const useRemoveMaintenanceObject = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const deleteMaintenanceObject = async (id: string) => {
    if (!axios) return null;
    return axios.delete(`/maintenance-exchange/maintenance/objects/${id}`);
  };

  return useMutation(deleteMaintenanceObject, {
    onSuccess: async () => {
      await rqClient.invalidateQueries('maintenance-objects');
    },
  });
};
