import { DomainEvent } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import { MaintenanceDocumentation } from './maintenance-documentation';

export class MaintenanceDocumentationUpdated extends DomainEvent {
  public static override tag = 'maintenance.object.documentation.updated';

  constructor(
    public readonly user: User,
    public readonly documentation: MaintenanceDocumentation
  ) {
    super();
  }
}
