import React from 'react';
import { Outlet } from 'react-router-dom';

import UserNavigation from '../../../components/Basics/TabNavigation/UserNavigation';
import HeaderLogout from '../../../components/Header/HeaderLogout';
import { PriceCalculationProvider } from './PriceCalculationContext';

const PriceCalculationContainer: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="flex flex-1 flex-col h-screen">
      <PriceCalculationProvider>
        <HeaderLogout>
          <UserNavigation />
        </HeaderLogout>
        <Outlet />
      </PriceCalculationProvider>
    </div>
  );
};

export default PriceCalculationContainer;
