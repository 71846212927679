import React from 'react';
import { FaReply } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { routes } from '../../../constants/routes';
import { InfoMessageBox } from '../../Basics/MessageBox';

type Props = {
  isMaintenanceReadOnly: boolean;
  isReOpened?: boolean;
  requestId?: string;
  maintenanceId?: string;
};

const MaintenanceObjectCantBeEditedInfoBox: React.FC<
  React.PropsWithChildren<Props>
> = ({ isMaintenanceReadOnly, isReOpened, requestId, maintenanceId }) => {
  if (!isMaintenanceReadOnly) {
    return null;
  }

  return (
    <div className="pb-8">
      <InfoMessageBox
        headline={
          isReOpened
            ? 'Änderungen an Wartung nur bedingt möglich'
            : 'Änderungen an Wartung nicht möglich'
        }
        label={
          isReOpened
            ? 'Da diese Wartungsaufgabe Teil eines laufenden Auftrags ist, kann sie nur bedingt bearbeitet werden. Nutze diese Möglichkeit, um Dokumente nachzureichen.'
            : 'Da diese Wartungsaufgabe Teil eines laufenden Auftrags ist, kann sie aktuell nicht bearbeitet werden.'
        }
      >
        <div>
          <Link
            to={`/${routes.requests}/${requestId}/maintenances/${maintenanceId}`}
            className="flex flex-row items-center"
          >
            <FaReply className="transform -scale-x-1" />
            <span className="ml-2 underline font-bold">
              {isReOpened
                ? 'Anfrage ansehen und Dokumente nachreichen'
                : 'Anfrage ansehen'}
            </span>
          </Link>
        </div>
      </InfoMessageBox>
    </div>
  );
};

MaintenanceObjectCantBeEditedInfoBox.defaultProps = {
  isReOpened: false,
  requestId: undefined,
  maintenanceId: undefined,
};

export default MaintenanceObjectCantBeEditedInfoBox;
