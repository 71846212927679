import { Entity } from '@warthungshelden/domain/common';

export class Distance extends Entity {
  public readonly minutes: number | null;
  public readonly kilometers: number | null;

  constructor(config: { minutes: number | null; kilometers: number | null }) {
    super();
    this.minutes = config.minutes;
    this.kilometers = config.kilometers;
  }
}

export type DistanceMatrix = Distance[][];
