/* eslint-disable react/require-default-props */
import { useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';

import { FormikMaintenanceWizardValues } from '../../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { FileOrDocumentation } from '../../../../services/maintenanceService';
import DocumentationUploadModal from '../../Documentation/DocumentationUploadModal';

interface UploadModalProps {
  isVisible: boolean;
  setIsVisible: (state: boolean | ((prevState: boolean) => boolean)) => void;
  index: number;
  setChosenFiles: Dispatch<SetStateAction<FileOrDocumentation[]>>;
  setAddedFiles?: Dispatch<SetStateAction<File[]>>;
  addedFiles?: File[];
}

const UploadModal: React.FC<React.PropsWithChildren<UploadModalProps>> = ({
  isVisible,
  setIsVisible,
  index,
  setChosenFiles,
  setAddedFiles,
  addedFiles,
}) => {
  const { values, setFieldValue } =
    useFormikContext<FormikMaintenanceWizardValues>();

  const existingDocs = values.uploadedMaintenanceDocumentations ?? [];

  const onSave = (
    existingDocumentations: FileOrDocumentation[],
    selectedDocumentations: FileOrDocumentation[],
    uploadedFiles: File[]
  ) => {
    setFieldValue(
      `maintenanceObjects.${index}.selectedFiles`,
      selectedDocumentations
    );
    setChosenFiles(selectedDocumentations);

    if (setAddedFiles) {
      setAddedFiles(uploadedFiles);
    }

    setIsVisible(false);
  };

  return (
    <DocumentationUploadModal
      visible={isVisible}
      existingDocumentations={existingDocs}
      initialAddedFiles={addedFiles}
      onSave={onSave}
      index={index}
    />
  );
};

export default UploadModal;
