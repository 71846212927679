import {
  isDefined,
  to,
  wherePropertyEquals,
} from '@warthungshelden/shared-functions';
import {
  addDays,
  addMonths,
  differenceInHours,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  getHours,
  isSameDay,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { InstallerDto, RouteDto } from '@wartungshelden/shared-types';

import { Button } from '../../../../../../components';
import DateSelectFieldFormik from '../../../../../../components/Basics/Inputs/DateSelection/DateSelectFieldFormik';
import SectionComboBox from '../../../../../../components/Basics/Inputs/SectionComboBox';
import LoadingSpinner from '../../../../../../components/Basics/Loaders/LoadingSpinner';
import {
  SuccessMessageBox,
  WarningMessageBox,
} from '../../../../../../components/Basics/MessageBox';
import Modal from '../../../../../../components/Basics/Modals/Modal';
import { useInstallerAppointments } from '../../../../../../services/api/ressource-planning/installers-api';
import { useRouteWithInstaller } from '../../../../../../services/api/ressource-planning/route-api';
import { RouteDtoWithEfficencyRules } from '../../../types/RouteDtoWithEfficencyRules';
import JobDetailsEditRoute from '../jobDetails/JobDetailsEditRoute';

interface RouteActionsEditModalProps {
  route: RouteDtoWithEfficencyRules;
  originalRoute: RouteDtoWithEfficencyRules;
  mainInstaller: InstallerDto;
  installers: InstallerDto[];
  showModal: boolean;
  onCancel: () => void;
  onSave: (changedRoute: RouteDto) => void;
}

const RouteActionsEditModal: React.FC<RouteActionsEditModalProps> = ({
  route,
  originalRoute,
  mainInstaller,
  installers,
  showModal,
  onCancel,
  onSave,
}) => {
  const [changedRoute, setChangedRoute] = useState<RouteDto | undefined>();

  const initialState = {
    installerId: mainInstaller?.id,
    date: route.date,
  };

  return (
    <Modal isOpen={showModal} headline="Tour bearbeiten" center width="half">
      <Formik
        initialValues={initialState}
        validationSchema={Yup.object().shape({
          installerId: Yup.string().required(),
          date: Yup.date().required(),
        })}
        enableReinitialize
        validateOnMount
        onSubmit={async (values) => {
          if (changedRoute) {
            onSave({ ...changedRoute, date: values.date });
          } else {
            onSave({ ...route, date: values.date });
          }
        }}
      >
        {({ submitForm, isValid, values, setFieldValue, dirty }) => {
          const [selectedPreviewMonth, setSelectedPreviewMonth] = useState<
            Date | undefined
          >(values.date);
          const {
            data: installerAppointments,
            isLoading: isLoadingInstallerAppointments,
          } = useInstallerAppointments(
            values.installerId,
            values.date && startOfDay(values.date)
          );

          const {
            data: installerMonthAppointmentsWithBuffer,
            isLoading: isLoadingInstallerMonthAppointments,
          } = useInstallerAppointments(
            values.installerId,
            selectedPreviewMonth &&
              startOfDay(subMonths(startOfMonth(selectedPreviewMonth), 1)),
            selectedPreviewMonth &&
              endOfDay(addMonths(endOfMonth(selectedPreviewMonth), 1))
          );

          const { mutateAsync: getRoute, isLoading: isLoadingRoute } =
            useRouteWithInstaller();

          const currentInstaller = installers.find(
            wherePropertyEquals('id', values.installerId)
          );

          useEffect(() => {
            if (!dirty) {
              setChangedRoute(undefined);
              return;
            }

            if (
              values.installerId ===
              originalRoute.installerCandidates[0]?.installerId
            ) {
              setChangedRoute({
                ...originalRoute,
                date: changedRoute?.date ?? originalRoute.date,
              });
              return;
            }

            const fetchRoute = async () => {
              if (currentInstaller && values.date) {
                const { efficiencyRules, ...routeDto } = route;
                const newRoute = await getRoute({
                  route: routeDto,
                  installer: currentInstaller,
                });
                if (!newRoute) {
                  throw new Error();
                }
                setChangedRoute(newRoute);
              }
            };

            fetchRoute().catch(() => {
              toast.error('Leider konnte die Route nicht berechnet werden', {
                position: 'top-center',
              });
            });
          }, [values.installerId]);

          const isSelectedDateOverlapping = () => {
            if (values?.date && installerAppointments) {
              const daysItTakes = Math.ceil(route.duration / 8) - 1;
              const allDaysFromSelectedDate = eachDayOfInterval({
                start: new Date(values.date),
                end: addDays(new Date(values.date), daysItTakes),
              });
              const allDaysFromAppointments =
                installerMonthAppointmentsWithBuffer
                  ?.map((appointment) => {
                    return eachDayOfInterval({
                      start: new Date(appointment.startDate),
                      end: new Date(appointment.endDate),
                    });
                  })
                  .flat();
              return allDaysFromSelectedDate.some((jobDays) =>
                allDaysFromAppointments?.some((appointmentDay) =>
                  isSameDay(jobDays, appointmentDay)
                )
              );
            }
            return false;
          };

          return (
            <>
              <div className="font-bold">Monteur</div>
              <SectionComboBox
                currentValue={mainInstaller}
                topSection={originalRoute.installerCandidates
                  .map((installerCandidate) =>
                    installers.find(
                      wherePropertyEquals('id', installerCandidate.installerId)
                    )
                  )
                  .filter(isDefined)}
                bottomSection={installers.filter(
                  (installer) =>
                    !originalRoute.installerCandidates
                      .map(to('installerId'))
                      .includes(installer.id)
                )}
                onChange={(installer) => {
                  setFieldValue('installerId', installer.id);
                }}
                keyToDisplay="name"
              />
              <div className="mt-4 font-bold">Datum</div>
              <div data-cy="edit-date-select-field">
                <DateSelectFieldFormik
                  name="date"
                  onMonthChange={(month) => {
                    setSelectedPreviewMonth(month);
                  }}
                  onYearChange={(year) => {
                    setSelectedPreviewMonth(year);
                  }}
                  highlightDates={installerMonthAppointmentsWithBuffer?.map(
                    (appointment) => {
                      return {
                        'bg-red-blocked hover:!bg-red-table	': eachDayOfInterval(
                          {
                            start: appointment.startDate,
                            end: appointment.endDate,
                          }
                        ),
                      };
                    }
                  )}
                  disableWeekend
                />
              </div>
              <LoadingSpinner
                isLoading={
                  isLoadingInstallerMonthAppointments ||
                  isLoadingInstallerAppointments
                }
              />
              <div className="pt-4 space-y-2 min-h-[20rem]">
                {!isLoadingInstallerAppointments &&
                  installerAppointments &&
                  // eslint-disable-next-line no-nested-ternary
                  (installerAppointments?.length ? (
                    <WarningMessageBox label="Für diesen Tag existieren bereits folgende Termine:">
                      <div className="flex flex-col ml-12 justify-start space-y-2">
                        {installerAppointments.map((appointment) => {
                          const appointmentDuration = differenceInHours(
                            appointment.endDate,
                            appointment.startDate
                          );

                          return (
                            <div>
                              {appointmentDuration > 24
                                ? 'Tag geblockt, 24h'
                                : `${getHours(
                                    appointment.startDate
                                  )}-${getHours(
                                    appointment.endDate
                                  )} Uhr geblockt, ${appointmentDuration}h `}
                            </div>
                          );
                        })}
                      </div>
                    </WarningMessageBox>
                  ) : isSelectedDateOverlapping() ? (
                    <WarningMessageBox
                      label="Im benötigten Zeitraum ist mindestens ein Tag bereits
                          durch andere Termine belegt."
                    />
                  ) : (
                    <SuccessMessageBox label="Der Tag ist verfügbar. Es liegen keine Termine vor." />
                  ))}
                <hr className="mt-4 w-full border-t-2 border-dotted border-gray-300" />
                {changedRoute && currentInstaller && (
                  <div className="flex flex-1 mt-4 justify-center space-x-4">
                    <JobDetailsEditRoute
                      route={route}
                      mainInstaller={mainInstaller}
                      title="Aktuelle Tour"
                    />

                    <JobDetailsEditRoute
                      route={changedRoute}
                      title="Neue Tour"
                      mainInstaller={currentInstaller}
                      backgroundColor="gray"
                      delay={{
                        totalDuration:
                          changedRoute.duration +
                          (changedRoute.totalJobDistance.minutes ?? 0) / 60 -
                          (route.duration +
                            (route.totalJobDistance.minutes ?? 0) / 60),
                        kilometers:
                          (changedRoute.totalJobDistance.kilometers ?? 0) -
                          (route.totalJobDistance.kilometers ?? 0),
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="flex justify-around mt-4">
                <Button onClick={onCancel} className="outline-button mr-2">
                  Abbrechen
                </Button>
                <Button
                  disabled={
                    !isValid || !dirty || isLoadingInstallerAppointments
                  }
                  onClick={submitForm}
                >
                  Änderung übernehmen
                </Button>
              </div>
              <LoadingSpinner isLoading={isLoadingRoute}>
                Berechnung der Touren läuft...
              </LoadingSpinner>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default RouteActionsEditModal;
