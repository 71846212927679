import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react';

import { SelectLineMaintenanceOrderDto } from '@wartungshelden/shared-types';

import InfoText from './InfoText';
import InformationContainer from './InformationContainer';

interface Props {
  selectLineData?: SelectLineMaintenanceOrderDto;
}

const InstallerInfo: React.FC<Props> = ({ selectLineData }) => {
  return (
    <InformationContainer>
      <InfoText label="Monteur:" indicateMissingData labelStyle="font-bold">
        {selectLineData?.installerName}
      </InfoText>

      <div className="mt-4">
        <InfoText label="Fälligkeit:" indicateMissingData>
          {selectLineData?.dueDate &&
            format(selectLineData.dueDate, 'MMMM yyyy', { locale: de })}
        </InfoText>

        <InfoText
          label="Ausführungstermin:"
          indicateMissingData
          labelStyle="font-bold"
        >
          {selectLineData?.appointment?.start &&
            format(selectLineData?.appointment?.start, 'dd.MM.yyyy')}
        </InfoText>

        <InfoText label="Uhrzeit:" indicateMissingData labelStyle="font-bold">
          {selectLineData?.appointment?.start &&
            format(selectLineData?.appointment?.start, "'ca.' HH 'Uhr'")}
        </InfoText>
      </div>
    </InformationContainer>
  );
};

InstallerInfo.defaultProps = {
  selectLineData: undefined,
};

export default InstallerInfo;
