import { FieldArray } from 'formik';
import React from 'react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import {
  AdditionalMaintenanceOrderDataDto,
  AdditionalMaintenanceOrderSystemDataDto,
} from '@wartungshelden/shared-types';

import { Button } from '../../../../components';
import TextareaRow from '../../../../components/Basics/Inputs/TextareaRow';
import InformationContainer from './InformationContainer';
import SystemDataTestProcedureCheckboxes from './SystemDataTestProcedureCheckboxes';

interface Props {
  additionalData?: AdditionalMaintenanceOrderDataDto;
}

const SystemsInfo: React.FC<Props> = ({ additionalData }) => {
  if (!additionalData) {
    return null;
  }

  return (
    <FieldArray
      name="systems"
      render={(arrayHelpers) => (
        <>
          {additionalData.systems?.map(
            (system: AdditionalMaintenanceOrderSystemDataDto, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <InformationContainer key={index}>
                <div className="flex flex-row justify-between font-bold mb-4">
                  <div>System</div>
                  <button
                    type="button"
                    className="hover:cursor-pointer"
                    onClick={() => {
                      arrayHelpers.remove(index);
                    }}
                  >
                    <FaTrashAlt size={18} />
                  </button>
                </div>
                <TextareaRow
                  name={`systems.[${index}].systemComponents`}
                  label="Systembestandteile:"
                  placeholder=""
                  rows={3}
                  displayInSingleRow
                  labelStyle="font-normal"
                />
                <TextareaRow
                  name={`systems.[${index}].additionalNotes`}
                  label="Notizen:"
                  placeholder=""
                  rows={3}
                  displayInSingleRow
                  labelStyle="font-normal"
                />

                <div className="flex w-full">
                  <div className="font-bold w-64 mr-4 font-normal">
                    Prüfverfahren
                  </div>
                  <div className="w-full">
                    <SystemDataTestProcedureCheckboxes
                      namePrefix={`systems.[${index}].testProcedures`}
                    />
                  </div>{' '}
                </div>
              </InformationContainer>
            )
          )}

          <Button
            className="outline-button"
            icon={<FaPlus />}
            onClick={() => {
              arrayHelpers.push({
                systemComponents: undefined,
                testProcedure: undefined,
                additionalNotes: undefined,
              });
            }}
            label="Weiteres System"
          />
        </>
      )}
    />
  );
};

SystemsInfo.defaultProps = {
  additionalData: undefined,
};

export default SystemsInfo;
