import {
  FallProtectionDto,
  PriceInformationObjectDto,
} from '@wartungshelden/shared-types';

import {
  isFallProtectionDto,
  isPPEDto,
} from '../../../../guards/isMaintenanceType';
import {
  PriceInformationCombination,
  toPriceInformationObject,
} from './combinations';

const hasSameType = (
  informationA: PriceInformationObjectDto,
  informationB: PriceInformationObjectDto
) => {
  return informationA.object.type.type === informationB.object.type.type;
};

const hasSameManufacturer = (
  informationA: PriceInformationObjectDto,
  informationB: PriceInformationObjectDto
) => {
  return informationA.object.manufacturer === informationB.object.manufacturer;
};

const hasSameBuildingProperty = (
  informationA: PriceInformationObjectDto,
  informationB: PriceInformationObjectDto
) => {
  return (
    informationA.building?.isPublic === informationB.building?.isPublic &&
    informationA.building?.buildingType === informationB.building?.buildingType
  );
};

const hasSameFallProtectionSystem = (
  objectType: FallProtectionDto,
  objectToCompareType: FallProtectionDto
) => {
  const { system: objectSystem } = objectType;
  const { system: objectToCompareSystem } = objectToCompareType;

  if (!objectSystem || !objectToCompareSystem) {
    return false;
  }

  if (objectToCompareSystem?.system !== objectSystem?.system) {
    return false;
  }

  if (objectSystem?.system === 'rope') {
    return objectType.location === objectToCompareType.location;
  }

  return true;
};

// eslint-disable-next-line import/prefer-default-export
export function toGroupedCombinations(
  combinations: PriceInformationCombination[][],
  combination: PriceInformationCombination
): PriceInformationCombination[][] {
  const groupIndex = combinations.findIndex(([firstCombination]) => {
    if (!firstCombination) {
      return firstCombination;
    }

    const information = toPriceInformationObject(combination);
    const firstInformation = toPriceInformationObject(firstCombination);

    if (!hasSameType(information, firstInformation)) {
      return false;
    }

    if (information.object.type.type === 'railing') {
      return true;
    }

    if (!hasSameManufacturer(information, firstInformation)) {
      return false;
    }

    if (!hasSameBuildingProperty(information, firstInformation)) {
      return false;
    }

    const informationType = information.object.type;
    const firstInformationType = firstInformation.object.type;

    if (
      isFallProtectionDto(informationType) &&
      isFallProtectionDto(firstInformationType)
    ) {
      return hasSameFallProtectionSystem(informationType, firstInformationType);
    }

    if (isPPEDto(informationType) && isPPEDto(firstInformationType)) {
      return informationType.systemType === firstInformationType.systemType;
    }

    if (
      (information.object.type.type === 'overpass' &&
        firstInformation.object.type.type === 'overpass') ||
      (information.object.type.type === 'stair_ladder' &&
        firstInformation.object.type.type === 'stair_ladder')
    ) {
      const informationContainsSmall = Boolean(
        information.object.type.parts.filter((length) => length <= 3).length
      );

      const informationContainsLarge = Boolean(
        information.object.type.parts.filter((length) => length > 3).length
      );

      const firstInformationContainsSmall = Boolean(
        firstInformation.object.type.parts.filter((length) => length <= 3)
          .length
      );

      const firstInformationContainsLarge = Boolean(
        firstInformation.object.type.parts.filter((length) => length > 3).length
      );

      return (
        informationContainsSmall === firstInformationContainsSmall &&
        informationContainsLarge === firstInformationContainsLarge
      );
    }

    if (
      information.object.type.type === 'ladder' &&
      firstInformation.object.type.type === 'ladder'
    ) {
      const informationContainsSmall = Boolean(
        information.object.type.parts.filter((length) => length <= 10).length
      );

      const informationContainsLarge = Boolean(
        information.object.type.parts.filter((length) => length > 10).length
      );

      const firstInformationContainsSmall = Boolean(
        firstInformation.object.type.parts.filter((length) => length <= 10)
          .length
      );

      const firstInformationContainsLarge = Boolean(
        firstInformation.object.type.parts.filter((length) => length > 10)
          .length
      );

      return (
        informationContainsSmall === firstInformationContainsSmall &&
        informationContainsLarge === firstInformationContainsLarge
      );
    }

    return true;
  });

  if (groupIndex === -1) {
    return [...combinations, [combination]];
  }

  const newGroups = [...combinations];
  combinations[groupIndex].push(combination);
  return newGroups;
}
