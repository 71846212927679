import { addYears, getDay, startOfMonth } from 'date-fns';
import de from 'date-fns/locale/de';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import environment from '../../../../../environments/environment';

export interface HighlightDates {
  [className: string]: Date[];
}
interface DateSelectFieldProps {
  onChange: (date: Date) => void;
  selected?: Date;
  onlyMonths?: boolean;
  onMonthChange?: (month: Date) => void;
  onYearChange?: (year: Date) => void;
  highlightDates?: Array<HighlightDates | Date>;
  disableWeekend?: boolean;
  showInline?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  calendarClassName?: string;
}

const DateSelectField: React.FC<DateSelectFieldProps> = ({
  onChange,
  selected,
  onlyMonths,
  onMonthChange,
  onYearChange,
  highlightDates,
  disableWeekend,
  showInline,
  disabled,
  readonly,
  calendarClassName,
}) => {
  const now = new Date();
  const fiveYearsFromNow = addYears(now, 5);

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const getMinDate = () => {
    if (environment.e2e) {
      return startOfMonth(new Date(2023, 7, 15));
    }
    if (onlyMonths) {
      return startOfMonth(now);
    }
    return now;
  };

  return (
    <DatePicker
      selected={selected}
      minDate={getMinDate()}
      calendarClassName={calendarClassName}
      onChange={onChange}
      maxDate={fiveYearsFromNow}
      showYearDropdown
      disabled={disabled}
      readOnly={readonly}
      showMonthYearPicker={onlyMonths}
      isClearable
      locale={de}
      dateFormat={onlyMonths ? 'MMMM yyyy' : 'dd.MM.yyyy'}
      className="border-2 rounded-lg px-4 py-2 bg-white border-gray w-full "
      dayClassName={
        readonly ? () => '!cursor-default hover:!bg-inherit' : undefined
      }
      onMonthChange={onMonthChange}
      onYearChange={onYearChange}
      highlightDates={highlightDates}
      filterDate={disableWeekend ? isWeekday : undefined}
      preventOpenOnFocus
      disabledKeyboardNavigation
      inline={showInline ?? false}
    />
  );
};

DateSelectField.defaultProps = {
  selected: undefined,
  onlyMonths: false,
  onMonthChange: undefined,
  onYearChange: undefined,
  highlightDates: undefined,
  disableWeekend: false,
  showInline: false,
  disabled: false,
  readonly: false,
  calendarClassName: undefined,
};

export default DateSelectField;
