import React, { useContext, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import {
  BuildingDto,
  CurrentMaintenanceObjectDto,
  CustomerOrMaintenanceTeamRequestDto,
  MaintenanceOfferDto,
} from '@wartungshelden/shared-types';

import { MaintenanceRequestMenu } from '../../components';
import UserNavigation from '../../components/Basics/TabNavigation/UserNavigation';
import HeaderLogout from '../../components/Header/HeaderLogout';
import { routes } from '../../constants/routes';
import {
  HintHighlightContext,
  MAINTENANCE_OBJECT_IS_REOPENED,
} from '../../contexts/HintHighlightContext';
import { SessionContext } from '../../contexts/SessionContext';
import { useBuildings } from '../../services/api/buildings/buildings-api';
import { useMaintenanceObjects } from '../../services/api/maintenance-objects/maintenance-objects-api';
import { useMaintenanceOffersOfRequest } from '../../services/api/maintenance-offers/maintenance-offer-api';
import { useMaintenanceRequests } from '../../services/api/maintenance-requests/maintenance-request-api';

export type RequestDetailsOutlet = {
  currentBuildings?: BuildingDto[];
  currentRequest?: CustomerOrMaintenanceTeamRequestDto;
  currentRequestMaintenances?: CurrentMaintenanceObjectDto[];
  currentOffer?: MaintenanceOfferDto;
};

const RequestMainPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  const { requestId } = useParams();

  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);
  const { addHighlight, clearHighlight } = useContext(HintHighlightContext);

  const { data: buildings } = useBuildings();
  const { data: maintenanceRequests } = useMaintenanceRequests();
  const { data: maintenances } = useMaintenanceObjects();

  const currentRequest = maintenanceRequests?.find(
    (request) => request.id === requestId
  );

  const { data: offers } = useMaintenanceOffersOfRequest(currentRequest?.id);

  const currentOffer = offers && offers[0];

  const currentRequestMaintenances = useMemo(
    () =>
      currentOffer?.maintenanceObjects ??
      maintenances?.filter(
        (maintenance) => maintenance.currentRequestId === currentRequest?.id
      ),
    [currentOffer]
  );

  const buildingIdsInRequest = [
    ...new Set(
      currentRequestMaintenances?.map(
        (maintenanceObject) => maintenanceObject.buildingId
      )
    ),
  ];

  const currentBuildings = buildings?.filter((building) =>
    buildingIdsInRequest.includes(building.id)
  );

  const isDocumentUploadEnabled = (maintenance) => {
    return Boolean(
      maintenance?.originalId &&
        currentOffer?.objectsWithOpenDocumentUpload.includes(
          maintenance?.originalId
        )
    );
  };

  useEffect(() => {
    if (!currentRequestMaintenances) {
      return;
    }
    currentRequestMaintenances.forEach((maintenanceObject) => {
      const highLightSignature = `${MAINTENANCE_OBJECT_IS_REOPENED}${maintenanceObject.originalId}`;
      if (
        isDocumentUploadEnabled(maintenanceObject) &&
        !isMaintenanceTeamMemberAdmin
      ) {
        addHighlight(highLightSignature);
      } else {
        clearHighlight(highLightSignature);
      }
    });
  }, [currentRequestMaintenances]);

  const shouldRenderHeader =
    location.pathname === `/${routes.requests}` ||
    location.pathname === `/admin/${routes.requests}`;

  if (shouldRenderHeader) {
    return (
      <div className="flex flex-1 flex-col h-screen">
        <HeaderLogout>
          <UserNavigation />
        </HeaderLogout>
        <Outlet />
      </div>
    );
  }
  return (
    <div className="flex flex-1 flex-row h-screen">
      {currentRequest && (
        <MaintenanceRequestMenu
          currentBuildings={currentBuildings}
          currentRequest={currentRequest}
          currentRequestMaintenances={currentRequestMaintenances}
        />
      )}
      <div className="flex h-screen w-full overflow-y-scroll px-8 py-12">
        <Outlet
          context={{
            currentBuildings,
            currentRequest,
            currentRequestMaintenances,
            currentOffer,
          }}
        />
      </div>
    </div>
  );
};

export default RequestMainPage;
