import { v4 } from 'uuid';

import { CalendarAppointment } from './calendar-appointment';

export abstract class CalendarAdapter {
  public abstract getAppointmentsForInstaller(
    installerId: string,
    from: Date,
    to?: Date
  ): Promise<CalendarAppointment[]>;

  public abstract createAppointmentsForInstaller(
    installerId: string,
    ...appointments: CalendarAppointment[]
  ): Promise<void>;

  public static calendarAppointmentId() {
    return v4().replace(/-/gm, '');
  }
}
