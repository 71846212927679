import React from 'react';

import { MaintenanceDocumentationDto } from '@wartungshelden/shared-types';

import { useMaintenanceDocumentationFile } from '../../../../services/api/maintenance-documentation/maintenance-documentation-api';
import Loader from '../../../Basics/Loaders/Loader';
import IsDocumentValidTip from '../IsDocumentValidTip';
import FilePreview from './FilePreview';

export interface MaintenanceDocumentationPreviewProps {
  isSelected?: boolean;
  onClick?: () => void;
  documentationOrFile: MaintenanceDocumentationDto | File;
  maintenanceOwnerId?: string;
  deleteFile?: (file: File) => void;
  disabled?: boolean;
  documentIsValid?: boolean;
}

const MaintenanceDocumentationPreview: React.FC<
  React.PropsWithChildren<MaintenanceDocumentationPreviewProps>
> = ({
  onClick,
  deleteFile,
  isSelected,
  documentationOrFile,
  maintenanceOwnerId,
  disabled,
  documentIsValid,
}) => {
  const hasFile = documentationOrFile instanceof File;

  const { isLoading, data: loadedFile } = useMaintenanceDocumentationFile(
    hasFile ? undefined : documentationOrFile.id,
    !hasFile,
    maintenanceOwnerId
  );

  const fileToUse = hasFile ? documentationOrFile : loadedFile;

  return (
    <div
      className={`relative ${isSelected ? 'border-4 border-green-500' : ''}`}
    >
      {!isLoading && fileToUse && (
        <FilePreview
          disabled={disabled}
          file={fileToUse}
          onClick={onClick}
          deleteFile={deleteFile}
        />
      )}
      {isLoading && (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
      {disabled && (
        <div className="absolute -top-2 -right-2">
          <IsDocumentValidTip isValid={documentIsValid} />
        </div>
      )}
    </div>
  );
};

MaintenanceDocumentationPreview.defaultProps = {
  isSelected: false,
  disabled: false,
  maintenanceOwnerId: undefined,
  onClick: undefined,
  deleteFile: undefined,
  documentIsValid: undefined,
};

export default MaintenanceDocumentationPreview;
