import React from 'react';
import 'react-calendar/dist/Calendar.css';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { BuildingsTable, Button, PageContainer } from '../../../components';
import { PageHeaderOverview } from '../../../components/Basics/Layouts/PageHeader';
import { InfoMessageBox } from '../../../components/Basics/MessageBox';
import { buildingsRoutes } from '../../../constants/routes';
import { useBuildings } from '../../../services/api/buildings/buildings-api';

const BuildingsPage = () => {
  const navigate = useNavigate();
  const { data: buildings } = useBuildings();

  return (
    <PageContainer>
      <PageHeaderOverview>
        <div className="pl-4">Gebäude</div>
        {buildings && buildings.length > 0 && (
          <Button
            className="primary-button small-button"
            onClick={() => {
              navigate(buildingsRoutes.add);
            }}
            label="Gebäude"
            icon={<FaPlus />}
          />
        )}
      </PageHeaderOverview>
      {buildings && buildings.length > 0 ? (
        <div className="h-[64vh]">
          <BuildingsTable
            buildings={buildings}
            onScrollToBottom={() => {
              // do nothing
            }}
            isLoading={false}
          />
        </div>
      ) : (
        <InfoMessageBox
          label="Kein Gebäude vorhanden. Lege ein Gebäude an."
          isCentered
        >
          <Button
            onClick={() => {
              navigate(buildingsRoutes.add);
            }}
            icon={<FaPlus />}
            label="Gebäude"
            className="primary-button small-button"
          />
        </InfoMessageBox>
      )}
    </PageContainer>
  );
};

export default BuildingsPage;
