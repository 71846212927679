/* eslint-disable react/jsx-props-no-spreading,react/prop-types,react/no-unstable-nested-components */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, Row, useRowSelect, useSortBy, useTable } from 'react-table';

import {
  BuildingDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { readableServiceType } from '../../../constants/MaintenanceConstants';
import { getCheckField } from '../../../constants/MaintenanceUtils';
import { requestableMaintenanceObjectValidationSchema } from '../../../constants/MaintenanceYupSchema';
import {
  buildingsRoutes,
  maintenanceRoutes,
  routes,
} from '../../../constants/routes';
import { isPriceableMaintenanceType } from '../../../services/maintenanceService';
import Button from '../../Basics/Buttons/Button';
import { InfoMessageBox } from '../../Basics/MessageBox';
import DueDateCell from './DueDateCell';
import TableCheckbox from './TableCheckbox';

type TableProps = {
  maintenances: MaintenanceObjectDto[];
  buildings: BuildingDto[];
  isLoading: boolean;
  selectedMaintenances: MaintenanceObjectDto[];
  setSelectedMaintenances: Dispatch<SetStateAction<MaintenanceObjectDto[]>>;
};

const MaintenanceRequestCreateTable: React.FC<
  React.PropsWithChildren<TableProps>
> = ({
  maintenances,
  buildings,
  selectedMaintenances,
  setSelectedMaintenances,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const listInnerRef = useRef<HTMLTableSectionElement>(null);

  const isMaintenanceComplete = (maintenance: MaintenanceObjectDto) =>
    requestableMaintenanceObjectValidationSchema.isValidSync(maintenance);
  const isMaintenanceTypeSupported = (maintenance: MaintenanceObjectDto) =>
    isPriceableMaintenanceType(maintenance);

  const goToDetailsPage = (maintenance: MaintenanceObjectDto) => {
    if (!(location.state as any)?.disableRowClick) {
      const isComplete = isMaintenanceComplete(maintenance);

      navigate(`/maintenance/${maintenance.id}`, {
        state: {
          showError: !isComplete,
          initialStep: isComplete ? 1 : 2,
        },
      });
    }
  };

  const getCheckFieldColor = (value) => {
    if (!isMaintenanceTypeSupported(value)) {
      return 'bg-gray';
    }
    return isMaintenanceComplete(value) ? 'bg-white' : 'bg-red-light';
  };

  const columns = React.useMemo((): Column<MaintenanceObjectDto>[] => {
    return [
      {
        Header: 'Gebäude',
        accessor: (maintenance) =>
          buildings?.find(({ id }) => id === maintenance.buildingId)?.name,
      },
      {
        Header: 'Interne Bezeichnung',
        accessor: 'name',
        sortType: 'sortMaintenanceName',
      },
      {
        Header: 'Wartungstyp',
        accessor: (maintenance) => readableServiceType(maintenance.type).label,
      },
      {
        Header: 'Nächste Fälligkeit',
        accessor: 'dueDate',
        id: 'dueDate',
        // @ts-ignore
        Cell: (cell) => <DueDateCell cell={cell} />,
        sortType: 'datetimeNullSafe',
      },
      {
        Header: 'Check',
        accessor: (maintenance) => maintenance,
        // @ts-ignore
        Cell: ({ value }) => {
          return (
            <div
              className={`h-9 flex items-center justify-center ${getCheckFieldColor(
                value
              )}`}
            >
              {getCheckField(value)}
            </div>
          );
        },
      },
      {
        id: 'details',
        // @ts-ignore
        Cell: ({ row }) => (
          <div className="flex justify-center px-1">
            <FaSearch onClick={() => goToDetailsPage(row.original)} size={20} />
          </div>
        ),
      },
    ];
  }, [buildings]);

  const selectMaintenance = (row: Row<MaintenanceObjectDto>) => {
    const canSelect =
      isMaintenanceComplete(row.original) &&
      isMaintenanceTypeSupported(row.original);

    if (!canSelect) {
      return;
    }
    if (row.isSelected) {
      setSelectedMaintenances((prevSelectedRows) => {
        return prevSelectedRows.filter(({ id }) => id !== row.original.id);
      });
    } else {
      setSelectedMaintenances((prevSelectedRows) => [
        ...prevSelectedRows,
        row.original,
      ]);
    }

    row.toggleRowSelected();
  };

  const getCheckboxColumn = (): Column<MaintenanceObjectDto> => ({
    id: 'selection',
    // @ts-ignore
    Header: <div />,
    // @ts-ignore
    Cell: ({ row }) => {
      const isComplete = isMaintenanceComplete(row.original);
      const isSupportedType = isPriceableMaintenanceType(row.original);

      return (
        <div
          role="button"
          tabIndex={0}
          data-cy="components-maintenances-maintenance-request-table-row-checkbox"
          className="flex justify-center items-center"
          onClick={() => {
            selectMaintenance(row);
          }}
          onKeyDown={() => {
            selectMaintenance(row);
          }}
        >
          {isSupportedType && (
            <TableCheckbox
              {...row.getToggleRowSelectedProps()}
              disabled={!isComplete}
            />
          )}
        </div>
      );
    },
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<MaintenanceObjectDto>(
      {
        columns,
        data: maintenances,
        sortTypes: {
          datetimeNullSafe: (
            row1: Row<MaintenanceObjectDto>,
            row2: Row<MaintenanceObjectDto>,
            columnName: string
          ) => {
            let [a, b] = [row1.values[columnName], row2.values[columnName]];

            a = a ? new Date(a).getTime() : Infinity;
            b = b ? new Date(b).getTime() : Infinity;

            if (a === b) {
              return 0;
            }
            return a > b ? 1 : -1;
          },
          sortMaintenanceName: (
            row1: Row<MaintenanceObjectDto>,
            row2: Row<MaintenanceObjectDto>
          ) => {
            const a = row1.values.name ? row1.values.name.toLowerCase() : '';
            const b = row2.values.name ? row2.values.name.toLowerCase() : '';

            if (a === '') {
              return 1;
            }
            return b === '' ? -1 : a.localeCompare(b);
          },
        },
        initialState: {
          sortBy: useMemo(() => {
            return [
              {
                id: 'dueDate',
              },
              {
                id: 'Wartungstyp',
              },
              {
                id: 'name',
              },
            ];
          }, []),
        },
        autoResetSortBy: false,
      },
      useSortBy,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((col) => [getCheckboxColumn(), ...col]);
      }
    );

  const getEmptyComponent = () => {
    if (buildings?.length <= 0) {
      return (
        <InfoMessageBox
          label="Kein Gebäude vorhanden. Lege ein Gebäude an."
          isCentered
        >
          <Button
            onClick={() => {
              navigate(`/${routes.buildings}/${buildingsRoutes.add}`);
            }}
            icon={<FaPlus />}
            label="Gebäude"
            className="primary-button small-button"
          />
        </InfoMessageBox>
      );
    }
    if (rows?.length <= 0) {
      return (
        <InfoMessageBox
          label="Keine Wartung vorhanden. Lege deine erste Wartung an."
          isCentered
        >
          <Button
            className="primary-button small-button"
            onClick={() => {
              navigate(`/${routes.maintenance}/${maintenanceRoutes.add}`);
            }}
            icon={<FaPlus />}
            label="Wartung"
          />
        </InfoMessageBox>
      );
    }
    return null;
  };

  useEffect(() => {
    if (!(location.state as any)?.maintenanceId) return;

    const navigationRow = rows.find(
      (row) => row.original.id === (location.state as any).maintenanceId
    );

    if (navigationRow === undefined) return;

    setSelectedMaintenances([navigationRow.original]);
  }, [location.state, maintenances, rows, setSelectedMaintenances]);

  useEffect(() => {
    if (selectedMaintenances && selectedMaintenances.length) {
      rows.forEach((row) => {
        if (
          selectedMaintenances
            .map((selectedRow) => selectedRow.id)
            .includes(row.original.id)
        ) {
          row.toggleRowSelected(true);
        }
      });
    }
  }, [selectedMaintenances, rows]);

  return (
    <div className="min-h-[30vh] overflow-y-auto w-full overflow-x-hidden">
      <table
        {...getTableProps()}
        data-cy="components-maintenances-maintenance-request-table"
        className="w-full overflow-y-auto border-collapse bg-white"
      >
        <thead className="sticky top-0 w-full bg-gray-light text-black-abs">
          {headerGroups.map((headerGroup) => (
            <tr className="h-9" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`${
                    index === 0 && 'border-b-2'
                  } border-r-2 border-white text-left pl-4`}
                >
                  {column.render('Header')}
                  {column.isSorted && (
                    <span>{column.isSortedDesc ? '↓' : '↑'}</span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="overflow-y-auto"
          {...getTableBodyProps()}
          ref={listInnerRef}
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                data-cy="components-maintenances-maintenance-request-table-row"
                className="cursor-pointer hover:bg-gray-lighter hover:text-black-abs text-center h-9 group"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, index) => {
                  if (
                    cell.column.id !== 'selection' &&
                    cell.column.id !== 'details'
                  ) {
                    return (
                      <td
                        className={`text-left ${
                          cell.column.id !== 'dueDate' &&
                          cell.column.id !== 'Check' &&
                          'pl-4'
                        } border-r-2 border-white ${
                          (cell.column as { Header: string }).Header ===
                            'Einzelpreis' && 'text-right pr-2'
                        } ${
                          index === 5 &&
                          `${
                            isMaintenanceComplete(row.original) &&
                            cell.column.id === 'check' &&
                            'bg-red-table/[.2]'
                          }  border-b-2 border-white`
                        }`}
                        {...cell.getCellProps()}
                      >
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            selectMaintenance(row);
                          }}
                          onKeyDown={() => {
                            selectMaintenance(row);
                          }}
                        >
                          {cell.render('Cell')}
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td
                      className={`border-r-2 border-white ${
                        index === 0 &&
                        `${getCheckFieldColor(
                          row.original
                        )} border-b-2 border-white`
                      } group-hover:bg-gray-lighter`}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {getEmptyComponent()}
    </div>
  );
};

export default MaintenanceRequestCreateTable;
