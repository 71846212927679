import { DistanceMatrix } from './distance';
import { GeoCoordinate, GeoDescription } from './geo-coordinate';

export abstract class GeoAdapter {
  public abstract calculateDistanceMatrix(
    sources: (GeoCoordinate | GeoDescription)[],
    destinations: (GeoCoordinate | GeoDescription)[]
  ): Promise<DistanceMatrix>;

  public abstract getCoordinates(
    address: GeoDescription
  ): Promise<GeoCoordinate[]>;

  public abstract getCityFromAddress(address: GeoDescription): Promise<string>;
}
