import { User, toMaintenanceObjectId } from '@warthungshelden/domain/common';

import {
  FallProtection,
  Ladder,
  PersonalProtectionEquipment,
  Railing,
  RopeFallProtectionSystem,
} from '../../maintenance-object';
import {
  MaintenanceObjectPrice,
  MaintenancePrice,
  PriceComponent,
  PriceComponentType,
} from '../maintenance-price';
import { MaintenanceUnitPriceResolver } from '../maintenance-unit-price-resolver';
import {
  MaintenanceObjectPriceInformation,
  MaintenancePriceStrategy,
} from './maintenance-price-strategy';
import { WartungsheldenPriceStrategy } from './wartungshelden-maintenance-price-strategy';

const hasSameType = (
  informationA: MaintenanceObjectPriceInformation,
  informationB: MaintenanceObjectPriceInformation
) => {
  return (
    informationA.object.type.constructor ===
    informationB.object.type.constructor
  );
};

const hasSameManufacturer = (
  informationA: MaintenanceObjectPriceInformation,
  informationB: MaintenanceObjectPriceInformation
) => {
  return informationA.object.manufacturer === informationB.object.manufacturer;
};

const hasSameBuildingProperty = (
  informationA: MaintenanceObjectPriceInformation,
  informationB: MaintenanceObjectPriceInformation
) => {
  return (
    informationA.building?.isPublic === informationB.building?.isPublic &&
    informationA.building?.buildingType === informationB.building?.buildingType
  );
};

const hasSameFallProtectionSystem = (
  objectType: FallProtection,
  objectToCompareType: FallProtection
) => {
  const { system: objectSystem } = objectType;
  const { system: objectToCompareSystem } = objectToCompareType;

  if (!objectSystem || !objectToCompareSystem) {
    return false;
  }

  if (objectToCompareSystem.constructor !== objectSystem.constructor) {
    return false;
  }

  if (objectSystem instanceof RopeFallProtectionSystem) {
    return objectType.location === objectToCompareType.location;
  }

  return true;
};

export const toGroupedObjectInformation = (
  groups: Array<Array<MaintenanceObjectPriceInformation>>,
  information: MaintenanceObjectPriceInformation
) => {
  const groupIndex = groups.findIndex(([firstInformation]) => {
    if (!firstInformation) {
      return firstInformation;
    }

    if (!hasSameType(information, firstInformation)) {
      return false;
    }

    if (
      information.object.type instanceof Ladder ||
      information.object.type instanceof Railing
    ) {
      return true;
    }

    if (!hasSameManufacturer(information, firstInformation)) {
      return false;
    }

    if (!hasSameBuildingProperty(information, firstInformation)) {
      return false;
    }

    const informationType = information.object.type;
    const firstInformationType = firstInformation.object.type;

    if (
      informationType instanceof FallProtection &&
      firstInformationType instanceof FallProtection
    ) {
      return hasSameFallProtectionSystem(informationType, firstInformationType);
    }

    if (
      informationType instanceof PersonalProtectionEquipment &&
      firstInformationType instanceof PersonalProtectionEquipment
    ) {
      return informationType.systemType === firstInformationType.systemType;
    }

    return true;
  });

  if (groupIndex === -1) {
    return [...groups, [information]];
  }

  const newGroups = [...groups];

  groups[groupIndex].push(information);

  return newGroups;
};

function byType(type: PriceComponentType) {
  return (component: PriceComponent) => component.type === type;
}

function toCappedValue(cap: number) {
  return (component) => ({
    ...component,
    value: Math.min(cap, component.value),
  });
}
function getOldAbsoluteFixedPriceComponents(
  oldPrice: MaintenanceObjectPrice,
  type: 'actual' | 'max'
) {
  return [
    ...oldPrice.absoluteFixedPriceComponents[type].map(
      (absoluteFixedPriceComponent) => {
        if (absoluteFixedPriceComponent.type === 'base_price') {
          return {
            ...absoluteFixedPriceComponent,
            value: absoluteFixedPriceComponent.value - 450,
          };
        }
        return absoluteFixedPriceComponent;
      }
    ),
  ];
}

function getNewAbsoluteVariablePriceComponents(
  oldAbsoluteVariablePriceComponents: PriceComponent[],
  oldAbsoluteFixedPriceComponents: PriceComponent[],
  numberOfGroupElements: number
) {
  return oldAbsoluteVariablePriceComponents.map(
    (oldAbsoluteVariablePriceComponent) => {
      const oldFixedPriceValue =
        oldAbsoluteFixedPriceComponents.find(
          (oldAbsoluteFixedPriceComponentActual) =>
            oldAbsoluteFixedPriceComponentActual.type ===
            oldAbsoluteVariablePriceComponent.type
        )?.value ?? 0;

      if (oldAbsoluteVariablePriceComponent.type === 'base_price') {
        return {
          ...oldAbsoluteVariablePriceComponent,
          value: oldAbsoluteVariablePriceComponent.value + oldFixedPriceValue,
        };
      } else if (
        [
          'documentation_missing',
          'public_building',
          'location_surcharge',
          'no_easy_access',
          'single_anchor_surcharge',
          'rail_system_surcharge',
          'mass_discount',
        ].includes(oldAbsoluteVariablePriceComponent.type)
      ) {
        return {
          ...oldAbsoluteVariablePriceComponent,
          value:
            oldAbsoluteVariablePriceComponent.value +
            oldFixedPriceValue / numberOfGroupElements,
        };
      }
      return oldAbsoluteVariablePriceComponent;
    }
  );
}

export class FixedFixedPriceStrategy implements MaintenancePriceStrategy {
  private readonly wartungsheldenStrategy: WartungsheldenPriceStrategy;

  constructor(private unitPriceResolver: MaintenanceUnitPriceResolver) {
    this.wartungsheldenStrategy = new WartungsheldenPriceStrategy(
      unitPriceResolver
    );
  }

  public async calculatePriceForObjects(
    user: User,
    information: MaintenanceObjectPriceInformation[]
  ): Promise<MaintenancePrice> {
    const oldPrice = await this.wartungsheldenStrategy.calculatePriceForObjects(
      user,
      information
    );

    const groupedComponents = information?.reduce(
      toGroupedObjectInformation,
      []
    );

    const objectPrices = oldPrice.objectPrices.map((oldPrice) => {
      const sameTypeGroup = groupedComponents?.find((group) => {
        return group
          .map((priceObject) => toMaintenanceObjectId(priceObject.object))
          .includes(oldPrice.objectId);
      });

      const oldAbsoluteVariablePriceComponentsActual = [
        ...oldPrice.absoluteVariablePriceComponents.actual,
      ];

      const oldAbsoluteFixedPriceComponentsActual =
        getOldAbsoluteFixedPriceComponents(oldPrice, 'actual');

      const oldAbsoluteVariablePriceComponentsMax = [
        ...oldPrice.absoluteVariablePriceComponents.max,
      ];

      const oldAbsoluteFixedPriceComponentsMax =
        getOldAbsoluteFixedPriceComponents(oldPrice, 'max');

      const newAbsoluteVariablePriceComponentsActual =
        getNewAbsoluteVariablePriceComponents(
          oldAbsoluteVariablePriceComponentsActual,
          oldAbsoluteFixedPriceComponentsActual,
          sameTypeGroup?.length || 1
        );

      const newAbsoluteVariablePriceComponentsMax =
        getNewAbsoluteVariablePriceComponents(
          oldAbsoluteVariablePriceComponentsMax,
          oldAbsoluteFixedPriceComponentsMax,
          sameTypeGroup?.length || 1
        );

      const absoluteFixedPriceComponentsActual =
        oldPrice.absoluteFixedPriceComponents.actual
          .filter(byType('base_price'))
          .map(toCappedValue(450));

      const absoluteFixedPriceComponentsMax =
        oldPrice.absoluteFixedPriceComponents.max
          .filter(byType('base_price'))
          .map(toCappedValue(450));

      const absoluteVariablePriceComponentsActual = [
        ...newAbsoluteVariablePriceComponentsActual,
      ];

      const absoluteVariablePriceComponentsMax = [
        ...newAbsoluteVariablePriceComponentsMax,
      ];

      return oldPrice.copyWith({
        unsortedFixedPriceComponents: {
          actual: absoluteFixedPriceComponentsActual,
          max: absoluteFixedPriceComponentsMax,
        },
        unsortedVariablePriceComponents: {
          actual: absoluteVariablePriceComponentsActual,
          max: absoluteVariablePriceComponentsMax,
        },
      });
    });

    return oldPrice.copyWith({
      strategy: 'fixed-fix-price',
      objectPrices: objectPrices,
    });
  }
}
