import { Entity } from '@warthungshelden/domain/common';
import { isDefined } from 'class-validator';

export class Address extends Entity {
  public readonly street?: string | null;
  public readonly postalCode?: string | null;
  public readonly city?: string | null;
  public readonly countryISO?: string | null;

  constructor(config: {
    street?: string | null;
    postalCode?: string | null;
    city?: string | null;
    countryISO?: string | null;
  }) {
    super();
    this.street = config.street;
    this.postalCode = config.postalCode;
    this.city = config.city;
    this.countryISO = config.countryISO;
  }

  public get address(): string {
    const city =
      this.city && this.postalCode
        ? `${this.postalCode} ${this.city}`
        : this.city
        ? this.city
        : this.postalCode;

    return [this.street, city, this.countryISO].filter(isDefined).join(', ');
  }
}
