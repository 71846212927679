import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

export class MustAcceptCustomerNumber<
  T extends {
    absCustomerNumber?: string | null;
    hasAcceptedCustomerNumber?: boolean | null;
  }
> extends DomainRule<T, T> {
  constructor() {
    super();
  }

  protected async rule(entity: T): Promise<T> {
    if (entity.absCustomerNumber && !entity.hasAcceptedCustomerNumber) {
      throw new DomainRuleNotSatisfiedError(MustAcceptCustomerNumber);
    }
    return entity;
  }
}
