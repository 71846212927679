import { Field, FieldAttributes } from 'formik';
import React from 'react';

import Tip from '../Tip';

export type InputFieldProps<T> = FieldAttributes<T> & {
  min?: number | null;
  label?: string | null;
  isInvalid?: boolean;
  isRequired?: boolean;
  help?: React.ReactElement | string;
  labelStyle?: string;
};

const InputField: React.FC<
  React.PropsWithChildren<InputFieldProps<unknown>>
> = ({
  name,
  label,
  help,
  type,
  disabled,
  placeholder,
  min,
  isRequired,
  isInvalid,
  labelStyle,
}) => (
  <div className="flex h-12 items-center w-full">
    {label && (
      <label htmlFor={name} className={`font-bold mr-4 ${labelStyle}`}>
        {`${label}${isRequired ? '*' : ''}`}
      </label>
    )}
    <div className="flex relative z-0 w-full">
      <Field
        data-cy="components-basics-input-field"
        id={name}
        name={name}
        as="input"
        type={type}
        className={`block border-2 rounded-lg px-4 py-2 ${
          type === 'number' ? 'w-24' : 'w-full'
        } ${isInvalid ? 'border-red' : 'border-gray'} ${
          disabled ? 'bg-gray-lighter text-gray-dark' : 'bg-white'
        }`}
        disabled={disabled}
        placeholder={placeholder}
        min={min}
        autoComplete="off"
      />
      {help && (
        <div className="absolute w-min inset-y-0 right-2 flex items-center z-1">
          <Tip disabled={disabled || false}>{help}</Tip>
        </div>
      )}
    </div>
  </div>
);
export default InputField;

InputField.defaultProps = {
  label: null,
  labelStyle: 'w-64',
  min: null,
  isRequired: false,
  isInvalid: false,
  help: undefined,
};
