import { addMonths, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { Formik } from 'formik';
import React, { useContext, useMemo } from 'react';
import { FaPaperPlane } from 'react-icons/fa';

import { Toggle } from '../../../components';
import Button from '../../../components/Basics/Buttons/Button';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import {
  ErrorMessageBox,
  InfoMessageBox,
} from '../../../components/Basics/MessageBox';
import { RoutePlanContext } from '../../../contexts/RoutePlanContext';
import useInstallers, {
  useOpenMaintenancesBetween,
} from '../../../services/api/ressource-planning/installers-api';
import { PlanFormikValues } from './InstallationPlanContainer';
import FilterButton from './components/FilterButton';
import RoutesInfo from './components/RoutesInfo';
import RouteOverview from './components/route/RouteOverview';

const InstallationPlanPreview = () => {
  const {
    routes,
    considerCalendar,
    setConsiderCalendar,
    selectedInstallers,
    setSelectedInstallers,
    selectedOpenMaintenances,
    setSelectedOpenMaintenances,
    installerAreaId,
    deliveryDateMonth,
    onPlanRoute,
    setPreferABSInstallers,
    preferABSInstallers,
    clearRoutePlan,
    invalidZipCodes,
  } = useContext(RoutePlanContext);

  const { data: openMaintenances, isLoading: isLoadingOpenMaintenances } =
    useOpenMaintenancesBetween(
      installerAreaId,
      deliveryDateMonth &&
        startOfDay(startOfMonth(addMonths(deliveryDateMonth, 1))),
      deliveryDateMonth &&
        startOfDay(endOfMonth(addMonths(deliveryDateMonth, 1)))
    );

  const { data: installers, isLoading: isLoadingInstallers } = useInstallers();

  const shoInvalidZipCodesError = invalidZipCodes.length > 0;

  const initialValues: PlanFormikValues = {
    installerId: undefined,
    deliveryDate: undefined,
  };

  const hasSelectedInstallersAndZipCodes = Boolean(
    selectedInstallers?.length && selectedOpenMaintenances?.length
  );

  const memoizedOpenMaintenances = useMemo(
    () => openMaintenances ?? undefined,
    [openMaintenances]
  );

  const installersWithAbsMarking = useMemo(
    () =>
      installers?.map((installer) => ({
        ...installer,
        name: installer.isAbsInstaller ? `${installer.name}*` : installer.name,
      })) ?? [],
    [installers]
  );

  if (openMaintenances && !openMaintenances.length) {
    return (
      <>
        <InfoMessageBox label="Für die Eingabe liegen keine offenen Daten vor." />
        <LoadingSpinner isLoading={isLoadingOpenMaintenances}>
          Offene Wartungen werden geladen...
        </LoadingSpinner>
      </>
    );
  }

  if (!installersWithAbsMarking || !memoizedOpenMaintenances) {
    return (
      <div className="flex w-full h-full flex-col items-center justify-center text-blue-abs">
        <FaPaperPlane className="text-2xl" />
        Plan wird hier angezeigt...
        <LoadingSpinner isLoading={isLoadingOpenMaintenances}>
          Offene Wartungen werden geladen...
        </LoadingSpinner>
      </div>
    );
  }

  return (
    <div className="my-2">
      {shoInvalidZipCodesError && (
        <ErrorMessageBox
          label={`PLZ ${invalidZipCodes
            .slice(0, -1)
            .join(', ')} ${invalidZipCodes.slice(
            -1
          )} ist ungültig und nicht in Routenplan enthalten`}
        />
      )}

      <div className="w-full h-full flex flex-col space-y-4 px-2 pb-12 pt-2">
        {Boolean(memoizedOpenMaintenances) && (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
            onSubmit={() => {
              if (deliveryDateMonth) {
                onPlanRoute();
              }
            }}
          >
            {({ submitForm }) => {
              const isValid = () =>
                selectedInstallers &&
                selectedOpenMaintenances &&
                deliveryDateMonth;

              return (
                <div
                  className="flex flex-1 flex-col space-y-2 "
                  data-cy="filter-bar"
                >
                  {Boolean(openMaintenances) && (
                    <>
                      <div className="flex flex-1 space-x-2">
                        <div className="flex flex-1 items-center pr-8 w-1/3">
                          <FilterButton
                            label="PLZ"
                            items={memoizedOpenMaintenances}
                            uniqueKeys={['orderId']}
                            contentKeys={['zipcode', 'duration']}
                            headerLabels={['PLZ', 'Aufwand']}
                            selectedItems={selectedOpenMaintenances}
                            setSelectedItems={(items) => {
                              clearRoutePlan();
                              setSelectedOpenMaintenances(items);
                            }}
                            disabled={
                              isLoadingOpenMaintenances ||
                              !isValid ||
                              !hasSelectedInstallersAndZipCodes
                            }
                          />
                        </div>
                        <div className="flex flex-1 items-center pr-8 w-1/3">
                          <FilterButton
                            label="Monteur"
                            items={installersWithAbsMarking}
                            contentKeys={['name']}
                            headerLabels={['Monteure']}
                            selectedItems={selectedInstallers}
                            setSelectedItems={(items) => {
                              clearRoutePlan();
                              setSelectedInstallers(items);
                            }}
                            disabled={
                              isLoadingInstallers ||
                              !isValid ||
                              !hasSelectedInstallersAndZipCodes
                            }
                          />
                        </div>
                        <div className="flex flex-1 items-center w-1/3">
                          <Toggle
                            onChange={() => {
                              clearRoutePlan();
                              setConsiderCalendar(!considerCalendar);
                            }}
                            name="considerCalendar"
                            checked={considerCalendar}
                            label="Kalender berücksichtigen"
                            disabled={
                              !isValid || !hasSelectedInstallersAndZipCodes
                            }
                          />
                        </div>
                      </div>

                      <div className="flex flex-1 space-x-2 justify-start items-center">
                        <div className="flex flex-1 items-center w-1/3 pr-8">
                          <Button
                            disabled={
                              !isValid || !hasSelectedInstallersAndZipCodes
                            }
                            label="Touren berechnen"
                            onClick={async () => {
                              await submitForm();
                            }}
                            className="primary-button"
                          />
                        </div>
                        <div className="flex flex-1 items-center w-1/3 pr-8" />
                        <div className="flex flex-1 items-center w-1/3">
                          <Toggle
                            onChange={() => {
                              clearRoutePlan();
                              setPreferABSInstallers(!preferABSInstallers);
                            }}
                            name="preferAbsInstallers"
                            checked={preferABSInstallers}
                            label="ABS Monteure bevorzugen"
                            disabled={
                              !isValid || !hasSelectedInstallersAndZipCodes
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            }}
          </Formik>
        )}
        <RoutesInfo />
        {routes?.map((route, index) => (
          <div data-cy={`route-${index}`}>
            <RouteOverview
              route={route}
              installers={installersWithAbsMarking}
              key={JSON.stringify(route)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

InstallationPlanPreview.defaultProps = {
  routes: undefined,
  installers: undefined,
  openMaintenances: undefined,
};

export default InstallationPlanPreview;
