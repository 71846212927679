import React from 'react';
import { FaCheck } from 'react-icons/fa';

type ToggleProps = {
  label: string;
  boldLabel?: boolean;
  onChange: () => void;
  checked: boolean;
  name: string;
  disabled?: boolean;
};

const Toggle: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  label,
  boldLabel = false,
  onChange,
  checked,
  name,
  disabled,
}) => {
  let backgroundColor = 'bg-gray';
  if (checked) {
    backgroundColor = 'bg-blue-abs';
  }
  if (disabled) {
    backgroundColor = 'bg-gray-light';
  }
  return (
    <div className="flex">
      <label
        htmlFor={name}
        className={`flex items-center ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        } ${boldLabel && 'font-bold'}`}
      >
        <input
          type="checkbox"
          id={name}
          name={name}
          className="hidden"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        <div
          className={`block relative ${backgroundColor} w-14 h-8 rounded-full relative mr-4`}
        >
          <div
            className={`dot absolute top-1 w-6 h-6 rounded-full bg-white transition ${
              checked ? 'right-1' : 'left-1'
            }`}
          />
          <div className={`absolute top-2 ${checked ? 'right-2' : 'left-2'}`}>
            {checked && (
              <FaCheck
                className={`${disabled ? 'text-gray-dark' : 'text-blue-abs'}`}
              />
            )}
          </div>
        </div>
        <span className={`${disabled ? 'text-gray-dark' : 'text-black'}`}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default Toggle;

Toggle.defaultProps = {
  disabled: false,
  boldLabel: false,
};
