import React from 'react';

interface ToolbarProps {
  title: string;
  status?: string;
}

const Toolbar: React.FC<React.PropsWithChildren<ToolbarProps>> = ({
  title,
  status,
  children,
}) => (
  <div className="p-4 flex justify-between">
    <div className="text-4xl">{title}</div>
    <div className="flex items-center">
      {status && (
        <>
          <div>Status:</div>
          <div
            className={`w-48 bg-gray-200 text-center ml-4 p-2 ${
              children && 'mr-4'
            }`}
          >
            {status}
          </div>
        </>
      )}
      {children}
    </div>
  </div>
);

Toolbar.defaultProps = {
  status: undefined,
};

export default Toolbar;
