import React from 'react';

import TestProcedureCheckboxSection from './TestProcedureCheckboxSection';

interface SystemDataTestProcedureCheckboxesProps {
  namePrefix: string;
}

const SystemDataTestProcedureCheckboxes: React.FC<
  SystemDataTestProcedureCheckboxesProps
> = ({ namePrefix }) => (
  <div>
    <div className="my-4">
      <TestProcedureCheckboxSection
        headline="EAP & Seilsysteme"
        checkboxes={[
          {
            name: `${namePrefix}.eapAndRopeSystem.hasVisualAndFunctionCheck`,
            label: 'Sicht- und Funktionsprüfung',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasJoggingTest`,
            label: 'Rüttelprobe',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasTorqueControl`,
            label: 'Drehmomentkontrolle',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasPunctualPiecesByMeansOfTensile`,
            label:
              'Punktuell X Stück mittels Zugversuches axial - X kN - 15Sek.',
            shortLabel: 'Zugversuch',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasSelectivelyPiecesByMeansOfRoofOpeningByRoofer`,
            label:
              'Punktuell 20-30% (mind. 2 Stück) pro Typ pro Dachfläche mittels Dachöffnungen durch Dachdecker',
            shortLabel: '20-30% Dachöffnung',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasExposePiecesPunctual`,
            label:
              'Punktuell 10% (mind. 2 Stück) pro Typ pro Dachfläche freilegen (Besen und Schaufel mitnehmen).',
            shortLabel: '10% Freilegen',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasTiltDowelControl`,
            label: 'Kippdübelkontrolle (von unten einsehbar)',
            shortLabel: 'Kippdübelkontrolle',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasCheckSuperimposedLoad`,
            label: 'Auflast prüfen',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasLockOnTopControlByMeansOfChainHoist`,
            label: 'Lock OnTop Control mittels Kettenzug',
          },
          {
            name: `${namePrefix}.eapAndRopeSystem.hasCheckWelds`,
            label: 'Verschweißungen prüfen',
          },
        ]}
      />
    </div>
    <div className="my-4">
      <TestProcedureCheckboxSection
        headline="Seilsysteme"
        checkboxes={[
          {
            name: `${namePrefix}.ropeSystem.hasMountSystemSigns`,
            label: 'Bitte Systemschild(er) montieren!',
            shortLabel: 'Systemschild montieren',
          },
          {
            name: `${namePrefix}.ropeSystem.hasStickSystemLabels`,
            label: 'Bitte Systemschildetikett(en) kleben!',
            shortLabel: 'Systemschild kleben',
          },
        ]}
      />
    </div>
    <div className="my-4">
      <TestProcedureCheckboxSection
        headline="Montagedokumentation"
        checkboxes={[
          {
            name: `${namePrefix}.infoAssemblyDocu.hasAssemblyDocu`,
            label: 'Montagedokumentation vorhanden',
            shortLabel: 'Montagedokumentation',
          },
          {
            name: `${namePrefix}.infoAssemblyDocu.hasNoAssemblyDocu`,
            label: 'Montagedokumentation nicht vorhanden',
            shortLabel: 'Keine Montagedokumentation',
          },
          {
            name: `${namePrefix}.infoAssemblyDocu.hasAssemblyByABS`,
            label: 'Montage durch ABS (komplett)',
            shortLabel: 'Montage durch ABS',
          },
          {
            name: `${namePrefix}.infoAssemblyDocu.hasDocuForRopeAssemblyForPillars`,
            label:
              'Seilendmontage durch ABS, für Stützen Dokumentation vorhanden',
            shortLabel: 'Stützen dokumentiert',
          },
          {
            name: `${namePrefix}.infoAssemblyDocu.hasNoDocuForRopeAssemblyForPillars`,
            label:
              'Seilendmontage durch ABS, für Stützen keine Dokumentation vorhanden',
            shortLabel: 'Keine Stützen dokumentiert',
          },
        ]}
      />
    </div>
  </div>
);

export default SystemDataTestProcedureCheckboxes;
