import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { FaCheck, FaEdit } from 'react-icons/fa';
import { useOutletContext, useParams } from 'react-router-dom';

import { distinct } from '../../../set';
import { Card, PageHeader } from '../../components';
import Button from '../../components/Basics/Buttons/Button';
import LoadingSpinner from '../../components/Basics/Loaders/LoadingSpinner';
import { WarningMessageBox } from '../../components/Basics/MessageBox';
import ConfirmationModal from '../../components/Basics/Modals/ConfirmationModal';
import MaintenanceObjectReadOnly from '../../components/Maintenance/ReadOnly/MaintenanceObjectReadOnly';
import MaintenanceObjectTypeReadOnly from '../../components/Maintenance/ReadOnly/MaintenanceObjectTypeReadOnly';
import { readableServiceType } from '../../constants/MaintenanceConstants';
import { SessionContext } from '../../contexts/SessionContext';
import { useMaintenanceDocumentations } from '../../services/api/maintenance-documentation/maintenance-documentation-api';
import { useMaintenanceObjects } from '../../services/api/maintenance-objects/maintenance-objects-api';
import { useUpdateMaintenanceOffer } from '../../services/api/maintenance-offers/maintenance-offer-api';
import { maintenanceObjectDtoToFormikMaintenanceType } from '../../services/maintenanceService';
import { formatToMonth } from '../../services/timeDateService';
import { RequestDetailsOutlet } from './RequestMainPage';

const RequestMaintenanceDetailsPage = () => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);
  const [isCloseDocumentUploadModalOpen, setIsCloseDocumentUploadModalOpen] =
    useState(false);

  const { currentRequest, currentOffer } =
    useOutletContext<RequestDetailsOutlet>();

  const { maintenanceId } = useParams();
  const { data: maintenances } = useMaintenanceObjects();

  const currentMaintenance = maintenances?.find(
    ({ id }) => id === maintenanceId
  );

  const { data: existingMaintenanceDocumentations, isLoading } =
    useMaintenanceDocumentations();

  function without(id: string) {
    return (current: string) => current !== id;
  }

  const { mutateAsync: updateMaintenanceOffer, isLoading: isUpdatingOffer } =
    useUpdateMaintenanceOffer();

  const isDocumentUploadEnabled = Boolean(
    currentMaintenance?.id &&
      currentOffer?.objectsWithOpenDocumentUpload.includes(
        currentMaintenance?.id
      )
  );

  const onToggleDocumentationUpload = async () => {
    if (!currentOffer || !currentMaintenance) {
      return;
    }

    const objectsWithOpenDocumentUpload = isDocumentUploadEnabled
      ? currentOffer.objectsWithOpenDocumentUpload.filter(
          without(currentMaintenance.id)
        )
      : distinct([
          ...currentOffer.objectsWithOpenDocumentUpload,
          currentMaintenance.id,
        ]);

    await updateMaintenanceOffer({
      id: currentOffer?.id,
      objectsWithOpenDocumentUpload,
    });
  };

  if (currentMaintenance && currentRequest && !isLoading) {
    return (
      <div className="w-full">
        <Formik
          enableReinitialize
          initialValues={{
            type: readableServiceType(currentMaintenance.type).label,
            buildingId: currentMaintenance.buildingId,
            frequency: currentMaintenance.frequency,
            dueDate: currentMaintenance.dueDate
              ? formatToMonth(currentMaintenance?.dueDate)
              : undefined,
            maintenanceObjects: currentMaintenance
              ? maintenanceObjectDtoToFormikMaintenanceType(
                  currentMaintenance,
                  existingMaintenanceDocumentations
                )
              : [],
          }}
          onSubmit={() => {
            // Nothing to submit
          }}
        >
          {() => (
            <Form>
              <Card>
                {!isMaintenanceTeamMemberAdmin && isDocumentUploadEnabled && (
                  <div className="mb-8">
                    <WarningMessageBox
                      headline="Zu dieser Absturzsicherung liegt uns keine aussagekräftige Montagedokumentation vor."
                      label="Bevor es mit der Wartung weitergeht, hast Du hier die Möglichkeit eine Montagedokumentation nachzureichen. Nutze jetzt diese Chance und spare Dir dadurch Zeit und Kosten."
                    >
                      <Button
                        className="small-button confirmation-button bg-orange"
                        onClick={() => setIsCloseDocumentUploadModalOpen(true)}
                        label="Dokumentenupload schließen"
                        icon={<FaCheck />}
                      />
                    </WarningMessageBox>
                  </div>
                )}
                <PageHeader>
                  <div className="flex flex-row items-center text-4xl text-bold ">
                    <FaEdit size={45} className="pr-4" />
                    Erfassung des Bedarfs
                  </div>
                </PageHeader>
                <div className="text-basic mb-6">
                  Grundlegende Angaben zur Erfassung der Wartungsaufgabe.
                </div>
                <MaintenanceObjectReadOnly />
                <MaintenanceObjectTypeReadOnly
                  isMaintenanceTeamMemberAdmin={isMaintenanceTeamMemberAdmin}
                  request={currentRequest}
                  offer={currentOffer}
                  maintenance={currentMaintenance}
                  isDocumentUploadEnabled={isDocumentUploadEnabled}
                  onToggleDocumentationUpload={onToggleDocumentationUpload}
                />
              </Card>
            </Form>
          )}
        </Formik>
        <ConfirmationModal
          isVisible={isCloseDocumentUploadModalOpen}
          onCancel={() => setIsCloseDocumentUploadModalOpen(false)}
          onAccept={async () => {
            await onToggleDocumentationUpload();
            setIsCloseDocumentUploadModalOpen(false);
          }}
          heading="Dokumentenupload schließen"
          width="one-third"
        >
          Möchtest Du den Dokumentenupload für diese Wartungsaufgabe
          abschließen?
          <br />
          Danach können hier keine weiteren Dokumente ergänzt werden.
        </ConfirmationModal>
        <LoadingSpinner isLoading={isUpdatingOffer} />
      </div>
    );
  }
  return null;
};

export default RequestMaintenanceDetailsPage;
