import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';

import { ProvidedServiceEnum } from '@wartungshelden/shared-types';

import { labelToProvidedService } from '../../../pages/UserPages/maintenance/MaintenanceObject';
import { FormikFallProtection } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikLadder } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikLadder';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import { FormikPPE } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikPPE';
import Button from '../../Basics/Buttons/Button';
import InventoryRow from './InventoryRow';

type InventoryListProps = {
  disabled?: boolean;
  isUpdateForm?: boolean;
  validityShown?: boolean;
  maintenanceOwnerId?: string;
  maintenanceObjectId?: string;
};

const InventoryList: React.FC<React.PropsWithChildren<InventoryListProps>> = ({
  disabled = false,
  isUpdateForm = false,
  validityShown = false,
  maintenanceOwnerId,
  maintenanceObjectId,
}) => {
  const { values, setFieldValue } =
    useFormikContext<FormikMaintenanceWizardValues>();

  const [addedFiles, setAddedFiles] = useState<File[]>([]);

  const [rows, setRows] = useState<React.ReactNode[]>(
    values.maintenanceObjects.map((_, index) => (
      <InventoryRow
        index={index}
        maintenanceOwnerId={maintenanceOwnerId}
        maintenanceObjectId={maintenanceObjectId}
        validityShown={validityShown}
        setAddedFiles={setAddedFiles}
        addedFiles={addedFiles}
      />
    ))
  );

  useEffect(() => {
    setRows(
      values.maintenanceObjects.map((_, index) => (
        <InventoryRow
          index={index}
          maintenanceOwnerId={maintenanceOwnerId}
          maintenanceObjectId={maintenanceObjectId}
          validityShown={validityShown}
          setAddedFiles={setAddedFiles}
          addedFiles={addedFiles}
        />
      ))
    );
  }, [
    maintenanceObjectId,
    maintenanceOwnerId,
    validityShown,
    values.maintenanceObjects,
  ]);

  const setMaintenanceObjectType = () => {
    const type = labelToProvidedService(values.type || '');
    if (type === ProvidedServiceEnum.FALL_PROTECTION) {
      const fallProtection: FormikFallProtection = {
        name: '',
        climbLadder: false,
        ladder: false,
        other: false,
        otherText: '',
        liftingPlatform: false,
        roofExit: false,
        scaffold: false,
        selectedFiles: [],
      };
      setFieldValue('maintenanceObjects', [
        ...values.maintenanceObjects,
        fallProtection,
      ]);
    } else if (type === ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT) {
      const ppe: FormikPPE = {
        name: '',
        manufacturer: undefined,
        systemType: 'default',
        category: '',
        quantity: 0,
      };
      setFieldValue('maintenanceObjects', [...values.maintenanceObjects, ppe]);
    } else if (type === ProvidedServiceEnum.LADDER) {
      const ladder: FormikLadder = {
        name: '',
        length: 0,
        numberOfPlatforms: 0,
      };
      setFieldValue('maintenanceObjects', [
        ...values.maintenanceObjects,
        ladder,
      ]);
    }
  };

  return (
    <>
      <div className="grid gap-y-2 mb-2">
        {values.type &&
          labelToProvidedService(values.type) ===
            ProvidedServiceEnum.FALL_PROTECTION && (
            <div className="grid grid-cols-6 bg-gray-light p-2 gap-x-2">
              <div>Systemtyp*</div>
              <div>Hersteller</div>
              <div>Eigenschaften</div>
              <div>Interne Bezeichnung</div>
              <div>Dateien</div>
            </div>
          )}
        {values.type &&
          labelToProvidedService(values.type) ===
            ProvidedServiceEnum.PERSONAL_PROTECTION_EQUIPMENT && (
            <div className="grid grid-cols-5 bg-gray-light p-2 gap-x-2">
              <div>Typ*</div>
              <div>Hersteller</div>
              <div>Anzahl</div>
              <div>Interne Bezeichnung</div>
            </div>
          )}
        {values.type &&
          labelToProvidedService(values.type) ===
            ProvidedServiceEnum.LADDER && (
            <div className="grid grid-cols-4 bg-gray-light p-2 gap-x-2">
              <div>Länge</div>
              <div>Interne Bezeichnung</div>
            </div>
          )}
        <FieldArray name="maintenanceObjects">
          {() => {
            return rows.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`row-${index}`}>
                {/* TODO Remove this when the read only designs are ready */}
                {/* @ts-ignore */}
                {React.cloneElement(row, {
                  disabled,
                  maintenanceOwnerId,
                })}
              </div>
            ));
          }}
        </FieldArray>
      </div>
      {!disabled && !isUpdateForm && (
        <Button
          className="primary-button small-button"
          onClick={() => {
            setMaintenanceObjectType();
            setRows((prevRows) => [
              ...prevRows,
              <InventoryRow
                index={prevRows.length}
                setAddedFiles={setAddedFiles}
                addedFiles={addedFiles}
              />,
            ]);
          }}
          icon={<FaPlusCircle />}
          label={`Weitere ${values.type}`}
        />
      )}
    </>
  );
};

InventoryList.defaultProps = {
  disabled: undefined,
  isUpdateForm: undefined,
  validityShown: undefined,
  maintenanceOwnerId: undefined,
  maintenanceObjectId: undefined,
};

export default InventoryList;
