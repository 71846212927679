import React from 'react';

const CheckBox: React.FC<
  React.PropsWithChildren<{
    checked?: boolean;
    isRequired?: boolean;
    label?: string;
    onChange?: (value) => void;
    disabled?: boolean;
    checkboxAlignment?: 'items-center' | 'items-start';
  }>
> = ({
  checked,
  onChange,
  label,
  isRequired,
  disabled,
  checkboxAlignment,
  ...props
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={`inline-flex ${checkboxAlignment} cursor-pointer`}>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={`w-5 h-5 text-blue-abs rounded-md ${
        disabled && 'bg-gray/[.5] border-gray'
      }`}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
    {label && (
      <span
        className="ml-2"
        data-cy="components-basics-input-check-field-field"
      >
        {label}
        {isRequired ? '*' : ''}
      </span>
    )}
  </label>
);

CheckBox.defaultProps = {
  checked: false,
  isRequired: false,
  disabled: false,
  label: undefined,
  onChange: undefined,
  checkboxAlignment: 'items-center',
};

export default CheckBox;
