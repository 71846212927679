import { FieldArray, Formik } from 'formik';
import Tooltip from 'rc-tooltip';
import React, { useContext } from 'react';

import { JobDto, RouteDto } from '@wartungshelden/shared-types';

import { Button } from '../../../../../../components';
import RadioButtonField from '../../../../../../components/Basics/Inputs/RadioButtonField';
import { InfoMessageBox } from '../../../../../../components/Basics/MessageBox';
import Modal from '../../../../../../components/Basics/Modals/Modal';
import { RoutePlanContext } from '../../../../../../contexts/RoutePlanContext';

interface RouteActionsAddModalProps {
  route: RouteDto;
  showModal: boolean;
  onCancel: () => void;
  onSave: (jobs: JobDto[]) => void;
}

const RouteActionsAddModal: React.FC<RouteActionsAddModalProps> = ({
  route,
  showModal,
  onCancel,
  onSave,
}) => {
  const initialState = {
    jobs: route.jobs.map<JobDto>((job) => {
      return {
        ...job,
        status:
          job.contactPreference === 'phone'
            ? 'scheduling_phone'
            : 'scheduling_written',
      };
    }),
  };

  const { getNoteForMaintenance } = useContext(RoutePlanContext);

  return (
    <Modal isOpen={showModal} headline="Tour akzeptieren" center>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validateOnMount
        onSubmit={(values) => onSave(values.jobs)}
      >
        {({ submitForm, isValid, values }) => {
          return (
            <div data-cy="accept-route-modal">
              <div>
                <div className="grid grid-cols-3 gap-x-4 gap-y-2">
                  <div className="flex justify-center font-bold">
                    Auftragsnummer
                  </div>
                  <div className="flex justify-center font-bold">
                    Kundentermin ausmachen via
                  </div>
                  <div className="flex justify-center font-bold">Notizen</div>

                  <FieldArray
                    name="jobs"
                    render={() => {
                      return values?.jobs.map((job, index) => {
                        return (
                          <>
                            <div className="flex justify-center items-end">
                              {job.orderId}
                            </div>
                            <div className="flex justify-center items-center space-x-10">
                              <RadioButtonField
                                label="E-mail"
                                name={`jobs.[${index}].status`}
                                value="scheduling_written"
                                disabled={!!job.contactPreference}
                              />
                              <RadioButtonField
                                label="Telefon"
                                name={`jobs.[${index}].status`}
                                value="scheduling_phone"
                                disabled={!!job.contactPreference}
                              />
                            </div>
                            <div className="flex justify-center items-end">
                              {getNoteForMaintenance(job.orderId) && (
                                <Tooltip
                                  placement="bottom"
                                  trigger="hover"
                                  overlay={getNoteForMaintenance(job.orderId)}
                                  overlayInnerStyle={{
                                    padding: 12,
                                    maxWidth: '18rem',
                                  }}
                                >
                                  <div>Notiz</div>
                                </Tooltip>
                              )}
                            </div>
                          </>
                        );
                      });
                    }}
                  />
                </div>
              </div>
              <div className="mt-8 mb-16">
                <InfoMessageBox label="Hinweis">
                  <div>
                    Folgende Daten ändern sich mit Bestätigen der Tour bei
                    SelectLine
                  </div>
                  <ul className="ml-8 list-disc">
                    <li>BVStatus</li>
                    <li>Liefertermin</li>
                    <li>Monteur</li>
                  </ul>
                </InfoMessageBox>
              </div>
              <div className="flex justify-around mt-4">
                <Button onClick={onCancel} className="outline-button mr-2">
                  Abbrechen
                </Button>
                <Button disabled={!isValid} onClick={submitForm}>
                  Tour akzeptieren
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default RouteActionsAddModal;
