import { Form, Formik } from 'formik';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import FormWrapper from '../../components/Basics/FormWrapper';
import InputField from '../../components/Basics/Inputs/InputField';
import Toggle from '../../components/Basics/Inputs/Toggle';
import Card from '../../components/Basics/Layouts/Card';
import PageHeader from '../../components/Basics/Layouts/PageHeader';
import BuildingTypeSelectField from '../../components/Buildings/BuildingTypeSelectField';
import { RequestDetailsOutlet } from './RequestMainPage';

type AdminBuildingDetailsPageProps = {
  disabled?: boolean;
};

const RequestBuildingDetailsPage: React.FC<
  React.PropsWithChildren<AdminBuildingDetailsPageProps>
> = () => {
  const { buildingId } = useParams();

  const { currentBuildings, currentRequest } =
    useOutletContext<RequestDetailsOutlet>();

  const currentBuilding = currentBuildings?.find(({ id }) => id === buildingId);

  if (currentBuilding && currentRequest) {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          buildingName: currentBuilding.name,
          buildingType: currentBuilding.buildingType,
          addressLine1: currentBuilding?.address?.addressLine1,
          addressLine2: currentBuilding?.address?.addressLine2,
          postalCode: currentBuilding?.address?.postalCode,
          city: currentBuilding?.address?.city,
          country: currentBuilding?.address?.countryISO,
          isPublic: currentBuilding.isPublic,
          firstName: currentBuilding?.contact?.firstName,
          lastName: currentBuilding?.contact?.lastName,
          position: currentBuilding?.contact?.position,
          email: currentBuilding?.contact?.email,
          phone: currentBuilding?.contact?.phone,
          mobilePhone: currentBuilding?.contact?.mobilePhone,
        }}
        onSubmit={() => {}}
      >
        {({ values }) => (
          <Form>
            <Card>
              <PageHeader>Gebäudedetails</PageHeader>

              <FormWrapper>
                <InputField label="Gebäudename*" name="buildingName" disabled />
                <BuildingTypeSelectField disabled />
                <InputField
                  label="Straße, Hausnummer"
                  name="addressLine1"
                  disabled
                />
                <InputField label="Adresszusatz" name="addressLine2" disabled />
                <InputField label="Postleitzahl" name="postalCode" disabled />
                <InputField label="Stadt" name="city" disabled />
                <InputField label="Land" name="country" disabled />
              </FormWrapper>

              <Toggle
                label="Öffentliches Gebäude (Körperschaft öffentlichen Rechts), z.B.
                    Städte, Gemeinden, Kommunen, Wohnungsbaugesellschaften"
                name="isPublic"
                checked={values.isPublic}
                onChange={() => {}}
                disabled
              />
            </Card>
            <Card>
              <PageHeader>Ansprechpartner vor Ort</PageHeader>
              <FormWrapper>
                <InputField label="Vorname" name="firstName" disabled />
                <InputField label="Nachname" name="lastName" disabled />
                <InputField label="Position" name="position" disabled />
                <InputField label="E-Mail" name="email" disabled />
                <InputField label="Telefon Büro" name="phone" disabled />
                <InputField label="Telefon Mobil" name="mobilePhone" disabled />
              </FormWrapper>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }

  return null;
};

RequestBuildingDetailsPage.defaultProps = {
  disabled: false,
};

export default RequestBuildingDetailsPage;
