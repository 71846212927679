/* eslint-disable react/no-unstable-nested-components,react/jsx-props-no-spreading,react/prop-types */
import { closestTo, min } from 'date-fns';
import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { FaAngleDown, FaAngleUp, FaPlus, FaReply } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import {
  CellProps,
  Column,
  Row,
  useExpanded,
  useSortBy,
  useTable,
} from 'react-table';

import {
  BuildingDto,
  CurrentMaintenanceObjectDto,
  CustomerDto,
  CustomerMaintenanceRequestDto,
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceOrderDto,
  MaintenanceRequestDto,
  currentMaintenanceObjectId,
  requestStatusToLabel,
  statusToUse,
} from '@wartungshelden/shared-types';

import groupRequestObjectsByBuildings from '../../../constants/MaintenanceRequestUtils';
import {
  adminRoutePrefix,
  maintenanceRoutes,
  routes,
} from '../../../constants/routes';
import { SessionContext } from '../../../contexts/SessionContext';
import { providedServiceToLabel } from '../../../pages/UserPages/maintenance/MaintenanceObject';
import { getTimestampOrUnknown } from '../../../services/timeDateService';
import Button from '../../Basics/Buttons/Button';
import Loader from '../../Basics/Loaders/Loader';
import { InfoMessageBox } from '../../Basics/MessageBox';
import { sortByBuildingNameAndInventoryName } from '../MaintenanceRequestMenu';
// eslint-disable-next-line import/no-cycle
import DueDateCell from '../MaintenanceTable/DueDateCell';
import CompanyCell from './CompanyCell';
import ExecutionDateCell from './ExecutionDateCell';
import IdCell from './IdCell';
import StatusCell from './StatusCell';

type AdminMaintenanceTableProps = {
  maintenanceRequests: (
    | MaintenanceRequestDto
    | CustomerMaintenanceRequestDto
  )[];
  maintenances?: CurrentMaintenanceObjectDto[];
  offers?: CustomerOrMaintenanceTeamOfferDto[] | null;
  orders?: MaintenanceOrderDto[];
  buildings?: BuildingDto[] | null;
  customers?: CustomerDto[] | null;
  isLoading: boolean;
  noMaintenanceRequest: boolean;
};

type MixedRequest = Omit<
  MaintenanceRequestDto & CustomerMaintenanceRequestDto,
  'fixedNetPrice' | 'variableNetPrice'
>;
export interface MaintenanceRequestTableData extends MixedRequest {
  lastReceived?: Date;
  offer?: CustomerOrMaintenanceTeamOfferDto;
  order?: MaintenanceOrderDto;
}

const MaintenanceRequestsTable: React.FC<
  React.PropsWithChildren<AdminMaintenanceTableProps>
> = ({
  maintenanceRequests,
  maintenances,
  offers,
  orders,
  buildings,
  isLoading,
  noMaintenanceRequest,
}) => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const isDefined = <T,>(argument: T | undefined): argument is T =>
    argument !== undefined;

  const renderDueDate = (
    cell: CellProps<MaintenanceRequestTableData, string[]>
  ) => {
    if (!maintenances) return '';
    const isDate = (field: Date | undefined | null): field is Date => !!field;
    const objectIds: string[] = cell.value;
    const dueDates = maintenances
      ?.filter((maintenance) =>
        objectIds.includes(currentMaintenanceObjectId(maintenance))
      )
      .map((maintenanceObject) => {
        return maintenanceObject.dueDate;
      })
      .filter(isDate);
    if (dueDates.length === 0) return '';
    const dateCell: CellProps<MaintenanceRequestTableData, Date> = {
      ...cell,
      cell: {
        ...cell.cell,
        value: min(dueDates),
      },
      value: min(dueDates),
    };
    return <DueDateCell cell={dateCell} />;
  };

  const renderEmpfangen = (date: Date) => {
    return getTimestampOrUnknown(date);
  };

  const getLastReceived = useCallback(
    (
      maintenanceRequest: MaintenanceRequestDto | CustomerMaintenanceRequestDto,
      offer?: CustomerOrMaintenanceTeamOfferDto,
      order?: MaintenanceOrderDto
    ) => {
      return closestTo(
        new Date(),
        [
          maintenanceRequest.updated,
          offer?.decision.date,
          order?.orderDate,
        ].filter(isDefined)
      );
    },
    []
  );

  const data = useMemo<MaintenanceRequestTableData[]>(() => {
    return maintenanceRequests.map<MaintenanceRequestTableData>(
      (maintenanceRequest) => {
        const offer = offers?.find(
          ({ requestId }) => requestId === maintenanceRequest.id
        );

        const order = orders?.find((o) => o.id === offer?.order?.id);

        return {
          ...maintenanceRequest,
          offer,
          order,
          lastReceived: getLastReceived(maintenanceRequest, offer, order),
        } as MaintenanceRequestTableData;
      }
    );
  }, [getLastReceived, maintenanceRequests, offers, orders]);

  const commonColumns: Column<MaintenanceRequestTableData>[] = [
    {
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      // @ts-ignore
      Header: ({ getToggleAllRowsExpandedProps }: any) => (
        <span {...getToggleAllRowsExpandedProps()} />
      ),
      // @ts-ignore
      Cell: ({ row, rows, toggleRowExpanded }: any) => (
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
            },
            onClick: () => {
              const expandedRow = rows.find((erow: any) => erow.isExpanded);

              if (expandedRow) {
                const isSubItemOfRow = Boolean(
                  expandedRow && row.id.split('.')[0] === expandedRow.id
                );

                if (isSubItemOfRow) {
                  const expandedSubItem = expandedRow.subRows.find(
                    (subRow: any) => subRow.isExpanded
                  );

                  if (expandedSubItem) {
                    const isClickedOnExpandedSubItem =
                      expandedSubItem.id === row.id;
                    if (!isClickedOnExpandedSubItem) {
                      toggleRowExpanded(expandedSubItem.id, false);
                    }
                  }
                } else {
                  toggleRowExpanded(expandedRow.id, false);
                }
              }
              row.toggleRowExpanded();
            },
          })}
        >
          {row.isExpanded ? <FaAngleUp /> : <FaAngleDown />}
        </span>
      ),
    },
    {
      Header: 'Anfrage',
      accessor: (request) => request,
      // @ts-ignore
      Cell: (cell) => (
        <IdCell
          cell={cell}
          isMaintenanceTeamMemberAdmin={isMaintenanceTeamMemberAdmin}
        />
      ),
    },
    {
      Header: 'Status',
      accessor: (request) => ({
        requestStatus: request.status,
        offerStatus: request.offer?.decision?.status,
        orderStatus: request.offer?.order?.status,
      }),
      // @ts-ignore
      Cell: (cell) => (
        <StatusCell
          cell={cell}
          isMaintenanceTeamMemberAdmin={isMaintenanceTeamMemberAdmin}
        />
      ),
      sortType: 'status',
    },
    {
      Header: 'Ausführungstermin',
      accessor: 'offer',
      // @ts-ignore
      Cell: ExecutionDateCell,
      sortType: 'executionDate',
    },
    {
      id: 'lastChange',
      Header: 'letzte Änderung',
      accessor: 'lastReceived',
      // @ts-ignore
      Cell: ({ value }) => <div>{value && renderEmpfangen(value)}</div>,
      sortType: 'datetimeNullSafe',
    },
  ];

  const adminColumns: Column<MaintenanceRequestTableData>[] = [
    {
      Header: 'Firma',
      accessor: ({ offer }) => offer,
      // @ts-ignore
      Cell: CompanyCell,
    },
    {
      Header: 'Empfangen',
      accessor: 'created',
      // @ts-ignore
      Cell: ({ value }) => <div>{renderEmpfangen(value)}</div>,
      sortType: 'datetimeNullSafe',
    },
    {
      Header: 'Nächste Fälligkeit',
      id: 'dueDate',
      accessor: 'objectIds',
      // @ts-ignore
      Cell: (cell) => <>{renderDueDate(cell)}</>,
      sortType: 'datetimeNullSafe',
    },
  ];

  const columns = useMemo<Column<MaintenanceRequestTableData>[]>(
    () =>
      isMaintenanceTeamMemberAdmin
        ? [
            commonColumns[0],
            commonColumns[1],
            ...adminColumns,
            commonColumns[4],
            commonColumns[2],
            commonColumns[3],
          ]
        : [
            commonColumns[0],
            commonColumns[1],
            commonColumns[2],
            commonColumns[4],
            commonColumns[3],
          ],
    [maintenances]
  );

  const sortTypes = {
    datetimeNullSafe: (
      row1: Row<MaintenanceRequestTableData>,
      row2: Row<MaintenanceRequestTableData>,
      columnName: string
    ) => {
      let [a, b] = [row1.values[columnName], row2.values[columnName]];

      a = a ? new Date(a).getTime() : Infinity;
      b = b ? new Date(b).getTime() : Infinity;

      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    },
    executionDate: (
      row1: Row<MaintenanceRequestTableData>,
      row2: Row<MaintenanceRequestTableData>,
      columnName: string
    ) => {
      let [a, b] = [
        row1.values[columnName].suggestedDate,
        row2.values[columnName].suggestedDate,
      ];

      a = a ? new Date(a).getTime() : Infinity;
      b = b ? new Date(b).getTime() : Infinity;

      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    },
    status: (
      row1: Row<MaintenanceRequestTableData>,
      row2: Row<MaintenanceRequestTableData>,
      columnName: string
    ) => {
      const status1 = requestStatusToLabel(
        isMaintenanceTeamMemberAdmin,
        statusToUse(
          row1.values[columnName].requestStatus,
          row1.values[columnName].offerStatus
        )
      );

      const status2 = requestStatusToLabel(
        isMaintenanceTeamMemberAdmin,
        statusToUse(
          row2.values[columnName].requestStatus,
          row2.values[columnName].offerStatus
        )
      );

      return status1.localeCompare(status2);
    },
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<MaintenanceRequestTableData>(
      {
        columns,
        data,
        sortTypes,
        initialState: {
          sortBy: [
            {
              id: 'lastChange',
              desc: true,
            },
          ],
        },
      },
      useSortBy,
      useExpanded
    );
  const navigate = useNavigate();

  const listInnerRef = useRef<HTMLTableSectionElement>(null);

  const getEmptyComponent = () => {
    if (!isMaintenanceTeamMemberAdmin && !buildings?.length) {
      return (
        <InfoMessageBox
          label="Bevor Du Deine erste Wartung erstellen kannst, lege ein Gebäude an."
          isCentered
        >
          <Link
            to={`/${routes.buildings}`}
            className="flex flex-row items-center"
          >
            <FaReply className="transform -scale-x-1" />
            <span className="ml-2 underline font-bold">zu den Gebäuden</span>
          </Link>
        </InfoMessageBox>
      );
    }
    if (noMaintenanceRequest) {
      return (
        <InfoMessageBox
          label="Keine Wartung vorhanden. Lege deine erste Wartung an."
          isCentered
        >
          <Button
            className="primary-button small-button"
            onClick={() => {
              navigate(maintenanceRoutes.add);
            }}
            icon={<FaPlus />}
            label="Wartung"
          />
        </InfoMessageBox>
      );
    }

    if (!rows.length) {
      return (
        <InfoMessageBox
          label="Keine Wartungen entsprechen Deinem Filter."
          isCentered
        />
      );
    }
    return null;
  };

  const adminPrefix = isMaintenanceTeamMemberAdmin
    ? `/${adminRoutePrefix}`
    : '';

  return (
    <div className="h-full overflow-y-auto w-full overflow-x-hidden">
      <table
        {...getTableProps()}
        data-cy="components-maintenances-maintenance-request-overview-table"
        className="w-full overflow-y-auto border-collapse bg-white"
      >
        {rows.length > 0 ? (
          <thead className="sticky top-0 w-full bg-gray-light text-black-abs">
            {headerGroups.map((headerGroup) => (
              <tr className="h-9" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="text-left pl-4"
                  >
                    {column.render('Header')}
                    {column.isSorted && (
                      <span>{column.isSortedDesc ? '↓' : '↑'}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody
          className="overflow-y-auto"
          {...getTableBodyProps()}
          ref={listInnerRef}
        >
          {rows.map((row) => {
            const subRowData = sortByBuildingNameAndInventoryName(
              groupRequestObjectsByBuildings(
                buildings,
                row?.original?.offer?.maintenanceObjects ?? maintenances
              )
            );

            prepareRow(row);
            return (
              <Fragment key={row.original.id}>
                {row.depth === 0 && (
                  <tr
                    className="cursor-pointer hover:bg-gray-lighter hover:text-black-abs text-left pl-4 h-9"
                    data-cy="components-maintenances-maintenance-request-overview-table-row"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      if (cell.column.id === 'expander') {
                        return (
                          <td
                            className="border-r-2 border-white"
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      }
                      return cell.column.id === 'lastChange' ? (
                        <td
                          className="border-r-2 border-white pl-4"
                          {...cell.getCellProps()}
                        >
                          <button
                            className="flex w-full items-start"
                            type="button"
                            onClick={() => {
                              navigate(
                                `${adminPrefix}/requests/${row.original.id}`
                              );
                            }}
                          >
                            {getTimestampOrUnknown(
                              closestTo(
                                new Date(),
                                [
                                  row.original?.updated,
                                  row.original?.offer?.decision.date,
                                  row.original?.order?.orderDate,
                                ]
                                  .filter(isDefined)
                                  .map((date) => new Date(date))
                              ) || null
                            )}
                          </button>
                        </td>
                      ) : (
                        <td
                          className={`border-r-2 border-white ${
                            cell.column.id !== 'dueDate' && 'pl-4'
                          }`}
                          {...cell.getCellProps()}
                        >
                          <button
                            className="flex w-full items-start"
                            type="button"
                            onClick={() => {
                              navigate(
                                `${adminPrefix}/requests/${row.original.id}`
                              );
                            }}
                          >
                            {cell.render('Cell')}
                          </button>
                        </td>
                      );
                    })}
                  </tr>
                )}

                {row.isExpanded && (
                  <Fragment key={row.original.id}>
                    {subRowData.map((r) => {
                      return (
                        <Fragment key={r.building?.id}>
                          <tr
                            className="cursor-pointer hover:bg-blue-abs hover:text-white text-left h-9"
                            key={r.building?.id}
                          >
                            <td />
                            <td colSpan={6}>
                              <Link
                                to={`${adminPrefix}/requests/${row.original.id}/buildings/${r.building?.id}`}
                              >
                                <div className="ml-4 pt-2">
                                  Gebäudename: {r.building?.name}
                                </div>
                              </Link>
                            </td>
                          </tr>
                          {r.maintenances?.map((maintenance) => (
                            <tr
                              className="cursor-pointer hover:bg-blue-abs hover:text-white text-left h-9"
                              key={currentMaintenanceObjectId(maintenance)}
                            >
                              <td />
                              <td colSpan={6}>
                                <Link
                                  to={`${adminPrefix}/requests/${
                                    row.original.id
                                  }/maintenances/${currentMaintenanceObjectId(
                                    maintenance
                                  )}`}
                                >
                                  <div className="ml-8 pt-2">
                                    {`${providedServiceToLabel(
                                      maintenance.type.type
                                    )} ${
                                      maintenance?.name
                                        ? `: ${maintenance.name}`
                                        : ''
                                    }`}
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      {getEmptyComponent()}
      {isLoading && (
        <div className="flex flex-1 justify-center py-4 bg-white">
          <Loader />
        </div>
      )}
    </div>
  );
};

MaintenanceRequestsTable.defaultProps = {
  maintenances: undefined,
  offers: undefined,
  orders: undefined,
  buildings: undefined,
  customers: undefined,
};

export default MaintenanceRequestsTable;
