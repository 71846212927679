import React from 'react';

import { Card, PageHeader } from '../../../../components';

const ConfirmationStep: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Card>
      <div className="flex flex-1 h-full flex-col overflow-y-auto">
        <PageHeader>Super, Deine Anfrage ist gesendet.</PageHeader>
        <p className="mb-6">
          <strong>
            Anfrage erfolgreich gesendet und bei uns eingetroffen.
          </strong>{' '}
          <br />
          Vielen lieben Dank für Deine Anfrage, wir werden diese zeitnah
          bearbeiten.
        </p>
        <p className="mb-6">
          Deine Anfrage kannst Du in der Tabelle Aufträge einsehen.
        </p>
      </div>
    </Card>
  );
};

export default ConfirmationStep;
