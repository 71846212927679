import React, { useContext } from 'react';

import {
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { SessionContext } from '../../../contexts/SessionContext';
import { useMaintenanceDocumentationFile } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import DocumentationValidityChanger from '../../Maintenance/MaintenanceWizard/DocumentationValidityChanger';
import Loader from '../Loaders/Loader';
import FileNameComponent from './File/FileNameComponent';
import FileThumbnailComponent from './File/FileThumbnailComponent';

type DocumentPreviewProps = {
  maintenanceObject?: MaintenanceObjectDto;
  maintenanceDocument: MaintenanceDocumentationDto;
};

const DocumentPreviewAdmin: React.FC<
  React.PropsWithChildren<DocumentPreviewProps>
> = ({ maintenanceObject, maintenanceDocument }) => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);
  const { isLoading, data: loadedFileFromDocument } =
    useMaintenanceDocumentationFile(
      maintenanceDocument.id,
      true,
      isMaintenanceTeamMemberAdmin ? maintenanceObject?.ownerId : undefined
    );

  const documentNotRelevant = Boolean(
    maintenanceDocument &&
      maintenanceObject?.id &&
      maintenanceDocument.notRelevantFor?.includes(maintenanceObject?.id) &&
      !maintenanceDocument.invalidFor?.includes(maintenanceObject?.id) &&
      !maintenanceDocument.validFor?.includes(maintenanceObject?.id)
  );

  const documentIsValid = Boolean(
    maintenanceDocument &&
      maintenanceObject?.id &&
      maintenanceDocument.validFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.invalidFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.notRelevantFor?.includes(maintenanceObject.id)
  );

  const documentNotValidated = Boolean(
    maintenanceDocument &&
      maintenanceObject?.id &&
      !maintenanceDocument?.validFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.invalidFor?.includes(maintenanceObject.id) &&
      !maintenanceDocument.notRelevantFor?.includes(maintenanceObject.id)
  );

  return (
    <div className="flex h-full w-full relative">
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-1 bg-gray text-gray-abs mb-2">
            {loadedFileFromDocument && (
              <div className="h-2/3 px-2">
                <a
                  href={
                    loadedFileFromDocument &&
                    URL.createObjectURL(loadedFileFromDocument)
                  }
                  target="_blank"
                  className="w-full h-full flex items-center justify-center"
                  rel="noreferrer"
                >
                  <FileThumbnailComponent file={loadedFileFromDocument} />
                </a>
                <FileNameComponent file={loadedFileFromDocument} />
              </div>
            )}
          </div>
          <div>
            {maintenanceObject?.id && (
              <DocumentationValidityChanger
                document={maintenanceDocument}
                documentNotValidated={documentNotValidated}
                documentIsValid={documentIsValid}
                documentNotRelevant={documentNotRelevant}
                maintenanceObjectId={maintenanceObject.id}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DocumentPreviewAdmin.defaultProps = {
  maintenanceObject: undefined,
};

export default DocumentPreviewAdmin;
