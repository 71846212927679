import React from 'react';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
} from 'react-icons/fa';

interface MessageBoxProps {
  variant: 'info' | 'warning' | 'error' | 'success';
  headline?: string;
  headlineStyle?: string;
  label?: string;
  customIcon?: React.ReactNode;
  isCentered?: boolean;
  dataCy?: string;
}

const MessageBox: React.FC<React.PropsWithChildren<MessageBoxProps>> = ({
  variant,
  headline,
  headlineStyle,
  label,
  customIcon,
  isCentered,
  children,
  dataCy,
}) => {
  const color = {
    info: 'gray',
    warning: 'orange',
    error: 'red',
    success: 'green',
  }[variant];

  const icon = {
    info: <FaInfoCircle />,
    warning: <FaExclamationTriangle className={`text-${color}`} />,
    error: <FaExclamationCircle className={`text-${color}`} />,
    success: <FaCheckCircle className="text-green-success" />,
  }[variant];

  return (
    <div
      data-cy={dataCy}
      className={`flex bg-${color} bg-opacity-10 ${
        variant === 'success' ? 'border-green-success' : `border-${color}`
      } border-4 p-4 items-center ${isCentered ? 'justify-center' : ''} `}
    >
      <div
        className={`pl-4 w-full ${
          isCentered ? 'flex flex-col items-center' : ''
        }`}
      >
        {headline ? (
          <>
            <div className="flex items-center mb-2">
              <span className="mr-2">{customIcon ?? icon}</span>
              <div className={`font-bold ${headlineStyle && headlineStyle}`}>
                {headline}
              </div>
            </div>
            {label && (
              <div className="flex items-center">
                <span className="mr-2 invisible">{customIcon ?? icon}</span>
                {label}
              </div>
            )}
            {children && (
              <div className="flex items-center">
                <span className="mr-2 invisible">{customIcon ?? icon}</span>
                <div className={label ? 'mt-4' : undefined}>{children}</div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex">
              <div className="flex mr-2 mt-1">{customIcon ?? icon}</div>
              {label}
            </div>
            {children && (
              <div className={label ? 'mt-4' : undefined}>{children}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

MessageBox.defaultProps = {
  headline: undefined,
  headlineStyle: undefined,
  label: undefined,
  customIcon: undefined,
  isCentered: false,
  dataCy: 'pages-maintenance-page-empty-maintenance-table-component',
};

export default MessageBox;

export const InfoMessageBox: React.FC<
  React.PropsWithChildren<Omit<MessageBoxProps, 'variant'>>
> = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MessageBox {...props} variant="info">
    {children}
  </MessageBox>
);

export const WarningMessageBox: React.FC<
  React.PropsWithChildren<Omit<MessageBoxProps, 'variant'>>
> = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MessageBox {...props} variant="warning" dataCy="warning-message-box">
    {children}
  </MessageBox>
);

export const ErrorMessageBox: React.FC<
  React.PropsWithChildren<Omit<MessageBoxProps, 'variant'>>
> = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MessageBox variant="error" {...props}>
    {children}
  </MessageBox>
);

export const SuccessMessageBox: React.FC<
  React.PropsWithChildren<Omit<MessageBoxProps, 'variant'>>
> = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MessageBox {...props} variant="success" dataCy="success-message-box">
    {children}
  </MessageBox>
);
