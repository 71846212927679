import { DomainEventDispatcher, User } from '@warthungshelden/domain/common';
import { distinct, intersects, to } from '@warthungshelden/shared-functions';
import { v4 as uuidv4 } from 'uuid';

import { BuildingRepository } from '../building';
import {
  MaintenanceObject,
  MaintenanceObjectRepository,
} from '../maintenance-object';
import { MaintenanceOfferRepository } from '../maintenance-offer';
import {
  CanNotChangeOwnerRule,
  CanNotSetValidityForUndocumentedObject,
  MustBeMaintenanceTeamRule,
  MustBeOwnerRule,
  MustExistRule,
  MustNotExistOnBuilding,
  MustOnlySetValidityBeforeOrdered,
  ObjectCanOnlyBeInOneValidityState,
  UserMustNotChangeValidity,
} from '../rules';
import { MaintenanceDocumentationUpdated } from './events';
import { MaintenanceDocumentation } from './maintenance-documentation';
import { MaintenanceDocumentationFileService } from './maintenance-documentation-file.service';
import { MaintenanceDocumentationRepository } from './maintenance-documentation.repository';

export class MaintenanceDocumentationService {
  constructor(
    private readonly eventDispatcher: DomainEventDispatcher,
    private readonly maintenanceDocumentationRepository: MaintenanceDocumentationRepository,
    private readonly maintenanceDocumentationFileService: MaintenanceDocumentationFileService,
    private readonly maintenanceObjectRepository: MaintenanceObjectRepository,
    private readonly maintenanceOfferRepository: MaintenanceOfferRepository,
    private readonly buildingRepository: BuildingRepository
  ) {}

  public async getById(
    user: User,
    id: string
  ): Promise<MaintenanceDocumentation> {
    await new MustExistRule(this.maintenanceDocumentationRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    return this.maintenanceDocumentationRepository.getById(id);
  }

  public async add(
    user: User,
    newMaintenanceDocumentation: Omit<
      MaintenanceDocumentation,
      | 'id'
      | 'fileName'
      | 'validFor'
      | 'invalidFor'
      | 'notRelevantFor'
      | 'copyWith'
    >,
    file: Buffer
  ): Promise<MaintenanceDocumentation> {
    const id = uuidv4();

    const maintenanceDocumentation = new MaintenanceDocumentation({
      ...newMaintenanceDocumentation,
      id,
      validFor: [],
      invalidFor: [],
      notRelevantFor: [],
      fileName: id,
    });

    await Promise.all(
      maintenanceDocumentation.maintenanceObjectIds.map((objectId) =>
        new MustExistRule(this.maintenanceObjectRepository)
          .and(new MustBeOwnerRule(maintenanceDocumentation.ownerId))
          .apply(objectId)
      )
    );

    await new MustNotExistOnBuilding(
      user,
      this,
      this.maintenanceObjectRepository
    ).apply(maintenanceDocumentation);

    await this.maintenanceDocumentationFileService.addFileFor(
      user,
      maintenanceDocumentation.fileName,
      file
    );

    return this.maintenanceDocumentationRepository.create(
      maintenanceDocumentation
    );
  }

  public async delete(user: User, id: string) {
    await new MustExistRule(this.maintenanceDocumentationRepository)
      .and(new MustBeOwnerRule(user.id))
      .apply(id);

    await this.maintenanceDocumentationRepository.delete(id);
  }

  public async update(
    user: User,
    newMaintenanceDocumentation: Pick<MaintenanceDocumentation, 'id'> &
      Partial<MaintenanceDocumentation>
  ): Promise<MaintenanceDocumentation | undefined> {
    const ownershipRules = new MustBeOwnerRule(user.id).or(
      new MustBeMaintenanceTeamRule(user)
    );

    const oldMaintenanceDocumentation = await new MustExistRule(
      this.maintenanceDocumentationRepository
    )
      .and(ownershipRules)
      .apply(newMaintenanceDocumentation.id);

    await new UserMustNotChangeValidity(user, newMaintenanceDocumentation)
      .and(
        new CanNotSetValidityForUndocumentedObject(newMaintenanceDocumentation)
      )
      .and(
        new MustOnlySetValidityBeforeOrdered(
          this.maintenanceObjectRepository,
          this.maintenanceOfferRepository,
          newMaintenanceDocumentation
        )
      )
      .and(new ObjectCanOnlyBeInOneValidityState(newMaintenanceDocumentation))
      .apply(oldMaintenanceDocumentation);

    await new CanNotChangeOwnerRule(oldMaintenanceDocumentation.ownerId).apply(
      newMaintenanceDocumentation
    );

    const maintenanceDocumentation = oldMaintenanceDocumentation.copyWith(
      newMaintenanceDocumentation
    );

    if (maintenanceDocumentation.maintenanceObjectIds.length === 0) {
      await this.maintenanceDocumentationFileService.deleteFileFor(
        user,
        maintenanceDocumentation.fileName
      );

      await this.delete(user, maintenanceDocumentation.id);

      return undefined;
    }

    await Promise.all(
      maintenanceDocumentation.maintenanceObjectIds.map((objectId) =>
        new MustExistRule(this.maintenanceObjectRepository)
          .and(ownershipRules)
          .apply(objectId)
      )
    );

    const updatedDocumentation =
      await this.maintenanceDocumentationRepository.update(
        maintenanceDocumentation
      );

    await this.eventDispatcher.dispatch(
      new MaintenanceDocumentationUpdated(user, updatedDocumentation)
    );

    return updatedDocumentation;
  }

  public async getAllAccessibleBy(
    user: User
  ): Promise<MaintenanceDocumentation[]> {
    return user.isMaintenanceTeamMemberAdmin
      ? this.maintenanceDocumentationRepository.getAll()
      : this.maintenanceDocumentationRepository.getAllByUser(user.id);
  }

  public async getAllForMaintenanceObject(
    user: User,
    objectId: string,
    only?: 'valid' | 'invalid'
  ): Promise<MaintenanceDocumentation[]> {
    const documentations =
      await this.maintenanceDocumentationRepository.getAllContainingObject(
        objectId
      );

    return only === 'valid'
      ? documentations.filter(({ validFor }) => validFor.includes(objectId))
      : only === 'invalid'
      ? documentations.filter(({ validFor }) => validFor.includes(objectId))
      : documentations.filter(({ maintenanceObjectIds }) =>
          maintenanceObjectIds.includes(objectId)
        );
  }

  public async hasValidDocumentation(
    user: User,
    objectId: string
  ): Promise<boolean | null> {
    const validDocumentations = await this.getAllForMaintenanceObject(
      user,
      objectId,
      'valid'
    );

    const invalidDocumentations = await this.getAllForMaintenanceObject(
      user,
      objectId,
      'invalid'
    );

    return validDocumentations.length !== 0
      ? true
      : invalidDocumentations.length !== 0
      ? false
      : null;
  }

  public async getAllByBuilding(
    user: User,
    buildingId: string
  ): Promise<MaintenanceDocumentation[]> {
    await new MustExistRule(this.buildingRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(buildingId);

    const documentations =
      await this.maintenanceDocumentationRepository.getAll();

    const maintenanceObjectIds = documentations.reduce<string[]>(
      toDistinctObjectIds(),
      []
    );

    const maintenanceObjects = await Promise.all(
      maintenanceObjectIds.map(
        toMaintenanceObject(this.maintenanceObjectRepository)
      )
    );

    const objectsWithBuildingIds = maintenanceObjects
      .filter(withBuilding(buildingId))
      .map(to('id'));

    return documentations.filter(withBuildingIn(objectsWithBuildingIds));
  }
}

function toDistinctObjectIds() {
  return (distinctObjects, { maintenanceObjectIds }): string[] =>
    distinct([...distinctObjects, ...maintenanceObjectIds]);
}

function withBuilding(buildingId: string) {
  return (object: MaintenanceObject) => object.buildingId === buildingId;
}

function toMaintenanceObject(
  maintenanceObjectRepository: MaintenanceObjectRepository
) {
  return (objectId: string) => maintenanceObjectRepository.getById(objectId);
}

function withBuildingIn(objectsWithBuildingIds: string[]) {
  return ({ maintenanceObjectIds }) =>
    intersects(maintenanceObjectIds, objectsWithBuildingIds);
}
