import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { Building } from '../../building';
import { MaintenanceObject } from '../../maintenance-object';

export class BuildingCanNotBeInRequestRule extends DomainRule<
  Building,
  Building
> {
  constructor(private requestedMaintenanceObjectsOfUser: MaintenanceObject[]) {
    super();
  }

  protected rule(building: Building): Promise<Building> | Building {
    if (
      !this.requestedMaintenanceObjectsOfUser.find(
        (maintenanceObject) =>
          maintenanceObject.buildingId === building.id &&
          maintenanceObject.currentRequestId !== undefined
      )
    ) {
      return building;
    }

    throw new DomainRuleNotSatisfiedError(BuildingCanNotBeInRequestRule);
  }
}
