import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  BuildingDto,
  CreateBuildingDto,
  UpdateBuildingDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

export const useBuildings = () => {
  const axios = useAxios();

  const fetchBuildings = async () => {
    if (!axios) return null;
    const { data } = await axios.get<BuildingDto[]>(
      '/maintenance-exchange/buildings'
    );
    return data;
  };

  return useQuery('buildings', fetchBuildings);
};

export const useCreateBuilding = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const createBuilding = async (building: CreateBuildingDto) => {
    if (!axios) return null;
    return axios.post('/maintenance-exchange/buildings', building);
  };

  return useMutation(createBuilding, {
    onSuccess: async () => {
      await rqClient.invalidateQueries(['buildings']);
    },
  });
};

export const useUpdateBuilding = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const updateBuilding = async (building: UpdateBuildingDto) => {
    if (!axios) return null;
    return axios.patch(`/maintenance-exchange/buildings`, building);
  };

  return useMutation(updateBuilding, {
    onSuccess: async () => {
      await rqClient.invalidateQueries('buildings');
    },
  });
};

export const useRemoveBuilding = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const deleteBuilding = async (id: string) => {
    if (!axios) return null;
    return axios.delete(`/maintenance-exchange/buildings/${id}`);
  };

  return useMutation(deleteBuilding, {
    onSuccess: async () => {
      await rqClient.invalidateQueries('buildings');
    },
  });
};
