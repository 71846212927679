import { isPhoneNumber } from 'class-validator';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaApple, FaSignInAlt } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Button, HorizontalLineWithText } from '../../../components';
import AuthInputField from '../../../components/Auth/AuthInputField';
import AuthLinkButton from '../../../components/Auth/AuthLinkButton';
import AuthPhoneInputField from '../../../components/Auth/AuthPhoneInputField';
import AuthPrimaryButton from '../../../components/Auth/AuthPrimaryButton';
import AuthProviderButton from '../../../components/Auth/AuthProviderButton';
import FieldError from '../../../components/Basics/FieldError';
import HorizontalLine from '../../../components/Basics/HorizontalLine';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import Modal from '../../../components/Basics/Modals/Modal';
import PolicyAuthFooter from '../../../components/Policies/PolicyAuthFooter';
import { SessionContext } from '../../../contexts/SessionContext';
import { useIsSignedIn } from '../../../services/authService';
import ContactButton from '../ContactButton';

const UserLoginForm = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [params] = useSearchParams();
  const { isSignedIn } = useIsSignedIn();

  const { login, loginWithGoogle, loginWithApple } = useContext(SessionContext);

  const [isUserNotConfirmedModalOpen, setIsUserNotConfirmedModalOpen] =
    useState<boolean>(false);

  const redirectedFrom = (state as any)?.from;

  const initialEmail =
    ((state as any)?.email as string | undefined) ?? params.get('email');

  const initialPhone = !initialEmail
    ? ((state as any)?.phone as string | undefined) ?? params.get('phone')
    : undefined;

  const [isEmailSelected, setIsEmailSelected] = useState(!initialPhone);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const navigateToSignUp = () => {
    navigate('/register');
  };

  const navigateToResetPassword = () => {
    navigate('/login/reset');
  };

  const navigateToApp = () => {
    const to =
      redirectedFrom && redirectedFrom !== pathname ? redirectedFrom : '/';

    navigate(to);
  };

  const LoginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-Mail nicht gültig')
      .when({
        is: () => isEmailSelected,
        then: (schema) => schema.required('E-Mail erforderlich'),
        otherwise: (schema) => schema.optional(),
      }),
    phone: Yup.string().when({
      is: () => !isEmailSelected,
      then: (schema) =>
        schema.test({
          message: 'Rufnummer nicht gültig',
          test: (number) => isPhoneNumber(number ?? ''),
        }),
      otherwise: (schema) => schema.optional(),
    }),
    password: Yup.string().required('Passwort erforderlich'),
  });

  const onSignIn = async (values) => {
    setIsSigningIn(true);
    const loginResponse = await login(
      isEmailSelected ? values.email : values.phone,
      values.password
    );
    setIsSigningIn(false);

    if (loginResponse.isSuccessful) {
      navigateToApp();
    } else if (loginResponse.error.code === 'UserNotConfirmedException') {
      setIsUserNotConfirmedModalOpen(true);
    } else {
      toast.error(
        'Login fehlgeschlagen. Bitte prüfe deine angegebenen Daten.',
        {
          position: 'top-center',
        }
      );
    }
  };

  const onGoogleSignIn = async () => {
    setIsSigningIn(true);
    await loginWithGoogle();
    setIsSigningIn(false);
  };

  const onAppleSignIn = async () => {
    setIsSigningIn(true);
    await loginWithApple();
    setIsSigningIn(false);
  };

  useEffect(() => {
    if (isSignedIn) {
      navigateToApp();
    }
  }, [isSignedIn]);

  return (
    <>
      <div className="flex justify-center p-8">
        <img src="/assets/logo.svg" alt="ABS Wartung" className="w-3/5" />
      </div>

      <div className="absolute top-4 right-4">
        <ContactButton />
      </div>
      <Formik
        validationSchema={LoginValidationSchema}
        initialValues={{
          email: initialEmail ?? '',
          phone: initialPhone ?? '',
          password: '',
        }}
        onSubmit={onSignIn}
      >
        {({
          submitForm,
          errors,
          isValid,
          dirty,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => (
          <div className="bg-white shadow w-full rounded-lg mb-2">
            <LoadingSpinner isLoading={isSigningIn} />

            <div className="py-7 px-5">
              <div className="py-1">
                <AuthProviderButton
                  provider="google"
                  label="Anmelden mit Google"
                  icon={<FcGoogle />}
                  onClick={onGoogleSignIn}
                />
              </div>
              <div className="py-1">
                <AuthProviderButton
                  provider="apple"
                  label="Anmelden mit Apple"
                  icon={<FaApple />}
                  onClick={onAppleSignIn}
                />
              </div>

              <HorizontalLineWithText text="oder" />
              <div className="py-2">
                Melde Dich mit deiner E-Mail-Adresse und Deinem Passwort an.
              </div>
              <div className="pt-2">
                {isEmailSelected ? (
                  <AuthInputField name="email" label="E-Mail" />
                ) : (
                  <AuthPhoneInputField name="phone" label="Telefon" />
                )}
                <AuthLinkButton
                  label={`Stattdessen mit ${
                    isEmailSelected ? 'Telefonnummer' : 'E-Mail'
                  } anmelden`}
                  onClick={() => {
                    setFieldValue(isEmailSelected ? 'email' : 'phone', '');
                    setFieldTouched(isEmailSelected ? 'email' : 'phone', false);
                    setIsEmailSelected((prev) => !prev);
                  }}
                />
                <FieldError
                  message={errors.email}
                  visible={Boolean(errors.email && touched.email)}
                />
                <FieldError
                  message={errors.phone}
                  visible={Boolean(errors.phone && touched.phone)}
                />
              </div>
              <div className="pt-3">
                <AuthInputField
                  name="password"
                  label="Passwort"
                  type="password"
                />
                <button
                  type="button"
                  className="underline text-sm text-blue-abs"
                  onClick={navigateToResetPassword}
                >
                  Passwort vergessen?
                </button>
                <FieldError
                  message={errors.password}
                  visible={Boolean(errors.password && touched.password)}
                />
              </div>

              <div className="pt-7">
                <AuthPrimaryButton
                  kind="login"
                  onClick={submitForm}
                  label="Anmelden"
                  icon={<FaSignInAlt color="white" />}
                  disabled={!isValid || !dirty}
                  className="primary-button full-button"
                />
              </div>
            </div>
            <HorizontalLine />
            <div className="py-5 flex flex-row justify-center">
              <p>Neu hier?&nbsp;&nbsp;</p>
              <AuthLinkButton
                label="Ja, ich möchte ein Nutzerkonto anlegen"
                onClick={navigateToSignUp}
              />
            </div>
          </div>
        )}
      </Formik>
      <PolicyAuthFooter />

      <Modal
        isOpen={isUserNotConfirmedModalOpen}
        headline="Bitte bestätige zuerst Dein Konto!"
        center
      >
        <div>
          <div>
            Wir haben Dir nach Deiner Registrierung eine E-Mail geschickt.
          </div>
          <div>
            Die E-Mail enthält einen Bestätigungscode zum Aktivieren Deines
            Nutzerkontos.
          </div>
          <div>Im Anschluss kannst Du Dich anmelden.</div>
        </div>

        <div className="flex flex-col w-full pt-4 mt-2 border-t-2 border-gray">
          <div>Du findest die E-Mail gerade nicht? Kein Problem.</div>
          <div>
            <Link
              to="/register/verify/resend"
              className="hover:border-b-2 hover:border-blue-abs text-black-abs mb-1 text-blue-abs"
            >
              E-Mail erneut zusenden
            </Link>
          </div>
        </div>

        <div className="flex w-full flex-row justify-center pt-4 mt-2 border-t-2 border-gray">
          <Button
            className="primary-button small-button"
            onClick={() => {
              setIsUserNotConfirmedModalOpen(false);
            }}
            label="Ok"
          />
        </div>
      </Modal>
    </>
  );
};

export default UserLoginForm;
