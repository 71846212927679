import {
  BuildingDto,
  MaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { DueDateStatus } from '../../../constants/dueDateStatus';
import { getDueDateStatus } from '../../../services/maintenanceService';

export const generateBuildingsOverallGraphData = (
  buildings?: BuildingDto[] | null,
  maintenanceObjects?: MaintenanceObjectDto[] | null
) => {
  let result: Array<{
    name: string;
    value: number;
    color: string;
    id: string;
    percentage: number;
  }> = [];

  if (buildings && maintenanceObjects) {
    let unknownDueDateBuildings = 0;
    let healthyBuildings = 0;
    let upcomingBuildings = 0;
    let nextMonthBuildings = 0;
    let overdueBuildings = 0;

    buildings?.forEach((building) => {
      const maintenancesFromCurrentBuilding = maintenanceObjects?.filter(
        (maintenanceObject) => maintenanceObject.buildingId === building?.id
      );

      if (maintenancesFromCurrentBuilding.length === 0) {
        return;
      }

      // check if it has red elements
      if (
        maintenancesFromCurrentBuilding?.some(
          (maintenanceObject) =>
            !maintenanceObject?.currentRequestId &&
            maintenanceObject.dueDate &&
            getDueDateStatus(maintenanceObject.dueDate) ===
              DueDateStatus.OVERDUE
        )
      ) {
        overdueBuildings += 1;
      } else if (
        maintenancesFromCurrentBuilding?.some(
          (maintenanceObject) =>
            !maintenanceObject?.currentRequestId &&
            maintenanceObject.dueDate &&
            getDueDateStatus(maintenanceObject.dueDate) ===
              DueDateStatus.UPCOMING
        )
      ) {
        upcomingBuildings += 1;
      } else if (
        maintenancesFromCurrentBuilding?.some(
          (maintenanceObject) =>
            !maintenanceObject?.currentRequestId &&
            maintenanceObject.dueDate &&
            getDueDateStatus(maintenanceObject.dueDate) ===
              DueDateStatus.NEXT_MONTH
        )
      ) {
        nextMonthBuildings += 1;
      } else if (
        maintenancesFromCurrentBuilding?.some(
          (maintenanceObject) =>
            !maintenanceObject?.currentRequestId && !maintenanceObject?.dueDate
        )
      ) {
        unknownDueDateBuildings += 1;
      } else if (maintenancesFromCurrentBuilding?.length) {
        healthyBuildings += 1;
      }
    });

    const buildingsWithRequest = buildings.filter((building) => {
      const maintenancesFromCurrentBuilding = maintenanceObjects?.filter(
        (maintenanceObject) => maintenanceObject.buildingId === building?.id
      );

      return maintenancesFromCurrentBuilding.length !== 0;
    });

    result = [
      {
        id: 'OVERDUE',
        name: 'Dringender Handlungsbedarf',
        value: overdueBuildings,
        color: '#b5131d',
        percentage: buildingsWithRequest?.length
          ? Number(
              ((overdueBuildings / buildingsWithRequest.length) * 100).toFixed(
                2
              )
            )
          : 0,
      },
      {
        id: 'UNKNOWN',
        name: 'Unbekannter Handlungsbedarf',
        value: unknownDueDateBuildings,
        color: '#c5c5c5',
        percentage: buildingsWithRequest?.length
          ? Number(
              (
                (unknownDueDateBuildings / buildingsWithRequest.length) *
                100
              ).toFixed(2)
            )
          : 0,
      },
      {
        id: 'HEALTHY',
        name: 'Kein Handlungsbedarf',
        value: healthyBuildings,
        color: '#0f8c77',
        percentage: buildingsWithRequest?.length
          ? Number(
              ((healthyBuildings / buildingsWithRequest.length) * 100).toFixed(
                2
              )
            )
          : 0,
      },
      {
        id: 'NEXT_MONTH',
        name: 'Empfohlener Handlungsbedarf',
        value: nextMonthBuildings,
        color: '#1D66A3',
        percentage: buildingsWithRequest?.length
          ? Number(
              (
                (nextMonthBuildings / buildingsWithRequest.length) *
                100
              ).toFixed(2)
            )
          : 0,
      },
      {
        id: 'UPCOMING',
        name: 'Akuter Handlungsbedarf',
        value: upcomingBuildings,
        color: '#ffcc00',
        percentage: buildingsWithRequest?.length
          ? Number(
              ((upcomingBuildings / buildingsWithRequest.length) * 100).toFixed(
                2
              )
            )
          : 0,
      },
    ].filter((data) => data.value !== 0);
  }
  return result;
};

export const getGraphBgColorFromDueDate = (dueDate, hasCurrentRequest) => {
  // if object is in a request its healthy
  if (hasCurrentRequest) {
    return 'bg-green-green';
  }
  // if it is not in request and has no due date it is missing data
  if (!dueDate) {
    return 'bg-gray';
  }
  // if it is not in request and has due date we determine its status by its due date
  switch (getDueDateStatus(dueDate)) {
    case 'OVERDUE': {
      return 'bg-red';
    }
    case 'UPCOMING': {
      return 'bg-yellow';
    }
    case 'NEXT_MONTH': {
      return 'bg-blue-abs';
    }
    default:
      return 'bg-green-green';
  }
};

export const shouldShowHintInLegend = (graphData, hintId) => {
  return graphData && graphData.find((data) => data.id === hintId);
};

export const generateBuildingsDetailGraphData = (
  buildings?: BuildingDto[] | null,
  maintenanceObjects?: MaintenanceObjectDto[] | null
) => {
  const result: Array<{
    building: BuildingDto | undefined;
    maintenances?: MaintenanceObjectDto[];
  }> = [];

  buildings?.forEach((building) => {
    const maintenancesFromSameBuilding = maintenanceObjects?.filter(
      (maintenanceObject) => maintenanceObject.buildingId === building?.id
    );

    if (maintenancesFromSameBuilding?.length) {
      result.push({
        building,
        maintenances: maintenancesFromSameBuilding,
      });
    }
  });
  return result;
};
