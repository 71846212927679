import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { BuildingDto } from '@wartungshelden/shared-types';

import {
  Button,
  FilterCheckboxList,
  PageContainer,
  UserMaintenanceTable,
} from '../../../components';
import { PageHeaderOverview } from '../../../components/Basics/Layouts/PageHeader';
import Loader from '../../../components/Basics/Loaders/Loader';
import {
  ReadableServiceType,
  readableServiceType,
} from '../../../constants/MaintenanceConstants';
import { maintenanceRoutes } from '../../../constants/routes';
import { useBuildings } from '../../../services/api/buildings/buildings-api';
import { useMaintenanceObjects } from '../../../services/api/maintenance-objects/maintenance-objects-api';
import {
  MaintenanceBuildingFilterKey,
  MaintenanceServiceTypeFilterKey,
} from '../../../services/filter';

const MaintenancePage = () => {
  const navigation = useNavigate();

  const { data: buildings, isLoading: buildingsLoading } = useBuildings();
  const { data: maintenances, isLoading: objectsLoading } =
    useMaintenanceObjects();

  const [selectedBuildings, setSelectedBuildings] = useState<
    BuildingDto[] | null
  >();

  const [selectedServiceTypes, setSelectedServiceTypes] = useState<
    ReadableServiceType[] | null
  >();

  const noBuildings = buildings?.length === 0;
  const noMaintenances = maintenances?.length === 0;
  const isLoading = objectsLoading || buildingsLoading;

  const maintenancesToShow = maintenances
    ?.filter((maintenance) =>
      selectedBuildings?.find(({ id }) => maintenance?.buildingId === id)
    )
    ?.filter((maintenance) =>
      selectedServiceTypes?.find(
        ({ label }) =>
          maintenance && label === readableServiceType(maintenance.type).label
      )
    );

  const buildingOptions = buildings?.filter((building) =>
    maintenances?.find((maintenance) => maintenance.buildingId === building.id)
  );

  const maintenanceTypeOptions: ReadableServiceType[] | undefined =
    maintenances?.map((maintenance) => readableServiceType(maintenance.type));

  return (
    <PageContainer>
      <PageHeaderOverview>
        <div className="pl-4">Wartungsaufgaben</div>
        {!noBuildings && !noMaintenances && (
          <Button
            className="primary-button small-button"
            onClick={() => {
              navigation(maintenanceRoutes.add);
            }}
            icon={<FaPlus />}
            label="Wartung"
          />
        )}
      </PageHeaderOverview>
      <div className="flex h-full">
        {noMaintenances ? null : (
          <div className="flex flex-col h-full mr-6 space-y-4 pb-24">
            <div className="h-1/2 w-96">
              {buildingOptions && (
                <FilterCheckboxList
                  label="Gebäude"
                  filterKey={MaintenanceBuildingFilterKey}
                  discriminator="id"
                  labelProp="name"
                  items={buildingOptions}
                  selectedItems={selectedBuildings}
                  setSelectedItems={(items) => {
                    setSelectedBuildings(items);
                  }}
                />
              )}
            </div>

            <div className="h-1/2 w-96">
              {maintenanceTypeOptions && (
                <FilterCheckboxList
                  label="Wartungstyp"
                  filterKey={MaintenanceServiceTypeFilterKey}
                  labelProp="label"
                  discriminator="label"
                  items={maintenanceTypeOptions}
                  selectedItems={selectedServiceTypes}
                  setSelectedItems={(items) => {
                    setSelectedServiceTypes(items);
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div className="w-full h-full">
          {isLoading || !maintenancesToShow ? (
            <div className="flex py-4 justify-center items-center bg-white">
              <Loader />
            </div>
          ) : (
            <div className="h-2/3">
              <UserMaintenanceTable
                key="MaintenancePageTable"
                maintenances={maintenancesToShow}
                buildings={buildings}
                noBuildings={noBuildings}
                isLoading={isLoading}
                noMaintenances={noMaintenances}
              />
            </div>
          )}
          {!noMaintenances &&
            !maintenances?.every(
              (maintenance) => maintenance.currentRequestId
            ) && (
              <div className="flex justify-end mt-6">
                <Button
                  className="primary-button small-button"
                  onClick={() => {
                    navigation(maintenanceRoutes.request);
                  }}
                  label="Anfrage erstellen"
                />
              </div>
            )}
        </div>
      </div>
    </PageContainer>
  );
};

export default MaintenancePage;
