import { useMutation } from 'react-query';

import {
  MaintenancePriceDto,
  PriceInformationDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

const usePriceCalculation = () => {
  const axios = useAxios();

  const fetchPrice = async (
    inputData: PriceInformationDto
  ): Promise<MaintenancePriceDto | undefined> => {
    if (!axios) return undefined;
    const { data } = await axios.post<MaintenancePriceDto>(
      '/maintenance-exchange/maintenance/objects/price',
      inputData
    );
    return data;
  };

  return useMutation('price-calculation', fetchPrice);
};

export default usePriceCalculation;
