import { FieldArray, Formik } from 'formik';
import React from 'react';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import * as Yup from 'yup';

import {
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceDiscountDto,
} from '@wartungshelden/shared-types';

import { Button, InputField } from '../../../components';
import UnitInputField from '../../../components/Basics/Inputs/UnitInputField';
import ConfirmationModal from '../../../components/Basics/Modals/ConfirmationModal';

interface PriceAdoptionModalProps {
  isOpen?: boolean;
  offer: CustomerOrMaintenanceTeamOfferDto;
  onClose: () => void;
  onDiscountChange: (discounts: MaintenanceDiscountDto[]) => unknown;
}

const schema = Yup.object().shape({
  discounts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      value: Yup.number().required().min(-99).max(-1),
      type: Yup.string().required(),
    })
  ),
});

const DiscountModal: React.FC<
  React.PropsWithChildren<PriceAdoptionModalProps>
> = ({ offer, onClose, onDiscountChange, isOpen = false }) => {
  const hasDiscounts =
    offer.price?.discounts && offer.price?.discounts?.length > 0;

  return (
    <Formik
      initialValues={{
        discounts: hasDiscounts
          ? offer.price?.discounts
          : [
              {
                name: undefined,
                value: undefined,
                type: 'percentage',
              },
            ],
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={({ discounts }) => {
        // @ts-ignore
        onDiscountChange(discounts);
      }}
    >
      {({ submitForm, values, dirty, isValid, resetForm }) => {
        return (
          <ConfirmationModal
            isVisible={isOpen}
            heading=" Rabatt"
            onAccept={
              dirty && isValid
                ? () => {
                    submitForm().catch();
                  }
                : undefined
            }
            onCancel={() => {
              resetForm();
              onClose();
            }}
          >
            <div className="mb-4">
              <strong>Beachte:</strong> Der Rabatt wird auch auf{' '}
              <strong>Kundenseite angezeigt.</strong>
            </div>
            <FieldArray
              name="discounts"
              render={(arrayHelpers) => (
                <div className="pb-4">
                  {values.discounts &&
                    values.discounts.length > 0 &&
                    values.discounts.map((currentDiscount, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index}>
                        <div className="flex items-center space-x-2 mb-2">
                          <InputField
                            name={`discounts.${index}.name`}
                            type="text"
                            placeholder="Rabattname"
                          />
                          <UnitInputField
                            fullWith
                            name={`discounts.${index}.value`}
                            unit="%"
                            allowDecimals
                            allowNegativeValues
                            placeholder="- Rabatt"
                            shouldTurnNegative
                          />
                          <div className="w-24">
                            <Button
                              className="full-button"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <FaTrashAlt size={22} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    className={`"w-full my-2 flex ${
                      values.discounts.length === 0
                        ? 'justify-center'
                        : 'justify-start'
                    } mb-2"`}
                  >
                    <Button
                      className="primary-button small-button"
                      onClick={() => {
                        arrayHelpers.push({
                          name: '',
                          value: undefined,
                          type: 'percentage',
                        });
                      }}
                      icon={<FaPlusCircle />}
                      label="Weiteren Rabatt"
                    />
                  </div>
                </div>
              )}
            />
          </ConfirmationModal>
        );
      }}
    </Formik>
  );
};
DiscountModal.defaultProps = {
  isOpen: undefined,
};

export default DiscountModal;
