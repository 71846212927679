import React from 'react';

import { PersonalProtectionEquipmentDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface PpePropertiesProps {
  ppe: PersonalProtectionEquipmentDto;
  hasSubprices: boolean;
}

const PpeProperties: React.FC<PpePropertiesProps> = ({ ppe, hasSubprices }) => (
  <MaintenanceObjectSummaryRow
    label="PSAgA"
    value={ppe.quantity}
    hasSubprices={hasSubprices}
    unit="Stück"
  />
);

export default PpeProperties;
