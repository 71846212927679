import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ObjectSchema } from 'yup';

import {
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
  UpdateMaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { isServiceAlreadySupported } from '../../../../constants/MaintenanceConstants';
import { routes } from '../../../../constants/routes';
import { isFormikFallProtectionMaintenanceObject } from '../../../../guards/isFormikMaintenanceObject';
import { useMaintenanceDocumentationsForBuilding } from '../../../../services/api/maintenance-documentation/maintenance-documentation-api';
import {
  useMaintenanceObjects,
  useUpdateMaintenanceObject,
} from '../../../../services/api/maintenance-objects/maintenance-objects-api';
import {
  maintenanceObjectDtoToFormikMaintenanceType,
  wizardValuesToUpdateMaintenanceObjectDto,
} from '../../../../services/maintenanceService';
import {
  formatToMonth,
  monthToDate,
} from '../../../../services/timeDateService';
import {
  labelToProvidedService,
  providedServiceToLabel,
} from '../MaintenanceObject';
import MaintenanceWizardUpdateForm from './MaintenanceWizardUpdateForm';
import { FormikMaintenanceWizardValues } from './types/FormikMaintenanceWizardValues';

interface MaintenanceWizardUpdatePageProps {
  maintenanceObjectId: string;
  activeStep: number;
  validationSchema: ObjectSchema<any>;
  setActiveStep: (state: number | ((state: number) => number)) => void;
  isLoading: boolean;
  setDocumentationsFor: (
    existingMaintenanceDocumentations: MaintenanceDocumentationDto[],
    objects: {
      createdObject?: MaintenanceObjectDto;
      selectedFiles: Array<File | MaintenanceDocumentationDto>;
    }[]
  ) => Promise<void>;
}

const MaintenanceWizardUpdatePage: React.FC<
  React.PropsWithChildren<MaintenanceWizardUpdatePageProps>
> = ({
  maintenanceObjectId,
  validationSchema,
  activeStep,
  setActiveStep,
  setDocumentationsFor,
  isLoading,
}) => {
  const { data: maintenanceObjects } = useMaintenanceObjects();
  const navigate = useNavigate();

  const { mutateAsync: updateMaintenanceObject, isLoading: isUpdating } =
    useUpdateMaintenanceObject();

  const maintenanceObject = maintenanceObjects?.find(
    (object) => object.id === maintenanceObjectId
  );

  const { data: existingMaintenanceDocumentations } =
    useMaintenanceDocumentationsForBuilding(maintenanceObject?.buildingId);

  const isMaintenanceReadOnly =
    maintenanceObject !== undefined &&
    maintenanceObject.currentRequestId !== undefined;

  const submitFormikForm = async (values: FormikMaintenanceWizardValues) => {
    if (!maintenanceObject) return;

    const updatedObject: UpdateMaintenanceObjectDto =
      values.type &&
      isServiceAlreadySupported(labelToProvidedService(values.type))
        ? wizardValuesToUpdateMaintenanceObjectDto(maintenanceObject, values)
        : {
            id: maintenanceObject.id,
            name: '',
            description: values.description,
            buildingId: values.buildingId,
            created: maintenanceObject.created,
            dueDate: values.dueDate ? monthToDate(values.dueDate) : undefined,
            frequency: values.frequency === '' ? undefined : values.frequency,
            type: {
              name: labelToProvidedService(values.type || ''),
              type: 'other_type',
            },
          };

    const response = await updateMaintenanceObject(updatedObject);

    if (isFormikFallProtectionMaintenanceObject(values.maintenanceObjects[0])) {
      await setDocumentationsFor(values.uploadedMaintenanceDocumentations, [
        {
          createdObject: response?.data,
          selectedFiles: values.maintenanceObjects[0].selectedFiles,
        },
      ]);
    }
    navigate(`/${routes.maintenance}`);
  };

  if (!maintenanceObject) return null;

  const initialValues: FormikMaintenanceWizardValues = {
    name: maintenanceObject.name,
    buildingId: maintenanceObject.buildingId,
    dueDate: maintenanceObject.dueDate
      ? formatToMonth(maintenanceObject.dueDate)
      : undefined,
    frequency: maintenanceObject.frequency,
    uploadedMaintenanceDocumentations: existingMaintenanceDocumentations ?? [],
    type: providedServiceToLabel(
      maintenanceObject.type.type === 'other_type'
        ? maintenanceObject.type.name
        : maintenanceObject.type.type
    ),
    maintenanceObjects:
      maintenanceObject &&
      isServiceAlreadySupported(maintenanceObject.type.type)
        ? maintenanceObjectDtoToFormikMaintenanceType(
            maintenanceObject,
            existingMaintenanceDocumentations
          )
        : [],
    description: maintenanceObject.description,
  };

  return (
    <Formik
      onSubmit={(values) => submitFormikForm(values)}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {() => {
        return (
          <MaintenanceWizardUpdateForm
            activeStep={activeStep}
            isMaintenanceReadOnly={isMaintenanceReadOnly}
            isLoading={isLoading || isUpdating}
            setActiveStep={setActiveStep}
            maintenanceObject={maintenanceObject}
          />
        );
      }}
    </Formik>
  );
};

export default MaintenanceWizardUpdatePage;
