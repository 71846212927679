// eslint-disable-next-line import/prefer-default-export
export const palette = {
  black: '#1d1d1d',
  white: '#ffffff',
  offWhite: '#e6e6e6',
  orange: '#FBA928',
  orangeDarker: '#EB9918',
  lightGrey: '#939AA4',
  lighterGrey: '#CDD4DA',
  absGrey: '#666666',
  angry: '#dd3333',
  deepPurple: '#5D2555',
  absRed: '#972810',
  absBlue: '#124067',
  wartungBlue: '#3371B0',
  red: '#b5131d', // ABS primary
  redDark: '#870e16',
  redComplementary: '#4aece2',
  green: '#0f8c77', // Lock book primary
  greenDark: '#0d7362',
  greenComplementary: '#f07388',
  grayLight: '#f5f5f5',
  grayLighter: '#e5e5e5',
  grayDarker: '#c5c5c5',
  grayDark: '#9c9c9c',
  yellow: '#ffcc00',
};
