import React, { useContext } from 'react';

import {
  CustomerMaintenancePriceDto,
  MaintenanceDiscountDto,
  MaintenanceOfferStatusDto,
  MaintenancePriceDto,
} from '@wartungshelden/shared-types';

import { SessionContext } from '../../../contexts/SessionContext';
// eslint-disable-next-line import/no-cycle
import { userShouldSeeOffer } from '../../../pages/Request/RequestDetailsPage';
import formatEuros from '../../../services/format';

type PriceCompositionProps = {
  price: MaintenancePriceDto | CustomerMaintenancePriceDto;
  excelPrice?: MaintenancePriceDto | CustomerMaintenancePriceDto;
  offerStatus?: MaintenanceOfferStatusDto;
};

const calculateDiscount = (
  priceToAdapt: number,
  discount: MaintenanceDiscountDto
) => {
  // discount is a negative number!
  if (discount.type === 'percentage') {
    return priceToAdapt * (discount.value / 100);
  }

  return priceToAdapt + discount.value;
};

const calculateDiscounts = (
  priceToAdapt: number,
  discounts: MaintenanceDiscountDto[]
) => {
  return discounts.reduce(
    (currentDiscount, discount) =>
      currentDiscount + calculateDiscount(priceToAdapt, discount),
    0
  );
};

const priceDisplayValues = (
  isMaintenanceTeamMemberAdmin: boolean,
  price?: MaintenancePriceDto | CustomerMaintenancePriceDto,
  offerStatus?: MaintenanceOfferStatusDto
) => {
  if (!price) {
    return null;
  }

  const hasPriceAdaption = price.priceAdaptation?.value !== undefined;
  const hasPriceDiscount = Boolean(price.discounts && price?.discounts?.length);
  const priceDiscounts = (hasPriceDiscount && price.discounts) || null;
  const userSeesOffer = userShouldSeeOffer(offerStatus);
  const shouldShowOfferDetails = userSeesOffer || isMaintenanceTeamMemberAdmin;

  const maxTotalPrice =
    (price.netTotal ?? 0) + (price.netDocumentationSavings ?? 0);

  const priceAdaption = hasPriceDiscount ? price.priceAdaptation : null;

  const actualAfterPriceAdaption =
    price.netTotal && hasPriceAdaption && shouldShowOfferDetails
      ? price.netTotal + (price?.priceAdaptation?.value ?? 0)
      : price.netTotal;

  const actualAfterPriceDiscountAdaption =
    actualAfterPriceAdaption && priceDiscounts && shouldShowOfferDetails
      ? actualAfterPriceAdaption +
        calculateDiscounts(actualAfterPriceAdaption, priceDiscounts)
      : actualAfterPriceAdaption;

  const maxVatTotal =
    actualAfterPriceDiscountAdaption && actualAfterPriceDiscountAdaption * 1.19;

  const vat = maxVatTotal
    ? maxVatTotal - actualAfterPriceDiscountAdaption
    : null;

  const savings = price.netDocumentationSavings;

  const shouldShowSavings =
    userSeesOffer &&
    !isMaintenanceTeamMemberAdmin &&
    savings !== null &&
    Math.abs(savings) !== 0;

  return {
    maxTotalPrice,
    priceAdaption,
    priceDiscounts,
    actualAfterPriceAdaption,
    actualAfterPriceDiscountAdaption,
    maxVatTotal,
    vat,
    shouldShowSavings,
    savings,
  };
};

const PriceComposition: React.FC<
  React.PropsWithChildren<PriceCompositionProps>
> = ({ price, excelPrice, offerStatus }) => {
  const { isMaintenanceTeamMemberAdmin } = useContext(SessionContext);

  const priceADisplay = priceDisplayValues(
    isMaintenanceTeamMemberAdmin,
    price,
    offerStatus
  );

  const priceBDisplay = priceDisplayValues(
    isMaintenanceTeamMemberAdmin,
    excelPrice
      ? {
          ...excelPrice,
          priceAdaptation: price.priceAdaptation,
          discounts: price.discounts,
        }
      : undefined,
    offerStatus
  );

  return (
    <>
      {isMaintenanceTeamMemberAdmin && priceADisplay?.priceAdaption && (
        <tr className="border-t-2 border-black">
          <td
            colSpan={isMaintenanceTeamMemberAdmin ? 5 : 4}
            className="text-right"
          >
            {priceADisplay?.priceAdaption?.reason}:
          </td>

          <td className="text-right pl-4 pr-2">
            {priceADisplay && formatEuros(priceADisplay?.priceAdaption?.value)}
          </td>
          {priceBDisplay && (
            <td className="text-right pl-4 pr-2">
              {priceBDisplay &&
                formatEuros(priceBDisplay?.priceAdaption?.value)}
            </td>
          )}
        </tr>
      )}

      <tr className="border-t-2 border-black">
        <td
          colSpan={isMaintenanceTeamMemberAdmin ? 5 : 4}
          className="text-right"
        >
          Preis (netto, gerundet):
        </td>

        <td className="text-right pl-4 pr-2">
          {priceADisplay &&
            formatEuros(priceADisplay?.actualAfterPriceAdaption)}
        </td>
        {priceBDisplay && (
          <td className="text-right pl-4 pr-2">
            {priceBDisplay &&
              formatEuros(priceBDisplay?.actualAfterPriceAdaption)}
          </td>
        )}
      </tr>

      {priceADisplay?.priceDiscounts?.map((discount) => (
        <tr className="text-red">
          <td
            data-cy="price-discount-reason"
            colSpan={isMaintenanceTeamMemberAdmin ? 5 : 4}
            className="text-right"
          >
            {`${discount.name} (${discount.value}${
              discount.type === 'percentage' ? '%' : '€'
            } Rabatt):`}
          </td>

          <td className="text-right pl-4 pr-2">
            {formatEuros(
              calculateDiscount(
                priceADisplay?.actualAfterPriceAdaption ?? 0,
                discount
              )
            )}
          </td>
          {priceBDisplay && (
            <td className="text-right pl-4 pr-2">
              {formatEuros(
                calculateDiscount(
                  priceADisplay?.actualAfterPriceAdaption ?? 0,
                  discount
                )
              )}
            </td>
          )}
        </tr>
      ))}

      <tr className="border-t-4 border-black font-bold">
        <td
          colSpan={isMaintenanceTeamMemberAdmin ? 5 : 4}
          className="text-right"
        >
          Angebotspreis (netto):
        </td>
        <td className="text-right pl-4 pr-2">
          {priceADisplay &&
            formatEuros(priceADisplay?.actualAfterPriceDiscountAdaption)}
        </td>
        {priceBDisplay && (
          <td className="text-right pl-4 pr-2">
            {priceBDisplay &&
              formatEuros(priceBDisplay?.actualAfterPriceDiscountAdaption)}
          </td>
        )}
      </tr>

      <tr>
        <td
          colSpan={isMaintenanceTeamMemberAdmin ? 5 : 4}
          className="text-right"
        >
          19% USt:
        </td>
        <td className="text-right pl-4 pr-2">
          {priceADisplay && formatEuros(priceADisplay.vat)}
        </td>
        {priceBDisplay && (
          <td className="text-right pl-4 pr-2">
            {priceBDisplay && formatEuros(priceBDisplay.vat)}
          </td>
        )}
      </tr>

      <tr>
        <td
          colSpan={isMaintenanceTeamMemberAdmin ? 5 : 4}
          className="text-right"
        >
          Angebotspreis (brutto):
        </td>
        <td className="text-right pl-4 pr-2">
          {priceADisplay && formatEuros(priceADisplay?.maxVatTotal)}
        </td>
        {priceBDisplay && (
          <td className="text-right pl-4 pr-2">
            {priceBDisplay && formatEuros(priceBDisplay?.maxVatTotal)}
          </td>
        )}
      </tr>
    </>
  );
};

PriceComposition.defaultProps = {
  offerStatus: undefined,
  excelPrice: undefined,
};

export default PriceComposition;
