import React from 'react';

import { PersonalProtectionEquipmentDto } from '@wartungshelden/shared-types';

import TextWithLabel from '../../../../../components/Basics/TextWithLabel';
import { toPPECategoryLabel } from '../../utils/utils';

interface GliderAsCombinationPropertiesProps {
  glider: PersonalProtectionEquipmentDto;
  hasSubprices: boolean;
}

const GliderAsCombinationProperties: React.FC<
  GliderAsCombinationPropertiesProps
> = ({ glider, hasSubprices }) => (
  <TextWithLabel
    dataCy="summary-Gleiter"
    label={`${glider?.systemType && toPPECategoryLabel(glider.systemType)}${
      hasSubprices ? ' (gesamt)' : ''
    }:`}
    labelStyle="font-normal w-96"
  >
    {`${glider.quantity} Stück`}
  </TextWithLabel>
);

export default GliderAsCombinationProperties;
