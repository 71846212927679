import { useFormikContext } from 'formik';
import React from 'react';
import TagManager from 'react-gtm-module';
import { v4 as uuidv4 } from 'uuid';

import {
  PersonalProtectionEquipmentDto,
  PriceInformationDto,
  PriceInformationObjectDto,
  PriceableObjectDto,
} from '@wartungshelden/shared-types';

import { Button } from '../../../../../components';
import Modal from '../../../../../components/Basics/Modals/Modal';
import {
  MANUFACTURERS,
  PSAGA_SYSTEM_TYPES,
} from '../../../../../constants/MaintenanceConstants';

interface AddPsaModalProps {
  showAddPSAModal: boolean;
  setShowAddPSAModal: (showAddPsaModal: boolean) => void;
}

const AddPsaModal: React.FC<AddPsaModalProps> = ({
  showAddPSAModal,
  setShowAddPSAModal,
}) => {
  const { values, setFieldValue } = useFormikContext<PriceInformationDto>();
  return (
    <Modal
      isOpen={showAddPSAModal}
      headline="PSAgA-Set hinzufügen"
      center
      width="one-third"
    >
      <div className="mb-12" data-cy="addPsaModalContent">
        <p className="mb-4">
          In Deiner Preisberechnung ist aktuell noch keine PSAgA aufgelistet.
          Standardmäßig bieten wir als ABS Safety GmbH bei Wartungen von
          Absturzsicherungen ein PSAgA-Set an.
        </p>
        <p> Möchtest Du jetzt ein PSAgA-Set hinzufügen?</p>
      </div>
      <div className="flex justify-around">
        <Button
          onClick={() => setShowAddPSAModal(false)}
          className="outline-button mr-2"
        >
          Nein, danke
        </Button>
        <Button
          onClick={async () => {
            const psaPriceObject: PriceInformationObjectDto<
              PriceableObjectDto<PersonalProtectionEquipmentDto>
            > = {
              object: {
                id: uuidv4(),
                type: {
                  type: 'personal_protection_equipment',
                  systemType: PSAGA_SYSTEM_TYPES.PPE_SET.value,
                  quantity: 1,
                },
                manufacturer: MANUFACTURERS[0],
              },
              building: {
                buildingType: '',
                isPublic: false,
              },
            };

            setFieldValue('objectInformation', [
              ...values.objectInformation,
              psaPriceObject,
            ]);
            setShowAddPSAModal(false);
            TagManager.dataLayer({
              dataLayer: {
                event: 'add_object',
                trackingId: `Modal_${psaPriceObject.object.type.systemType}`,
              },
            });
          }}
        >
          1x PSAgA-Set von ABS Safety
        </Button>
      </div>
    </Modal>
  );
};

export default AddPsaModal;
