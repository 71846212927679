import { Form, Formik } from 'formik';
import React, { useContext, useMemo, useState } from 'react';

import {
  CustomerMaintenanceRequestDto,
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceOfferStatusDto,
  MaintenanceOfferStatusDtoEnum,
  MaintenanceOrderStatusDto,
  MaintenanceOrderStatusDtoEnum,
  MaintenanceRequestDto,
  MaintenanceRequestStatus,
  RequestStatusEnum,
  requestStatusToLabel,
  statusToUse,
} from '@wartungshelden/shared-types';

import { DropdownFieldOption } from '../../../components/Basics/Inputs/DropdownField';
import DropdownFieldLayout from '../../../components/Basics/Inputs/DropdownFieldLayout';
import ConfirmationModal from '../../../components/Basics/Modals/ConfirmationModal';
import {
  DOCUMENTATION_NOT_VALIDATED_ERROR,
  HintHighlightContext,
  MAINTENANCE_OBJECT_DOCUMENTATION_NOT_VALIDATED_ERROR,
  OFFER_NOT_UPLOADED_ERROR,
  ORDER_CONFIRMATION_NOT_UPLOADED_ERROR,
} from '../../../contexts/HintHighlightContext';
import { useMaintenanceDocumentations } from '../../../services/api/maintenance-documentation/maintenance-documentation-api';
import { useMaintenanceObjects } from '../../../services/api/maintenance-objects/maintenance-objects-api';
import { useUpdateMaintenanceOffer } from '../../../services/api/maintenance-offers/maintenance-offer-api';
import { useUpdateMaintenanceRequest } from '../../../services/api/maintenance-requests/maintenance-request-api';
import { getSelectedFiles } from '../../../services/maintenanceService';

const requestStatusToOption = (
  status:
    | MaintenanceRequestStatus
    | MaintenanceOfferStatusDto
    | MaintenanceOrderStatusDto,
  disabled = false
): DropdownFieldOption => {
  return {
    label: requestStatusToLabel(true, status),
    value: status,
    disabled,
  };
};

const optionsForStatus = (
  status:
    | MaintenanceRequestStatus
    | MaintenanceOfferStatusDto
    | MaintenanceOrderStatusDto
): DropdownFieldOption[] => {
  switch (status) {
    case RequestStatusEnum.REQUESTED:
    case RequestStatusEnum.VALIDATING:
    case MaintenanceOfferStatusDtoEnum.DRAFT:
    case MaintenanceOfferStatusDtoEnum.SENT:
      return [
        requestStatusToOption(RequestStatusEnum.REQUESTED),
        requestStatusToOption(RequestStatusEnum.VALIDATING),
        requestStatusToOption(RequestStatusEnum.CANCELED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.SENT),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.DECLINED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.ACCEPTED, true),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CONFIRMED, true),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CLOSED, true),
      ];
    case MaintenanceOfferStatusDtoEnum.ACCEPTED:
    case MaintenanceOrderStatusDtoEnum.UNCONFIRMED:
      return [
        requestStatusToOption(RequestStatusEnum.REQUESTED, true),
        requestStatusToOption(RequestStatusEnum.VALIDATING, true),
        requestStatusToOption(RequestStatusEnum.CANCELED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.SENT, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.DECLINED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.ACCEPTED, false),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CONFIRMED, false),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CLOSED, true),
      ];
    case MaintenanceOrderStatusDtoEnum.CONFIRMED:
      return [
        requestStatusToOption(RequestStatusEnum.REQUESTED, true),
        requestStatusToOption(RequestStatusEnum.VALIDATING, true),
        requestStatusToOption(RequestStatusEnum.CANCELED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.SENT, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.DECLINED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.ACCEPTED, true),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CONFIRMED, false),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CLOSED, false),
      ];
    case MaintenanceOrderStatusDtoEnum.CLOSED:
      return [
        requestStatusToOption(RequestStatusEnum.REQUESTED, true),
        requestStatusToOption(RequestStatusEnum.VALIDATING, true),
        requestStatusToOption(RequestStatusEnum.CANCELED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.SENT, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.DECLINED, true),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.ACCEPTED, true),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CONFIRMED, true),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CLOSED, false),
      ];
    case RequestStatusEnum.CANCELED:
    case MaintenanceOfferStatusDtoEnum.DECLINED:
      return [
        requestStatusToOption(RequestStatusEnum.REQUESTED, true),
        requestStatusToOption(RequestStatusEnum.VALIDATING, true),
        requestStatusToOption(
          RequestStatusEnum.CANCELED,
          status !== RequestStatusEnum.CANCELED
        ),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.SENT, true),
        requestStatusToOption(
          MaintenanceOfferStatusDtoEnum.DECLINED,
          status !== MaintenanceOfferStatusDtoEnum.DECLINED
        ),
        requestStatusToOption(MaintenanceOfferStatusDtoEnum.ACCEPTED, true),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CONFIRMED, true),
        requestStatusToOption(MaintenanceOrderStatusDtoEnum.CLOSED, true),
      ];
    default:
      return [];
  }
};

const findInvalidatedDocumentation = (
  maintenancesOfRequest,
  existingMaintenanceDocumentations
) => {
  if (maintenancesOfRequest && existingMaintenanceDocumentations) {
    // check every object and find if one has documentation that is not validated
    return maintenancesOfRequest?.map((maintenanceObject) => {
      const requestDocuments = getSelectedFiles(
        maintenanceObject.id,
        existingMaintenanceDocumentations
      );
      const maintenanceObjectId = maintenanceObject.id;
      // find any document that is not validated
      return requestDocuments.filter((document) => {
        return (
          !document?.validFor?.includes(maintenanceObjectId) &&
          !document?.invalidFor?.includes(maintenanceObjectId) &&
          !document?.notRelevantFor?.includes(maintenanceObjectId)
        );
      });
    });
  }
  return [];
};

const AdminStatusDropdown: React.FC<
  React.PropsWithChildren<{
    request: MaintenanceRequestDto | CustomerMaintenanceRequestDto;
    offer?: CustomerOrMaintenanceTeamOfferDto | null;
  }>
> = ({ request, offer }) => {
  const { addHighlight } = useContext(HintHighlightContext);

  const [isOfferDocumentNeededModalOpen, setIsOfferDocumentNeededModalOpen] =
    useState(false);

  const [
    isOrderConfirmationNeededModalOpen,
    setIsOrderConfirmationNeededModalOpen,
  ] = useState(false);

  const { mutateAsync: updateMaintenanceRequest } =
    useUpdateMaintenanceRequest();

  const { mutateAsync: updateMaintenanceOffer } = useUpdateMaintenanceOffer();

  const { data: maintenances } = useMaintenanceObjects();

  const { data: existingMaintenanceDocumentations } =
    useMaintenanceDocumentations();

  const status = statusToUse(
    request.status,
    offer?.decision.status,
    offer?.order?.status
  );

  const maintenancesOfRequest = maintenances?.filter(
    (maintenance) => maintenance.currentRequestId === request?.id
  );

  const submitStatus = async (
    statusToSubmit:
      | MaintenanceRequestStatus
      | MaintenanceOfferStatusDto
      | MaintenanceOrderStatusDto
  ) => {
    if (Object.values<string>(RequestStatusEnum).includes(statusToSubmit)) {
      await updateMaintenanceRequest({
        id: request.id,
        status: statusToSubmit as MaintenanceRequestStatus,
      }).catch();
    } else if (
      offer &&
      offer.decision.status !== MaintenanceOfferStatusDtoEnum.ACCEPTED &&
      Object.values<string>(MaintenanceOfferStatusDtoEnum).includes(
        statusToSubmit
      )
    ) {
      await updateMaintenanceOffer({
        id: offer.id,
        decision: {
          date: new Date(),
          status: statusToSubmit as MaintenanceOfferStatusDto,
        },
      }).catch();
    } else if (
      offer?.order &&
      offer?.decision.status === MaintenanceOfferStatusDtoEnum.ACCEPTED &&
      statusToSubmit === MaintenanceOfferStatusDtoEnum.ACCEPTED
    ) {
      await updateMaintenanceOffer({
        id: offer.id,
        order: {
          ...offer.order,
          status: MaintenanceOrderStatusDtoEnum.UNCONFIRMED,
        },
      }).catch();
    } else if (
      offer?.order &&
      (statusToSubmit === MaintenanceOrderStatusDtoEnum.CONFIRMED ||
        statusToSubmit === MaintenanceOrderStatusDtoEnum.CLOSED)
    ) {
      await updateMaintenanceOffer({
        id: offer.id,
        order: {
          ...offer.order,
          status: statusToSubmit as MaintenanceOrderStatusDto,
        },
      }).catch();
    }
  };

  const isConfirmationDocumentUploaded =
    !!offer?.order?.confirmationDocumentName;

  const notValidatedDocumentation = useMemo(
    () =>
      findInvalidatedDocumentation(
        maintenancesOfRequest,
        existingMaintenanceDocumentations
      ).flat(),
    [maintenancesOfRequest, existingMaintenanceDocumentations]
  );

  const hasInvalidatedDocumentation = () =>
    notValidatedDocumentation.length > 0;

  const canChangeStatusToConfirmed = () =>
    !hasInvalidatedDocumentation() && isConfirmationDocumentUploaded;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          status,
        }}
        onSubmit={async (values) => {
          await submitStatus(values.status);
        }}
      >
        {({ setFieldValue, submitForm }) => (
          <Form className="w-64">
            <DropdownFieldLayout
              options={optionsForStatus(status)}
              name="status"
              onSelect={(value) => {
                switch (value) {
                  case MaintenanceOfferStatusDtoEnum.SENT:
                    if (offer?.documentName) {
                      setFieldValue('status', value, false);
                      submitForm().catch();
                    } else {
                      setIsOfferDocumentNeededModalOpen(true);
                      addHighlight(`${OFFER_NOT_UPLOADED_ERROR}${request?.id}`);
                    }
                    break;
                  case MaintenanceOrderStatusDtoEnum.CONFIRMED:
                    if (canChangeStatusToConfirmed()) {
                      setFieldValue('status', value, false);
                      submitForm().catch();
                    } else {
                      if (!isConfirmationDocumentUploaded) {
                        addHighlight(
                          `${ORDER_CONFIRMATION_NOT_UPLOADED_ERROR}${request.id}`
                        );
                      }
                      if (hasInvalidatedDocumentation()) {
                        notValidatedDocumentation.forEach(
                          (notValidatedDocument) => {
                            // find all maintenance objects that have not validated docs and add error hints
                            maintenancesOfRequest
                              ?.filter((re) =>
                                notValidatedDocument.maintenanceObjectIds?.some(
                                  (maintenanceObjectId) => {
                                    return re.id === maintenanceObjectId;
                                  }
                                )
                              )
                              .forEach((objectWithDocError) => {
                                addHighlight(
                                  `${MAINTENANCE_OBJECT_DOCUMENTATION_NOT_VALIDATED_ERROR}${objectWithDocError.id}`
                                );
                              });

                            // add error hint for every document
                            addHighlight(
                              `${DOCUMENTATION_NOT_VALIDATED_ERROR}${notValidatedDocument.id}`
                            );
                          }
                        );
                      }
                      setIsOrderConfirmationNeededModalOpen(true);
                    }
                    break;
                  default:
                    setFieldValue('status', value, false);
                    submitForm().catch();
                    break;
                }
              }}
            />
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        useMinHeight={false}
        isFullHeight={false}
        isVisible={isOfferDocumentNeededModalOpen}
        onAccept={() => setIsOfferDocumentNeededModalOpen(false)}
        heading="Angebotsdokument hinzufügen"
      >
        <div className="flex h-12">
          Bitte lade ein Angebotsdokument hoch, bevor Du das Angebot versendest.
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        useMinHeight={false}
        isFullHeight={false}
        isVisible={isOrderConfirmationNeededModalOpen}
        onAccept={() => setIsOrderConfirmationNeededModalOpen(false)}
        heading="Es fehlen Informationen"
      >
        <div className="flex flex-col">
          <div>
            {!isConfirmationDocumentUploaded &&
              `Bitte lade zuerst eine Auftragsbestätigung hoch, bevor Du den Status in "Beauftragt" änderst.`}
          </div>
          <div>
            {hasInvalidatedDocumentation() &&
              'Alle mitgelieferten Dateien bitte auf Gültigkeit prüfen.'}
          </div>
        </div>
      </ConfirmationModal>
    </>
  );
};

AdminStatusDropdown.defaultProps = {
  offer: undefined,
};

export default AdminStatusDropdown;
