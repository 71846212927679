import { Auth } from '@aws-amplify/auth';

import environment from '../environments/environment';

// eslint-disable-next-line import/prefer-default-export
export const Config = {
  environment: process.env.REACT_APP_ENV ?? 'local',
  clientId: process.env.NX_CLIENT_ID ?? 'mdlduoajp1vuel0hg98htlb5o',
  redirectUri:
    process.env.REDIRECT_URI ?? `${window.location.origin}/oauth-callback`,
  appProvider: process.env.NX_OAUTH_URL ?? 'https://sso.dev.abs-wartung.de',
  awsAPI:
    process.env.NX_AWS_API ??
    (environment.e2e
      ? 'http://localhost:8888/api/'
      : 'http://localhost:8080/api/'),
  auth: {
    region: process.env.NX_AWS_AUTH_REGION ?? 'eu-west-1',
    domain: process.env.NX_AWS_AUTH_DOMAIN ?? 'sso.dev.abs-wartung.de',
    redirectUri:
      process.env.NX_AWS_AUTH_REDIRECT_URI ??
      `${window.location.origin}/oauth-callback`,
    userPoolId:
      process.env.NX_AWS_AUTH_USER_POOL_ID ?? 'eu-central-1_WthnWOP8j',
    userPoolWebClientId:
      process.env.NX_AWS_AUTH_CLIENT_ID ?? 'mdlduoajp1vuel0hg98htlb5o',
  },
  userflow: {
    token: process.env.NX_USERFLOW ?? 'ct_o26xmigbkbctdhzoy7zv3n54qi',
  },
};

Auth.configure({
  region: Config.auth.region,
  userPoolId: Config.auth.userPoolId,
  userPoolWebClientId: Config.auth.userPoolWebClientId,
  oauth: {
    domain: Config.auth.domain,
    responseType: 'code',
    scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: Config.auth.redirectUri,
    redirectSignOut: Config.auth.redirectUri,
  },
});
