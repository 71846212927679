import React from 'react';

interface CardProps {
  paddingX?: number;
  paddingY?: number;
}

const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  children,
  paddingX,
  paddingY,
}) => (
  <div className={`w-full px-${paddingX} py-${paddingY} bg-white rounded`}>
    {children}
  </div>
);

Card.defaultProps = {
  paddingX: 12,
  paddingY: 6,
};

export default Card;
