import { Entity } from '@warthungshelden/domain/common';
import { notNull, to, toSum } from '@warthungshelden/shared-functions';

import { MaintenancePriceStrategyName } from './maintenance-price-strategy';

function toPrice() {
  return (currentPrice: number, { kind, value }: PriceComponent) => {
    if (kind === 'absolute') {
      return currentPrice + value;
    } else {
      return currentPrice * value;
    }
  };
}

function byType(type: PriceComponentType) {
  return (component: PriceComponent) => component.type === type;
}

function byPosition() {
  return (componentA: PriceComponent, componentB: PriceComponent) => {
    return componentA.position - componentB.position;
  };
}

function toAbsolutePriceComponents(components: PriceComponent[]) {
  const { components: absoluteComponents } = components.reduce(
    ({ components, currentSum }, currentComponent) => {
      const newComponent: PriceComponent = {
        position: currentComponent.position,
        kind: 'absolute',
        type: currentComponent.type,
        value:
          currentComponent.kind === 'absolute'
            ? currentComponent.value
            : currentSum * currentComponent.value - currentSum,
        reason: currentComponent.reason,
      };

      const newSum = currentSum + newComponent.value;

      return {
        components: [...components, newComponent],
        currentSum: newSum,
      };
    },
    { components: new Array<PriceComponent>(), currentSum: 0 }
  );

  return absoluteComponents;
}

export function roundUpTotalPrice(value: number) {
  if (value < 500) {
    const total = Math.ceil(value / 10) * 10;
    if (total % 100 == 0) {
      return total - 10;
    }
    return total;
  } else {
    return Math.round(value / 10) * 10;
  }
}

export type PriceComponentType =
  | 'base_price'
  | 'foreign_manufacturer'
  | 'documentation_missing'
  | 'location_surcharge'
  | 'public_building'
  | 'mass_discount'
  | 'has_ladder_discount'
  | 'no_easy_access'
  | 'fix_price_redistribution'
  | 'single_anchor_surcharge'
  | 'rail_system_surcharge';

export type PriceComponentKind = 'factor' | 'absolute';

export interface PriceComponent {
  position: number;
  type: PriceComponentType;
  kind: PriceComponentKind;
  value: number;
  reason: string;
}

export interface PriceComponents {
  readonly actual: PriceComponent[];
  readonly max: PriceComponent[];
}

export class MaintenancePriceAdaptation extends Entity {
  public readonly value: number;
  public readonly reason: string;

  constructor(config: { value: number; reason: string }) {
    super();
    this.value = config.value;
    this.reason = config.reason;
  }
}

export type MaintenanceDiscountType = 'percentage' | 'absolute';

export class MaintenanceDiscount extends Entity {
  public readonly name: string;
  public readonly value: number;
  public readonly type: MaintenanceDiscountType;

  constructor(config: {
    value: number;
    name: string;
    type: MaintenanceDiscountType;
  }) {
    super();
    this.value = config.value;
    this.name = config.name;
    this.type = config.type;
  }
}

export class MaintenanceObjectPrice extends Entity {
  public readonly objectId: string;

  public readonly unsortedFixedPriceComponents: PriceComponents;
  public readonly unsortedVariablePriceComponents: PriceComponents;

  constructor(config: {
    objectId: string;
    unsortedFixedPriceComponents: PriceComponents;
    unsortedVariablePriceComponents: PriceComponents;
  }) {
    super();
    this.objectId = config.objectId;
    this.unsortedFixedPriceComponents = config.unsortedFixedPriceComponents;
    this.unsortedVariablePriceComponents =
      config.unsortedVariablePriceComponents;
  }

  get fixedPriceComponents() {
    return {
      actual: this.unsortedFixedPriceComponents.actual.sort(byPosition()),
      max: this.unsortedFixedPriceComponents.max.sort(byPosition()),
    };
  }

  get variablePriceComponents() {
    return {
      actual: this.unsortedVariablePriceComponents.actual.sort(byPosition()),
      max: this.unsortedVariablePriceComponents.max.sort(byPosition()),
    };
  }

  get absoluteFixedPriceComponents() {
    return {
      actual: toAbsolutePriceComponents(this.fixedPriceComponents.actual),
      max: toAbsolutePriceComponents(this.fixedPriceComponents.max),
    };
  }

  get absoluteVariablePriceComponents() {
    return {
      actual: toAbsolutePriceComponents(this.variablePriceComponents.actual),
      max: toAbsolutePriceComponents(this.variablePriceComponents.max),
    };
  }

  get fixedNetPrice() {
    return {
      actual: this.absoluteFixedPriceComponents.actual.reduce(toPrice(), 0),
      max: this.absoluteFixedPriceComponents.max.reduce(toPrice(), 0),
    };
  }

  get variableNetPrice() {
    return {
      actual: this.absoluteVariablePriceComponents.actual.reduce(toPrice(), 0),
      max: this.absoluteVariablePriceComponents.max.reduce(toPrice(), 0),
    };
  }

  get documentationMissingSurcharge() {
    const fixedDocumentationSurcharge = this.absoluteFixedPriceComponents.actual
      .filter(byType('documentation_missing'))
      .reduce(toPrice(), 0);

    const variableDocumentationSurcharge =
      this.absoluteVariablePriceComponents.actual
        .filter(byType('documentation_missing'))
        .reduce(toPrice(), 0);

    return fixedDocumentationSurcharge + variableDocumentationSurcharge;
  }

  get maxDocumentationMissingSurcharge() {
    const fixedDocumentationSurcharge = this.absoluteFixedPriceComponents.max
      .filter(byType('documentation_missing'))
      .reduce(toPrice(), 0);

    const variableDocumentationSurcharge =
      this.absoluteVariablePriceComponents.max
        .filter(byType('documentation_missing'))
        .reduce(toPrice(), 0);

    return fixedDocumentationSurcharge + variableDocumentationSurcharge;
  }

  get netDocumentationSavings() {
    return (
      this.maxDocumentationMissingSurcharge - this.documentationMissingSurcharge
    );
  }
}

export class PriceUnsupportedMaintenanceObject extends Entity {
  public readonly objectId: string;
  public readonly reason: string | null;

  constructor(config: { objectId: string; reason: string | null }) {
    super();
    this.objectId = config.objectId;
    this.reason = config.reason;
  }
}

export class MaintenancePrice extends Entity {
  public readonly strategy: MaintenancePriceStrategyName;
  public readonly objectPrices: MaintenanceObjectPrice[];
  public readonly unsupportedObjects: PriceUnsupportedMaintenanceObject[];

  public readonly priceAdaptation?: MaintenancePriceAdaptation | null;
  public readonly discounts: MaintenanceDiscount[];

  constructor(config: {
    strategy: MaintenancePriceStrategyName;
    objectPrices: MaintenanceObjectPrice[];
    unsupportedObjects: PriceUnsupportedMaintenanceObject[];
    priceAdaptation?: MaintenancePriceAdaptation | null;
    discounts: MaintenanceDiscount[];
  }) {
    super();
    this.strategy = config.strategy;
    this.objectPrices = config.objectPrices;
    this.unsupportedObjects = config.unsupportedObjects;
    this.priceAdaptation = config.priceAdaptation;
    this.discounts = config.discounts;
  }

  public get netTotal(): number | null {
    const fixed = this.netFixed;
    const variable = this.netVariable;

    return fixed !== null && variable !== null
      ? roundUpTotalPrice(fixed + variable)
      : null;
  }

  public get netFixed(): number | null {
    if (!this.objectPrices.length) {
      return null;
    }

    const fixedValues = this.objectPrices
      .map(to('fixedNetPrice'))
      .map(to('actual'));

    return Math.max(...fixedValues);
  }

  public get netVariable(): number | null {
    if (!this.objectPrices.length) {
      return null;
    }

    const variableValues = this.objectPrices
      .map(to('variableNetPrice'))
      .map(to('actual'));

    return variableValues.reduce(toSum(), 0);
  }

  public get netDocumentationSavings(): number | null {
    if (!this.objectPrices.length) {
      return null;
    }

    const surcharges = this.objectPrices.map(to('netDocumentationSavings')); // TODO Check if fine
    if (!surcharges.every(notNull())) {
      return null;
    }

    return surcharges.filter(notNull()).reduce(toSum(), 0);
  }

  public get maximumDocumentationSavings(): number | null {
    if (!this.objectPrices.length) {
      return null;
    }

    const maxSurcharges = this.objectPrices.map(
      to('maxDocumentationMissingSurcharge')
    );

    if (!maxSurcharges.every(notNull())) {
      return null;
    }

    return maxSurcharges.filter(notNull()).reduce(toSum(), 0);
  }

  public withDiscount(discount: MaintenanceDiscount) {
    return (this as MaintenancePrice).copyWith({
      discounts: [...this.discounts, discount],
    });
  }
}
