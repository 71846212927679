import { isPhoneNumber } from 'class-validator';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import AuthInputField from '../../components/Auth/AuthInputField';
import AuthLinkButton from '../../components/Auth/AuthLinkButton';
import AuthPhoneInputField from '../../components/Auth/AuthPhoneInputField';
import AuthPrimaryButton from '../../components/Auth/AuthPrimaryButton';
import FieldError from '../../components/Basics/FieldError';
import { SessionContext } from '../../contexts/SessionContext';

const ResetPasswordPage = ({ isAdminLogin }: { isAdminLogin?: boolean }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [params] = useSearchParams();

  const { requestPasswordReset } = useContext(SessionContext);

  const initialEmail =
    ((state as any)?.email as string | undefined) ?? params.get('email');

  const initialPhone = !initialEmail
    ? ((state as any)?.phone as string | undefined) ?? params.get('phone')
    : undefined;

  const [isEmailSelected, setIsEmailSelected] = useState(!initialPhone);

  const navigateToLogin = (email?: string, phone?: string) => {
    navigate(isAdminLogin ? '/admin/login' : '/login', {
      state: { email, phone },
    });
  };

  const navigateToVerifyConfirm = (email?: string, phone?: string) => {
    navigate(
      isAdminLogin ? '/admin/login/reset/confirm' : '/login/reset/confirm',
      { state: { email, phone } }
    );
  };

  const ResetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-Mail nicht gültig')
      .when({
        is: () => isEmailSelected,
        then: (schema) => schema.required('E-Mail erforderlich'),
        otherwise: (schema) => schema.optional(),
      }),
    phone: Yup.string().when({
      is: () => !isEmailSelected,
      then: (schema) =>
        schema.test({
          message: 'Rufnummer nicht gültig',
          test: (number) => isPhoneNumber(number ?? ''),
        }),
      otherwise: (schema) => schema.optional(),
    }),
  });

  return (
    <Formik
      validationSchema={ResetPasswordValidationSchema}
      initialValues={{
        email: initialEmail ?? '',
        phone: initialPhone ?? '',
      }}
      onSubmit={async (values) => {
        const success = await requestPasswordReset(
          isEmailSelected ? values.email : values.phone
        );

        if (success) {
          navigateToVerifyConfirm(
            values.email.length ? values.email : undefined,
            values.phone.length ? values.phone : undefined
          );
        }
      }}
    >
      {({
        submitForm,
        isValid,
        dirty,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <div className="flex justify-center px-8 pt-4 pb-4">
            <img src="/assets/logo.svg" alt="ABS Wartung" className="w-3/5" />
          </div>

          <div className="px-5 py-7 bg-white shadow w-full rounded-lg mb-2">
            <div className="pt-3">
              {isEmailSelected ? (
                <AuthInputField name="email" label="E-Mail" />
              ) : (
                <AuthPhoneInputField name="phone" label="Telefon" />
              )}
              <AuthLinkButton
                label={`Stattdessen ${
                  isEmailSelected ? 'Telefonnummer' : 'E-Mail'
                } verwenden`}
                onClick={() => {
                  setFieldValue(isEmailSelected ? 'email' : 'phone', '');
                  setFieldTouched(isEmailSelected ? 'email' : 'phone', false);
                  setIsEmailSelected((prev) => !prev);
                }}
              />
              <FieldError
                message={errors.email}
                visible={Boolean(errors.email && touched.email)}
              />
              <FieldError
                message={errors.phone}
                visible={Boolean(errors.phone && touched.phone)}
              />
            </div>
            <div className="pt-7">
              <AuthPrimaryButton
                onClick={submitForm}
                label="Passwort zurücksetzen"
                disabled={!isValid || !dirty}
                className="primary-button full-button"
              />
            </div>
            <div className="py-5">
              <div className="grid grid-cols-1 gap-1">
                <AuthLinkButton label="Zum Login" onClick={navigateToLogin} />
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

ResetPasswordPage.defaultProps = {
  isAdminLogin: false,
};

export default ResetPasswordPage;
