import React, { useState } from 'react';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';

type HintLinkProps = {
  hintLabel: string;
};

const HintLink: React.FC<React.PropsWithChildren<HintLinkProps>> = ({
  hintLabel,
  children,
}) => {
  const [showHint, setShowHint] = useState<boolean>(false);
  return (
    <>
      <div
        role="link"
        tabIndex={0}
        className="flex items-center text-blue-abs cursor-pointer"
        onClick={() => setShowHint(!showHint)}
        onKeyDown={(event) => {
          if (event.key !== 'Enter') return;
          setShowHint(!showHint);
        }}
      >
        <div className="mr-1">
          {showHint ? <FaAngleDown /> : <FaAngleRight />}
        </div>
        <div>{hintLabel}</div>
      </div>
      {showHint && <div className="mt-2">{children}</div>}
    </>
  );
};

export default HintLink;
