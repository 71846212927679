import React, { useEffect } from 'react';

import { saveFilter } from '../../services/filter';
import CheckBox from '../Basics/Inputs/CheckBox/CheckBox';

type SelectAllCheckboxProps<
  T extends { [P in Key]: string } | { id: string },
  Key extends keyof T
> = {
  checked: boolean;
  setChecked: (state: boolean | ((prevState: boolean) => boolean)) => void;
  selectedItems: T;
  setSelectedItems: (state: T | ((prevState: T) => T)) => void;
  items: T;
  filterKey?: string;
  checkboxAlignment?: 'items-center' | 'items-start';
};

const SelectAllCheckbox: React.FC<SelectAllCheckboxProps<any, any>> = ({
  checked,
  setChecked,
  selectedItems,
  setSelectedItems,
  items,
  filterKey,
  checkboxAlignment,
}) => {
  useEffect(() => {
    if (items?.length === selectedItems?.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [items, selectedItems]);

  return (
    <div>
      <CheckBox
        label="Alle"
        checkboxAlignment={checkboxAlignment}
        checked={checked}
        onChange={() => {
          setChecked(!checked);
          if (checked) {
            setSelectedItems([]);
            if (!filterKey) return;

            saveFilter(filterKey, []);
          } else {
            setSelectedItems(items);
            if (!filterKey) return;

            saveFilter(filterKey, items);
          }
        }}
      />
    </div>
  );
};

SelectAllCheckbox.defaultProps = {
  checkboxAlignment: 'items-center',
  filterKey: undefined,
};

export default SelectAllCheckbox;
