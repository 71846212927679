import { useFormikContext } from 'formik';
import React from 'react';
import { FaClipboardList } from 'react-icons/fa';

import { MaintenanceObjectDto } from '@wartungshelden/shared-types';

import { Card, InputField, PageHeader } from '../../../../../components';
import TextareaRow from '../../../../../components/Basics/Inputs/TextareaRow';
import InventoryList from '../../../../../components/Maintenance/MaintenanceWizard/InventoryList';
import MaintenanceObjectCantBeEditedInfoBox from '../../../../../components/Maintenance/ReadOnly/MaintenanceObjectCantBeEditedInfoBox';
import MaintenanceObjectTypeReadOnly from '../../../../../components/Maintenance/ReadOnly/MaintenanceObjectTypeReadOnly';
import { isServiceAlreadySupported } from '../../../../../constants/MaintenanceConstants';
import { labelToProvidedService } from '../../MaintenanceObject';
import { FormikMaintenanceWizardValues } from '../types/FormikMaintenanceWizardValues';

interface StepTwoProps {
  isMaintenanceReadOnly: boolean;
  isUpdateForm?: boolean;
  maintenance?: MaintenanceObjectDto;
  isReOpened?: boolean;
  requestId?: string;
  maintenanceId?: string;
}

const StepTwo: React.FC<React.PropsWithChildren<StepTwoProps>> = ({
  isMaintenanceReadOnly,
  isUpdateForm,
  maintenance,
  isReOpened,
  requestId,
  maintenanceId,
}) => {
  const { values } = useFormikContext<FormikMaintenanceWizardValues>();

  return (
    <div className="flex h-full overflow-y-scroll">
      <Card>
        <PageHeader>
          <div className="flex flex-row items-center text-4xl text-bold ">
            <FaClipboardList size={45} className="pr-4" />
            Bestandsaufnahme
          </div>
        </PageHeader>

        <MaintenanceObjectCantBeEditedInfoBox
          isMaintenanceReadOnly={isMaintenanceReadOnly}
          isReOpened={isReOpened}
          requestId={requestId}
          maintenanceId={maintenanceId}
        />

        {values.type &&
        isServiceAlreadySupported(labelToProvidedService(values.type)) ? (
          <div>
            {isMaintenanceReadOnly ? (
              <MaintenanceObjectTypeReadOnly maintenance={maintenance} />
            ) : (
              <>
                <div className="text-basic mb-6">
                  Welche {values.type} ist vorhanden?
                </div>
                <div className="flex-1">
                  <InventoryList
                    disabled={isMaintenanceReadOnly}
                    isUpdateForm={isUpdateForm}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <TextareaRow
              name="description"
              label="Beschreibung der Wartungsaufgabe"
              placeholder="Hier ist Platz für weitere Details..."
              rows={5}
              displayInSingleRow
            />
            <InputField
              label="Inventarkennung"
              name="name"
              placeholder="Eigene Bezeichnung oder Namen eintragen..."
            />
          </>
        )}
      </Card>
    </div>
  );
};

StepTwo.defaultProps = {
  isUpdateForm: false,
  maintenance: undefined,
  isReOpened: false,
  requestId: undefined,
  maintenanceId: undefined,
};

export default StepTwo;
