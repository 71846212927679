import React from 'react';

import formatEuros from '../../../services/format';

type PriceSupplementRowProps = {
  doumentationSupplement: number;
  doumentationSupplementExcel: number;
};

const PriceSupplementRow: React.FC<PriceSupplementRowProps> = ({
  doumentationSupplement,
  doumentationSupplementExcel,
}) => {
  const hasSupplement =
    doumentationSupplement > 0 || doumentationSupplementExcel > 0;

  return (
    <tr>
      <td colSpan={4} />

      <td className="border-r-2 border-white pr-2 dueDate text-left pl-4">
        {hasSupplement ? (
          <span className="text-red table-cell">
            Aufschlag wg. nicht aussagekräftiger Dokumentation
          </span>
        ) : (
          <span className="text-green">
            Aussagekräftige Dokumentation vorhanden
          </span>
        )}
      </td>

      <td className="border-r-2 border-white pr-2 dueDate text-right pl-4">
        {formatEuros(doumentationSupplement)}
      </td>

      <td className="border-r-2 border-white pr-2 dueDate text-right pl-4">
        {formatEuros(doumentationSupplementExcel)}
      </td>
    </tr>
  );
};

export default PriceSupplementRow;
