/* eslint-disable react/require-default-props */
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaQuestionCircle, FaRegLightbulb } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { MaintenanceDocumentationDto } from '@wartungshelden/shared-types';

import { isFormikFallProtectionMaintenanceObject } from '../../../guards/isFormikMaintenanceObject';
import { FormikFallProtection } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikFallProtection';
import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import {
  FileOrDocumentation,
  toFileName,
  withFile,
} from '../../../services/maintenanceService';
import Button from '../../Basics/Buttons/Button';
import DocumentOrFileSelection from '../../Basics/Documents/DocumentOrFileSelection';
import FileUpload from '../../Basics/FileUpload';
import { InfoMessageBox } from '../../Basics/MessageBox';
import Modal from '../../Basics/Modals/Modal';

interface DocumentationUploadModalProps {
  visible: boolean;
  existingDocumentations: MaintenanceDocumentationDto[];
  disabledDocumentations?: FileOrDocumentation[];
  initialAddedFiles?: File[];
  onSave: (
    existingDocs: MaintenanceDocumentationDto[],
    selectedDocs: FileOrDocumentation[],
    uploadedDocs: File[]
  ) => void;
  index: number;
}

const DocumentationUploadModal: React.FC<
  React.PropsWithChildren<DocumentationUploadModalProps>
> = ({
  visible,
  existingDocumentations,
  disabledDocumentations,
  initialAddedFiles,
  onSave,
  index,
}) => {
  const { values } = useFormikContext<FormikMaintenanceWizardValues>();

  const maintenanceObject = values.maintenanceObjects[index];

  const formikFallProtection: FormikFallProtection | undefined =
    isFormikFallProtectionMaintenanceObject(maintenanceObject)
      ? maintenanceObject
      : undefined;

  const [addedFiles, setAddedFiles] = useState<File[]>(initialAddedFiles ?? []);
  const [selectedDocumentations, setSelectedDocumentations] = useState<
    FileOrDocumentation[]
  >(formikFallProtection?.selectedFiles ?? []);

  useEffect(() => {
    setAddedFiles(initialAddedFiles ?? []);
  }, [initialAddedFiles, visible]);

  const isSelected = (file: FileOrDocumentation) => {
    return selectedDocumentations.some(withFile(file));
  };

  const isDisabled = (file: FileOrDocumentation) => {
    if (!disabledDocumentations) {
      return false;
    }

    return disabledDocumentations.some(withFile(file));
  };

  const toggleFile = (file: FileOrDocumentation) => () => {
    if (isSelected(file)) {
      const selectedWithoutCurrent = selectedDocumentations.filter(
        (selectedFile) => toFileName(selectedFile) !== toFileName(file)
      );
      setSelectedDocumentations(selectedWithoutCurrent);
    } else {
      setSelectedDocumentations([...selectedDocumentations, file]);
    }
  };

  const onAddFile = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((newFile) => {
      const alreadyUploaded = existingDocumentations.some(withFile(newFile));
      const alreadyExists = addedFiles.some(withFile(newFile));
      const fileAlreadyExists = alreadyUploaded || alreadyExists;

      if (!fileAlreadyExists) {
        setAddedFiles([...addedFiles, newFile]);
      }

      const docAlreadySelected =
        fileAlreadyExists && selectedDocumentations.some(withFile(newFile));

      if (!docAlreadySelected) {
        setSelectedDocumentations([...selectedDocumentations, newFile]);
      }
    });
  };

  return (
    <Modal isOpen={visible} headline="Wähle eine Datei aus" center>
      <div className="max-w-2xl h-full">
        <div className="w-full">
          <InfoMessageBox
            headline="Tipp:"
            customIcon={<FaRegLightbulb size={35} />}
            label="Füge jetzt eine aussagekräftige Montagedokumentation hinzu. So sparst du dir später beim Anfragen Zeit & Kosten."
            isCentered={false}
          >
            <Link
              to="/gute-doku"
              target="_blank"
              className="flex items-center text-blue-abs"
            >
              <FaQuestionCircle className="mr-1" />{' '}
              <div>Was ist eine aussagekräftige Montagedokumentation?</div>
            </Link>
          </InfoMessageBox>
        </div>
        <div className="mt-4">
          <h3 className="font-bold mb-2">Neue Datei hinzufügen</h3>
          <div className="w-full flex flex-wrap gap-2">
            <div className="h-40 w-40">
              <FileUpload onAddFile={onAddFile} />
            </div>
            {addedFiles.map((file, fileIndex) => (
              <div className="h-40 w-40" key={file.name}>
                <DocumentOrFileSelection
                  documentationOrFile={file}
                  isSelected={isSelected(file)}
                  onSelect={toggleFile(file)}
                  testId={`added-${fileIndex}`}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          {existingDocumentations.length > 0 && (
            <>
              <h3 className="font-bold mb-2">
                Bereits hochgeladene Dateien am Gebäude
              </h3>
              <div className="w-full flex flex-wrap gap-2">
                {existingDocumentations.map((file, fileIndex) => (
                  <div className="h-40 w-40" key={file.id}>
                    <DocumentOrFileSelection
                      documentationOrFile={file}
                      disabled={isDisabled(file)}
                      isSelected={isSelected(file)}
                      onSelect={toggleFile(file)}
                      testId={`existing-${fileIndex}`}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="flex justify-center w-full pt-4 border-t-4 border-blue-abs mt-4">
          <Button
            label="Ok"
            onClick={() => {
              onSave(
                existingDocumentations,
                selectedDocumentations,
                addedFiles
              );
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DocumentationUploadModal;
