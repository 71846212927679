import { User } from '@warthungshelden/domain/common';

export interface MaintenanceDocumentationFileService {
  addFileFor(
    user: User,
    fileName: string,
    file: Buffer,
    userId?: string
  ): Promise<void>;

  getFileFor(user: User, fileName: string, userId?: string): Promise<Buffer>;

  deleteFileFor(user: User, fileName: string, userId?: string): Promise<void>;
}
