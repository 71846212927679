import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

export class MustOnlySet<T, U extends Partial<T>> extends DomainRule<T, T> {
  private readonly keys: Array<keyof U>;

  constructor(private update: U, ...keys: Array<keyof U>) {
    super();
    this.keys = keys;
  }

  protected async rule(entity: T) {
    const onlySetsField = Object.entries(this.update)
      .filter(byUndefined())
      .every(([field]) => ['id', 'ownerId', ...this.keys].includes(field));

    if (!onlySetsField) {
      throw new DomainRuleNotSatisfiedError(MustOnlySet);
    }

    return entity;
  }
}

function byUndefined() {
  return ([, value]) => typeof value !== 'undefined';
}
