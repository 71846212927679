import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {
  CustomerOrMaintenanceTeamOfferDto,
  MaintenancePriceAdaptationDto,
} from '@wartungshelden/shared-types';

import { InputField } from '../../../components';
import UnitInputField from '../../../components/Basics/Inputs/UnitInputField';
import ConfirmationModal from '../../../components/Basics/Modals/ConfirmationModal';

interface PriceAdoptionModalProps {
  isOpen?: boolean;
  offer: CustomerOrMaintenanceTeamOfferDto;
  onClose: () => void;
  onPriceAdaptionChange: (
    priceAdaption: MaintenancePriceAdaptationDto | null
  ) => unknown;
}

const schema = Yup.object().shape({
  reason: Yup.string().required(),
  value: Yup.number().required(),
});

const PriceAdaptionModal: React.FC<
  React.PropsWithChildren<PriceAdoptionModalProps>
> = ({ offer, onClose, onPriceAdaptionChange, isOpen = false }) => (
  <Formik
    initialValues={{ ...offer.price?.priceAdaptation }}
    enableReinitialize
    validationSchema={schema}
    onSubmit={({ reason, value }) => {
      onPriceAdaptionChange({ reason: reason!, value: value! });
    }}
  >
    {({ submitForm, dirty, values }) => {
      return (
        <ConfirmationModal
          isVisible={isOpen}
          heading="Preisanpassung"
          onAccept={
            dirty
              ? () => {
                  submitForm().catch();
                }
              : undefined
          }
          onCancel={onClose}
          onDelete={
            !dirty && offer.price?.priceAdaptation
              ? () => onPriceAdaptionChange(null)
              : undefined
          }
        >
          <div className="mb-4">
            Diese Preisanpassung wird auf{' '}
            <strong>Kundenseite nicht angezeigt.</strong>
          </div>

          <div className="flex items-center space-x-2 mb-4">
            <InputField
              name="reason"
              type="text"
              placeholder="Grund für Preisanpassung"
            />
            <UnitInputField
              fullWith
              name="value"
              unit="€"
              placeholder="+/- Netto-Betrag"
              allowDecimals
              allowNegativeValues
            />
          </div>
        </ConfirmationModal>
      );
    }}
  </Formik>
);
PriceAdaptionModal.defaultProps = {
  isOpen: undefined,
};

export default PriceAdaptionModal;
