import React, { useEffect } from 'react';
import { FaArrowCircleRight, FaInfoCircle } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { Modal } from 'react-responsive-modal';
import { useNavigate, useParams } from 'react-router-dom';

import { Card } from '../../components';

interface DocuHint {
  text: string;
  imageName: string;
}

const hints: DocuHint[] = [
  {
    text: 'Objektidentifikation: Gebäudename und Adresse',
    imageName: 'Musterdokumentation_Seite_1a.svg',
  },
  {
    text: 'Montagedatum',
    imageName: 'Musterdokumentation_Seite_1b.svg',
  },
  {
    text: 'Montageunternehmen',
    imageName: 'Musterdokumentation_Seite_3a.svg',
  },
  {
    text: 'Fachunternehmererklärung, Unterschrift, Name des verantwortlichen Monteurs',
    imageName: 'Musterdokumentation_Seite_3b.svg',
  },
  {
    text: 'Planungsunterlagen',
    imageName: 'Musterdokumentation_Seite_5.svg',
  },
  {
    text: 'Produktidentifikation: Jedes Produkt muss eindeutig identifizierbar sein durch Angabe von Hersteller, Produktname, Typ und Seriennummer',
    imageName: 'Musterdokumentation_Seite_9a.svg',
  },
  {
    text: 'Befestigungsuntergrund, Befestigungsmittel (Hersteller, Produkt, Bohrbild), ggf. Absprachen mit Hersteller',
    imageName: 'Musterdokumentation_Seite_9c.svg',
  },
  {
    text:
      'Fotodokumentation: Idealerweise pro Typ des Anschlagpunktes eine Fotostrecke, welche die sicherheitsrelevanten Einbauschritte zeigt, die zu einem späteren Zeitpunkt nicht mehr sichtbar sind (inkl. verwendetem Werkzeug und eingehaltener Drehmomente). ' +
      'Die Fotos sollten eine hinreichend gute Qualität haben!',
    imageName: 'Musterdokumentation_Seite_7.svg',
  },
  {
    text:
      'Mindestanforderung an die Fotodokumentation: Pro Typ des Anschlagpunktes von mindestens 20% der verbauten Anschlagpunkte ein Foto im eingebauten Zustand, auf dem die untere Befestigung sichtbar ist. ' +
      'Die Fotos sollten eine hinreichend gute Qualität haben!',
    imageName: 'Musterdokumentation_Seite_9b.svg',
  },
];

const HintListItem: React.FC<
  React.PropsWithChildren<{
    hint: DocuHint;
    onClick: (hint: DocuHint) => unknown;
    selected?: boolean;
    hideExampleButton?: boolean;
  }>
> = ({ hint, onClick, selected = false, hideExampleButton = false }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => onClick(hint)}
      className="flex w-full cursor-pointer"
      onClick={() => onClick(hint)}
    >
      <Card>
        <div className="flex">
          <div className="flex-1">
            <p className="hyphens-auto">{hint.text}</p>
          </div>
          {!hideExampleButton && (
            <div
              className={`border-l-2 pl-8 ml-4 justify-end flex items-center ${
                selected && 'text-[#009FFF]'
              }`}
            >
              <div className="mr-2 underline underline-offset-4">Beispiel</div>
              <FaArrowCircleRight />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

HintListItem.defaultProps = {
  selected: undefined,
  hideExampleButton: undefined,
};

const InstallationDocumentationPage: React.FC<React.PropsWithChildren> = () => {
  const isLaptopOrDesktop = useMediaQuery({ minWidth: 1224 });
  const { hintId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLaptopOrDesktop) {
      navigate('/gute-doku/0');
    }
  }, []);

  const chosenHint: DocuHint | undefined = hintId && hints[hintId];

  const getHintList = () => (
    <>
      <div className="px-3.5 pt-6 pb-5 flex text-white items-center">
        <FaInfoCircle size="3em" />
        <h2 className="text-xl font-bold pl-3">
          Kriterien für eine aussagekräftige Montagedokumentation *
        </h2>
      </div>
      <div className="basis-5/6 px-4">
        {hints.map((hint, index) => (
          <div
            className="my-4 shadow-doku"
            key={`${hint.text}${hint.imageName}`}
          >
            <HintListItem
              hint={hint}
              onClick={() => navigate(`/gute-doku/${index}`)}
              selected={chosenHint === hint}
              hideExampleButton={!isLaptopOrDesktop}
            />
          </div>
        ))}
      </div>
      <p className="shrink p-4 text-white">
        * Die Kriterien für die Inhalte einer aussagekräftigen
        Montagedokumentation sind nicht allgemeingültig, sondern entsprechen den
        Vorgaben der ABS Safety GmbH und sind in Anlehnung an die Regelungen der
        DGUV 201-056.
      </p>
    </>
  );

  if (!isLaptopOrDesktop)
    return (
      <div className="flex flex-col overflow-y-auto bg-gray-abs min-h-full px-4">
        {getHintList()}
        <Modal
          styles={{
            modal: {
              width: '100%',
              margin: 0,
              backgroundColor: '#e6e6e6',
              padding: 0,
            },
            closeIcon: { border: 'solid thin black', borderRadius: '50%' },
          }}
          open={!!chosenHint}
          onClose={() => navigate('/gute-doku')}
        >
          {chosenHint && (
            <>
              <div className="p-4 mr-4">{chosenHint.text}</div>
              <img
                src={`/assets/documentation/${chosenHint.imageName}`}
                alt={chosenHint.text}
                className="h-full"
              />
            </>
          )}
        </Modal>
      </div>
    );

  return (
    <div className="flex overflow-y-auto bg-gray-abs">
      <div className="w-1/2 h-screen py-4 overflow-y-auto px-2 flex flex-col">
        {getHintList()}
      </div>
      <div className="w-1/2 h-screen">
        {chosenHint && (
          <img
            src={`/assets/documentation/${chosenHint.imageName}`}
            alt={chosenHint.text}
            className="h-full"
          />
        )}
      </div>
    </div>
  );
};

export default InstallationDocumentationPage;
