import { DomainEventDispatcher } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';
import { to } from '@warthungshelden/shared-functions';
import { v4 as uuidv4 } from 'uuid';

import { MaintenanceDocumentationService } from '../maintenance-documentation';
import { MaintenanceDocumentationUpdated } from '../maintenance-documentation';
import {
  MaintenanceObjectRepository,
  MaintenanceObjectService,
} from '../maintenance-object';
import { MaintenancePriceService } from '../maintenance-price';
import {
  MaintenanceRequest,
  MaintenanceRequestRepository,
  RequestStatusEnum,
} from '../maintenance-request';
import {
  MaintenanceRequestCreated,
  MaintenanceRequestStatusChanged,
} from '../maintenance-request';
import {
  MustBeMaintenanceTeamRule,
  MustBeOwnerRule,
  MustExistRule,
} from '../rules';
import { MustBeAccepted } from '../rules';
import { MustBeUnconfirmed } from '../rules';
import { MustHaveDocumentAttached } from '../rules';
import { MustHaveOrder } from '../rules';
import { MustHaveOrderConfirmationDocumentAttached } from '../rules';
import { MustNotBeSent } from '../rules';
import { MustNotChangeDecisionRule } from '../rules';
import { MustNotHaveDocumentAttached } from '../rules';
import { MustNotHaveOrderConfirmationDocumentAttached } from '../rules';
import { MustOnlyCloseDocumentUpload } from '../rules';
import { MustOnlySet } from '../rules';
import {
  MaintenanceOfferDecisionSet,
  MaintenanceOfferDocumentUploadClosed,
  MaintenanceOfferDocumentUploadOpened,
  MaintenanceOfferStatusChanged,
  MaintenanceOrderClosed,
} from './events';
import {
  MaintenanceOffer,
  MaintenanceOfferDecision,
  MaintenanceOfferStatusEnum,
  MaintenanceOfferUpdate,
} from './maintenance-offer';
import { MaintenanceOfferAddress } from './maintenance-offer-address';
import { MaintenanceOfferDocumentAdapter } from './maintenance-offer-document.adapter';
import { MaintenanceOfferRepository } from './maintenance-offer.repository';
import {
  MaintenanceOrder,
  MaintenanceOrderStatusEnum,
} from './maintenance-order';
import { MaintenanceOrderAddress } from './maintenance-order-address';

export class MaintenanceOfferService {
  constructor(
    protected readonly eventDispatcher: DomainEventDispatcher,
    protected readonly maintenanceOfferRepository: MaintenanceOfferRepository,
    protected readonly maintenanceOfferDocumentService: MaintenanceOfferDocumentAdapter,
    protected readonly maintenanceDocumentationService: MaintenanceDocumentationService,
    protected readonly maintenanceRequestRepository: MaintenanceRequestRepository,
    protected readonly maintenanceObjectRepository: MaintenanceObjectRepository,
    protected readonly maintenanceObjectService: MaintenanceObjectService,
    protected readonly maintenancePriceService: MaintenancePriceService
  ) {
    this.eventDispatcher.register(
      MaintenanceRequestCreated,
      this.onMaintenanceRequestCreated.bind(this)
    );

    this.eventDispatcher.register(
      MaintenanceRequestStatusChanged,
      this.onMaintenanceRequestStatusChanged.bind(this)
    );

    this.eventDispatcher.register(
      MaintenanceDocumentationUpdated,
      this.onMaintenanceDocumentationUpdated.bind(this)
    );
  }

  public async addFromMaintenanceRequest(
    user: User,
    maintenanceRequest: MaintenanceRequest,
    address: MaintenanceOfferAddress,
    absCustomerNumber?: string
  ): Promise<MaintenanceOffer> {
    const maintenanceObjects =
      await this.maintenanceObjectRepository.getAllById(
        maintenanceRequest.objectIds
      );

    const offerPrice = await this.maintenancePriceService.calculatePrice(
      user,
      maintenanceRequest.objectIds
    );

    const offer = MaintenanceOffer.fromMaintenanceRequest(
      maintenanceRequest,
      maintenanceObjects,
      address,
      offerPrice,
      absCustomerNumber
    );

    return this.maintenanceOfferRepository.create(offer);
  }

  public async recalculatePriceFor(user: User, offerId: string): Promise<void> {
    const oldOffer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(offerId);

    await this.updatePriviledged(user, {
      id: offerId,
      price: await this.maintenancePriceService.recalculatePriceForOffer(
        user,
        oldOffer
      ),
    });
  }

  public async updatePriviledged(
    user: User,
    maintenanceOffer: MaintenanceOfferUpdate,
    disableNotification = false
  ): Promise<MaintenanceOffer> {
    const oldOffer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustNotChangeDecisionRule(maintenanceOffer))
      .apply(maintenanceOffer.id);

    const newOffer = await this.maintenanceOfferRepository.update(
      oldOffer.copyWith({ ...maintenanceOffer })
    );

    const isOrderClosed =
      maintenanceOffer?.order?.status === MaintenanceOrderStatusEnum.CLOSED;

    if (isOrderClosed) {
      await this.eventDispatcher.dispatch(
        new MaintenanceOrderClosed(user, oldOffer)
      );
    }

    const decisonChanged =
      maintenanceOffer.decision?.status &&
      maintenanceOffer.decision?.status !== oldOffer.decision.status;

    const decisionSet =
      decisonChanged &&
      (maintenanceOffer.decision?.status ===
        MaintenanceOfferStatusEnum.ACCEPTED ||
        maintenanceOffer.decision?.status ===
          MaintenanceOfferStatusEnum.DECLINED);

    if (decisionSet) {
      await this.eventDispatcher.dispatch(
        new MaintenanceOfferDecisionSet(user, newOffer)
      );
    } else if (!disableNotification && decisonChanged) {
      await this.eventDispatcher.dispatch(
        new MaintenanceOfferStatusChanged(user, newOffer)
      );
    }

    const openedDocumentUploads = newOffer.objectsWithOpenDocumentUpload.filter(
      (id) => !oldOffer.objectsWithOpenDocumentUpload.includes(id)
    );

    if (openedDocumentUploads.length) {
      await this.eventDispatcher.dispatch(
        new MaintenanceOfferDocumentUploadOpened(
          user,
          newOffer,
          openedDocumentUploads
        )
      );
    }

    const closedDocumentUploads = oldOffer.objectsWithOpenDocumentUpload.filter(
      (id) => !newOffer.objectsWithOpenDocumentUpload.includes(id)
    );

    if (closedDocumentUploads.length) {
      await this.eventDispatcher.dispatch(
        new MaintenanceOfferDocumentUploadClosed(
          user,
          newOffer,
          closedDocumentUploads
        )
      );
    }

    return newOffer;
  }

  public async update(
    user: User,
    maintenanceOffer: MaintenanceOfferUpdate
  ): Promise<MaintenanceOffer> {
    const customerRule = new MustBeOwnerRule(user.id).and(
      new MustOnlySet(
        maintenanceOffer,
        'objectsWithOpenDocumentUpload',
        'customerReference'
      ).and(new MustOnlyCloseDocumentUpload(maintenanceOffer))
    );

    await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeMaintenanceTeamRule(user).or(customerRule))
      .apply(maintenanceOffer.id);

    return this.updatePriviledged(user, maintenanceOffer);
  }

  public async getAll(user: User): Promise<MaintenanceOffer[]> {
    await new MustBeMaintenanceTeamRule(user).apply(undefined);

    return await this.maintenanceOfferRepository.getAll();
  }

  public async getById(user: User, id: string): Promise<MaintenanceOffer> {
    await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    return await this.maintenanceOfferRepository.getById(id);
  }

  public async getAllAccessibleBy(user: User): Promise<MaintenanceOffer[]> {
    return user.isMaintenanceTeamMemberAdmin
      ? await this.maintenanceOfferRepository.getAll()
      : await this.maintenanceOfferRepository.getAllByUser(user.id);
  }

  public async getAllByRequestId(
    user: User,
    id: string
  ): Promise<MaintenanceOffer[]> {
    await new MustExistRule(this.maintenanceRequestRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    return await this.maintenanceOfferRepository.getAllByRequestId(id);
  }

  public async setDecisionFor(
    user: User,
    id: string,
    decision:
      | MaintenanceOfferStatusEnum.DECLINED
      | MaintenanceOfferStatusEnum.ACCEPTED,
    orderAddress: MaintenanceOrderAddress,
    reasons?: string[]
  ): Promise<MaintenanceOffer> {
    const offer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeOwnerRule(user.id))
      .apply(id);

    const durationPolicy =
      await this.maintenanceObjectService.getDurationPolicy(user);

    return this.updatePriviledged(
      user,
      offer.copyWith({
        order:
          offer.order ?? decision === MaintenanceOfferStatusEnum.ACCEPTED
            ? new MaintenanceOrder({
                id: uuidv4(),
                orderDate: new Date(),
                status: MaintenanceOrderStatusEnum.UNCONFIRMED,
                address: orderAddress,
                durationInMinutes: await offer.calculateDurationInMinutes(
                  user,
                  durationPolicy
                ),
              })
            : undefined,
        decision: new MaintenanceOfferDecision({
          date: new Date(),
          status: decision,
          reasons: reasons,
        }),
      })
    );
  }

  public async attachDocumentTo(
    user: User,
    id: string,
    fileName: string,
    file: Buffer
  ) {
    const offer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeMaintenanceTeamRule(user))
      .and(new MustNotHaveDocumentAttached())
      .apply(id);

    await this.update(user, {
      id,
      documentName: fileName,
    });

    await this.maintenanceOfferDocumentService.addOfferDocument(
      user,
      offer.id,
      fileName,
      file,
      offer.ownerId
    );
  }

  public async removeDocumentFrom(user: User, id: string) {
    const offer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeMaintenanceTeamRule(user))
      .and(new MustNotBeSent())
      .apply(id);

    const offerWithDocument = await new MustHaveDocumentAttached().apply(offer);

    await this.update(user, {
      id,
      documentName: null,
    });

    await this.maintenanceOfferDocumentService.deleteOfferDocument(
      user,
      offer.id,
      offerWithDocument.documentName,
      offer.ownerId
    );
  }

  public async getDocumentFor(user: User, id: string): Promise<Buffer> {
    const offer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    const offerWithDocument = await new MustHaveDocumentAttached().apply(offer);

    return this.maintenanceOfferDocumentService.getOfferDocument(
      user,
      offer.id,
      offerWithDocument.documentName,
      user.isMaintenanceTeamMemberAdmin ? offer.ownerId : undefined
    );
  }

  public async attachOrderConfirmationDocumentTo(
    user: User,
    id: string,
    fileName: string,
    file: Buffer
  ) {
    const offer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeMaintenanceTeamRule(user))
      .apply(id);

    const offerWithOrder = await new MustBeAccepted()
      .and(new MustHaveOrder())
      .apply(offer);

    const orderWithoutDocument =
      await new MustNotHaveOrderConfirmationDocumentAttached().apply(
        offerWithOrder.order
      );

    await this.update(user, {
      id,
      order: orderWithoutDocument.copyWith({
        confirmationDocumentName: fileName,
      }),
    });

    await this.maintenanceOfferDocumentService.addOrderConfirmationDocument(
      user,
      orderWithoutDocument.id,
      fileName,
      file,
      offer.ownerId
    );
  }

  public async removeOrderConfirmationDocumentFrom(user: User, id: string) {
    const offer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeMaintenanceTeamRule(user))
      .apply(id);

    const offerWithOrder = await new MustHaveOrder().apply(offer);
    const orderWithDocument =
      await new MustHaveOrderConfirmationDocumentAttached()
        .and(new MustBeUnconfirmed())
        .apply(offerWithOrder.order);

    await this.update(user, {
      id,
      order: offerWithOrder.order.copyWith({ confirmationDocumentName: null }),
    });

    await this.maintenanceOfferDocumentService.deleteOrderConfirmationDocument(
      user,
      orderWithDocument.id,
      orderWithDocument.confirmationDocumentName,
      offer.ownerId
    );
  }

  public async getOrderConfirmationDocumentFor(
    user: User,
    id: string
  ): Promise<Buffer> {
    const offer = await new MustExistRule(this.maintenanceOfferRepository)
      .and(new MustBeOwnerRule(user.id).or(new MustBeMaintenanceTeamRule(user)))
      .apply(id);

    const offerWithOrder = await new MustHaveOrder().apply(offer);
    const orderWithDocument =
      await new MustHaveOrderConfirmationDocumentAttached().apply(
        offerWithOrder.order
      );

    return this.maintenanceOfferDocumentService.getOrderConfirmationDocument(
      user,
      orderWithDocument.id,
      orderWithDocument.confirmationDocumentName,
      user.isMaintenanceTeamMemberAdmin ? offer.ownerId : undefined
    );
  }

  private async onMaintenanceRequestCreated(event: MaintenanceRequestCreated) {
    await this.addFromMaintenanceRequest(
      event.user,
      event.maintenanceRequest,
      event.address,
      event.absCustomerNumber
    );
  }

  private async onMaintenanceRequestStatusChanged(
    event: MaintenanceRequestStatusChanged
  ) {
    if (!event.currentOffer) {
      return;
    }

    if (event.maintenanceRequest.status === RequestStatusEnum.CLOSED) {
      return;
    }

    await this.updatePriviledged(
      event.user,
      event.currentOffer.copyWith({
        decision: new MaintenanceOfferDecision({
          status: MaintenanceOfferStatusEnum.DRAFT,
          date: new Date(),
        }),
      }),
      true
    );
  }

  private async onMaintenanceDocumentationUpdated(
    event: MaintenanceDocumentationUpdated
  ) {
    for (const id of event.documentation.maintenanceObjectIds) {
      const object = await this.maintenanceObjectRepository.getById(id);

      if (object.currentRequestId) {
        const offers = await this.maintenanceOfferRepository.getAllByRequestId(
          object.currentRequestId
        );

        const openOfferIds = offers
          .filter(({ decision: { status } }) =>
            ['draft', 'sent'].includes(status)
          )
          .map(to('id'));

        for (const id of openOfferIds) {
          await this.recalculatePriceFor(event.user, id);
        }
      }
    }
  }
}
