/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, useSortBy, useTable } from 'react-table';

import { BuildingDto } from '@wartungshelden/shared-types';

import Loader from '../Basics/Loaders/Loader';

type TableProps = {
  buildings: BuildingDto[];
  onScrollToBottom: () => void;
  isLoading: boolean;
};

const columns: Column<BuildingDto>[] = [
  {
    Header: 'Gebäudename',
    accessor: 'name',
  },
  {
    Header: 'Stadt',
    accessor: (building) => building.address?.city,
  },
  {
    Header: 'Adresse',
    accessor: (building) => building.address?.addressLine1,
  },
];

const BuildingsTable: React.FC<React.PropsWithChildren<TableProps>> = ({
  buildings,
  onScrollToBottom,
  isLoading,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<BuildingDto>(
      {
        columns,
        data: buildings,
      },
      useSortBy
    );

  const navigate = useNavigate();

  const listInnerRef = useRef<HTMLTableSectionElement>(null);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        onScrollToBottom();
      }
    }
  };

  const goToDetailsPage = (id: string) => {
    navigate(id, {});
  };
  return (
    <div className="h-full overflow-y-auto w-full">
      <table
        {...getTableProps()}
        data-cy="components-buildings-buildings-table"
        className="w-full overflow-y-auto border-collapse bg-white"
      >
        <thead className="sticky top-0 w-full bg-gray-light text-black-abs">
          {headerGroups.map((headerGroup) => (
            <tr className="h-9" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // @ts-ignore
                <th
                  className="border-r-2 border-white text-left pl-4"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {/* @ts-ignore */}
                  {column.isSorted && (
                    <span>{column.isSortedDesc ? '↓' : '↑'}</span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="overflow-y-auto"
          {...getTableBodyProps()}
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className="cursor-pointer hover:bg-gray-lighter hover:text-black-abs text-center h-9"
                {...row.getRowProps()}
                onClick={() => {
                  // @ts-ignore
                  goToDetailsPage(row.original.id);
                }}
              >
                {row.cells.map((cell) => (
                  <td
                    className="border-r-2 border-white text-left pl-4"
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isLoading && (
        <div className="flex flex-1 justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default BuildingsTable;
