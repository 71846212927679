import React from 'react';

import { RailSystemDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface RailSystemPropertiesProps {
  railSystem: RailSystemDto;
  hasSubprices: boolean;
}

const RailSystemProperties: React.FC<RailSystemPropertiesProps> = ({
  railSystem,
  hasSubprices,
}) => (
  <>
    <MaintenanceObjectSummaryRow
      label="Länge"
      value={railSystem.length}
      unit="m"
      hasSubprices={hasSubprices}
    />

    <MaintenanceObjectSummaryRow
      label="Halter"
      value={railSystem.brackets}
      unit="Stück"
      hasSubprices={hasSubprices}
    />
  </>
);

export default RailSystemProperties;
