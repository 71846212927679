import React from 'react';

import CheckBox from '../../Basics/Inputs/CheckBox/CheckBox';

interface TableCheckboxProps {
  indeterminate: any;
  disabled: boolean;
}

const TableCheckbox = React.forwardRef<any, TableCheckboxProps>(
  ({ indeterminate, disabled, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    // @ts-ignore
    const isChecked = () => rest.checked;

    return (
      <div className="flex items-center" ref={resolvedRef}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <CheckBox {...rest} checked={isChecked()} disabled={disabled} />
      </div>
    );
  }
);

export default TableCheckbox;
