/* eslint-disable react/jsx-props-no-spreading,react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import { Column, Row, useSortBy, useTable } from 'react-table';

import {
  BuildingDto,
  CurrentMaintenanceObjectDto,
} from '@wartungshelden/shared-types';

import { readableServiceType } from '../../../constants/MaintenanceConstants';
import DueDateCell from './DueDateCell';

type TableProps = {
  buildings?: BuildingDto[] | null;
  maintenances: CurrentMaintenanceObjectDto[];
};

const MaintenanceRequestSelectionOverviewTable: React.FC<
  React.PropsWithChildren<TableProps>
> = ({ buildings, maintenances }) => {
  const columns = React.useMemo((): Column<CurrentMaintenanceObjectDto>[] => {
    return [
      {
        Header: 'Gebäude',
        accessor: (maintenance) =>
          buildings?.find(({ id }) => id === maintenance.buildingId)?.name,
      },
      {
        Header: 'Interne Bezeichnung',
        accessor: 'name',
      },
      {
        Header: 'Wartungstyp',
        accessor: (maintenance) => readableServiceType(maintenance.type).label,
      },
      {
        Header: 'Nächste Fälligkeit',
        id: 'dueDate',
        accessor: 'dueDate',
        // @ts-ignore
        Cell: (cell) => <DueDateCell cell={cell} />,
      },
    ];
  }, [buildings]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<CurrentMaintenanceObjectDto>(
      {
        columns,
        data: maintenances,
        initialState: {
          sortBy: useMemo(
            () => [
              {
                id: 'Gebäude',
                desc: false,
              },
              {
                id: 'name',
                desc: false,
              },
            ],
            []
          ),
        },
      },
      useSortBy
    );

  const generateRows = (r: Row<CurrentMaintenanceObjectDto>[]) =>
    r.map((row) => {
      prepareRow(row);
      return (
        <tr className="text-center" {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <td
              className={`border-r-2 border-white ${
                cell.column.id !== 'dueDate' && 'text-left pl-4'
              }`}
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    });

  return (
    <div className="max-h-70v overflow-y-auto w-full overflow-x-hidden">
      <table
        {...getTableProps()}
        className="w-full overflow-y-auto border-collapse bg-white"
      >
        <thead className="sticky top-0 w-full bg-gray-light text-black-abs">
          {headerGroups.map((headerGroup) => (
            <tr className="h-9" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  className={`text-left pl-4 ${
                    index === 0 && 'border-b-2'
                  } border-r-2 border-white`}
                  key={column.id}
                  {...column.getHeaderProps}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="overflow-y-auto" {...getTableBodyProps()}>
          {rows?.length > 0 && <>{generateRows(rows)}</>}
        </tbody>
      </table>
    </div>
  );
};

MaintenanceRequestSelectionOverviewTable.defaultProps = {
  buildings: undefined,
};

export default MaintenanceRequestSelectionOverviewTable;
