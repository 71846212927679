import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';

type FileNameComponentProps = {
  file: File;
};

const FileNameComponent: React.FC<
  React.PropsWithChildren<FileNameComponentProps>
> = ({ file }) => {
  return (
    <div
      data-tip
      data-for={file.name}
      className="flex h-6 w-full items-center justify-center text-center"
    >
      <Tooltip
        placement="bottom"
        trigger="hover"
        overlay={file.name}
        overlayInnerStyle={{
          padding: 12,
          maxWidth: '18rem',
        }}
      >
        <div>{`${file.name.substring(0, 13)}...`}</div>
      </Tooltip>
    </div>
  );
};

export default FileNameComponent;
