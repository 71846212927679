import React, { Dispatch, SetStateAction } from 'react';

type InputFieldProps = {
  name: string;
  value: unknown;
  setState: Dispatch<SetStateAction<unknown>>;
  state?: unknown;
  label?: string;
  disabled?: boolean;
};

const RadioButton: React.FC<React.PropsWithChildren<InputFieldProps>> = ({
  name,
  label,
  value,
  disabled,
  state,
  setState,
}) => (
  <div data-cy="components-basics-radio-button-field">
    <label className="flex fex-col" htmlFor={name}>
      <button
        type="button"
        name={name}
        onClick={() => {
          if (!disabled) {
            setState(value);
          }
        }}
        className={`flex items-center mt-2 ${
          disabled && ' cursor-default text-gray-dark'
        }`}
      >
        <div
          className={`flex items-center justify-center w-6 h-6 border-2 mr-2 rounded-full ${
            disabled ? 'border-gray-dark border-opacity-50' : 'border-blue-abs'
          }`}
        >
          <div className={`${state && ' w-4 h-4 bg-blue-abs rounded-full'}`} />
        </div>
        {label}
      </button>
    </label>
  </div>
);

RadioButton.defaultProps = {
  label: undefined,
  disabled: false,
  state: undefined,
};

export default RadioButton;
