import { format } from 'date-fns';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { areEqual } from '../../../../../set';
import CheckBox from '../../../../components/Basics/Inputs/CheckBox/CheckBox';
import SelectAllCheckbox from '../../../../components/Filter/SelectAllCheckbox';

interface FilterCheckboxGridProps<T> {
  headerLabels: string[];
  items: T[];
  contentKeys: Array<keyof T>;
  uniqueKeys?: Array<keyof T>;
  setSelectedItems: (items: T[]) => void;
  selectedItems?: T[] | null;
  getUniqueItems: (items: T[]) => T[];
}

const FilterCheckboxGrid = <
  T extends { [K in keyof T]: string | number | Date | object | boolean | null }
>({
  headerLabels,
  items,
  contentKeys,
  uniqueKeys,
  selectedItems,
  setSelectedItems,
  getUniqueItems,
}: PropsWithChildren<FilterCheckboxGridProps<T>>) => {
  const identificationKeys = uniqueKeys ?? contentKeys;
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [uniqueItems, setUniqueItems] = useState<T[]>(getUniqueItems(items));

  useEffect(() => {
    const newUnique = getUniqueItems(items);

    if (!uniqueItems || !areEqual(uniqueItems, newUnique)) {
      setUniqueItems(newUnique);
    }
  }, [getUniqueItems, items, uniqueItems]);

  const toggleCheckedValue = (isSelected, item) => {
    if (isSelected) {
      return selectedItems?.filter((prevItem) =>
        identificationKeys.some((key) => prevItem[key] !== item[key])
      );
    }
    return selectedItems ? [...selectedItems, item] : [item];
  };

  return (
    <div
      className={`grid grid-rows-${items.length + 1}`}
      style={{
        gridTemplateColumns: `5em repeat(${contentKeys.length}, 1fr)`,
      }}
    >
      <div className="font-bold bg-gray-light p-2">
        <SelectAllCheckbox
          checked={isAllChecked}
          setChecked={setIsAllChecked}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          items={uniqueItems}
          checkboxAlignment="items-start"
        />
      </div>
      {headerLabels.map((headerLabel) => (
        <div className="font-bold bg-gray-light p-2 break-all">
          {headerLabel}
        </div>
      ))}
      {items.map((item) => {
        const isSelected = !!selectedItems?.find((selectedItem) => {
          return identificationKeys.every(
            (key) => selectedItem[key] === item[key]
          );
        });

        const onCheckboxChange = () => {
          const updatedSelectedItems = toggleCheckedValue(isSelected, item);

          if (updatedSelectedItems) {
            setSelectedItems(updatedSelectedItems);
          }
        };

        return (
          <>
            <div className="mr-2 px-2 py-1">
              <CheckBox onChange={onCheckboxChange} checked={isSelected} />
            </div>
            {contentKeys.map((contentKey) => {
              const value = item[contentKey];
              const key = `FilterCheckboxGrid-${String(contentKey)}`;
              if (value instanceof Date) {
                return (
                  <div className="flex flex-1 px-2 py-1" key={key}>
                    {format(value, 'dd.MM.yyyy')}
                  </div>
                );
              }
              if (typeof value === 'string' || typeof value === 'number') {
                return (
                  <div className="flex flex-1 px-2 py-1 leading-5" key={key}>
                    {value}
                  </div>
                );
              }

              return (
                <div className="flex flex-1 px-2 py-1" key={key}>
                  {JSON.stringify(value)}
                </div>
              );
            })}
          </>
        );
      })}
    </div>
  );
};

FilterCheckboxGrid.defaultProps = {
  selectedItems: undefined,
  uniqueKeys: undefined,
};

export default FilterCheckboxGrid;
