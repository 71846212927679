import { Field } from 'formik';
import React from 'react';

type InputFieldProps = {
  label?: string;
  name: any;
  value: any;
  disabled?: boolean;
};

const RadioButtonField: React.FC<React.PropsWithChildren<InputFieldProps>> = ({
  name,
  label,
  value,
  disabled,
}) => (
  <div data-cy="components-basics-radio-button-field">
    <label className="flex fex-col" htmlFor={name}>
      <Field type="radio" name={name} value={value} id={name}>
        {({ field, form }: any) => (
          <button
            type="button"
            onClick={() => {
              if (!disabled) {
                form.setFieldValue(name, value);
              }
            }}
            className={`flex items-center mt-2 ${
              disabled && ' cursor-default text-gray-dark'
            }`}
          >
            <div
              className={`flex items-center justify-center w-6 h-6 border-2 mr-2 rounded-full ${
                disabled
                  ? 'border-gray-dark border-opacity-50'
                  : 'border-blue-abs'
              }`}
            >
              <div
                className={`${
                  field.checked && ' w-4 h-4 bg-blue-abs rounded-full'
                }`}
              />
            </div>
            {label}
          </button>
        )}
      </Field>
    </label>
  </div>
);

RadioButtonField.defaultProps = {
  label: undefined,
  disabled: false,
};

export default RadioButtonField;
