import React from 'react';

interface LoaderProps {
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ className }) => (
  <div
    data-testid="loading-spinner"
    className={`animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-gray-900 ${className}`}
  />
);

Loader.defaultProps = {
  className: undefined,
};

export default Loader;
