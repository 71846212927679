import React from 'react';

import { PushLockFallProtectionSystemDto } from '@wartungshelden/shared-types';

import MaintenanceObjectSummaryRow from '../MaintenanceObjectSummaryRow';

interface PushLockPropertiesProps {
  pushLockSystem: PushLockFallProtectionSystemDto;
  hasSubprices: boolean;
}

const PushLockProperties: React.FC<PushLockPropertiesProps> = ({
  pushLockSystem,
  hasSubprices,
}) => (
  <>
    <MaintenanceObjectSummaryRow
      label="Lock-I"
      value={pushLockSystem.anchors}
      unit="Stück"
      hasSubprices={hasSubprices}
    />

    <MaintenanceObjectSummaryRow
      label="Lock-II"
      value={pushLockSystem.sleeves}
      unit="Stück"
      hasSubprices={hasSubprices}
    />
  </>
);

export default PushLockProperties;
