import {
  CreateMaintenanceDocumentationDto,
  MaintenanceDocumentationDto,
  MaintenanceObjectDto,
  UpdateMaintenanceDocumentationDto,
} from '@wartungshelden/shared-types';

import { areEqual, distinct } from '../../set';
import { toFileName } from './maintenanceService';

function toCreateDtos(
  objects: {
    createdObject?: MaintenanceObjectDto;
    selectedFiles: Array<File | MaintenanceDocumentationDto>;
  }[]
) {
  return (
    toCreate: CreateMaintenanceDocumentationDto[],
    file: File | MaintenanceDocumentationDto
  ): CreateMaintenanceDocumentationDto[] => {
    const maintenanceObjectIds = objects
      .filter(({ selectedFiles }) => selectedFiles.includes(file))
      .map(({ createdObject }) => createdObject!.id); // TODO Think if fine

    return [...toCreate, { file: file as File, maintenanceObjectIds }];
  };
}

function toRemoveDtos(
  objects: {
    createdObject?: MaintenanceObjectDto;
    selectedFiles: Array<File | MaintenanceDocumentationDto>;
  }[]
) {
  return (
    toRemove: UpdateMaintenanceDocumentationDto[],
    documentation: MaintenanceDocumentationDto
  ) => {
    const maintenanceObjectIds = documentation.maintenanceObjectIds.filter(
      (maintenanceObjectId) => {
        return !objects.find(
          (object) => object.createdObject?.id === maintenanceObjectId
        );
      }
    );

    return areEqual(documentation.maintenanceObjectIds, maintenanceObjectIds)
      ? toRemove
      : [
          ...toRemove,
          {
            id: documentation.id,
            fileName: documentation.fileName,
            maintenanceObjectIds,
          },
        ];
  };
}

function toUpdateDtos(
  existingMaintenanceDocumentations: MaintenanceDocumentationDto[],
  objects: {
    createdObject?: MaintenanceObjectDto;
    selectedFiles: Array<File | MaintenanceDocumentationDto>;
  }[]
) {
  return (
    toUpdate: UpdateMaintenanceDocumentationDto[],
    file: File | MaintenanceDocumentationDto
  ): UpdateMaintenanceDocumentationDto[] => {
    const existingFile =
      file instanceof File
        ? existingMaintenanceDocumentations?.find(
            ({ fileName }) => fileName === toFileName(file)
          )
        : file;

    if (!existingFile) return toUpdate;

    const existingObjectIds = existingFile.maintenanceObjectIds;

    const newMaintenanceObjectIds = objects
      .filter(({ selectedFiles }) => selectedFiles.includes(file))
      .map(({ createdObject }) => createdObject!.id); // TODO Think if fine

    const maintenanceObjectIds = distinct([
      ...existingObjectIds,
      ...newMaintenanceObjectIds,
    ]);

    return areEqual(existingObjectIds, maintenanceObjectIds)
      ? toUpdate
      : [
          ...toUpdate,
          {
            id: existingFile.id,
            maintenanceObjectIds,
          },
        ];
  };
}

function shouldAdd(
  existingMaintenanceDocumentations: MaintenanceDocumentationDto[]
) {
  return (file) =>
    !existingMaintenanceDocumentations.find(
      (doc) => doc.fileName === toFileName(file)
    );
}

function toUsedFiles() {
  return (files, { selectedFiles }) => {
    return distinct([...files, ...selectedFiles]);
  };
}

function byShouldUpdate(filesToAdd: Array<File | MaintenanceDocumentationDto>) {
  return (file) => !filesToAdd.includes(file);
}

function byShouldRemove(files: Array<File | MaintenanceDocumentationDto>) {
  return ({ fileName }) => !files.find((file) => fileName === toFileName(file));
}

// eslint-disable-next-line import/prefer-default-export
export const setDocumentationsFor =
  (
    createMaintenanceDocumentation: (
      dto: CreateMaintenanceDocumentationDto
    ) => Promise<unknown>,
    updateMaintenanceDocumentation: (
      dto: UpdateMaintenanceDocumentationDto
    ) => Promise<unknown>
  ) =>
  async (
    maintenanceDocumentations: MaintenanceDocumentationDto[],
    objects: {
      createdObject?: MaintenanceObjectDto;
      selectedFiles: Array<File | MaintenanceDocumentationDto>;
    }[]
  ) => {
    if (!maintenanceDocumentations) {
      return;
    }

    const files = objects.reduce<Array<File | MaintenanceDocumentationDto>>(
      toUsedFiles(),
      new Array<File | MaintenanceDocumentationDto>()
    );

    const filesToAdd = files.filter(shouldAdd(maintenanceDocumentations));

    const createDtos = filesToAdd.reduce(
      toCreateDtos(objects),
      new Array<CreateMaintenanceDocumentationDto>()
    );

    const filesToUpdate = files.filter(byShouldUpdate(filesToAdd));

    const updateDtos = filesToUpdate.reduce(
      toUpdateDtos(maintenanceDocumentations, objects),
      new Array<UpdateMaintenanceDocumentationDto>()
    );

    const docsToRemoveObjectId = maintenanceDocumentations.filter(
      byShouldRemove(files)
    );

    const removeDtos = docsToRemoveObjectId.reduce(
      toRemoveDtos(objects),
      new Array<UpdateMaintenanceDocumentationDto>()
    );

    await Promise.all([
      ...createDtos.map((doc) => createMaintenanceDocumentation(doc)),
      ...updateDtos.map((doc) => updateMaintenanceDocumentation(doc)),
      ...removeDtos.map((doc) => updateMaintenanceDocumentation(doc)),
    ]);
  };

export const getDocumentStatus = (
  maintenanceDocument: MaintenanceDocumentationDto,
  maintenanceObjectId: string
): 'valid' | 'invalid' | 'notRelevant' | 'notValidated' | undefined => {
  if (!maintenanceDocument || !maintenanceObjectId) {
    return undefined;
  }
  if (
    !maintenanceDocument.validFor?.includes(maintenanceObjectId) &&
    !maintenanceDocument.invalidFor?.includes(maintenanceObjectId) &&
    !maintenanceDocument.notRelevantFor?.includes(maintenanceObjectId)
  ) {
    return 'notValidated';
  }
  if (maintenanceDocument.validFor?.includes(maintenanceObjectId)) {
    return 'valid';
  }
  if (maintenanceDocument.invalidFor?.includes(maintenanceObjectId)) {
    return 'invalid';
  }
  if (maintenanceDocument.notRelevantFor?.includes(maintenanceObjectId)) {
    return 'notRelevant';
  }
  return undefined;
};
