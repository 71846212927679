import { Entity } from '@warthungshelden/domain/common';

export type MaintenanceDocumentationUpdate = Omit<
  MaintenanceDocumentation,
  | 'fileName'
  | 'displayName'
  | 'validFor'
  | 'invalidFor'
  | 'notRelevantFor'
  | 'copyWith'
> & { validFor?: string[]; invalidFor?: string[]; notRelevantFor?: string[] };

export class MaintenanceDocumentation extends Entity {
  public readonly id: string;
  public readonly ownerId: string;
  public readonly fileName: string;
  public readonly displayName: string;
  public readonly maintenanceObjectIds: string[];
  public readonly validFor: string[];
  public readonly invalidFor: string[];
  public readonly notRelevantFor: string[];

  constructor(config: {
    id: string;
    ownerId: string;
    fileName: string;
    displayName: string;
    maintenanceObjectIds: string[];
    validFor: string[];
    invalidFor: string[];
    notRelevantFor: string[];
  }) {
    super();
    this.id = config.id;
    this.ownerId = config.ownerId;
    this.fileName = config.fileName;
    this.displayName = config.displayName;
    this.maintenanceObjectIds = config.maintenanceObjectIds;
    this.validFor = config.validFor;
    this.invalidFor = config.invalidFor;
    this.notRelevantFor = config.notRelevantFor;
  }
}
