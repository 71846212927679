import { Formik } from 'formik';
import React, { useState } from 'react';
import { FaShoppingCart, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';

import {
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceOfferStatusDtoEnum,
  MaintenanceRequestDto,
} from '@wartungshelden/shared-types';

import { InputField } from '../../../components';
import AddressForm from '../../../components/Address/AddressForm';
import Button from '../../../components/Basics/Buttons/Button';
import FieldError from '../../../components/Basics/FieldError';
import CheckBoxField from '../../../components/Basics/Inputs/CheckBox/CheckBoxField';
import TextareaRow from '../../../components/Basics/Inputs/TextareaRow';
import LoadingSpinner from '../../../components/Basics/Loaders/LoadingSpinner';
import Modal from '../../../components/Basics/Modals/Modal';
import {
  useUpdateMaintenanceOffer,
  useUpdateMaintenanceOfferDecision,
} from '../../../services/api/maintenance-offers/maintenance-offer-api';
import OfferOrOrderAddress from './OfferOrOrderAddress';

interface OfferDecisionProps {
  isMaintenanceTeamMemberAdmin: boolean;
  offer: CustomerOrMaintenanceTeamOfferDto;
  customer?: string;
}

enum ReasonLabels {
  priceReason = 'Ich finde den Preis nicht angemessen.',
  otherServiceProviderReason = 'Ich habe bereits einen Wartungsdienstleister gefunden.',
  qualityReason = 'Ich habe Bedenken bzgl. der Wartungsqualität.',
}

interface Address {
  company: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  city: string;
  country: string;
  addressLine: string;
}

type OfferDecisionFormikValues = {
  accepted: boolean;
  useOfferAddressForInvoice: boolean;
  priceReason: boolean;
  otherServiceProviderReason: boolean;
  qualityReason: boolean;
  checkBoxOther: boolean;
  other?: string;
  customerReference: string;
  address: Address;
};

const TermsAndConditions: React.FC = () => {
  return (
    <p>
      Es gelten die{' '}
      <a
        href="https://www.absturzsicherung.de/agb.html"
        target="_blank"
        rel="noreferrer"
        className="underline text-blue-abs"
      >
        AGB
      </a>{' '}
      und{' '}
      <a
        href="https://www.absturzsicherung.de/montage-und-wartungsbedingungen.html"
        target="_blank"
        rel="noreferrer"
        className="underline text-blue-abs"
      >
        Montage- und Wartungsbedingungen
      </a>{' '}
      der ABS Safety GmbH.
    </p>
  );
};

const OfferDecision: React.FC<React.PropsWithChildren<OfferDecisionProps>> = ({
  offer,
  isMaintenanceTeamMemberAdmin,
  customer,
}) => {
  const [isAcceptOfferModalOpen, setIsAcceptOfferModalOpen] = useState(false);
  const [isDeclineOfferModalOpen, setIsDeclineOfferModalOpen] = useState(false);
  useOutletContext<MaintenanceRequestDto | undefined>();
  const {
    mutateAsync: updateMaintenanceOfferDecision,
    isLoading: isOfferDecisionLoading,
  } = useUpdateMaintenanceOfferDecision();

  const { mutateAsync: updateMaintenanceOffer, isLoading: isOfferLoading } =
    useUpdateMaintenanceOffer({ disableToast: true });

  const initialValues: OfferDecisionFormikValues = {
    accepted: false,
    useOfferAddressForInvoice: true,
    priceReason: false,
    otherServiceProviderReason: false,
    qualityReason: false,
    checkBoxOther: false,
    other: undefined,
    customerReference: '',
    address: {
      company: offer.address.company,
      firstName: offer.address.firstName || '',
      lastName: offer.address.lastName || '',
      postalCode: offer.address.postalCode || '',
      city: offer.address.city || '',
      country: offer.address.countryISO || 'DE',
      addressLine: offer.address.addressLine1 || '',
    },
  };

  const mapBooleanToDeclineReason = (values: OfferDecisionFormikValues) => {
    const reasons: string[] = [];
    if (values.priceReason) {
      reasons.push(ReasonLabels.priceReason);
    }
    if (values.otherServiceProviderReason) {
      reasons.push(ReasonLabels.otherServiceProviderReason);
    }
    if (values.qualityReason) {
      reasons.push(ReasonLabels.qualityReason);
    }
    if (values.checkBoxOther && values.other) {
      reasons.push(values.other);
    }
    return reasons;
  };

  if (isMaintenanceTeamMemberAdmin) {
    if (offer.decision?.status === MaintenanceOfferStatusDtoEnum.ACCEPTED) {
      return <div>Dieses Angebot wurde angenommen</div>;
    }
    if (offer.decision?.status === MaintenanceOfferStatusDtoEnum.DECLINED) {
      return <div>Dieses Angebot wurde abgelehnt</div>;
    }
    return <div>Eine Entscheidung für dieses Dokument steht aus</div>;
  }
  return (
    <>
      {offer.decision?.status === MaintenanceOfferStatusDtoEnum.ACCEPTED ||
      offer.decision?.status === MaintenanceOfferStatusDtoEnum.DECLINED ? (
        <div>
          {offer.decision?.status === MaintenanceOfferStatusDtoEnum.DECLINED ? (
            <div data-cy="offerDecision">Du hast dieses Angebot abgelehnt.</div>
          ) : (
            <div data-cy="offerDecision">
              <p>Du hast dieses Angebot angenommen.</p>
              <TermsAndConditions />
              <p>
                Der Auftrag wird verbindlich, sobald Du die Auftragsbestätigung
                erhalten hast.
              </p>
            </div>
          )}
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={() => {
            // no submit
          }}
          validateOnMount
          enableReinitialize
          validationSchema={Yup.object({
            priceReason: Yup.boolean(),
            otherServiceProviderReason: Yup.boolean(),
            qualityReason: Yup.boolean(),
            checkBoxOther: Yup.boolean(),
            other: Yup.string().when('checkBoxOther', {
              is: true,
              then: (otherSchema) =>
                otherSchema.required('Bitte gib deinen sonstigen Grund an'),
            }),
          }).test(
            'At least one is selected',
            'Please check at least one checkbox',
            (object) => {
              if (
                object.priceReason ||
                object.otherServiceProviderReason ||
                object.qualityReason ||
                (object.checkBoxOther && object.other)
              ) {
                return true;
              }
              return new Yup.ValidationError(
                'Please check at least one checkbox',
                null,
                'checkboxList'
              );
            }
          )}
        >
          {({ values, isValid, errors }) => {
            return (
              <div className="flex flex-col justify-end">
                <div className="flex space-x-2">
                  <Button
                    label="Angebot ablehnen"
                    onClick={() => setIsDeclineOfferModalOpen(true)}
                    icon={<FaTimes />}
                    className="alert-button"
                  />
                  <Modal
                    isOpen={isDeclineOfferModalOpen}
                    headline="Angebot ablehnen"
                  >
                    Bitte teile uns mit, warum Du das Angebot ablehnen möchtest.
                    So hilfst Du uns, unsere Angebote zu verbessern.
                    <div className="flex flex-col mt-2 mb-2  space-y-2">
                      <CheckBoxField
                        name="priceReason"
                        label={ReasonLabels.priceReason}
                      />
                      <CheckBoxField
                        name="otherServiceProviderReason"
                        label={ReasonLabels.otherServiceProviderReason}
                      />
                      <CheckBoxField
                        name="qualityReason"
                        label={ReasonLabels.qualityReason}
                      />
                      <div className="flex">
                        <CheckBoxField name="checkBoxOther" label="" />
                        <div className="flex-1 ml-2">
                          <TextareaRow
                            disabled={!values.checkBoxOther}
                            rows={3}
                            label=""
                            placeholder="Sonstiges..."
                            name="other"
                          />
                        </div>
                      </div>
                      <FieldError
                        message={errors.other}
                        visible={Boolean(errors.other)}
                      />
                    </div>
                    <div className="flex justify-evenly space-x-4 mt-2">
                      <Button
                        label="Abbrechen"
                        icon={<FaTimesCircle />}
                        onClick={() => {
                          setIsDeclineOfferModalOpen(false);
                        }}
                        className="outline-button full-button"
                      />
                      <Button
                        label="Angebot ablehnen"
                        icon={<FaTimes />}
                        className="alert-button full-button"
                        disabled={!isValid}
                        onClick={async () => {
                          const {
                            addressLine: addressLine1,
                            country: countryISO,
                            ...address
                          } = values.address;
                          await updateMaintenanceOfferDecision({
                            id: offer.id,
                            decision: {
                              status: MaintenanceOfferStatusDtoEnum.DECLINED,
                              reasons: mapBooleanToDeclineReason(values),
                              address: {
                                ...address,
                                addressLine1,
                                countryISO,
                              },
                            },
                          });
                          setIsDeclineOfferModalOpen(false);
                        }}
                      />
                    </div>
                  </Modal>
                  <Button
                    label="Angebot annehmen"
                    onClick={() => setIsAcceptOfferModalOpen(true)}
                    icon={<FaShoppingCart />}
                    className="confirmation-button"
                  />
                  <Modal
                    isOpen={isAcceptOfferModalOpen}
                    headline="Angebot annehmen"
                  >
                    <div className="font-bold">
                      Welche Rechnungsadresse sollen wir verwenden?
                    </div>
                    <div className="mb-4">
                      <CheckBoxField
                        label="Angebotsadresse als Rechnungsadresse nutzen"
                        name="useOfferAddressForInvoice"
                      />
                      {customer && values.useOfferAddressForInvoice && (
                        <OfferOrOrderAddress
                          offerOrOrderAddress={{
                            ...offer.address,
                            firstName: offer.address.firstName ?? '',
                            lastName: offer.address.lastName ?? '',
                            email: offer.address.email ?? '',
                          }}
                          customerId={customer}
                          showContactData={false}
                        />
                      )}
                    </div>
                    {!values.useOfferAddressForInvoice && (
                      <AddressForm
                        namePrefix="address"
                        showContactData={false}
                      />
                    )}
                    <div className="font-bold mt-8">Interne Bestellnummer</div>
                    <p>
                      Wenn Du möchtest, kannst Du hier eine eigene Referenz wie
                      z.B. eine interne Bestellnummer angeben. Dies ist nach
                      Angebotsannahme nicht mehr möglich.
                    </p>
                    <InputField
                      name="customerReference"
                      placeholder="Eigene Referenz eingeben..."
                    />
                    <p className="my-6">
                      Um zahlungspflichtig die Wartung beauftragen zu können,
                      lese und bestätige bitte folgende Montage- und
                      Wartungsbedingungen.
                    </p>
                    <CheckBoxField
                      label={
                        <>
                          Ich habe die{' '}
                          <a
                            href="https://www.absturzsicherung.de/agb.html"
                            target="_blank"
                            rel="noreferrer"
                            className="underline text-blue-abs"
                          >
                            AGB
                          </a>{' '}
                          und{' '}
                          <a
                            href="https://www.absturzsicherung.de/montage-und-wartungsbedingungen.html"
                            target="_blank"
                            rel="noreferrer"
                            className="underline text-blue-abs"
                          >
                            Montage- und Wartungsbedingungen
                          </a>{' '}
                          der ABS Safety GmbH gelesen.
                        </>
                      }
                      name="accepted"
                    />
                    <div className="flex justify-evenly space-x-4 mt-6">
                      <Button
                        label="Abbrechen"
                        icon={<FaTimesCircle />}
                        onClick={() => {
                          setIsAcceptOfferModalOpen(false);
                        }}
                        className="outline-button full-button"
                      />
                      <Button
                        label="Zahlungspflichtig bestellen"
                        icon={<FaShoppingCart />}
                        className="confirmation-button full-button"
                        disabled={!values.accepted}
                        onClick={async () => {
                          const {
                            addressLine: addressLine1,
                            country: countryISO,
                            ...address
                          } = values.address;
                          await updateMaintenanceOfferDecision({
                            id: offer.id,
                            decision: {
                              status: MaintenanceOfferStatusDtoEnum.ACCEPTED,
                              address: {
                                ...address,
                                addressLine1,
                                countryISO,
                              },
                            },
                          });
                          await updateMaintenanceOffer({
                            id: offer.id,
                            customerReference: values.customerReference,
                          });
                          setIsAcceptOfferModalOpen(false);
                        }}
                      />
                    </div>
                  </Modal>
                </div>
                <div className="py-4">
                  <TermsAndConditions />
                </div>
              </div>
            );
          }}
        </Formik>
      )}
      <LoadingSpinner isLoading={isOfferLoading || isOfferDecisionLoading} />
    </>
  );
};

OfferDecision.defaultProps = {
  customer: undefined,
};

export default OfferDecision;
