import { useMutation } from 'react-query';

import {
  ChangeRouteInstallerDto,
  CreateRouteDto,
  RouteDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

const useRoute = () => {
  const axios = useAxios();

  const fetchRoute = async (
    createRouteDto: CreateRouteDto
  ): Promise<RouteDto[] | undefined> => {
    if (!axios) return undefined;
    const { data } = await axios.post<RouteDto[]>(
      '/maintenance-administration/resource-planning/routes',
      createRouteDto
    );
    return data;
  };

  return useMutation('route', fetchRoute);
};

export const useRouteWithInstaller = () => {
  const axios = useAxios();

  const fetchRoute = async (
    changeRouteInstallerDto: ChangeRouteInstallerDto
  ): Promise<RouteDto | undefined> => {
    if (!axios) return undefined;
    const { data } = await axios.put<RouteDto>(
      '/maintenance-administration/resource-planning/routes',
      changeRouteInstallerDto
    );
    return data;
  };

  return useMutation('route', fetchRoute);
};

export default useRoute;
