import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { RadioButtonField } from '../../../../../components';
import UnitInputField from '../../../../../components/Basics/Inputs/UnitInputField';
import { isStairLadderDto } from '../../../../../guards/isMaintenanceType';
import { PriceCalculationMaintenanceObjectModalFormikState } from '../../types/PriceCalculationMaintenanceObjectModalFormikState';

interface StairLadderSelectorProps {
  isUpdating: boolean;
}
const StairLadderSelector: React.FC<StairLadderSelectorProps> = ({
  isUpdating,
}) => {
  const { setFieldValue, values } =
    useFormikContext<PriceCalculationMaintenanceObjectModalFormikState>();
  useEffect(() => {
    if (isUpdating) {
      setFieldValue(
        'steps',
        isStairLadderDto(values.object.type) && values.object.type.parts[0]
      );
      setFieldValue(
        'amount',
        isStairLadderDto(values.object.type) && values.object.type.parts.length
      );
    }
  }, []);
  return (
    <div className="space-y-4 px-8">
      <div className="flex flex-row items-start">
        <div className="font-bold w-64 mr-4">Kategorie*</div>
        <div className="flex flex-1 flex-col space-y-2">
          <RadioButtonField name="steps" value={2} label="bis 3 Stufen" />
          <RadioButtonField name="steps" value={5} label="bis 8 Stufen" />
        </div>
      </div>
      <UnitInputField
        label="Anzahl*"
        name="amount"
        unit="Stück"
        allowDecimals={false}
        shouldSelectOnFocus
      />
    </div>
  );
};

export default StairLadderSelector;
