import React from 'react';

import FileNameComponent from './FileNameComponent';
import FileThumbnailComponent from './FileThumbnailComponent';

type FilePreviewComponentProps = {
  file?: File | null;
};

const FilePreviewComponent: React.FC<
  React.PropsWithChildren<FilePreviewComponentProps>
> = ({ file }) => {
  return file ? (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 h-full">
        <a
          href={file && URL.createObjectURL(file)}
          target="_blank"
          className="w-full h-full flex items-center justify-center"
          rel="noreferrer"
        >
          <FileThumbnailComponent file={file} />
        </a>
      </div>
      <FileNameComponent file={file} />
    </div>
  ) : null;
};

FilePreviewComponent.defaultProps = {
  file: undefined,
};

export default FilePreviewComponent;
