import {
  DomainRule,
  DomainRuleNotSatisfiedError,
} from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

export class MustBeMaintenanceTeamRule<T> extends DomainRule<T, T> {
  constructor(private user: User) {
    super();
  }

  protected async rule(ownedEntity: T) {
    if (!this.user.groups.includes('ABS-Wartung')) {
      throw new DomainRuleNotSatisfiedError(MustBeMaintenanceTeamRule);
    }
    return ownedEntity;
  }
}
