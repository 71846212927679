import { DomainEvent } from '@warthungshelden/domain/common';
import { User } from '@warthungshelden/domain/common';

import {
  MaintenanceOffer,
  MaintenanceOfferAddress,
} from '../maintenance-offer';
import { MaintenanceRequest } from './maintenance-request';

export class MaintenanceRequestCreated extends DomainEvent {
  public static override tag = 'maintenance.request.created';

  constructor(
    public readonly user: User,
    public readonly maintenanceRequest: MaintenanceRequest,
    public readonly address: MaintenanceOfferAddress,
    public readonly absCustomerNumber?: string
  ) {
    super();
  }
}

export class MaintenanceRequestStatusChanged extends DomainEvent {
  public static override tag = 'maintenance.request.status.changed';

  constructor(
    public readonly user: User,
    public readonly maintenanceRequest: MaintenanceRequest,
    public readonly currentOffer?: MaintenanceOffer
  ) {
    super();
  }
}
