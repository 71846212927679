import { PPEType, getPPETypeKeyByValue } from '../maintenance-object';
import { WithDuration } from './maintenance.type';

export class Ladder implements WithDuration {
  public readonly parts: number[];
  public readonly numberOfPlatforms: number;
  public readonly hasUsedHeightAssistanceCalculator?: boolean | null;

  constructor(config: {
    parts: number[];
    numberOfPlatforms: number;
    hasUsedHeightAssistanceCalculator?: boolean | null;
  }) {
    this.parts = config.parts;
    this.numberOfPlatforms = config.numberOfPlatforms;
    this.hasUsedHeightAssistanceCalculator =
      config.hasUsedHeightAssistanceCalculator;
  }

  private isSmallPart(length: number) {
    return length <= 10;
  }

  private isLargePart(length: number) {
    return length > 10 && length <= 30;
  }

  public get smallParts(): number[] {
    return this.parts.filter(this.isSmallPart);
  }

  public get largeParts(): number[] {
    return this.parts.filter(this.isLargePart);
  }

  public get durationInMinutes(): number {
    return this.parts.reduce((summedDuration, length) => {
      if (length <= 15) {
        return summedDuration + 25;
      }
      return Math.ceil(25 + length - 15);
    }, 0);
  }
}

export class Railing implements WithDuration {
  public readonly length: number;

  constructor(config: { length: number }) {
    this.length = config.length;
  }

  // TODO Validate this duration since this is copy of ladder
  public get durationInMinutes(): number {
    if (this.length <= 15) {
      return 25;
    }
    return Math.ceil(25 + this.length - 15);
  }
}

export class PersonalProtectionEquipment implements WithDuration {
  public readonly quantity: number;
  public readonly category?: string | null; // TODO To be discussed
  public readonly systemType?: string | null;

  constructor(config: {
    quantity: number;
    systemType?: string | null;
    category?: string | null;
  }) {
    this.quantity = config.quantity;
    this.category = config.category;
    this.systemType = config.systemType;
  }

  public get durationInMinutes(): number {
    if (
      this.systemType === getPPETypeKeyByValue(PPEType.SLING) ||
      this.systemType === getPPETypeKeyByValue(PPEType.HELMET) ||
      this.systemType === getPPETypeKeyByValue(PPEType.DOME_COMPLETE) ||
      this.systemType === getPPETypeKeyByValue(PPEType.DOME_FABRIC_THREAD) ||
      this.systemType === getPPETypeKeyByValue(PPEType.DOME_FIX) ||
      this.systemType === getPPETypeKeyByValue(PPEType.DAMPER) ||
      this.systemType === getPPETypeKeyByValue(PPEType.GLIDER) ||
      this.systemType === getPPETypeKeyByValue(PPEType.TEMP_ROPE)
    ) {
      return this.quantity * 5;
    } else if (this.systemType === getPPETypeKeyByValue(PPEType.BELT)) {
      return this.quantity * 10;
    } else if (
      this.systemType === getPPETypeKeyByValue(PPEType.RESCUE_DESCENDER)
    ) {
      return this.quantity * 15;
    } else if (this.systemType === getPPETypeKeyByValue(PPEType.PPE_SET)) {
      return this.quantity * 20;
    }

    throw new Error('No valid PPE Category');
  }
}

export class OtherType implements WithDuration {
  public readonly name: string;

  constructor(config: { name: string }) {
    this.name = config.name;
  }

  public get durationInMinutes(): number | undefined {
    return undefined;
  }
}

export type FallProtectionLocation = 'Roof' | 'Overhead' | 'Facade';

export class FallProtection implements WithDuration {
  public readonly hasUsedRopeAssistanceCalculator?: boolean | null;
  public readonly location?: FallProtectionLocation | null;
  public readonly system?: FallProtectionSystem | null;
  public readonly access: string[];

  constructor(config: {
    hasUsedRopeAssistanceCalculator?: boolean | null;
    location?: FallProtectionLocation | null;
    system?: FallProtectionSystem | null;
    access: string[];
  }) {
    this.hasUsedRopeAssistanceCalculator =
      config.hasUsedRopeAssistanceCalculator;
    this.location = config.location;
    this.system = config.system;
    this.access = config.access;
  }

  public get durationInMinutes(): number | undefined {
    return this.system?.durationInMinutes;
  }
}

export type FallProtectionSystem =
  | RopeFallProtectionSystem
  | PushLockFallProtectionSystem
  | SingleAnchorFallProtectionSystem;

export class RopeFallProtectionSystem implements WithDuration {
  public readonly singleAnchors: number;
  public readonly ropeLength: number;
  public readonly systemAnchors: number;

  constructor(config: {
    singleAnchors: number;
    ropeLength: number;
    systemAnchors: number;
  }) {
    this.singleAnchors = config.singleAnchors;
    this.ropeLength = config.ropeLength;
    this.systemAnchors = config.systemAnchors;
  }

  public get durationInMinutes(): number {
    return Math.ceil((60 / 50) * this.ropeLength) + 5 * this.singleAnchors;
  }
}

export class PushLockFallProtectionSystem implements WithDuration {
  public readonly anchors: number; // = LOCK I
  public readonly sleeves: number; // = LOCK II

  constructor(config: { anchors: number; sleeves: number }) {
    this.anchors = config.anchors;
    this.sleeves = config.sleeves;
  }

  public get durationInMinutes(): number {
    return 5 * this.sleeves + 5 * this.anchors;
  }
}

export class SingleAnchorFallProtectionSystem implements WithDuration {
  public readonly singleAnchors: number;

  constructor(config: { singleAnchors: number }) {
    this.singleAnchors = config.singleAnchors;
  }

  public get durationInMinutes(): number {
    return this.singleAnchors * 5;
  }
}

export class RailSystem implements WithDuration {
  public readonly length: number;
  public readonly brackets: number;

  constructor(config: { readonly length: number; readonly brackets: number }) {
    this.length = config.length;
    this.brackets = config.brackets;
  }

  get durationInMinutes(): number | undefined {
    return undefined; // TODO Implement duration
  }
}

export class ClimbingProtection implements WithDuration {
  public readonly length: number;
  public readonly ladderId?: string;

  constructor(config: { readonly length: number; readonly ladderId?: string }) {
    this.length = config.length;
    this.ladderId = config.ladderId;
  }

  get durationInMinutes(): number | undefined {
    return undefined; // TODO Implement duration
  }
}

export class StairLadder implements WithDuration {
  public readonly parts: number[];

  private isSmallPart(steps: number) {
    return steps <= 3;
  }

  private isLargePart(steps: number) {
    return steps > 3 && steps <= 8;
  }

  public get smallParts(): number[] {
    return this.parts.filter(this.isSmallPart);
  }

  public get largeParts(): number[] {
    return this.parts.filter(this.isLargePart);
  }

  constructor(config: { readonly parts: number[] }) {
    this.parts = config.parts;
  }

  get durationInMinutes(): number | undefined {
    return undefined; // TODO Implement duration
  }
}

export class Overpass implements WithDuration {
  public readonly parts: number[];

  constructor(config: { readonly parts: number[] }) {
    this.parts = config.parts;
  }

  private isSmallPart(steps: number) {
    return steps <= 3;
  }

  private isLargePart(steps: number) {
    return steps > 3 && steps <= 8;
  }

  public get smallParts(): number[] {
    return this.parts.filter(this.isSmallPart);
  }

  public get largeParts(): number[] {
    return this.parts.filter(this.isLargePart);
  }

  get durationInMinutes(): number | undefined {
    return undefined; // TODO Implement duration
  }
}

export class GroundStairs implements WithDuration {
  public readonly amount: number;

  constructor(config: { readonly amount: number }) {
    this.amount = config.amount;
  }
  get durationInMinutes(): number | undefined {
    return undefined; // TODO Implement duration
  }
}
