import { Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { FormikMaintenanceWizardValues } from '../../../pages/UserPages/maintenance/maintenanceWizard/types/FormikMaintenanceWizardValues';
import Button from '../../Basics/Buttons/Button';
import FieldError from '../../Basics/FieldError';
import HintLink from '../../Basics/HintLink';
import { UnitInputField } from '../../index';

const RopeSystemAssistanceValidationSchema = Yup.object().shape({
  area: Yup.number()
    .min(34, 'We cannot calculate on area that small')
    .required(''),
});

const RopeSystemAssistance: React.FC<
  React.PropsWithChildren<{
    index: number;
    onClose: () => void;
  }>
> = ({ index, onClose }) => {
  const { setFieldValue } = useFormikContext<FormikMaintenanceWizardValues>();

  const [useKnowsAreaOrSideLengths, setUseKnowsAreaOrSideLengths] =
    useState(false);

  const calculateRopeLengthAndSystemAnchorsFromArea = (area) => {
    const ropeLengthForOneSideOfArea = Math.sqrt(area) - 4;
    const ropeLength = 4 * ropeLengthForOneSideOfArea;
    const systemAnchors = Math.ceil(ropeLengthForOneSideOfArea / 6) * 4;
    setFieldValue(
      `maintenanceObjects.${index}.system.ropeLength`,
      Math.ceil(ropeLength)
    );
    setFieldValue(
      `maintenanceObjects.${index}.system.systemAnchors`,
      Math.ceil(systemAnchors)
    );
    setFieldValue(
      `maintenanceObjects.${index}.hasUsedRopeAssistanceCalculator`,
      true
    );
  };

  return (
    <div className="w-full">
      {useKnowsAreaOrSideLengths ? (
        <>
          <div className="flex flex-1 flex-col">
            <FaArrowLeft
              className="flex flex-1 text-2xl cursor-pointer"
              onClick={() => {
                setUseKnowsAreaOrSideLengths(false);
              }}
            />

            <div className="flex flex-col w-full mt-6 items-center text-center mb-12">
              <strong className="mb-6">
                Ich kenne die Dachfläche, aber nicht die Bestandteile darauf.
              </strong>
              <div>
                Mithilfe von Informationen zur Dachfläche, können wir die
                Bestandteile Deiner Absturzsicherung automatisiert ermitteln.
                Bitte beachte, dass die ermittelten Werte eine Schätzung sind
                und wir von einer rechteckigen Dachfläche ausgehen.
              </div>
            </div>
          </div>

          <Formik
            validationSchema={RopeSystemAssistanceValidationSchema}
            initialValues={{
              area: undefined,
            }}
            validateOnMount
            onSubmit={(values) => {
              if (values.area) {
                calculateRopeLengthAndSystemAnchorsFromArea(values.area);
                toast.success('Geschätzte Werte wurden eingefügt.', {
                  position: 'top-center',
                });
              }
              onClose();
            }}
          >
            {({ isValid, submitForm, errors, dirty, values }) => (
              <>
                <div className="flex flex-col w-full flex-start px-8">
                  <strong>Dachfläche</strong>
                  <UnitInputField
                    name="area"
                    label="Flächeninhalt"
                    unit="m²"
                    isLabelBold={false}
                    labelWidthTailwindClass="w-80"
                  />
                  <FieldError
                    message="Der Flächeninhalt ist für eine automatische Berechnung zu klein."
                    visible={Boolean(dirty && errors?.area)}
                  />
                  {dirty && errors?.area && (
                    <div>
                      <div>
                        Kein Problem, gemeinsam finden wir sicherlich eine
                        Lösung.
                      </div>
                      <p>
                        Kontaktiere uns unter:
                        <br />
                        <span className="mr-1">+49 201 7475985-730</span> |
                        <a
                          href="mailto:inspektion@absturzsicherung.de"
                          className="ml-1 text-blue-abs"
                        >
                          inspektion@absturzsicherung.de
                        </a>
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex w-full justify-center mt-24">
                  <Button
                    label="Ok"
                    onClick={() => {
                      if (!isValid) {
                        toast.error(
                          'Es konnten keine geschätzten Werte eingefügt werden.',
                          {
                            position: 'top-center',
                          }
                        );
                        onClose();
                      }
                      submitForm();
                    }}
                  />
                </div>
              </>
            )}
          </Formik>
        </>
      ) : (
        <>
          <div className="flex flex-1 flex-col">
            <FaArrowLeft
              className="flex flex-1 text-2xl cursor-pointer"
              onClick={() => {
                onClose();
              }}
            />

            <div className="flex flex-col w-full mt-6 items-center text-center mb-12">
              <strong className="mb-6">Wo genau benötigst Du Hilfe?</strong>
              <div>
                Bitte teile uns mit, welche Informationen Dir vorliegen.
              </div>
              <div>Wähle hierfür die entsprechende Option aus.</div>
            </div>
          </div>

          <div className="flex flex-col overflow-y-auto w-full mb-6">
            <Button
              className="primary-button full-button"
              label="Ich kenne die Dachfläche, aber nicht die Bestandteile darauf."
              onClick={() => {
                setUseKnowsAreaOrSideLengths(true);
              }}
            />
          </div>
          <div className="flex flex-col w-full items-center">
            <HintLink hintLabel="Trifft keine der Optionen zu?">
              <div className="mt-2">
                <p className="mb-2">
                  Kein Problem, gemeinsam finden wir sicherlich eine Lösung.
                </p>
                <p>
                  Kontaktiere uns unter:
                  <br />
                  <span className="mr-1">+49 201 7475985-730</span> |
                  <a
                    href="mailto:inspektion@absturzsicherung.de"
                    className="ml-1 text-blue-abs"
                  >
                    inspektion@absturzsicherung.de
                  </a>
                </p>
              </div>
            </HintLink>
          </div>
        </>
      )}
    </div>
  );
};

export default RopeSystemAssistance;
