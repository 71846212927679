import { Entity } from '@warthungshelden/domain/common';

export class InstallerContact extends Entity {
  public readonly email?: string;
  public readonly mobile?: string;

  constructor(config: { readonly email?: string; readonly mobile?: string }) {
    super();
    this.email = config.email;
    this.mobile = config.mobile;
  }
}

export class InstallerAddress extends Entity {
  public readonly street?: string;
  public readonly zip?: string;
  public readonly city?: string;
  public readonly country?: string;

  constructor(config: {
    readonly street?: string;
    readonly zip?: string;
    readonly city?: string;
    readonly country?: string;
  }) {
    super();
    this.street = config.street;
    this.zip = config.zip;
    this.city = config.city;
    this.country = config.country;
  }
}

export class Installer extends Entity {
  public readonly id: string;
  public readonly name: string;
  public readonly address: InstallerAddress;
  public readonly contact: InstallerContact;
  public readonly isAbsInstaller: boolean | null;

  constructor(config: {
    readonly id: string;
    readonly name: string;
    readonly address: InstallerAddress;
    readonly contact: InstallerContact;
    readonly isAbsInstaller: boolean | null;
  }) {
    super();
    this.id = config.id;
    this.name = config.name;
    this.address = config.address;
    this.contact = config.contact;
    this.isAbsInstaller = config.isAbsInstaller;
  }
}
