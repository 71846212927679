import { isPhoneNumber } from 'class-validator';
import { Formik, FormikValues } from 'formik';
import React, { useContext, useState } from 'react';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import AuthInputField from '../../components/Auth/AuthInputField';
import AuthLinkButton from '../../components/Auth/AuthLinkButton';
import AuthOptionButton from '../../components/Auth/AuthOptionButton';
import AuthPhoneInputField from '../../components/Auth/AuthPhoneInputField';
import AuthPrimaryButton from '../../components/Auth/AuthPrimaryButton';
import Button from '../../components/Basics/Buttons/Button';
import FieldError from '../../components/Basics/FieldError';
import HorizontalLine from '../../components/Basics/HorizontalLine';
import Modal from '../../components/Basics/Modals/Modal';
import { SessionContext } from '../../contexts/SessionContext';

const VerifyPage = ({ isAdminLogin }: { isAdminLogin?: boolean }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [params] = useSearchParams();

  const { verifyUser } = useContext(SessionContext);

  const [isVerificationCodeExpired, setIsVerificationCodeExpired] =
    useState<boolean>(false);

  const initialCode =
    ((state as any)?.verificationCode as string | undefined) ??
    params.get('verificationCode');

  const initialEmail =
    ((state as any)?.email as string | undefined) ?? params.get('email');

  const initialPhone = !initialEmail
    ? ((state as any)?.phone as string | undefined) ?? params.get('phone')
    : undefined;

  const [isEmailSelected, setIsEmailSelected] = useState(!initialPhone);

  const navigateToResendCode = (email?: string, phone?: string) => {
    navigate(
      isAdminLogin
        ? '/admin/register/verify/resend'
        : '/register/verify/resend',
      { state: { email, phone } }
    );
  };

  const navigateToSuccess = (email?: string, phone?: string) => {
    navigate(
      isAdminLogin ? '/admin/register/success' : '/register/register/success',
      { state: { email, phone } }
    );
  };

  const VerifyValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-Mail nicht gültig')
      .when({
        is: () => isEmailSelected,
        then: (schema) => schema.required('E-Mail erforderlich'),
        otherwise: (schema) => schema.optional(),
      }),
    phone: Yup.string().when({
      is: () => !isEmailSelected,
      then: (schema) =>
        schema.test({
          message: 'Rufnummer nicht gültig',
          test: (number) => isPhoneNumber(number ?? ''),
        }),
      otherwise: (schema) => schema.optional(),
    }),
    verificationCode: Yup.string()
      .required('Code erforderlich')
      .length(6, 'Code ungültig'),
  });

  const onSubmit = async (values: FormikValues) => {
    const verifyResponse = await verifyUser(
      isEmailSelected ? values.email : values.phone,
      values.verificationCode
    );

    if (verifyResponse.isSuccessful) {
      navigateToSuccess(
        values.email.length ? values.email : undefined,
        values.phone.length ? values.phone : undefined
      );
    } else if (verifyResponse.error.code === 'ExpiredCodeException') {
      setIsVerificationCodeExpired(true);
    } else {
      toast.error(
        'Verifizierung fehlgeschlagen. Bitte prüfe den angegebenen Code.',
        {
          position: 'top-center',
        }
      );
    }
  };

  return (
    <>
      <div className="flex justify-center px-8 pt-4 pb-4">
        <img src="/assets/logo.svg" alt="ABS Wartung" className="w-3/5" />
      </div>
      <Formik
        validationSchema={VerifyValidationSchema}
        initialValues={{
          email: initialEmail ?? '',
          phone: initialPhone ?? '',
          verificationCode: initialCode ?? '',
        }}
        onSubmit={onSubmit}
      >
        {({
          submitForm,
          isValid,
          dirty,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => (
          <div className="bg-white shadow w-full rounded-lg mb-2">
            <div className="px-5 py-7 ">
              <div className="py-2">
                {isEmailSelected
                  ? 'Bestätige Deine Anmeldung mit Deiner E-Mail-Adresse und dem Code, den wir Dir per E-Mail gesendet haben.'
                  : 'Bestätige Deine Anmeldung mit Deiner Telefonnummer und dem Code, den wir Dir per SMS gesendet haben.'}
              </div>
              <div className="pt-3">
                {isEmailSelected ? (
                  <AuthInputField name="email" label="E-Mail" />
                ) : (
                  <AuthPhoneInputField name="phone" label="Telefon" />
                )}
                <AuthLinkButton
                  label={`Stattdessen ${
                    isEmailSelected ? 'Telefonnummer' : 'E-Mail'
                  } verwenden`}
                  onClick={() => {
                    setFieldValue(isEmailSelected ? 'email' : 'phone', '');
                    setFieldTouched(isEmailSelected ? 'email' : 'phone', false);
                    setIsEmailSelected((prev) => !prev);
                  }}
                />
                <FieldError
                  message={errors.email}
                  visible={Boolean(errors.email && touched.email)}
                />
                <FieldError
                  message={errors.phone}
                  visible={Boolean(errors.phone && touched.phone)}
                />
              </div>
              <div className="pt-3">
                <AuthInputField name="verificationCode" label="Code" />
              </div>
              <div className="pt-7">
                <AuthPrimaryButton
                  kind="confirm"
                  onClick={submitForm}
                  label="Nutzerkonto bestätigen"
                  disabled={!isValid || !dirty}
                  className="primary-button full-button"
                />
              </div>
            </div>
            <HorizontalLine />
            <div className="py-5">
              <div className="grid grid-cols-1 gap-1">
                <AuthOptionButton
                  icon={<BsArrowCounterclockwise />}
                  label="Code erneut senden"
                  onClick={navigateToResendCode}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      <Modal
        isOpen={isVerificationCodeExpired}
        headline="Code ist nicht mehr gültig!"
        center
      >
        <div>Der Code für die Bestätigung ist abgelaufen.</div>
        <div>Du kannst per E-Mail einen neuen Code erhalten.</div>

        <div>
          <Link
            to="/register/verify/resend"
            className="hover:border-b-2 hover:border-blue-abs text-black-abs mb-1 text-blue-abs"
          >
            Neuen Code anfordern
          </Link>
        </div>

        <div className="flex w-full flex-row justify-center pt-4 mt-2 border-t-2 border-gray">
          <Button
            className="primary-button small-button"
            onClick={() => {
              setIsVerificationCodeExpired(false);
            }}
            label="Ok"
          />
        </div>
      </Modal>
    </>
  );
};

VerifyPage.defaultProps = {
  isAdminLogin: false,
};

export default VerifyPage;
