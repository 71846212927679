import React from 'react';
import { Cell } from 'react-table';

import { MaintenanceRequestDto } from '@wartungshelden/shared-types';

import { getTimestampOrUnknown } from '../../../services/timeDateService';

interface ExecutionDateCellProps {
  cell: Cell<MaintenanceRequestDto>;
}

const ExecutionDateCell: React.FC<
  React.PropsWithChildren<ExecutionDateCellProps>
> = ({ cell }) => {
  const date = cell?.value?.suggestedDate;

  return <>{getTimestampOrUnknown(date)}</>;
};

export default ExecutionDateCell;
