import { DomainRule } from '@warthungshelden/domain/common';
import { DomainRuleNotSatisfiedError } from '@warthungshelden/domain/common';

import { Building } from '../building';
import { MaintenanceObjectRepository } from '../maintenance-object';

export class CanNotBeInObjectRule extends DomainRule<Building, Building> {
  constructor(
    private maintenanceObjectRepository: MaintenanceObjectRepository,
    private userId: string
  ) {
    super();
  }

  protected async rule(building: Building): Promise<Building> {
    const userMaintenanceObjects =
      await this.maintenanceObjectRepository.getAllByUser(this.userId);

    if (
      userMaintenanceObjects.find(
        ({ buildingId }) => buildingId === building.id
      )
    ) {
      throw new DomainRuleNotSatisfiedError(CanNotBeInObjectRule);
    }
    return building;
  }
}
