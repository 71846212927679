import React from 'react';
import { FaStar } from 'react-icons/fa';

import { MenuOptionType } from '../types/MenuOptionType';
import { PriceInformationCombination } from '../utils/combinations';
import { getPreselectedObject } from '../utils/utils';

interface MaintenanceTypeMenuButtonProps {
  onPreselectedObjectClick: (
    preselectedObject: PriceInformationCombination
  ) => void;
  menuOption: MenuOptionType;
  close: () => void;
  isFavorite: boolean;
  onToggleFavorite: () => void;
}

const MaintenanceTypeMenuButton = React.forwardRef<
  HTMLButtonElement,
  MaintenanceTypeMenuButtonProps
>(
  (
    {
      onPreselectedObjectClick,
      menuOption,
      isFavorite,
      onToggleFavorite,
      close,
    },
    ref
  ) => {
    return (
      <div
        className="flex justify-between w-full p-2 text-left border-b-2 border-gray-lighter last:border-b-0
      hover:underline hover:decoration-blue-abs hover:decoration-2 hover:underline-offset-4"
      >
        <button
          data-cy={`add-${menuOption.key}`}
          ref={ref}
          type="button"
          className="flex-1 text-left"
          onClick={() => {
            close();
            onPreselectedObjectClick(getPreselectedObject(menuOption.key));
          }}
        >
          {menuOption.value}
        </button>

        <div>
          <FaStar
            className={`text-xl cursor-pointer ${
              isFavorite ? 'text-blue-abs' : 'text-gray'
            }`}
            onClick={onToggleFavorite}
          />
        </div>
      </div>
    );
  }
);

export default MaintenanceTypeMenuButton;
