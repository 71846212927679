import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  to: string;
};

const Tab: React.FC<React.PropsWithChildren<Props>> = ({ to, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname.includes(to);
  const handleClick = () => navigate(`/${to}`);

  return (
    <button
      data-cy="components-basic-navigation-tab"
      onClick={handleClick}
      type="button"
      className={`flex mr-5 text-black-abs cursor-pointer pb-1 px-2 ${
        isActive && 'border-b-4 border-red font-bold'
      } `}
    >
      {children}
    </button>
  );
};

export default Tab;
