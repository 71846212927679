import { Formik } from 'formik';
import React, { useContext } from 'react';

import { CustomerDto } from '@wartungshelden/shared-types';

import { CustomerSchema } from '../../constants/CustomerYupSchema';
import { SessionContext } from '../../contexts/SessionContext';
import { CustomerFormikValues } from '../../pages/SettingsPages/types/customerFormikValues';
import { useUpdateCustomer } from '../../services/api/customer/customer-api';
import AddressForm from '../Address/AddressForm';
import LoadingSpinner from '../Basics/Loaders/LoadingSpinner';
import Modal from '../Basics/Modals/Modal';
import { Button } from '../index';

interface CustomerDataModalProps {
  isOpen: boolean;
  setIsOpen: (state: boolean | ((prevState: boolean) => boolean)) => void;
  customer: CustomerDto;
}

const CustomerDataModal: React.FC<
  React.PropsWithChildren<CustomerDataModalProps>
> = ({ isOpen, setIsOpen, customer }) => {
  const { userInfo } = useContext(SessionContext);
  const { mutateAsync: updateCustomer, isLoading } = useUpdateCustomer();

  const initialValues: CustomerFormikValues = {
    email: customer?.email || userInfo?.email || '',
    phone: customer.phoneNumber || undefined,
    billingAddresses:
      customer?.billingAddresses.length && customer?.billingAddresses.length > 0
        ? customer.billingAddresses.map((address) => {
            return {
              company: address.company || '',
              firstName: address.firstName || '',
              lastName: address.lastName || '',
              postalCode: address.address.postalCode || '',
              city: address.address.city || '',
              country: address.address.countryISO || 'DE',
              addressLine: address.address.addressLine1 || '',
            };
          })
        : [
            {
              company: '',
              firstName: userInfo?.givenName || '',
              lastName: userInfo?.familyName || '',
              postalCode: '',
              city: '',
              country: 'DE',
              addressLine: '',
            },
          ],
  };

  return (
    <>
      <Modal headline="Adresse" isOpen={isOpen}>
        <Formik
          initialValues={initialValues}
          validationSchema={CustomerSchema}
          validateOnMount
          validateOnChange
          onSubmit={async (values) => {
            const updatedCustomer = {
              ...customer,
              email: values.email !== '' ? values.email : customer.email,
              phoneNumber: values.phone || null,
              billingAddresses: values.billingAddresses.map((address) => {
                return {
                  address: {
                    addressLine1: address.addressLine,
                    city: address.city,
                    postalCode: address.postalCode,
                    countryISO: address.country,
                  },
                  firstName: address.firstName,
                  lastName: address.lastName,
                  company: address.company,
                  id: address.id,
                };
              }),
            };

            await updateCustomer(updatedCustomer);
          }}
          enableReinitialize
        >
          {({ resetForm, submitForm, isValid }) => {
            return (
              <>
                <AddressForm namePrefix="billingAddresses.0" />
                <div className="flex justify-center">
                  <div>
                    <Button
                      className="primary-button small-button"
                      label="Abbrechen"
                      onClick={() => {
                        resetForm();
                        setIsOpen(false);
                      }}
                    />
                  </div>
                  <div className="ml-4">
                    <Button
                      className="primary-button small-button"
                      onClick={async () => {
                        await submitForm();
                        setIsOpen(false);
                      }}
                      label="Ok"
                      disabled={!isValid}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </Modal>
      <LoadingSpinner isLoading={isLoading}>Speichern...</LoadingSpinner>
    </>
  );
};

export default CustomerDataModal;
