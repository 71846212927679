import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

export const dateToMonthString = (date: Date) =>
  format(new Date(date), 'LLLL yyyy', { locale: de });

export const dueDateOrToday = (dueDate?: Date | null) => dueDate || new Date();

export const getDateOrUnknown = (myDate?: Date | null) =>
  myDate ? format(new Date(myDate), 'dd.MM.yyyy') : null;

export const getTimestampOrUnknown = (myDate?: Date | null) =>
  myDate ? format(new Date(myDate), 'dd.MM.yyyy, HH:mm') : null;

export const formatToMonth = (myDate: Date) =>
  format(new Date(myDate), 'MM/yyyy');

export const monthToDate = (month: string) => {
  const [mon, year] = month.split('/');
  return new Date(Date.UTC(Number(year), Number(mon) - 1, 1));
};

export const formatToHHMM = (durationHours: number) => {
  const hours = Math.floor(durationHours);
  return `${hours}:${String(((durationHours - hours) * 60).toFixed(0)).padStart(
    2,
    '0'
  )}`;
};

const isIsoDateString = (value: any): boolean => {
  const isoDateFormat =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/gm;
  return value && typeof value === 'string' && isoDateFormat.test(value);
};
// eslint-disable-next-line
export const handleDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body;
  }
  // eslint-disable-next-line
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      // eslint-disable-next-line
      body[key] = parseISO(value);
    } else if (typeof value === 'object') {
      handleDates(value);
    }
  }
};
