/* eslint-disable react/jsx-props-no-spreading,react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import { FaPlus, FaReply } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CellProps, Column, Row, useSortBy, useTable } from 'react-table';

import {
  BuildingDto,
  MaintenanceObjectDto,
  MaintenanceOrderStatusDtoEnum,
  MaintenanceTypeDto,
} from '@wartungshelden/shared-types';

// eslint-disable-next-line import/no-cycle
import { renderTypeCell } from '../../../constants/MaintenanceUtils';
import { maintenanceRoutes, routes } from '../../../constants/routes';
import { taskStatuses } from '../../../constants/taskStatus';
import { providedServiceToLabel } from '../../../pages/UserPages/maintenance/MaintenanceObject';
import { useMaintenanceOffers } from '../../../services/api/maintenance-offers/maintenance-offer-api';
import { useMaintenanceRequests } from '../../../services/api/maintenance-requests/maintenance-request-api';
import { renderTaskStatus } from '../../../services/maintenanceService';
import { getTimestampOrUnknown } from '../../../services/timeDateService';
import Button from '../../Basics/Buttons/Button';
import Loader from '../../Basics/Loaders/Loader';
import { InfoMessageBox } from '../../Basics/MessageBox';
// eslint-disable-next-line import/no-cycle
import DueDateCell from './DueDateCell';

type TableProps = {
  maintenances: MaintenanceObjectDto[] | undefined;
  buildings: BuildingDto[] | undefined | null;
  noBuildings: boolean;
  noMaintenances: boolean;
  isLoading: boolean;
};

export interface TableData {
  maintenance: MaintenanceObjectDto;
  maintenanceName: string;
  building?: string | null;
  type: MaintenanceTypeDto;
  dueDate?: Date | null;
  term?: string | null;
  status: number;
}

const UserMaintenanceTable: React.FC<React.PropsWithChildren<TableProps>> = ({
  maintenances,
  buildings,
  isLoading,
  noBuildings,
  noMaintenances,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: maintenanceRequests, isLoading: areRequestsLoading } =
    useMaintenanceRequests();
  const { data: maintenanceOffers, isLoading: areOffersLoading } =
    useMaintenanceOffers();

  const renderStatusCell = (cell: CellProps<TableData, number>) =>
    renderTaskStatus(cell.value);

  const columns = React.useMemo<Column<TableData>[]>(
    () => [
      {
        Header: 'Gebäude',
        accessor: 'building',
      },
      {
        Header: 'Interne Bezeichnung',
        accessor: 'maintenanceName',
        sortType: 'sortMaintenanceName',
      },
      {
        Header: 'Wartungstyp',
        accessor: 'type',
        // @ts-ignore
        Cell: (cell) => <div>{renderTypeCell(cell)}</div>,
        sortType: 'sortMaintenanceType',
      },
      {
        Header: 'Nächste Fälligkeit',
        accessor: 'dueDate',
        id: 'dueDate',
        // @ts-ignore
        Cell: (cell) => <DueDateCell cell={cell} />,
        sortType: 'datetimeNullSafe',
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        // @ts-ignore
        Cell: (cell) => <div>{renderStatusCell(cell)}</div>, // visual representation
      },
      {
        Header: 'Termin',
        accessor: 'term',
      },
    ],
    []
  );

  const data = React.useMemo<TableData[]>(
    () =>
      maintenances?.map((maintenance) => {
        const building = buildings?.find(
          ({ id }) => id === maintenance.buildingId
        );

        let status = taskStatuses.OPEN;

        const currentRequest = maintenanceRequests?.find(
          ({ id }) => id === maintenance.currentRequestId
        );

        if (currentRequest) status = taskStatuses.REQUESTED;

        const currentOffer = maintenanceOffers?.find(
          ({ requestId }) => requestId === currentRequest?.id
        );

        if (
          currentOffer?.order &&
          currentOffer?.order.status === MaintenanceOrderStatusDtoEnum.CONFIRMED
        ) {
          status = taskStatuses.OFFER_ACCEPTED;
        }

        const termDate = currentOffer?.suggestedDate;

        return {
          maintenance,
          maintenanceName: maintenance.name,
          building: building?.name,
          type: maintenance?.type, // TODO Map to user readable
          dueDate: maintenance?.dueDate,
          status,
          term: getTimestampOrUnknown(termDate), // TODO Check if Request exists
        };
      }) ?? [],
    [buildings, maintenances, areRequestsLoading, areOffersLoading]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<TableData>(
      {
        columns,
        data,
        sortTypes: {
          // TODO: In the current alpha the built in sort function for alphanumeric ignores the case already
          // https://github.com/TanStack/react-table/blob/alpha/packages/react-table/src/sortTypes.ts */
          alphanumeric: (
            row1: Row<TableData>,
            row2: Row<TableData>,
            columnName: string
          ) => {
            const r1 = String(row1.values[columnName]).toLowerCase();
            const r2 = String(row2.values[columnName]).toLowerCase();
            return r1.localeCompare(r2);
          },
          datetimeNullSafe: (
            row1: Row<TableData>,
            row2: Row<TableData>,
            columnName: string
          ) => {
            let [a, b] = [row1.values[columnName], row2.values[columnName]];

            a = a ? new Date(a).getTime() : Infinity;
            b = b ? new Date(b).getTime() : Infinity;

            if (a === b) {
              return 0;
            }
            return a > b ? 1 : -1;
          },
          sortMaintenanceType: (row1: Row<TableData>, row2: Row<TableData>) => {
            const a = providedServiceToLabel(
              row1.values.type.type === 'other_type'
                ? row1.values.type.name
                : row1.values.type.type
            );
            const b = providedServiceToLabel(
              row2.values.type.type === 'other_type'
                ? row2.values.type.name
                : row2.values.type.type
            );

            return a.localeCompare(b);
          },
          sortMaintenanceName: (row1: Row<TableData>, row2: Row<TableData>) => {
            const a = row1.values.maintenanceName.toLowerCase();
            const b = row2.values.maintenanceName.toLowerCase();

            if (a === '') {
              return 1;
            }
            return b === '' ? -1 : a.localeCompare(b);
          },
        },
        initialState: {
          sortBy: useMemo(() => {
            return [
              {
                id: 'status',
              },
              {
                id: 'dueDate',
              },
              {
                id: 'type',
              },
              {
                id: 'maintenanceName',
              },
            ];
          }, []),
        },
      },
      useSortBy
    );

  const goToDetailsPage = (id: string) => {
    if (!(location.state as { disableRowClick?: boolean })?.disableRowClick) {
      navigate(`/maintenance/${id}`);
    }
  };

  const getEmptyComponent = () => {
    if (noBuildings) {
      return (
        <InfoMessageBox
          label="Bevor Du Deine erste Wartung erstellen kannst, lege ein Gebäude an."
          isCentered
        >
          <Link
            to={`/${routes.buildings}`}
            className="flex flex-row items-center"
          >
            <FaReply className="transform -scale-x-1" />
            <span className="ml-2 underline font-bold">zu den Gebäuden</span>
          </Link>
        </InfoMessageBox>
      );
    }

    if (noMaintenances) {
      return (
        <InfoMessageBox
          label="Keine Wartung vorhanden. Lege deine erste Wartung an."
          isCentered
        >
          <Button
            className="primary-button small-button"
            onClick={() => {
              navigate(maintenanceRoutes.add);
            }}
            icon={<FaPlus />}
            label="Wartung"
          />
        </InfoMessageBox>
      );
    }

    if (!rows.length) {
      return (
        <InfoMessageBox
          label="Keine Wartungen entsprechen Deinem Filter."
          isCentered
        />
      );
    }
    return null;
  };

  return (
    <div className="h-full overflow-y-auto w-full overflow-x-hidden">
      <table
        {...getTableProps()}
        data-cy="components-maintenances-maintenance-table"
        className="w-full overflow-y-auto border-collapse bg-white"
      >
        {rows.length > 0 ? (
          <thead className="sticky top-0 w-full bg-gray-light text-black-abs">
            {headerGroups.map((headerGroup) => (
              <tr className="h-9" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${
                      index === 0 && 'border-b-2'
                    } border-r-2 border-white text-left pl-4`}
                  >
                    {column.render('Header')}
                    {column.isSorted && (
                      <span>{column.isSortedDesc ? '↓' : '↑'}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody className="overflow-y-auto" {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className="cursor-pointer hover:bg-gray-lighter hover:text-black-abs text-center h-9"
                {...row.getRowProps()}
                data-cy="components-maintenances-maintenance-overview-table-row"
              >
                {row.cells.map((cell, index) => {
                  if (cell.column.id !== 'selection') {
                    return (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <td
                        className={`border-r-2 border-white ${
                          (cell.column as { Header: string }).Header ===
                            'Einzelpreis' && 'text-right pr-2'
                        } ${index === 5 && ` border-b-2 border-white`} ${
                          cell.column.id !== 'dueDate'
                            ? 'text-left pl-4'
                            : 'text-center'
                        }`}
                        {...cell.getCellProps()}
                        onClick={() => {
                          goToDetailsPage(row.original.maintenance.id);
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                  return (
                    <td
                      className={`border-r-2 border-white ${
                        index === 0 && 'border-b-2 border-white'
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {getEmptyComponent()}
      {isLoading && (
        <div className="flex flex-1 justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default UserMaintenanceTable;
