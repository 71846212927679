import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

import { SelectLineMaintenanceOrderDto } from '@wartungshelden/shared-types';

interface Props {
  selectLineData?: SelectLineMaintenanceOrderDto;
}

const MissingDataIndicator: React.FC<Props> = ({ selectLineData }) => {
  if (!selectLineData) {
    return null;
  }

  return (
    <div className="flex flex-row items-center border-red border-2 px-4 py-2 mb-4 font-bold">
      <FaExclamationCircle className="mr-2 text-red" />
      Angaben fehlen. Bitte ergänze die fehlenden Informationen in SelectLine.
    </div>
  );
};

MissingDataIndicator.defaultProps = {
  selectLineData: undefined,
};

export default MissingDataIndicator;
