import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { useEffect, useState } from 'react';

export type UserInfo = {
  username: string;
  email?: string;
  phone?: string;
  familyName: string;
  givenName: string;
  isMaintenanceTeamMemberAdmin: boolean;
  isMaintenanceTeamMemberUser: boolean;
  groups: string[];
};

export const signup = async (
  username: string,
  password: string,
  firstName: string,
  lastName: string
) => {
  return Auth.signUp({
    username,
    password,
    attributes: {
      given_name: firstName,
      family_name: lastName,
    },
  });
};

export const verify = async (username: string, code: string) => {
  return Auth.confirmSignUp(username, code);
};

export const resendConfirmationCode = async (username: string) => {
  return Auth.resendSignUp(username);
};

export const signin = async (username: string, password: string) => {
  return Auth.signIn(username, password);
};

export const resetPasswordRequest = async (username: string) => {
  return Auth.forgotPassword(username);
};

export const resetPasswordConfirm = async (
  username: string,
  code: string,
  password: string
) => {
  return Auth.forgotPasswordSubmit(username, code, password);
};

export const signinWithGoogle = async () => {
  return Auth.federatedSignIn({
    provider: 'Google' as never,
  });
};

export const signinWithApple = async () => {
  return Auth.federatedSignIn({
    provider: 'SignInWithApple' as never,
  });
};

export const signinWithMicrosoft = async () => {
  return Auth.federatedSignIn({
    provider: 'ABS-Safety' as never,
  });
};

export const signout = async () => {
  await Auth.signOut();
};

const refreshSessionFactory = () => {
  let currentRefresh: Promise<boolean> | null = null;

  const refresh = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (!user) {
        return false;
      }

      const cognitoUser = user as CognitoUser;
      const currentSession = cognitoUser.getSignInUserSession();

      if (!currentSession) {
        return false;
      }

      return await new Promise<boolean>((resolve) => {
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err) => {
          if (err) {
            resolve(false);
          }

          resolve(true);
        });
      });
    } catch (e) {
      return false;
    } finally {
      currentRefresh = null;
    }
  };

  return () => {
    if (currentRefresh) {
      return currentRefresh;
    }

    currentRefresh = refresh();
    return currentRefresh;
  };
};
export const refreshSession = refreshSessionFactory();

export const getUser = async (): Promise<UserInfo | null> => {
  try {
    const user = await Auth.currentUserInfo();
    const session = await Auth.currentSession();

    if (!session || !user || Object.keys(user).length === 0) {
      return null;
    }

    const groups = session?.getAccessToken()?.payload['cognito:groups'] ?? [];

    return {
      username: user.username,
      email: user?.attributes?.email,
      phone: user?.attributes?.phone,
      givenName: user?.attributes?.given_name,
      familyName: user?.attributes?.family_name,
      isMaintenanceTeamMemberAdmin: groups.includes('ABS-Wartung'),
      isMaintenanceTeamMemberUser: groups.includes('ABS-Wartung-Internal'),
      groups,
    };
  } catch (e) {
    return null;
  }
};

export const getAccessToken = async (): Promise<string | null> => {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    return null;
  }
};

export const isSignedIn = async () => {
  const user = await getUser();
  return !!user;
};

export const useIsSignedIn = () => {
  const [signedIn, setSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialSignIn = async () => {
      setIsLoading(true);
      setSignedIn(await isSignedIn());
      setIsLoading(false);
    };
    initialSignIn();
  }, []);

  const listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setSignedIn(true);
        break;
      case 'signOut':
        setSignedIn(false);
        break;
      case 'signIn_failure':
        setSignedIn(false);
        break;
      case 'tokenRefresh':
        setSignedIn(true);
        break;
      case 'tokenRefresh_failure':
        setSignedIn(false);
        break;
      default:
        break;
    }
  };

  Hub.listen('auth', listener);

  return { isSignedIn: signedIn, isLoading };
};
