export interface BaseRepository<T extends { id: Id }, Id = string> {
  create(T: T): Promise<T>;

  update(update: Pick<T, 'id'> & Partial<T>): Promise<T>;

  getAll(): Promise<T[]>;

  getById(id: Id): Promise<T>;

  delete(id: Id): Promise<void>;
}

export interface OwnedEntityBaseRepository<
  T extends { id: Id; ownerId: string },
  Id = string
> extends BaseRepository<T, Id> {
  getAllByUser(userId: string): Promise<T[]>;
}

export type Id<T, Key extends keyof T> = Required<Pick<T, Key>>;

export interface CompositeBaseRepository<
  T extends { ownerId: string },
  U extends keyof T
> {
  create(T: T): Promise<T>;

  update(update: Pick<T, U> & Partial<T>): Promise<T>;

  getAll(): Promise<T[]>;

  getAllByUser(userId: string): Promise<T[]>;

  getById(id: Id<T, U>): Promise<T>;

  delete(id: Id<T, U>): Promise<void>;
}
