export enum PolicyTypeEnum {
  DATA_PROTECTION = 'data_protection_policy',
  TERMS_OF_SERVICE = 'terms_of_service',
  ALLOW_CONTACT = 'allow_contact',
}

export interface PolicyDto {
  readonly type: PolicyTypeEnum;
  readonly validFrom: Date;
  readonly version: string;
  readonly url?: string;
  readonly consentRequired: boolean;
}

export interface PolicyConsentDto {
  readonly type: PolicyTypeEnum;
  readonly version: string;
  readonly dateOfConsent?: Date;
}
