import React from 'react';

import { UnitInputField } from '../../../../../components';

const GroundStairSelector = () => {
  return (
    <div className="space-y-4 px-8">
      <UnitInputField
        label="Anzahl*"
        name="object.type.amount"
        unit="Stück"
        allowDecimals={false}
        shouldSelectOnFocus
      />
    </div>
  );
};

export default GroundStairSelector;
