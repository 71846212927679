import React from 'react';

type ButtonProps = {
  label?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  dataCy?: string;
};

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  onClick,
  disabled,
  children,
  icon,
  label,
  className,
  dataCy,
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      data-cy={dataCy}
      className={`flex items-center justify-center ${className}${
        disabled ? ' bg-opacity-50 text-opacity-50' : ''
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {(icon || label) && (
        <div className="flex items-center justify-center">
          {icon}
          {label && <div className={`truncate ${icon && 'ml-2'}`}>{label}</div>}
        </div>
      )}
      {children}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  disabled: false,
  onClick: undefined,
  className: 'primary-button',
  label: undefined,
  icon: undefined,
  dataCy: 'components-basics-button',
};
