import { isPhoneNumber } from 'class-validator';
import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const CustomerSchema = Yup.object().shape({
  // eslint-disable-next-line react/forbid-prop-types
  billingAddresses: Yup.array(
    Yup.object().shape({
      company: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      postalCode: Yup.number().required(),
      city: Yup.string().required(),
      country: Yup.string().required(),
      addressLine: Yup.string().required(),
    })
  ),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .optional()
    .test({
      message: 'Rufnummer nicht gültig',
      test: (number) => typeof number === 'undefined' || isPhoneNumber(number),
    }),
});
