import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  AdditionalMaintenanceOrderDataDto,
  AdditionalMaintenanceOrderSystemDataDto,
  BuildingAreaDto,
  CustomerMaintenancePriceDto,
  CustomerOrMaintenanceTeamOfferDto,
  MaintenanceAdministrationBuildingDto,
  MaintenanceOfferSetDecisionDto,
  MaintenancePriceDto,
  SelectLineMaintenanceOrderDto,
  UpdateMaintenanceOfferDto,
} from '@wartungshelden/shared-types';

import { useAxios } from '../api';

export const installationOrderQueryKey = 'installation-order';

export const useMaintenanceOffers = () => {
  const axios = useAxios();

  const fetchMaintenanceOffers = async () => {
    if (!axios) return undefined;
    const { data } = await axios.get<CustomerOrMaintenanceTeamOfferDto[]>(
      '/maintenance-exchange/maintenance/offers'
    );
    return data;
  };

  return useQuery('maintenanceOffers', fetchMaintenanceOffers);
};

export const useMaintenanceOffersOfRequest = (requestId?: string | null) => {
  const axios = useAxios();

  const fetchMaintenanceOffers = async () => {
    if (!axios) return undefined;
    const { data } = await axios.get<CustomerOrMaintenanceTeamOfferDto[]>(
      `/maintenance-exchange/maintenance/requests/${requestId}/offers`
    );
    return data;
  };

  return useQuery(['maintenanceOffers', requestId], fetchMaintenanceOffers, {
    enabled: Boolean(requestId),
  });
};

export const useMaintenanceOfferPrice = (
  offerId: string | null | undefined,
  strategy: 'wartungshelden' | 'fixed-fix-price' | 'no-fix-price'
) => {
  const axios = useAxios();

  const fetchMaintenanceOffers = async () => {
    if (!axios) return undefined;
    const { data } = await axios.get<
      CustomerMaintenancePriceDto | MaintenancePriceDto
    >(`/maintenance-exchange/maintenance/offers/${offerId}/price`, {
      params: {
        calculate: true,
        strategy: strategy ?? 'wartungshelden',
      },
    });
    return data;
  };

  return useQuery(
    ['maintenanceOffers', 'price', offerId],
    fetchMaintenanceOffers,
    {
      enabled: Boolean(offerId),
    }
  );
};

const updateMaintenanceOfferCall =
  (axios) => async (maintenanceOffer: UpdateMaintenanceOfferDto) => {
    if (!axios) return null;
    return axios.patch(
      `/maintenance-exchange/maintenance/offers`,
      maintenanceOffer
    );
  };

export const useUpdateMaintenanceOffer = (config?: {
  disableToast?: boolean;
  successMessage?: string;
  errorMessage?: string;
}) => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  return useMutation(updateMaintenanceOfferCall(axios), {
    onSuccess: async () => {
      if (!config?.disableToast) {
        toast.success(config?.successMessage ?? 'Speichern erfolgreich', {
          position: 'top-center',
        });
      }
      await rqClient.invalidateQueries(['maintenanceOffers']);
    },
    onError: () => {
      if (!config?.disableToast) {
        toast.error(config?.errorMessage ?? 'Speichern fehlgeschlagen', {
          position: 'top-center',
        });
      }
    },
  });
};

export const useUpdateMaintenanceOfferDecision = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const setMaintenanceOfferDecision =
    (ax) =>
    async (params: {
      id: string;
      decision: MaintenanceOfferSetDecisionDto;
    }) => {
      if (!ax) return null;
      return ax.post(
        `/maintenance-exchange/maintenance/offers/${params.id}/decision`,
        params.decision
      );
    };

  return useMutation(setMaintenanceOfferDecision(axios), {
    onSuccess: async () => {
      toast.success('Speichern erfolgreich', { position: 'top-center' });
      await rqClient.invalidateQueries(['maintenanceOffers']);
    },
  });
};

export const useInstallationOrder = () => {
  const axios = useAxios();

  const fetchInstallationOrder = async (
    orderId: string
  ): Promise<File | undefined> => {
    if (!axios) return undefined;

    const { data, filename }: { data: Blob; filename: string } =
      await axios.post(
        `/maintenance-administration/orders/${orderId}`,
        {},
        {
          responseType: 'blob',
          headers: {
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        }
      );

    return new File([data], filename, {
      type: data.type,
    });
  };

  return useMutation(
    [installationOrderQueryKey, 'installation-order'],
    fetchInstallationOrder
  );
};

export const useInstallationOrderSelectLine = (orderId: string | undefined) => {
  const axios = useAxios();

  const fetchInstallationOrderSelectLine = async () => {
    if (!axios) return undefined;

    const { data } = await axios.get<SelectLineMaintenanceOrderDto>(
      `/maintenance-administration/orders/${orderId}/selectline`
    );
    return data;
  };

  return useQuery(
    [installationOrderQueryKey, 'installation-order-selectline', orderId],
    fetchInstallationOrderSelectLine,
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useBuildingAreaDocu = (orderId: string | undefined) => {
  const axios = useAxios();

  const fetchBuildingArea = async () => {
    if (!axios || !orderId) return undefined;

    const { data, status } = await axios.get<BuildingAreaDto[] | undefined>(
      `/maintenance-administration/orders/${orderId}/building-areas`
    );

    if (status === 230) {
      toast.warning(
        'Leider haben wir auf die Systemdaten dieses Auftrags aktuell keinen Zugriff.',
        {
          position: 'top-center',
        }
      );
      return undefined;
    }

    if (status === 204) {
      toast.warning(
        'Leider gibt es keine Douc Daten zu dieser Auftragsnummer',
        {
          position: 'top-center',
        }
      );
    }
    return data;
  };

  return useQuery(
    [installationOrderQueryKey, 'installation-order-docu', orderId],
    fetchBuildingArea,
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      retry: false,
      onError: () => {
        toast.error('Docu Daten konnten nicht geladen werden', {
          position: 'top-center',
        });
      },
    }
  );
};

export const useInstallationOrderAdditional = (orderId: string | undefined) => {
  const axios = useAxios();

  const fetchInstallationOrderAdditional = async () => {
    if (!axios || !orderId) return undefined;

    const { data, status } = await axios.get<
      AdditionalMaintenanceOrderDataDto | undefined
    >(`/maintenance-administration/orders/${orderId}/additional/`);
    if (status === 204) {
      return undefined;
    }
    return data;
  };

  return useQuery(
    [installationOrderQueryKey, 'installation-order-additional', orderId],
    fetchInstallationOrderAdditional,
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useInstallationOrderBuilding = (orderId: string | undefined) => {
  const axios = useAxios();

  const fetchInstallationOrderBuilding = async () => {
    if (!axios) return undefined;

    const { data, status } = await axios.get<
      MaintenanceAdministrationBuildingDto | undefined
    >(`/maintenance-administration/orders/${orderId}/building`);
    if (status === 204) {
      return undefined;
    }
    return data;
  };

  return useQuery(
    [installationOrderQueryKey, 'installation-order-building', orderId],
    fetchInstallationOrderBuilding,
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useUpdateInstallationOrderAdditional = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const updateInstallationOrderAdditional = async (
    additionalMaintenanceOrderDataDto: AdditionalMaintenanceOrderDataDto
  ) => {
    if (!axios) return null;
    const { orderNumber, ...rest } = additionalMaintenanceOrderDataDto;
    return axios.post<AdditionalMaintenanceOrderDataDto>(
      `/maintenance-administration/orders/${orderNumber}/additional/`,
      rest
    );
  };

  return useMutation(
    [installationOrderQueryKey, 'installation-order-additional'],
    updateInstallationOrderAdditional,
    {
      onSuccess: async () => {
        await rqClient.invalidateQueries([
          installationOrderQueryKey,
          'installation-order-additional',
        ]);
      },
      onError: () => {
        toast.error('Zusätzliche Daten konnten nicht geladen werden', {
          position: 'top-center',
        });
      },
    }
  );
};

export const useInstallationOrderAdditionalWithDocu = (
  orderId: string | undefined
) => {
  const { data: buildingAreas, isLoading: isBuildingAreaLoading } =
    useBuildingAreaDocu(orderId);
  const { data: additionalData, isLoading: isAdditionalDataLoading } =
    useInstallationOrderAdditional(orderId);
  const [data, setData] = useState<
    AdditionalMaintenanceOrderDataDto | undefined
  >();

  const {
    mutateAsync: updateInstallationOrderAdditional,
    isLoading: isLoadingAdditionalUpdate,
  } = useUpdateInstallationOrderAdditional();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(isAdditionalDataLoading || isBuildingAreaLoading);
  }, [isAdditionalDataLoading, isBuildingAreaLoading]);

  useEffect(() => {
    const initializeData = async () => {
      if (!buildingAreas && !additionalData) {
        setData(undefined);
        return;
      }

      if (
        !buildingAreas ||
        (additionalData?.systems && additionalData?.systems.length)
      ) {
        setData(additionalData);
        return;
      }

      const additionalDataWithDocu = buildingAreas.reduce(
        (prev, currBuildingArea) => {
          const documentationProducts = currBuildingArea.documentations.flatMap(
            ({ products }) => products
          );

          const newSystems: AdditionalMaintenanceOrderSystemDataDto[] =
            documentationProducts.map((product) => {
              return {
                additionalNotes: `Dachfläche: ${currBuildingArea.name}`,
                systemComponents: product.productName,
              };
            });

          if (prev.systems) {
            return {
              ...prev,
              systems: [...prev.systems, ...newSystems],
            };
          }

          return {
            ...prev,
            systems: newSystems,
          };
        },
        additionalData ??
          ({
            orderNumber: orderId,
            systems: [],
          } as AdditionalMaintenanceOrderDataDto)
      );

      await updateInstallationOrderAdditional(additionalDataWithDocu);

      setData(additionalDataWithDocu);
    };

    initializeData();
  }, [buildingAreas, additionalData]);
  return { data, isLoading: isLoading || isLoadingAdditionalUpdate };
};
