import { OwnedEntityBaseRepository } from '@warthungshelden/domain/common';

import { MaintenanceOffer } from './maintenance-offer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MaintenanceOfferRepository
  extends OwnedEntityBaseRepository<MaintenanceOffer> {
  getAllByRequestId(requestId: string): Promise<MaintenanceOffer[]>;

  getAllWhereSentBetween(from: Date, to: Date): Promise<MaintenanceOffer[]>;
}
