import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { SessionContext } from './contexts/SessionContext';

const InstanaComponent = () => {
  const { pathname, search } = useLocation();
  const session = useContext(SessionContext);

  const path = pathname + search;

  React.useEffect(() => {
    if (window.ineum !== undefined) {
      ineum(
        'meta',
        'version',
        process.env.npm_package_version ?? 'Unknown version'
      );
    }
  }, []);

  React.useEffect(() => {
    if (window.ineum !== undefined) {
      ineum('page', path);
    }
  }, [path]);

  React.useEffect(() => {
    if (window.ineum !== undefined && session.userInfo) {
      ineum('user', session.userInfo.username);
    }
  }, [session]);

  return null;
};

export default InstanaComponent;
