import { Formik } from 'formik';
import React, { useContext } from 'react';

import { PageContainer } from '../../../components';
import { PageHeaderOverview } from '../../../components/Basics/Layouts/PageHeader';
import PriceCalculationContext from './PriceCalculationContext';
import PriceCalculationPageForm from './components/PriceCalculationPageForm';

const PriceCalculationPage = () => {
  const {
    setPriceWithDocu,
    setPriceWithoutDocu,
    initialValues,
    submitFormikForm,
  } = useContext(PriceCalculationContext);

  return (
    <PageContainer>
      <div className="flex flex-col flex-1 h-full">
        <PageHeaderOverview>
          <div className="pl-4">Wartungspreisrechner</div>
        </PageHeaderOverview>

        <div className="flex h-full">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (state) => {
              await submitFormikForm(state);
            }}
            onReset={() => {
              setPriceWithoutDocu(undefined);
              setPriceWithDocu(undefined);
            }}
          >
            {() => {
              return <PriceCalculationPageForm />;
            }}
          </Formik>
        </div>
      </div>
    </PageContainer>
  );
};

export default PriceCalculationPage;
