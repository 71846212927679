import { useFormikContext } from 'formik';
import React from 'react';

import { Button } from '../../../../components';
import AddressForm from '../../../../components/Address/AddressForm';
import { AddressFormikValues } from '../../../../components/Address/types/AddressFormikValues';
import CheckBoxField from '../../../../components/Basics/Inputs/CheckBox/CheckBoxField';
import Modal from '../../../../components/Basics/Modals/Modal';

interface AddressModalProps {
  isOpen: boolean;
  setIsOpen: (state: boolean | ((prevState: boolean) => boolean)) => void;
}

const AddressModal: React.FC<React.PropsWithChildren<AddressModalProps>> = ({
  isOpen,
  setIsOpen,
}) => {
  const { resetForm, isValid } = useFormikContext<AddressFormikValues>();

  return (
    <Modal headline="Adresse" isOpen={isOpen}>
      <>
        <AddressForm />
        <div className="my-2">
          <CheckBoxField
            name="hasSaveOnCustomerChecked"
            label="Adresse und Kontaktdaten als Standard festlegen"
          />
        </div>
        <div className="flex justify-center">
          <div>
            <Button
              className="primary-button small-button"
              label="Abbrechen"
              onClick={() => {
                resetForm();
                setIsOpen(false);
              }}
            />
          </div>
          <div className="ml-4">
            <Button
              className="primary-button small-button"
              onClick={async () => {
                setIsOpen(false);
              }}
              label="Ok"
              disabled={!isValid}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

export default AddressModal;
