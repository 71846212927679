import { MaintenanceObject, MaintenanceType } from '../../maintenance-object';
import { OfferedMaintenanceObject } from '../../maintenance-offer';

export type Priceable<T extends MaintenanceObject | OfferedMaintenanceObject> =
  T extends MaintenanceObject
    ? Required<Pick<T, 'type' | 'id'>> & Partial<T>
    : T extends OfferedMaintenanceObject
    ? Required<Pick<T, 'type' | 'originalId'>> & Partial<T>
    : never;

export type PriceableMaintenanceObject<
  T extends MaintenanceType = MaintenanceType
> = Priceable<MaintenanceObject<T> | OfferedMaintenanceObject<T>>;

export type PriceableBuilding = { isPublic: boolean; buildingType: string };
