import { Menu, Transition } from '@headlessui/react';
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
} from 'react';
import { FaAngleDown } from 'react-icons/fa';

import FilterCheckboxGrid from './FilterCheckboxGrid';

interface FilterButtonProps<T> {
  label: string;
  headerLabels: string[];
  items: T[];
  contentKeys: Array<keyof T>;
  uniqueKeys?: Array<keyof T>;
  setSelectedItems: (items: T[]) => void;
  selectedItems?: T[] | null;
  disabled?: boolean;
}

const FilterButton = <
  T extends { [K in keyof T]: string | number | Date | object | boolean | null }
>({
  label,
  headerLabels,
  items,
  contentKeys,
  uniqueKeys,
  selectedItems,
  setSelectedItems,
  disabled,
}: PropsWithChildren<FilterButtonProps<T>>) => {
  const getUniqueItems = useCallback((uniqueItems: T[]) => {
    const identificationKeys = uniqueKeys ?? contentKeys;

    return uniqueItems.reduce((unique, o) => {
      if (
        !unique.some((obj) => {
          return identificationKeys.every((key) => obj[key] === o[key]);
        })
      ) {
        unique.push(o);
      }
      return unique;
    }, new Array<T>());
  }, []);

  useEffect(() => {
    setSelectedItems(getUniqueItems(items));
  }, [items]);

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        {disabled ? (
          <div
            className={`flex items-center border-2 border-gray w-full rounded-lg px-4 ${
              disabled ? 'bg-gray-lighter text-gray-dark' : 'bg-white'
            }`}
          >
            <span>{label}</span>
            <div className="p-1 flex flex-1 items-center justify-end">
              <span className="bg-gray-dark text-white p-1 rounded-md mr-4">
                {selectedItems?.length === getUniqueItems(items).length &&
                selectedItems?.length !== 0
                  ? `Alle (${selectedItems.length})`
                  : selectedItems?.length ?? 0}
              </span>
              <FaAngleDown />
            </div>
          </div>
        ) : (
          <Menu.Button className="flex items-center bg-white border-2 border-gray w-full rounded-lg px-4">
            <span>{label}</span>
            <div className="p-1 flex flex-1 items-center justify-end">
              <span className="bg-gray-dark text-white p-1 rounded-md mr-4">
                {selectedItems?.length === getUniqueItems(items).length &&
                selectedItems?.length !== 0
                  ? `Alle (${selectedItems.length})`
                  : selectedItems?.length ?? 0}
              </span>
              <FaAngleDown />
            </div>
          </Menu.Button>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-80 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll max-h-96 z-50">
          <Menu.Item>
            {() => (
              <FilterCheckboxGrid
                items={items}
                uniqueKeys={uniqueKeys}
                contentKeys={contentKeys}
                headerLabels={headerLabels}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                getUniqueItems={getUniqueItems}
              />
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

FilterButton.defaultProps = {
  selectedItems: undefined,
  disabled: false,
  uniqueKeys: undefined,
};

export default FilterButton;
