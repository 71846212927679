import { addYears } from 'date-fns';
import de from 'date-fns/locale/de';
import { useFormikContext } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type DateTimeSelectProps = {
  submitOnChange?: boolean;
  name: string;
};

const DateTimeSelectField: React.FC<
  React.PropsWithChildren<DateTimeSelectProps>
> = ({ name, submitOnChange }) => {
  const { setFieldValue, values, submitForm }: any = useFormikContext();

  const now = new Date();
  const fiveYearsFromNow = addYears(now, 5);
  return (
    <DatePicker
      selected={values[name]}
      onChange={(date) => {
        setFieldValue(name, date);
        if (submitOnChange) {
          submitForm(date);
        }
      }}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      minDate={now}
      maxDate={fiveYearsFromNow}
      showYearDropdown
      isClearable
      locale={de}
      timeCaption="Zeit"
      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      dateFormat="Pp"
      className="border-2 rounded-lg px-4 py-2 bg-white border-gray"
    />
  );
};

DateTimeSelectField.defaultProps = {
  submitOnChange: false,
};
export default DateTimeSelectField;
