import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAxios } from '../api';

const QUERY_KEY = 'maintenanceOrderConfirmations';

export const useOrderConfirmationFile = (offerId?: string, enabled = true) => {
  const axios = useAxios();

  const fetchOrderConfirmationFile = async () => {
    if (!axios) return null;

    const { data, filename }: { data: Blob; filename: string } =
      await axios.get(
        `/maintenance-exchange/maintenance/offers/${offerId}/order/confirmation/document`,
        {
          responseType: 'blob',
        }
      );

    return new File([data], filename, {
      type: data.type,
    });
  };

  return useQuery([QUERY_KEY], fetchOrderConfirmationFile, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
  });
};

export const useAttachOrderConfirmationFile = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const attachOrderConfirmationFile = async ({
    offerId,
    file,
  }: {
    offerId?: string;
    file: File;
  }) => {
    if (!axios) return;
    const formData = new FormData();
    formData.append('file', file);

    await axios.put(
      `/maintenance-exchange/maintenance/offers/${offerId}/order/confirmation/document`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };

  return useMutation(attachOrderConfirmationFile, {
    onSuccess: async () => {
      await rqClient.invalidateQueries([QUERY_KEY]);
      await rqClient.invalidateQueries('maintenanceOffers');
    },
  });
};

export const useRemoveOrderConfirmationFile = () => {
  const axios = useAxios();
  const rqClient = useQueryClient();

  const removeOrderConfirmationFile = async ({
    offerId,
  }: {
    offerId?: string;
  }) => {
    if (!axios) return;

    await axios.delete(
      `/maintenance-exchange/maintenance/offers/${offerId}/order/confirmation/document`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };

  return useMutation(removeOrderConfirmationFile, {
    onSuccess: async () => {
      await rqClient.resetQueries([QUERY_KEY]);
      await rqClient.invalidateQueries([QUERY_KEY]);
      await rqClient.invalidateQueries('maintenanceOffers');
    },
  });
};
