import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import {
  formatToMonth,
  monthToDate,
} from '../../../../services/timeDateService';
import { SelectField } from '../../../index';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import './CalendarStyles.css';

type DueDateSelectProps = {
  disabled: boolean;
  isRequired?: boolean;
};

const DueDateSelectField: React.FC<
  React.PropsWithChildren<DueDateSelectProps>
> = ({ disabled, isRequired }) => {
  const { setFieldValue, values }: any = useFormikContext();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Date | null>(
    values.dueDate && monthToDate(values.dueDate)
  );
  const [showDelete, setShowDelete] = useState<boolean>(values.dueDate);

  useEffect(() => {
    setShowDelete(values.dueDate);
  }, [values.dueDate]);

  return (
    <>
      <SelectField
        label={`Nächste Fälligkeit${isRequired ? '*' : ''}`}
        name="dueDate"
        placeHolder="Nächste Fälligkeit"
        onClick={() => !disabled && setIsModalVisible(true)}
        disabled={disabled}
      />
      <ConfirmationModal
        isFullHeight
        heading="Monat, Jahr auswählen"
        onAccept={
          !showDelete && selectedItem
            ? () => {
                setFieldValue('dueDate', formatToMonth(selectedItem));
                setIsModalVisible(false);
              }
            : undefined
        }
        onCancel={() => {
          setSelectedItem(values.dueDate && monthToDate(values.dueDate));
          setShowDelete(values.dueDate);
          setIsModalVisible(false);
        }}
        isVisible={isModalVisible}
        onDelete={
          showDelete
            ? () => {
                setFieldValue('dueDate', null);
                setIsModalVisible(false);
                setSelectedItem(null);
              }
            : undefined
        }
      >
        <Calendar
          value={selectedItem}
          onChange={(dueDate: any) => {
            setSelectedItem(dueDate);
            setShowDelete(false);
          }}
          maxDetail="year"
          defaultView="year"
          locale="de"
        />
      </ConfirmationModal>
    </>
  );
};

DueDateSelectField.defaultProps = {
  isRequired: false,
};
export default DueDateSelectField;
