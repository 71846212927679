import { v4 as uuidv4 } from 'uuid';

import { NotFoundError } from '../errors';
import { OwnedEntityBaseRepository } from './base.repository';

export interface Type<T> extends Function {
  new (...args: any[]): T;
}

export class InMemoryRepository<T extends { id: string; ownerId: string }>
  implements OwnedEntityBaseRepository<T>
{
  constructor(protected type: Type<T>, protected entities: T[] = []) {}

  public reset() {
    this.entities = [];
  }

  public async create(entity: T): Promise<T> {
    if (!entity.id) {
      entity.id = uuidv4();
    }

    this.entities.push(entity);
    return entity;
  }

  public async delete(id: string): Promise<void> {
    if (!this.entities.find(({ id: currentId }) => currentId === id)) {
      throw new NotFoundError();
    }

    this.entities = this.entities.filter(
      ({ id: currentId }) => currentId !== id
    );
  }

  public async getAll(): Promise<T[]> {
    return this.entities;
  }

  public async getAllByUser(userId: string): Promise<T[]> {
    return this.entities.filter(({ ownerId }) => ownerId === userId);
  }

  public async getAllById(ids: string[]): Promise<T[]> {
    const results = this.entities.filter(({ id }) => ids.includes(id));

    if (results.length !== ids.length) {
      throw new NotFoundError();
    }

    return results;
  }

  public async getById(id: string): Promise<T> {
    const entity = this.entities.find(({ id: currentId }) => currentId === id);
    if (!entity) {
      throw new NotFoundError();
    }
    return entity;
  }

  public async update(update: Pick<T, 'id'> & Partial<T>): Promise<T> {
    const entity = this.entities.find(
      ({ id: currentId }) => currentId === update.id
    );

    if (!entity) {
      throw new NotFoundError();
    }

    const updatedEntity = new this.type({ ...entity, ...update });

    this.entities = this.entities.filter(({ id }) => id != update.id);
    this.entities.push(updatedEntity);

    return updatedEntity;
  }
}
